import React, { useState, useContext, useEffect } from 'react';
import AdminUI from './Ui/AdminUI';
import { Context } from '../..';
import { Row, Col, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { cleanAdminDashboard, getAllNotifications } from '../../api/notificationsAPI';
import LoadingPlaceholder from '../Functional/LoadingPlaceholder';
import AdminNotification from './Ui/AdminNotification/AdminNotifications';
import { NotificationContainer, NotificationManager } from 'react-notifications';

const NotifyContainer = styled.div`
display: flex;
flex-direction: column;
gap: 15px;
`
const ActionContainer = styled.div`
display: flex;
`

const Admin = () => {
    const { user } = useContext(Context)
    const [isLoaded, setIsLoaded] = useState(false);
    const [noti, setNoti] = useState([]);
    const [reload, setReload] = useState(false);
    const [error, setError] = useState(null);
    useEffect(() => {
        getAllNotifications().then((data) => {
            setNoti(data);
            setIsLoaded(true);
        }, (e) => {
            setError(e);
        })
    }, [reload])

    const removeAllHandle = () => {
        cleanAdminDashboard().then((data) => {
            if (data.status == 'all_removed') {
                NotificationManager.success('Cleared', 'Success');
                setReload(!reload);
            }
            else {
                NotificationManager.error(data, 'Error');
            }
        })
    }

    return (
        <AdminUI>
            <NotificationContainer />
            <Row>
                <Col>
                    <h2>Welcome, {user.user.first_name} {user.user.last_name}</h2>
                    <ActionContainer>
                        <h3 style={{ margin: '0px', marginRight: '10px' }}>Latest events</h3>
                        {noti.length < 1 ? null : <Button variant='danger' onClick={() => { removeAllHandle() }}>Clear all</Button>}
                    </ActionContainer>
                    {error ? <h2>{error}</h2> : isLoaded && noti.length > 0 ? <Row>
                        <Col>
                            <h5>Security events</h5>
                            <NotifyContainer>
                                {noti.map((n) => {
                                    if (n.notifyType == 'admin-login' || n.notifyType == 'user-pass-change' || n.notifyType == 'user-strange-login') {
                                        return (<AdminNotification key={n.id} id={n.id} notifyType={n.notifyType} eventedBy={n.eventedBy} eventTime={n.eventTime} body={n.body} setNeedReload={setReload} needReload={reload} createdObjectId={n.createdObjectId} />)
                                    }
                                })}
                            </NotifyContainer>
                        </Col>
                        <Col>
                            <h5>New purchases</h5>
                            <NotifyContainer>
                                {noti.map((n) => {
                                    if (n.notifyType == 'user-new-order') {
                                        return (<AdminNotification key={n.id} id={n.id} notifyType={n.notifyType} eventedBy={n.eventedBy} eventTime={n.eventTime} body={n.body} setNeedReload={setReload} needReload={reload} createdObjectId={n.createdObjectId} />)
                                    }
                                })}
                            </NotifyContainer>
                        </Col>
                        <Col>
                            <h5>Other events</h5>
                            <NotifyContainer>
                                {noti.map((n) => {
                                    if (n.notifyType == 'order-status-changed' || n.notifyType == 'user-created') {
                                        return (<AdminNotification key={n.id} id={n.id} notifyType={n.notifyType} eventedBy={n.eventedBy} eventTime={n.eventTime} body={n.body} setNeedReload={setReload} needReload={reload} createdObjectId={n.createdObjectId} />)
                                    }
                                })}
                            </NotifyContainer>
                        </Col>
                    </Row> : !isLoaded && noti.length < 1 ? <LoadingPlaceholder /> : noti.length < 1 ? <h5 style={{ marginTop: '15px' }}>Notifications not found</h5> : null}
                </Col>
            </Row>
        </AdminUI>
    )
}
export default Admin