import React from "react";
import styled from "styled-components";
import NotificationMessage from "./NotificationMessage";


const NotificationContainer = styled.div`
    z-index: 999999999999999999999;
    min-height: auto;
    position: fixed;
    bottom: 20px;
    width: 90%;
    left: 5%;
@media screen and (min-width: 1128px){
    min-height: auto;
    position: fixed;
    left: calc(50% - 200px);
    width: auto;
}
`;

const Notifications = () => {
    return (
        <NotificationContainer>
            <NotificationMessage name='cookie' action='Agree'>
                <p class="cookienotify popup__text">Vitaforest.eu uses cookies to provide necessary website functionality, improve your experience and analyze our traffic. By using our website, you agree to our <a href="/en/cookie-policy">Cookie Policy</a> and our cookies usage.</p>
            </NotificationMessage>
        </NotificationContainer>
    )
}

export default Notifications