import { $authHost, $host } from ".";

export const getAllOffers = async () => {
    try {
        const { data } = await $authHost.get('/api/offers/');
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const updateOffer = async (body) => {
    try {
        const { data } = await $authHost.post('/api/offers/update', body);
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const removeOffer = async (id) => {
    try {
        const { data } = await $authHost.post(`/api/offers/remove/${id}`);
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const offerToBasket = async (id) => {
    try {
        const { data } = await $authHost.post(`/api/offers/tocart/${id}`);
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const getOffersByUser = async () => {
    try {
        const { data } = await $authHost.get('/api/offers/byuser');
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const createOffer = async (body, type) => {
    try {
        if (type == 'multi') {
            const { data } = await $authHost.post('/api/offers/multicreate', body);
            return data
        }
        if (type == 'single') {
            const { data } = await $authHost.post('/api/offers/', body);
            return data
        }
    } catch (e) {
        return e.response.data;
    }
}

export const getOffer = async (id) => {
    try {
        const { data } = await $authHost.get(`/api/offers/id/${id}`);
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const declineOffer = async (id) => {
    try {
        const { data } = await $authHost.get(`/api/offers/decline/${id}`);
        return data;
    } catch (e) {
        return e.response.data;
    }
}