import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import styled from "styled-components";
import { liveSearch } from "../../../../../api/searchAPI";
import { getFileUrl } from "../../../../../api/productAPI";
import RoundedButton from "../../Buttons/RoundedButton";
import { Link, useNavigate } from "react-router-dom";
import LoadingPlaceholder from "../../../../Functional/LoadingPlaceholder";

const Container = styled.div`
  position: absolute;
  width: calc(100vw - 10px);
  min-height: auto;
  background: #f7f8f9;
  border: 1px solid #eaebec;
  border-radius: 2px;
  z-index: 8;
  left: -13px;
  top: 50px;
  box-sizing: border-box;
  padding: 20px 33px;
  @media screen and (min-width: 1128px) {
    width: 693px;
    height: auto;
    left: 192px;
  }
`;

const SectionHeading = styled.h4`
  font-weight: 700;
  font-size: 13px;
  line-height: 14px;
  color: #303236;
  opacity: 0.8;
  margin-bottom: 8px;
`;

const Section = styled.div``;

const Product = styled.div`
  width: calc(50% - 10px);
  position: relative;
  @media screen and (min-width: 1128px) {
    width: 195px;
  }
`;

const ProductImage = styled.img`
  width: 100%;
  aspect-ratio: 115/102;
  object-fit: cover;
  filter: brightness(0.6);
`;

const ProductsList = styled.ul`
  padding-left: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  @media screen and (min-width: 1128px) {
    gap: 20px;
  }
`;

const ProductTitle = styled.h5`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #ffffff;
  position: absolute;
  bottom: 20px;
  left: 10px;
`;

const ItemsList = styled.ul`
  padding: 0;
`;

const Item = styled.li`
  list-style: none;
  background-color: #ffffff;
  width: 100%;
  margin-bottom: 5px;
  box-sizing: border-box;
  padding: 10px;
  &:hover{
    box-shadow: 0px 0px 20px rgba(26, 33, 45, 0.05);
  }
  &:hover a{
    color: inherit;
    text-decoration: underline;
  }
`;

const ItemTitle = styled(Link)`
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #303236;
  text-decoration: none;
`;

const MoreBtn = styled(RoundedButton)`
  margin-bottom: 25px;
`;

const LinkWrap = styled(Link)`
text-decoration: none;
color: inherit;
&:hover h5{
  text-decoration-line: underline;
}
`

export default function SearchResults({ search }) {
  const [results, setResults] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (search.length >= 3) {
      liveSearch(search).then(
        (data) => {
          setResults(data);
          setIsLoaded(true);
        },
        (e) => {
          setError(error);
          setIsLoaded(false);
        }
      );
    }
    else {
      return null
    }
  }, [search]);

  if (!isLoaded) {
    return (
      <Container>
        <LoadingPlaceholder />
      </Container>
    );
  } else if (error) {
    return <Container>{error}</Container>;
  } else {
    return (
      <Container>
        {results.products[0] ? (<Section>
          <SectionHeading>Products</SectionHeading>
          <ProductsList>
            {results.products.map((item) => {
              return (
                <Product>
                  <LinkWrap to={`/en/product/${item.url}`}>
                    <ProductImage src={getFileUrl(item.img)} />
                    <ProductTitle>{item.title}</ProductTitle>
                  </LinkWrap>
                </Product>
              );
            })}
          </ProductsList>
          <MoreBtn title="See more" onClick={() => { navigate(`/search/${search}`) }} />
        </Section>) : null}
        {results.wiki[0] ? (<Section>
          <SectionHeading>Wiki</SectionHeading>
          <ItemsList>
            {results.wiki.map((item) => {
              return (
                <Item>
                  <ItemTitle to={`/en/wiki/${item.url}`}>{item.title}</ItemTitle>
                </Item>
              );
            })}
          </ItemsList>
          <MoreBtn title="See more" onClick={() => { navigate(`/search/${search}`) }} />
        </Section>) : null}
        {results.posts[0] ? (<Section>
          <SectionHeading>Posts</SectionHeading>
          <ItemsList>
            {results.posts.map((item) => {
              return (
                <Item>
                  <ItemTitle to={`/en/blog/${item.url}`}>{item.title}</ItemTitle>
                </Item>
              );
            })}
          </ItemsList>
          <MoreBtn title="See more" onClick={() => { navigate(`/search/${search}`) }} />
        </Section>) : null}
        {!results.posts[0] && !results.wiki[0] && !results.products[0] ? (
          <Section>
            Nothing was found. Try changing your search query.
          </Section>
        ) : null}
      </Container>
    );
  }
}
