import React, { useState } from "react";
import styled from "styled-components";
import Page from "../components/VitaforestUI/Interface/Page/Page";
import { Carousel } from "react-responsive-carousel";
import MediaQuery from "react-responsive";
import { Helmet } from "react-helmet";
import SliderButton from "../components/VitaforestUI/Interface/Buttons/SliderButton";
const Container = styled.div`
  width: calc(100vw - 36px);
  margin: 30px auto;
  @media screen and (min-width: 1128px) {
    width: 1128px;
    margin-top: 36px;
  }
`;

const AboutList = styled.div`
  @media screen and (min-width: 1128px) {
    display: flex;
    flex-direction: column;
    gap: 100px;
  }
`;
const Preview = styled.div`
  height: 530px;
  background: url("/about-preview.webp");
  background-size: cover;
  margin-top: -10px;
  @media screen and (min-width: 1128px) {
    height: 380px;
  }
`;

const PreviewTitle = styled.h2`
  font-weight: 700;
  font-size: 38px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
  padding-top: 100px;
  position: relative;

  @media screen and (min-width: 1128px) {
    padding-top: 110px;
    font-size: 32px;
    line-height: 36px;
  }
`;

const PreviewDescription = styled.p`
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  width: 250px;
  margin-top: 15px;
  margin: 15px auto;
  @media screen and (min-width: 1128px) {
    width: 364px;
    margin-top: 6px;
  }
`;

const AboutBlock = styled.div`
  @media screen and (min-width: 1128px) {
    display: flex;
    gap: 60px;
    align-items: center;
  }
`;
const AboutTitle = styled.h3`
  font-weight: 600;
  font-size: 20px;
  line-height: 17px;
  padding: 15px 0px;
  box-shadow: 0px 1px 0px #eaebec;
  position: relative;
  &:before {
    height: 5px;
    width: 10px;
    content: " ";
    mask: url("/arrow.svg");
    position: absolute;
    right: 0px;
    transition: transform 0.5s, background-color 0.5s;
    background-color: #303236;
    top: 21px;
  }
  &.opened {
    color: #40bf6a;
    box-shadow: none;
    &:before {
      transform: rotate(180deg);
      background-color: #40bf6a;
    }
  }

  @media screen and (min-width: 1128px) {
    box-shadow: none;
    color: #303236;
    font-weight: 600;
    font-size: 26px;
    line-height: 28px;
    margin-bottom: 50px;
    &:before {
      mask: none;
      width: 0px;
      height: 0px;
    }
    &.opened {
      color: #303236;
    }
  }
`;

const ShowingBlock = styled.div`
  display: none;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  text-align: justify;
  p {
    margin-top: 30px;
  }
  &.opened {
    display: block;
  }
  @media screen and (min-width: 1128px) {
    display: block;
    p {
      margin-top: 0;
    }
  }
`;

const BlockImage = styled.img`
  border-radius: 4px;
  width: 100%;
  margin-top: 15px;
  max-height: 450px;
  object-fit: cover;
  display: none;
  aspect-ratio: 284/150;
  &.opened {
    display: block;
  }
  @media screen and (min-width: 1128px) {
    display: block;
    width: 530px;
    aspect-ratio: 438/360;
  }
`;

const Wrapper = styled.div`
  @media screen and (min-width: 1128px) {
    width: 530px;
  }
`;

const ReversedAboutBlock = styled(AboutBlock)`
  @media screen and (min-width: 1128px) {
    flex-direction: row-reverse;
  }
`;

const Map = styled(BlockImage)`
  @media screen and (min-width: 1128px) {
    width: 100%;
  }
`;

const Heading = styled.h4`
  font-weight: 600;
  font-size: 26px;
  margin-bottom: 30px;
  line-height: 28px;
  @media screen and (min-width: 1128px) {
    font-weight: 700;
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 44px;
  }
`;

const Advantages = styled.div`
  background: #f7f8f9;

  position: relative;
  padding-top: 20px;

  .button {
    border: none;
    background-color: transparent;
    position: absolute;
    bottom: 56px;
    z-index: 8;
    &.prevButton {
      left: calc(50% - 80px);
    }
    &.nextButton {
      right: calc(50% - 80px);
    }
  }
  .carousel .carousel-status {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #3c3f54;
    position: absolute;
    bottom: 44px;
    left: calc(50% - 21px);
    top: initial;
    text-shadow: none;
  }
  @media screen and (min-width: 1128px) {
    padding-top: 44px;
    .carousel .slide img {
      width: 550px;
      height: 237px;
      object-fit: contain;
    }
    .button {
      bottom: 36px;
    }
    .carousel .carousel-status {
      bottom: 24px;
    }
  }
`;
const Slide = styled.div`
  padding-bottom: 116px;
  padding-left: 4px;
`;
const SlideWrapper = styled.div`
  @media screen and (min-width: 1128px) {
    display: flex;
    gap: 97px;
    align-items: center;
  }
`;

const SlideBg = styled.div`
  background: linear-gradient(
    0deg,
    rgba(26, 33, 45, 0.5),
    rgba(26, 33, 45, 0.5)
  );
  z-index: 2;
  position: absolute;
  opacity: 0.8;
  width: 100%;
  height: calc(100% - 30px);
  left: 0px;
  top: 0px;
`;

const SlideImageWrapper = styled.div`
  position: relative;
`;

const SlideImage = styled.img`
  border-radius: 4px;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 1;
`;
const SlideTitle = styled.h5`
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  font-weight: 400;
  position: absolute;
  left: 18px;
  bottom: 46px;
  position: relative;
  z-index: 3;
  padding-right: 18px;
  margin-bottom: 0;
  @media screen and (min-width: 1128px) {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #303236;
    position: initial;
    left: initial;
    bottom: initial;
  }
`;

const SlideContent = styled.div`
  text-align: justify;
  padding-top: 30px;
  padding-bottom: 21px;
`;

const SlideDescription = styled.p`
  margin-top: 30px;
`;

const SliderContainer = styled(Container)`
  margin-bottom: 0;
`;
export default function About() {
  const [isWhoOpened, setIsWhoOpened] = useState(false);
  const [isWhatOpened, setIsWhatOpened] = useState(false);
  const [isLocationOpened, setIsLocationOpened] = useState(false);
  const [isEnvironmentalOpened, setIsEnvironmentalOpened] = useState(false);
  const [isClientsOpened, setIsClientsOpened] = useState(false);

  return (
    <Page seo_title={`About us - Vitaforest`} seo_description={'Vitaforest about us'}>
      <Preview>
        <PreviewTitle>About us</PreviewTitle>
        <PreviewDescription>
          We produce and supply raw materials and extracts from wild plants and
          fungi
        </PreviewDescription>
      </Preview>
      <Container>
        <AboutList>
          <AboutBlock>
            <Wrapper>
              <AboutTitle
                className={isWhoOpened ? "opened" : null}
                onClick={() => setIsWhoOpened(!isWhoOpened)}
              >
                Who we are?
              </AboutTitle>
              <ShowingBlock className={isWhoOpened ? "opened" : null}>
                <MediaQuery maxWidth={1127}>
                  <BlockImage
                    src="/whoweare_pic.webp"
                    className={isWhoOpened ? "opened" : null}
                  />
                </MediaQuery>
                <p>
                  The international company Vitaforest is the group of experts
                  in the production and supply of powders and extracts from wild
                  plants, berries, fruits and mushrooms.
                </p>
                <p>
                  We forage with care raw materials in ecologically clean
                  regions of Siberia to preserve the most powerful complex of
                  bioactive substances of plant, berry, fruit and mushrooms
                  sources, to share with you the pristine unbridled power of the
                  wild nature of this unique region.
                </p>
              </ShowingBlock>
            </Wrapper>
            <MediaQuery minWidth={1128}>
              <BlockImage
                src="/whoweare_pic.webp"
                className={isWhoOpened ? "opened" : null}
              />
            </MediaQuery>
          </AboutBlock>

          <ReversedAboutBlock>
            <Wrapper>
              <AboutTitle
                className={isWhatOpened ? "opened" : null}
                onClick={() => setIsWhatOpened(!isWhatOpened)}
              >
                What can we do for you?
              </AboutTitle>
              <ShowingBlock className={isWhatOpened ? "opened" : null}>
                <MediaQuery maxWidth={1127}>
                  <BlockImage
                    src="/whatcanwedo_pic.webp"
                    className={isWhatOpened ? "opened" : null}
                  />
                </MediaQuery>
                <p>
                  We produce ingredients, such as herbal extracts and powders
                  for manufacturers of healthy foods, dietary supplements for
                  both animals and people, cosmetics and pharmaceutical
                  industries.
                </p>
                <p>
                  At your request, we are able to develop technical
                  documentation and produce dry extracts or powders from any
                  wild-growing sources in Siberia which are famous for having
                  high stimulating properties and naturally activate the innate
                  vigor of the human and animal body.
                </p>
              </ShowingBlock>
            </Wrapper>
            <MediaQuery minWidth={1128}>
              <BlockImage
                src="/whatcanwedo_pic.webp"
                className={isWhatOpened ? "opened" : null}
              />
            </MediaQuery>
          </ReversedAboutBlock>

          <AboutBlock>
            <Wrapper>
              <AboutTitle
                className={isLocationOpened ? "opened" : null}
                onClick={() => setIsLocationOpened(!isLocationOpened)}
              >
                Location and delivery
              </AboutTitle>
              <ShowingBlock className={isLocationOpened ? "opened" : null}>
                <MediaQuery maxWidth={1127}>
                  <BlockImage
                    src="/location_pic.webp"
                    className={isLocationOpened ? "opened" : null}
                  />
                </MediaQuery>
                <p>
                  The company's departments responsible for the foraging and
                  processing of plant materials. Production and quality control
                  is located in Russia. Storage, allocation and shipment are
                  carried out from the terminal in Tallinn (Estonia).
                </p>
                <p>
                  We collaborate with the logistics company Europiir Logistics
                  for more than 3 years by now, whose competence helps our
                  customers to save on shipping costs without sacrificing
                  quality. At your request, we will send the available goods to
                  you by any convenient way from Tallinn, within 2 days after
                  making the payment. The average cumulative production and
                  delivery time for custom-made goods is 35-40 days.
                </p>
              </ShowingBlock>
            </Wrapper>
            <MediaQuery minWidth={1128}>
              <BlockImage
                src="/location_pic.webp"
                className={isLocationOpened ? "opened" : null}
              />
            </MediaQuery>
          </AboutBlock>

          <ReversedAboutBlock>
            <Wrapper>
              <AboutTitle
                className={isEnvironmentalOpened ? "opened" : null}
                onClick={() => setIsEnvironmentalOpened(!isEnvironmentalOpened)}
              >
                Environmental concern
              </AboutTitle>
              <ShowingBlock className={isEnvironmentalOpened ? "opened" : null}>
                <MediaQuery maxWidth={1128}>
                  <BlockImage
                    src="/environmental_pic.webp"
                    className={isEnvironmentalOpened ? "opened" : null}
                  />
                </MediaQuery>
                <p>
                  We recognize the power and fragility of nature, probably, more
                  than anyone else. Therefore, our priority is to take care of
                  the environment, preserve its population and the constant
                  regeneration of all plant species, both those that we collect
                  and those that grow nearby. Therefore, we annually hold events
                  to educate the locals and experienced collectors to care for
                  the world around, telling about plants and animals and their
                  interrelations.
                </p>
              </ShowingBlock>
            </Wrapper>
            <MediaQuery minWidth={1128}>
              <BlockImage
                src="/environmental_pic.webp"
                className={isEnvironmentalOpened ? "opened" : null}
              />
            </MediaQuery>
          </ReversedAboutBlock>

          <div>
            <AboutTitle
              className={isClientsOpened ? "opened" : null}
              onClick={() => setIsClientsOpened(!isClientsOpened)}
            >
              Geography of our clients
            </AboutTitle>

            <Map
              src="/bigmap.webp"
              className={isClientsOpened ? "opened" : null}
            />
          </div>
        </AboutList>
      </Container>
      <Advantages>
        <SliderContainer>
          <Heading>Our advantages</Heading>
          <MediaQuery maxWidth={1127}>
            <Carousel
              infiniteLoop={true}
              showThumbs={false}
              showIndicators={false}
              statusFormatter={(current, total) => `${current} / ${total}`}
              renderArrowPrev={(clickHandler, hasPrev, labelPrev) =>
                hasPrev && (
                  <button onClick={clickHandler} className="prevButton button">
                    <SliderButton variant={"back"} />
                  </button>
                )
              }
              renderArrowNext={(clickHandler, hasNext, labelNext) =>
                hasNext && (
                  <button onClick={clickHandler} className="nextButton button">
                    <SliderButton variant={"next"} />
                  </button>
                )
              }
            >
              <Slide>
                <SlideWrapper>
                  <SlideImageWrapper>
                    <SlideImage src="/advantages-slide1.webp" />
                    <SlideTitle>
                      Keeping up with trends and consumer expectations
                    </SlideTitle>
                    <SlideBg />
                  </SlideImageWrapper>
                  <SlideContent>
                    We are constantly looking for new (wild) plant sources and
                    maximize our efforts to find the purest and richest in terms
                    of active substances content raw materials in to inspire you
                    for creation bew functional products.
                  </SlideContent>
                </SlideWrapper>
              </Slide>

              <Slide>
                <SlideWrapper>
                  <SlideImageWrapper>
                    <SlideImage src="/advantages-slide2.webp" />
                    <SlideTitle>
                      Customer-oriented approach and responsibility
                    </SlideTitle>
                    <SlideBg />
                  </SlideImageWrapper>
                  <SlideContent>
                    We focus on the needs of our customers. Upon request, we
                    conduct additional analyzes, provide or develop necessary
                    documentation, produce ingredients with the parameters you
                    require.
                  </SlideContent>
                </SlideWrapper>
              </Slide>

              <Slide>
                <SlideWrapper>
                  <SlideImageWrapper>
                    <SlideImage src="/advantages-slide3.webp" />
                    <SlideTitle>
                      Excellent product quality and safety
                    </SlideTitle>
                    <SlideBg />
                  </SlideImageWrapper>
                  <SlideContent>
                    We check raw materials and final products by laboratory
                    testing starting from raw material preparation to finished
                    extract. All the products comply with the EU safety and
                    quality standards.
                  </SlideContent>
                </SlideWrapper>
              </Slide>

              <Slide>
                <SlideWrapper>
                  <SlideImageWrapper>
                    <SlideImage src="/advantages-slide4.webp" />
                    <SlideTitle>Full-cycle production</SlideTitle>
                    <SlideBg />
                  </SlideImageWrapper>
                  <SlideContent>
                    We have established a full-cycle production - starting from
                    the search and foraging of raw materials and end up
                    ingredients delivery to the customer location. This grants
                    you stable prices, consistent quality and sustainable
                    supplies.
                  </SlideContent>
                </SlideWrapper>
              </Slide>

              <Slide>
                <SlideWrapper>
                  <SlideImageWrapper>
                    <SlideImage src="/advantages-slide5.webp" />
                    <SlideTitle>Together we create the future</SlideTitle>
                    <SlideBg />
                  </SlideImageWrapper>
                  <SlideContent>
                    We pursue a common goal - to help consumers fill their lives
                    with inexhaustible energy, vigorous health and strength of
                    wild nature, without separation out of the groove of
                    everyday life and comfortable lifestyle in a modern
                    metropolis.
                  </SlideContent>
                </SlideWrapper>
              </Slide>
            </Carousel>
          </MediaQuery>

          <MediaQuery minWidth={1128}>
            <Carousel
              infiniteLoop={true}
              showThumbs={false}
              showIndicators={false}
              statusFormatter={(current, total) => `${current} / ${total}`}
              renderArrowPrev={(clickHandler, hasPrev, labelPrev) =>
                hasPrev && (
                  <button onClick={clickHandler} className="prevButton button">
                    <SliderButton variant={"back"} />
                  </button>
                )
              }
              renderArrowNext={(clickHandler, hasNext, labelNext) =>
                hasNext && (
                  <button onClick={clickHandler} className="nextButton button">
                    <SliderButton variant={"next"} />
                  </button>
                )
              }
            >
              <Slide>
                <SlideWrapper>
                  <SlideImageWrapper>
                    <SlideImage src="/advantages-slide1.webp" />
                  </SlideImageWrapper>
                  <SlideContent>
                    <SlideTitle>
                      Keeping up with trends and consumer expectations
                    </SlideTitle>
                    <SlideDescription>
                      We are constantly looking for new (wild) plant sources and
                      maximize our efforts to find the purest and richest in
                      terms of active substances content raw materials in to
                      inspire you for creation bew functional products.
                    </SlideDescription>
                  </SlideContent>
                </SlideWrapper>
              </Slide>

              <Slide>
                <SlideWrapper>
                  <SlideImageWrapper>
                    <SlideImage src="/advantages-slide2.webp" />
                  </SlideImageWrapper>
                  <SlideContent>
                    <SlideTitle>
                      Customer-oriented approach and responsibility
                    </SlideTitle>
                    <SlideDescription>
                      We focus on the needs of our customers. Upon request, we
                      conduct additional analyzes, provide or develop necessary
                      documentation, produce ingredients with the parameters you
                      require.
                    </SlideDescription>
                  </SlideContent>
                </SlideWrapper>
              </Slide>

              <Slide>
                <SlideWrapper>
                  <SlideImageWrapper>
                    <SlideImage src="/advantages-slide3.webp" />
                  </SlideImageWrapper>
                  <SlideContent>
                    <SlideTitle>
                      Excellent product quality and safety
                    </SlideTitle>
                    <SlideDescription>
                      We check raw materials and final products by laboratory
                      testing starting from raw material preparation to finished
                      extract. All the products comply with the EU safety and
                      quality standards.
                    </SlideDescription>
                  </SlideContent>
                </SlideWrapper>
              </Slide>

              <Slide>
                <SlideWrapper>
                  <SlideImageWrapper>
                    <SlideImage src="/advantages-slide4.webp" />
                  </SlideImageWrapper>
                  <SlideContent>
                    <SlideTitle>Full-cycle production</SlideTitle>
                    <SlideDescription>
                      We have established a full-cycle production - starting
                      from the search and foraging of raw materials and end up
                      ingredients delivery to the customer location. This grants
                      you stable prices, consistent quality and sustainable
                      supplies.
                    </SlideDescription>
                  </SlideContent>
                </SlideWrapper>
              </Slide>

              <Slide>
                <SlideWrapper>
                  <SlideImageWrapper>
                    <SlideImage src="/advantages-slide5.webp" />
                  </SlideImageWrapper>
                  <SlideContent>
                    <SlideTitle>Together we create the future</SlideTitle>
                    <SlideDescription>
                      We pursue a common goal - to help consumers fill their
                      lives with inexhaustible energy, vigorous health and
                      strength of wild nature, without separation out of the
                      groove of everyday life and comfortable lifestyle in a
                      modern metropolis.
                    </SlideDescription>
                  </SlideContent>
                </SlideWrapper>
              </Slide>
            </Carousel>
          </MediaQuery>
        </SliderContainer>
      </Advantages>
    </Page>
  );
}
