import React, { useContext, useState } from "react";
import styled from "styled-components";
import MainSectionDesk from "./subcomponents/MainSectionDesk";
import Tabs from "./subcomponents/Tabs";
import RoundedButton from "../../Interface/Buttons/RoundedButton";
import ImagePreview from "./subcomponents/ImagePreview";
import useScrollBlock from "../../../Functional/useScrollBlock";
import Modal from "../../Interface/Modal";
import useModal from "../../../Functional/useModal";
import { getFileUrl } from "../../../../api/productAPI";
import { Context } from "../../../..";
import { useNavigate } from "react-router-dom";
const Container = styled.div`
  margin: 0 auto;
  width: 1128px;
  &.unscroll {
    overflow: hidden;
    height: 100%;
  }
`;

const Wrapper = styled.div`
  background: #ffffff;
  border: 1px solid #eaebec;
  border-radius: 6px;
  box-sizing: border-box;
  width: 850px;
  padding: 25px;
  position: relative;
  margin-top: 20px;
`;

const Shadow = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #000000;
  opacity: 0.6;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 15;
  overflow: hidden;
`;

const TabsWrapper = styled(Wrapper)`
  margin-bottom: 30px;
  padding-top: 0px;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Question = styled.div`
  background: #ffffff;
  border: 1px solid #eaebec;
  border-radius: 6px;
  align-items: flex-start;
  width: 314px;
  height: 90px;
  box-sizing: border-box;
  padding: 25px;
  margin-top: 20px;
`;

const Button = styled.button`
  width: 100%;
  height: 100%;
  border: 1px solid #40bf6a;
  border-radius: 4px;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #40bf6a;
  background-color: transparent;
  font-weight: 700;
  &:hover,
  &:focus,
  &:active {
    color: #ffffff;
    background: #40bf6a;
  }
`;
const Desktop = ({ product, gallery }) => {
  const [shadowOpen, setShadowOpen] = useState(false);
  const [image, setImage] = useState(false);
  const [imageOpen, setImageOpen] = useState(false);
  const { isShowing, toggle } = useModal();
  const [blockScroll, allowScroll] = useScrollBlock();
  const { user } = useContext(Context);
  const navigate = useNavigate();

  return (
    <Flex>
      <Modal
        type={"question"}
        isShowing={isShowing}
        hide={toggle}
        item={product.title}
        link={product.url}
        image={getFileUrl(product.img)}
        allow={allowScroll}
      />
      {shadowOpen ? (
        <Shadow
          scroll="no"
          onClick={() => {
            setShadowOpen(false);
            setImageOpen(false);
            allowScroll();
          }}
        />
      ) : null}
      {image && imageOpen ? (
        <ImagePreview
          scroll="no"
          image={image}
          onClick={() => {
            setImageOpen(false);
            setShadowOpen(false);
            allowScroll();
          }}
        />
      ) : null}
      <Container>
        <Wrapper>
          <MainSectionDesk
            id={product.id}
            sku={product.sku}
            title={product.title}
            moq={product.moq}
            price={product.price}
            gallery={gallery}
            img={product.img}
            status={product.status}
            stock={product.stock}
            step={product.qty_step}
            orderStatus={product.status}
            attributeId={product.attributeId ? product.attributeId : []}
            prices={product.prices ? product.prices : false}
            url={product.url}
            onClick={(e) => {
              setImage(e);
              setImageOpen(!imageOpen);
              setShadowOpen(!shadowOpen);
              blockScroll();
            }}
          />
        </Wrapper>
        <TabsWrapper>
          <Tabs
            documents={product.documents ? product.documents : []}
            description={product.description}
            onClick={() => {
              setShadowOpen(!shadowOpen);
              blockScroll();
            }}
          />
        </TabsWrapper>
      </Container>
      <Question>
        {user.isAuth ? <Button
          onClick={() => {
            navigate(`/account/new-ticket/item=${product.title}`);
          }}
        >
          Ask question
        </Button> : <Button
          onClick={() => {
            blockScroll();
            toggle();
          }}
        >
          Ask question
        </Button>}
      </Question>
    </Flex>
  );
};

export default Desktop;
