import { $authHost } from ".";
//fetch basket by basket id
export const fetchBasket = async (id) => {
    try {
        const { data } = await $authHost.get(`api/basket/${id}`);
        return data;
    } catch (e) {
        return e.response.data;
    }
}
// update busket by id, sending body
export const addBasketItem = async (body) => {
    try {
        const { data } = await $authHost.post('api/basket', body);
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const removeBasketItem = async (body) => {
    try {
        const { data } = await $authHost.post('api/basket/remove', body);
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const updateBasket = async (body) => {
    try {
        const { data } = await $authHost.post('api/basket/update', body);
        return data;
    } catch (e) {
        return e.response.data;
    }
}


export const getCheckoutData = async (id) => {
    try {
        const { basket } = await $authHost.get(`api/basket/${id}`);
        const { address } = await $authHost.get(`api/address/shipping/${id}`);
        return basket, address;
    } catch (e) {
        return e.response.data;
    }
}