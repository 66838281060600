import React, { useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Context } from "../../../../..";

const Container = styled.div`
margin-top: 10px;
width: 100%;
display: flex;
align-items: center;
background: #F3F7F2;
justify-content: center;
@media screen and (min-width: 1128px){
margin-top: 0px !important;   
}
`

const Text = styled.p`
margin: 0px;
font-family: 'Proxima Nova';
font-style: normal;
font-weight: 700;
font-size: 13px;
line-height: 14px;
padding: 15px 20px;
margin-bottom: 5px;
`

const GoTo = styled(Link)`
color: #18A0FB;
text-decoration: underline;
`

const AuthNotify = () => {
    const { user } = useContext(Context)
    if (!user.isAuth) {
        return (
            <Container>
                <Text>Please <GoTo to={'/registration'}>register</GoTo> or <GoTo to={'/login'}>login</GoTo> and you will be able to see prices, an extended description of the goods, as well as technical documentation</Text>
            </Container>
        )
    }
    else {
        return null
    }
}

export default AuthNotify
