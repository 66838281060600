import React, { useState } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { registration } from "../api/userAPI";
import DefaultButton from "../components/VitaforestUI/Interface/Buttons/DefaultButton";
import { Country, State, City } from "country-state-city";
import Input from "../components/VitaforestUI/Interface/Input/Input";
import Select from "../components/VitaforestUI/Interface/Select/Select";
import Header from "../components/VitaforestUI/Interface/Header/Header";
import Footer from "../components/VitaforestUI/Interface/Footer/Footer";
import MediaQuery from "react-responsive";
import { Button } from "react-bootstrap";
import { checkVAT, countries } from 'jsvat';
import { NotificationContainer, NotificationManager } from "react-notifications";
import { Helmet } from "react-helmet";



const PageContainer = styled.div`
  font-family: "Proxima Nova";
  font-style: normal;
  box-sizing: border-box;
  @media screen and (min-width: 1128px) {
    display: flex;
    height: 100vh;
  }
`;
const ButtonContainer = styled.div`
  margin: 15px auto;
  button {
    display: block;
    margin: 0 auto;
  }
`;
const Heading = styled.h2`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 17px;
  color: #303236;
  padding-bottom: 8px;
  margin-top: 30px;
  border-bottom: 1px solid #303236;
`;
const SubHeading = styled.h3`
  font-weight: 700;
  font-size: 13px;
  line-height: 14px;
  color: #40bf6a;
  margin-top: 25px;
  margin-bottom: 15px;
  @media screen and (min-width: 1128px) {
    width: 100%;
  }
`;
const FormContainer = styled.div`
  @media screen and (min-width: 1128px) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 40px 42px 22px 76px;
  background-image: url("/regbg.webp");
  width: 36vw;
  background-size: cover;
  .logo {
    width: 191px;
    height: 49px;
  }
`;

const Socials = styled.div`
  margin-top: auto;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  img {
    padding-right: 10px;
  }
`;

const Copyright = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #eaebec;
  justify-content: space-between;
`;

const Privacy = styled(Link)`
  text-align: right;
  text-decoration-line: underline;
  color: inherit;
`;

const MainForm = styled.form`
  margin: 20px auto;
  width: calc(100vw - 36px);
  @media screen and (min-width: 1128px) {
    margin: 0px;
    width: 64vw;
    height: 100vh;
    box-sizing: border-box;
    padding: 40px 86px;
    overflow: scroll;
  }
`;

const BackButton = styled.button`
  padding: 12px 24px 12px 42px;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: #3c3f54;
  border: 2px solid #3c3f54;
  background-color: #ffffff;
  border-radius: 4px;
  position: relative;
  display: block;
  margin-left: auto;
  &:before {
    content: url("/arrow-reg.svg");
    position: absolute;
    left: 19px;
    top: 12px;
  }
`;

const InputContainer = styled.div`
  @media screen and (min-width: 1128px) {
    width: calc(50% - 12px);
  }
`;

const LogoLink = styled(Link)`
`

const Registration = () => {
  const [firstName, setfirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [birthday, setBirthday] = useState(null);
  const [gender, setGender] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [website, setWebsite] = useState(null);
  const [regisrty, setRegistry] = useState(null);
  const [vat, setVat] = useState(null);
  const [country, setCountry] = useState(null);
  const [countryIso, setCountryIco] = useState(null);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [zip, setZip] = useState(null);
  const [post, setPost] = useState(null);
  const [phone, setPhone] = useState(null);

  const countriesRaw = Country.getAllCountries();

  const navigate = useNavigate();

  const updateCountry = function (e) {
    setCountry(e.target.value);
    countriesRaw.forEach((elem) => {
      if (elem.name === e.target.value) {
        setCountryIco(elem.isoCode);
      }
    });
  };

  const UpdatedStates = function () {
    if (countryIso) {
      return State.getStatesOfCountry(countryIso);
    } else {
      return State.getStatesOfCountry("AF");
    }
  };

  const prevalidate = () => {
    let result = checkVAT(vat, countries);
    return result.isValid
  }


  const Send = (e) => {
    if (!prevalidate()) {
      NotificationManager.warning('VAT number is not valid, plese check the VAT number', 'VAT not valid')
    }
    else if (!firstName || !lastName || !vat || !country || !regisrty || !zip || !phone || !email || !password || !companyName) {
      NotificationManager.warning('Please fill all required fields', 'Warning')
    }
    else {
      let formData = new FormData();
      formData.append("first_name", firstName);
      formData.append("last_name", lastName);
      formData.append("email", email);
      formData.append("password", password);
      formData.append("birthDate", birthday);
      formData.append("gender", gender);
      formData.append("role_name", "user");
      formData.append("companyName", companyName);
      formData.append("registryCode", regisrty);
      formData.append("vat", vat);
      formData.append("country", country);
      formData.append("website", website);
      formData.append("state", state);
      formData.append("city", city);
      formData.append("zip", zip);
      formData.append("post", post);
      formData.append("phone", phone);
      registration(formData).then((data) => {
        console.log(data);
        if (data.id) {
          NotificationManager.success('New account registered! You will be able to log in after your account is verified. Expect an email.', 'Registered')
          setTimeout(() => {
            navigate('/login');
          }, 5000);
        }
        else {
          NotificationManager.error(data.message, 'Error');
        }
      });
    }
  };

  return (
    <PageContainer>
      <Helmet>
        <title>Registration - Vitaforest</title>
        <meta name="description" content={'Vitaforest registration page'} />
      </Helmet>
      <NotificationContainer />
      <MediaQuery maxWidth={1127}>
        <Header />
      </MediaQuery>
      <MediaQuery minWidth={1128}>
        <LeftColumn>
          <LogoLink to={'/'}><img className="logo" src="/LogoLight.svg" /></LogoLink>
          <Socials>
            <Link to="https://www.facebook.com/vitaforestfood/">
              <img src="/Facebook.svg" />
            </Link>
            <Link to="https://www.linkedin.com/company/vitaforestfood/">
              <img src="/Linkedin.svg" />
            </Link>
          </Socials>
          <Copyright>
            <span> © VitaForest 2023</span>
            <Privacy to={'/en/privacy-policy'}>Privacy Policy</Privacy>
          </Copyright>
        </LeftColumn>
      </MediaQuery>
      <MainForm>
        <MediaQuery minWidth={1128}>
          <BackButton onClick={() => navigate('/login')}>Back</BackButton>
        </MediaQuery>
        <form onSubmit={(e) => e.preventDefault()}>
          <Heading>New customer registration</Heading>
          <FormContainer>
            <SubHeading>Personal information</SubHeading>
            <InputContainer>
              <Input
                label="First name"
                placeholder="Your name"
                type="text"
                onChange={(e) => {
                  setfirstName(e.target.value);
                }}
                required="true"
              />
            </InputContainer>
            <InputContainer>
              <Input
                label="Last name"
                placeholder="Your last name"
                type="text"
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
                required="true"
              />
            </InputContainer>
            <InputContainer>
              <Input
                label="Date of birth"
                placeholder="dd/mm/yy"
                type="date"
                onChange={(e) => {
                  setBirthday(e.target.value);
                }}
              />
            </InputContainer>
            <InputContainer>
              <Select
                label="Gender"
                options={[
                  "male",
                  "female",
                  "transgender",
                  "gender neutral",
                  "non-binary",
                  "agender",
                  "pangender",
                  "genderqueer",
                  "two-spirit",
                  "third gender",
                  "and all",
                  "none or a combination of these",
                ]}
                name="gender"
                onChange={(e) => {
                  setGender(e.target.value);
                }}
              />
            </InputContainer>
          </FormContainer>
          <FormContainer>
            <SubHeading>Company information</SubHeading>
            <InputContainer>
              <Input
                label="Company name"
                placeholder="Company"
                type="text"
                onChange={(e) => {
                  setCompanyName(e.target.value);
                }}
                required="true"
              />
            </InputContainer>
            <InputContainer>
              <Input
                label="Company website"
                placeholder="website.com"
                type="text"
                onChange={(e) => {
                  setWebsite(e.target.value);
                }}
              />
            </InputContainer>
            <InputContainer>
              <Input
                label="Registry code"
                placeholder="_ _ _ _ _ _"
                type="text"
                onChange={(e) => {
                  setRegistry(e.target.value);
                }}
              />
            </InputContainer>
            <InputContainer>
              <Input
                label="Tax/VAT number"
                placeholder="_ _ _ _ _ _"
                type="text"
                onChange={(e) => {
                  setVat(e.target.value);
                }}
                required="true"
              />
            </InputContainer>
            <InputContainer>
              <Select
                label="Country"
                options={countriesRaw}
                name={country}
                type="country"
                onChange={(e) => {
                  updateCountry(e);
                }}
                required="true"
              />
            </InputContainer>
            <InputContainer>
              <Select
                label="State/province"
                options={UpdatedStates()}
                name={country}
                type="country"
                onChange={(e) => {
                  setState(e.target.value);
                }}
                required="true"
              />
            </InputContainer>
            <InputContainer>
              <Input
                label="City"
                placeholder="Your city"
                type="text"
                onChange={(e) => {
                  setCity(e.target.value);
                }}
              />
            </InputContainer>
            <InputContainer>
              <Input
                label="Zip/postal code"
                placeholder="_ _ _ _ _ _"
                type="text"
                onChange={(e) => {
                  setZip(e.target.value);
                }}
                required="true"
              />
            </InputContainer>
            <InputContainer>
              <Input
                label="Post address"
                placeholder="Your post address"
                type="text"
                onChange={(e) => {
                  setPost(e.target.value);
                }}
                required="true"
              />
            </InputContainer>
            <InputContainer>
              <Input
                label="Phone number"
                placeholder="+ _ _ _ (_ _ _)"
                type="text"
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
                required="true"
              />
            </InputContainer>
          </FormContainer>
          <FormContainer>
            <SubHeading>Sign In information</SubHeading>
            <InputContainer>
              <Input
                label="Email"
                placeholder="youraddress@yourmail.com"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                required="true"
              />
            </InputContainer>
            <InputContainer>
              <Input
                label="Password"
                placeholder="• • • • • • • •"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                required="true"
              />
            </InputContainer>
            <InputContainer>
              <Input
                label="Confirm Password"
                placeholder="• • • • • • • •"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                required="true"
              />
            </InputContainer>
          </FormContainer>
          <ButtonContainer>
            <DefaultButton
              title="Create an account"
              onClick={(e) => { e.preventDefault(); Send(); }}
              width="248px"
              type={'submit'}
            />
          </ButtonContainer>
        </form>
      </MainForm>
      <MediaQuery maxWidth={1127}>
        <Footer />
      </MediaQuery>
    </PageContainer>
  );
};

export default Registration;
