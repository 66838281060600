import React, { useEffect, useState } from "react";
import { Form, Row, Col, Card, Button } from "react-bootstrap";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { fetchCategories } from "../../../../api/productAPI";
import { createNewUrl } from "../../../../api/urlsApi";
import AttributesManager from "../../AttributesManager/AttributesManager";
import AdminUI from "../../Ui/AdminUI";

const AddUrl = () => {

    const navigate = useNavigate();

    // seo states
    const [seoTitle, setSeoTitle] = useState('');
    const [seoDesc, setSeoDesc] = useState('');
    const [seoKeywords, setSeoKeywords] = useState('');
    const [url, setUrl] = useState('');
    const [selectedAttrs, setSelectedAttrs] = useState([]);
    const [error, setError] = useState(null);
    const [selectedCategories, setSelectedCategories] = useState([]);

    const [categoryList, setCategoryList] = useState([]);
    const [catsIsLoaded, setCatsIsLoaded] = useState(false);

    useEffect(() => {
        let result = url.replace(/\s+/g, '-').toLowerCase();
        setUrl(result);
    }, [url])

    useEffect(() => {
        fetchCategories().then((data) => {
            setCategoryList(data);
            setCatsIsLoaded(true);
        }, (error) => {
            setCatsIsLoaded(false);
            setError(error);
        })
    }, [])

    const createUrl = () => {
        let formdata = new FormData();
        formdata.append('url', url);
        formdata.append('seo_title', seoTitle);
        formdata.append('seo_description', seoDesc);
        formdata.append('seo_keywords', seoKeywords);
        formdata.append('attributeId', JSON.stringify(selectedAttrs));
        formdata.append('categoryId', JSON.stringify(selectedCategories));
        createNewUrl(formdata).then((data) => {
            if (data.id) {
                NotificationManager.success('New url created', 'Success');
                navigate('/admin/products/urls');
            }
            else {
                NotificationManager.success(data.message, 'Error');
            }
        })
    }

    // category handle
    const categoryHandler = (id) => {
        let item = selectedCategories.indexOf(id);
        if (item >= 0) {
            let prev = selectedCategories;
            prev.splice(item, 1);
            setSelectedCategories(prev);
        }
        else {
            let prev = selectedCategories;
            prev.push(id);
            setSelectedCategories(prev);
        }
    }


    return (
        <AdminUI>
            <NotificationContainer />
            <Button onClick={() => { createUrl() }}>Create</Button>
            <h1>Shop custom url controller</h1>
            <Row>
                <h2>Url seo data</h2>
                <Col>
                    <Form.Group className="mb-2">
                        <Form.Label>URL</Form.Label>
                        <Form.Control type="text" placeholder="Enter product url" value={url} onChange={(e) => setUrl(String(e.target.value))}></Form.Control>
                        <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Label>SEO Title</Form.Label>
                        <Form.Control type="text" placeholder="SEO Title" value={seoTitle} onChange={(e) => setSeoTitle(String(e.target.value))}></Form.Control>
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Label>SEO Description</Form.Label>
                        <Form.Control type="text" placeholder="SEO Description" value={seoDesc} onChange={(e) => setSeoDesc(String(e.target.value))}></Form.Control>
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Label>SEO Keywords</Form.Label>
                        <Form.Control type="text" placeholder="SEO Keywords" value={seoKeywords} onChange={(e) => setSeoKeywords(String(e.target.value))}></Form.Control>
                        <Form.Text className="text-muted">List the key words, separated by commas</Form.Text>
                    </Form.Group>
                </Col>
                <Col>
                    <AttributesManager selected={selectedAttrs} setSelected={setSelectedAttrs} mode={'post'} className='mb-3' />
                    <Card className="mb-3">
                        <Card.Header>Categories</Card.Header>
                        <Card.Body>
                            {categoryList.map((item) => {
                                return (
                                    <Form.Check key={item.id} type={"checkbox"}>
                                        <Form.Check.Input
                                            type={"checkbox"}
                                            onClick={() => { categoryHandler(item.id); }}
                                        />
                                        <Form.Check.Label>{item.name}</Form.Check.Label>
                                    </Form.Check>
                                );
                            })}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </AdminUI>
    )
}

export default AddUrl