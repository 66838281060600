import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { fetchAttributes } from "../../../../../api/attributeAPI";

const AttributesTable = styled.table`
  font-size: 14px;
  line-height: 17px;
  width: 100%;
  @media screen and (min-width: 1128px) {
    margin-top: 50px;
  }
  tr {
    display: flex;
    min-height: 33px;
    align-items: flex-start;
  }
  tr:nth-child(odd) {
    background: #f7f8f9;
    border-radius: 2px;
  }
`;

const AttributeName = styled.td`
  font-weight: 600;
  padding-left: 15px;
  padding-bottom: 8px;
  padding-top: 8px;
  color: #303236;
  vertical-align: top;
  width: 50%;
  @media screen and (min-width: 1128px) {
    margin-right: 10px;
    width: 110px;
  }
`;
const AttributeValue = styled.td`
  padding-left: 10px;
  padding-right: 15px;
  padding-bottom: 8px;
  padding-top: 8px;
  width: 50%;
  @media screen and (min-width: 1128px) {
    margin-right: 10px;
    width: 660px;
  }
`;
const Attributes = ({ attributeId }) => {
  const [attributes, setAttributes] = useState([]);
  useEffect(() => {
    fetchAttributes().then(data => {
      const array = attributeId.reduce((acc, id) => {
        const attr = data.find(a => a.terms.find(t => t.id === Number(id)));
        if (!attr) return acc;

        const existingAttr = acc.find(item => item.attrName === attr.name);
        if (existingAttr) {
          existingAttr.termName.push(', ' + attr.terms.find(t => t.id === Number(id)).name);
        } else {
          acc.push({ attrName: attr.name, termName: [attr.terms.find(t => t.id === Number(id)).name] });
        }
        return acc;
      }, []);

      setAttributes(array);
    });
  }, []);

  return (
    <AttributesTable>
      {attributes.map((item) => {
        return (
          <tr>
            <AttributeName>{item.attrName}:</AttributeName>
            <AttributeValue>{item.termName}</AttributeValue>
          </tr>
        );
      })}
    </AttributesTable>
  );
}

export default Attributes
