import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { getTicket, sendTicketMessage } from "../../../../api/ticketAPI";
import Page from "../../Interface/Page/Page";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import AccountMenu from "../subcomponents/AccountMenu";
import PageHeader from "../subcomponents/PageHeader";
import dayjs from "dayjs";
import Textarea from "../../Interface/Textarea/Textarea";
import DefaultButton from "../../Interface/Buttons/DefaultButton";
import { Context } from "../../../..";
import { NotificationContainer, NotificationManager } from "react-notifications";
import LoadingPlaceholder from "../../../Functional/LoadingPlaceholder";

const TemporaryHide = styled.div`
  @media screen and (max-width: 1127px) {
    display: none;
  }
`;
const Container = styled.div`
  margin-top: 20px;
  @media screen and (min-width: 1128px) {
    margin-top: 30px;
    margin-bottom: 40px;
    display: flex;
    width: 1128px;
    margin: 30px auto;
  }
`;

const Wrapper = styled.div`
position: relative;
  width: calc(100vw - 36px);
  margin: 0 auto;
  @media screen and (min-width: 1128px) {
    width: 840px;
    margin: 0;
  }
`;

const Flex = styled.div`
  display: flex;
  margin-top: 15px;
  font-size: 12px;
  line-height: 19px;
  color: #7f878b;
  @media screen and (min-width: 1128px) {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
  }
`;

const Type = styled.p`
  width: 50%;
  text-align: center;
  @media screen and (min-width: 1128px) {
    text-align: left;
    width: max-content;
    margin-right: 20px;
  }
`;

const Date = styled.p`
  width: 50%;
  text-align: center;
  @media screen and (min-width: 1128px) {
    text-align: left;
    width: max-content;
  }
`;

const Messages = styled.div``;

const Message = styled.div`
  padding-bottom: 15px;
  margin-bottom: 20px;
  box-shadow: 0px 1px 0px #eaebec;
  @media screen and (min-width: 1128px) {
    background: #ffffff;
    border: 1px solid #eaebec;
    border-radius: 6px;
    box-sizing: border-box;
    padding: 25px;
    box-shadow: none;
  }
`;

const Title = styled.h4`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #303236;
  margin-bottom: 12px;
`;

const MessageDate = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #7f878b;
  margin-bottom: 20px;
`;

const MobileBackButton = styled(Link)`
&:before{
  content: url('/arrow-back.svg');
}
text-decoration: none;
color: black;
position: absolute;
top: -5px;
margin-left: 0px;
vertical-align: center;
@media screen and (min-width: 1128px){
  display: none;
}
&:hover{
  color: inherit;
}
&:active{
  color: inherit;
}
`;

const MessageContent = styled.p``;

const NewMessage = styled.div``;

const Button = styled(DefaultButton)`
  margin: 25px auto;
`;
const SingleTicket = () => {
  // data
  const { id } = useParams();
  const { user } = useContext(Context);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [ticket, setTicket] = useState([]);
  const [message, setMessage] = useState('');
  const [needReload, setNeedReload] = useState(false);

  const newMessage = () => {
    if (message.length > 1) {
      let formdata = new FormData();
      formdata.append('senderName', `${user.user.first_name} ${user.user.last_name}`);
      formdata.append('senderId', user.user.id);
      formdata.append('ticketId', id);
      formdata.append('message', message);
      sendTicketMessage(formdata).then((data) => {
        if (data.id) {
          NotificationManager.success(`Your message added into ticket #${data.id}`, 'Success');
          setMessage('');
          setNeedReload(!needReload);
        }
        else {
          NotificationManager.error(`${data.message}`, 'Error');
        }
      })
    }
    else {
      NotificationManager.error(`Nothing to send`, 'Error');
    }
  }

  // fetching ticket list
  useEffect(() => {
    getTicket(id).then(
      (data) => {
        setTicket(data);
        setIsLoaded(true);
      },
      (e) => {
        setError(e);
        setIsLoaded(false);
      }
    );
  }, [needReload]);
  if (error) {
    return (
      <Page>
        <h5>{error}</h5>
      </Page>
    );
  } else if (!isLoaded) {
    return (
      <Page>
        <LoadingPlaceholder />
      </Page>
    );
  } else {
    return (
      <Page background={false} seo_title={`Vitaforest - Account - New Ticket`} seo_description={'Vitaforest create new ticket'}>
        <NotificationContainer />
        <Container>
          <TemporaryHide>
            <AccountMenu />
          </TemporaryHide>
          <Wrapper>
            <MobileBackButton to={'/account/tickets'} />
            <PageHeader>{ticket.subject}</PageHeader>
            <Flex>
              <Type>{ticket.status}</Type>
              <Date>
                {"From "}
                {dayjs(ticket.createdAt).format("MMM DD") + ", "}
                {dayjs(ticket.createdAt).format("YYYY")}
              </Date>
            </Flex>
            <Messages>
              {ticket.messages.map((item) => {
                return (
                  <Message>
                    <Title>{item.senderName}</Title>
                    <MessageDate>
                      {dayjs(item.createdAt).format("MM.DD.YYYY, HH:mm")}
                    </MessageDate>
                    <MessageContent>{item.message}</MessageContent>
                  </Message>
                );
              })}
            </Messages>
            <NewMessage>
              <Textarea
                label="Add new message"
                value={message}
                requied={true}
                placeholder="Jot us a note and we’ll get back to you as quickly as possible"
                onChange={(e) => setMessage(e.target.value)}
              />
              <Button title="Send" onClick={() => { newMessage() }} />
            </NewMessage>
          </Wrapper>
        </Container>
      </Page>
    );
  }
};

export default SingleTicket;
