import React from "react";
import styled from "styled-components";
const Radio = styled.div`
  position: relative;
  .custom-checkbox + label::before {
    content: url("/radio.svg");
    position: absolute;
    left: 0px;
  }
  .custom-checkbox:checked + label::before {
    content: url("/radio-checked.svg");
  }
  .custom-checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  display: flex;
  justify-content: space-between;
`;

const RadioInput = styled.input``;

const RadioLabel = styled.label`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #303236;
  padding-left: 30px;
`;

const RadioDescription = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #303236;
  margin-left: auto;
`;
const CircleRadio = ({ value, label, description, onChange, checked, onClick }) => {
  return (
    <Radio>
      <RadioInput
        className="custom-checkbox"
        type="radio"
        id={value}
        value={value}
        onChange={onChange}
        checked={checked}
        onClick={onClick}
      />
      <RadioLabel for={value}>{label}</RadioLabel>
      <RadioDescription>{description}</RadioDescription>
    </Radio>
  );
};

export default CircleRadio;
