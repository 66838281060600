import React, { useEffect, useContext, useState } from "react";
import AdminUI from "../../Ui/AdminUI";
import { Button, Row, Table, Col, Form, Card, Container } from "react-bootstrap";
import { fetchProducts } from "../../../../api/productAPI";
import { getAllUsersByAdmin } from "../../../../api/userAPI";
import Select from "react-select";
import LoadingPlaceholder from "../../../Functional/LoadingPlaceholder";
import styled from "styled-components";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { createOffer } from "../../../../api/offerAPI";
import { useNavigate } from "react-router-dom";

const AddOfferSection = styled.div`
display: flex;
h1{
    margin: 0px;
    margin-right: 20px;
}
margin-bottom: 20px;
`

const CreateOffer = () => {

    const [products, setProducts] = useState([]);
    const [users, setUsers] = useState([]);
    const [items, setItems] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [select, setSelect] = useState([]);
    const [userSelect, setUserSelect] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedItems, setSelectedItems] = useState([]);
    const [reload, setReload] = useState(false);
    const [name, setName] = useState(null);
    const [offerFor, setOfferFor] = useState(null);
    const [userId, setUserId] = useState(null);
    const [offerType, setOfferType] = useState('one-time');
    const [multiQty, setMultiQty] = useState(1);

    const nav = useNavigate();


    const changeValues = (key, value, id) => {
        setSelectedItems(
            selectedItems.map((i) => (i.id === id ? { ...i, [key]: value } : i))
        );
    };


    const selectItem = (change) => {
        setSelectedItem(change.value);
    }

    const selectFor = (change) => {
        setOfferFor(change.value);
    }

    const selectType = (change) => {
        if (change.value == 'one-time') {
            setOfferType(change.value);
        }
        else if (change.value == 'multiple') {
            setOfferType(`multiple=${multiQty}`);
        }
    }

    const setUser = (change) => {
        setUserId(change.value);
    }

    // create new offer
    const createNew = () => {
        let formdata = new FormData();
        formdata.append('items', JSON.stringify(selectedItems));
        formdata.append('name', name);
        formdata.append('type', offerType);
        if (offerFor == 'single') {
            formdata.append('userId', userId);
            createOffer(formdata, 'single').then((data) => {
                if (data.id) {
                    NotificationManager.success('Offer created', 'Success');
                    setTimeout(() => {
                        nav('/admin/offers');
                    }, 1500)
                }
            })
        }
        else if (offerFor == 'forAll') {
            createOffer(formdata, 'multi').then((data) => {
                if (data.multi_created == true) {
                    NotificationManager.success('Offer created', 'Success');
                    setTimeout(() => {
                        nav('/admin/offers');
                    }, 1500)
                }
            })
        }
    }

    const addItem = (id) => {
        let exist = false;
        selectedItems.find((elem) => {
            if (elem.id == id) {
                NotificationManager.warning('Already added in offer', 'Warning');
                exist = true;
            }
        })
        let prevState = selectedItems;
        if (!exist) {
            products.find((product) => {
                if (product.id == id) {
                    prevState.push(product);
                    setSelectedItems(prevState);
                    setReload(!reload)
                }
            })
        }
        else {
            return false;
        }
    }

    // fetching data 
    useEffect(() => {
        fetchProducts([], []).then((data) => {
            let select_attributes = [];
            data.map((item) => {
                select_attributes.push({ value: item.id, label: item.title })
                item.qty = 10;
            })
            setProducts(data);
            setSelect(select_attributes);
        })
        getAllUsersByAdmin().then((data) => {
            setUsers(data);
            let select_users = [];
            data.map((user) => {
                select_users.push({ value: user.id, label: `${user.first_name} ${user.last_name} - ${user.email}` });
            })
            setUserSelect(select_users);
        })
        setIsLoaded(true);
    }, []);


    if (isLoaded) {
        return (
            <AdminUI>
                <NotificationContainer />
                <AddOfferSection>
                    <h1>Create new offer</h1>
                    <Button onClick={() => { createNew() }}>Add new offer</Button>
                </AddOfferSection>
                <Container>
                    <Row>
                        <Col>
                            <h5>Offer name</h5>
                            <Form.Control type="text" onChange={(e) => { setName(e.target.value) }} className="mb-4" />
                            <Table striped bordered variant="dark">
                                <thead>
                                    <tr>                                <th>Product</th>
                                        <th>Qty</th>
                                        <th>Price</th></tr>
                                </thead>
                                <tbody>
                                    {selectedItems.map((item) => {
                                        return (
                                            <tr>
                                                <td>{item.title}</td>
                                                <td><Form.Control type="number" value={item.qty} onChange={(e) => { changeValues('qty', Number(e.target.value), item.id) }} /></td>
                                                <td><Form.Control type="number" value={item.price} onChange={(e) => { changeValues('price', Number(e.target.value), item.id) }} /></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                            <Row>
                                <Col><Select options={select} onChange={selectItem} /></Col>
                                <Col><Button onClick={() => { addItem(selectedItem) }}>Add</Button></Col>
                            </Row>
                        </Col>
                        <Col>
                            <Card className="mb-3">
                                <Card.Header>Offer type</Card.Header>
                                <Card.Body>
                                    <Select options={[{ value: 'one-time', label: 'One time' }, { value: 'multiple', label: 'Multiple' }]} onChange={selectType} />
                                    {offerType.indexOf('multiple') >= 0 ?
                                        <><p style={{ marginBottom: '5px', marginTop: '10px' }}>How much times can use</p>
                                            <Form.Control type="number" onChange={(e) => { setMultiQty(e.target.value) }} min={2} defaultValue={2} /></>
                                        : null}
                                </Card.Body>
                            </Card>
                            <Card className="mb-3">
                                <Card.Header>Offer for</Card.Header>
                                <Card.Body>
                                    <Select options={[{ value: 'single', label: 'Single user' }, { value: 'forAll', label: 'For all users' }]} onChange={selectFor} />
                                </Card.Body>
                            </Card>
                            {offerFor == 'single' ?
                                <Card className="mb-3">
                                    <Card.Header>Select user</Card.Header>
                                    <Card.Body>
                                        <Select options={userSelect} onChange={setUser} />
                                    </Card.Body>
                                </Card>
                                : null}
                        </Col>
                    </Row>
                </Container>
            </AdminUI>
        )
    }
    else {
        return (
            <AdminUI>
                <LoadingPlaceholder />
            </AdminUI>
        )
    }
}

export default CreateOffer