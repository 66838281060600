import { $authHost } from "."

export const createNewPage = async (page) => {
    try {
        const { data } = await $authHost.post(`/api/pages/`, page);
        return data;
    } catch (e) {
        return e.response.data
    }
}

export const getPageContentByUrl = async (url) => {
    try {
        const { data } = await $authHost.get(`/api/pages/url/${url}`);
        return data;
    } catch (e) {
        return e.response.data
    }
}

export const getPagesList = async () => {
    try {
        const { data } = await $authHost.get(`/api/pages/`);
        return data;
    } catch (e) {
        return e.response.data
    }
}

export const getPageById = async (id) => {
    try {
        const { data } = await $authHost.get(`/api/pages/${id}`);
        return data;
    } catch (e) {
        return e.response.data
    }
}

export const removePage = async (id) => {
    try {
        const { data } = await $authHost.post(`/api/pages/remove`, { 'id': id });
        return data;
    } catch (e) {
        return e.response.data
    }
}

export const updatePage = async (page) => {
    try {
        const { data } = await $authHost.post(`/api/pages/update`, page);
        return data;
    } catch (e) {
        return e.response.data
    }
}

