import React, { useContext, useState } from "react";
import styled, { keyframes } from "styled-components";
import { getFileUrl } from "../../../../../api/productAPI";
import WishlistButton from "../../../Interface/WishlistButton/WishlistButton";
import Status from "../../../Interface/Status/Status";
import { ReactComponent as Cart } from "../img/cart.svg";
import { Context } from "../../../../..";
import { addBasketItem, fetchBasket } from "../../../../../api/basketAPI";
import MediaQuery from "react-responsive";
import Counter from "../../../Interface/Counter/Counter";
import { Link } from "react-router-dom";
import useModal from "../../../../Functional/useModal";
import Modal from "../../../Interface/Modal";
import useScrollBlock from "../../../../Functional/useScrollBlock";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { check } from "../../../../../api/userAPI";
import { removeFromWishlist } from "../../../../../api/wishlistAPI";

const LinkElement = styled(Link)`
  text-decoration: none;
  color: inherit;
  &:hover {
    color: black;
  }
`;

const CardContainer = styled.div`
  width: calc(50% - 10px);
  box-shadow: 0px 5px 10px rgba(26, 33, 45, 0.05);
  border-radius: 4px;
  position: relative;
  @media screen and (max-width: 350px) {
    width: 100%;
  }
  @media screen and (min-width: 900px) {
    width: calc(33% - 7px);
  }
`;

const CardImage = styled.img`
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  aspect-ratio: 1.47;
`;
const CardContent = styled.div`
  box-sizing: border-box;
  padding: 10px 15px 1px 15px;
  @media screen and (min-width: 1128px) {
    padding: 10px 15px 15px 15px;
    justify-content: space-between;
    h3 {
      width: 100%;
    }
    button {
      display: inline;
      margin: 0px !important;
    }
  }
`;

const AbsoluteRow = styled.div`
  button {
    position: absolute;
    right: 5px;
    top: 5px;
  }
`;

const Sku = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #7f878b;
  margin: auto 0;
`;

const Title = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #303236;
  margin-bottom: 5px;
  @media screen and (min-width: 1128px) {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 8px;
  }
`;

const Stock = styled.p`
  font-size: 11px;
  line-height: 13px;
  padding: 3px 15px;
  margin-bottom: 0px;
  background: #f3f7f2;
`;

const StockText = styled.span`
  color: #303236;
`;

const StockValue = styled.span`
  font-weight: 600;
  color: #40bf6a;
`;

const StockEmpty = styled(Stock)`
  background: #f7f8f9;
`;

const Button = styled.button`
  padding: 7px 10px;
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
  background: #40bf6a;
  border: none;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  align-items: center;
  span {
    padding-left: 16px;
  }
  &:hover {
    background-color: #61d186;
  }
  &:active {
    background-color: #2e9e53;
  }
`;

const ButtonPreorder = styled(Button)`
  margin: 15px 0px;
`;

const Purchase = styled.div`
  margin-top: 15px;
`;

const PurchaseWithPrice = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  align-items: center;
  margin-bottom: 15px;
  @media screen and (min-width: 1128px) {
    margin-bottom: 0;
  }
`;
const Price = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #303236;
  margin-bottom: 0;
`;

const Flex = styled.div`
  @media screen and (min-width: 1128px) {
    display: flex;
    justify-content: space-between;
  }
`;

const HueviyFlex = styled.div`
  @media screen and (min-width: 1128px) {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }
`;

const CardLogged = ({
  img,
  stock,
  price,
  title,
  sku,
  status,
  id,
  needReload,
  setNeedReload,
  wishlist,
  notificationPush,
  step,
  moq,
  prices,
  url,
}) => {
  // states
  const [value, setValue] = useState(moq);
  const [pricesArray, setPricesArray] = useState(prices ? prices : false);
  const [itemPrice, setItemPrice] = useState(price);
  const { isShowing, toggle } = useModal();
  const [wishModalOpen, setWishModalOpen] = useState(false);
  const [blockScroll, allowScroll] = useScrollBlock();
  const [reload, doReload] = useState(false);

  const { user } = useContext(Context);
  const addToCart = (id, title, status, img, step, moq) => {
    let formdata = new FormData();
    formdata.append("title", title);
    formdata.append("img", img);
    formdata.append("status", status);
    formdata.append("price", itemPrice);
    formdata.append("qty", value);
    formdata.append("item_id", id);
    formdata.append("stock", stock);
    formdata.append("moq", moq);
    formdata.append("qty_step", step);
    formdata.append("prices", JSON.stringify(pricesArray));
    formdata.append("userId", user.user.id);
    addBasketItem(formdata).then((data) => {
      if (data.id) {
        toggle();
        blockScroll();
      } else {
        NotificationManager.error(`${data.message}`, "Error");
      }
    });
  };

  const BoundWish = (wishlist) => {
    let result = [];
    wishlist.map((item) => {
      result.push({ value: item.id, label: item.name });
    });
    return result;
  };

  const wishRemoveHandle = (itemId) => {
    let wId;
    wishlist.map((wl) => {
      wl.items.find((elem) => {
        if (elem.itemId == itemId) {
          wId = wl.id
        }
      })
    })
    removeFromWishlist(itemId, wId).then((data) => {
      if (data.status == 'removed') {
        NotificationManager.success('Item removed from wishlist', 'Success');
        setNeedReload(!needReload);
      }
      else {
        NotificationManager.error(data.message, 'Error');
      }
    })
  }

  const calculatePrice = () => {
    pricesArray.find((item) => {
      if (value >= item.min && value <= item.max) {
        setItemPrice(item.price);
      }
    });
  };

  const CheckInWish = (wish) => {
    let result;
    wish.map((item) => {
      item.items.map((elem) => {
        if (elem.itemId === id) {
          result = true;
        }
      });
    });
    return result;
  };


  return (
    <CardContainer>
      <NotificationContainer />
      <Modal
        type={"add-cart"}
        isShowing={isShowing}
        hide={toggle}
        item={title}
        link={url}
        image={getFileUrl(img)}
        allow={allowScroll()}
      />
      <Modal
        type={"wishlist"}
        isShowing={wishModalOpen}
        hide={() => setWishModalOpen(false)}
        wishlists={BoundWish(wishlist)}
        allow={allowScroll}
        id={id}
        notiManager={NotificationManager}
      />
      <Link to={`/en/product/${url}`}>
        <CardImage src={getFileUrl(img)} />
      </Link>
      {stock ? (
        <Stock>
          <StockText>In stock: </StockText>
          <StockValue>{stock} kg</StockValue>
        </Stock>
      ) : (
        <StockEmpty>Preorder</StockEmpty>
      )}

      <CardContent>
        <Title>
          <LinkElement to={`/en/product/${url}`}>{title}</LinkElement>
        </Title>
        <Flex>
          <Sku className="SKU">{`SKU: ${sku}`}</Sku>
          <MediaQuery minWidth={1128}>
            {itemPrice ? <Price>€{itemPrice}</Price> : null}
          </MediaQuery>
        </Flex>
        {itemPrice ? (
          <PurchaseWithPrice>
            <MediaQuery maxWidth={1127}>
              <Price>€{itemPrice}</Price>
              <Button>
                <Cart />
              </Button>
            </MediaQuery>
            <MediaQuery minWidth={1128}>
              {pricesArray[0] ? (
                <Counter
                  width="100px"
                  value={value}
                  step={step}
                  qty={moq}
                  min={moq}
                  setValue={setValue}
                  calcPrice={calculatePrice}
                />
              ) : (
                <Counter
                  width="100px"
                  value={value}
                  step={step}
                  qty={moq}
                  min={moq}
                  setValue={setValue}
                />
              )}
              <Button
                onClick={() => addToCart(id, title, status, img, step, moq)}
              >
                <Cart />
                <span>Add to cart</span>
              </Button>
            </MediaQuery>
          </PurchaseWithPrice>
        ) : (
          <HueviyFlex>
            <MediaQuery minWidth={1128}>
              {pricesArray[0] ? (
                <Counter
                  width="100px"
                  value={value}
                  step={step}
                  qty={moq}
                  min={moq}
                  setValue={setValue}
                  calcPrice={calculatePrice}
                />
              ) : (
                <Counter
                  width="100px"
                  value={value}
                  step={step}
                  qty={moq}
                  min={moq}
                  setValue={setValue}
                />
              )}
            </MediaQuery>
            <ButtonPreorder
              className="add-to-cart"
              onClick={() => {
                addToCart(id, title, status, img, step, moq);
              }}
            >
              <Cart />
              <span>Preorder</span>
            </ButtonPreorder>
          </HueviyFlex>
        )}
      </CardContent>
      <AbsoluteRow>
        {CheckInWish(wishlist) ? (
          <WishlistButton
            className="wish"
            variant={"liked"}
            onClick={() => {
              wishRemoveHandle(id);
            }}
          />
        ) : (
          <WishlistButton
            className="wish"
            onClick={() => {
              setWishModalOpen(true);
            }}
          />
        )}

        <Status variant={status}></Status>
      </AbsoluteRow>
    </CardContainer>
  );
};

export default CardLogged;
