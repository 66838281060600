import React, { useContext, useEffect, useState } from "react";
import { Context } from "..";
import Page from "../components/VitaforestUI/Interface/Page/Page";
import styled from "styled-components";
import MediaQuery from "react-responsive";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ShareSocial } from "react-share-social";
import Textarea from "../components/VitaforestUI/Interface/Textarea/Textarea";
import { Editor, EditorState } from "draft-js";
import {
  addPostComment,
  decodeEditorBody,
  fetchPostByUrl,
} from "../api/postAPI";
import { Spinner } from "react-bootstrap";
import { getFileUrl } from "../api/productAPI";
import { Helmet } from "react-helmet";
import dayjs from "dayjs";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import LoadingPlaceholder from "../components/Functional/LoadingPlaceholder";
import { getUserSettings } from "../api/userAPI";

const PageContainer = styled.div`
  width: calc(100vw - 36px);
  margin: 20px auto;
  @media screen and (min-width: 1128px) {
    width: 1128px;
  }
`;

const Wrapper = styled.div`
  position: relative;
`;

const BtnBack = styled.button`
  border: none;
  background-color: #fff;
  position: absolute;
  top: 4px;
  &::before {
    content: url("/arrow-back.svg");
  }
  span {
    display: none;
  }
  @media screen and (min-width: 1128px) {
    right: 0;
    &::before {
      content: url("/arrow-back-grey.svg");
      position: absolute;
      left: -13px;
    }
    span {
      display: block;
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
      color: #7f878b;
    }
    &:hover {
      text-decoration-line: underline;
      color: #7f878b;
    }
  }
`;

const Title = styled.h2`
  font-family: "Proxima Nova";
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  padding: 0px 15px;
  @media screen and (min-width: 1128px) {
    text-align: left;
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 28px;
  }
`;

const ContentWrapper = styled.div`
  @media screen and (min-width: 1128px) {
    margin-top: 40px;
    display: flex;
    align-items: flex-start;
  }
`;

const Thumbnail = styled.img`
  border-radius: 4px;
  width: 100%;
  margin-bottom: 10px;
  @media screen and (min-width: 1128px) {
    width: 360px;
    margin-right: 24px;
    margin-bottom: 0px;
  }
`;

const EditorWrapper = styled.div`
  margin-top: -10px;
`;

const CategoryLink = styled(Link)`
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  text-decoration: none;
  color: #40bf6a;
  display: block;
  &:hover {
    color: inherit;
    text-decoration: underline;
    color: #40bf6a;
  }
  &:active {
    color: inherit;
    text-decoration: underline;
    color: #40bf6a;
  }
`;

const DesctopCategoryLink = styled(CategoryLink)``;

const Content = styled.div`
  @media screen and (min-width: 1128px) {
    box-sizing: border-box;
    padding: 13px 25px;
    border: 1px solid #eaebec;
    border-radius: 6px;
    min-width: 744px;
  }
`;

const InteractiveWrapper = styled.div`
  @media screen and (min-width: 1128px) {
    display: flex;
    margin-top: 30px;
    align-items: flex-start;
  }
`;

const Share = styled.div`
  div {
    display: flex;
    justify-content: flex-start;
    padding: 0px;
    .makeStyles-copyContainer-21 {
      display: none;
    }
  }
  @media screen and (min-width: 1128px) {
    width: 360px;
    margin-top: 24px;
    margin-right: 20px;
    border: 1px solid #eaebec;
    border-radius: 6px;
    box-sizing: border-box;
    padding: 25px;
    div {
      justify-content: space-between;
    }
  }
`;

const Comments = styled.div`
  @media screen and (min-width: 1128px) {
    min-width: 744px;
    margin-left: auto;
  }
`;

const CommentsTitle = styled.h4`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #303236;
  margin-top: 50px;
  margin-bottom: 30px;
  @media screen and (min-width: 1128px) {
    font-size: 20px;
    line-height: 17px;
    margin-top: 0px;
    margin-bottom: 20px;
  }
`;

const CommentsList = styled.ul`
  padding: 0px;
`;

const Comment = styled.li`
  list-style: none;
  box-shadow: 0px 1px 0px #eaebec;
  margin-bottom: 20px;
  @media screen and (min-width: 1128px) {
    box-shadow: none;
    box-sizing: border-box;
    padding: 25px;
    border: 1px solid #eaebec;
    border-radius: 6px;
    margin-bottom: 24px;
  }
`;

const CommentUser = styled.h5`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #303236;
  margin-bottom: 10px;
`;

const CommentDate = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #7f878b;
`;
const ShareTitle = styled(CommentsTitle)`
  margin-bottom: 15px;
  @media screen and (min-width: 1128px) {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }
`;

const CommentText = styled.p`
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  padding-bottom: 20px;
  text-align: justify;
  @media screen and (min-width: 1128px) {
    padding-bottom: 0;
  }
`;
const CommentForm = styled.form``;

const CommentsButton = styled.button`
  width: 100%;
  max-width: 284px;
  height: 48px;
  display: block;
  background: ${(props) => (props.isempty ? "#eaebec" : "#40BF6A")};
  border-radius: 4px;
  padding: 12px 24px;
  border: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: ${(props) => (props.isempty ? "#7f878b" : "white")};
  margin: 20px auto;
  @media screen and (min-width: 1128px) {
    max-width: 168px;
    margin-left: auto;
    margin-right: 0px;
    height: 40px;
  }
  &:hover {
    background: ${(props) => (props.isempty ? "#eaebec" : "#61D186")};
  }
  &:active {
    background: ${(props) => (props.isempty ? "#eaebec" : "#2E9E53")};
  }
`;
const Text = styled.p`
  margin: 0px;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 14px;
  padding: 15px 0px;
  margin-bottom: 5px;
`;
const GoTo = styled(Link)`
  color: #18a0fb;
  text-decoration: underline;
`;

export default function Post() {
  const { url } = useParams();
  const { user } = useContext(Context);
  // states
  const [post, setPost] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [description, setDescription] = useState(null);
  const [comment, setComment] = useState("");
  const [settings, setSettings] = useState(null);
  const [needReload, setNeedReload] = useState(false);

  // funcs

  const sendComment = () => {
    let formdata = new FormData();
    if (settings.comments_privacy) {
      formdata.append(
        "username",
        `${'Anonymous'}`
      );
    }
    else {
      formdata.append(
        "username",
        `${user.user.first_name} ${user.user.last_name}`
      );
    }
    formdata.append("userId", user.user.id);
    formdata.append("postId", post.id);
    formdata.append("date", dayjs().format("MMM, DD, YYYY, hh:mm"));
    formdata.append("text", comment);
    addPostComment(formdata).then((data) => {
      if (data.id) {
        NotificationManager.success(
          "Comment added. Other users will be able to see your comment after it has been checked by the site administration.",
          "Success"
        );
      } else {
        NotificationManager.success(`${data.message}`, "Error");
      }
      setComment("");
      setTimeout(() => {
        setNeedReload(!needReload);
      }, 1000);
    });
  };

  // other
  const navigate = useNavigate();

  useEffect(() => {
    fetchPostByUrl(url).then(
      (data) => {
        setPost(data);
        setDescription(
          EditorState.createWithContent(decodeEditorBody(data.description))
        );
        getUserSettings(user.user.id).then((data) => {
          setSettings(data);
        });
        setIsLoaded(true);
      },
      (e) => {
        setError(error);
        setIsLoaded(false);
      }
    );
  }, [needReload]);
  if (!isLoaded) {
    return (
      <Page>
        <LoadingPlaceholder />
      </Page>
    );
  } else if (error) {
    return (
      <Page>
        <h5>{error}</h5>
      </Page>
    );
  } else {
    return (
      <Page>
        <NotificationContainer />
        <Helmet>
          <title>{post.seo_title}</title>
          <meta name="description" content={post.seo_description} />
          <meta name="keywords" content={post.seo_keywords} />
        </Helmet>
        <PageContainer>
          <Wrapper>
            <Title>{post.title}</Title>
            <BtnBack onClick={() => navigate("/en/blog")}>
              <span>Return to recent blog posts</span>
            </BtnBack>
          </Wrapper>
          <ContentWrapper>
            <div>
              <Thumbnail src={getFileUrl(post.img)} />
              <MediaQuery minWidth={1128}>
                <Share>
                  <ShareTitle>Share the</ShareTitle>
                  <ShareSocial
                    socialTypes={[
                      "facebook",
                      "twitter",
                      "email",
                      "linkedin",
                      "whatsapp",
                    ]}
                    url={window.location.href}
                    style={{ copyContainer: { display: "none" } }}
                  />
                </Share>
              </MediaQuery>
            </div>
            <MediaQuery maxWidth={1127}>
              <CategoryLink to={`/en/blog/category/${post.category}`}>
                {post.category}
              </CategoryLink>
            </MediaQuery>
            <Content>
              <MediaQuery minWidth={1128}>
                <DesctopCategoryLink to={`/en/blog/category/${post.category}`}>
                  {post.category}
                </DesctopCategoryLink>
              </MediaQuery>
              <EditorWrapper>
                <Editor editorState={description} readOnly={true} />
              </EditorWrapper>
            </Content>
          </ContentWrapper>
          <InteractiveWrapper>
            <MediaQuery maxWidth={1127}>
              <Share>
                <ShareTitle>Share the</ShareTitle>
                <ShareSocial
                  socialTypes={[
                    "facebook",
                    "twitter",
                    "email",
                    "linkedin",
                    "whatsapp",
                  ]}
                  style={{ copyContainer: { display: "none" } }}
                />
              </Share>
            </MediaQuery>
            <Comments>
              <CommentsTitle>Comments</CommentsTitle>
              {post.comments[0] ? (
                <CommentsList>
                  {post.comments.map((elem) => {
                    return (
                      <Comment>
                        <CommentUser>{elem.username}</CommentUser>
                        <CommentDate>{elem.date}</CommentDate>
                        <CommentText>{elem.text}</CommentText>
                      </Comment>
                    );
                  })}
                </CommentsList>
              ) : (
                <h5>No comments</h5>
              )}
              {user.isAuth === true ? (
                <CommentForm>
                  <Textarea
                    label="Add your comment"
                    value={comment}
                    onChange={(e) => {
                      setComment(e.target.value);
                    }}
                  ></Textarea>
                  <CommentsButton
                    isempty={comment.length > 0 ? false : true}
                    disabled={comment.length > 0 ? false : true}
                    onClick={(e) => {
                      e.preventDefault();
                      sendComment();
                    }}
                  >
                    Post
                  </CommentsButton>
                </CommentForm>
              ) : null}
              {!user.isAuth ? (
                <Text>
                  Please <GoTo to={"/registration"}>register</GoTo> or{" "}
                  <GoTo to={"/login"}>login</GoTo> and you will be able to add
                  comments
                </Text>
              ) : null}
            </Comments>
          </InteractiveWrapper>
        </PageContainer>
      </Page>
    );
  }
}
