import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import PageHeader from "../subcomponents/PageHeader";
import MediaQuery from "react-responsive";
import Heading from "../../Interface/TextElements/Heading";
import Address from "../../Interface/AccountComponetnts/Address";
import { Link, useParams } from "react-router-dom";
import { getOneOrder } from "../../../../api/ordersAPI";
import { Spinner } from "react-bootstrap";
import Page from "../../Interface/Page/Page";
import AccountMenu from "../subcomponents/AccountMenu";
import dayjs from "dayjs";
import { Helmet } from "react-helmet";
import { fetchAddress } from "../../../../api/addressAPI";
import { Context } from "../../../..";
import { getUserData } from "../../../../api/userAPI";
import LoadingPlaceholder from "../../../Functional/LoadingPlaceholder";

const ConvertTime = (date) => {
  let dateTime = new Date(date);
  return `${dateTime.getMonth() + 1
    }.${dateTime.getDate()}.${dateTime.getFullYear()}`;
};

const calculatePrice = (arr) => {
  let price = 0;
  arr.map((item) => {
    price += item.price * item.qty;
  });
  return price;
};


const TemporaryHide = styled.div`
  @media screen and (max-width: 1127px) {
    display: none;
  }
`;

const Container = styled.div`
  width: calc(100vw - 36px);
  margin: 20px auto;
  position: relative;
  @media screen and (min-width: 1128px) {
    width: auto;
    margin: 0;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  align-items: center;
  color: #7f878b;
  margin-top: 15px;
  @media screen and (min-width: 1128px) {
    margin-top: 0;
    justify-content: flex-start;
  }
`;

const DateElem = styled.p`
  text-align: center;
  @media screen and (min-width: 1128px) {
    width: 125px;
    padding-right: 15px;
    text-align: left;
    span {
      font-weight: 600;
    }
  }
`;
const Number = styled.p`
  text-align: center;
  @media screen and (min-width: 1128px) {
    padding-right: 15px;
    text-align: left;
    span {
      font-weight: 600;
    }
  }
`;

const Status = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  padding: 7px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #303236;
  height: 30px;
  background: #f7f8f9;
  border-radius: 4px;
  @media screen and (min-width: 1128px) {
    background: none;
    color: #7f878b;
    text-align: left;
    padding: 0px;
  }
`;
const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;
const OrderTable = styled.table`
  width: 100%;
  @media screen and (min-width: 1128px) {
    border: 1px solid #eaebec;
    border-radius: 6px 6px 0px 0px;
    border-bottom: none;
  }
`;

const TableRow = styled.tr`
  td {
    padding: 5px 0px;
    box-shadow: 0px 1px 0px #eaebec;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #303236;
    @media screen and (min-width: 1128px) {
      font-size: 14px;
      line-height: 17px;
      box-shadow: none;
    }
  }
  th {
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    color: #7f878b;
    background: #f3f7f2;
    @media screen and (min-width: 1128px) {
      background: none;
      box-shadow: 0px 1px 0px #eaebec;
      padding-top: 10px;
    }
  }
  td:nth-child(1),
  th:nth-child(1) {
    padding-left: 18px;
    @media screen and (min-width: 1128px) {
      padding-left: 35px;
    }
    width: 48%;
    text-align: left;
  }
  td:nth-child(2),
  th:nth-child(2) {
    padding-left: 8px;
    width: 20%;
    text-align: center;
  }
  td:nth-child(3),
  th:nth-child(3) {
    padding-left: 8px;
    width: 20%;
    padding-right: 18px;
    text-align: right;
    @media screen and (min-width: 1128px) {
      padding-right: 20px;
    }
  }
`;

const Row = styled(TableRow)`
  height: 42px;
`;

const Qty = styled.span`
  padding: 7px 10px;
  background: #f7f8f9;
  border: 1px solid #eaebec;
  border-radius: 4px;
`;

const OrderTotal = styled.div`
  background-color: #f3f7f2;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  @media screen and (min-width: 1128px) {
    border: 1px solid #eaebec;
    border-radius: 6px;
    margin-bottom: 25px;
    border: 1px solid #eaebec;
    border-radius: 0px 0px 6px 6px;
    border-top: none;
  }
`;

const TotalItem = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #303236;
  opacity: 0.6;
  margin-bottom: 0;
  span:nth-child(1) {
    text-align: right;
    padding-right: 10px;
    width: 50%;
    @media screen and (min-width: 1128px) {
      width: 80%;
    }
  }
  span:nth-child(2) {
    text-align: left;
    width: 50%;
    @media screen and (min-width: 1128px) {
      width: 20%;
    }
  }
`;

const ContentContainer = styled.div`
  margin-top: 20px;
  @media screen and (min-width: 1128px) {
    display: flex;
    margin-top: 30px;
    margin-bottom: 40px;
  }
`;

const Total = styled(TotalItem)`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #303236;
  opacity: 1;
  margin-top: 10px;
`;

const BoldSpan = styled.span`
font-weight: 600;
margin-left: 3px;
`

const MobileBackButton = styled(Link)`
&:before{
  content: url('/arrow-back.svg');
}
text-decoration: none;
color: black;
position: absolute;
top: -5px;
margin-left: 20px;
vertical-align: center;
@media screen and (min-width: 1128px){
  display: none;
}
&:hover{
  color: inherit;
}
&:active{
  color: inherit;
}
`;


const Shipment = styled.div``;
export default function SingleOrder() {

  const { id } = useParams();
  const { user } = useContext(Context);

  // states
  const [totalPrice, setTotalPrice] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [userInfo, setUserInfo] = useState(false);
  const [userInfoLoaded, setUserInfoLoaded] = useState(false);
  const [addressIsLoaded, setAddressIsLoaded] = useState(false);
  const [order, setOrder] = useState(null);
  const [shipping, setShipping] = useState(null);
  const [error, setError] = useState(null);

  // load data
  useEffect(() => {
    getOneOrder(id).then((data) => {
      setOrder(data);
      // fetch address
      fetchAddress(data.shipping_address).then((data) => {
        setShipping(data)
        setAddressIsLoaded(true);
      })
      // fetch user info
      getUserData(user.user.id).then((data) => {
        if (data.id) {
          setUserInfo(data);
          setUserInfoLoaded(true);
        }
        else {
          setIsLoaded(false);
          setError(data);
        }
      })
      setIsLoaded(true);
    }, (error) => {
      setIsLoaded(false);
      setError(error);
    })
  }, [])
  if (!isLoaded || !addressIsLoaded || !userInfoLoaded) {
    return (
      <Page>
        <LoadingPlaceholder />
      </Page>
    )
  }
  if (error) {
    return (
      <Page>
        <h5>{error}</h5>
      </Page>
    )
  }
  else {
    return (
      <Page variant={'centred'} seo_title={`Vitaforest - Order #${id} - Account`} seo_description={'Vitaforest - Order details - Account'}>
        <ContentContainer>
          <TemporaryHide>
            <AccountMenu />
          </TemporaryHide>
          <Wrapper>
            <MobileBackButton to={'/account/orders'} />
            <PageHeader>Order details</PageHeader>
            <Container>
              <Flex>
                <DateElem>From {dayjs(order.createdAt).format("MMM, DD, YYYY")}</DateElem>
                <Number>Order number: <BoldSpan>№ {order.id}</BoldSpan></Number>
                <MediaQuery minWidth={1128}>
                  {" "}
                  <Status>Status:<BoldSpan>{order.status}</BoldSpan></Status>
                </MediaQuery>
              </Flex>
              <MediaQuery maxWidth={1127}>
                <Status>{order.status}</Status>
              </MediaQuery>
            </Container>
            <OrderTable>
              <TableRow>
                <th>Product</th>
                <th>Qty</th>
                <th>Total</th>
              </TableRow>
              {order.items.map((item) => {
                return (
                  <Row>
                    <td>{item.title}</td>
                    <td>
                      <Qty>{item.qty}</Qty>
                    </td>
                    <td>€{(item.price * item.qty).toFixed(2)}</td>
                  </Row>
                );
              })}
            </OrderTable>
            <OrderTotal>
              <TotalItem>
                <span>Subtotal:</span>
                <span>€{calculatePrice(order.items)}</span>
              </TotalItem>
              <TotalItem>
                <span>Payment method:</span>
                <span>{order.payment_method}</span>
              </TotalItem>
              <Total>
                <span>Total:</span>
                <span>€{calculatePrice(order.items)}</span>
              </Total>
            </OrderTotal>
            <Container>
              <Shipment>
                <MediaQuery maxWidth={'1127px'}>
                  <Heading>Shipment</Heading>
                </MediaQuery>
                <Address
                  name={`${shipping.first_name} ${shipping.last_name}`}
                  country={shipping.country}
                  state={shipping.state}
                  street={`${shipping.city}, ${shipping.post}, ${shipping.zip}`}
                  phone={shipping.phone}
                  isOrderView={true}
                />
              </Shipment>
            </Container>
          </Wrapper>
        </ContentContainer>
      </Page>
    );
  }
}
