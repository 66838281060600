import React, { useEffect, useContext } from "react";
import AdminUI from "../../Ui/AdminUI";
import { Editor } from 'react-draft-wysiwyg';
import { useState } from "react";
import CodeEditor from '@uiw/react-textarea-code-editor';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Card, Form, Button, Container, Row, Col, Table } from "react-bootstrap";
import styled from "styled-components";
import { createPost, decodeEditorBody, encodeEditorBody } from "../../../../api/postAPI";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { Context } from "../../../..";
import { useNavigate } from "react-router-dom";
import { createPostDraft, createPageDraft } from "../../../../api/draftAPI";
import LoadingPlaceholder from "../../../Functional/LoadingPlaceholder";
import PageVar from "../../../VitaforestUI/Interface/Page/PageVar";
import { EditorState } from "draft-js";
import { createNewPage } from "../../../../api/pagesAPI";


const FlexTitle = styled.div`
display: flex;
margin-bottom: 20px;
`;

const PageTitle = styled.h1`
margin: 0px;
margin-right: 20px;
`

const HTMLPage = () => {

    const { user } = useContext(Context)
    const navigate = useNavigate();
    const [seoTitle, setSeoTitle] = useState('');
    const [html, setHtml] = useState('');
    const [css, setCss] = useState('');
    const [seoDesc, setSeoDesc] = useState('');
    const [seoKeywords, setSeoKeywords] = useState('');
    const [url, setUrl] = useState('');
    const [error, setError] = useState(null);
    const [withInterface, setWithInterface] = useState('standwith');

    const createPage = () => {
        const formData = new FormData();
        formData.append('html_body', html);
        formData.append('html_styles', css);
        formData.append('url', url);
        if (url.length < 1) {
            NotificationManager.error(
                `Url cannot be empty or less then 1 symbol`
            );
            return false;
        }
        formData.append('seo_title', seoTitle);
        if (seoTitle.length < 1) {
            NotificationManager.error(
                `Title cannot be empty or less then 1 symbol`
            );
            return false;
        }
        formData.append('seo_description', seoDesc);
        formData.append('seo_keywords', seoKeywords);
        formData.append('pageType', withInterface);
        createNewPage(formData).then((data) => {
            if (data.id) {
                NotificationManager.success(
                    `Page "${seoTitle}" published`,
                    "Success"
                );
                setTimeout(() => {
                    navigate('/admin/pages/edit/' + data.id)
                }, 1500)
            } else {
                NotificationManager.error(
                    `Page "${seoTitle}" cannot be created`,
                    `${data}`
                );
            }
        })
    }
    const draftPage = () => {
        const formData = new FormData();
        formData.append('html_body', html);
        formData.append('html_styles', css);
        formData.append('url', url);
        if (url.length < 1) {
            NotificationManager.error(
                `Url cannot be empty or less then 1 symbol`
            );
            return false;
        }
        formData.append('seo_title', seoTitle);
        if (seoTitle.length < 1) {
            NotificationManager.error(
                `Title cannot be empty or less then 1 symbol`
            );
            return false;
        }
        formData.append('seo_description', seoDesc);
        formData.append('seo_keywords', seoKeywords);
        formData.append('pageType', withInterface);
        console.log(formData.pageType)
        createPageDraft(formData).then((data) => {
            if (data.id) {
                NotificationManager.success(
                    `Page "${seoTitle}" draft saved`,
                    "Success"
                );
                setTimeout(() => {
                    navigate('/admin/pages/draft/' + data.id)
                }, 1500)
            } else {
                NotificationManager.error(
                    `Page "${seoTitle}" cannot be created`,
                    `${data}`
                );
            }
        })
    }
    if (error) {
        return <AdminUI>{error}</AdminUI>
    }
    else {
        return (
            <AdminUI>
                <NotificationContainer />
                <FlexTitle>
                    <PageTitle>Add new page</PageTitle>
                    <Button onClick={() => { createPage() }} >Publish page</Button>
                    <Button variant="warning" onClick={() => { draftPage() }} style={{ marginLeft: '10px' }}>Save draft</Button>
                </FlexTitle>
                <Form className="mb-5">
                    <Container>
                        <Row>
                            <Col>
                                <Card className="mb-5">
                                    <Card.Body>
                                        <Form.Group className="mb-2">
                                            <Form.Label>URL</Form.Label>
                                            <Form.Control aria-label="large"
                                                value={url}
                                                onChange={e => setUrl(String(e.target.value))}
                                                placeholder="Post title"
                                                type="text"
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-2">
                                            <Form.Label>SEO Title / Page title</Form.Label>
                                            <Form.Control type="text" placeholder="SEO Title" value={seoTitle} onChange={(e) => setSeoTitle(String(e.target.value))}></Form.Control>
                                        </Form.Group>
                                        <Form.Group className="mb-2">
                                            <Form.Label>SEO Description</Form.Label>
                                            <Form.Control type="text" placeholder="SEO Description" value={seoDesc} onChange={(e) => setSeoDesc(String(e.target.value))}></Form.Control>
                                        </Form.Group>
                                        <Form.Group className="mb-2">
                                            <Form.Label>SEO Keywords</Form.Label>
                                            <Form.Control type="text" placeholder="SEO Keywords" value={seoKeywords} onChange={(e) => setSeoKeywords(String(e.target.value))}></Form.Control>
                                            <Form.Text className="text-muted">List the key words, separated by commas</Form.Text>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                                <Card className="mb-2">
                                    <Card.Header>Preview (for preview refresh click on any empty space)</Card.Header>
                                    <PageVar type={'standalone-preview'} html_body={html} html_styles={css} />
                                    <Card.Body>

                                    </Card.Body>
                                </Card>
                                <Card className="mb-2">
                                    <Card.Header>HTML editor</Card.Header>
                                    <Card.Body>
                                        <CodeEditor
                                            value={html}
                                            language="html"
                                            placeholder="Please enter html code."
                                            onChange={(evn) => setHtml(evn.target.value)}
                                            padding={15}
                                            style={{
                                                backgroundColor: "#f5f5f5",
                                                fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                                                color: 'black'
                                            }}
                                        />
                                    </Card.Body>
                                </Card>
                                <Card className="mb-2">
                                    <Card.Header>CSS editor</Card.Header>
                                    <Card.Body>
                                        <CodeEditor
                                            value={css}
                                            language="css"
                                            placeholder="Please enter html code."
                                            onChange={(evn) => setCss(evn.target.value)}
                                            padding={15}
                                            style={{
                                                backgroundColor: "#f5f5f5",
                                                fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                                                color: 'black'
                                            }}
                                        />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Form>
            </AdminUI >
        )
    }
}
export default HTMLPage