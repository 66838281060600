import { $authHost, $host } from ".";

export const getHeaderAdContent = async () => {
    try {
        const { data } = await $host.get('/api/any-content/getheaderads');
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const headerAdsAction = async (body) => {
    try {
        const { data } = await $authHost.post('/api/any-content/add/advert', body);
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const contentRemove = async (id) => {
    try {
        const { data } = await $authHost.post(`/api/any-content/remove/${id}`);
        return data;
    } catch (e) {
        return e.response.data;
    }
}