import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "./images/logo.svg";
import { ReactComponent as KyoLogo } from "./images/kyoshop.svg";
import { appendScript } from "./ScriptAppender";
import Notifications from "../Notifications/Notifications";

const FooterContainer = styled.div`
  background-color: #1a212d;
  box-sizing: border-box;
  padding: 30px 40px;
  padding-bottom: 0;
  @media screen {
  }
`;
const FooterInner = styled.footer`
  @media screen and (min-width: 1128px) {
    display: flex;
    margin-top: 25px;
    width: 1128px;
    margin: 0 auto;
    padding-bottom: 40px;
  }
`;

const Contacts = styled.div`
  margin-right: 110px;
`;

const ContactsText = styled.p`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #eaebec;
  opacity: 0.5;
  margin-top: 27px;
`;

const KyoLogotype = styled(KyoLogo)`
width: 130px;
margin: 0px;
padding: 0px;
height: 40px;
display: flex;
align-items: flex-end;
`

const FooterNav = styled.div`
  @media screen and (min-width: 1128px) {
    display: flex;
  }
`;

const FooterNavWrapper = styled.div`
  flex: 0 0 auto;
  @media screen and (min-width: 1128px) {
    margin-right: 60px;
  }
  .nav-expanded {
    display: block;
  }
`;

const NavTitle = styled.h3`
  text-transform: uppercase;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  opacity: 0.7;
  padding-left: 20px;
  position: relative;
  @media screen and (min-width: 1128px) {
    text-transform: uppercase;
    height: 46px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }

  &::before {
    content: "+";
    font-size: 20px;
    font-weight: 900;
    position: absolute;
    left: 0;
    top: 2px;
    @media screen and (min-width: 1128px) {
      content: none;
    }
  }
`;
const NavList = styled.ul`
  list-style: none;
  padding: 0;
  display: none;
  @media screen and (min-width: 1128px) {
    display: block;
  }
  a {
    text-decoration: none;
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 158%;
    text-decoration-line: underline;
    color: #ffffff;
    opacity: 0.9;
  }
`;

const NavLink = styled.li`
  padding-left: 20px;
`;

const Socials = styled.div`
  margin-top: 27px;
  padding-bottom: 30px;
  display: flex;
  align-items: flex-start;
  img {
    padding-right: 17px;
  }
  @media screen and (min-width: 1128px) {
    margin-top: 12px;
  }
`;
const Copyright = styled.div`
  box-shadow: 0px -1px 0px rgba(234, 235, 236, 0.06);
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  background: #1a212d;
  box-sizing: border-box;
  padding: 10px 20px 0px 20px;
  div {
    display: flex;
    justify-content: space-between;
  }
  @media screen and (min-width: 1128px) {
    padding: 23px 0px;
    p {
      margin-bottom: 0;
    }
    div {
      width: 1128px;
      margin: 0 auto;
      justify-content: flex-start;
    }
  }
  .name {
    color: #eaebec;
    opacity: 0.5;
    @media screen and (min-width: 1128px){
      margin-right: 193px; 
    }
  }
  .linktext {
    display: flex;
    align-items: center;
    text-align: right;
    text-decoration-line: underline;
    color: #ffffff;
    opacity: 0.9;
  }
`;
const FooterWrapper = styled.div`
  flex: 0 0 auto;
`;
const Footer = () => {
  const [isCompanyExpanded, setIsCompanyExpanded] = useState(false);
  const [isCustomerExpanded, setIsCustomerExpanded] = useState(false);
  const [isInspiredExpanded, setIsInspiredExpanded] = useState(false);
  const fullYearString = new Date;

  return (
    <FooterWrapper>
      <Notifications />
      <FooterContainer>
        <FooterInner>
          <Contacts>
            <Link to="/">
              <Logo />
            </Link>

            <ContactsText>
              Vitaforest Distribution OÜ
              <br />
              Register code: 14377464
              <br />
              VAT No: EE102049370
              <br />
              Harju maakond, Tallinn,
              <br />
              Kesklinna linnaosa,
              <br />
              Vesivärava tn 50-301, 10152
            </ContactsText>
          </Contacts>
          <FooterNav>
            <FooterNavWrapper>
              <NavTitle
                onClick={() => {
                  setIsCompanyExpanded(!isCompanyExpanded);
                }}
              >
                Company information
              </NavTitle>
              <NavList
                className={isCompanyExpanded ? "nav-expanded" : "unexpanded"}
              >
                <NavLink>
                  <Link to="/en/about-us">About us</Link>
                </NavLink>
                <NavLink>
                  <Link to="/en/terms-and-conditions">Terms and conditions</Link>
                </NavLink>
                <NavLink>
                  <Link to="/en/cookie-policy">Cookie policy</Link>
                </NavLink>
              </NavList>
            </FooterNavWrapper>
            <FooterNavWrapper>
              <NavTitle
                onClick={() => {
                  setIsCustomerExpanded(!isCustomerExpanded);
                }}
              >
                Customer service
              </NavTitle>
              <NavList
                className={isCustomerExpanded ? "nav-expanded" : "unexpanded"}
              >
                <NavLink>
                  <Link to="/account">My account</Link>
                </NavLink>
                <NavLink>
                  <Link to="/en/contact-us">Contact us</Link>
                </NavLink>
                <NavLink>
                  <Link to="/en/faq">FAQ</Link>
                </NavLink>
              </NavList>
            </FooterNavWrapper>
            <FooterNavWrapper>
              <NavTitle
                onClick={() => {
                  setIsInspiredExpanded(!isInspiredExpanded);
                }}
              >
                Inspired by
              </NavTitle>
              <NavList
                className={isInspiredExpanded ? "nav-expanded" : "unexpanded"}
              >
                <NavLink>
                  <Link to="/en/blog/category/health">Health</Link>
                </NavLink>
                <NavLink>
                  <Link to="/en/blog/category/scientific publication">Scientific publication</Link>
                </NavLink>
                <NavLink>
                  <Link to="/en/blog/category/industry news">Industry news</Link>
                </NavLink>
                <NavLink>
                  <Link to="/en/blog/category/trends">Trends</Link>
                </NavLink>
              </NavList>
            </FooterNavWrapper>
          </FooterNav>
          <Socials>
            <a href="https://www.facebook.com/vitaforestfood/">
              <img src="/Facebook.svg" />
            </a>
            <a href="https://www.linkedin.com/company/vitaforestfood/">
              <img src="/Linkedin.svg" />
            </a>
          </Socials>
        </FooterInner>
      </FooterContainer>
      <Copyright>
        <div>
          <p className="name"> © VitaForest {fullYearString.getFullYear()}</p>
          <Link to="/en/privacy-policy">
            <p className="linktext">Privacy Policy</p>
          </Link>
        </div>
      </Copyright>
      {appendScript('/3rdparty/linkedin/linkedin_tag.js')}
    </FooterWrapper>
  );
};

export default Footer;
