import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { Context } from "../../../../..";
import { ReactComponent as CloseIcon } from "../images/close.svg";
import { NotificationContainer, NotificationManager } from "react-notifications";

const CloseAccount = styled.button`
  background-color: #fff;
  border: none;
  position: absolute;
  right: 10px;
  top: 10px;
  @media screen and (min-width: 1128px) {
    display: none;
  }
`;
const AccountWrapper = styled.div`
  height: auto;
  @media screen and (max-width: 1127px) {
    display: flex;
    flex-direction: column;
    height: 100vh;
    box-sizing: border-box;
    padding: 50px 0px;
  }
`;

const CustomLink = styled(Link)`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  box-shadow: 0px 1px 0px #eaebec;
  padding: 9px 18px;
  text-decoration: none;
  color: #303236;
  padding-left: 48px;
  position: relative;
  &:before {
    position: absolute;
    left: 18px;
  }
  @media screen and (min-width: 1128px) {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #303236;
    box-shadow: none;
    opacity: 0.8;
    padding: 8px 15px;
    &:hover {
      color: inherit;
      background-color: #f7f8f9;
    }
  }
`;

const Account = styled.div`
  display: block;
  position: absolute;
  top: 0px;
  height: 100vh;
  right: -100vw;
  width: 90vw;
  background-color: #fff;
  z-index: 8;
  animation-duration: 0.5s;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  -webkit-transition: right 0.5s;
  transition: right 0.5s;
  &.expanded {
    right: -20px;
  }
  @media screen and (min-width: 1128px) {
    width: 150px;
    display: none;
    height: fit-content;
    filter: drop-shadow(0px 4px 14px rgba(26, 33, 45, 0.1));

    top: 56px;
    border-radius: 4px;
    animation-name: none;
    &.expanded {
      display: block;
      left: initial;
      right: 59px;
    }
  }
`;

const Triangle = styled.div`
  display: none;
  @media screen and (min-width: 1128px) {
    display: block;
    position: absolute;
    width: 17px;
    height: 17px;
    left: 14px;
    top: -5px;
    background: #ffffff;
    transform: rotate(-45deg);
    z-index: 0;
  }
`;

const LastLink = styled(CustomLink)`
  @media screen and (max-width: 1127px) {
    margin-top: auto;
    box-shadow: none;
    &:before {
      content: url("/sign-out.svg");
    }
  }
  @media screen and (min-width: 1128px) {
    margin-bottom: 5px;
  }
`;

const CartLink = styled(CustomLink)`
  @media screen and (max-width: 1127px) {
    &:before {
      content: url("/basket.svg");
      left: 10px;
      top: 5px;
    }
  }
`;

const WishlistLink = styled(CustomLink)`
  @media screen and (max-width: 1127px) {
    &:before {
      content: url("/heart.svg");
    }
  }
`;
const AccountLink = styled(CustomLink)`
  @media screen and (max-width: 1127px) {
    &:before {
      content: url("/user.svg");
    }
  }
  @media screen and (min-width: 1128px) {
    margin-top: 5px;
  }
`;
const LoginLink = styled(CustomLink)`
  @media screen and (max-width: 1127px) {
    &:before {
      content: url("/sign-out.svg");
    }
    @media screen and (min-width: 1128px) {
      margin-top: 5px;
    }
  }
`;

export default function AccountModal(props) {
  const { user } = useContext(Context);

  const logOut = () => {
    localStorage.clear();
    setTimeout(() => {
      window.location.replace('/');
    }, 500);
  };

  return (
    <Account
      expanded={props.expanded}
      className={props.expanded ? "expanded" : null}
    >
      <Triangle />
      <CloseAccount onClick={() => props.onClick()}>
        <CloseIcon />
      </CloseAccount>
      {user.isAuth ? (
        <AccountWrapper>
          <AccountLink to="/account">My account</AccountLink>
          <CartLink to="/cart">Shopping cart</CartLink>
          <WishlistLink to="/account/wishlist">My wish list</WishlistLink>
          <LastLink
            onClick={() => {
              logOut();
            }}
          >
            Sign out
          </LastLink>
        </AccountWrapper>
      ) : (
        <AccountWrapper>
          <LoginLink to="/login">Sign in or Sign up</LoginLink>
          <CartLink to="/request" requestcart={true}>
            Request cart
          </CartLink>
        </AccountWrapper>
      )}
    </Account>
  );
}
