import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import MediaQuery from "react-responsive";
import PageHeader from "../subcomponents/PageHeader";
import Page from "../../Interface/Page/Page";
import AccountMenu from "../subcomponents/AccountMenu";
import { Link, useNavigate } from "react-router-dom";
import { getOffersByUser, offerToBasket } from "../../../../api/offerAPI";
import LoadingPlaceholder from "../../../Functional/LoadingPlaceholder";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { ReactComponent as Cart } from "../../Product/ProductCard/img/cart.svg";
const TemporaryHide = styled.div`
  @media screen and (max-width: 1127px) {
    display: none;
  }
`;
const Container = styled.div`
  position: relative;
  margin-top: 20px;
  @media screen and (min-width: 1128px) {
    display: flex;
    margin-top: 30px;
    margin-bottom: 40px;
  }
`;

const Wrapper = styled.div`
  width: calc(100vw - 36px);
  margin: 0 auto;
  @media screen and (min-width: 1128px) {
    width: auto;
    margin: 0;
  }
`;

const MobileBackButton = styled(Link)`
  &:before {
    content: url("/arrow-back.svg");
  }
  text-decoration: none;
  color: black;
  position: absolute;
  top: -5px;
  vertical-align: center;
  @media screen and (min-width: 1128px) {
    display: none;
  }
  &:hover {
    color: inherit;
  }
  &:active {
    color: inherit;
  }
`;

const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
`;

const OffersList = styled.ul`
  display: flex;
  list-style: none;
  gap: 15px;
  padding: 0px;
  margin-top: 20px;
`;

const MobileOffersList = styled.div``;

const MobileOfferTitle = styled.button`
  display: block;
  border: none;
  background-color: #fff;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #303236;
  height: 40px;
  text-align: left;
  width: 100%;
  position: relative;
  margin-bottom: 10px;
  box-shadow: 0px 1px 0px #eaebec;
  &::after {
    content: url("/arrow.svg");
    right: 0;
    position: absolute;
    rotate: -90deg;
  }
  &:hover,
  &:focus,
  &:active {
    &::after {
      content: url("/arrow.svg");
      rotate: none;
      transition: all 0.5s;
    }
  }
`;

const OffersInner = styled.div``;

const OfferBtn = styled.button`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #303236;
  padding: 12px 20px;
  border-radius: 4px;
  border: 1px solid #eaebec;
  background-color: transparent;
  &:hover,
  &:focus,
  &:active {
    color: #ffffff;
    background: #3c3f54;
    border: 1px solid #3c3f54;
    transition: all 0.5s;
  }
`;

const OfferBtnActive = styled(OfferBtn)`
  color: #ffffff;
  background: #3c3f54;
  border: 1px solid #3c3f54;
  &:hover,
  &:focus,
  &:active {
    color: #303236;
    background: transparent;
    border: 1px solid #eaebec;
  }
`;

const OfferTable = styled.div`
  width: 100%;
  table {
    width: 100%;
  }
  @media screen and (min-width: 1128px) {
    table {
      width: 840px;
    }
    border: 1px solid #eaebec;
    border-radius: 6px 6px 6px 6px;
    margin-top: 20px;

    box-sizing: border-box;
    padding-bottom: 15px;
  }
`;

const TableRow = styled.tr`
  td {
    padding: 10px 18px 10px 18px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    vertical-align: center;
    color: #303236;
    @media screen and (min-width: 1128px) {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #303236;
      padding-top: 15px;
    }
  }
  th {
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    color: #7f878b;
    background: #f3f7f2;
    padding: 0px 18px;

    @media screen and (min-width: 1128px) {
      background: none;
      border-bottom: 1px solid #eaebec;
      padding-top: 10px;
    }
  }
  @media screen and (min-width: 1128px) {
    td,
    th {
      padding-right: 10px;
    }
    td:nth-child(1),
    th:nth-child(1) {
      padding-left: 30px;
      width: 426px;
    }
    td:nth-child(2),
    th:nth-child(2) {
      width: 95px;
    }
    td:nth-child(3),
    th:nth-child(3) {
      width: 75px;
      padding-left: 0px;
      padding-right: 0px;
    }

    td:nth-child(4),
    th:nth-child(4) {
      width: 96px;
    }
    &:hover {
      box-shadow: 0px 4px 10px rgba(26, 33, 45, 0.05);
      border-radius: 4px;
      span {
        text-decoration: underline;
      }
    }
  }
`;
const OfferQty = styled.span`
  background: #f7f8f9;
  border: 1px solid #eaebec;
  border-radius: 4px;
  padding: 7px 10px;
  text-decoration: none !important;
`;

const Row = styled.p`
  margin: 0px;
`;

const Sku = styled.span`
  color: #7f878b;
  text-decoration: none !important;
`;

const TotalRow = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: right;
  color: #303236;
  margin: 0px;
`;

const AddToCartBtn = styled.button`
  background: #40bf6a;
  border-radius: 4px;
  border: none;
  gap: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 48px;
  align-items: center;
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 30px;
  margin-top: 40px;
  @media screen and (min-width: 1128px) {
    width: 154px;
    height: 40px;
    margin: 0px;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0px 35px;
  margin-top: 30px;
`;
export default function Offers() {
  const [offers, setOffers] = useState([]);
  const [currentOffer, setCurrentOffer] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [needReload, setNeedReload] = useState(false);

  const nav = useNavigate();

  useEffect(() => {
    getOffersByUser().then(
      (data) => {
        let collection = []
        data.map((item) => {
          if (item.status == 'active') {
            collection.push(item);
          }
        })
        setOffers(collection);
        setCurrentOffer(collection[0]);
        setIsLoaded(true);
      },
      (e) => {
        setError(e.message);
        setIsLoaded(false);
      }
    );
  }, [needReload]);

  const CalculateTotal = (currentOffer) => {
    let summ = 0;
    currentOffer.items.map((item) => {
      summ = summ + item.qty * item.price;
    });
    return summ;
  };

  const convertOffer = () => {
    offerToBasket(currentOffer.id).then((data) => {
      if (data.transformed == true) {
        NotificationManager.success("Offer converted to cart", "Success");
        nav('/checkout');
        setNeedReload(!needReload);
      } else {
        NotificationManager.error(
          `Something went wrong. Error: ${data.message}`,
          "Error"
        );
      }
    });
  };

  const calcAmount = (price, qty) => {
    return price * qty;
  };
  if (!isLoaded) {
    return (
      <Page
        variant={"centred"}
        seo_title={"Vitaforest - Account - Offers"}
        seo_description={"Vitaforest user offers page"}
      >
        <LoadingPlaceholder />
      </Page>
    );
  }
  if (offers.length < 1) {
    return (
      <Page variant={"centred"}
        seo_title={"Vitaforest - Account - Offers"}
        seo_description={"Vitaforest user offers page"}>
        <Container>
          <TemporaryHide>
            <AccountMenu />
          </TemporaryHide>
          <Wrapper>
            <PageHeader>Offers</PageHeader>
            <h5>You dont have offers</h5>
          </Wrapper>
        </Container>
      </Page>
    )
  }
  if (isLoaded) {
    return (
      <Page
        variant={"centred"}
        seo_title={"Vitaforest - Account - Offers"}
        seo_description={"Vitaforest user offers page"}
      >
        <NotificationContainer />
        <Container>
          <TemporaryHide>
            <AccountMenu />
          </TemporaryHide>
          <Wrapper>
            <MobileBackButton to={"/account"} />
            <PageHeader>Offers</PageHeader>
            <MediaQuery maxWidth={1127}>
              {offers.length > 0 ? (
                <>
                  <MobileOffersList>
                    {offers.map((item) => {
                      return (
                        <>
                          <MobileOfferTitle
                            onClick={() => setCurrentOffer(item)}
                          >
                            {item.name}
                          </MobileOfferTitle>
                          {currentOffer.name == item.name ? (
                            <>
                              <OfferTable>
                                <TableRow>
                                  <th>Product</th>
                                  <th>Qty</th>
                                  <th>Unit price</th>
                                  <th>Amount</th>
                                </TableRow>
                                {item.items.map((item) => {
                                  return (
                                    <TableRow>
                                      <td>
                                        <Row>{item.title}</Row>
                                        <Sku>SKU:({item.sku})</Sku>
                                      </td>
                                      <td>
                                        <OfferQty>{item.qty}</OfferQty>
                                      </td>
                                      <td>€{item.price}</td>
                                      <td>
                                        €{calcAmount(item.price, item.qty)}
                                      </td>
                                    </TableRow>
                                  );
                                })}
                              </OfferTable>
                              <TotalRow>
                                Total: €{CalculateTotal(currentOffer)}
                              </TotalRow>
                              <AddToCartBtn onClick={() => convertOffer()}>
                                <Cart />
                                <span>Add all to cart</span>
                              </AddToCartBtn>
                            </>
                          ) : null}
                        </>
                      );
                    })}
                  </MobileOffersList>
                </>
              ) : (
                <CenteredDiv>
                  <h5>You dont have offers</h5>
                </CenteredDiv>
              )}
            </MediaQuery>
            <MediaQuery minWidth={1128}>
              {offers ? (
                <>
                  <OffersInner>
                    <OffersList>
                      {offers.map((item) => {
                        return item.name == currentOffer.name ? (
                          <li>
                            <OfferBtnActive
                              onClick={() => setCurrentOffer(item)}
                            >
                              {item.name}
                            </OfferBtnActive>
                          </li>
                        ) : (
                          <li>
                            <OfferBtn onClick={() => setCurrentOffer(item)}>
                              {item.name}
                            </OfferBtn>
                          </li>
                        );
                      })}
                    </OffersList>
                  </OffersInner>
                  <OfferTable>
                    <table>
                      <TableRow>
                        <th>Product</th>
                        <th>Quantity</th>
                        <th>Unit price</th>
                        <th>Amount</th>
                      </TableRow>
                      {currentOffer.items.map((item) => {
                        return (
                          <TableRow>
                            <td>
                              <Row>
                                {item.title + " "}
                                <Sku>SKU:({item.sku})</Sku>
                              </Row>
                            </td>
                            <td>
                              <OfferQty>{item.qty}</OfferQty>
                            </td>
                            <td>
                              <span>€{item.price}</span>
                            </td>
                            <td>
                              <span>€{calcAmount(item.price, item.qty)}</span>
                            </td>
                          </TableRow>
                        );
                      })}
                    </table>

                    <Flex>
                      <AddToCartBtn onClick={() => convertOffer()}>
                        <Cart />
                        <span>Add all to cart</span>
                      </AddToCartBtn>
                      <TotalRow>
                        Total: €{CalculateTotal(currentOffer)}
                      </TotalRow>
                    </Flex>
                  </OfferTable>
                </>
              ) : (
                <CenteredDiv>
                  <h5>You dont have offers</h5>
                </CenteredDiv>
              )}
            </MediaQuery>
          </Wrapper>
        </Container>
      </Page>
    );
  } else if (error) {
    return (
      <Page
        variant={"centred"}
        seo_title={"Vitaforest - Account - Offers"}
        seo_description={"Vitaforest user offers page"}
      >
        <h1>{error}</h1>
      </Page>
    );
  }
}
