import React, { useEffect, useState } from "react";
import { Modal, Button, Tabs, Tab, Col, Row, Form } from "react-bootstrap";
import styled from "styled-components";
import { fileFetchDocs, fileUpdate, fileUpload } from "../../../api/fileAPI";
import { NotificationContainer, NotificationManager } from "react-notifications";
import File from "../../VitaforestUI/Interface/File/File";


// styled components for render preview

const ImageItem = styled.div`
margin-top: 10px;
margin-right: 5px;
border: ${props => props.selected ? '2px solid green' : null};
display: flex;
flex-direction: column;
align-items: center;
`;

const ImageListPreview = styled.img`
width: 100px;
height: 100px;
object-fit: cover;
`

const ImageItemName = styled.p`
margin: 0px;
font-size: 10px;
width: 80px;
`;

const ImageList = styled.div`
margin-top: 10px;
display: flex;
justify-content: flex-start;
flex-wrap: wrap;
`;

const ViewContainer = styled.div`
margin-top: 20px;
display: flex;
flex-direction: column;
`;

const ViewImage = styled.img`
width: 250px;
height: 250px;
`;

const UpdateView = styled.div`
display: flex;
justify-content: space-evenly;
`

const FlexUpload = styled.div`
display: flex;
align-items: center;
`

const UploadTitle = styled.p`
margin: 0px;
margin-right: 15px;
`

const UploadButton = styled(Button)`
margin-left: 15px;
`



const FMAddFiles = ({ showModal, setShowModal, gallery, setGallery, reload, setReload }) => {

    const [selectedFiles, setSelectedFiles] = useState(gallery ? gallery : []);
    const [images, setFiles] = useState(null);
    const [needReload, setNeedReload] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [viewItem, setViewItem] = useState(null);
    const [viewItemName, setViewItemName] = useState(null);
    const [needUpdate, setNeedUpdate] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState(null);

    // fetching images
    useEffect(() => {
        fileFetchDocs(1, 1000).then((data) => {
            setFiles(data.rows);
            setIsLoaded(true);
        }, (error) => {
            setIsLoaded(false);
            setError(error);
        })
    }, [needReload])

    // item update handle
    const updateItem = () => {
        const formdata = new FormData();
        let item = viewItem;
        formdata.append('id', item.id);
        formdata.append('name', viewItemName);
        fileUpdate(formdata).then((data) => {
            setNeedUpdate(false);
            setNeedReload(!needReload);
            setIsLoaded(false);
        })
    }

    const selectHandler = (elem) => {
        let item = selectedFiles.indexOf(elem);
        if (item >= 0) {
            let prev = selectedFiles;
            prev.splice(item, 1);
            setSelectedFiles(prev);
            setNeedReload(!needReload)
        }
        else {
            let prev = selectedFiles;
            prev.push(elem);
            setSelectedFiles(prev);
            setNeedReload(!needReload)
        }
    }

    // files upload
    const uploadFiles = (e) => {
        setUploadedFiles(e.target.files);
    }

    const uploadToServer = () => {
        let formdata = new FormData();
        if (uploadedFiles.length <= 1) {
            formdata.append('files', uploadedFiles[0])
        }
        else {
            for (let i = 0; i < uploadedFiles.length; i++) {
                formdata.append('files', uploadedFiles[i]);
            }
        }
        fileUpload(formdata).then((data) => {
            if (data.status) {
                NotificationManager.success(
                    `Successfully ${data.status}`,
                    "Success"
                );
                setUploadedFiles([]);
                setNeedReload(!needReload);
            }
            else {
                NotificationManager.error(
                    `File cannot be uploaded`,
                    `${data.message}`
                );
                setUploadedFiles([]);
            }
        })
    }

    if (isLoaded) {
        return (
            <Modal size="xl" show={showModal}>
                <NotificationContainer />
                <Modal.Header>
                    <Modal.Title>File manager</Modal.Title>
                    <FlexUpload>
                        <UploadTitle>Upload new files</UploadTitle>
                        <Form.Group>
                            <Form.Control type='file' onChange={uploadFiles} multiple={true}></Form.Control>
                        </Form.Group>
                        {uploadedFiles ? <UploadButton onClick={() => { uploadToServer() }}>Upload</UploadButton> : null}
                    </FlexUpload>
                </Modal.Header>
                <Modal.Body>
                    <Tabs defaultActiveKey='documents'>
                        <Tab eventKey='documents' title='Documents'>
                            <Row>
                                <Col>
                                    <ImageList>
                                        {images ? images.map((item) => (
                                            <ImageItem key={item.id} selected={(selectedFiles.includes(item.id) ? true : false)} onClick={() => { setViewItem(item); setViewItemName(item.name ? item.name : 'None'); selectHandler(item.id); setNeedUpdate(false); }}>
                                                <File />
                                                <ImageItemName>{item.src}</ImageItemName>
                                            </ImageItem>
                                        )) : null}
                                    </ImageList>
                                </Col>
                                <Col>
                                    <ViewContainer>
                                        {viewItem ?
                                            <File />
                                            : <File />}
                                        {viewItem ?
                                            <Form.Group>
                                                <Form.Label>File name</Form.Label><Form.Control value={viewItem.src}></Form.Control></Form.Group>
                                            : <Form.Group><Form.Label>File name</Form.Label><Form.Control value='Select item'></Form.Control></Form.Group>}
                                        {viewItem ?
                                            <Form.Group>
                                                <Form.Label>File ID</Form.Label><Form.Control value={viewItem.id}></Form.Control></Form.Group>
                                            : <Form.Group><Form.Label>File ID</Form.Label><Form.Control value='Select item'></Form.Control></Form.Group>}
                                        {viewItem ?
                                            <Form.Group>
                                                <Form.Label>File type</Form.Label><Form.Control value={viewItem.type}></Form.Control></Form.Group>
                                            : <Form.Group><Form.Label>File type</Form.Label><Form.Control value='Select item'></Form.Control></Form.Group>}
                                        {viewItem ?
                                            <Form.Group>
                                                <Form.Label>Name</Form.Label>
                                                <UpdateView>
                                                    <Form.Control value={viewItemName ? viewItemName : null} onChange={(e) => { setViewItemName(e.target.value); setNeedUpdate(true); }}></Form.Control>
                                                    {needUpdate ? <Button onClick={() => { updateItem() }}>Update data</Button> : null}
                                                </UpdateView>
                                            </Form.Group>
                                            : <Form.Group><Form.Label>Name</Form.Label><Form.Control value="Select item"></Form.Control></Form.Group>}
                                    </ViewContainer>
                                </Col>
                            </Row>
                        </Tab>
                    </Tabs>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => { setShowModal(false); setGallery(selectedFiles); setReload(!reload) }}>Select</Button>
                    <Button onClick={() => { setShowModal(false) }}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }
    else {
        return (
            <div></div>
        )
    }
}

export default FMAddFiles