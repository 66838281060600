import React, { useEffect, useContext } from "react";
import AdminUI from "../../Ui/AdminUI";
import { Editor } from 'react-draft-wysiwyg';
import { useState } from "react";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Card, Form, Button, Container, Row, Col, Table } from "react-bootstrap";
import styled from "styled-components";
import { createPost, decodeEditorBody, encodeEditorBody, fetchOnePost, updatePost } from "../../../../api/postAPI";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { Context } from "../../../..";
import { useNavigate, useParams } from "react-router-dom";
import { fetchCategories, getFileUrl } from "../../../../api/productAPI";
import FMAddImage from "../../FileManager/FMAddImage";
import { EditorState } from "draft-js";
import { fetchPostCategories } from "../../../../api/categoriesAPI";
import { fetchAttributes } from "../../../../api/attributeAPI";
import Select from 'react-select';
import CardHeader from "react-bootstrap/esm/CardHeader";
import { getHeaderAdContent, headerAdsAction } from "../../../../api/contentAPI";


// Стилизованные компоненты
const FlexBox = styled.div`
display: flex;
justify-content: space-between;
`;

const SelectFiles = styled(Form.Group)`
margin-top: 10px;
display: flex;
justify-content: space-between;
flex-direction: column;
`;

const PreviewFlex = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
`;



const CatsViewer = styled.ul`
`;

const FlexTitle = styled.div`
display: flex;
margin-bottom: 20px;
`;

const PageTitle = styled.h1`
margin: 0px;
margin-right: 20px;
`

const Preview = styled.img`
width: 256px;
height: 256px;
object-fit: cover;
margin: 10px 0px 10px 0px;
`;



const EditPromo = () => {

    const { id } = useParams();
    const [primaryImage, setPrimaryImage] = useState('/placeholder/no-img.gif');
    const [secondaryImage, setSecondaryImage] = useState('/placeholder/no-img.gif');
    const [showFirstAddModal, setFirstShowAddModal] = useState(false);
    const [showSecondAddModal, setSecondShowAddModal] = useState(false);
    const [firstExist, setFirstExist] = useState(false);
    const [first, setFirst] = useState(null);
    const [second, setSecond] = useState(null);
    const [secondExist, setSecondExist] = useState(false);
    const [firstText, setFirstText] = useState('');
    const [secondText, setSecondText] = useState('');
    const [firstUrl, setFirstUrl] = useState('');
    const [secondUrl, setSecondUrl] = useState('');

    // States
    useEffect(() => {
        getHeaderAdContent().then((data) => {
            if (data.length && data.length > 0) {
                data.map((elem) => {
                    if (elem.type == 'headerAd1') {
                        setPrimaryImage(elem.img)
                        setFirst(elem.id);
                        setFirstText(elem.main_text);
                        setFirstUrl(elem.dest_link);
                        setFirstExist(true);
                    }
                    if (elem.type == 'headerAd2') {
                        setSecondaryImage(elem.img)
                        setSecond(elem.id);
                        setSecondText(elem.main_text);
                        setSecondUrl(elem.dest_link);
                        setSecondExist(true);
                    }
                })
            }
        })
    }, [])

    const updateItem = (itemtype) => {
        let formdata = new FormData()
        if (itemtype == 'headerAd1') {
            if (first) {
                formdata.append('id', first)
            }
            formdata.append('main_text', firstText);
            formdata.append('dest_link', firstUrl);
            formdata.append('img', primaryImage)
            formdata.append('type', 'headerAd1')
        }
        else if (itemtype == 'headerAd2') {
            if (second) {
                formdata.append('id', second)
            }
            formdata.append('main_text', secondText);
            formdata.append('dest_link', secondUrl);
            formdata.append('img', secondaryImage)
            formdata.append('type', 'headerAd2')
        }
        else {
            return false
        }
        headerAdsAction(formdata).then((data) => {
            if (data.status = 'headerAd1_updated') {
                NotificationManager.success('Promo block 1 updated', 'Success')
                setTimeout(() => {
                    window.location.reload()
                }, 1500)
            }
            else if (data.status = 'headerAd2_updated') {
                NotificationManager.success('Promo block 1 updated', 'Success')
                setTimeout(() => {
                    window.location.reload()
                }, 1500)
            }
            else {
                NotificationManager.error('Check developer console', 'Error')
                console.log(data);
            }
        })
    }

    const { user } = useContext(Context)

    return (
        <AdminUI>
            <NotificationContainer />
            <FlexTitle>
                <PageTitle>Edit header promo elements</PageTitle>
            </FlexTitle>
            <Form className="mb-5">
                <Container>
                    <Row>
                        <Col xs={6}>
                            {firstExist ? <Card>
                                <Card.Header>First promo element</Card.Header>
                                <Card.Body>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                        <Preview src={getFileUrl(primaryImage)}></Preview>
                                        <Button style={{ marginBottom: '15px' }} onClick={() => { setFirstShowAddModal(true) }}>Select image</Button>
                                        <Button style={{ marginBottom: '15px' }} onClick={() => { updateItem('headerAd1') }} >Save changes</Button>
                                    </div>
                                    <Form.Label>Text</Form.Label>
                                    <Form.Control type="text" placeholder="Enter promo text" style={{ marginBottom: '10px' }} value={firstText} onChange={(e) => { setFirstText(e.target.value) }}></Form.Control>
                                    <Form.Label>Url</Form.Label>
                                    <Form.Control type="text" placeholder="Enter promo link" value={firstUrl} onChange={(e) => { setFirstUrl(e.target.value) }}></Form.Control>
                                </Card.Body>
                            </Card> : <Card>
                                <Card.Header>First promo element</Card.Header>
                                <Card.Body>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                        <Preview src={getFileUrl(secondaryImage)}></Preview>
                                        <Button style={{ marginBottom: '15px' }} onClick={() => { setFirstShowAddModal(true) }}>Select image</Button>
                                        <Button style={{ marginBottom: '15px' }} onClick={() => { updateItem('headerAd1') }} >Save changes</Button>
                                    </div>
                                    <Form.Label>Text</Form.Label>
                                    <Form.Control type="text" placeholder="Enter promo text" style={{ marginBottom: '10px' }} onChange={(e) => { setFirstText(e.target.value) }}></Form.Control>
                                    <Form.Label>Url</Form.Label>
                                    <Form.Control type="text" placeholder="Enter promo link" onChange={(e) => { setFirstUrl(e.target.value) }}></Form.Control>
                                </Card.Body>
                            </Card>}
                        </Col>
                        <Col xs={6}>
                            {secondExist ? <Card>
                                <Card.Header>Second promo element</Card.Header>
                                <Card.Body>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                        <Preview src={getFileUrl(secondaryImage)}></Preview>
                                        <Button style={{ marginBottom: '15px' }} onClick={() => { setSecondShowAddModal(true) }}>Select image</Button>
                                        <Button style={{ marginBottom: '15px' }} onClick={() => { updateItem('headerAd2') }} >Save changes</Button>
                                    </div>
                                    <Form.Label>Text</Form.Label>
                                    <Form.Control type="text" placeholder="Enter promo text" style={{ marginBottom: '10px' }} value={secondText} onChange={(e) => { setSecondText(e.target.value) }}></Form.Control>
                                    <Form.Label>Url</Form.Label>
                                    <Form.Control type="text" placeholder="Enter promo link" value={secondUrl} onChange={(e) => { setSecondUrl(e.target.value) }}></Form.Control>
                                </Card.Body>
                            </Card> : <Card>
                                <Card.Header>First promo element</Card.Header>
                                <Card.Body>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                        <Preview src={getFileUrl(secondaryImage)}></Preview>
                                        <Button style={{ marginBottom: '15px' }} onClick={() => { setSecondShowAddModal(true) }}>Select image</Button>
                                        <Button style={{ marginBottom: '15px' }} onClick={() => { updateItem('headerAd2') }} >Save changes</Button>
                                    </div>
                                    <Form.Label>Text</Form.Label>
                                    <Form.Control type="text" placeholder="Enter promo text" style={{ marginBottom: '10px' }} onChange={(e) => { setSecondText(e.target.value) }}></Form.Control>
                                    <Form.Label>Url</Form.Label>
                                    <Form.Control type="text" placeholder="Enter promo link" onChange={(e) => { setSecondUrl(e.target.value) }}></Form.Control>
                                </Card.Body>
                            </Card>}
                        </Col>
                    </Row>
                </Container>
            </Form>
            <FMAddImage showModal={showFirstAddModal} setShowModal={setFirstShowAddModal} image={primaryImage} setImage={setPrimaryImage} />
            <FMAddImage showModal={showSecondAddModal} setShowModal={setSecondShowAddModal} image={secondaryImage} setImage={setSecondaryImage} />
        </AdminUI >
    )
}

export default EditPromo