import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from 'react-bootstrap';
import { updatePostCategory } from "../../../../api/categoriesAPI";
import { fetchCategories } from "../../../../api/productAPI";


const EditPostCategoryModal = ({ modalActive, setModalActive, editItem, editTitle, editId }) => {

    const [name, setName] = useState(editTitle);
    const [categories, setCategories] = useState([]);
    const [childOf, setChildOf] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [currentId, setCurrentId] = useState(editId);

    useEffect(() => {
        fetchCategories().then((data) => {
            setCategories(data);
        })
    }, [])

    const handleAction = () => {
        setModalActive(false)
        editItem(name);
    };

    const handleClose = () => {
        setModalActive(false);
    }
    return (
        <>
            <Modal show={modalActive} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add new category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Label>Enter category name</Form.Label>
                    <Form.Control type="text" placeholder="Category name" onChange={(e) => { setName(e.target.value) }} />
                    <Form.Label>Select parent category</Form.Label>
                    <Form.Select onChange={(e) => { setChildOf(e.target.value) }}>
                        <option>Select parent category</option>
                        {categories.map((item) => (
                            <option key={item.id} value={item.id}>{item.name}</option>
                        ))}
                    </Form.Select>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleAction}>
                        Add
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default EditPostCategoryModal