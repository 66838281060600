import React, { useState, useEffect, useContext, useMemo } from "react";
import { Spinner } from "react-bootstrap";
import styled from "styled-components";
import { Context } from "../../..";
import { fetchProducts } from "../../../api/productAPI";
import { getAllWishlists } from "../../../api/wishlistAPI";
import ProductCard from "../Product/ProductCard/ProductCard";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { Helmet } from "react-helmet";
import Pagination from "./Pagination";
import Page from "../Interface/Page/Page";
import LoadingPlaceholder from "../../Functional/LoadingPlaceholder";
import ScrollToTop from "../../Functional/ScrollToTop";
import LinedButton from "../Interface/Buttons/LinedButton";

const ProductsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
`;

const Container = styled.section`
  @media screen and (min-width: 1128px) {
    width: 100%;
  }
`;

const LoadMoreContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
  justify-content: center;
`;

const ShopProducts = ({ isCard, attributesFilters, reload, products }) => {
  const { user } = useContext(Context);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [wishItemsLoaded, setWishItemsLoaded] = useState(false);
  const [wishlistedItems, setWishlistedItems] = useState([]);
  const [needReload, setNeedReload] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(9);
  const [filterReload, setFilterReload] = useState(reload);

  const currentData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return products.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, products, pageSize]);

  const pushNotificationWish = (title, type) => {
    if (type === "add") {
      NotificationManager.success(`${title} added to wishlist`);
    } else if (type === "remove") {
      NotificationManager.success(`${title} removed from wishlist`);
    } else if (type === "cart-add") {
      NotificationManager.success(`${title} added to cart`);
    }
  };

  useEffect(() => {
    if (user.isAuth) {
      getAllWishlists(user.user.id).then(
        (data) => {
          setWishlistedItems(data);
          setWishItemsLoaded(true);
          setIsLoaded(true);
        },
        (error) => {
          setError(error);
          setWishItemsLoaded(false);
        }
      );
    } else {
      setWishItemsLoaded(true);
      setIsLoaded(true);
    }
  }, [needReload]);
  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (user.isAuth && !products) {
    return (
      <Page>
        <LoadingPlaceholder />
      </Page>
    );
  } else if (!user.isAuth && !isLoaded) {
    return (
      <Page>
        <LoadingPlaceholder />
      </Page>
    );
  } else {
    return (
      <Container>
        <NotificationContainer />
        <ScrollToTop depend={currentPage} />
        <ProductsContainer>
          {currentData.length > 0 ? (
            currentData.map((item) => (
              <ProductCard
                variant={isCard ? "card" : "line"}
                item={item}
                key={item.id}
                wishlist={wishlistedItems}
                needReload={needReload}
                setNeedReload={setNeedReload}
                notificationPush={pushNotificationWish}
              />
            ))
          ) : (
            <h6>Products with this parameters not found</h6>
          )}
        </ProductsContainer>
        {products.length / pageSize > 1 ? (
          <LoadMoreContainer>
            <LinedButton
              title="Show more"
              onClick={() => {
                setPageSize(pageSize + 10);
              }}
            />
          </LoadMoreContainer>
        ) : null}
        <LoadMoreContainer>
          <Pagination
            currentPage={currentPage}
            totalCount={products.length}
            pageSize={pageSize}
            onPageChange={(page) => setCurrentPage(page)}
            style={{ 'margin-bottom': '25px' }}
          />
        </LoadMoreContainer>
      </Container>
    );
  }
};

export default ShopProducts;
