import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Context } from "../../../../..";
import { fetchBasket } from "../../../../../api/basketAPI";
import LoadingPlaceholder from "../../../../Functional/LoadingPlaceholder";
import DefaultButton from "../../Buttons/DefaultButton";
import { ReactComponent as CloseIcon } from "../images/close.svg";
import CartProduct from "./CartProduct";
import { ReactComponent as EmptyCartImg } from "../images/cartEmptyImg.svg";

const Cart = styled.div`
  z-index: 8;
  background-color: #fff;
  position: absolute;
  display: ${(props) => (props.expanded ? "block" : "none")};
  width: 400px;
  border: 1px solid #eaebec;
  border-radius: 4px;
  height: auto;
  right: 0px;
  top: 55px;
  box-sizing: border-box;
  padding: 20px 15px;
`;
const CloseCart = styled.button`
  background-color: #fff;
  border: none;
  position: absolute;
  right: 15px;
  top: 20px;
`;

const CartTitle = styled.h4`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #303236;
`;
const CartList = styled.ul`
  margin-top: 30px;
  overflow-y: scroll;
  max-height: 492px;
  padding: 0px;
  &*{
    scrollbar-width: auto;
    scrollbar-color: #a8a8a8 #ffffff;
  }
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a8a8a8;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }
`;




const CartInfo = styled.div`

`

const ItemsTotalRow = styled.p`
font-family: 'Proxima Nova';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 17px;
display: flex;
align-items: center;
color: #303236;
opacity: 0.6;
`

const Subtotal = styled.div`
display: flex;
justify-content: space-between;
`
const SubtotalText = styled.p`
font-family: 'Proxima Nova';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 22px;
display: flex;
align-items: center;
color: #303236;
`

const CartControls = styled.div`
display: flex;
align-items: center;
flex-direction: column;
`;

const ProceedButton = styled(DefaultButton)`
width: 100%;
height: 40px;
margin-bottom: 10px;
`
const ViewButton = styled(DefaultButton)`
width: 100%;
height: 40px;
`

const StyledLink = styled(Link)`
text-decoration: none;
width: 100%;
`

const Triangle = styled.div`
display: none;
@media screen and (min-width: 1128px){
display: block;
position: absolute;
width: 15px;
height: 15px;
right: 17px;
top: -7.5px;
background: #FFFFFF;
border-top: 1px solid #eaebec;
border-right: 1px solid #eaebec;
transform: rotate(-45deg);
z-index: 0;
}
`

const EmptyCartText = styled.p`
width: 188px;
height: 70px;
font-family: 'Proxima Nova';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 19px;
display: flex;
align-items: center;
color: #303236;
margin-left: 40px;
margin-bottom: 0px;
`
const EmptyCartContainer = styled.div`
display: flex;
`

const CartModal = ({ expanded, onClick, products, error, isLoaded, total }) => {
  const { user } = useContext(Context);
  if (error) {
    return (
      <Cart expanded={expanded}>
        <CloseCart onClick={() => onClick()}>
          <CloseIcon />
        </CloseCart>
        <CartTitle>Internal server error: {error}</CartTitle>
      </Cart>
    );
  }
  else if (!products || products.length < 1) {
    return (
      <Cart expanded={expanded}>
        <Triangle />
        <EmptyCartContainer>
          <EmptyCartImg />
          <EmptyCartText>You have no items in your shopping cart</EmptyCartText>
        </EmptyCartContainer>
        <CloseCart onClick={() => onClick()}>
          <CloseIcon />
        </CloseCart>
      </Cart>
    )
  }
  else if (isLoaded && user.user.id) {
    return (
      <Cart expanded={expanded}>
        <Triangle />
        <CloseCart onClick={() => onClick()}>
          <CloseIcon />
        </CloseCart>
        <CartTitle>Recently added items</CartTitle>
        <CartList>
          {products.map((item) => {
            return (
              <CartProduct
                key={Math.random(0, 1000, 1)}
                title={item.title}
                img={item.img}
                status={item.status}
                qty={item.qty}
                price={item.price}
              />
            );
          })}
        </CartList>
        <CartInfo>
          <ItemsTotalRow>{products.length} items in cart</ItemsTotalRow>
          <Subtotal>
            <SubtotalText>Cart subtotal:</SubtotalText>
            <SubtotalText>€{total}</SubtotalText>
          </Subtotal>
          <CartControls>
            <StyledLink to={'/checkout'}>
              <ProceedButton title={'Proceed to checkout'} />
            </StyledLink>
            <StyledLink to={'/cart'}>
              <ViewButton variant={'dark'} title={'View and edit cart'} />
            </StyledLink>
          </CartControls>
        </CartInfo>
      </Cart>
    );
  } else {
    return (
      <Cart expanded={expanded}>
        <CloseCart onClick={() => onClick()}>
          <CloseIcon />
        </CloseCart>
        <CartTitle><LoadingPlaceholder /></CartTitle>
      </Cart>
    );
  }
};

export default CartModal;
