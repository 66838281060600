import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { fetchWikiByUrl } from "../api/wikiAPI";
import Page from "../components/VitaforestUI/Interface/Page/Page";
import { Carousel } from "react-responsive-carousel";
import { getFileUrl } from "../api/productAPI";
import MediaQuery from "react-responsive";
import { Editor, EditorState } from "draft-js";
import { decodeEditorBody } from "../api/postAPI";
import { Helmet } from "react-helmet";
import LoadingPlaceholder from "../components/Functional/LoadingPlaceholder";
import WikiRelated from "../components/VitaforestUI/Interface/WikiRelated";

const Container = styled.div`
  width: calc(100vw - 38px);
  margin: 0 auto;

  @media screen and (min-width: 1128px) {
    width: 1128px;
    margin-bottom: 40px;
  }
`;
const Title = styled.h2`
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #303236;
  margin-top: 15px;
  @media screen and (min-width: 1128px) {
    font-weight: 600;
    font-size: 26px;
    line-height: 28px;
    margin-top: 30px;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1128px) {
    flex-direction: row;
  }
`;

const Galery = styled.div`
  img {
    object-fit: cover;
    width: 100%;
    aspect-ratio: 300/200;
    border-radius: 4px;
  }
  .carousel .thumbs-wrapper {
    margin: 0px;
  }
  .thumbs {
    padding: 0;
    margin: 0px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }
  .carousel .thumb {
    margin: 0;
    padding: 0;
    opacity: 0.3;
    border: none;
    margin-right: 10px;
    :last-child {
      margin-right: 0px;
    }
    &.selected {
      opacity: 1;
      border: none;
    }
  }

  @media screen and (min-width: 1128px) {
    width: 360px;
    margin-right: 24px;
    .slide {
      img {
        width: 360px;
      }
    }
  }
`;

const MainImage = styled.img`
  width: 100%;
  border-radius: 6px;
  object-fit: contain;
  @media screen and (min-width: 1128px) {
    height: 360px;
  }
`;

const Content = styled.div`
  @media screen and (min-width: 1128px) {
    width: 744px;
    border: 1px solid #eaebec;
    border-radius: 6px;
    background-color: #ffffff;
  }
`;

const Tabs = styled.div`
  margin-top: 20px;
  @media screen and (min-width: 1128px) {
    margin-top: 5px;
  }
`;
const TabsHeader = styled.div`
  display: flex;
  border-bottom: 1px solid #eaebec;
  overflow-x: auto;
  overflow-y: hidden;
  @media screen and (min-width: 1128px) {
    padding: 0px 30px;
    overflow-x: initial;
    overflow-y: initial;
  }
`;

const TabButton = styled.button`
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  color: #7f878b;
  padding: 10px;
  border: none;
  background-color: inherit;
  margin-bottom: -1px;
  min-width: 200px;
  box-sizing: border-box;
  border-bottom: 1px solid #eaebec;
  margin-bottom: -1px;
  &:hover,
  &:focus,
  &:active {
    color: #40bf6a;
    border-bottom: 1px solid #40bf6a;
  }
  &.current {
    color: #40bf6a;
    border-bottom: 1px solid #40bf6a;
  }
  @media screen and (min-width: 1128px) {
    font-size: 20px;
    line-height: 24px;
    min-width: 0px;
  }
`;

const Tab = styled.div`
  box-sizing: border-box;
  padding: 14px 25px 30px 25px;
`;

const General = styled.div`
  margin-top: 0px;
  h3 {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin: 0px;
    color: #333333;
  }
`;
const Contraindications = styled.div`
  margin-top: 0px;
  h3 {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin: 0px;
    color: #333333;
  }
`;
const External = styled.div`
  margin-top: 0px;
  h3 {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin: 0px;
    color: #333333;
  }
`;

const SingleWiki = () => {
  const { url } = useParams();

  // states
  const [wiki, setWiki] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [tab, setTab] = useState("general");
  // funcs
  useEffect(() => {
    fetchWikiByUrl(url).then(
      (data) => {
        setWiki(data);
        console.log(wiki);
        setIsLoaded(true);
      },
      (e) => {
        setError(e);
        setIsLoaded(false);
      }
    );
  }, []);
  if (error) {
    return (
      <Page>
        <h5>{error}</h5>
      </Page>
    );
  } else if (!isLoaded) {
    return (
      <Page>
        <LoadingPlaceholder />
      </Page>
    );
  } else {
    return (
      <Page background={"true"} seo_title={`${wiki.title} - Wiki - Vitaforest`} seo_description={`${wiki.seo_description} post in Vitaforest Wiki`}>
        <Container>
          <Title>{wiki.title}</Title>
          <FlexWrapper>
            <Galery>
              {wiki.gallery != 'null' ? (
                <>
                  <MediaQuery maxWidth={1127}>
                    <Carousel
                      infiniteLoop={true}
                      showArrows={false}
                      thumbWidth="20vw"
                      showIndicators={false}
                      showStatus={false}
                    >
                      {JSON.parse(wiki.gallery).map((item) => {
                        return <img src={getFileUrl(item)} />;
                      })}
                    </Carousel>
                  </MediaQuery>
                  <MediaQuery minWidth={1128}>
                    <Carousel
                      infiniteLoop={true}
                      showArrows={false}
                      thumbWidth="80px"
                      showIndicators={false}
                      showStatus={false}
                    >
                      {JSON.parse(wiki.gallery).map((item) => {
                        return <img src={getFileUrl(item)} />;
                      })}
                    </Carousel>
                  </MediaQuery>
                </>
              ) : null
              }
              {wiki.img ? <MainImage src={getFileUrl(wiki.img)} /> : null}
            </Galery>
            <Content>
              <Tabs>
                <TabsHeader>
                  <TabButton
                    className={tab == "general" ? "current" : null}
                    onClick={() => setTab("general")}
                  >
                    General information
                  </TabButton>
                  <TabButton
                    className={tab == "external" ? "current" : null}
                    onClick={() => setTab("external")}
                  >
                    External signs of raw materials
                  </TabButton>
                  <TabButton
                    onClick={() => setTab("contraindications")}
                    className={tab == "contraindications" ? "current" : null}
                  >
                    Contraindications
                  </TabButton>
                </TabsHeader>
                <Tab>
                  {tab == "general" && tab == "general" ? (
                    <General>
                      <Editor
                        editorState={EditorState.createWithContent(
                          decodeEditorBody(wiki.general)
                        )}
                        readOnly={true}
                      />
                    </General>
                  ) : tab == "external" ? (
                    <External>
                      <Editor
                        editorState={EditorState.createWithContent(
                          decodeEditorBody(wiki.external)
                        )}
                        readOnly={true}
                      />
                    </External>
                  ) : tab == 'contraindications' ? (
                    <Contraindications>
                      <Editor
                        editorState={EditorState.createWithContent(
                          decodeEditorBody(wiki.contraindications)
                        )}
                        readOnly={true}
                      />
                    </Contraindications>
                  ) : <h6>Unexpected error</h6>}
                </Tab>
              </Tabs>
            </Content>
          </FlexWrapper>
        </Container>
        <WikiRelated ids={wiki.attributeId} />
      </Page>
    );
  }
};
export default SingleWiki;
