import React, { useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import { approveFetchComments, commentApprove, fetchAllComments, fetchPosts, removeComment } from "../../../../api/postAPI";
import LoadingPlaceholder from "../../../Functional/LoadingPlaceholder";
import AdminUI from "../../Ui/AdminUI";
import styled from "styled-components";
import Pagination from "../../../VitaforestUI/Shop/Pagination";
import RemoveModal from "../RemoveModal";

const TableContainer = styled.div`

`
const EditLabel = styled.span`
color: white;
`

const ControlsTitle = styled.span`
margin-right: 15px;
`

const TotalItems = styled.select`
`


const CommentsList = () => {
    // states
    const [comments, setComments] = useState([]);
    const [postData, setPostData] = useState([]);
    const [loadedComments, setLoadedComments] = useState(false);
    const [modalActive, setModalActive] = useState(false);
    const [loadedPosts, setLoadedPosts] = useState(false);
    const [currentItemId, setCurrentItemId] = useState(0);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(9);
    const [needReload, setNeedReload] = useState(false);

    const currentData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * pageSize;
        let lastPageIndex;
        if (pageSize > comments.length) {
            lastPageIndex = firstPageIndex + comments.length;
        }
        else {
            lastPageIndex = firstPageIndex + pageSize;
        }
        return comments.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, comments, pageSize]);

    useEffect(() => {
        fetchAllComments().then((data) => {
            let result = [];
            data.map((item) => {
                if (item.approved == true) {
                    result.push(item)
                }
            })
            setComments(result);
            setLoadedComments(true);
        }, (e) => {
            setError(e);
            setLoadedPosts(false);
            setLoadedComments(false);
        })
        fetchPosts(1, 10000).then((data) => {
            setPostData(data.rows);
            setLoadedPosts(true);
        }, (e) => {
            setError(e);
            setLoadedPosts(false);
            setLoadedComments(false);
        })
    }, [needReload]);

    const approveComment = (id) => {
        commentApprove(id).then((data) => {
            if (data.request) {
                NotificationManager.success('Comment approved', 'Success');
                setNeedReload(!needReload);
            }
            else {
                { NotificationManager.error(`${data.message}`, 'Error') }
            }
        })
    }

    const handleRemove = (id) => {
        setCurrentItemId(Number(id));
        setModalActive(true);
    }

    const removeCommentHandle = (id) => {
        removeComment(id).then((data) => {
            if (data.status == 'removed') {
                NotificationManager.success('Comment removed', 'Success');
                setNeedReload(!needReload);
            }
            else {
                NotificationManager.error(`${data.message}`, 'Error')
            }
        })
    }

    if (error) {
        return (
            <AdminUI>
                <NotificationContainer />
                {NotificationManager.error(`${error.message}`, 'Error')}
            </AdminUI>
        )
    }
    else if (!loadedComments || !loadedPosts) {
        return (
            <AdminUI>
                <LoadingPlaceholder />
            </AdminUI>
        )
    }
    else if (!comments[0]) {
        return (
            <AdminUI>
                <h1>All comments</h1>
                <h5>No items founded</h5>
            </AdminUI>
        )
    }
    else {
        return (
            <AdminUI>
                <h1>All comments</h1>
                <h5>Items total: {comments.length}</h5>
                <Row className="mb-3">
                    <Col>
                        <ControlsTitle>Items per page:</ControlsTitle>
                        <TotalItems onChange={(e) => { setPageSize(e.target.value) }}>
                            <option value={9}>Default</option>
                            <option value={25}>25 items</option>
                            <option value={50}>50 items</option>
                            <option value={100}>100 items</option>
                        </TotalItems>
                    </Col>
                </Row>
                <NotificationContainer />
                <Table striped bordered hover variant="dark">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Comment</th>
                            <th>Author</th>
                            <th>Assigned with post</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentData.length > 0 ? currentData.map((comment) => {
                            return (
                                <tr>
                                    <td>{comment.id}</td>
                                    <td>{comment.text}</td>
                                    <td><Link to={`/admin/users/edit/${comment.userId}`}>{comment.username}</Link></td>
                                    {postData.map((post) => {
                                        if (comment.postId == post.id) {
                                            return (
                                                <td><Link to={`/en/blog/${post.url}`}>{post.title}</Link></td>
                                            )
                                        }
                                    })}
                                    <td><Button variant="danger" onClick={() => { handleRemove(comment.id) }}>Remove</Button></td>
                                </tr>
                            )
                        }) : <h6>Comments not found</h6>}
                    </tbody>
                </Table>
                <Pagination currentPage={currentPage}
                    totalCount={comments.length}
                    pageSize={pageSize}
                    onPageChange={(page) => setCurrentPage(page)} />
                <RemoveModal modalActive={modalActive} setModalActive={setModalActive} itemId={currentItemId} removeItem={removeCommentHandle} />
            </AdminUI>
        )
    }
}

export default CommentsList