import React from "react";
import classnames from "classnames";
import { usePagination, DOTS } from "./usePagination";
import styled from "styled-components";

const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
    .left svg{
      transform: rotate(180deg);
    }
    svg:hover{
      cursor: pointer;
    }
`;

const StyledList = styled.ul`
  display: flex;
  align-items: center;
  gap: 8px;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-right: 10px;
  margin-left: 10px;
`;

const StyledListNumber = styled.li`
  &:hover,
  &:active {
    cursor: pointer;
  }
`;

const AdmPagination = (props) => {
    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
        className,
    } = props;

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize,
    });

    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }
    let lastPage = paginationRange[paginationRange.length - 1];
    const onNext = () => {
        if (currentPage === lastPage) {
            return false;
        } else {
            onPageChange(currentPage + 1);
        }
    };

    const onPrevious = () => {
        if (currentPage === 1) {
            return false;
        } else {
            onPageChange(currentPage - 1);
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        }
    };

    return (
        <PaginationWrapper className={classnames({ [className]: className })}>
            <div
                className={classnames("arrow left", { disabled: currentPage === 1 })}
                onClick={onPrevious}
            ><svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.6752 24.6063L20.7581 19.9707L17.3284 15.5854L18.3191 14.5604L22.4981 19.9038L18.7417 25.5522L17.6752 24.6063Z" fill="#3C3F54" />
                    <rect x="0.7" y="39.3" width="38.6" height="38.6" rx="19.3" transform="rotate(-90 0.7 39.3)" stroke="#3C3F54" stroke-width="1.4" />
                </svg>
            </div>
            <StyledList>
                {paginationRange.map((pageNumber) => {
                    if (pageNumber === DOTS) {
                        return <li className="pagination-item dots">&#8230;</li>;
                    }

                    return (
                        <StyledListNumber
                            className={classnames("pagination-item", {
                                selected: pageNumber === currentPage,
                            })}
                            onClick={() => {
                                onPageChange(pageNumber);
                                window.scrollTo({
                                    top: 0,
                                    left: 0,
                                    behavior: "smooth",
                                });
                            }}
                        >
                            {pageNumber}
                        </StyledListNumber>
                    );
                })}
            </StyledList>
            <div
                className={classnames("arrow right", {
                    disabled: currentPage === lastPage,
                })}
                onClick={onNext}
            ><svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.6752 24.6063L20.7581 19.9707L17.3284 15.5854L18.3191 14.5604L22.4981 19.9038L18.7417 25.5522L17.6752 24.6063Z" fill="#3C3F54" />
                    <rect x="0.7" y="39.3" width="38.6" height="38.6" rx="19.3" transform="rotate(-90 0.7 39.3)" stroke="#3C3F54" stroke-width="1.4" />
                </svg>
            </div>
        </PaginationWrapper>
    );
};

export default AdmPagination;