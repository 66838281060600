import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import { approveFetchComments, commentApprove, fetchPosts, removeComment } from "../../../../api/postAPI";
import LoadingPlaceholder from "../../../Functional/LoadingPlaceholder";
import AdminUI from "../../Ui/AdminUI";

const CommentsApprove = () => {
    // states
    const [comments, setComments] = useState([]);
    const [postData, setPostData] = useState([]);
    const [loadedComments, setLoadedComments] = useState(false);
    const [loadedPosts, setLoadedPosts] = useState(false);
    const [error, setError] = useState(null);
    const [needReload, setNeedReload] = useState(false);

    useEffect(() => {
        approveFetchComments().then((data) => {
            let result = [];
            data.map((item) => {
                if (item.approved == false) {
                    result.push(item)
                }
            })
            setComments(result);
            setLoadedComments(true);
        }, (e) => {
            setError(e);
            setLoadedPosts(false);
            setLoadedComments(false);
        })
        fetchPosts(1, 10000).then((data) => {
            setPostData(data.rows);
            setLoadedPosts(true);
        }, (e) => {
            setError(e);
            setLoadedPosts(false);
            setLoadedComments(false);
        })
    }, [needReload]);

    const approveComment = (id) => {
        commentApprove(id).then((data) => {
            if (data.request) {
                NotificationManager.success('Comment approved', 'Success');
                setNeedReload(!needReload);
            }
            else {
                { NotificationManager.error(`${data.message}`, 'Error') }
            }
        })
    }

    const removeCommentHandle = (id) => {
        removeComment(id).then((data) => {
            if (data.status == 'removed') {
                NotificationManager.success('Comment removed', 'Success');
                setNeedReload(!needReload);
            }
            else {
                NotificationManager.error(`${data.message}`, 'Error')
            }
        })
    }

    if (error) {
        return (
            <AdminUI>
                <NotificationContainer />
                {NotificationManager.error(`${error.message}`, 'Error')}
            </AdminUI>
        )
    }
    else if (!loadedComments || !loadedPosts) {
        return (
            <AdminUI>
                <LoadingPlaceholder />
            </AdminUI>
        )
    }
    else if (!comments[0]) {
        return (
            <AdminUI>
                <h1>Unapproved comments</h1>
                <h5>No items founded</h5>
            </AdminUI>
        )
    }
    else {
        return (
            <AdminUI>
                <NotificationContainer />
                <Row>
                    <Col>
                        <h1>Unapproved post comments</h1>
                        {comments.map((comment) => {
                            return (
                                <Card className="mb-3">
                                    {postData.map((item) => {
                                        if (item.id == comment.postId) {
                                            return (
                                                <Card.Header>Comment for post <Link to={`/en/blog/${item.url}`}>"{item.title}"</Link></Card.Header>
                                            )
                                        }
                                    }
                                    )
                                    }
                                    <Card.Body>
                                        <Card.Title>Commented by <Link to={`/admin/users/edit/${comment.userId}`}>{comment.username}</Link></Card.Title>
                                        <Card.Text>
                                            {comment.text}
                                        </Card.Text>
                                        <Button style={{ marginRight: '20px' }} onClick={() => { approveComment(comment.id) }}>Approve comment</Button>
                                        <Button variant="danger" onClick={() => { removeCommentHandle(comment.id) }}>Delete comment</Button>
                                    </Card.Body>
                                </Card>
                            )
                        })}
                    </Col>
                    <Col>
                    </Col>
                </Row>
            </AdminUI>
        )
    }
}

export default CommentsApprove