import React, { useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { createAttribute, createAttributeTerm, fetchAttributes, fetchAttributeTerms, removeAttributeTerm, updateAttribute, updateAttributeTerm } from "../../../../api/attributeAPI";
import AdminUI from "../../Ui/AdminUI";
import AddAttributeTermModal from "./AddAttributeTermModal";
import EditAttributeModal from "./EditAttributeModal";
import RemoveAttributeTermModal from "./RemoveAttributeTermModal";
import { Link } from "react-router-dom";
const TableContainer = styled.div`

`

const FlexBox = styled.div`
display: flex;
`

const FlexTitle = styled.div`
display: flex;
margin-bottom: 20px;
`;

const PageTitle = styled.h1`
margin: 0px;
margin-right: 20px;
`

const EditLabel = styled(Link)`
color: white;
text-decoration: none;
&:hover{
    color: white;
}
`

const AttributeTerms = () => {
    const { id } = useParams();
    const [attributes, setAttributes] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [modalActive, setModalActive] = useState(false);
    const [editModalActive, setEditModalActive] = useState(false);
    const [removeModal, setRemoveModal] = useState(false);
    const [removableId, setRemovableId] = useState(null);
    const [editId, setEditId] = useState(null);
    const [editTitle, setEditTitle] = useState(null);
    const [needReload, setNeedReload] = useState(false);
    // Loading cats for table draw
    useEffect(() => {
        fetchAttributeTerms(id).then((data) => {
            setAttributes(data);
        }).finally(() => {
            setIsLoaded(true);
        })
    }, [needReload])

    // Open add modal
    const openAddModal = () => {
        setModalActive(true)
    }

    const openRemoveModal = (id) => {
        setRemovableId(id)
        setRemoveModal(true);
    }

    const openEditModal = (id, name) => {
        setEditId(id);
        setEditTitle(name);
        setEditModalActive(true);
    }

    const updateSelectedAttribute = (name) => {
        let formdata = new FormData();
        formdata.append('id', editId);
        formdata.append('name', name);
        formdata.append('attributeId', id);
        updateAttributeTerm(formdata).then((data) => {
            setEditId(null);
            setEditTitle(null);
            setNeedReload(!needReload);
            if (data.status == 'updated') {
                NotificationManager.success(`Term updated`, 'Success')
            }
            else {
                NotificationManager.error(`${data.message}`, 'Error')
            }
        })
    }


    const removeCurrentAttributeTerm = (id) => {
        removeAttributeTerm(id).then((data) => {
            if (data.status == 'removed') {
                NotificationManager.success(`Term removed`, 'Success')
            }
            else {
                NotificationManager.error(`${data.message}`, 'Error')
            }
            setNeedReload(!needReload);
            setRemovableId(null);
        })
    }

    const addAttribute = (name) => {
        let formdata = new FormData();
        formdata.append('name', name);
        formdata.append('attributeId', id);
        createAttributeTerm(formdata).then((data) => {
            if (data.id) {
                NotificationManager.success('Term added', 'Success');
                setNeedReload(!needReload);
            }
            else {
                NotificationManager.error(`${data.message}`, 'Error');
            }
        })
    }
    if (!isLoaded) {
        return (
            <AdminUI>
                <h5>Loading</h5>
            </AdminUI>
        )
    }
    return (
        <AdminUI>
            <FlexTitle>
                <PageTitle>Attribute terms</PageTitle>
                <Button onClick={() => openAddModal()}>Add new</Button>
            </FlexTitle>
            <NotificationContainer />
            <TableContainer>
                <Table striped bordered hover variant="dark">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Term name</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {attributes.map((item) => (
                            <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                <td><Button onClick={() => openEditModal(item.id, item.name)}>Edit</Button></td>
                                <td><Button onClick={() => openRemoveModal(item.id)}>Delete</Button></td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </TableContainer>
            <AddAttributeTermModal modalActive={modalActive} setModalActive={setModalActive} addItem={addAttribute} />
            <RemoveAttributeTermModal modalActive={removeModal} setModalActive={setRemoveModal} itemId={removableId} removeItem={removeCurrentAttributeTerm} />
            <EditAttributeModal modalActive={editModalActive} setModalActive={setEditModalActive} editId={editId} editTitle={editTitle} editItem={updateSelectedAttribute} />
        </AdminUI >
    )

}

export default AttributeTerms