import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";


const Container = styled.div`
  box-sizing: border-box;
  padding: 30px;
  background-image:  url(${(props) => props.image.length > 0 ? props.image : '/'});
  width: 258px;
  border-radius: 5px;
  height: 100%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
`;

const LinkItem = styled(Link)`
  font-size: 13px;
  line-height: 16px;
  text-decoration-line: none;
  color: #40bf6a;
  position: relative;
  opacity: 1;
  z-index: 2;
  filter: none;
  &::after {
    content: url("/promo_arrow.svg");
  }
  &:hover, &:active{
    color: #40bf6a;
    text-decoration-line: underline;
  }
`;

const Title = styled(Link)`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  text-decoration: none;
  margin-bottom: 8px;
  margin-top: auto;
  opacity: 1;
  z-index: 2;
  &:hover, &:active{
    text-decoration: underline;
    color: white;
  }
`;

const Overlay = styled.div`
position: absolute;
top: 0px;
left: 0px;
z-index: 1;
width: 100%;
height: 100%;
background-color: black;
opacity: 50%;
border-radius: 5px;
`

export default function Promo({ img, title, url }) {
  return (
    <Container image={img}>
      <Overlay />
      <Title to={url}>{title}</Title>
      <LinkItem to={url}>Shop now </LinkItem>
    </Container>
  );
}
