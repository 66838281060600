import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Page from "../../Interface/Page/Page";
import { Helmet } from "react-helmet";
import AccountMenu from "../subcomponents/AccountMenu";
import PageHeader from "../subcomponents/PageHeader";
import Input from "../../Interface/Input/Input";
import Textarea from "../../Interface/Textarea/Textarea";
import DefaultButton from "../../Interface/Buttons/DefaultButton";
import Required from "../../Interface/TextElements/Required";
import { Context } from "../../../..";
import { createTicket } from "../../../../api/ticketAPI";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { Link, useNavigate, useParams } from "react-router-dom";

const TemporaryHide = styled.div`
  @media screen and (max-width: 1127px) {
    display: none;
  }
`;
const Container = styled.div`
  margin-top: 20px;
  @media screen and (min-width: 1128px) {
    margin-top: 30px;
    margin-bottom: 40px;
    display: flex;
    width: 1128px;
    margin: 30px auto;
  }
`;

const Wrapper = styled.div`
  width: calc(100vw - 36px);
  margin: 0 auto;
  @media screen and (min-width: 1128px) {
    width: auto;
    margin: 0;
  }
`;

const AddTicketForm = styled.form`
  @media screen and (min-width: 1128px) {
    width: 430px;
  }
`;

const Button = styled(DefaultButton)`
  margin: 25px auto;
`;

const MobileBackButton = styled(Link)`
&:before{
  content: url('/arrow-back.svg');
}
text-decoration: none;
color: black;
position: absolute;
top: -5px;
margin-left: 20px;
vertical-align: center;
@media screen and (min-width: 1128px){
  display: none;
}
&:hover{
  color: inherit;
}
&:active{
  color: inherit;
}
`;


export default function NewTicket() {
  const navigate = useNavigate();
  const params = useParams();
  const { user } = useContext(Context);

  const [subject, setSubject] = useState(null);
  const [message, setMessage] = useState(null);
  useEffect(() => {
    if (params) {
      setSubject(`Question about product: ${params.params.split('=')[1]}`);
    }
  }, [])
  const createNew = () => {
    let formdata = new FormData();
    formdata.append('message', message);
    formdata.append('subject', subject);
    formdata.append('userId', user.user.id);
    formdata.append('senderName', `${user.user.first_name} ${user.user.last_name}`);
    createTicket(formdata).then((data) => {
      if (data.id) {
        NotificationManager.success(`Ticket #${data.id} updated. New message added.`, 'Success');
        setSubject('');
        setMessage('');
        setTimeout(() => {
          navigate('/account/tickets')
        }, 1000)
      }
      else {
        NotificationManager.error(`${data.message}`, 'Error');
      }
    })
  }

  return (
    <Page background={false} seo_title={'Vitaforest - Account - New Ticket'} seo_description={'Vitaforest - Account details'}>
      <NotificationContainer />
      <Container>
        <TemporaryHide>
          <AccountMenu />
        </TemporaryHide>
        <Wrapper>
          <MobileBackButton />
          <PageHeader>Create new ticket</PageHeader>
          <p>Jot us a note and we’ll get back to you as quickly as possible</p>
          <AddTicketForm>
            <Input
              label="Subject"
              requied={true}
              placeholder="Name your ticket"
              value={subject}
              onChange={(e) => { setSubject(e.target.value) }}
            />
            <Textarea
              label="Message"
              requied={true}
              placeholder="Yout message"
              value={message}
              onChange={(e) => { setMessage(e.target.value) }}
            />
            <Required />
            <Button title="Submit" onClick={(e) => { e.preventDefault(); createNew() }} />
          </AddTicketForm>
        </Wrapper>
      </Container>
    </Page>
  );
}
