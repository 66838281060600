import React, { useState, useEffect, useMemo } from "react";
import { Button, Table, Dropdown, Form, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { NotificationManager, NotificationContainer } from 'react-notifications';
import { getUrlsList, removeUrl } from "../../../../api/urlsApi";
import AdminUI from "../../Ui/AdminUI";
import { fetchAttributes } from "../../../../api/attributeAPI";
import { fetchCategories } from "../../../../api/productAPI";
import Pagination from "../../../VitaforestUI/Shop/Pagination";
import { useNavigate } from "react-router-dom";
import LoadingPlaceholder from "../../../Functional/LoadingPlaceholder";

const TableContainer = styled.div`

`
const EditLabel = styled.span`
color: white;
`

const ControlsTitle = styled.span`
margin-right: 15px;
`

const TotalItems = styled.select`
`

const HeadContainer = styled.div`
display: flex;
h1{
    margin-right: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
}
`

function UrlsList() {

    const nav = useNavigate();

    // States
    const [items, setItems] = useState([])
    const [needReload, setNeedReload] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const [error, setError] = useState(null)
    const [modalActive, setModalActive] = useState(false);
    const [currentItemId, setCurrentItemId] = useState(0);
    const [attrs, setAttrs] = useState([]);
    const [cats, setCats] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(9);

    const currentData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * pageSize;
        let lastPageIndex;
        if (pageSize > items.length) {
            lastPageIndex = firstPageIndex + items.length;
        }
        else {
            lastPageIndex = firstPageIndex + pageSize;
        }
        return items.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, items, pageSize]);


    useEffect(() => {
        getUrlsList().then(
            (data) => {
                setItems(data)
                setIsLoaded(true)
            },
            (e) => {
                setError(e)
                setIsLoaded(false)
            },
        )
    }, [needReload])
    useEffect(() => {
        fetchAttributes().then((data) => {
            setAttrs(data);
        }, (e) => {
            setError(e)
            setIsLoaded(false)
        },)
    }, [])
    useEffect(() => {
        fetchCategories().then((data) => {
            setCats(data);
        }, (e) => {
            setError(e)
            setIsLoaded(false)
        },)
    }, [])
    if (error) {
        return (
            <AdminUI>
                {NotificationManager.error(`${error.message}`, 'Error')}
                <NotificationContainer />
            </AdminUI>
        )
    } else if (!isLoaded) {
        return (
            <AdminUI>
                <LoadingPlaceholder />
            </AdminUI>
        )
    } else {
        return (

            <AdminUI>
                <TableContainer>
                    <HeadContainer>
                        <h1>All URLs</h1>
                        <Button onClick={() => { nav('/admin/products/urls/add') }}>Create new</Button>
                    </HeadContainer>
                    <h5>Items total: {items.length}</h5>
                    <Row className="mb-3">
                        <Col>
                            <ControlsTitle>Items per page:</ControlsTitle>
                            <TotalItems onChange={(e) => { setPageSize(e.target.value) }}>
                                <option value={9}>Default</option>
                                <option value={25}>25 items</option>
                                <option value={50}>50 items</option>
                                <option value={100}>100 items</option>
                            </TotalItems>
                        </Col>
                    </Row>
                    <NotificationContainer />
                    <Table striped bordered hover variant="dark">
                        <thead>
                            <tr>
                                <th>Endpoint path</th>
                                <th>Included attributes</th>
                                <th>Included categories</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map((item) => (
                                <tr key={item.id}>
                                    <td>{item.url}</td>
                                    <td>
                                        {attrs
                                            .filter(attr => item.attributeId.includes(attr.id))
                                            .map(attr => attr.name)
                                            .join(", ")
                                        }
                                    </td>
                                    <td>
                                        {cats
                                            .filter(cat => item.categoryId.includes(cat.id))
                                            .map(cat => cat.name)
                                            .join(", ")
                                        }
                                    </td>
                                    <td><Button variant="danger" onClick={() => { removeUrl(item.id) }}>Remove</Button></td>
                                    <td><Button>Edit</Button></td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <Pagination currentPage={currentPage}
                        totalCount={items.length}
                        pageSize={pageSize}
                        onPageChange={(page) => setCurrentPage(page)} />
                </TableContainer>
            </AdminUI>
        )
    }
}

export default UrlsList