import React, { useState, useEffect, useContext } from "react";
import "./App.css";
import "react-notifications/lib/notifications.css";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./app-router/AppRouter";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import { observer } from "mobx-react-lite";
import { check } from "./api/userAPI";
import { Spinner } from "react-bootstrap";
import { Context } from ".";
import GlobalStyle from "./GlobalStyle.js";
import LoadingPlaceholder from "./components/Functional/LoadingPlaceholder";

const Page = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const Content = styled.div`
  flex: 1 0 auto;
`;
const App = observer(() => {
  const { user } = useContext(Context);
  const { basket } = useContext(Context);
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState(null);

  const yaMetricsHTML = `<!-- Yandex.Metrika counter -->
  <script type="text/javascript" >
     (function(m,e,t,r,i,k,a){m[i]=m[i]function(){(m[i].a=m[i].a[]).push(arguments)};
     m[i].l=1*new Date();
     for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
     k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
     (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
  
     ym(65590639, "init", {
          clickmap:true,
          trackLinks:true,
          accurateTrackBounce:true,
          webvisor:true
     });
  </script>
  <noscript><div><img src="https://mc.yandex.ru/watch/65590639" style="position:absolute; left:-9999px;" alt="" /></div></noscript>
  <!-- /Yandex.Metrika counter -->`;

  // Set user data
  useEffect(() => {
    check()
      .then((data) => {
        user.setUser(data);
        user.setIsAuth(true);
        setUserId(data.id);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // Set user basket

  if (loading) {
    return <LoadingPlaceholder />;
  }
  return (
    <Page>
      <div dangerouslySetInnerHTML={{ __html: yaMetricsHTML }} />
      <GlobalStyle></GlobalStyle>
      <BrowserRouter>
        <Content>
          <AppRouter />
        </Content>
      </BrowserRouter>
    </Page>
  );
});

export default App;
