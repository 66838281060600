import { $authHost } from ".";

export const getNotificationsByUser = async () => {
    try {
        const { data } = await $authHost.get('/api/notifications/byuser');
        return data
    } catch (e) {
        return e.response.data;
    }
}

export const getAllNotifications = async () => {
    try {
        const { data } = await $authHost.get('/api/notifications');
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const removeNotification = async (id) => {
    try {
        const { data } = await $authHost.post(`/api/notifications/remove/${id}`);
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const cleanAdminDashboard = async () => {
    try {
        const { data } = await $authHost.get('/api/notifications/delete-all');
        return data;
    } catch (e) {
        return e.response.data;
    }
}