import React, { useState } from "react";
import { useContext } from "react";
import styled from "styled-components";
import { Context } from "../../../../..";
import { wishlistUpdate, wishlistRemoveItem } from "../../../../../api/userAPI";
import Status from "../../../Interface/Status/Status";
import WishlistButton from "../../../Interface/WishlistButton/WishlistButton";
import { ReactComponent as Cart } from "../img/cart.svg";
import MediaQuery from "react-responsive";
import Counter from "../../../Interface/Counter/Counter";
import { addBasketItem } from "../../../../../api/basketAPI";
import { Link } from "react-router-dom";
import Modal from "../../../Interface/Modal";
import useModal from "../../../../Functional/useModal";
import useScrollBlock from "../../../../Functional/useScrollBlock";
import { getFileUrl } from "../../../../../api/productAPI";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { removeFromWishlist } from "../../../../../api/wishlistAPI";

const AbsoluteRow = styled.div`
  button {
    position: absolute;
    right: 5px;
    top: 5px;
  }
`;

const Sku = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #7f878b;
  margin: auto 0;
`;

const Title = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #303236;
  margin-bottom: 0px;
`;

const StatusLined = styled(Status)`
  font-weight: 600;
  font-size: 10px;
  line-height: 19px;
  color: #ffffff;
  background: #40bf6a;
  border-radius: 4px 0px 4px 0px;
  min-width: 70px;
  text-align: center;
  position: absolute;
  top: 0px;
  left: 0px;
`;

const LineContainer = styled.div`
  box-shadow: 0px 4px 10px rgba(26, 33, 45, 0.05);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 27px 10px 10px 10px;
  position: relative;
  width: 100%;
`;

const RowContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.first {
    margin-bottom: 8px;
    @media screen and (min-width: 1128px) {
      margin-bottom: 10px;
    }
  }
`;

const LinkElement = styled(Link)`
  text-decoration: none;
  color: inherit;
  &:hover {
    color: black;
  }
`;

const Button = styled.button`
  padding: 7px 10px;
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
  background: #40bf6a;
  border: none;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  align-items: center;
  margin-left: 10px;
  span {
    padding-left: 16px;
  }
  &:hover {
    background-color: #61d186;
  }
  &:active {
    background-color: #2e9e53;
  }
`;

const Flex = styled.div`
  display: flex;
`;

const Price = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #303236;
  margin-bottom: 0;
  margin-right: 15px;
`;

const Stock = styled.p`
  background: #f3f7f2;
  border-radius: 4px 4px 0px 4px;
  font-size: 11px;
  line-height: 13px;
  padding: 3px 8px;
  position: absolute;
  right: 0px;
  top: 0px;
`;

const StockText = styled.span`
  font-weight: 400;
  color: #303236;
`;

const StockValue = styled.span`
  color: #40bf6a;
  font-weight: 600;
`;

const LineLogged = ({
  img,
  stock,
  price,
  title,
  sku,
  status,
  id,
  needReload,
  setNeedReload,
  wishlist,
  notificationPush,
  step,
  moq,
  prices,
  url,
}) => {
  // states
  const [value, setValue] = useState(moq);
  const [pricesArray, setPricesArray] = useState(prices ? prices : false);
  const [itemPrice, setItemPrice] = useState(price);
  const { isShowing, toggle } = useModal();
  const [blockScroll, allowScroll] = useScrollBlock();
  const [wishModalOpen, setWishModalOpen] = useState(false);
  // funcs
  const isAddedToWishlist = (id) => {
    return wishlist.find((item) => {
      if (id === item) {
        return true;
      } else {
        return false;
      }
    });
  };

  const addToCart = (id, title, status, img, step, moq) => {
    let formdata = new FormData();
    formdata.append("title", title);
    formdata.append("img", img);
    formdata.append("status", status);
    formdata.append("price", itemPrice);
    formdata.append("qty", value);
    formdata.append("item_id", id);
    formdata.append("moq", moq);
    formdata.append("qty_step", step);
    formdata.append("prices", JSON.stringify(pricesArray));
    formdata.append("userId", user.user.id);
    addBasketItem(formdata).then((data) => {
      if (data.id) {
        toggle();
        blockScroll();
      } else {
        NotificationManager.error(`${data.message}`, "Error");
      }
    });
  };

  const calculatePrice = () => {
    pricesArray.find((item) => {
      if (value >= item.min && value <= item.max) {
        setItemPrice(item.price);
      }
    });
  };


  const wishRemoveHandle = (itemId) => {
    let wId;
    wishlist.map((wl) => {
      wl.items.find((elem) => {
        if (elem.itemId == itemId) {
          wId = wl.id
        }
      })
    })
    removeFromWishlist(itemId, wId).then((data) => {
      if (data.status == 'removed') {
        NotificationManager.success('Item removed from wishlist', 'Success');
        setNeedReload(!needReload);
      }
      else {
        NotificationManager.error(data.message, 'Error');
      }
    })
  }
  const { user } = useContext(Context);
  const BoundWish = (wishlist) => {
    let result = [];
    wishlist.map((item) => {
      result.push({ value: item.id, label: item.name });
    });
    return result;
  };

  const CheckInWish = (wish) => {
    let result;
    wish.map((item) => {
      item.items.map((elem) => {
        if (elem.itemId === id) {
          result = true;
        }
      });
    });
    return result;
  };

  return (
    <LineContainer>
      <NotificationContainer />
      <Modal
        type={"add-cart"}
        isShowing={isShowing}
        hide={toggle}
        item={title}
        link={url}
        image={getFileUrl(img)}
        allow={allowScroll()}
      />
      <Modal
        type={"wishlist"}
        isShowing={wishModalOpen}
        hide={() => setWishModalOpen(false)}
        wishlists={BoundWish(wishlist)}
        allow={allowScroll}
        notiManager={NotificationManager}
        reloadState={needReload}
        reloadStateSetter={setNeedReload}
        id={id}
      />
      <RowContent className="first">
        <Title>
          <LinkElement to={`/en/product/${url}`}>{title}</LinkElement>
        </Title>
        <Flex>
          {itemPrice ? <Price>€{itemPrice}</Price> : null}
          {CheckInWish(wishlist) ? (
            <WishlistButton
              className="wish"
              variant={"liked"}
              onClick={() => {
                wishRemoveHandle(id);
              }}
            />
          ) : (
            <WishlistButton
              className="wish"
              onClick={() => {
                setWishModalOpen(true);
              }}
            />
          )}
        </Flex>
      </RowContent>
      <RowContent>
        <Sku>{`SKU: ${sku}`}</Sku>
        <Flex>
          <MediaQuery minWidth={1128}>
            {pricesArray[0] ? (
              <Counter
                width="100px"
                value={value}
                step={step}
                qty={moq}
                min={moq}
                setValue={setValue}
                calcPrice={calculatePrice}
              />
            ) : (
              <Counter
                width="100px"
                value={value}
                step={step}
                qty={moq}
                min={moq}
                setValue={setValue}
              />
            )}
          </MediaQuery>
          <Button onClick={() => addToCart(id, title, status, img, step, moq)}>
            <Cart />
            <MediaQuery minWidth={1128}>
              {itemPrice ? <span>Add to cart</span> : <span>Preorder</span>}
            </MediaQuery>
          </Button>
        </Flex>
      </RowContent>
      <AbsoluteRow>
        <StatusLined variant={status} />

        {itemPrice ? (
          <Stock>
            <StockText>In stock: </StockText>
            <StockValue>{stock} kg</StockValue>
          </Stock>
        ) : (
          <Stock>
            <StockText>Preorder</StockText>
          </Stock>
        )}
      </AbsoluteRow>
    </LineContainer>
  );
};

export default LineLogged;
