import React, { useState } from "react";
import { Helmet } from "react-helmet";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { Link, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { sendForm } from "../api/formsAPI";
import DefaultButton from "../components/VitaforestUI/Interface/Buttons/DefaultButton";
import Input from "../components/VitaforestUI/Interface/Input/Input";
import Page from "../components/VitaforestUI/Interface/Page/Page";
import Textarea from "../components/VitaforestUI/Interface/Textarea/Textarea";

const Container = styled.div`
  width: calc(100vw - 36px);
  margin: 30px auto;
  @media screen and (min-width: 1128px) {
    width: 1128px;
    margin-top: 36px;
  }
`;
const Heading = styled.h2`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 17px;
  color: #303236;
  padding-bottom: 8px;
  border-bottom: 1px solid #303236;
  margin-bottom: 15px;
  @media screen and (min-width: 1128px) {
    font-weight: 600;
    font-size: 26px;
    border-bottom: none;
    margin-bottom: 20px;
  }
`;

const PageDescription = styled.p`
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  font-style: normal;
  text-align: justify;
  @media screen and (min-width: 1128px) {
    margin: 0px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }
`;

const ContentWrapper = styled.div`
  @media screen and (min-width: 1128px) {
    display: flex;
    gap: 44px;
    align-items: flex-start;
  }
`;

const ContactsForm = styled.div`
  margin-top: 30px;
  @media screen and (min-width: 1128px) {
    width: 552px;
  }
`;

const FormRow = styled.div`
  @media screen and (min-width: 1128px) {
    display: flex;
    gap: 24px;
  }
`;

const InputContainer = styled.div`
  @media screen and (min-width: 1128px) {
    width: 264px;
  }
`;

const Submit = styled(DefaultButton)`
  width: 284px;
  margin: 30px auto;
  @media screen and (min-width: 1128px) {
    width: 168px;
    margin: 30px 0px;
  }
`;

const ContactsInfo = styled.div`
  @media screen and (min-width: 1128px) {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    padding-top: 70px;
  }
`;

const InfoBlock = styled.div`
  @media screen and (min-width: 1128px) {
    width: 244px;
  }
`;
const InfoTitle = styled.h4`
  font-weight: 600;
  font-size: 20px;
  line-height: 17px;
  color: #303236;
  text-align: left;
  margin-top: 30px;
  @media screen and (min-width: 1128px) {
    margin-top: 0;
  }
`;

const InfoContent = styled.p`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  text-align: justify;
  color: #303236;
  @media screen and (min-width: 1128px) {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: justify;
    color: #303236;
  }
`;

const InfoContentCompany = styled.p`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  text-align: justify;
  color: #303236;
  @media screen and (min-width: 1128px) {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: justify;
    color: #303236;
  }
`;

const Warning = styled.p`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  color: #ef5e53;
  margin: 0px;
  margin-top: 15px;
  margin-bottom: 0px;
`;

const InfoMail = styled.a`
  color: #18a0fb;
  text-decoration: none;
  &:hover,
  &:active {
    text-decoration: underline;
  }
`;

const Socials = styled.ul`
  margin: 30px 0px;
  display: flex;
  gap: 10px;
  padding: 0px;
  list-style: none;
`;

const SocialsItem = styled.li`
  border: 1px solid #40bf6a;
  border-radius: 50%;
  &:hover,
  &:focus,
  &:active {
    background-color: #40bf6a;
    svg {
      path {
        fill: #ffffff;
      }
    }
  }
`;

const Map = styled.iframe`
  width: 100vw;
  height: 440px;
`;
export default function Contacts() {
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [message, setMessage] = useState(null);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const send = () => {
    if (email && name && message) {
      if (!isValidEmail(email)) {
        NotificationManager.error("Email is invalid", "Error");
      } else {
        let formdata = new FormData();
        formdata.append("name", name);
        formdata.append("email", email);
        formdata.append("phone", phone);
        formdata.append("message", message);
        formdata.append("from", "Contact us form");
        sendForm(formdata).then((data) => {
          if (data.id) {
            NotificationManager.success("Your message sended!", "Success");
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            NotificationManager.error(data.message, "Error");
          }
        });
      }
    } else {
      NotificationManager.warning("Fill all required fields", "Warning");
    }
  };

  return (
    <Page seo_title={`Contact us - Vitaforest`} seo_description={'Vitaforest contact us page'}>
      <NotificationContainer />
      <Container>
        <Heading>Got questions?</Heading>
        <PageDescription>
          If you’d like to learn more about us and our products, get in touch!
        </PageDescription>
        <ContentWrapper>
          <ContactsForm>
            <Input
              label="Name"
              required
              placeholder="Jhon"
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <FormRow>
              <InputContainer>
                <Input
                  label="Email"
                  type={"email"}
                  required
                  placeholder="myemail@gmail.com"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </InputContainer>
              <InputContainer>
                <Input
                  label="Phone"
                  placeholder="Type your phone number"
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                />
              </InputContainer>
            </FormRow>
            <Textarea
              label="What`s on your mind?"
              required
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />
            <Warning>* Required fields</Warning>
            <Submit
              title="Submit"
              variant="dark"
              onClick={() => {
                send();
              }}
            />
          </ContactsForm>
          <ContactsInfo>
            <InfoBlock>
              <InfoTitle>Company info</InfoTitle>
              <InfoContentCompany>
                Vitaforest Distribution OÜ
                <br />
                Register code: 14377464
                <br />
                VAT No: EE102049370
                <br />
              </InfoContentCompany>
            </InfoBlock>

            <InfoBlock>
              <InfoTitle>Contact info</InfoTitle>
              <InfoContent>
                Harju maakond, Tallinn,
                <br />
                Kesklinna linnaosa,
                <br />
                Vesivärava tn 50-301, 10152
                <br />
                <InfoMail href="mailto:info@vitaforest.eu">
                  info@vitaforest.eu
                </InfoMail>
                <br />
                +3728801043
                <br />
              </InfoContent>
            </InfoBlock>
            <InfoBlock>
              <InfoTitle>Follow us</InfoTitle>
              <Socials>
                <SocialsItem>
                  <a href="https://www.facebook.com/vitaforesteu">
                    <svg
                      width="50"
                      height="50"
                      viewBox="0 0 50 50"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M30.8105 27.1875L31.4941 22.6953H27.1484V19.7656C27.1484 18.4961 27.7344 17.3242 29.6875 17.3242H31.6895V13.4668C31.6895 13.4668 29.8828 13.125 28.1738 13.125C24.6094 13.125 22.2656 15.3223 22.2656 19.2285V22.6953H18.2617V27.1875H22.2656V38.125H27.1484V27.1875H30.8105Z"
                        fill="#40BF6A"
                      />
                    </svg>
                  </a>
                </SocialsItem>
                <SocialsItem>
                  <a href="https://www.linkedin.com/company/vitaforestfood/">
                    <svg
                      width="50"
                      height="50"
                      viewBox="0 0 50 50"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M34.375 14.6875H15.5762C14.7461 14.6875 14.0625 15.4199 14.0625 16.2988V35C14.0625 35.8789 14.7461 36.5625 15.5762 36.5625H34.375C35.2051 36.5625 35.9375 35.8789 35.9375 35V16.2988C35.9375 15.4199 35.2051 14.6875 34.375 14.6875ZM20.6543 33.4375H17.4316V23.0371H20.6543V33.4375ZM19.043 21.5723C17.9688 21.5723 17.1387 20.7422 17.1387 19.7168C17.1387 18.6914 17.9688 17.8125 19.043 17.8125C20.0684 17.8125 20.8984 18.6914 20.8984 19.7168C20.8984 20.7422 20.0684 21.5723 19.043 21.5723ZM32.8125 33.4375H29.541V28.3594C29.541 27.1875 29.541 25.625 27.8809 25.625C26.1719 25.625 25.9277 26.9434 25.9277 28.3105V33.4375H22.7051V23.0371H25.7812V24.4531H25.8301C26.2695 23.623 27.3438 22.7441 28.9062 22.7441C32.1777 22.7441 32.8125 24.9414 32.8125 27.7246V33.4375Z"
                        fill="#40BF6A"
                      />
                    </svg>
                  </a>
                </SocialsItem>
              </Socials>
            </InfoBlock>
          </ContactsInfo>
        </ContentWrapper>
      </Container>
      <Map
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2029.3411276032753!2d24.808793199999997!3d59.427384999999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4692ed68ede5daf1%3A0x4c59afde623e8814!2zVsOkaWtlLVBhYWxhIDIsIDExNDE0IFRhbGxpbm4sINCt0YHRgtC-0L3QuNGP!5e0!3m2!1sru!2sru!4v1674410570390!5m2!1sru!2sru"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></Map>
    </Page>
  );
}
