import React, { useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { NotificationContainer, NotificationManager } from "react-notifications";
import styled from "styled-components";
import { createPostCategory, fetchPostCategories, removeCategory, updatePostCategory } from "../../../../api/categoriesAPI";
import { createCategory, fetchCategories } from "../../../../api/productAPI";
import AdminUI from "../../Ui/AdminUI";
import RemoveModal from "../RemoveModal";
import AddPostCategoryModal from "./AddCategoryModal";
import AddCategoryModal from "./AddCategoryModal";
import EditPostCategoryModal from "./EditCategoryModal";
const TableContainer = styled.div`

`

const FlexBox = styled.div`
display: flex;
`

const FlexTitle = styled.div`
display: flex;
margin-bottom: 20px;
`;

const PageTitle = styled.h1`
margin: 0px;
margin-right: 20px;
`

const PostCategory = () => {
    const [categories, setCategories] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [modalActive, setModalActive] = useState(false);
    const [editModalActive, setEditModalActive] = useState(false);
    const [removeModal, setRemoveModal] = useState(false);
    const [removableId, setRemovableId] = useState(null);
    const [editId, setEditId] = useState(null);
    const [editTitle, setEditTitle] = useState(null);
    // Loading cats for table draw
    useEffect(() => {
        fetchPostCategories().then((data) => {
            setCategories(data);
        }).finally(() => {
            setIsLoaded(true);
        })
    }, [isLoaded])

    // Open add modal
    const openAddModal = () => {
        setModalActive(true)
    }

    const openRemoveModal = (id) => {
        setRemovableId(id)
        setRemoveModal(true);
    }

    const openEditModal = (id, name) => {
        setEditId(id);
        setEditTitle(name);
        setEditModalActive(true);
    }

    const updateSelectedCategory = (name) => {
        let formData = new FormData();
        formData.append('id', editId);
        formData.append('name', name);
        updatePostCategory(formData).then((data) => {
            setEditId(null);
            setEditTitle(null);
            setIsLoaded(!isLoaded);
            if (data.id) {
                NotificationManager.success(`Category updated`, 'Success')
            }
            else {
                NotificationManager.error(`${data.message}`, 'Error')
            }
        })
    }


    const removeSelectedCategory = (id) => {
        removeCategory(id).then((data) => {
            if (data == 'Removed') {
                NotificationManager.success(`Category removed`, 'Success')
            }
            setIsLoaded(false);
            setRemovableId(null);
        })
    }

    const addCategory = (name, childOf) => {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('childOf', Number(childOf));
        createPostCategory(formData).then((data) => {
            if (data.id) {
                NotificationManager.success('Category added', 'Success');
                setIsLoaded(false);
            }
            else {
                NotificationManager.error(`${data}`, 'Error');
            }
        })
    }
    if (!isLoaded) {
        return (
            <h1>Loading</h1>
        )
    }
    return (
        <AdminUI>
            <FlexTitle>
                <PageTitle>Post categories</PageTitle>
                <Button onClick={() => openAddModal()}>Add new</Button>
            </FlexTitle>
            <NotificationContainer />
            <TableContainer>
                <Table striped bordered hover variant="dark">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {categories.map((item) => (
                            <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                <td><Button onClick={() => openRemoveModal(item.id)}>Delete</Button></td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </TableContainer>
            <AddPostCategoryModal modalActive={modalActive} setModalActive={setModalActive} addItem={addCategory} />
            <RemoveModal modalActive={removeModal} setModalActive={setRemoveModal} itemId={removableId} removeItem={removeSelectedCategory} />
            <EditPostCategoryModal modalActive={editModalActive} setModalActive={setEditModalActive} editId={editId} editTitle={editTitle} editItem={updateSelectedCategory} />
        </AdminUI >
    )

}

export default PostCategory