import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Button = styled(Link)`
position: fixed;
bottom: 60px;
left: 50px;
border-radius: 50px;
font-size: 14px;
padding: 10px 30px;
background-color: #40bf6a;
border: none;
color: white;
text-decoration: none;
z-index: 4000;
&:hover{
    background: #61D186;
    color: white;
    text-decoration: underline;
}
&:active{
    background: #2E9E53;
    color: white;
    text-decoration: underline;
}
`

const PromoButton = ({ location }) => {
    let url;
    if (location == 'Rhodiola promo') {
        url = '/en/rhodiola-promo'
    }
    else if (location == 'Chaga promo') {
        url = '/en/chaga-promo'
    }
    else if (location == 'Taxifolin Promo') {
        url = '/en/taxifolin-promo'
    }
    else if (location == 'Taxifolin Promo US') {
        url = '/en/taxifolin-promo/us'
    }
    else if (location == 'Chaga promo US') {
        url = '/en/chaga-promo/us'
    }
    return (
        <Button to={url}>Return to {location}</Button>
    )
}

export default PromoButton