import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { getOneOrder, updateOrder } from "../../../../api/ordersAPI";
import { fetchProducts } from "../../../../api/productAPI";
import AdminUI from "../../Ui/AdminUI";
import { Row, Col, Table, Button, Form } from "react-bootstrap";
import { Context } from "../../../..";
import { fetchShippingAddresses, getUserById } from "../../../../api/userAPI";
import { fetchAddress } from "../../../../api/addressAPI";
import Select, { OnChangeValue, ActionMeta } from "react-select";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import LoadingPlaceholder from "../../../Functional/LoadingPlaceholder";

const AddItemRow = styled.div`
  display: flex;
  justify-content: space-between;
`;
const ItemSelect = styled(Select)`
  width: 80%;
`;
const FlexTitle = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const PageTitle = styled.h1`
  margin: 0px;
  margin-right: 20px;
`;

const OrderPage = () => {
  // imports
  const { id } = useParams();
  const { user } = useContext(Context);
  // states
  const [order, setOrder] = useState(null);
  const [orderItems, setOrderItems] = useState([]);
  const [products, setProducts] = useState(null);
  const [orderLoaded, setOrderLoaded] = useState(false);
  const [addressLoaded, setAddressLoaded] = useState(false);
  const [status, setStatus] = useState(null);
  const [productsLoaded, setProductsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [shipping, setShipping] = useState(null);
  const [selectItems, setSelectItems] = useState({});
  const [selectedNewItem, setSelectedNewItem] = useState(null);
  const [userData, setUserData] = useState(null);
  const [userDataLoaded, setUserDataLoaded] = useState(false);
  const [billing, setBilling] = useState(null);

  // funcs
  const changePrice = (key, value, id) => {
    setOrderItems(
      orderItems.map((i) =>
        i.item_id === id ? { ...i, [key]: Number(value) } : i
      )
    );
  };

  const changeQty = (key, value, id) => {
    setOrderItems(
      orderItems.map((i) =>
        i.item_id === id ? { ...i, [key]: Number(value) } : i
      )
    );
  };

  const selectNewItem = (OnChangeValue) => {
    setSelectedNewItem(OnChangeValue.value);
  };

  const addNewItem = () => {
    if (selectedNewItem) {
      products.map((item) => {
        if (item.id === selectedNewItem) {
          setOrderItems([
            ...orderItems,
            {
              item_id: item.id,
              title: item.title,
              img: item.img,
              qty: item.moq,
              moq: item.moq,
              price: item.price,
              status: item.status,
              qty_step: item.qty_step,
            },
          ]);
        }
      });
    }
  };
  // update func
  const update = () => {
    let formdata = new FormData();
    formdata.append("items", JSON.stringify(orderItems));
    formdata.append("status", status);
    formdata.append("payment_method", order.payment_method);
    formdata.append("comment", order.comment);
    formdata.append("tare", order.tare);
    formdata.append("shipping_address", order.shipping_address);
    formdata.append("billing_address", order.billing_address);
    formdata.append("id", order.id);
    updateOrder(formdata).then((data) => {
      if (!data.message) {
        NotificationManager.success(
          `Order "${order.id}" successfully updated`,
          "Success"
        );
      } else {
        NotificationManager.error(
          `Order "${order.id}" cannot be updated. Error: ${data.message}`,
          `Error`
        );
      }
    });
  };

  const removeItem = (id) => {
    setOrderItems(orderItems.filter((i) => i.item_id !== id));
  };

  // fetch order
  useEffect(() => {
    // fetching order details
    getOneOrder(id).then(
      (data) => {
        // fetching address when load data
        fetchAddress(data.shipping_address).then((data) => {
          setShipping(data);
          setAddressLoaded(true);
        });
        getUserById(data.userId).then((data) => {
          setUserData(data);
          setUserDataLoaded(true);
        });
        setOrder(data);
        setBilling(data.billing_address);
        setStatus(data.status);
        setOrderLoaded(true);
        setOrderItems(data.items);
      },
      (error) => {
        setOrderLoaded(false);
        setError(error);
      }
    );
    // fetching all products
    fetchProducts([], []).then(
      (data) => {
        setProducts(data);
        let dataArray = [];
        data.map((item) => {
          dataArray.push({
            value: item.id,
            label: `${item.title} | QTY: ${item.qty} | Price: ${item.price}`,
          });
        });
        setSelectItems(dataArray);
        setProductsLoaded(true);
      },
      (error) => {
        setProductsLoaded(false);
        setError(error);
      }
    );
  }, []);
  if (!orderLoaded || !productsLoaded || !addressLoaded || !userDataLoaded) {
    return (
      <AdminUI>
        <LoadingPlaceholder />
      </AdminUI>
    );
  } else {
    return (
      <AdminUI>
        <NotificationContainer />
        <FlexTitle>
          <PageTitle>Order #{order.id}</PageTitle>
          <Button onClick={() => update()}>Update order</Button>
        </FlexTitle>
        <Row>
          <Col xs={8}>
            <Form.Group className="mb-2">
              <Form.Label>Order status</Form.Label>
              <Form.Select
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
              >
                <option value="New">New</option>
                <option value="On hold">On hold</option>
                <option value="Pending payment">Pending payment</option>
                <option value="Complete">Complete</option>
                <option value="Closed">Closed</option>
              </Form.Select>
            </Form.Group>
            <Table striped bordered hover variant="dark">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Title</th>
                  <th>Qty</th>
                  <th>Item price</th>
                  <th>Total</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {orderItems.map((item) => (
                  <tr>
                    <td>{item.id ? item.id : null}</td>
                    <td>{item.title}</td>
                    <td>
                      <Form.Control
                        value={item.qty}
                        onChange={(e) => {
                          changeQty("qty", e.target.value, item.item_id);
                        }}
                        placeholder="Qty"
                      />
                    </td>
                    <td>
                      <Form.Control
                        value={item.price}
                        onChange={(e) => {
                          changePrice("price", e.target.value, item.item_id);
                        }}
                        placeholder="Price"
                      />
                    </td>
                    <td>{item.price * item.qty}</td>
                    <td>
                      <Button
                        onClick={() => {
                          removeItem(item.item_id);
                        }}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <AddItemRow>
              <ItemSelect options={selectItems} onChange={selectNewItem} />
              <Button
                onClick={() => {
                  addNewItem();
                }}
              >
                Add product
              </Button>
            </AddItemRow>
          </Col>
          <Col xs={4}>
            <Table striped bordered hover variant="dark">
              <thead>
                <tr>
                  <th>Order details</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Number</td>
                  <td>{order.id}</td>
                </tr>
                <tr>
                  <td>Payment method</td>
                  <td>{order.payment_method}</td>
                </tr>
                <tr>
                  <td>Order tare</td>
                  <td>{order.tare}</td>
                </tr>
              </tbody>
            </Table>
            <Table striped bordered hover variant="dark">
              <thead>
                <tr>
                  <th>Client information</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>
                    {userData.first_name} {userData.last_name}
                  </td>
                </tr>
                <tr>
                  <td>Phone</td>
                  <td>{userData.phone}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>{userData.email}</td>
                </tr>
              </tbody>
            </Table>
            <Table striped bordered hover variant="dark">
              <thead>
                <tr>
                  <th>Shipping address</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>
                    {shipping.first_name} {shipping.last_name}
                  </td>
                </tr>
                <tr>
                  <td>Phone</td>
                  <td>{shipping.phone}</td>
                </tr>
                <tr>
                  <td>Country</td>
                  <td>{shipping.country}</td>
                </tr>
                <tr>
                  <td>City</td>
                  <td>{shipping.city}</td>
                </tr>
                <tr>
                  <td>State</td>
                  <td>{shipping.state}</td>
                </tr>
                <tr>
                  <td>Post</td>
                  <td>{shipping.post}</td>
                </tr>
                <tr>
                  <td>ZIP</td>
                  <td>{shipping.zip}</td>
                </tr>
                <tr>
                  <td>Address type</td>
                  <td>{shipping.type}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </AdminUI>
    );
  }
};

export default OrderPage;
