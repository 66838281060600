import React, { useState } from "react";
import styled from "styled-components";
import DefaultButton from "../Buttons/DefaultButton";
import MediaQuery from "react-responsive";
import SectionHeading from "../../Interface/TextElements/SectionHeading";
import Input from "../Input/Input";
import Counter from "../Counter/Counter";
import Status from "../Status/Status";
import { useEffect } from "react";
import { useContext } from "react";
import { Context } from "../../../..";
import {
  fetchBasket,
  removeBasketItem,
  updateBasket,
} from "../../../../api/basketAPI";
import { getFileUrl } from "../../../../api/productAPI";
import CartItemCounter from "../Counter/CartItemCounter";
import TableRowItem from "./TableRowItem";
import { Link } from "react-router-dom";

const Table = styled.table`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #303236;
  width: 100%;
`;
const ItemsCounter = styled.p`
  font-size: 12px;
  line-height: 19px;
  text-align: center;
  color: #7f878b;
`;
const TableHeader = styled.thead`
  color: #7f878b;
  box-sizing: border-box;
  padding: 5px 18px;
  font-weight: 400;
  background: #f3f7f2;
  width: 100%;
  font-size: 12px;
  line-height: 19px;
`;

const Th = styled.th`
  font-weight: 400;
  padding-right: 5px;
`;

const ThProduct = styled(Th)`
  width: 40%;
  padding-left: 18px;
`;

const ThPrice = styled(Th)`
  width: 18%;
`;

const ThQty = styled(Th)`
  width: 15.5%;
`;

const TBody = styled.tbody``;
const Row = styled.tr`
  position: relative;
  &:after {
    content: " ";
    display: block;
    position: absolute;
    width: calc(100vw - 36px);
    height: 1px;
    background-color: #eaebec;
    left: 18px;
    bottom: -5px;
  }
`;

const Td = styled.td`
  padding-top: 10px;
`;
const Name = styled(Td)`
  padding-left: 18px;
`;

const Oders = styled.div``;

const Summary = styled.table`
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  color: #303236;
  opacity: 0.6;
  width: 100%;
  padding: 10px 0px;
  box-sizing: border-box;
`;

const SummaryCell = styled.td`
  width: 50%;
`;

const LeftCell = styled(SummaryCell)`
  text-align: right;
  @media screen and (min-width: 1128px) {
    width: 620px;
  }
`;

const RightCell = styled(SummaryCell)`
  text-align: left;
  @media screen and (min-width: 1128px) {
    width: 125px;
  }
`;
const Container = styled.div`
  padding: 15px;
  box-sizing: border-box;
  margin-top: 25px;
  background: #f3f7f2;
`;
const CalculatePrice = (items) => {
  let ammount = 0;
  items.map((item) => {
    ammount = ammount + item.price * item.qty;
  });
  return ammount.toFixed(2);
};

const TotalRow = styled.tr`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #303236;
  @media screen and (min-width: 1128px) {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #303236;
    opacity: 1;
  }
`;
const TotalTitle = styled(LeftCell)`
  padding-top: 10px;
`;
const TotalValue = styled(RightCell)`
  padding-top: 10px;
`;
const UpdateButton = styled.button`
  display: block;
  width: 100%;
  font-size: 14px;
  line-height: 19px;
  color: #40bf6a;
  font-weight: 700;
  background-color: #ffffff;
  border: 1px solid #40bf6a;
  border-radius: 4px;
  padding: 11px;
  @media screen and (min-width: 1128px) {
    width: 208px;
  }
`;
const OrderButton = styled(DefaultButton)`
  width: calc(100vw - 53px);
  margin: 0px auto;
  margin-top: 10px;
  margin-bottom: 25px;
  @media screen and (min-width: 1128px) {
    width: 140px;
    margin: 0px;
    padding: 14px 24px;
    margin-left: 20px;
  }
`;

const DeleteCell = styled.td``;

const DeleteButton = styled.button`
  position: absolute;
  width: 24px;
  height: 24px;
  background: url("/deleteCart.svg");
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  right: 9px;
  bottom: -15px;
  z-index: 8;
  @media screen and (min-width: 1128px) {
    position: initial;
  }
`;

const CentredContainer = styled.div`
  margin: 0px 18px;
`;

const ButtonsContainer = styled.div`
  border-bottom: 1px solid #eaebec;
  box-sizing: border-box;
  padding: 25px 0px;
  @media screen and (min-width: 1128px) {
    display: flex;
    align-items: center;
    margin-left: 410px;
    border-bottom: none;
  }
`;

const Discount = styled.div`
  box-sizing: border-box;
  padding: 25px 0px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #303236;
`;

const CartContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;
const LeftColumn = styled.div`
  width: 840px;
  box-sizing: border-box;
  padding: 25px;
  margin-top: 25px;
  background: #ffffff;
  border: 1px solid #eaebec;
  border-radius: 6px;
  margin-bottom: 40px;
`;
const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ProductTable = styled.table`
  width: 100%;
  margin-top: 40px;
  box-shadow: 0px 1px 0px #eaebec;
  margin-bottom: 40px;
`;
const ProductsCounter = styled.p`
  margin-bottom: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #40bf6a;
`;
const DesktopHeading = styled.h3`
  font-weight: 600;
  font-size: 26px;
  color: #303236;
  line-height: 28px;
  margin-bottom: 0;
`;

const TableHead = styled.thead`
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: #7f878b;
  box-shadow: 0px 1px 0px #eaebec;
`;
const Item = styled.td``;

const ProductHead = styled(Item)`
  width: 415px;
`;
const PriceHead = styled(Item)`
  width: 110px;
`;

const AmmountHead = styled(Item)`
  width: 109px;
`;
const QuantityHead = styled(Item)`
  width: 130px;
  padding-right: 30px;
`;
const DeleteHead = styled(Item)`
  width: 25px;
`;

const Image = styled.img`
  width: 42px;
  height: 42px;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 15px;
`;

const ProductName = styled(Item)`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #303236;
  display: flex;
  width: 415px;
  margin: none;
  padding: 15px 0px;
`;

const ProductPrice = styled(Item)`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #303236;
  opacity: 0.8;
`;

const TableRow = styled.tr`
  box-sizing: border-box;
  margin: 15px 0px;
  position: relative;
`;

const ProductTitle = styled.p`
  width: 100%;
  margin-bottom: 0;
`;

const ProductFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Amount = styled(Item)`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #303236;
`;

const RightColumn = styled.div`
  width: 264px;
  background: #ffffff;
  border: 1px solid #eaebec;
  border-radius: 6px;
  margin-left: 24px;
  box-sizing: border-box;
  padding: 25px;
  margin-top: 25px;
  margin-bottom: 40px;
  height: auto;
`;

const DiscountTitle = styled.h4`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #303236;
  margin-bottom: 15px;
`;

const DicountInput = styled.input`
  background: #ffffff;
  border-radius: 4px 0px 0px 4px;
  border: none;
  height: 40px;
  text-indent: 20px;
`;

const DiscountForm = styled.div`
  border: 1px solid #eaebec;
  border-radius: 4px;
  position: relative;
`;

const StyledLink = styled(Link)`
text-decoration: none;
`

const DiscountButton = styled(DefaultButton)`
  padding: 12px 24px;
  gap: 8px;
  width: 82px;
  height: 40px;
  position: absolute;
  right: 0px;
  top: 0px;
  border-radius: 0px 4px 4px 0px;
`;
const CartTable = ({
  items,
  setItems,
  needReload,
  setNeedReload,
  notificationPush,
}) => {
  const { user } = useContext(Context);
  //funcs

  const removeCartItem = (id, title) => {
    let formdata = new FormData();
    formdata.append("id", id);
    formdata.append("userId", user.user.id);
    removeBasketItem(formdata).then((data) => {
      if (data.message) {
        notificationPush(data.message, "error");
      }
      setNeedReload(!needReload);
      notificationPush(title, "remove");
    });
  };

  const updateCart = () => {
    let formdata = new FormData();
    formdata.append("items", JSON.stringify(items));
    updateBasket(formdata).then((data) => {
      setNeedReload(!needReload);
      notificationPush("Cart updated", "remove");
    });
  };

  return (
    <>
      <MediaQuery minWidth={1128}>
        <CartContainer>
          <LeftColumn>
            <HeaderRow>
              <DesktopHeading>Shopping cart</DesktopHeading>
              <ProductsCounter>{items.length} items</ProductsCounter>
            </HeaderRow>
            <ProductTable>
              <TableHead>
                <tr>
                  <ProductHead>Product</ProductHead>
                  <PriceHead>Unit price</PriceHead>
                  <QuantityHead>Quantity</QuantityHead>
                  <AmmountHead>Amount</AmmountHead>
                  <DeleteHead></DeleteHead>
                </tr>
              </TableHead>
              <tbody>
                {items.map((item) => {
                  return (
                    <TableRowItem item={item} items={items} setItems={setItems} remove={removeCartItem} />
                  );
                })}
              </tbody>
            </ProductTable>
            <Summary>
              <tr>
                <LeftCell>Subtotal::</LeftCell>
                <RightCell>€{CalculatePrice(items)}</RightCell>
              </tr>
              <tr>
                <LeftCell>Payment method:</LeftCell>
                <RightCell>Direct bank transfer</RightCell>
              </tr>
              <tr>
                <LeftCell>Shipping & handling:</LeftCell>
                <RightCell>individual calculation</RightCell>
              </tr>
              <TotalRow>
                <TotalTitle>Order total:</TotalTitle>
                <TotalValue>€{CalculatePrice(items)}</TotalValue>
              </TotalRow>
            </Summary>
            <ButtonsContainer>
              <UpdateButton
                onClick={() => {
                  updateCart();
                }}
              >
                Update cart
              </UpdateButton>
              <StyledLink to={'/checkout'}>
                <OrderButton title="Start new order" />
              </StyledLink>
            </ButtonsContainer>
          </LeftColumn>
          <RightColumn className="dsc-code-form" style={{ display: 'none' }}>
            <DiscountTitle>Apply discount code</DiscountTitle>
            <DiscountForm>
              <DicountInput placeholder="Enter code" />
              <DiscountButton title="Apply" />
            </DiscountForm>
          </RightColumn>
        </CartContainer>
      </MediaQuery>
      <MediaQuery maxWidth={1127}>
        <Oders>
          <SectionHeading>Shopping cart</SectionHeading>
          <ItemsCounter>{items.length} items</ItemsCounter>
          <Table>
            <TableHeader>
              <ThProduct>Product</ThProduct>
              <ThPrice>Unit price</ThPrice>
              <ThQty>Qty</ThQty>
              <Th>Amount</Th>
            </TableHeader>
            <TBody>
              {items.map((item) => {
                return (
                  <Row>
                    <Name>{item.title} </Name>
                    <Td>€{item.price.toFixed(2)} </Td>
                    <Td> {item.qty} </Td>
                    <Td>€{(item.price * item.qty).toFixed(2)}</Td>
                    <DeleteCell>
                      <DeleteButton />
                    </DeleteCell>
                  </Row>
                );
              })}
            </TBody>
          </Table>
          <Container>
            <Summary>
              <tr>
                <LeftCell>Cart subtotal:</LeftCell>
                <RightCell>€{CalculatePrice(items)}</RightCell>
              </tr>
              <tr>
                <LeftCell>Payment method:</LeftCell>
                <RightCell>Direct bank transfer</RightCell>
              </tr>
              <tr>
                <LeftCell>Shipping & handling:</LeftCell>
                <RightCell>individual calculation</RightCell>
              </tr>
              <TotalRow>
                <TotalTitle>Total:</TotalTitle>
                <TotalValue>€{CalculatePrice(items)}</TotalValue>
              </TotalRow>
            </Summary>
          </Container>
          <CentredContainer>
            <ButtonsContainer>
              <UpdateButton
                onClick={() => {
                  updateCart();
                }}
              >
                Update cart
              </UpdateButton>
              <StyledLink to={'/checkout'}>
                <OrderButton title="Start new order" />
              </StyledLink>
            </ButtonsContainer>
            <Discount className="dsc-mobile-form" style={{ display: 'none' }}>
              <Input label="Apply discount code" placeholder="Enter code" />
              <OrderButton title="Apply" />
            </Discount>
          </CentredContainer>
        </Oders>
      </MediaQuery>
    </>
  );
};

export default CartTable;
