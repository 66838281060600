import React, { useContext, useEffect, useState } from "react";
import { login, requestPasswordChange, twoFalogin } from "../api/userAPI";
import { Form } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import { Context } from "../index";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import DefaultButton from "../components/VitaforestUI/Interface/Buttons/DefaultButton";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Header from "../components/VitaforestUI/Interface/Header/Header";
import Footer from "../components/VitaforestUI/Interface/Footer/Footer";
import MediaQuery from "react-responsive";
import jwtDecode from "jwt-decode";
import useModal from "../components/Functional/useModal";
import useScrollBlock from "../components/Functional/useScrollBlock";
import Modal from "../components/VitaforestUI/Interface/Modal";
import { Helmet } from "react-helmet";

const PageContainer = styled.div`
  font-family: "Proxima Nova";
  font-style: normal;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  @media screen and (min-width: 1128px) {
    height: 100vh;
    flex-direction: row;
    justify-content: flex-start;
  }
`;

const Twofacont = styled.div`
margin-top: 10px;
`

const Heading = styled.h2`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 17px;
  color: #303236;
  padding-bottom: 8px;
  border-bottom: 1px solid #303236;
`;

const ButtonSubmit = styled(DefaultButton)`
  max-width: 280px;
  margin: 15px auto;
  @media screen and (min-width: 1128px) {
    min-width: 384px;
  }
`;

const LoginForm = styled.div`
  margin: 18px;
  margin-bottom: auto;
  @media screen and (min-width: 1128px) {
    width: 43vw;
    box-sizing: border-box;
    padding: 40px 86px;
  }
`;

const Text = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-top: 15px;
  color: #303236;
  margin-bottom: 0;
`;

const RegistrationBlock = styled.div`
  margin: 10px auto;
  @media screen and (min-width: 1128px) {
    margin-top: 80px;
  }
`;

const BenefitsList = styled.ul`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding-left: 18px;
`;

const RegistrationLink = styled(Link)`
  background: #3c3f54;
  border-radius: 4px;
  max-width: 280px;
  color: #ffffff;
  align-items: center;
  width: 100%;
  padding: 12px 24px;
  border-radius: 4px;
  border: none;
  font-family: "Proxima Nova";
  font-weight: 700;
  font-size: 13px;
  display: flex;
  justify-content: center;
  line-height: 16px;
  text-decoration: none;
  margin: 0 auto;
  text-decoration: none;
  @media screen and (min-width: 1128px) {
    max-width: 384px;
    margin-top: 30px;
  }
  &:hover{
    background: #525673;
    color: white;
  }
  &:active{
    background: #242638;
    color: white;
  }
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 40px 42px 22px 76px;
  background-image: url("/regbg.webp");
  width: 57vw;
  background-size: cover;
  .logo {
    width: 191px;
    height: 49px;
  }
`;

const RegText = styled(Text)`
  margin-bottom: 30px;
`;
const BackButton = styled.button`
  padding: 12px 24px 12px 42px;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  border: 2px solid #3c3f54;
  color: #3c3f54;
  background-color: #ffffff;
  border-radius: 4px;
  position: relative;
  display: block;
  margin-left: auto;
  margin-bottom: 20px;
  &:before {
    content: url("/arrow-reg.svg");
    position: absolute;
    left: 19px;
    top: 12px;
  }
`;

const Socials = styled.div`
  margin-top: auto;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  img {
    padding-right: 10px;
  }
`;

const Copyright = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #eaebec;
  justify-content: space-between;
`;

const Privacy = styled(Link)`
  text-align: right;
  text-decoration-line: underline;
  color: inherit;
`;

const StylesLayer = styled(PageContainer)`
.form-control:focus{
  border: 1px solid #BBBBBB;
  box-shadow: none;
}
`

const LogoLink = styled(Link)`

`

const ForgotPassword = styled.button`
border: none;
background-color: inherit;
color: #18a0fb;
margin-top: 10px;
&:hover{
  text-decoration: underline;
}
`

const Auth = observer(() => {

  const { user } = useContext(Context);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [twoFa, setTwoFa] = useState(false);
  const [twoFaCode, setTwoFaCode] = useState("");
  const { isShowing, toggle } = useModal();
  const [blockScroll, allowScroll] = useScrollBlock();
  const [restoreEmail, setRestoreEmail] = useState('');
  const navigate = useNavigate();
  let currentYear = new Date;
  currentYear = currentYear.getFullYear();

  useEffect(() => {
    return false;
  }, [twoFa]);

  const restorePassword = () => {
    requestPasswordChange(restoreEmail).then((data) => {
      if (data.status == 'reset_link_sended') {
        toggle();
        NotificationManager.success('Restoration link successfully sended to email', 'Success');
      }
      else if (data.status == 'user_not_found') {
        NotificationManager.error('User with this email not found', 'Error');
      }
      else {
        NotificationManager.error(`???`, 'Error');
      }
    })
  }

  const click = async () => {
    try {
      if (twoFa == false) {
        login(email, password).then((data) => {
          if (data.token) {
            localStorage.setItem('token', data.token);
            let decryptedToken = jwtDecode(data.token);
            user.setUser(decryptedToken);
            user.setIsAuth(true);
            NotificationManager.success(`Welcome back, ${decryptedToken.first_name}`, 'Logged in');
            setTimeout(() => {
              if (decryptedToken.email) {
                window.location.href = "/account";
              }
            }, 3000);
          }
          else if (data.status == '2fa_required') {
            NotificationManager.warning('2FA enabled. Code sended to assigned email', '2FA');
            setTwoFa(true);
          }
          else {
            NotificationManager.error(data.message, 'Error')
          }
        });
      }
      else if (twoFa && twoFaCode.length < 6) {
        NotificationManager.warning('Please enter 2FA code', 'Warning');
      }
      else {
        twoFalogin(email, password, twoFaCode).then((data) => {
          if (data.token) {
            localStorage.setItem('token', data.token);
            let decryptedToken = jwtDecode(data.token);
            user.setUser(decryptedToken);
            user.setIsAuth(true);
            NotificationManager.success(`Welcome back, ${decryptedToken.first_name}`, 'Success');
            setTimeout(() => {
              if (decryptedToken.email) {
                window.location.href = "/account";
              }
            }, 3000);
          }
          else if (data.status == '2fa_retry') {
            NotificationManager.warning('The codes you entered are incorrect, we have sent a new code to the associated email address', '2FA Warging')
          }
          else if (!data.token) {
            NotificationManager.error(`${data.message}`, 'Error');
          }
          else {
            NotificationManager.error('Unexpected error', 'Error');
          }
        })
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <StylesLayer>
      <Helmet>
        <title>Login - Vitaforest</title>
        <meta name="description" content={'Vitaforest website login page'} />
      </Helmet>
      <Modal
        type={'reset-password'}
        isShowing={isShowing}
        hide={toggle}
        allow={allowScroll}
        setRestoreEmail={setRestoreEmail}
        action={restorePassword}
      />
      <MediaQuery maxWidth={1127}>
        <Header />
      </MediaQuery>
      <NotificationContainer />
      <MediaQuery minWidth={1128}>
        <LeftColumn>
          <LogoLink to={'/en'}><img className="logo" src="/LogoLight.svg" /></LogoLink>
          <Socials>
            <Link to="https://www.facebook.com/vitaforestfood/">
              <img src="/Facebook.svg" />
            </Link>
            <Link to="https://www.linkedin.com/company/vitaforestfood/">
              <img src="/Linkedin.svg" />
            </Link>
          </Socials>
          <Copyright>
            <span> © VitaForest {currentYear}</span>
            <Privacy to={'/en/privacy-policy'}>Privacy Policy</Privacy>
          </Copyright>
        </LeftColumn>
      </MediaQuery>
      <LoginForm>
        <MediaQuery minWidth={1128}>
          <BackButton onClick={() => navigate(-1)}>Back</BackButton>
        </MediaQuery>
        <Heading>Registered customers</Heading>
        <RegText>
          If you have an account, sign in with your email address
        </RegText>
        <NotificationContainer />
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        ></Form.Control>
        <Form.Label>Password</Form.Label>
        <Form.Control
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        ></Form.Control>
        {twoFa ?
          <Twofacont>
            <Form.Label>2FA Code</Form.Label>
            <Form.Control
              type="text"
              value={twoFaCode}
              onChange={(e) => setTwoFaCode(e.target.value)}
            ></Form.Control></Twofacont>
          : null}
        <ForgotPassword onClick={() => { toggle() }}>Forgot password?</ForgotPassword>
        <ButtonSubmit type="submit" onClick={() => click()} title="Submit" />
        <RegistrationBlock>
          <Heading>New customer</Heading>
          <Text>Creating an account has many benefits:</Text>
          <BenefitsList>
            <li>check out faster</li>
            <li>keep more than one address</li>
            <li>track orders and more</li>
          </BenefitsList>
          <RegistrationLink to="/registration">
            Create B2B account
          </RegistrationLink>
        </RegistrationBlock>
      </LoginForm>

      <MediaQuery maxWidth={1127}>
        <Footer />
      </MediaQuery>
    </StylesLayer>
  );
});

export default Auth;
