import React from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import styled from "styled-components";
import close from "../img/close.svg";

const Popup = styled.div`
  position: fixed;
  top: 10%;
  left: 25%;
  z-index: 16;
  height: 871.2px;
  width: 850.2px;
  padding-top: 40px;
  border: 1px solid #eaebec;
  border-radius: 4px;
  background-color: #ffffff;
  @media screen and (max-width: 1127px) {
    top: 0px;
    padding-top: 40px;
    right: 0px;
    left: 0px;
    width: 98vw;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  border: none;
  background-color: transparent;
  top: 10px;
  right: 20px;
  z-index: 19;
`;

export default function FilePopup(props) {
  return (
    <Popup>
      <CloseButton onClick={props.onClick}>
        <img src={close} />
      </CloseButton>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.0.279/build/pdf.worker.min.js">
        <Viewer fileUrl={props.url} />
      </Worker>
    </Popup>
  );
}
