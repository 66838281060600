import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import MediaQuery from "react-responsive";
import PageHeader from "../subcomponents/PageHeader";
import OrdersMobile from "../subcomponents/OrdersMobile";
import OrdersDesktop from "../subcomponents/OrdersDesktop";
import Page from "../../Interface/Page/Page";
import AccountMenu from "../subcomponents/AccountMenu";
import { Helmet } from "react-helmet";
import { Context } from "../../../..";
import { getOrdersByUserId } from "../../../../api/ordersAPI";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import MobileAccountHead from "../MobileAccountHead";
import LoadingPlaceholder from "../../../Functional/LoadingPlaceholder";
import { style } from "@mui/system";
const TemporaryHide = styled.div`
  @media screen and (max-width: 1127px) {
    display: none;
  }
`;
const Container = styled.div`
position: relative;
  margin-top: 20px;
  @media screen and (min-width: 1128px) {
    display: flex;
    margin-top: 30px;
    margin-bottom: 40px;
  }
`;

const Wrapper = styled.div`
  width: calc(100vw - 36px);
  margin: 0 auto;
  @media screen and (min-width: 1128px) {
    width: auto;
    margin: 0;
  }
  `;

const MobileBackButton = styled(Link)`
&:before{
  content: url('/arrow-back.svg');
}
text-decoration: none;
color: black;
position: absolute;
top: -5px;
vertical-align: center;
@media screen and (min-width: 1128px){
  display: none;
}
&:hover{
  color: inherit;
}
&:active{
  color: inherit;
}
`;

const CenteredDiv = styled.div`
display: flex;
justify-content: center;
margin-top: 15px;
`

export default function Orders() {
  const { user } = useContext(Context);
  const [orders, setOrders] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  useEffect(() => {
    getOrdersByUserId(user.user.id).then((data) => {
      setOrders(data);
      setIsLoaded(true);
    }, (error) => {
      setIsLoaded(false);
      setError(error);
    })
  }, [])
  if (!isLoaded) {
    return (
      <Page>
        <Container>
          <LoadingPlaceholder />
        </Container>
      </Page>
    )
  }
  else if (!orders[0]) {
    return (
      <Page variant={'centred'} seo_title={'Vitaforest - Account - Orders'} seo_description={'Vitaforest user orders page'}>
        <Container>
          <TemporaryHide>
            <AccountMenu />
          </TemporaryHide>
          <Wrapper>
            <MobileBackButton to={'/account'} />
            <PageHeader>My orders</PageHeader>
            <MediaQuery maxWidth={1127}>
              <CenteredDiv><h5>You dont have orders</h5></CenteredDiv>
            </MediaQuery>
            <MediaQuery minWidth={1128}>
              <h5>You dont have orders</h5>
            </MediaQuery>
          </Wrapper>
        </Container>
      </Page>
    )
  }
  else {
    return (
      <Page variant={'centred'} seo_title={'Vitaforest - Account - Orders'} seo_description={'Vitaforest user orders page'}>
        <Container>
          <TemporaryHide>
            <AccountMenu />
          </TemporaryHide>
          <Wrapper>
            <MobileBackButton to={'/account'} />
            <PageHeader>My orders</PageHeader>
            <MediaQuery maxWidth={1127}>
              <OrdersMobile orders={orders} />
            </MediaQuery>
            <MediaQuery minWidth={1128}>
              <OrdersDesktop orders={orders} />
            </MediaQuery>
          </Wrapper>
        </Container>
      </Page>
    );
  }
}
