import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import MediaQuery from "react-responsive";
import PageHeader from "../subcomponents/PageHeader";
import Page from "../../Interface/Page/Page";
import AccountMenu from "../subcomponents/AccountMenu";
import { Helmet } from "react-helmet";
import { Context } from "../../../..";
import { Spinner } from "react-bootstrap";
import { fetchAddress, fetchUserAddresses } from "../../../../api/addressAPI";
import Input from "../../Interface/Input/Input";
import Address from "../../Interface/AccountComponetnts/Address";
import SectionHeading from "../../Interface/TextElements/SectionHeading";
import Heading from "../../Interface/TextElements/Heading";
import Required from "../../Interface/TextElements/Required";
import DefaultButton from "../../Interface/Buttons/DefaultButton";
import { Link } from "react-router-dom";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { getUserById } from "../../../../api/userAPI";
import LoadingPlaceholder from "../../../Functional/LoadingPlaceholder";
const TemporaryHide = styled.div`
  @media screen and (max-width: 1127px) {
    display: none;
  }
`;
const Container = styled.div`
  margin-top: 20px;
  position: relative;
  @media screen and (min-width: 1128px) {
    display: flex;
    margin-top: 30px;
    margin-bottom: 40px;
  }
`;

const Wrapper = styled.div`
  width: calc(100vw - 36px);
  margin: 0 auto;
  @media screen and (min-width: 1128px) {
    width: auto;
    margin: 0;
  }
`;

const Addresses = styled.div`
  display: flex;
  gap: 15px;
  flex-direction: column;
  margin: 25px 0px;
`;

const SaveButton = styled(DefaultButton)`
  width: 284px;
  margin: 0 auto;
  margin-bottom: 10px;
`;

const AddButton = styled(Link)`
  width: 284px;
  height: 40px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: #3c3f54;
  margin: 0 auto;
  border-radius: 4px;
  text-decoration: none;
  margin-bottom: 40px;
`;

const AddressTypeHeading = styled.p`
margin-top: 20px;
`

const DesktopUser = styled.div`
  margin-top: 46px;
`;
const UserItem = styled.div`
  border-top: 1px solid #eaebec;

  height: 47px;
  display: flex;
  align-items: center;
  vertical-align: middle;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
`;
const LeftColumn = styled.p`
  width: 168px;
  margin-bottom: 0;
`;
const RightColumn = styled.p`
  font-weight: 600;
  width: 652px;
  margin-bottom: 0;
`;
const LastUserItem = styled(UserItem)`
  border-bottom: 1px solid #eaebec;
`;
const DesktopAddresses = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  margin-top: 29px;
`;

const MobileBackButton = styled(Link)`
&:before{
  content: url('/arrow-back.svg');
}
text-decoration: none;
color: black;
position: absolute;
top: -5px;
vertical-align: center;
@media screen and (min-width: 1128px){
  display: none;
}
&:hover{
  color: inherit;
}
&:active{
  color: inherit;
}
`;

const AddButtonDesktop = styled(AddButton)`
background-color: #40BF6A;
margin: 0px;
width: 189px;
height: 40px;
&:hover{
color: white;
background-color: #61D186;
}
&:active{
color: white;  
background-color: #2E9E53;
}
`;

const DesktopAddContainer = styled.div`
@media screen and (max-width: 1127px){
  display: none;
}
display: flex;
justify-content: flex-start;
margin-top: 21px;
`

export default function AccountAddresses() {
  const { user } = useContext(Context);
  const [userDetails, setUserDetails] = useState([])
  const [addresses, setAdresses] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [needReload, setNeedReload] = useState(false);
  useEffect(() => {
    fetchUserAddresses(user.user.id).then(
      (data) => {
        setAdresses(data);
      },
      (error) => {
        setIsLoaded(false);
        setError(error);
      }
    );
    getUserById(user.user.id).then((data) => {
      setUserDetails(data);
    }, (e) => {
      setIsLoaded(false);
      setError(error)
    })
    setIsLoaded(true);
  }, [needReload]);
  if (!isLoaded) {
    return (
      <Page>
        <Container>
          <LoadingPlaceholder />
        </Container>
      </Page>
    );
  } else if (error) {
    return (
      <Page>
        <Container>
          <h5>{error}</h5>
        </Container>
      </Page>
    );
  } else {
    return (
      <Page variant={"centred"} seo_title={'Vitaforest - Account - Orders'} seo_description={'Vitaforest user orders page'}>
        <NotificationContainer />
        <Container>
          <TemporaryHide>
            <AccountMenu />
          </TemporaryHide>
          <Wrapper>
            <MobileBackButton to={'/account'} />
            <PageHeader>Address book</PageHeader>
            <MediaQuery maxWidth={1127}>
              <Input label="First name" placeholder="Dave" value={user.user.first_name} />
              <Input label="Last name" placeholder="Peterson" value={user.user.last_name} />
              <Input
                label="Phone number"
                placeholder="+ 45 (477) 223456"
                value={userDetails.phone}
              />
              <Addresses>
                <Heading>Billing addresses</Heading>
                {addresses.map((item) => {
                  if (item.type == "Billing") {
                    return (
                      <Address
                        name={item.first_name + " " + item.last_name}
                        country={item.country}
                        state={item.state}
                        street={item.post}
                        phone={item.phone}
                        type={item.type}
                        fixable={true}
                        id={item.id}
                        deleteable={true}
                        defaultShipping={item.defaultShipping}
                        defaultBilling={item.defaultBilling}
                        notificationManager={NotificationManager}
                        needReload={needReload}
                        setNeedReload={setNeedReload}
                        selected={item.defaultBilling}
                      />
                    );
                  }
                })}
              </Addresses>
              <Addresses>
                <Heading>Shipping addresses</Heading>
                {addresses.map((item) => {
                  if (item.type == "Shipping") {
                    return (
                      <Address
                        name={item.first_name + " " + item.last_name}
                        country={item.country}
                        state={item.state}
                        street={item.post}
                        phone={item.phone}
                        type={item.type}
                        fixable={true}
                        id={item.id}
                        deleteable={true}
                        defaultShipping={item.defaultShipping}
                        defaultBilling={item.defaultBilling}
                        notificationManager={NotificationManager}
                        needReload={needReload}
                        setNeedReload={setNeedReload}
                        selected={item.defaultShipping}
                      />
                    );
                  }
                })}
              </Addresses>
              <AddButton to="/account/addresses/add">Add new address</AddButton>
            </MediaQuery>
            <MediaQuery minWidth={1128}>
              <DesktopUser>
                <UserItem>
                  <LeftColumn>First name:</LeftColumn>
                  <RightColumn>{user.user.first_name}</RightColumn>
                </UserItem>
                <UserItem>
                  <LeftColumn>Last name:</LeftColumn>
                  <RightColumn>{user.user.last_name}</RightColumn>
                </UserItem>
                <LastUserItem>
                  <LeftColumn>Phone number</LeftColumn>
                  <RightColumn>{userDetails.phone}</RightColumn>
                </LastUserItem>
              </DesktopUser>
              <AddressTypeHeading>Billing addresses</AddressTypeHeading>
              <DesktopAddresses>
                {addresses.map((item) => {
                  if (item.type == "Billing") {
                    return (
                      <Address
                        name={item.first_name + " " + item.last_name}
                        country={item.country}
                        state={item.state}
                        street={item.post}
                        phone={item.phone}
                        type={item.type}
                        fixable={true}
                        id={item.id}
                        deleteable={true}
                        defaultShipping={item.defaultShipping}
                        defaultBilling={item.defaultBilling}
                        notificationManager={NotificationManager}
                        needReload={needReload}
                        setNeedReload={setNeedReload}
                        selected={item.defaultBilling}
                      />
                    );
                  }
                })}
              </DesktopAddresses>
              <AddressTypeHeading>Shipping addresses</AddressTypeHeading>
              <DesktopAddresses>
                {addresses.map((item) => {
                  if (item.type == "Shipping") {
                    return (
                      <Address
                        name={item.first_name + " " + item.last_name}
                        country={item.country}
                        state={item.state}
                        street={item.post}
                        phone={item.phone}
                        type={item.type}
                        fixable={true}
                        id={item.id}
                        deleteable={true}
                        defaultShipping={item.defaultShipping}
                        defaultBilling={item.defaultBilling}
                        notificationManager={NotificationManager}
                        needReload={needReload}
                        setNeedReload={setNeedReload}
                        selected={item.defaultShipping}
                      />
                    );
                  }
                })}
              </DesktopAddresses>
              <DesktopAddContainer><AddButtonDesktop to="/account/addresses/add">+ Add new address</AddButtonDesktop></DesktopAddContainer>
            </MediaQuery>
          </Wrapper>
        </Container>
      </Page>
    );
  }
}
