import { $authHost, $host } from "./index";
import jwt_decode from "jwt-decode";

export const registration = async (user) => {
    try {
        const { data } = await $host.post('api/user/registration', user)
        localStorage.setItem('token', data.token)
        return jwt_decode(data.token)
    }
    catch (e) {
        return e.response.data
    }
}

export const login = async (email, password) => {
    try {
        const { data } = await $host.post('api/user/login', { email, password });
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const twoFalogin = async (email, password, otp) => {
    const { data } = await $host.post('api/user/twofa', { email, password, otp });
    return data;
}

export const check = async () => {
    const { data } = await $authHost.get('api/user/auth')
    localStorage.setItem('token', data.token)
    return jwt_decode(data.token)
}

export const getUserById = async (id) => {
    try {
        const { data } = await $authHost.get(`/api/user/${id}`)
        return data
    }
    catch (e) {
        return e.response.data
    }
}

export const getAllUsersByAdmin = async () => {
    try {
        const { data } = await $authHost.get('/api/user/');
        return data
    }
    catch (e) {
        return e.response.data;
    }
}

export const updateUser = async (body) => {
    try {
        const { data } = await $authHost.post('/api/user/update', body);
        return data
    }
    catch (e) {
        return e.response.data;
    }
}

export const updatePassword = async (body) => {
    try {
        const { data } = await $authHost.post('/api/user/password', body);
        return data
    }
    catch (e) {
        return e.response.data;
    }
}

// Approve user by one click from admin panel
export const approveUser = async (id) => {
    try {
        const { data } = await $authHost.post(`/api/user/approve/${id}`);
        return data
    } catch (e) {
        return e.response.data;
    }
}


export const requestPasswordChange = async (email) => {
    try {
        const { data } = await $host.post('/api/user/req-restore-pass', { email });
        return data
    } catch (e) {
        return e.response.data;
    }
}

export const doPasswordChange = async (otl, newpassword) => {
    try {
        const { data } = await $host.post('/api/user/password-change', { otl, newpassword });
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const fetchUserRoles = async () => {
    try {
        const { data } = await $authHost.get('/api/roles');
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const createUserRole = async (body) => {
    try {
        const { data } = await $authHost.post('/api/roles', body);
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const updateUserRole = async (body) => {
    try {
        const { data } = await $authHost.post('/api/roles/update', body);
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const removeUserRole = async (id) => {
    try {
        const { data } = await $authHost.post(`/api/roles/remove/${id}`);
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const fetchShippingAddresses = async (id) => {
    try {
        const { data } = await $authHost.get(`/api/address/shipping/${id}`);
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const fetchBillingAddresses = async (id) => {
    try {
        const { data } = await $authHost.get(`/api/address/billing/${id}`);
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const getUserData = async (id) => {
    try {
        const { data } = await $authHost.get(`/api/user/info/${id}`);
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const updateCompany = async (body) => {
    try {
        const { data } = await $authHost.post('/api/company/update', body);
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const getUserSettings = async (id) => {
    try {
        const { data } = await $authHost.get(`/api/user/settings/${id}`);
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const switchTwoFA = async (twoFa) => {
    try {
        const { data } = await $authHost.post('/api/user/settings/set/twofa', { twoFa });
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const commentPrivacySwitch = async (comments_privacy) => {
    try {
        const { data } = await $authHost.post('/api/user/settings/set/commprivacy', { comments_privacy });
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const getGdpr = async () => {
    try {
        const { data } = await $authHost.post('/api/user/gdpr');
        return data;
    } catch (e) {
        return e.response.data;
    }
}