import React, { useEffect, useMemo, useState } from "react";
import { Button, Spinner, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Table } from "react-bootstrap";
import AdminUI from "../../Ui/AdminUI";
import { getForms, removeForm } from "../../../../api/formsAPI";
import LoadingPlaceholder from "../../../Functional/LoadingPlaceholder";
import Pagination from "../../../VitaforestUI/Shop/Pagination";
import RemoveModal from "../RemoveModal";
import { NotificationManager, NotificationContainer } from "react-notifications";


const TableContainer = styled.div`

`
const EditLabel = styled.span`
color: white;
`

const ControlsTitle = styled.span`
margin-right: 15px;
`

const TotalItems = styled.select`
`


const ViewForms = () => {
    const [items, setItems] = useState([])
    const [needReload, setNeedReload] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const [error, setError] = useState(null)
    const [modalActive, setModalActive] = useState(false);
    const [currentItemId, setCurrentItemId] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(9);
    const [bulkSelected, setBulkSelected] = useState([]);

    const currentData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * pageSize;
        let lastPageIndex;
        if (pageSize > items.length) {
            lastPageIndex = firstPageIndex + items.length;
        }
        else {
            lastPageIndex = firstPageIndex + pageSize;
        }
        return items.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, items, pageSize]);

    const RemoveItem = (id) => {
        removeForm(id).then((data) => {
            {
                if (data.status == 'removed') {
                    NotificationManager.success(`Form data removed`, 'Success')
                } else { NotificationManager.error(`${data.message}`, 'Error') }
            }
        });
        setNeedReload(true);
    }

    const handleRemove = (id) => {
        setCurrentItemId(id);
        setModalActive(true);
    }

    useEffect(() => {
        getForms().then((data) => {
            setItems(data)
            setIsLoaded(true)
        })
    }, [])
    if (!isLoaded) {
        return (
            <AdminUI>
                <LoadingPlaceholder />
            </AdminUI>
        )
    }
    else {
        return (
            <AdminUI>
                <NotificationContainer />
                <h1>Forms</h1>
                <h5>Items total: {items.length}</h5>
                <Row className="mb-3">
                    <Col>
                        <ControlsTitle>Items per page:</ControlsTitle>
                        <TotalItems onChange={(e) => { setPageSize(e.target.value) }}>
                            <option value={9}>Default</option>
                            <option value={25}>25 items</option>
                            <option value={50}>50 items</option>
                            <option value={100}>100 items</option>
                        </TotalItems>
                    </Col>
                </Row>
                <Table striped bordered hover variant="dark">
                    <thead>
                        <tr>
                            <th>
                                #
                            </th>
                            <th>
                                From
                            </th>
                            <th>
                                Name
                            </th>
                            <th>
                                Email
                            </th>
                            <th>
                                Phone
                            </th>
                            <th>
                                Message
                            </th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentData.map((item) => (
                            <tr>
                                <td>{item.id}</td>
                                <td>{item.from}</td>
                                <td>{item.name}</td>
                                <td>{item.email}</td>
                                <td>{item.phone}</td>
                                <td>{item.message}</td>
                                <td><Button variant="danger" onClick={() => { handleRemove(item.id) }}>Remove</Button></td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <Pagination currentPage={currentPage}
                    totalCount={items.length}
                    pageSize={pageSize}
                    onPageChange={(page) => setCurrentPage(page)} />
                <RemoveModal modalActive={modalActive} setModalActive={setModalActive} itemId={currentItemId} removeItem={RemoveItem} />
            </AdminUI>
        )
    }
}

export default ViewForms