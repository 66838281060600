export const getSelectCollection = (data) => {
    let result = []
    data.map((item) => {
        result.push({ value: item.id, label: item.name })
    })
    return result
}

export const getTermsList = (attributes) => {
    let result = [];
    attributes.map((attr) => {
        attr.terms.map((term) => {
            result.push({ value: term.id, label: term.name })
        })
    })
    return result;
}

export const compareAttributesTableArray = (selected, attributes) => {
    const array = selected.reduce((acc, id) => {
        const attr = attributes.find(a => a.terms.find(t => t.id === Number(id)));
        if (!attr) return acc;
        const existingAttr = acc.find(item => item.attrName === attr.name);
        if (existingAttr) {
            existingAttr.termName.push(attr.terms.find(t => t.id === Number(id)).name + `:${id}`);
        } else {
            acc.push({ attrName: attr.name, termName: [attr.terms.find(t => t.id === Number(id)).name + `:${id}`] });
        }
        return acc;
    }, []);
    return array
}