import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Page from "../components/VitaforestUI/Interface/Page/Page";
import { liveSearch } from "../api/searchAPI";
import ProductCard from "../components/VitaforestUI/Product/ProductCard/ProductCard";
import BlogElement from "../components/VitaforestUI/Blog/BlogElement";
import { getFileUrl } from "../api/productAPI";
import LoadingPlaceholder from "../components/Functional/LoadingPlaceholder";
import WikiSearchElem from "../components/VitaforestUI/Wiki/WikiSearchElem";
import { Helmet } from "react-helmet";

const Container = styled.div`
  width: calc(100vw - 36px);
  margin: 0 auto;
  @media screen and (min-width: 1128px) {
    width: 1128px;
    margin-top: 30px;
  }
`;

const TabsHeader = styled.div`
  display: flex;
  border-bottom: 1px solid #eaebec;
  @media screen and (min-width: 1128px) {
    padding: 0px 30px;
  }
`;

const SearchHeading = styled.h2`
  font-weight: 600;
  font-size: 20px;
  line-height: 17px;
  color: #303236;
  margin-top: 20px;
  @media screen and (min-width: 1128px){
    margin-bottom: 20px; 
  }
`;

const TabButton = styled.button`
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  color: #7f878b;
  padding: 10px;
  border: none;
  background-color: inherit;
  padding-right: 20px;
  margin-bottom: -1px;
  box-sizing: border-box;
  border-bottom: 1px solid #eaebec;
  margin-bottom: -1px;
  &:hover,
  &:focus,
  &:active {
    color: #40bf6a;
    border-bottom: 1px solid #40bf6a;
  }
  @media screen and (min-width: 1128px) {
    font-size: 20px;
    line-height: 24px;
    min-width: 0px;
  }
  &.active{
    color: #40bf6a;
    border-bottom: 1px solid #40bf6a;
  }
`;

const Count = styled.span`
  font-weight: 900;
`;

const TabContainer = styled.div`
margin-bottom: 30px;
@media screen and (min-width: 1128px){
  margin-bottom: 100px;
}
`;

const Products = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 20px;
`;

const Wiki = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 20px;
  @media screen and (min-width: 1228px){
    gap: 24px;
    margin-top: 60px;
  }
`;

const Posts = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  margin-top: 20px;
  @media screen and (min-width: 1228px){
    margin-top: 70px;
    margin-bottom: 70px;
  }
`;

export default function Search() {
  const [tab, setTab] = useState("products");
  const [results, setResults] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const { url } = useParams();
  useEffect(() => {
    liveSearch(url).then(
      (data) => {
        setResults(data);
        setIsLoaded(true);
      },
      (e) => {
        setError(error);
        setIsLoaded(false);
      }
    );
  }, [url]);
  if (!isLoaded) {
    return (
      <Container>
        <LoadingPlaceholder />
      </Container>
    );
  } else if (error) {
    return <Container>{error}</Container>;
  } else {
    return (
      <Page seo_title={`Search "${url}" - Vitaforest`} seo_description={'Vitaforest search page'}>
        <Container>
          <SearchHeading>Search results for: `{url}`</SearchHeading>
          <TabsHeader>
            <TabButton onClick={() => setTab("products")} className={tab == "products" ? 'active' : null}>
              Products <Count>({results.products.length})</Count>
            </TabButton>
            <TabButton onClick={() => setTab("wiki")} className={tab == "wiki" ? 'active' : null}>
              Wiki <Count>({results.wiki.length})</Count>
            </TabButton>
            <TabButton onClick={() => setTab("blog")} className={tab == "blog" ? 'active' : null}>
              Blog <Count>({results.posts.length})</Count>
            </TabButton>
          </TabsHeader>

          <TabContainer>
            {tab === "products" ? (
              <Products>
                {results.products.map((item) => {
                  return <ProductCard variant={"card"} item={item} />;
                })}
              </Products>
            ) : tab === "wiki" ? (
              <Wiki>
                {results.wiki.map((item) => {
                  return (
                    <WikiSearchElem
                      img={getFileUrl(item.img)}
                      title={item.title}
                      url={item.url}
                      short={item.short}
                    />
                  );
                })}
              </Wiki>
            ) : (
              <Posts>
                {results.posts.map((item) => {
                  return (
                    <BlogElement
                      date={item.date}
                      img={getFileUrl(item.img)}
                      title={item.title}
                      link={item.link}
                      short={item.short}
                      category={item.category}
                    />
                  );
                })}
              </Posts>
            )}
          </TabContainer>
        </Container>
      </Page>
    );
  }
}
