import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Button, Spinner, Row, Col } from "react-bootstrap";
import AdminUI from "../../Ui/AdminUI";
import { NotificationContainer } from "react-notifications";
import { exportCompanies, exportForms, exportPages, exportPostCats, exportPosts, exportPrices, exportProdCats, exportProducts, exportRoles, exportUrl, exportUsers, exportWikis } from "../../../../api/dataManagementAPI";

const Column = styled(Col)`
display: flex;
flex-direction: column;
gap: 20px;
`

const RowStarts = styled(Row)`
display: flex;
justify-content: flex-end;
align-items: flex-end;
`



const ExportPage = () => {

    return (
        <AdminUI>
            <NotificationContainer />
            <h1>Export data</h1>
            <RowStarts>
                <Column>
                    <Button onClick={() => { exportUsers() }}>Export users</Button>
                    <Button onClick={() => { exportRoles() }}>Export users roles</Button>
                    <Button onClick={() => { exportCompanies() }}>Export companies</Button>
                    <Button onClick={() => { exportForms() }}>Export users forms (Contact)</Button>
                </Column>
                <Column>
                    <h2>Export product data</h2>
                    <Button onClick={() => { exportProducts() }}>Export products</Button>
                    <Button onClick={() => { exportProdCats() }}>Export products categories</Button>
                    <Button onClick={() => { exportPrices() }}>Export prices</Button>
                </Column>
                <Column>
                    <h2>Export blog and wiki posts</h2>
                    <Button onClick={() => { exportPosts() }}>Export posts</Button>
                    <Button onClick={() => { exportPostCats() }}>Export post categories</Button>
                    <Button onClick={() => { exportWikis() }}>Export wiki</Button>
                </Column>
                <Column>
                    <h2>Other</h2>
                    <Button onClick={() => { exportPages() }}>Export pages</Button>
                    <Button onClick={() => { exportUrl() }}>Export URLs</Button>
                </Column>
            </RowStarts>
        </AdminUI>
    )

}

export default ExportPage