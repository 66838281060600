import React, { useState, useEffect, useContext, useMemo } from "react";
import { Button, Table, Dropdown, Form, Row, Col } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Context } from "../../../..";
import { createProduct, fetchProducts, fetchCategories, removeProduct } from "../../../../api/productAPI";
import styled from "styled-components";
import { NotificationManager, NotificationContainer } from 'react-notifications';
import { Link, Route } from "react-router-dom";
import RemoveModal from "../RemoveModal";
import Pagination from "../../../VitaforestUI/Shop/Pagination";
import { getPagesDrafted, getPostsDrafted, getProductsDrafted, getWikisDrafted, removeDraftItem } from "../../../../api/draftAPI";
import AdminUI from "../../Ui/AdminUI";


const TableContainer = styled.div`

`
const EditLabel = styled(Link)`
color: white;
text-decoration: none;
&:hover{
    color: white;
}
`

const ControlsTitle = styled.span`
margin-right: 15px;
`

const TotalItems = styled.select`
`

function PageDraftList() {

    const { user } = useContext(Context)

    // States
    const [items, setItems] = useState([])
    const [needReload, setNeedReload] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const [error, setError] = useState(null)
    const [modalActive, setModalActive] = useState(false);
    const [currentItemId, setCurrentItemId] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(9);
    const [bulkSelected, setBulkSelected] = useState([]);

    const currentData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * pageSize;
        let lastPageIndex;
        if (pageSize > items.length) {
            lastPageIndex = firstPageIndex + items.length;
        }
        else {
            lastPageIndex = firstPageIndex + pageSize;
        }
        return items.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, items, pageSize]);


    const getPageTypeString = (type) => {
        if (type == 'wysiwyg') {
            return 'Text page'
        }
        else if (type == 'standalone') {
            return 'HTML page without site interface'
        }
        else if (type == 'standwith') {
            return 'HTML page with site interface'
        }
        else {
            return 'NoTypeError'
        }
    }

    // Remove selected product

    const RemoveProduct = (id) => {
        removeDraftItem(id).then(NotificationManager.success(`Page draft removed`, 'Success'));
        setNeedReload(true);
    }

    const HandleClicker = (id) => {
        setCurrentItemId(Number(id))
        setModalActive(true)
    }

    const HandleBulk = (id) => {
        let item = bulkSelected.indexOf(id);
        if (item >= 0) {
            let prev = bulkSelected;
            prev.splice(item, 1);
            setBulkSelected(prev);
        }
        else {
            let prev = bulkSelected;
            prev.push(id);
            setBulkSelected(prev);
        }
    }

    // useEffect for products loading
    useEffect(() => {
        getPagesDrafted().then(
            (data) => {
                setItems(data);
                setIsLoaded(true);
                setNeedReload(false);
            },
            (error) => {
                setIsLoaded(true)
                setError(error)
            },
        )
        return () => {

        }
    }, [needReload])
    if (error) {
        return (
            <div>
                {NotificationManager.error(`${error.message}`, 'Error')}
                <NotificationContainer />
            </div>
        )
    } else if (!isLoaded) {
        return (
            <h1>Loading...</h1>
        )
    } else {
        return (

            // HTML
            <AdminUI>
                <TableContainer>
                    <h1>All drafted pages</h1>
                    <h5>Items total: {items.length}</h5>
                    <Row className="mb-3">
                        <Col>
                            <ControlsTitle>Items per page:</ControlsTitle>
                            <TotalItems onChange={(e) => { setPageSize(e.target.value) }}>
                                <option value={9}>Default</option>
                                <option value={25}>25 items</option>
                                <option value={50}>50 items</option>
                                <option value={100}>100 items</option>
                            </TotalItems>
                        </Col>
                    </Row>
                    <NotificationContainer />
                    <Table striped bordered hover variant="dark">
                        <thead>
                            <tr>
                                <th></th>
                                <th>#</th>
                                <th>Title</th>
                                <th>Type</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentData.length > 0 ? currentData.map((item) => (
                                <tr key={item.id}>
                                    <td><input type={'checkbox'} value={item.id} onChange={(e) => { HandleBulk(e.target.value); }} defaultChecked={bulkSelected.includes(item.id)} /></td>
                                    <td>{item.id}</td>
                                    <td>{item.seo_title}</td>
                                    <td>{getPageTypeString(item.pageType)}</td>
                                    <td><Button onClick={() => HandleClicker(item.id)}>Delete</Button></td>
                                    <td><Button variant={'primary'}><EditLabel to={'/admin/pages/draft/' + item.id}>Edit</EditLabel></Button></td>
                                </tr>
                            )) : <h6>Pages draft with this parameters not found</h6>}
                        </tbody>
                    </Table>
                    <Pagination currentPage={currentPage}
                        totalCount={items.length}
                        pageSize={pageSize}
                        onPageChange={(page) => setCurrentPage(page)}>
                    </Pagination>
                    <RemoveModal modalActive={modalActive} setModalActive={setModalActive} itemId={currentItemId} removeItem={RemoveProduct} />
                </TableContainer>
            </AdminUI>
        )
    }
}

export default PageDraftList