import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import ShareButton from "../../../Interface/ShareButton/ShareButton";
import WishlistButton from "../../../Interface/WishlistButton/WishlistButton";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Context } from "../../../../..";
import Counter from "../../../Interface/Counter/Counter";
import DefaultButton from "../../../Interface/Buttons/DefaultButton";
import { ReactComponent as Image } from "../img/cart.svg";
import { getFileUrl } from "../../../../../api/productAPI";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { addBasketItem } from "../../../../../api/basketAPI";
import { getAllWishlists } from "../../../../../api/wishlistAPI";
import LoadingPlaceholder from "../../../../Functional/LoadingPlaceholder";
import Status from "../../../Interface/Status/Status";
import { addToRequest } from "../../../../../api/productAPI";
import Modal from "../../../Interface/Modal";
import useModal from "../../../../Functional/useModal";
import useScrollBlock from "../../../../Functional/useScrollBlock";

const Container = styled.div``;

const ProductTitle = styled.h3`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #303236;
  text-align: left;
  padding-right: 40px;
`;

const ButtonsContainer = styled.div`
  position: absolute;
  right: 18px;
  button {
    margin-bottom: 10px;
  }
`;
const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
`;

const SKU = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #7f878b;
`;

const SliderSection = styled.div`
  position: relative;
  img {
    object-fit: cover;
    width: 100%;
    aspect-ratio: 300/200;
    border-radius: 4px;
  }
  .carousel .thumbs-wrapper {
    margin: 0px;
  }
  .thumbs {
    padding: 0;
    margin: 0px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }
  .carousel .thumb {
    margin: 0;
    padding: 0;
    opacity: 0.3;
    border: none;
    &.selected {
      opacity: 1;
      border: none;
    }
  }
`;

const Preorder = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #303236;
  width: 100%;
  background-color: #f7f8f9;
  padding: 3px 15px;
  margin-top: 10px;
`;

const SizeContainer = styled.div`
  background: rgba(24, 160, 251, 0.1);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px 20px;
  display: flex;
  font-weight: 600;
  font-size: 13px;
  line-height: 14px;
  color: #18a0fb;
  opacity: 0.8;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const BuySection = styled.div`
  box-sizing: border-box;
  padding: 15px;
  border: 1px solid #eaebec;
  border-radius: 4px;
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
`;

const RequestButton = styled(DefaultButton)`
  width: calc(100% - 23px);
  height: 30px;
`;

const RequestCounter = styled(Counter)`
  width: calc(100% - 23px);
`;

const Price = styled.p`
  font-weight: 600;
  font-size: 20px;
  line-height: 17px;
  color: #303236;
  width: calc(100% - 23px);
  margin-bottom: 0;
  display: flex;
  align-items: center;
  span {
    color: #7f878b;
  }
`;

const AuthBuySection = styled(BuySection)`
  display: block;
`;

const PickRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  justify-content: space-between;
`;

const BuyRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  width: 100%;
  justify-content: space-between;
`;

const TotalPrice = styled.p`
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #303236;
  margin-bottom: 0px;
  span {
    font-size: 11px;
    line-height: 13px;
    color: #7f878b;
    font-weight: 400;
  }
`;
const BuyButton = styled.button`
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  width: 135px;
  border: none;
  background: #40bf6a;
  border: 1px solid #40bf6a;
  border-radius: 4px;
  padding: 9px 0px;
  justify-content: center;
  span {
    padding-left: 10px;
  }
`;

const MainImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  width: 100%;
  aspect-ratio: 300/200;
  border-radius: 4px;
`;
const MainSection = ({
  title,
  price,
  sku,
  moq,
  gallery,
  status,
  step,
  orderStatus,
  attributes,
  img,
  stock,
  prices,
  id,
  url,
}) => {
  const { user } = useContext(Context);
  // states
  const [value, setValue] = useState(moq);
  const [pricesArray, setPricesArray] = useState(prices ? prices : false);
  const [itemPrice, setItemPrice] = useState(price);
  const [wishlistedItems, setWishlistedItems] = useState([]);
  const [needReload, setNeedReload] = useState(false);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const { isShowing, toggle } = useModal();
  const [blockScroll, allowScroll] = useScrollBlock();
  const [wishModalOpen, setWishModalOpen] = useState(false);
  // load wishlist data
  useEffect(() => {
    if (user.isAuth) {
      getAllWishlists(user.user.id).then(
        (data) => {
          setWishlistedItems(data);
          setIsLoaded(true);
        },
        (error) => {
          setError(error);
          setIsLoaded(false);
        }
      );
    } else {
      setIsLoaded(true);
    }
  }, [needReload]);

  // funcs
  const BoundWish = (wishlist) => {
    let result = [];
    wishlist.map((item) => {
      result.push({ value: item.id, label: item.name });
    });
    return result;
  };

  const addToCart = () => {
    let formdata = new FormData();
    formdata.append("title", title);
    formdata.append("img", img);
    formdata.append("status", status);
    formdata.append("price", itemPrice);
    formdata.append("qty", value);
    formdata.append("prices", JSON.stringify(pricesArray));
    formdata.append("item_id", id);
    formdata.append("moq", moq);
    formdata.append("qty_step", step);
    formdata.append("userId", user.user.id);
    addBasketItem(formdata).then((data) => {
      if (data.id) {
        toggle();
        blockScroll();
      } else {
        NotificationManager.error(`${data.message}`, "Error");
      }
    });
  };

  const CheckInWish = (wish) => {
    let result;
    wish.map((item) => {
      item.items.map((elem) => {
        if (elem.itemId === id) {
          result = true;
        }
      });
    });
    return result;
  };

  const calculatePrice = () => {
    pricesArray.find((elem) => {
      if (value >= elem.min && value <= elem.max) {
        setItemPrice(elem.price);
      }
    });
  };
  if (error) {
    return (
      <Container>
        <h5>{error}</h5>
      </Container>
    );
  }
  if (isLoaded) {
    return (
      <Container>
        <Modal
          type={"add-cart"}
          isShowing={isShowing}
          hide={toggle}
          item={title}
          allow={allowScroll}
          image={getFileUrl(img)}
          link={url}
        />
        <Modal
          type={"wishlist"}
          isShowing={wishModalOpen}
          hide={() => setWishModalOpen(false)}
          wishlists={BoundWish(wishlistedItems)}
          allow={allowScroll}
          id={id}
        />
        <NotificationContainer />
        <HeadingContainer>
          <ProductTitle>{title}</ProductTitle>
          <ButtonsContainer>
            {CheckInWish(wishlistedItems) ? (
              <WishlistButton
                className="wish"
                variant={"liked"}
                onClick={() => {
                  setWishModalOpen(true);
                }}
              />
            ) : (
              <WishlistButton
                className="wish"
                onClick={() => {
                  setWishModalOpen(true);
                }}
              />
            )}

            <ShareButton />
          </ButtonsContainer>
        </HeadingContainer>
        <SKU>SKU: {sku}</SKU>

        <SliderSection>
          {gallery.length == 1 ? (
            <MainImage src={getFileUrl(gallery[0])} />
          ) : (
            <Carousel
              infiniteLoop={true}
              showArrows={false}
              thumbWidth="20vw"
              showIndicators={false}
              showStatus={false}
            >
              {gallery.map((item) => {
                return <img src={getFileUrl(item)} />;
              })}
            </Carousel>
          )}
          <Status variant={status} />
        </SliderSection>
        <Preorder>
          {stock > 0
            ? `In stock: ${stock} kg`
            : stock == 0 && status == "Soon"
              ? "Preorder"
              : "Out of stock"}
        </Preorder>
        <SizeContainer>
          <span>1 box = {step}</span>
          <span>MOQ = {moq}</span>
        </SizeContainer>
        {price && user.isAuth ? (
          <AuthBuySection>
            <PickRow>
              <Price>
                €{itemPrice}
                <span> / kg</span>
              </Price>
              <RequestCounter
                width="160px"
                height="38px"
                value={value}
                step={step}
                qty={moq}
                min={moq}
                setValue={setValue}
                calcPrice={calculatePrice}
              />
            </PickRow>
            <BuyRow>
              <TotalPrice>
                <span>total price</span>€{itemPrice * value}
              </TotalPrice>
              <BuyButton onClick={() => addToCart()}>
                <Image />
                <span>Add to cart</span>
              </BuyButton>
            </BuyRow>
          </AuthBuySection>
        ) : user.isAuth ? (
          <BuySection>
            <RequestCounter
              step={step}
              qty={moq}
              min={moq}
              value={value}
              width={"135px"}
            />
            <BuyButton
              padding={"0px"}
              title="Request"
              onClick={() => {
                addToCart();
              }}
            >
              Add to quote
            </BuyButton>
          </BuySection>
        ) : (
          <BuySection>
            <RequestCounter
              value={value}
              step={step}
              qty={moq}
              min={moq}
              setValue={setValue}
              width={"135px"}
            />
            <BuyButton
              padding={"0px"}
              title="Request"
              onClick={() => {
                addToRequest(id, title, img, moq, status, value, step);
                toggle();
                blockScroll();
              }}
            >
              Request
            </BuyButton>
          </BuySection>
        )}
      </Container>
    );
  } else {
    return (
      <Container>
        <LoadingPlaceholder />
      </Container>
    );
  }
};

export default MainSection;
