import React from "react";
import styled from "styled-components";
import { useState } from "react";

const Main = styled.div`
  display: flex;
  position: relative;
  border: 1px solid #eaebec;
  box-sizing: border-box;
  border-radius: 4px;
  background: #f7f8f9;
  box-sizing: border-box;
  min-width: 135px;
  max-width: 135px;
  height: ${(props) => (props.height ? props.height : "30px")};
  @media screen and (min-width: 1128px) {
    min-width: ${(props) => (props.width ? props.width : "175px")};
    max-width: ${(props) => (props.width ? props.width : "175px")};
  }
`;

const Input = styled.input`
  font-family: "Proxima Nova";
  border: none;
  background: #f7f8f9;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-align: center;
`;

const ButtonPlus = styled.button`
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  width: 20px;
  padding: 0;
  display: block;
  text-align: center;
  border: none;
  font-size: 16px;
  font-weight: 600;
  background: #f7f8f9;
`;

const ButtonMinus = styled(ButtonPlus)`
  position: absolute;
  top: 1px;
  left: 1px;
  bottom: 1px;
  width: 20px;
  padding: 0;
  display: block;
  text-align: center;
  border: none;
  font-size: 16px;
  font-weight: 600;
`;

const Counter = ({
  step,
  min,
  value,
  width,
  height,
  setValue,
  calcPrice,
  stock,
}) => {
  const [defaultValue, setDefaultValue] = useState(value);
  let increment = () => {
    if (defaultValue >= stock) {
      setValue(stock);
      setDefaultValue(stock);
    }
    setDefaultValue(defaultValue + step);
    setValue(defaultValue);
    if (calcPrice) {
      calcPrice();
    }
  };
  let decrement = () => {
    if (defaultValue > min) {
      setDefaultValue(defaultValue - step);
      setValue(defaultValue);
      if (calcPrice) {
        calcPrice();
      }
    }
  };

  return (
    <Main width={width} height={height}>
      <ButtonMinus
        onClick={() => {
          decrement();
        }}
      >
        -
      </ButtonMinus>
      <Input
        type="number"
        value={defaultValue}
        onChange={(e) => {
          e.preventDefault();
          setDefaultValue(Number(e.target.value));
          setValue(Number(e.target.value));
        }}
        min={min}
        max={stock}
      />
      <ButtonPlus
        onClick={() => {
          increment();
        }}
      >
        +
      </ButtonPlus>
    </Main>
  );
};

export default Counter;
