import React from "react";
import styled from "styled-components";


const Container = styled.div`
width: 100%;
height: 50vh;
background: white;
display: flex;
justify-content: center;
align-items: center;
`;

const Spinner = styled.div`
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: #40BF6A transparent #40BF6A transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
    @keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
`

const LoadingPlaceholder = () => {
    return (
        <Container>
            <Spinner />
        </Container>
    )
}

export default LoadingPlaceholder