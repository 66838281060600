import { $authHost, $host } from "./index";

export const createTicket = async (body) => {
    try {
        const { data } = await $authHost.post('api/tickets/add-new', body)
        return data
    } catch (e) {
        return e.response.data
    }
}

export const userFetchTickets = async (userId) => {
    try {
        const { data } = await $authHost.get(`api/tickets/user/${userId}`);
        return data
    } catch (e) {
        return e.response.data;
    }
}

export const adminFetchTickets = async () => {
    try {
        const { data } = await $authHost.get('api/tickets');
        return data
    } catch (e) {
        return e.response.data;
    }
}

export const updateTicket = async (body) => {
    try {
        const { data } = await $authHost.post('api/tickets/update', body);
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const getTicket = async (id) => {
    try {
        const { data } = await $authHost.get(`api/tickets/${id}`);
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const sendTicketMessage = async (body) => {
    try {
        const { data } = await $authHost.post('/api/tickets/send', body);
        return data;
    } catch (e) {
        return e.response.data;
    }
}

