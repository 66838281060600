import { $authHost } from "."

export const createProductDraft = async (draft) => {
    try {
        const { data } = await $authHost.post(`/api/draft/product`, draft);
        return data;
    } catch (e) {
        return e.response.data
    }
}

export const createWikiDraft = async (draft) => {
    try {
        const { data } = await $authHost.post(`/api/draft/wiki`, draft);
        return data;
    } catch (e) {
        return e.response.data
    }
}

export const createPageDraft = async (draft) => {
    try {
        const { data } = await $authHost.post(`/api/draft/page`, draft);
        return data;
    } catch (e) {
        return e.response.data
    }
}

export const createPostDraft = async (draft) => {
    try {
        const { data } = await $authHost.post(`/api/draft/post`, draft);
        return data;
    } catch (e) {
        return e.response.data
    }
}

export const getDraft = async (id) => {
    try {
        const { data } = await $authHost.get(`/api/draft/byid/${id}`)
        return data;
    } catch (e) {
        return e.response.data
    }
}

export const removeDraftItem = async (id) => {
    try {
        const { data } = await $authHost.get(`/api/draft/remove/${id}`)
        return data;
    } catch (e) {
        return e.response.data
    }
}

export const getProductsDrafted = async (id) => {
    try {
        const { data } = await $authHost.get(`/api/draft/all/products`);
        return data;
    } catch (e) {
        return e.response.data
    }
}

export const getWikisDrafted = async (id) => {
    try {
        const { data } = await $authHost.get(`/api/draft/all/wikis`);
        return data;
    } catch (e) {
        return e.response.data
    }
}

export const getPostsDrafted = async (id) => {
    try {
        const { data } = await $authHost.get(`/api/draft/all/posts`);
        return data;
    } catch (e) {
        return e.response.data
    }
}

export const getPagesDrafted = async (id) => {
    try {
        const { data } = await $authHost.get(`/api/draft/all/pages`);
        return data;
    } catch (e) {
        return e.response.data
    }
}

