import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as KyoLogo } from "../AdminPanel/kyoshop.svg";
import { exportProducts, exportUsers } from '../../../api/dataManagementAPI';

const CustomLink = styled(Link)`
color: white;
text-decoration: none;
&:hover{
    color: white;
}
`

const KyoLogotype = styled(KyoLogo)`
width: 130px;
margin: 0px;
padding: 0px;
height: 40px;
display: flex;
align-items: flex-end;
`

const LogoContainer = styled.div`
display: flex;
align-items: center;
`

function AdminSidebar() {
    return (
        <Navbar variant="dark" bg="dark" expand="lg">
            <Container fluid>
                <Navbar.Brand href="#home"><LogoContainer><KyoLogotype /> <span style={{ fontSize: '10pt' }}>version 1.30</span></LogoContainer></Navbar.Brand>
                <Navbar.Toggle aria-controls="navbar-dark" />
                <Navbar.Collapse id="navbar-dark kyoadmin-nav">
                    <Nav>
                        <Nav.Link><CustomLink to='/admin/'>Dashboard</CustomLink></Nav.Link>
                        <Nav.Link><CustomLink to='/admin/tickets/'>Tickets</CustomLink></Nav.Link>
                        <Nav.Link><CustomLink to='/admin/forms/'>Forms</CustomLink></Nav.Link>
                        <Nav.Link><CustomLink to='/admin/orders/'>Orders</CustomLink></Nav.Link>
                        <Nav.Link><CustomLink to='/admin/requests/'>Requests</CustomLink></Nav.Link>
                        <NavDropdown
                            id="nav-dropdown-products"
                            title="Offers"
                            menuVariant="dark"
                        >
                            <NavDropdown.Item><CustomLink to='/admin/offers'>View all offers</CustomLink></NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item>
                                <CustomLink to='/admin/offers/add'>Add new offer</CustomLink>
                            </NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown
                            id="nav-dropdown-products"
                            title="Products"
                            menuVariant="dark"
                        >
                            <NavDropdown.Item><CustomLink to='/admin/products'>View all products</CustomLink></NavDropdown.Item>
                            <NavDropdown.Item><CustomLink to='/admin/products/drafts'>View all drafted products</CustomLink></NavDropdown.Item>
                            <NavDropdown.Item><CustomLink to='/admin/products/urls'>Shop URL Constructor</CustomLink></NavDropdown.Item>
                            <NavDropdown.Item><CustomLink to='/admin/products/categories'>Categories</CustomLink></NavDropdown.Item>
                            <NavDropdown.Item><CustomLink to='/admin/attributes'>View attributes</CustomLink></NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item>
                                <CustomLink to='/admin/products/add'>Add new product</CustomLink>
                            </NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown
                            id="nav-dropdown-posts"
                            title="Posts"
                            menuVariant='dark'
                        >
                            <NavDropdown.Item><CustomLink to='/admin/posts'>View all posts</CustomLink></NavDropdown.Item>
                            <NavDropdown.Item><CustomLink to='/admin/post/drafts'>View all drafted posts</CustomLink></NavDropdown.Item>
                            <NavDropdown.Item><CustomLink to='/admin/posts/comments-list'>View all comments</CustomLink></NavDropdown.Item>
                            <NavDropdown.Item><CustomLink to='/admin/posts/comments-approve'>View unapproved comments</CustomLink></NavDropdown.Item>
                            <NavDropdown.Item><CustomLink to='/admin/posts/categories'>Post categories</CustomLink></NavDropdown.Item>
                            <NavDropdown.Divider></NavDropdown.Divider>
                            <NavDropdown.Item><CustomLink to='/admin/posts/add'>Add new post</CustomLink></NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown
                            id="nav-dropdown-posts"
                            title="Wiki"
                            menuVariant='dark'
                        >
                            <NavDropdown.Item><CustomLink to='/admin/wiki'>View all posts</CustomLink></NavDropdown.Item>
                            <NavDropdown.Item><CustomLink to='/admin/wiki/drafts'>View all drafted wiki posts</CustomLink></NavDropdown.Item>
                            <NavDropdown.Divider></NavDropdown.Divider>
                            <NavDropdown.Item><CustomLink to='/admin/wiki/add'>Add new post</CustomLink></NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown
                            id="nav-dropdown-posts"
                            title="Users"
                            menuVariant='dark'
                        >
                            <NavDropdown.Item><CustomLink to='/admin/users'>View all users</CustomLink></NavDropdown.Item>
                            <NavDropdown.Item><CustomLink to='/admin/users/roles'>View roles</CustomLink></NavDropdown.Item>
                            <NavDropdown.Divider></NavDropdown.Divider>
                            <NavDropdown.Item><CustomLink to='/admin/'>Add new user</CustomLink></NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown
                            id="nav-dropdown-posts"
                            title="Settings"
                            menuVariant='dark'
                        >
                            <NavDropdown.Item><CustomLink to='/admin/customize/header'>Edit header promo blocks</CustomLink></NavDropdown.Item>
                            <NavDropdown.Divider></NavDropdown.Divider>
                            <NavDropdown.Item><CustomLink to='/admin/pages/create/wysiwyg'>Create new page (Default)</CustomLink></NavDropdown.Item>
                            <NavDropdown.Item><CustomLink to='/admin/pages/create/html'>Create new page (HTML custom)</CustomLink></NavDropdown.Item>
                            <NavDropdown.Item><CustomLink to='/admin/pages'>View editable pages list</CustomLink></NavDropdown.Item>
                            <NavDropdown.Item><CustomLink to='/admin/pages/drafts'>View pages drafts</CustomLink></NavDropdown.Item>
                            <NavDropdown.Item><CustomLink to='/admin/exports'>Export data</CustomLink></NavDropdown.Item>
                            <NavDropdown.Divider></NavDropdown.Divider>
                            <NavDropdown.Item>kyoShop 1.30</NavDropdown.Item>
                            <NavDropdown.Item>Developed by Stepan Pavlenko</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
export default AdminSidebar;