import { $authHost, $host } from ".";

export const removeWiki = async (id) => {
    try {
        const { data } = await $authHost.post('api/wiki/remove', id)
        return true
    }
    catch (e) {
        return e.response.data
    }
}


export const createWiki = async (wiki) => {
    try {
        const { data } = await $authHost.post('api/wiki ', wiki);
        return data
    }
    catch (e) {
        return e.response.data
    }
}

export const updateWiki = async (wiki) => {
    try {
        const { data } = await $authHost.post('api/wiki/update', wiki)
        return true
    }
    catch (e) {
        return e.response.data
    }
}

export const fetchWiki = async (page, limit = 5) => {
    try {
        const { data } = await $host.get('api/wiki', {
            params: {
                page, limit
            }
        })
        return data
    }
    catch (e) {
        return e.response.data
    }
}

export const fetchOneWiki = async (id) => {
    try {
        const { data } = await $host.get(`api/wiki/${id}`)
        return data
    }
    catch (e) {
        return e.response.data
    }
}

export const fetchWikiByUrl = async (url) => {
    try {
        const { data } = await $host.get(`api/wiki/url/${url}`)
        return data
    }
    catch (e) {
        return e.response.data
    }
}