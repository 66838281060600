import { EditorState } from "draft-js";
import React, { useEffect, useState } from "react"
import { Button, Card, CloseButton, Col, Form, Row, Spinner, Table } from "react-bootstrap";
import styled from "styled-components";
import { decodeEditorBody, encodeEditorBody } from "../../../../api/postAPI";
import { fetchCategories, fetchOneProduct, updateProduct, getFileUrl, deletePrice } from "../../../../api/productAPI";
import { NotificationContainer, NotificationManager } from "react-notifications";
import AdminUI from "../../Ui/AdminUI";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Editor } from "react-draft-wysiwyg";
import File from "../../../VitaforestUI/Interface/File/File";
import { useParams } from "react-router-dom";
import FMAddImage from "../../FileManager/FMAddImage";
import FMAddImageMultiple from "../../FileManager/FMAddImageMultiple";
import { fetchUserRoles } from "../../../../api/userAPI";
import FMAddFiles from "../../FileManager/FMAddFiles";
import { getFilesCollection } from "../../../../api/fileAPI";
import AttributesManager from "../../AttributesManager/AttributesManager";

// styled components

const FilePreview = styled.div`
margin-bottom: 20px;
`;

const SelectFiles = styled(Form.Group)`
margin-top: 10px;
display: flex;
justify-content: space-between;
flex-direction: column;
`;


const Flex = styled.div`
display: flex;
`;

const FlexTitle = styled.div`
display: flex;
margin-bottom: 20px;
`;


const Preview = styled.img`
width: 256px;
height: 256px;
object-fit: cover;
`;

const PreviewGallery = styled.img`
width: 150px;
height: 150px;
object-fit: cover;
margin-right: 20px;
`;


const PreviewFlex = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
`;

const PreviewGalleryFlex = styled.div`
display: flex;
flex-wrap: wrap;
flex-direction: row;
`;

const PreviewContainer = styled.div`

`;

const DeleteGalleryItem = styled(CloseButton)`
position: relative;
`



const PageTitle = styled.h1`
margin: 0px;
margin-right: 20px;
`

const PreviewDocumentsFlex = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
`;


const EditProduct = () => {
  // get id of product
  const { id } = useParams();

  // states
  const [title, setTitle] = useState('');
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [price, setPrice] = useState(null);
  // images
  const [primaryImage, setPrimaryImage] = useState(null);
  const [imageGallery, setImageGallery] = useState(null);
  // -----------

  // file collection
  const [fileCollection, setFileCollection] = useState([]);
  const [fetchReload, setFetchReload] = useState(false);
  // documents
  const [documentsGallery, setDocumentsGallery] = useState([]);
  const [moq, setMoq] = useState(null);
  const [qtyStep, setQtyStep] = useState(null);
  const [stock, setStock] = useState(null);
  const [sku, setSku] = useState(null);
  const [status, setStatus] = useState(null);
  const [categoryList, setCategoryList] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [url, setUrl] = useState('');
  // gallery of downloaded
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [catsLoaded, setCatsLoaded] = useState(false);
  // date info
  const [createdAt, setCreatedAt] = useState(null);
  const [updatedAt, setUpdatedAt] = useState(null);

  // seo states
  const [seoTitle, setSeoTitle] = useState('');
  const [seoDesc, setSeoDesc] = useState('');
  const [seoKeywords, setSeoKeywords] = useState('');

  // modals showing state
  const [showPrimaryAdd, setShowPrimaryAdd] = useState(false);
  const [showGalleryAdd, setShowGalleryAdd] = useState(false);
  const [showDocsAdd, setShowDocsAdd] = useState(false);
  const [prices, setPrices] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [role, setRole] = useState('');
  const [rolesIsLoaded, setRolesIsLoaded] = useState(false);
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  //-------------


  const previewUrlGenerate = (item) => {
    if (item.includes('local?', 0)) {
      let resItem = item.split('?')[1];
      return resItem;
    }
    else {
      return getFileUrl(item);
    }
  }
  // funcs

  useEffect(() => {
    let result = title.replace(/\s+/g, '-').toLowerCase();
    setUrl(result);
  }, [title])

  useEffect(() => {
    let result = url.replace(/\s+/g, '-').toLowerCase();
    setUrl(result);
  }, [url])


  const selectPrimaryImage = () => {
    setShowPrimaryAdd(true)
  }

  const selectGallery = () => {
    setShowGalleryAdd(true);
  }

  const selectDocuments = () => {
    setShowDocsAdd(true);
  }


  // get files info
  useEffect(() => {
    getFilesCollection(documentsGallery).then((data) => {
      setFileCollection(data);
    })
  }, [fetchReload])

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState)
  }

  const categoryHandler = (name) => {
    let item = selectedCategories.indexOf(name);
    if (item >= 0) {
      let prev = selectedCategories;
      prev.splice(item, 1);
      setSelectedCategories(prev);
    }
    else {
      let prev = selectedCategories;
      prev.push(name);
      setSelectedCategories(prev);
    }
  }

  // delete existing price in database 

  const deleteExistingPrice = (id) => {
    deletePrice(id).then((data) => {
      if (data.status == 'removed') {
        NotificationManager.success('Price value removed', 'Success')
      }
      else {
        NotificationManager.error(data.message, 'Error')
      }
    })
  };

  // prices control

  const addPrice = () => {
    setPrices([...prices, { min: "", max: "", price: "", role: role, productId: "", number: Date.now() }]);
  };

  const removePrice = (number) => {
    setPrices(prices.filter((i) => i.number !== number));
  };

  const changePrice = (key, value, number) => {
    setPrices(
      prices.map((i) => (i.number === number ? { ...i, [key]: value } : i))
    );
  };

  const changeExistingPrice = (key, value, number) => {
    setPrices(
      prices.map((i) => (i.id === number ? { ...i, [key]: value } : i))
    );
  };


  const publish = () => {
    const formdata = new FormData();
    formdata.append('id', id);
    formdata.append('title', title);
    formdata.append('description', encodeEditorBody(editorState));
    formdata.append('price', price);
    formdata.append('moq', moq);
    formdata.append('qty_step', qtyStep);
    formdata.append('img', primaryImage);
    formdata.append('gallery', JSON.stringify(imageGallery));
    formdata.append('attributeId', JSON.stringify(selectedAttributes));
    formdata.append('documents', JSON.stringify(documentsGallery));
    formdata.append('prices', JSON.stringify(prices));
    formdata.append('stock', stock);
    formdata.append('status', status);
    formdata.append('seo_title', seoTitle);
    formdata.append('seo_description', seoDesc);
    formdata.append('seo_keywords', seoKeywords);
    formdata.append('sku', sku);
    formdata.append('url', url);
    formdata.append('category', JSON.stringify(selectedCategories));
    updateProduct(formdata).then((data) => {
      if (!data.message) {
        NotificationManager.success(
          `Product "${title}" successfully updated`,
          "Success"
        );
        return data.id;
      } else {
        NotificationManager.error(
          `Product "${title}" cannot be updated. Error: ${data.message}`,
          `Error`
        );
      }
    });
  }

  // load cats
  useEffect(() => {
    fetchCategories().then((data) => {
      setCategoryList(data);
      setCatsLoaded(true);
    })
  }, [])

  useEffect(() => {
    fetchUserRoles().then((data) => {
      setRoleList(data);
      setRolesIsLoaded(true);
    })
  }, [])

  // load data
  useEffect(() => {
    fetchOneProduct(id).then((data) => {
      setTitle(data.title);
      setPrice(data.price);
      setMoq(data.moq);
      setQtyStep(data.qty_step);
      setStock(data.stock);
      setStatus(data.status);
      setPrimaryImage(data.img == 'null' ? '/placeholder/no-img.gif' : !data.img ? '/placeholder/no-img.gif' : data.img)
      setDocumentsGallery(JSON.parse(data.documents));
      setImageGallery(JSON.parse(data.gallery));
      setSeoTitle(data.seo_title);
      setSeoDesc(data.seo_description);
      setSelectedAttributes(data.attributeId);
      setSeoKeywords(data.seo_keywords);
      if (String(data.category) != 'null') {
        setSelectedCategories(data.category);
      }
      setEditorState(EditorState.createWithContent(decodeEditorBody(data.description)));
      setUrl(data.url);
      setSku(data.sku);
      setFetchReload(!fetchReload)

      // convert from ISO date
      let created = new Date(data.createdAt);
      let formattedCreated = `${(created.getMonth() + 1)}/${created.getDate()}/${created.getFullYear()}`;
      let updated = new Date(data.updatedAt);
      let formattedUpdated = `${(updated.getMonth() + 1)}/${updated.getDate()}/${updated.getFullYear()}`;
      setCreatedAt(formattedCreated);
      setUpdatedAt(formattedUpdated);
      setPrices(data.prices);
      setIsLoaded(true);
    }, (error) => {
      setIsLoaded(false);
      setError(error);
    })
  }, [])

  if (error) {
    return (
      <AdminUI>
        {NotificationManager.error(`${error.message}`, 'Error')}
        <NotificationContainer />
      </AdminUI>
    )
  } else if (!isLoaded || !catsLoaded || !rolesIsLoaded) {
    return (
      <AdminUI>
        <Flex>
          <Spinner />
          <h1>Loading...</h1>
        </Flex>
      </AdminUI>
    )
  } else {
    return (
      <AdminUI AdminUI >
        <NotificationContainer />
        <FlexTitle>
          <PageTitle>Update product "{title}"</PageTitle>
          <Button onClick={() => publish()}>Update</Button>
        </FlexTitle>
        <Form>
          <Row>
            <Col xs={7}>
              <Card className="mb-3">
                <Card.Body>
                  <Form.Group className="mb-2">
                    <Form.Label>Product name</Form.Label>
                    <Form.Control type="text" size="lg" placeholder="Enter product name" value={title} onChange={(e) => setTitle(String(e.target.value))}></Form.Control>
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label>Product status</Form.Label>
                    <Form.Select value={status} onChange={(e) => { setStatus(e.target.value); }}>
                      <option value="Avaliable">Avaliable</option>
                      <option value="Soon">Soon</option>
                      <option value="Development">Development</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label>SKU</Form.Label>
                    <Form.Control type="text" placeholder="Enter product SKU" value={sku} onChange={(e) => setSku(String(e.target.value))}></Form.Control>
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label>Product URL</Form.Label>
                    <Form.Control type="text" placeholder="Enter product url" value={url} onChange={(e) => setUrl(String(e.target.value))}></Form.Control>
                    <Form.Text className="text-muted">Product name automatically uses to generate url, but you can write it by yourself</Form.Text>
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label>SEO Title</Form.Label>
                    <Form.Control type="text" placeholder="SEO Title" value={seoTitle} onChange={(e) => setSeoTitle(String(e.target.value))}></Form.Control>
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label>SEO Description</Form.Label>
                    <Form.Control type="text" placeholder="SEO Description" value={seoDesc} onChange={(e) => setSeoDesc(String(e.target.value))}></Form.Control>
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label>SEO Keywords</Form.Label>
                    <Form.Control type="text" placeholder="SEO Keywords" value={seoKeywords} onChange={(e) => setSeoKeywords(String(e.target.value))}></Form.Control>
                    <Form.Text className="text-muted">List the key words, separated by commas</Form.Text>
                  </Form.Group>
                </Card.Body>
              </Card>
              <Card className="mb-3">
                <Card.Header>Categories</Card.Header>
                <Card.Body>
                  {categoryList.map((item) => {
                    return (
                      <Form.Check key={item.id} type={"checkbox"}>
                        <Form.Check.Input
                          type={"checkbox"}
                          onClick={() => categoryHandler(item.id)}
                          defaultChecked={selectedCategories.includes(item.id)}
                        />
                        <Form.Check.Label>{item.name}</Form.Check.Label>
                      </Form.Check>
                    );
                  })}
                </Card.Body>
              </Card>
              <Card className="mb-3">
                <Form.Group>
                  <Card.Header>Description</Card.Header>
                  <Card.Body>
                    <Editor editorState={editorState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={onEditorStateChange} />
                  </Card.Body>
                </Form.Group>
              </Card>
              <Card className="mb-3">
                <Card.Body>
                  <Form.Group className="mb-2">
                    <Form.Label>Product price</Form.Label>
                    <Form.Control type="number" placeholder="Set product price" value={price} min={0} onChange={(e) => setPrice(Number(e.target.value))}></Form.Control>
                    <Form.Text className="text-muted">That is default product price value for non-specific user role (max. price)</Form.Text>
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label>Stock (kg)</Form.Label>
                    <Form.Control type="number" placeholder="Set product stock" value={stock} min={0} onChange={(e) => setStock(Number(e.target.value))} ></Form.Control>
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label>Minimal order quantity</Form.Label>
                    <Form.Control type="number" placeholder="Set MOQ" value={moq} onChange={(e) => setMoq(Number(e.target.value))} min={0}></Form.Control>
                    <Form.Text className="text-muted">Minimal order quantity is minimal value of current product in order</Form.Text>
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label>Step quantity</Form.Label>
                    <Form.Control type="number" placeholder="Set quantity step" min={0} value={qtyStep} onChange={(e) => { setQtyStep(Number(e.target.value)); }}></Form.Control>
                    <Form.Text className="text-muted">Step value of product qty</Form.Text>
                  </Form.Group>
                </Card.Body>
              </Card>
              <AttributesManager selected={selectedAttributes} setSelected={setSelectedAttributes} />
              <Card className="mb-3">
                <Card.Body>
                  <Row>
                    <Button
                      onClick={addPrice}>
                      Add price for role
                    </Button>
                    <Form.Select value={role} onChange={(e) => { setRole(e.target.value) }}>
                      {roleList.map((item) => (
                        <option>{item.name}</option>
                      ))}
                    </Form.Select>
                  </Row>

                  {prices.map(i =>
                    <Row className="mt-4" key={i.id ? i.id : i.number}>
                      <Col md={2}>
                        {i.id ? <Form.Control
                          value={i.min}
                          type='number'
                          onChange={(e) => changeExistingPrice('min', Number(e.target.value), i.id)}
                          placeholder="Minimum qty"
                        /> : <Form.Control
                          value={i.min}
                          type='number'
                          onChange={(e) => changePrice('min', Number(e.target.value), i.number)}
                          placeholder="Minimum qty"
                        />}
                      </Col>
                      <Col md={2}>
                        {i.id ? <Form.Control
                          value={i.max}
                          type='number'
                          onChange={(e) => changeExistingPrice('max', Number(e.target.value), i.id)}
                          placeholder="Maximum qty"
                        /> : <Form.Control
                          value={i.max}
                          type='number'
                          onChange={(e) => changePrice('max', Number(e.target.value), i.number)}
                          placeholder="Maximum qty"
                        />}
                      </Col>
                      <Col md={2}>
                        {i.id ? <Form.Control
                          value={i.price}
                          type='number'
                          onChange={(e) => changeExistingPrice('price', Number(e.target.value), i.id)}
                          placeholder="Price"
                        /> : <Form.Control
                          value={i.price}
                          type='number'
                          onChange={(e) => changePrice('price', Number(e.target.value), i.number)}
                          placeholder="Price"
                        />}
                      </Col>
                      <Col md={2}>
                        {i.id ? <Form.Control
                          value={i.role}
                          onChange={(e) => changeExistingPrice('role', e.target.value, i.id)}
                          placeholder="Role"
                        /> : <Form.Control
                          value={i.role}
                          onChange={(e) => changePrice('role', e.target.value, i.number)}
                          placeholder="Role"
                        />}
                      </Col>
                      <Col md={2}>
                        {i.id ? <Button onClick={() => deleteExistingPrice(i.id)} variant={"outline-danger"} >Remove</Button> : <Button onClick={() => removePrice(i.number)} variant={"outline-danger"} >Remove</Button>}
                      </Col>
                    </Row>
                  )}
                </Card.Body>
              </Card>
            </Col>
            <Col xs={5}>
              <Card className="mb-3">
                <Card.Body>
                  <Form.Group>
                    <PreviewFlex>
                      <Form.Label>About</Form.Label>
                      <Form.Text>{`Created at: ${createdAt.toString()}`}</Form.Text>
                      <Form.Text>{`Updated at: ${updatedAt.toString()}`}</Form.Text>
                    </PreviewFlex>
                  </Form.Group>
                </Card.Body>
              </Card>
              <Card className="mb-3">
                <Card.Body>
                  <Form.Group>
                    <PreviewFlex>
                      <Form.Label>Primary image preview</Form.Label>
                      <Preview src={getFileUrl(primaryImage)}></Preview>
                      <p>{primaryImage == '/placeholder/no-img.gif' ? 'Empty' : primaryImage}</p>
                    </PreviewFlex>
                  </Form.Group>
                  <SelectFiles>
                    <Form.Label>Select primary image</Form.Label>
                    <Button onClick={selectPrimaryImage}>Select image</Button>
                  </SelectFiles>
                </Card.Body>
              </Card>
              <Card className="mb-3">
                <Card.Header>Image gallery</Card.Header>
                <Card.Body>
                  <Form.Group>
                    <PreviewGalleryFlex>
                      {imageGallery ? imageGallery.map((item) => (
                        <PreviewContainer>
                          <PreviewGallery src={previewUrlGenerate(item)} />
                        </PreviewContainer>
                      )) : null}
                    </PreviewGalleryFlex>
                  </Form.Group>
                  <SelectFiles>
                    <Form.Label>Select files</Form.Label>
                    <Button onClick={selectGallery}>Select images</Button>
                    <Form.Text className="text-muted">Accept webp, jpeg, png</Form.Text>
                  </SelectFiles>
                </Card.Body>
              </Card>
              <Card className="mb-3">
                <Card.Header>Documents</Card.Header>
                <Card.Body>
                  <Form.Group>
                    <PreviewDocumentsFlex>
                      {fileCollection ? fileCollection.map((item) => (
                        <FilePreview>
                          <File title={item.name} />
                        </FilePreview>
                      )) : null}
                    </PreviewDocumentsFlex>
                  </Form.Group>
                  <SelectFiles>
                    <Form.Label>Select files</Form.Label>
                    <Button onClick={selectDocuments}>Select documents</Button>
                    <Form.Text className="text-muted">Accept pdf</Form.Text>
                  </SelectFiles>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Form>
        <FMAddImage showModal={showPrimaryAdd} setShowModal={setShowPrimaryAdd} image={primaryImage} setImage={setPrimaryImage} />
        <FMAddImageMultiple showModal={showGalleryAdd} setShowModal={setShowGalleryAdd} gallery={imageGallery} setGallery={setImageGallery} />
        <FMAddFiles showModal={showDocsAdd} setShowModal={setShowDocsAdd} gallery={documentsGallery} setGallery={setDocumentsGallery} reload={fetchReload} setReload={setFetchReload} />
      </AdminUI >
    )
  }
}
export default EditProduct;
