import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Page from "../components/VitaforestUI/Interface/Page/Page";
import { Carousel as Jija } from "react-responsive-carousel";
import MediaQuery from "react-responsive";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import { fetchProducts, getFileUrl } from "../api/productAPI";
import LoadingPlaceholder from "../components/Functional/LoadingPlaceholder";
import { Helmet } from "react-helmet";
import SliderButton from "../components/VitaforestUI/Interface/Buttons/SliderButton";
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1128 },
    items: 3,
    paritialVisibilityGutter: 60,
  },
  tablet: {
    breakpoint: { max: 1128, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Container = styled.div`
  width: calc(100vw - 36px);
  margin: 30px auto;
  @media screen and (min-width: 1128px) {
    width: 1128px;
    margin-top: 36px;
  }
`;

const Preview = styled.div`
  height: 530px;
  background: url("/production-preview.webp");
  background-size: cover;
  margin-top: -10px;
  @media screen and (min-width: 1128px) {
    height: 380px;
  }
`;

const PreviewTitle = styled.h2`
  font-weight: 700;
  font-size: 38px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
  padding-top: 100px;
  position: relative;

  @media screen and (min-width: 1128px) {
    padding-top: 110px;
    font-size: 32px;
    line-height: 36px;
  }
`;

const PreviewDescription = styled.p`
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  width: 250px;
  margin-top: 15px;
  margin: 15px auto;
  @media screen and (min-width: 1128px) {
    width: 364px;
    margin-top: 6px;
  }
`;

const Heading = styled.h4`
  font-weight: 600;
  font-size: 26px;
  margin-bottom: 30px;
  line-height: 28px;
  @media screen and (min-width: 1128px) {
    font-weight: 700;
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 40px;
  }
`;

const ProcessSection = styled.div`
  margin-top: 50px;
  margin-bottom: 70px;
  @media screen and (min-width: 1128px) {
    margin-top: 80px;
    margin-bottom: 100px;
  }
`;

const ProcessList = styled.ol`
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  margin: 0px;
  padding: 0px;
  counter-reset: custom-counter;
  list-style: none;
  li {
    &:nth-child(6) {
      &::after {
        content: " ";
      }
    }
    counter-increment: custom-counter;
    position: relative;
    z-index: 1;
    &:before {
      content: counter(custom-counter);
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: #40bf6a;
      position: absolute;
      width: 24px;
      height: 24px;
      left: -12px;
      background-color: #fff;
      z-index: 2;
    }
  }
  @media screen and (min-width: 1128px) {
    display: flex;
    gap: 27px;
  }
`;

const ProcessItem = styled.li`
  padding: 10px;
  width: calc(100% - 15px);
  margin: 0 auto;
  text-align: center;
  border: 1px solid #40bf6a;
  border-radius: 4px;
  margin-bottom: 27px;

  &::after {
    content: url("/process-arrow.svg");
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: -25px;
    left: calc(50% - 4px);
  }
  @media screen and (min-width: 1128px) {
    padding: 13px;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    &::after {
      transform: rotate(-90deg);
      bottom: initial;
      left: initial;
      right: -22px;
    }
  }
`;

const Section = styled.section`
  text-align: justify;
`;

const ProcessTitle = styled.h3`
  font-weight: 600;
  font-size: 26px;
  line-height: 28px;
  color: #303236;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  text-align: left;
  @media screen and (min-width: 1128px) {
    font-weight: 700;
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 30px;
  }
`;

const TitleNumber = styled.span`
  font-size: 75px;
  line-height: 91px;
  color: #7f878b;
  opacity: 0.3;
  font-weight: 250;
`;

const TitleContent = styled.span``;

const ObtainingList = styled.ul`
  padding-left: 0;
  margin-top: 45px;
  margin-bottom: 65px;
  @media screen and (min-width: 1128px) {
    margin-top: 35px;
    margin-bottom: 105px;
    display: flex;
    justify-content: space-between;
  }
`;

const ObtainingItem = styled.li`
  display: flex;
  gap: 30px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #303236;
  opacity: 0.9;
  margin-bottom: 20px;
  @media screen and (min-width: 1128px) {
    margin-bottom: 0;
  }
`;
const ObtainingItemContent = styled.p`
  width: 184px;
  margin-bottom: 0;
  text-align: left;
  @media screen and (min-width: 1128px) {
    width: 210px;
  }
`;

const ObtainingImage = styled.img``;

const SliderSection = styled.section`
  box-sizing: border-box;
  padding: 50px 0px;
  background: #f7f8f9;
  .button {
    border: none;
    background-color: transparent;
    position: absolute;
    bottom: 56px;
    z-index: 8;
    &.prevButton {
      left: calc(50% - 80px);
    }
    &.nextButton {
      right: calc(50% - 80px);
    }
  }
  .carousel .carousel-status {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #3c3f54;
    position: absolute;
    bottom: 44px;
    left: calc(50% - 21px);
    top: initial;
    text-shadow: none;
  }
  @media screen and (min-width: 1128px) {
    padding-top: 44px;
    .carousel .slide img {
      width: 550px;
      height: 237px;
      object-fit: cover;
    }
    .button {
      bottom: 36px;
    }
    .carousel .carousel-status {
      bottom: 24px;
    }
  }
`;

const Slide = styled.div`
  position: relative;
  padding-bottom: 116px;
`;
const SlideWrapper = styled.div`
  @media screen and (min-width: 1128px) {
    display: flex;
    gap: 97px;
    align-items: center;
  }
`;

const SlideImageWrapper = styled.div`
  position: relative;
`;

const SlideImage = styled.img`
  border-radius: 4px;
`;
const SlideTitle = styled.h5`
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  font-weight: 400;
  position: absolute;
  left: 18px;
  bottom: 18px;
  @media screen and (min-width: 1128px) {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #303236;
    position: initial;
    left: initial;
    bottom: initial;
  }
`;

const SlideContent = styled.div`
  text-align: justify;
  padding-top: 30px;
  padding-bottom: 21px;
`;

const SlideDescription = styled.p`
  margin-top: 30px;
`;

const SliderContainer = styled(Container)`
  margin-bottom: 0;
`;

const FullWidthSlider = styled.div`
  .react-multi-carousel-track {
    gap: 15px;
  }
`;

const ImageSlide = styled.div`
  min-width: 254px;
  position: relative;
  box-shadow: 0px 4px 14px rgba(26, 33, 45, 0.1);
  margin-right: 15px;
  height: 150px;
  border-radius: 5px;
  @media screen and (min-width: 1128px) {
    min-width: 338px;
    height: 200px;
  }
`;

const ImageSlideTitle = styled.h5`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  position: absolute;
  bottom: 45px;
  left: 30px;
`;

const ImageSlideThumbnail = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 5px;
  @media screen and (min-width: 1128px) {
    height: 200px;
  }
`;

const ImageSlideLink = styled(Link)`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-decoration-line: underline;
  color: #40bf6a;
  position: absolute;
  bottom: 20px;
  left: 30px;
  :after {
    transform: rotate(-90deg);
    content: url("/process-arrow.svg");
    position: absolute;
    right: -10px;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100vw - 36px);
  margin: 0 auto;
  margin-bottom: 30px;
  @media screen and (min-width: 1128px) {
    gap: 60px;
    justify-content: flex-start;
    width: 1128px;
  }
`;
const FullWidthSliderTitle = styled.h5`
  font-weight: 600;
  font-size: 26px;
  line-height: 28px;
  margin-bottom: 0;
`;

const SliderLink = styled(Link)`
  padding: 8.5px 17px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #40bf6a;
  border: 1px solid #40bf6a;
  border-radius: 20px;
  text-decoration: none;
  &:hover {
    background: #61d186;
    color: white;
    text-decoration: underline;
  }
  &:active {
    background: #2e9e53;
    color: white;
    text-decoration: underline;
  }
`;

const ExtractSliderSection = styled(SliderSection)`
  background: #1a212d;
  color: #ffffff;
`;

const ExtractProcessTitle = styled(ProcessTitle)`
  color: #ffffff;
`;

const ExtractSlide = styled(Slide)`
  color: #ffffff;
  opacity: 0.7;
  h5 {
    color: #ffffff;
  }
`;

const BlackSalam = styled.div`
  background: black;
  opacity: 35%;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 5px;
`;

const SingleSection = styled(Section)`
  margin-bottom: 60px;
  background-color: #ffffff;
  @media screen and (min-width: 1128px) {
    margin-bottom: 80px;
  }
`;

export default function Production() {
  const [items, setItems] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  useEffect(() => {
    fetchProducts([], []).then(
      (data) => {
        setItems(data);
        setIsLoaded(true);
      },
      (e) => {
        setIsLoaded(true);
      }
    );
  }, []);
  if (!isLoaded) {
    return (
      <Page>
        <LoadingPlaceholder />
      </Page>
    );
  }
  return (
    <Page seo_title={`Production - Vitaforest`} seo_description={'Vitaforest production page'}>
      <Preview>
        <PreviewTitle>Our production</PreviewTitle>
        <PreviewDescription>
          We implement the whole cycle <br />
          of VitaForest's powders and extracts production
        </PreviewDescription>
      </Preview>
      <Container>
        <ProcessSection>
          <Heading>Process</Heading>
          <ProcessList>
            <ProcessItem>Obtaining raw materials</ProcessItem>
            <ProcessItem>Primary processing of raw materials</ProcessItem>
            <ProcessItem>Production and quality control of powders</ProcessItem>
            <ProcessItem>
              Production and quality control of extracts
            </ProcessItem>
            <ProcessItem>Storing </ProcessItem>
            <ProcessItem>Logistics</ProcessItem>
          </ProcessList>
        </ProcessSection>
        <Section>
          <ProcessTitle>
            <TitleNumber>1</TitleNumber>
            <TitleContent>Obtaining raw materials</TitleContent>
          </ProcessTitle>
          <p>
            Raw materials are obtained in outlying Siberian areas, far away from
            urban civilization. Our company with help of local people carefully
            forage wild mushrooms, berries, fruits, roots and herbs by hand,
            this way the ecological purity of our raw materials is guaranteed.
            We do not stimulate growth of raw materials anyhow and do not
            cultivate any organic products, instead we benefit from natural
            environment and wild nature.
          </p>
          <p>
            To maintain high quality of raw materials and nature conservation
            all foragers get instructed on sight. Foraged mushrooms and plants
            get sorted, verified, tested in laboratory condition and sent for
            processing.
          </p>
          <ObtainingList>
            <ObtainingItem>
              <ObtainingImage src="/obtaining1.svg" />
              <ObtainingItemContent>
                Faraway from industrial areas wilderness
              </ObtainingItemContent>
            </ObtainingItem>
            <ObtainingItem>
              <ObtainingImage src="/obtaining2.svg" />
              <ObtainingItemContent>Clean ecology</ObtainingItemContent>
            </ObtainingItem>
            <ObtainingItem>
              <ObtainingImage src="/obtaining3.svg" />
              <ObtainingItemContent>
                Availability of specialists for collection
              </ObtainingItemContent>
            </ObtainingItem>
          </ObtainingList>
        </Section>
        <Section>
          <ProcessTitle>
            <TitleNumber>2</TitleNumber>
            <TitleContent>Primary processing of raw materials</TitleContent>
          </ProcessTitle>
          <p>
            We elaborately adjust processing technology of each herb, berry,
            fruit or mushroom according to it special aspects. It ensures high
            concentration of biologically active substances for VitaForest's
            finished products.
          </p>
          <p>
            Primary processing is implemented at low temperatures. Raw materials
            is washed through or cleaned, dried at a temperatures below 60
            degrees Celsius or froze through the use of dry shock freezing
            method. Further, ready matter is pre-packed and gets delivered to
            the manufacturing site.
          </p>
        </Section>
      </Container>
      <SliderSection>
        <SliderContainer>
          <ProcessTitle>
            <TitleNumber>3</TitleNumber>
            <TitleContent>Production of powders</TitleContent>
          </ProcessTitle>
          <MediaQuery maxWidth={1127}>
            <Jija
              infiniteLoop={true}
              showThumbs={false}
              showIndicators={false}
              statusFormatter={(current, total) => `${current} / ${total}`}
              renderArrowPrev={(clickHandler, hasPrev, labelPrev) =>
                hasPrev && (
                  <button onClick={clickHandler} className="prevButton button">
                    <SliderButton variant={'back'} />
                  </button>
                )
              }
              renderArrowNext={(clickHandler, hasNext, labelNext) =>
                hasNext && (
                  <button onClick={clickHandler} className="nextButton button">
                    <SliderButton variant={'next'} />
                  </button>
                )
              }
            >
              <Slide>
                <SlideWrapper>
                  <SlideImageWrapper>
                    <SlideImage src="/powders1.webp" />
                    <SlideTitle>Grinding</SlideTitle>
                  </SlideImageWrapper>
                  <SlideContent>
                    Ready dry raw materials are loaded into grinder and turned
                    into required size.
                  </SlideContent>
                </SlideWrapper>
              </Slide>

              <Slide>
                <SlideWrapper>
                  <SlideImageWrapper>
                    <SlideImage src="/powders2.webp" />
                    <SlideTitle>Laboratory testing</SlideTitle>
                  </SlideImageWrapper>
                  <SlideContent>
                    Quantitative and Qualitative analyses are carried out
                    according to specifications. "Sigma- Aldrich" substance
                    standards are used during laboratory tests implementation.
                    Ready products comply with EU safety and quality standards.
                  </SlideContent>
                </SlideWrapper>
              </Slide>

              <Slide>
                <SlideWrapper>
                  <SlideImageWrapper>
                    <SlideImage src="/powders3.webp" />
                    <SlideTitle>Together we create the future</SlideTitle>
                  </SlideImageWrapper>
                  <SlideContent>
                    We pursue a common goal - to help consumers fill their lives
                    with inexhaustible energy, vigorous health and strength of
                    wild nature, without separation out of the groove of
                    everyday life and comfortable lifestyle in a modern
                    metropolis.
                  </SlideContent>
                </SlideWrapper>
              </Slide>
            </Jija>
          </MediaQuery>

          <MediaQuery minWidth={1128}>
            <Jija
              infiniteLoop={true}
              showThumbs={false}
              showIndicators={false}
              statusFormatter={(current, total) => `${current} / ${total}`}
              renderArrowPrev={(clickHandler, hasPrev, labelPrev) =>
                hasPrev && (
                  <button onClick={clickHandler} className="prevButton button">
                    <SliderButton variant={'back'} />
                  </button>
                )
              }
              renderArrowNext={(clickHandler, hasNext, labelNext) =>
                hasNext && (
                  <button onClick={clickHandler} className="nextButton button">
                    <SliderButton variant={'next'} />
                  </button>
                )
              }
            >
              <Slide>
                <SlideWrapper>
                  <SlideImageWrapper>
                    <SlideImage src="/powders1.webp" />
                  </SlideImageWrapper>
                  <SlideContent>
                    <SlideTitle>Grinding</SlideTitle>
                    <SlideDescription>
                      Ready dry raw materials are loaded into grinder and turned
                      into required size.
                    </SlideDescription>
                  </SlideContent>
                </SlideWrapper>
              </Slide>

              <Slide>
                <SlideWrapper>
                  <SlideImageWrapper>
                    <SlideImage src="/powders2.webp" />
                  </SlideImageWrapper>
                  <SlideContent>
                    <SlideTitle>Laboratory testing</SlideTitle>
                    <SlideDescription>
                      Quantitative and Qualitative analyses are carried out
                      according to specifications. "Sigma- Aldrich" substance
                      standards are used during laboratory tests implementation.
                      Ready products comply with EU safety and quality
                      standards.
                    </SlideDescription>
                  </SlideContent>
                </SlideWrapper>
              </Slide>

              <Slide>
                <SlideWrapper>
                  <SlideImageWrapper>
                    <SlideImage src="/powders3.webp" />
                  </SlideImageWrapper>
                  <SlideContent>
                    <SlideTitle>Together we create the future</SlideTitle>
                    <SlideDescription>
                      We pursue a common goal - to help consumers fill their
                      lives with inexhaustible energy, vigorous health and
                      strength of wild nature, without separation out of the
                      groove of everyday life and comfortable lifestyle in a
                      modern metropolis.
                    </SlideDescription>
                  </SlideContent>
                </SlideWrapper>
              </Slide>
            </Jija>
          </MediaQuery>
        </SliderContainer>
        <FullWidthSlider>
          <Flex>
            <FullWidthSliderTitle>Powders</FullWidthSliderTitle>
            <SliderLink to={"/en/shop/powders"}>See products</SliderLink>
          </Flex>
          <Carousel
            ssr
            itemClass="image-item"
            responsive={responsive}
            infinite={true}
            autoPlaySpeed={1000}
            showDots={false}
            swipeable={false}
            draggable={false}
            centerMode={true}
            partialVisible={false}
          >
            {!error
              ? items.map((item) => {
                if (item.category.includes(5)) {
                  return (
                    <ImageSlide>
                      <BlackSalam />
                      <ImageSlideTitle>{item.title}</ImageSlideTitle>
                      <ImageSlideThumbnail src={getFileUrl(item.img)} />
                      <ImageSlideLink to={`/product/${item.url}`}>
                        Shop now
                      </ImageSlideLink>
                    </ImageSlide>
                  );
                }
              })
              : null}
          </Carousel>
        </FullWidthSlider>
      </SliderSection>
      <ExtractSliderSection>
        <SliderContainer>
          <ExtractProcessTitle>
            <TitleNumber>4</TitleNumber>
            <TitleContent>Production of powders</TitleContent>
          </ExtractProcessTitle>
          <MediaQuery maxWidth={1127}>
            <Jija
              infiniteLoop={true}
              showThumbs={false}
              showIndicators={false}
              statusFormatter={(current, total) => `${current} / ${total}`}
              renderArrowPrev={(clickHandler, hasPrev, labelPrev) =>
                hasPrev && (
                  <button onClick={clickHandler} className="prevButton button">
                    <SliderButton variant={'back'} />
                  </button>
                )
              }
              renderArrowNext={(clickHandler, hasNext, labelNext) =>
                hasNext && (
                  <button onClick={clickHandler} className="nextButton button">
                    <SliderButton variant={'next'} />
                  </button>
                )
              }
            >
              <ExtractSlide>
                <SlideWrapper>
                  <SlideImageWrapper>
                    <SlideImage src="/extracts1.webp" />
                    <SlideTitle>Grinding</SlideTitle>
                  </SlideImageWrapper>
                  <SlideContent>
                    Ready dry raw materials are loaded into grinder and turned
                    into required size.
                  </SlideContent>
                </SlideWrapper>
              </ExtractSlide>

              <ExtractSlide>
                <SlideWrapper>
                  <SlideImageWrapper>
                    <SlideImage src="/extracts2.webp" />
                    <SlideTitle>Extraction</SlideTitle>
                  </SlideImageWrapper>
                  <SlideContent>
                    To achieve highest possible quantity of useful substances
                    every extract is going through individually adjusted,
                    scientifically justified and laboratory tested production.
                  </SlideContent>
                </SlideWrapper>
              </ExtractSlide>

              <ExtractSlide>
                <SlideWrapper>
                  <SlideImageWrapper>
                    <SlideImage src="/extracts3.webp" />
                    <SlideTitle>Filtration</SlideTitle>
                  </SlideImageWrapper>
                  <SlideContent>
                    After the extraction process, the resulting raw material is
                    thoroughly filtered.
                  </SlideContent>
                </SlideWrapper>
              </ExtractSlide>

              <ExtractSlide>
                <SlideWrapper>
                  <SlideImageWrapper>
                    <SlideImage src="/extracts4.webp" />
                    <SlideTitle>Drying</SlideTitle>
                  </SlideImageWrapper>
                  <SlideContent>
                    Under vacuum drying, matter reforms into dry leaf and gets
                    grinded. Under spray dehydration extract is loaded into tank
                    with circulating warm air where particles instantly dry up
                    and reform into powder.
                  </SlideContent>
                </SlideWrapper>
              </ExtractSlide>

              <ExtractSlide>
                <SlideWrapper>
                  <SlideImageWrapper>
                    <SlideImage src="/extracts5.webp" />
                    <SlideTitle>Laboratory testing</SlideTitle>
                  </SlideImageWrapper>
                  <SlideContent>
                    Quantitative and Qualitative analyses of matter and ready
                    extracts are carried out according to specifications.
                    Sigma-Aldrich matter standards are used during laboratory
                    tests implementation. Ready products comply with EU safety
                    and quality standards.
                  </SlideContent>
                </SlideWrapper>
              </ExtractSlide>

              <ExtractSlide>
                <SlideWrapper>
                  <SlideImageWrapper>
                    <SlideImage src="/extracts6.webp" />
                    <SlideTitle>Packaging</SlideTitle>
                  </SlideImageWrapper>
                  <SlideContent>
                    Extracts are packed into double bags to avoid moisture
                    condensation. During shipment bags are stored into
                    double-double carton boxes.
                  </SlideContent>
                </SlideWrapper>
              </ExtractSlide>
            </Jija>
          </MediaQuery>

          <MediaQuery minWidth={1128}>
            <Jija
              infiniteLoop={true}
              showThumbs={false}
              showIndicators={false}
              statusFormatter={(current, total) => `${current} / ${total}`}
              renderArrowPrev={(clickHandler, hasPrev, labelPrev) =>
                hasPrev && (
                  <button onClick={clickHandler} className="prevButton button">
                    <SliderButton variant={'back'} />
                  </button>
                )
              }
              renderArrowNext={(clickHandler, hasNext, labelNext) =>
                hasNext && (
                  <button onClick={clickHandler} className="nextButton button">
                    <SliderButton variant={'next'} />
                  </button>
                )
              }
            >
              <ExtractSlide>
                <SlideWrapper>
                  <SlideImageWrapper>
                    <SlideImage src="/extracts1.webp" />
                  </SlideImageWrapper>
                  <SlideContent>
                    <SlideTitle>Grinding</SlideTitle>
                    <SlideDescription>
                      Ready dry raw materials are loaded into grinder and turned
                      into required size.
                    </SlideDescription>
                  </SlideContent>
                </SlideWrapper>
              </ExtractSlide>

              <ExtractSlide>
                <SlideWrapper>
                  <SlideImageWrapper>
                    <SlideImage src="/extracts2.webp" />
                  </SlideImageWrapper>
                  <SlideContent>
                    <SlideTitle>Extraction</SlideTitle>
                    <SlideDescription>
                      To achieve highest possible quantity of useful substances
                      every extract is going through individually adjusted,
                      scientifically justified and laboratory tested production.
                    </SlideDescription>
                  </SlideContent>
                </SlideWrapper>
              </ExtractSlide>

              <ExtractSlide>
                <SlideWrapper>
                  <SlideImageWrapper>
                    <SlideImage src="/extracts3.webp" />
                  </SlideImageWrapper>
                  <SlideContent>
                    <SlideTitle>Filtration</SlideTitle>
                    <SlideDescription>
                      After the extraction process, the resulting raw material
                      is thoroughly filtered.
                    </SlideDescription>
                  </SlideContent>
                </SlideWrapper>
              </ExtractSlide>

              <ExtractSlide>
                <SlideWrapper>
                  <SlideImageWrapper>
                    <SlideImage src="/extracts4.webp" />
                  </SlideImageWrapper>
                  <SlideContent>
                    <SlideTitle>Drying</SlideTitle>
                    <SlideDescription>
                      Under vacuum drying, matter reforms into dry leaf and gets
                      grinded. Under spray dehydration extract is loaded into
                      tank with circulating warm air where particles instantly
                      dry up and reform into powder.
                    </SlideDescription>
                  </SlideContent>
                </SlideWrapper>
              </ExtractSlide>

              <ExtractSlide>
                <SlideWrapper>
                  <SlideImageWrapper>
                    <SlideImage src="/extracts5.webp" />
                  </SlideImageWrapper>
                  <SlideContent>
                    <SlideTitle>Laboratory testing</SlideTitle>
                    <SlideDescription>
                      Quantitative and Qualitative analyses of matter and ready
                      extracts are carried out according to specifications.
                      Sigma-Aldrich matter standards are used during laboratory
                      tests implementation. Ready products comply with EU safety
                      and quality standards.
                    </SlideDescription>
                  </SlideContent>
                </SlideWrapper>
              </ExtractSlide>

              <ExtractSlide>
                <SlideWrapper>
                  <SlideImageWrapper>
                    <SlideImage src="/extracts6.webp" />
                  </SlideImageWrapper>
                  <SlideContent>
                    <SlideTitle>Packaging</SlideTitle>
                    <SlideDescription>
                      Extracts are packed into double bags to avoid moisture
                      condensation. During shipment bags are stored into
                      double-double carton boxes.
                    </SlideDescription>
                  </SlideContent>
                </SlideWrapper>
              </ExtractSlide>
            </Jija>
          </MediaQuery>
        </SliderContainer>
        <FullWidthSlider>
          <Flex>
            <FullWidthSliderTitle>Extracts</FullWidthSliderTitle>
            <SliderLink to={"/en/shop/extracts"}>See products</SliderLink>
          </Flex>
          <Carousel
            ssr
            itemClass="image-item"
            responsive={responsive}
            infinite={true}
            autoPlaySpeed={1000}
            showDots={false}
            swipeable={false}
            draggable={false}
            centerMode={true}
            partialVisible={false}
          >
            {!error
              ? items.map((item) => {
                if (item.category.includes(1)) {
                  return (
                    <ImageSlide>
                      <BlackSalam />
                      <ImageSlideTitle>{item.title}</ImageSlideTitle>
                      <ImageSlideThumbnail src={getFileUrl(item.img)} />
                      <ImageSlideLink to={`/product/${item.url}`}>
                        Shop now
                      </ImageSlideLink>
                    </ImageSlide>
                  );
                }
              })
              : null}
          </Carousel>
        </FullWidthSlider>
      </ExtractSliderSection>
      <Container>
        <SingleSection>
          <ProcessTitle>
            <TitleNumber>5</TitleNumber>
            <TitleContent>Storing</TitleContent>
          </ProcessTitle>
          <p>
            Ready extracts and plant materials are stored at our warehouse in
            Tallin, Estonia. Warehouse complies with EU safety and quality
            standards. Thick bags encased in strong carton boxes are used as
            packages.
          </p>
        </SingleSection>
        <SingleSection>
          <ProcessTitle>
            <TitleNumber>6</TitleNumber>
            <TitleContent>Logistics</TitleContent>
          </ProcessTitle>
          <p>
            We collaborate with the logistics company Europiir Logistics for
            more than 3 years already, whos competence helps our customers to
            save on shipping costs without sacrificing quality. At your request,
            we will send the available goods to you by any convenient way from
            Tallinn, within 2 days after making the payment. The average
            cumulative production and delivery time for custom-made goods are
            35-40 days. Each shipment of goods comes with complete documentation
            set.
          </p>
        </SingleSection>
      </Container>
    </Page>
  );
}
