// Other routes
export const LOGIN_ROUTE = "/login";
export const REGISTRATION_ROUTE = "/registration";
export const SHOP_ROUTE = "/en/shop/";
export const SHOP_PARAMS_ROUTE = "/en/shop/:param";
export const BASKET_ROUTE = "/cart";
export const PRODUCT_ROUTE = "/en/product";
export const HOME_ROUTE = "/";
export const HOME_ROUTE_EN = "/en";
export const UI_KIT = "/en/ui-kit";
export const MY_ACCOUNT = "/account";
export const MY_ACCOUNT_ORDER = "/account/orders";
export const MY_ACCOUNT_TICKETS = "/account/tickets";
export const MY_ACCOUNT_NEW_TICKET_PARAMS = "/account/new-ticket/:params";
export const MY_ACCOUNT_NEW_TICKET = "/account/new-ticket";
export const MY_ACCOUNT_VIEW_TICKET = "/account/ticket/:id";
export const MY_ACCOUNT_VIEW_ORDER = "/account/orders/:id";
export const MY_ACCOUNT_ACCOUNT_DETAILS = "/account/details";
export const MY_ACCOUNT_VIEW_ADDRESSES = "/account/addresses";
export const MY_ACCOUNT_EDIT_ADDRESS = "/account/addresses/edit/:id";
export const MY_ACCOUNT_ADD_ADDRESS = "/account/addresses/add";
export const MY_ACCOUNT_OFFERS = "/account/offers";
export const MY_ACCOUNT_PRIVACY = "/account/privacy";
export const MY_ACCOUNT_NOTIFICATIONS = "/account/notifications";
export const CHECOUT_ROUTE = "/checkout";
export const BLOG_ROUTE = "/en/blog";
export const BLOG_PAGE_ROUTE = "/en/blog/:url";
export const WIKI_SINGLE_PAGE = "/en/wiki/:url";
export const MY_ACCOUNT_WISHLIST = "/account/wishlist";
export const MY_ACCOUNT_NEW_WISHLIST = "/account/wishlist/create";
export const MY_ACCOUNT_EDIT_WISHLIST = "/account/wishlist/:id";
export const WIKI_ROUTE = "/en/wiki";
export const SEARCH_ROUTE = "/search/:url";
export const REQUEST_ROUTE = "/request";
export const PRODUCTION_PAGE = "/en/production";
export const CONTACTS_PAGE = "/en/contact-us";
export const ABOUT_PAGE = "/en/about-us";
export const RHODIOLA_PROMO_PAGE = "/en/rhodiola-promo";
export const CHAGA_PROMO_PAGE = "/en/chaga-promo";
export const CHAGA_PROMO_PAGE_PARAMS = "/en/chaga-promo/:params";
export const FAQ_ROUTE = "/en/faq";
export const PRIVACY_ROUTER = "/en/privacy-policy";
export const COOKIE_POLICY = "/en/cookie-policy";
export const TERMS_ROUTE = "/en/terms-and-conditions";
export const TAXIFOLIN_PROMO_PARAMS = "/en/taxifolin-promo/:params";
export const TAXIFOLIN_PROMO = "/en/taxifolin-promo";
export const DEV_PAGE = "/dev";
export const RESTORE_PASSWORD = "/account/reset/:otl";
// Admin panel
export const ADMIN_SEE_PRODUCTS = "/admin/products/";
export const ADMIN_ADD_PRODUCT = "/admin/products/add";
export const ADMIN_ROUTE = "/admin";
export const ADMIN_EDIT_PRODUCT = "/admin/products/edit/:id";
export const ADMIN_ADD_POST = "/admin/posts/add";
export const ADMIN_EDIT_POST = "/admin/posts/edit/:id";
export const ADMIN_SEE_POSTS = "/admin/posts";
export const ADMIN_SEE_USERS = "/admin/users";
export const ADMIN_ADD_PRODUCT_CATEGORY = "/admin/products/categories/";
export const ADMIN_ADD_POST_CATEGORY = "/admin/posts/categories/";
export const ADMIN_EDIT_USER = "/admin/users/edit/:id";
export const ADMIN_ADD_USER = "/admin/users/add";
export const ADMIN_SEE_WIKI = "/admin/wiki";
export const ADMIN_ADD_WIKI = "/admin/wiki/add";
export const ADMIN_EDIT_WIKI = "/admin/wiki/edit/:id";
export const ADMIN_SEE_ATTRIBUTES = "/admin/attributes";
export const ADMIN_SEE_ATTRIBUTE_TERMS = "/admin/attributes/terms/:id";
export const ADMIN_SEE_TICKETS = "/admin/tickets";
export const ADMIN_SEE_TICKET = "/admin/tickets/:id";
export const ADMIN_SEE_ROLES = "/admin/users/roles";
export const ADMIN_SEE_ORDERS = "/admin/orders";
export const ADMIN_SEE_SINGLEORDER = "/admin/order/:id";
export const ADMIN_SEE_REQUESTS = "/admin/requests";
export const ADMIN_SEE_REQUEST = "/admin/request/:id";
export const ADMIN_SEE_FORMS = "/admin/forms";
export const ADMIN_APPROVE_COMMENTS = "/admin/posts/comments-approve";
export const ADMIN_PRODUCT_URL_ADD = "/admin/products/urls/add";
export const ADMIN_PRODUCT_URLS = "/admin/products/urls";
export const ADMIN_ADD_OFFER = "/admin/offers/add";
export const ADMIN_EDIT_OFFER = "/admin/offers/edit/:id";
export const ADMIN_OFFERS_LIST = "/admin/offers";
export const ADMIN_COMMENTS_LIST = "/admin/posts/comments-list";
export const ADMIN_EDIT_PRODUCT_DRAFT = "/admin/products/draft/:id";
export const ADMIN_PRODUCT_DRAFT_LIST = "/admin/products/drafts";
export const ADMIN_EDIT_WIKI_DRAFT = "/admin/wiki/draft/:id";
export const ADMIN_WIKI_DRAFT_LIST = "/admin/wiki/drafts";
export const ADMIN_EDIT_POST_DRAFT = "/admin/post/draft/:id";
export const ADMIN_POST_DRAFT_LIST = "/admin/post/drafts";
export const ADMIN_EDIT_HEADER_PROMO = "/admin/customize/header";
export const ADMIN_CREATE_WYSIWYG_PAGE = "/admin/pages/create/wysiwyg";
export const ADMIN_CREATE_HTML_PAGE = "/admin/pages/create/html";
export const ADMIN_PAGES_LIST = "/admin/pages/";
export const ADMIN_PAGES_DRAFTS_LIST = "/admin/pages/drafts";
export const ADMIN_EDIT_DRAFT_PAGE = "/admin/pages/draft/:id";
export const ADMIN_EDIT_PAGE = "/admin/pages/edit/:id";
export const ADMIN_EXPORT_PAGE = "/admin/exports";
