import React, { useContext } from "react";
import styled from "styled-components";
import Attributes from "./subcomponents/Attributes";
import { Context } from "../../../..";
import MainSection from "./subcomponents/MainSection";
import Tabs from "./subcomponents/Tabs";
const Container = styled.div`
  margin: 0px 18px;
`;

const Mobile = ({ product, gallery }) => {
  const { user } = useContext(Context);
  return (
    <Container>
      <MainSection
        id={product.id}
        sku={product.sku}
        title={product.title}
        moq={product.moq}
        price={product.price}
        gallery={gallery}
        status={product.status}
        stock={product.stock}
        step={product.qty_step}
        orderStatus={product.status}
        attributeId={product.attributeId ? product.attributeId : []}
        prices={product.prices ? product.prices : false}
        url={product.url}
      />
      {user.isAuth ? (
        <Attributes attributeId={product.attributeId ? product.attributeId : []} />
      ) : (
        <></>
      )}
      <Tabs
        documents={product.documents ? JSON.parse(product.documents) : []}
        description={product.description}
      />
    </Container>
  );
}

export default Mobile