import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { fetchRequest } from "../../../../api/ordersAPI";
import AdminUI from "../../Ui/AdminUI";
import { Row, Col, Table, Button, Form } from "react-bootstrap";
import { Context } from "../../../..";

const FlexTitle = styled.div`
display: flex;
margin-bottom: 20px;
`;

const PageTitle = styled.h1`
margin: 0px;
margin-right: 20px;
`

const RequestPage = () => {
    // imports
    const { id } = useParams();
    const { user } = useContext(Context);
    // states
    const [request, setRequest] = useState(null);
    const [items, setItems] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);

    // fetch order
    useEffect(() => {
        // fetching order details
        fetchRequest(id).then((data) => {
            setRequest(data);
            setItems(JSON.parse(data.items));
            setIsLoaded(true);
        }, (error) => {
            setIsLoaded(false);
            setError(error)
        })
    }, [])
    if (!isLoaded) {
        return (
            <div>Loading...</div>
        )
    }
    else {
        return (
            <AdminUI>
                <FlexTitle>
                    <PageTitle>Request #{request.id}</PageTitle>
                </FlexTitle>
                <Row>
                    <Col xs={8}>
                        <Table striped bordered hover variant="dark">
                            <thead>
                                <tr>
                                    <th>
                                        #
                                    </th>
                                    <th>
                                        Title
                                    </th>
                                    <th>
                                        Qty
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map((item) => (
                                    <tr>
                                        <td>{item.id ? item.id : null}</td>
                                        <td>{item.title}</td>
                                        <td><Form.Control
                                            value={item.qty}
                                            placeholder="Qty"
                                        /></td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                    <Col xs={4}>
                        <Table striped bordered hover variant="dark">
                            <thead>
                                <tr>
                                    <th>Client information</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Company name</td>
                                    <td>{request.company_name}</td>
                                </tr>
                                <tr>
                                    <td>Contact name</td>
                                    <td>{request.contact_name}</td>
                                </tr>
                                <tr>
                                    <td>VAT</td>
                                    <td>{request.vat}</td>
                                </tr>
                                <tr>
                                    <td>Registry code</td>
                                    <td>{request.registry_code}</td>
                                </tr>
                                <tr>
                                    <td>Phone</td>
                                    <td>{request.phone}</td>
                                </tr>
                                <tr>
                                    <td>Email</td>
                                    <td>{request.email}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </AdminUI>
        )
    }
}

export default RequestPage