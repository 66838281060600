import { useEffect } from "react";
import { $authHost, $host } from ".";

export const fileFetchImages = async (page, limit) => {
    try {
        const { data } = await $authHost.get('api/files/images', {
            params: {
                page, limit
            }
        });
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const fileFetchDocs = async (page, limit) => {
    try {
        const { data } = await $authHost.get('api/files/docs', {
            params: {
                page, limit
            }
        });
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const fileUpdate = async (body) => {
    try {
        const { data } = await $authHost.post('api/files/update', body);
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const fileRemove = async (id, name) => {
    try {
        const { data } = await $authHost.post(`api/files/remove/${id}`);
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const fileUpload = async (body) => {
    try {
        const { data } = await $authHost.post('api/files', body);
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const getFilesCollection = async (collection) => {
    try {
        if (typeof collection == 'string') {
            collection = JSON.parse(collection);
        }
        let formdata = new FormData();
        formdata.append('filesCollection', JSON.stringify(collection));
        const { data } = await $authHost.post('api/files/get', formdata);
        return data;
    } catch (e) {
        return e.response.data;
    }
}