import React, { useEffect, useState } from "react";
import { Button, Row, Col, Card, Table } from "react-bootstrap";
import Select from 'react-select';
import styled from "styled-components";
import { fetchAttributes } from "../../../api/attributeAPI";
import LoadingPlaceholder from "../../Functional/LoadingPlaceholder";
import { compareAttributesTableArray, getSelectCollection, getTermsList } from "./AttributesManagerAPI";
import DefaultButton from "../../VitaforestUI/Interface/Buttons/DefaultButton";


// Styled components

const ManagerContainer = styled.div`
`

const TermBadge = styled.div`
background: #F3F7F2;
display: flex;
padding: 2px 5px;
border-radius: 5px;
`
const TermRemove = styled.button`
border: none;
background: rgba(216, 216, 216, 0.3);
color: red;
margin-left: 5px;
border-radius: 5px;
`

const CustomTermTd = styled.td`
display: flex;
gap: 5px;
flex-wrap: wrap;
`

const AttributesManager = ({ selected, setSelected, mode, className }) => {

    // States (data)

    const [attributes, setAttributes] = useState([]);
    const [attributesCollection, setAttributesCollection] = useState([]);
    const [termsCollection, setTermsCollection] = useState([]);
    const [selectedAttribute, setSelectedAttribute] = useState(null);
    const [selectedTerm, setSelectedTerm] = useState(null);
    const [tableArray, setTableArray] = useState([]);
    const [termsList, setTermsList] = useState([]);

    // States (loading)
    const [loaded, setLoaded] = useState(false);
    const [needReload, setNeedReload] = useState(false);

    // Funcs


    // Changing value of selected attribute
    const selectAttribute = (change) => {
        setSelectedAttribute(change.value)
    }
    // Changing value of selected attribute term
    const selectTerm = (change) => {
        setSelectedTerm(change.value)
    }

    // Return selected to select array
    const addAttribute = (id) => {
        let item = selected.indexOf(id);
        if (item >= 0) {
            let prev = selected;
            prev.splice(item, 1);
            setSelected(prev);
        }
        else {
            let prev = selected;
            prev.push(id);
            setSelected(prev);
        }
        setNeedReload(!needReload)
    }

    // Data load

    // Set terms list when selected attribute id changing
    useEffect(() => {
        let array = [];
        attributes.map((item) => {
            if (item.id == selectedAttribute) {
                item.terms.map((term) => {
                    array.push({ value: term.id, label: term.name })
                })
            }
        })
        setTermsCollection(array);
    }, [selectedAttribute])

    // Recalculate selected attributes table

    useEffect(() => {
        setTableArray(compareAttributesTableArray(selected, attributes));
    }, [selected, needReload])

    // Load attributes
    useEffect(() => {
        fetchAttributes().then((data) => {
            setAttributes(data);
            setTermsList(getTermsList(data));
            setAttributesCollection(getSelectCollection(data));
            setNeedReload(!needReload);
            setLoaded(true);
        })
    }, [])

    if (!loaded) {
        return (
            <ManagerContainer>
                <Card>
                    <Card.Header>Attributes manager</Card.Header>
                    <LoadingPlaceholder />
                </Card>
            </ManagerContainer>
        )
    }
    else if (loaded && mode == 'post') {
        return (
            <ManagerContainer className={className}>
                <Card>
                    <Card.Header>Attributes manager</Card.Header>
                    <Card.Body>
                        <Row>
                            <Table>
                                <thead>
                                    <th>Selected terms</th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <CustomTermTd>
                                            {tableArray.map((item) => {
                                                return (
                                                    item.termName.map((term) => {
                                                        return (
                                                            <TermBadge>
                                                                {term.split(':')[0]}
                                                                <TermRemove onClick={() => { addAttribute(Number(term.split(':')[1])) }}>X</TermRemove>
                                                            </TermBadge>
                                                        )
                                                    })
                                                )
                                            })}
                                        </CustomTermTd>
                                    </tr>
                                </tbody>
                            </Table>
                        </Row>
                        <Row>
                            <Col>
                                <Select options={termsList} onChange={selectTerm} />
                            </Col>
                            <Col>
                                <Button onClick={() => { addAttribute(selectedTerm) }}>Add</Button>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </ManagerContainer>
        )
    }
    else {
        return (<ManagerContainer>
            <Card>
                <Card.Header>Attributes manager</Card.Header>
                <Card.Body>
                    <Row>
                        <Table>
                            <thead>
                                <th>
                                    Attribute
                                </th>
                                <th>Selected terms</th>
                            </thead>
                            <tbody>
                                {tableArray.map((item) => {
                                    return (
                                        <tr>
                                            <td>{item.attrName}</td>
                                            <CustomTermTd>{item.termName.map((term) => {
                                                return (
                                                    <TermBadge>
                                                        {term.split(':')[0]}
                                                        <TermRemove onClick={(e) => { e.preventDefault(); addAttribute(Number(term.split(':')[1])) }}>X</TermRemove>
                                                    </TermBadge>
                                                )
                                            })}</CustomTermTd>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </Row>
                    <Row>
                        <Col>
                            <Select options={attributesCollection} onChange={selectAttribute} />
                        </Col>
                        <Col>
                            <Select options={termsCollection} onChange={selectTerm} />
                        </Col>
                        <Col>
                            <Button onClick={() => { addAttribute(selectedTerm) }}>Add</Button>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </ManagerContainer>)
    }
}

export default AttributesManager