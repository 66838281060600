import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MediaQuery from "react-responsive";
import Mobile from "./Mobile";
import Desktop from "./Desktop";
import { useParams } from "react-router-dom";
import { fetchOneProduct, fetchProductByUrl } from "../../../../api/productAPI";
import { Helmet } from "react-helmet";
import LoadingPlaceholder from "../../../Functional/LoadingPlaceholder";

const ProductPage = () => {
  const { url } = useParams();

  const [product, setProduct] = useState(null);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [gallery, setGallery] = useState([]);

  useEffect(() => {
    fetchProductByUrl(url).then((data) => {
      if (data.img) {
        gallery.push(data.img)
      }
      let gallery_items = JSON.parse(data.gallery);
      if (gallery_items.length > 0) {
        gallery_items.map((item) => {
          gallery.push(item);
        })
      }
      setProduct(data);
      setIsLoaded(true);
    })
  }, [])
  if (!isLoaded) {
    return (
      <>
        <LoadingPlaceholder />
      </>
    )
  }
  else {
    return (
      <>
        <Helmet>
          <title>{product.seo_title}</title>
          <meta name="description" content={product.seo_description} />
          <meta name="keywords" content={product.seo_keywords} />
        </Helmet>
        <MediaQuery maxWidth={1127}>
          <Mobile product={product} gallery={gallery} />
        </MediaQuery>

        <MediaQuery minWidth={1128}>
          <Desktop product={product} gallery={gallery} />
        </MediaQuery>
      </>
    );
  }
}

export default ProductPage
