import { useParams } from "react-router-dom";
import { Context } from "..";
import { Editor, EditorState } from "draft-js";
import { decodeEditorBody } from "../api/postAPI";

const { useEffect, useState, useContext } = require("react");
const { getPageContentByUrl } = require("../api/pagesAPI");
const { default: NotFound } = require("./NotFound");
const { default: Page } = require("../components/VitaforestUI/Interface/Page/Page");
const { default: LoadingPlaceholder } = require("../components/Functional/LoadingPlaceholder");
const { default: styled, css } = require("styled-components");


const DefaultPage = () => {

    const { url } = useParams();
    const { user } = useContext(Context);
    const [pageData, setPageData] = useState([]);
    const [error, setError] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    console.log(url)

    useEffect(() => {
        getPageContentByUrl(url).then((data) => {
            if (data == null) {
                setError('not_found');
            }
            else if (data.id) {
                setPageData(data);
                setIsLoaded(true);
            }
        })
    }, [])

    const DynamicStyledComponent = styled.div`
        ${props => props.dynamicStyles && css`${props.dynamicStyles}`}
    `;

    if (error == 'not_found') {
        return (
            <NotFound />
        )
    }
    else if (!isLoaded && error == false) {
        return (
            <Page variant={'centred'}>
                <LoadingPlaceholder />
            </Page>
        )
    }
    else {
        if (pageData.pageType == 'standwith') {
            return (
                <Page variant={'centred'} seo_title={pageData.seo_title} seo_description={pageData.seo_description} seo_keywords={pageData.seo_keywords}>
                    <DynamicStyledComponent dynamicStyles={pageData.html_styles} dangerouslySetInnerHTML={{ __html: pageData.html_body }} />
                </Page>
            )
        }
        else if (pageData.pageType == 'wysiwyg') {
            return (
                <Page variant={'centred'} seo_title={pageData.seo_title} seo_description={pageData.seo_description} seo_keywords={pageData.seo_keywords}>
                    <Editor editorState={EditorState.createWithContent(decodeEditorBody(pageData.body))} readOnly={true} />
                </Page>
            )
        }
        else {
            return (
                <></>
            )
        }
    }

}

export default DefaultPage