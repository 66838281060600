import React, { useContext, useState } from "react";
import { doPasswordChange, login } from "../api/userAPI";
import { Form } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import { Context } from "../index";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import DefaultButton from "../components/VitaforestUI/Interface/Buttons/DefaultButton";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Header from "../components/VitaforestUI/Interface/Header/Header";
import Footer from "../components/VitaforestUI/Interface/Footer/Footer";
import MediaQuery from "react-responsive";
import Input from "../components/VitaforestUI/Interface/Input/Input";
import Textarea from "../components/VitaforestUI/Interface/Textarea/Textarea";
import { sendForm } from "../api/formsAPI";
import { Helmet } from "react-helmet";

const PageContainer = styled.div`
  font-family: "Proxima Nova";
  font-style: normal;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  @media screen and (min-width: 1128px) {
    height: 100vh;
    flex-direction: row;
    justify-content: flex-start;
  }
`;

const Heading = styled.h2`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 17px;
  color: #303236;
  padding-bottom: 8px;
  border-bottom: 1px solid #303236;
  margin-top: 25px;
`;

const ButtonSubmit = styled(DefaultButton)`
  max-width: 280px;
  margin: 15px auto;
  color: #ffffff;
  background-color: #3c3f54;
  &:hover,
  &:active,
  &:focus {
    background-color: #242638;
  }
  @media screen and (min-width: 1128px) {
    min-width: 100%;
  }
`;

const LoginForm = styled.div`
  margin: 18px;
  margin-bottom: auto;
  @media screen and (min-width: 1128px) {
    width: 43vw;
    box-sizing: border-box;
    padding: 40px 86px;
  }
`;

const Text = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-top: 15px;
  color: #303236;
  margin-bottom: 0;
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 40px 42px 22px 76px;
  background-image: url("/regbg.webp");
  width: 57vw;
  background-size: cover;
  .logo {
    width: 191px;
    height: 49px;
  }
`;

const RegText = styled(Text)`
  margin-bottom: 30px;
`;
const BackButton = styled.button`
  padding: 12px 24px 12px 42px;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  border: 2px solid #3c3f54;
  color: #3c3f54;
  background-color: #ffffff;
  border-radius: 4px;
  position: relative;
  display: block;
  margin-left: auto;
  margin-bottom: 20px;
  &:before {
    content: url("/arrow-reg.svg");
    position: absolute;
    left: 19px;
    top: 12px;
  }
`;

const Socials = styled.div`
  margin-top: auto;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  img {
    padding-right: 10px;
  }
`;

const Copyright = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #eaebec;
  justify-content: space-between;
  margin-top: auto;
`;

const Privacy = styled(Link)`
  text-align: right;
  text-decoration-line: underline;
  color: inherit;
`;

const StylesLayer = styled(PageContainer)`
  .form-control:focus {
    border: 1px solid #bbbbbb;
    box-shadow: none;
  }
`;

const Flex = styled.div`
  display: flex;
  gap: 25px;
  margin-top: 10px;
`;

const FormDiv = styled.div`
  width: 100%;
`;

const LogoLink = styled(Link)``;

const RestorePassword = observer(() => {
  const { otl } = useParams();
  const [newPass, setNewPass] = useState('');
  const [repeatPass, setRepeatPass] = useState('');
  const navigate = useNavigate();

  const send = () => {
    if (repeatPass != newPass) {
      NotificationManager.warning('Passwords not match', 'Warning')
    }
    else {
      doPasswordChange(otl, newPass).then((data) => {
        if (data.status == 'not_valid_otl') {
          NotificationManager.error('Link is not valid', 'Error');
        }
        else if (data.status == 'password_changed') {
          NotificationManager.success('Password successfully changed, you can login', 'Success');
          setTimeout(() => {
            navigate('/login');
          }, 1000)
        }
        else {
          NotificationManager.error(data, 'Error');
        }
      })
    }
  }

  return (
    <StylesLayer>
      <Helmet>
        <title>Password restore - Vitaforest</title>
        <meta name="description" content={'Vitaforest website login page'} />
      </Helmet>
      <NotificationContainer />
      <MediaQuery minWidth={1128}>
        <LeftColumn>
          <LogoLink to={"/en"}>
            <img className="logo" src="/LogoLight.svg" />
          </LogoLink>

          <Copyright>
            <span> © VitaForest 2023</span>
          </Copyright>
        </LeftColumn>
      </MediaQuery>
      <LoginForm>
        <Heading>Restore password</Heading>
        <RegText>
          Enter new password
        </RegText>
        <NotificationContainer />
        <Input label="Password" type="text" placeholder="********" required onChange={(e) => { setNewPass(e.target.value); console.log(e.target.value) }} />
        <Input label="Repeat password" type="text" placeholder="********" required onChange={(e) => { setRepeatPass(e.target.value); console.log(e.target.value) }} />
        <ButtonSubmit type="submit" title="Save" onClick={() => {
          send();
        }} />
      </LoginForm>
    </StylesLayer>
  );
});

export default RestorePassword;
