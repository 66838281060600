import React from "react";
import styled from "styled-components";
const StatusWrapper = styled.p`
  margin-bottom: 0px;
  position: absolute;
  top: -5px;
  left: 0px;
  margin-top: 0px;
`;
const UnderDevelopment = styled.span`
  font-family: "Proxima Nova";
  padding: 3px 6px;
  margin: 0px;
  background: #18a0fb;
  border-radius: ${(props) =>
    props.type == "desktop-cart" ? "4px" : "4px 0px 4px 0px"};
  color: white;
  font-weight: 600;
  font-size: 10px;
`;

const Avaliable = styled(UnderDevelopment)`
  background: #40bf6a;
`;

const UponRequest = styled(UnderDevelopment)`
  background: #EF5E53;
`;

const Soon = styled(UnderDevelopment)`
  background: #18a0fb;
`;

const Status = ({ variant }) => {
  return (
    <StatusWrapper>
      {variant == "under-development" ? (
        <UnderDevelopment>Under development</UnderDevelopment>
      ) : variant == "Avaliable" ? (
        <Avaliable>Avaliable</Avaliable>
      ) : variant == "under-development" ? (
        <UponRequest>Upon request</UponRequest>
      ) : variant == "Soon" ? (
        <Soon>Soon</Soon>
      ) : (
        <UponRequest>Unset</UponRequest>
      )}
    </StatusWrapper>
  );
};

export default Status;
