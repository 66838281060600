import React from "react";
import styled from "styled-components";

const Image = styled.img`
  width: calc(100vw - 100px);
  height: calc(100vh - 100px);
  position: absolute;
  left: 50px;
  top: 50px;
  z-index: 50;
  overflow: hidden;
  object-fit: cover;
  @media screen and (min-width: 1128px) {
    width: 600px;
    height: 440px;
    left: calc(50vw - 300px);
    top: calc(50vh - 220px);
  }
`;

const ImageContainer = styled.div`
  overflow: hidden;
  height: 100%;
`;

const ImageButton = styled.button`
  background-color: transparent;
  border: none;
  position: absolute;
  right: calc(50vw - 300px);
  top: calc(50vh - 200px);
  z-index: 51;
`;
export default function ImagePreview({ image, onClick }) {
  return (
    <ImageContainer>
      <Image src={image} />
      <ImageButton onClick={() => onClick()}>
        <img src="/close.svg" />
      </ImageButton>
    </ImageContainer>
  );
}
