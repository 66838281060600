import { $authHost, $host } from "./index";

export const addToWishlist = async (itemId, wishlistId) => {
  try {
    const { data } = await $authHost.post("api/wishlist/add", {
      itemId,
      wishlistId,
    });
    return data;
  } catch (e) {
    return e.response.data;
  }
};

export const removeFromWishlist = async (itemId, wishlistId) => {
  try {
    const { data } = await $authHost.post("api/wishlist/remove", {
      itemId,
      wishlistId
    });
    return data;
  } catch (e) {
    return e.response.data;
  }
};

export const getAllWishlists = async (userId) => {
  try {
    const { data } = await $authHost.get(`api/wishlist/${userId}`);
    return data;
  } catch (e) {
    return e.response.data;
  }
};

export const createNewWishlist = async (name, items, user) => {
  try {
    const userId = user.user.id;
    const { data } = await $authHost.post(`api/wishlist/create`, {
      name,
      items,
      userId,
    });
    return data;
  } catch (e) {
    return e.response.data;
  }
};

export const updateWishlist = async (name, items, wishlistId) => {
  try {
    const { data } = await $authHost.post(`api/wishlist/update`, {
      name,
      items,
      wishlistId,
    });
    return data;
  } catch (e) {
    return e.response.data;
  }
};

export const convertWishlistToBasket = async (userId, wishlistId) => {
  try {
    const { data } = await $authHost.post("api/wishlist/w2b", {
      userId,
      wishlistId,
    });
    return data;
  } catch (e) {
    return e.response.data;
  }
};

export const removeWishlist = async (id) => {
  try {
    const { data } = await $authHost.post("api/wishlist/remove-list", {
      id,
    });
    return data;
  } catch (e) {
    return e.response.data;
  }
};
