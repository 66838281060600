import React, { useEffect, useContext } from "react";
import AdminUI from "../../Ui/AdminUI";
import { Editor } from 'react-draft-wysiwyg';
import { useState } from "react";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Card, Form, Button, Row, Col, CloseButton, Table, Container } from "react-bootstrap";
import styled from "styled-components";
import { encodeEditorBody } from "../../../../api/postAPI";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { Context } from "../../../..";
import { useNavigate } from "react-router-dom";
import { getFileUrl } from "../../../../api/productAPI";
import { createWiki } from "../../../../api/wikiAPI";
import FMAddImage from "../../FileManager/FMAddImage";
import { fetchAttributes } from "../../../../api/attributeAPI";
import Select from 'react-select';
import { createWikiDraft } from "../../../../api/draftAPI";

// Стилизованные компоненты
const FlexBox = styled.div`
display: flex;
justify-content: space-between;
`;

const CatsViewer = styled.ul`
`

const PreviewFlex = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
`;

const Preview = styled.img`
width: 256px;
height: 256px;
object-fit: cover;
`;

const SelectFiles = styled(Form.Group)`
margin-top: 10px;
display: flex;
justify-content: space-between;
flex-direction: column;
`;

const PageTitle = styled.h1`
margin: 0px;
margin-right: 20px;
`

const FlexTitle = styled.div`
display: flex;
margin-bottom: 20px;
`;


const PreviewGallery = styled.img`
width: 150px;
height: 150px;
object-fit: cover;
margin-right: 20px;
`;



const PreviewGalleryFlex = styled.div`
display: flex;
flex-wrap: wrap;
flex-direction: row;
`;

const PreviewContainer = styled.div`

`;

const DeleteGalleryItem = styled(CloseButton)`
position: relative;
`


const AddWiki = () => {

    const navigate = useNavigate();
    // States
    const { user } = useContext(Context)
    const [title, setTitle] = useState('');
    const [editorState, setEditorState] = useState(null);
    const [secondEditorState, setSecondEditorState] = useState(null);
    const [thirdEditorState, setThirdEditorState] = useState(null);
    const [selectedImage, setSelectedImage] = useState('/placeholder/no-img.gif')
    const [url, setUrl] = useState('');
    const [shortDesc, setShortDesc] = useState('');
    // modal showns
    const [showModal, setShowModal] = useState(false);
    const [showGalleryAdd, setShowGalleryAdd] = useState(false);
    // gallery
    const [imageGallery, setImageGallery] = useState([]);
    // seo states
    const [seoTitle, setSeoTitle] = useState('');
    const [seoDesc, setSeoDesc] = useState('');
    const [seoKeywords, setSeoKeywords] = useState('');

    // load new format of attrs
    const [attributes, setAttributes] = useState([]);
    const [attributesList, setAttributesList] = useState([]);
    const [attributeTerms, setAttributeTerms] = useState([]);
    const [selectedTerm, setSelectedTerm] = useState(null);
    const [selectedAttribute, setSelectedAttribute] = useState(null);
    const [selectedAttributes, setSelectedAttributes] = useState([]);
    const [attributesTable, setAttributesTable] = useState([]);
    const [needReload, setNeedReload] = useState(false);
    const [attributesLoaded, setAttributesLoaded] = useState(false);


    // attributes control
    const selectAttribute = (change) => {
        setSelectedAttribute(change.value)
    }

    const selectTerm = (change) => {
        setSelectedTerm(change.value)
    }

    // set terms list using attribute id
    useEffect(() => {
        let array = [];
        attributes.map((item) => {
            if (item.id == selectedAttribute) {
                item.terms.map((term) => {
                    array.push({ value: term.id, label: term.name })
                })
            }
        })
        setAttributeTerms(array);
    }, [selectedAttribute])

    useEffect(() => {
        let array = [];
        selectedAttributes.map((elem) => {
            attributes.find((attr) => {
                attr.terms.find((term) => {
                    if (term.id == elem) {
                        array.push({ name: attr.name, term: term.name })
                    }
                })
            })
        })
        setAttributesTable(array)
    }, [needReload])

    const addAttribute = (id) => {
        let item = selectedAttributes.indexOf(id);
        if (item >= 0) {
            let prev = selectedAttributes;
            prev.splice(item, 1);
            setSelectedAttributes(prev);
        }
        else {
            let prev = selectedAttributes;
            prev.push(id);
            setSelectedAttributes(prev);
        }
        setNeedReload(!needReload);
    }

    // new type of attrs load
    useEffect(() => {
        fetchAttributes().then((data) => {
            setAttributes(data);
            let array = [];
            data.map((item) => {
                array.push({ value: item.id, label: item.name })
            })
            setAttributesList(array);
            setAttributesLoaded(true);
        })
    }, [])

    // SEO url generator
    useEffect(() => {
        let result = title.replace(/\s+/g, '-').toLowerCase();
        setUrl(result)
    }, [title])

    useEffect(() => {
        let result = url.replace(/\s+/g, '-').toLowerCase();
        setUrl(result);
    }, [url])



    const selectPrimaryImage = () => {
        setShowModal(true)
    }


    const previewUrlGenerate = (item) => {
        if (item.includes('local?', 0)) {
            let resItem = item.split('?')[1];
            return resItem;
        }
        else {
            return getFileUrl(item);
        }
    }



    // Editor state handler
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
        setShortDesc(editorState.getCurrentContent().getPlainText())
    }

    const onSecondEditorStateChange = (editorState) => {
        setSecondEditorState(editorState)
    }

    const onThirdEditorStateChange = (editorState) => {
        setThirdEditorState(editorState)
    }

    const addWiki = () => {
        const formData = new FormData();
        formData.append('title', title);
        formData.append('general', encodeEditorBody(editorState));
        formData.append('external', encodeEditorBody(secondEditorState));
        formData.append('contraindications', encodeEditorBody(thirdEditorState));
        formData.append('seo_title', seoTitle);
        formData.append('seo_description', seoDesc);
        formData.append('seo_keywords', seoKeywords);
        formData.append('url', url);
        formData.append('attributeId', JSON.stringify(selectedAttributes))
        if (shortDesc.length > 70) {
            let short = shortDesc.substring(0, 69) + '...';
            formData.append('short', short);
        }
        else {
            formData.append('short', shortDesc + '...');
        }
        formData.append('img', selectedImage);
        formData.append('userId', user.user.id);
        createWiki(formData).then((data) => {
            if (data.id) {
                NotificationManager.success(`Wiki post "${title}" successfully created`, 'Success')
                setTimeout(() => {
                    navigate(`/admin/wiki/edit/${data.id}`)
                }, 2000)
            }
            else {
                NotificationManager.error(`Wiki post "${title}" cannot be created`, `${data}`);
            }
        })
    }

    const draftWiki = () => {
        const formData = new FormData();
        formData.append('title', title);
        formData.append('general', encodeEditorBody(editorState));
        formData.append('external', encodeEditorBody(secondEditorState));
        formData.append('contraindications', encodeEditorBody(thirdEditorState));
        formData.append('seo_title', seoTitle);
        formData.append('seo_description', seoDesc);
        formData.append('seo_keywords', seoKeywords);
        formData.append('url', url);
        formData.append('attributeId', JSON.stringify(selectedAttributes))
        formData.append('img', selectedImage);
        formData.append('userId', user.user.id);
        createWikiDraft(formData).then((data) => {
            if (data.id) {
                NotificationManager.success(
                    `Wiki post "${title}" draft saved`,
                    "Success"
                );
                setTimeout(() => {
                    navigate('/admin/wiki/draft/' + data.id)
                }, 1500)
            } else {
                NotificationManager.error(
                    `Wiki post "${title}" cannot be created`,
                    `${data}`
                );
            }
        });
    }

    return (
        <AdminUI>
            <NotificationContainer />
            <FlexTitle>
                <PageTitle>Add wiki post</PageTitle>
                <Button onClick={() => addWiki()}>Publish</Button>
                <Button variant="warning" onClick={() => draftWiki()} style={{ marginLeft: '10px' }}>Save draft</Button>
            </FlexTitle>
            <Container>
                <Row>
                    <Row className="mb-5">
                        <Col>
                            <Card className="mb-3">
                                <Card.Body>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Title</Form.Label>
                                        <Form.Control aria-label="large"
                                            value={title}
                                            onChange={e => setTitle(String(e.target.value))}
                                            placeholder="Post title"
                                            type="text"
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-2">
                                        <Form.Label>SEO Url</Form.Label>
                                        <Form.Control aria-label="large"
                                            value={url}
                                            onChange={e => setUrl(String(e.target.value))}
                                            placeholder="Url"
                                            type="text"
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Short description</Form.Label>
                                        <Form.Control aria-label="large"
                                            value={shortDesc}
                                            onChange={e => setShortDesc(String(e.target.value))}
                                            placeholder="Post short desc"
                                            type="text"
                                            height={'auto'}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-2">
                                        <Form.Label>SEO Title</Form.Label>
                                        <Form.Control type="text" placeholder="SEO Title" value={seoTitle} onChange={(e) => setSeoTitle(String(e.target.value))}></Form.Control>
                                    </Form.Group>
                                    <Form.Group className="mb-2">
                                        <Form.Label>SEO Description</Form.Label>
                                        <Form.Control type="text" placeholder="SEO Description" value={seoDesc} onChange={(e) => setSeoDesc(String(e.target.value))}></Form.Control>
                                    </Form.Group>
                                    <Form.Group className="mb-2">
                                        <Form.Label>SEO Keywords</Form.Label>
                                        <Form.Control type="text" placeholder="SEO Keywords" value={seoKeywords} onChange={(e) => setSeoKeywords(String(e.target.value))}></Form.Control>
                                        <Form.Text className="text-muted">List the key words, separated by commas</Form.Text>
                                    </Form.Group>
                                </Card.Body>
                            </Card>
                            <Card className="mb-3">
                                <Card.Body>
                                    <Table>
                                        <thead>
                                            <th>Attribute name</th>
                                            <th>Term</th>
                                            <th></th>
                                        </thead>
                                        <tbody>
                                            {attributesTable[0] ? attributesTable.map((item) => (
                                                <tr>
                                                    <td>{item.name}</td>
                                                    <td>{item.term}</td>
                                                    <td></td>
                                                </tr>
                                            )) : null}
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                            <Card className="mb-3">
                                <Row>
                                    <Col><Select options={attributesList} onChange={selectAttribute} /></Col>
                                    <Col><Select options={attributeTerms} onChange={selectTerm} /></Col>
                                    <Col> <Button onClick={() => { addAttribute(selectedTerm) }}>Add attribute</Button></Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col>
                            <Card className="mb-3">
                                <Card.Body>
                                    <Form.Group>
                                        <PreviewFlex>
                                            <Form.Label>Primary image preview</Form.Label>
                                            <Preview src={getFileUrl(selectedImage)}></Preview>
                                            <p>{selectedImage == '/placeholder/no-img.gif' ? 'Empty' : selectedImage}</p>
                                        </PreviewFlex>
                                    </Form.Group>
                                    <SelectFiles>
                                        <Form.Label>Select primary image</Form.Label>
                                        <Button onClick={selectPrimaryImage}>Select image</Button>
                                    </SelectFiles>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Card className="mb-3">
                            <Card.Header>General information</Card.Header>
                            <Card.Body>
                                <Editor editorState={editorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={onEditorStateChange} />
                            </Card.Body>
                        </Card>
                        <Card className="mb-3">
                            <Card.Header>External signs of raw materials</Card.Header>
                            <Card.Body>
                                <Editor editorState={secondEditorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={onSecondEditorStateChange} />
                            </Card.Body>
                        </Card>
                        <Card className="mb-3">
                            <Card.Header>Contraindications</Card.Header>
                            <Card.Body>
                                <Editor editorState={thirdEditorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={onThirdEditorStateChange} />
                            </Card.Body>
                        </Card>
                    </Row>
                </Row>
            </Container>
            <FMAddImage showModal={showModal} setShowModal={setShowModal} image={selectedImage} setImage={setSelectedImage} />
        </AdminUI >
    )
}

export default AddWiki