import React, { Children } from "react";
import styled from "styled-components";

const Component = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: ${(props) => (props.padding ? props.padding : "12px 24px")};
  background: ${(props) =>
    props.variant == "dark"
      ? "#3C3F54"
      : props.variant == "light-dark"
        ? "#EAEBEC" : props.variant == "danger" ?
          "#EF5E53" : "#40BF6A"};
  border-radius: 4px;
  border: none;
  color: ${(props) => (props.variant == "light-dark" ? "#7F878B" : "white")};
  font-family: "Proxima Nova";
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  cursor: pointer;
  @media screen and (min-width: 1128px) {
    width: ${(props) => (props.width ? props.width : "144px")};
  }
  &:hover {
    background: ${(props) => (props.variant == "dark" ? "#525673" : props.variant == "danger" ? "#FF7E74" : "#61D186")};
  }
  &:active {
    background: ${(props) => (props.variant == "dark" ? "#242638" : props.variant == "danger" ? "#B03B32" : "#2E9E53")};
  }
`;

const DefaultButton = (props) => {
  return (
    <Component
      onClick={props.onClick}
      width={props.width}
      height={props.height}
      variant={props.variant}
      className={props.className}
      type={props.type}
      style={props.style}
    >
      {props.title}
    </Component>
  );
};

export default DefaultButton;
