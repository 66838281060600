import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { removeAddress, selectAddressDefault } from "../../../../api/addressAPI";

const AddressContainer = styled.div`
  background: #ffffff;
  border: 1px solid;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 15px;
  position: relative;
  border-color: ${(props) => (props.selected ? "#40BF6A" : "#7F878B")};
  @media screen and (min-width: 1128px) {
    width: 264px;
  }
`;
const AddressText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #303236;
`;

const Default = styled.button`
background-color: white;
border: none;
  color: #7f878b;
  position: relative;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  padding-right: 15px;
  margin-bottom: 0px;
  &:after {
    content: url("/check.svg");
    position: absolute;
    right: -3px;
    top: 2px;
  }
`;
const AddressLine = styled.span`
  display: inline-block;
  width: 100%;
`;

const Selected = styled.span`
  position: absolute;
  top: -1px;
  right: -1px;
  background: #40bf6a;
  border-radius: 0px 4px;
  width: 20px;
  height: 20px;
  z-index: 7;
  &::after {
    content: url("/check-white.svg");
    position: absolute;
    z-index: 8;
    top: -6px;
    left: 6px;
  }
`;

const Fix = styled(Link)`
  position: absolute;
  top: 3px;
  right: 40px;
  & svg{
    width: auto;
    height: 14px;
  }
  &:hover svg path{
    fill: #303236;
  }
`;

const Remove = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  border: none;
  background-color: #fff;
  &:hover {
    path {
      fill: #ef5e53;
    }
  }
`;

const SelectDefault = styled.button`
  text-align: right;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #7f878b;
  border: none;
  background-color: #fff;
  &:hover{
    color: #40BF6A;
  }
`;

const Address = ({
  name,
  country,
  state,
  street,
  phone,
  selected,
  onClick,
  fixable,
  id,
  deleteable,
  notificationManager,
  needReload,
  setNeedReload,
  defaultBilling,
  defaultShipping,
  type,
  isOrderView,
  isCheckoutView,
}) => {


  const removeCurrent = (id) => {
    removeAddress(id).then((data) => {
      if (data.status == 'removed') {
        notificationManager.success('Address removed', 'Success');
        setNeedReload(!needReload)
      }
      else {
        notificationManager.error(`${data.message}`, 'Error');
        setNeedReload(!needReload)
      }
    })
  }

  const setDefault = () => {

    let formdata = new FormData();
    formdata.append('id', id);
    formdata.append('type', type);
    selectAddressDefault(formdata).then((data) => {
      if (data.status == 'updated') {
        notificationManager.success('Address selected as default', 'Success');
        setNeedReload(!needReload)
      }
      else {
        notificationManager.error(`${data.message}`, 'Error');
        setNeedReload(!needReload)
      }
    })

  }

  return (
    <AddressContainer onClick={onClick} selected={selected}>
      {(selected == true && isOrderView == false) ? <Selected /> : null}
      <AddressText>
        <AddressLine>{name}</AddressLine>
        <AddressLine>{country}</AddressLine>
        <AddressLine>{state}</AddressLine>
        <AddressLine>{street}</AddressLine>
        <AddressLine>{phone}</AddressLine>
      </AddressText>
      {(defaultBilling || defaultShipping) ? (
        <Default>Selected by default</Default>
      ) : isOrderView || isCheckoutView ? (null) : (
        <SelectDefault onClick={() => { setDefault(id,); }}>Select by default</SelectDefault>
      )}
      {deleteable ? (
        <Remove onClick={() => removeCurrent(id)}>
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.04329 1.0141C1.75887 0.729676 1.29773 0.729676 1.01331 1.0141C0.728894 1.29851 0.728894 1.75965 1.01331 2.04407L5.97003 7.00078L1.01331 11.9575C0.728894 12.2419 0.728894 12.703 1.01331 12.9875C1.29773 13.2719 1.75887 13.2719 2.04329 12.9875L7.00001 8.03075L11.9567 12.9874C12.2411 13.2719 12.7023 13.2719 12.9867 12.9874C13.2711 12.703 13.2711 12.2419 12.9867 11.9575L8.02998 7.00078L12.9867 2.04409C13.2711 1.75967 13.2711 1.29853 12.9867 1.01412C12.7023 0.729696 12.2411 0.729696 11.9567 1.01411L7.00001 5.97081L2.04329 1.0141Z" fill="#7F878B" />
          </svg>
        </Remove>
      ) : (
        ""
      )}
      {fixable ? (
        <Fix to={"/account/addresses/edit/" + id}>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.3358 5.51593C16.2214 4.6303 16.2214 3.2056 15.3358 2.31997L13.6801 0.664232C12.7944 -0.221395 11.3697 -0.221395 10.4841 0.664232L0.395645 10.7527L0.0105896 14.2567C-0.104927 15.2578 0.742195 16.1049 1.74334 15.9894L5.24734 15.6044L15.3358 5.51593ZM11.9381 6.98838L4.7468 14.1797L1.68508 14.413L1.91837 11.3513L9.1097 4.15995L11.9381 6.98838ZM14.2695 3.38625C14.6854 3.80215 14.7666 4.15995 14.3924 4.53407L12.9795 5.94697L10.1704 3.09929L11.5448 1.72489C11.9381 1.33152 12.3396 1.45639 12.6138 1.73052L14.2695 3.38625Z" fill="#7F878B" />
          </svg>
        </Fix>
      ) : (
        ""
      )}
    </AddressContainer>
  );
};

export default Address;
