import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap"
import { Helmet } from "react-helmet";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Context } from "../../../..";
import { getUserData, updateUser } from "../../../../api/userAPI";
import LoadingPlaceholder from "../../../Functional/LoadingPlaceholder";
import DefaultButton from "../../Interface/Buttons/DefaultButton";
import Input from "../../Interface/Input/Input";
import Page from "../../Interface/Page/Page";
import Heading from "../../Interface/TextElements/Heading";
import Required from "../../Interface/TextElements/Required";
import AccountMenu from "../subcomponents/AccountMenu";
import PageHeader from "../subcomponents/PageHeader";

const Container = styled.div`
  position: relative;
  width: calc(100vw - 36px);
  margin: 0 auto;
  margin-bottom: 40px;
  h4 {
    margin-top: 20px;
  }
  button {
    margin-top: 30px;
  }
  @media screen and (min-width: 1128px) {
    width: auto;
    margin: 0;
  }
`;

const HeadingStyled = styled(Heading)`
  margin-bottom: 5px;
`;

const Form = styled.div`
  width: 100%;
  @media screen and (min-width: 1128px) {
    width: 456px;
  }
`;

const TemporaryHide = styled.div`
  @media screen and (max-width: 1127px) {
    display: none;
  }
`;

const ContentContainer = styled.div`
  margin-top: 20px;
  @media screen and (min-width: 1128px) {
    display: flex;
    margin-top: 30px;
    margin-bottom: 40px;
  }
`;


const MobileBackButton = styled(Link)`
&:before{
  content: url('/arrow-back.svg');
}
text-decoration: none;
color: black;
position: absolute;
top: -5px;
vertical-align: center;
@media screen and (min-width: 1128px){
  display: none;
}
&:hover{
  color: inherit;
}
&:active{
  color: inherit;
}
`;

const AccountDetails = () => {
  const { user } = useContext(Context);
  // states
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [passwordCurrent, setPasswordCurrent] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [passConfirm, setPassConfirm] = useState(null);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [changed, setChanged] = useState(false);
  const [needReload, setNeedReload] = useState(false);
  const [company, setCompany] = useState(null)
  // funcs
  const validateData = () => {
    if (!changed) {
      NotificationManager.warning('No data changed', 'No changes');
    }
    if (changed && !newPassword && !passConfirm) {
      if (!passwordCurrent) {
        NotificationManager.error('Enter current password', 'Password error');
      }
      else {
        let formdata = new FormData();
        formdata.append('first_name', firstName);
        formdata.append('last_name', lastName);
        formdata.append('email', email);
        formdata.append('password', passwordCurrent);
        formdata.append('id', user.user.id);
        updateUser(formdata).then((data) => {
          if (data.message == 'updated') {
            NotificationManager.success('Account details updated', 'Success');
            setNeedReload(!needReload);
          }
          else {
            NotificationManager.error(`${data.message}`, 'Error');
          }
        })
      }
    }
    if (newPassword) {
      if (!passConfirm) {
        NotificationManager.warning('Confirm your password', 'Confirm password');
      }
      if (newPassword != passConfirm) {
        NotificationManager.error(`Passwords not the same`, 'New password error');
      }
      if (newPassword == passConfirm && !passwordCurrent) {
        NotificationManager.warning('Enter current password', 'Current password');
      }
      if (newPassword == passConfirm && passwordCurrent) {
        let formdata = new FormData();
        formdata.append('first_name', firstName);
        formdata.append('last_name', lastName);
        formdata.append('email', email);
        formdata.append('password', passwordCurrent);
        formdata.append('newpassword', newPassword);
        formdata.append('id', user.user.id);
        updateUser(formdata).then((data) => {
          if (data.message == 'updated') {
            NotificationManager.success('Account details updated', 'Success');
            setNeedReload(!needReload);
          }
          else {
            NotificationManager.error(`${data.message}`, 'Error');
          }
        })
      }
    }
  }

  useEffect(() => {
    getUserData(user.user.id).then((data) => {
      setFirstName(data.first_name);
      setLastName(data.last_name);
      setEmail(data.email);
      setCompany(data.company);
      setIsLoaded(true)
    }, (error) => {
      setIsLoaded(false);
      setError(error);
    })
  }, [needReload])
  if (error) {
    return (
      <Page>
        {error}
      </Page>
    )
  }
  else if (!isLoaded) {
    return (
      <Page>
        <LoadingPlaceholder />
      </Page>
    )
  }
  else {
    return (
      <Page variant={'centred'} seo_title={'Vitaforest - Details - Account'} seo_description={'Vitaforest - Account details'}>
        <NotificationContainer />
        <ContentContainer>
          <TemporaryHide>
            <AccountMenu />
          </TemporaryHide>
          <Container>
            <MobileBackButton to={'/account'} />
            <PageHeader>Account details</PageHeader>
            <Form>
              <HeadingStyled>Personal information</HeadingStyled>
              <Input label={"First name"} required={true} placeholder={"Dave"} value={firstName} onChange={(e) => { setFirstName(e.target.value); setChanged(true) }} />
              <Input label={"Last name"} required={true} placeholder={"Peterson"} value={lastName} onChange={(e) => { setLastName(e.target.value); setChanged(true) }} />
              <HeadingStyled>Change email</HeadingStyled>
              <Input
                label={"Email "}
                required={true}
                placeholder={"Youraddress@yourmail.com"}
                value={email}
                onChange={(e) => { setEmail(e.target.value); setChanged(true) }}
              />
              <HeadingStyled>Company information</HeadingStyled>
              <Input label={"Company name"} placeholder={"Company, ltd"} value={company.companyName} disabled onClick={() => { NotificationManager.warning('If you want to change the details of the company - contact us through the ticket system.', 'Company details change') }} />
              <Input label={"VAT number"} placeholder={"EE1122334455"} value={company.vat} disabled onClick={() => { NotificationManager.warning('If you want to change the details of the company - contact us through the ticket system.', 'Company details change') }} />
              <Input label={"Registry code"} placeholder={"123654"} value={company.registryCode} disabled onClick={() => { NotificationManager.warning('If you want to change the details of the company - contact us through the ticket system.', 'Company details change') }} />
              <Input
                label={"Current password"}
                required={true}
                type={'password'}
                placeholder={"Enter your password"}
                onChange={(e) => { setPasswordCurrent(e.target.value); setChanged(true) }}
              />
              <Input
                label={"New password"}
                type={'password'}
                required={true}
                placeholder={"Enter your password"}
                onChange={(e) => { setNewPassword(e.target.value); setChanged(true) }}
              />
              <Input
                label={"Confirm new password"}
                required={true}
                type={'password'}
                placeholder={"Enter your password"}
                onChange={(e) => { setPassConfirm(e.target.value); setChanged(true) }}
              />
              <Required />
              <DefaultButton title={"Save changes"} onClick={() => { validateData() }} />
            </Form>
          </Container>
        </ContentContainer>
      </Page>
    );
  }
}

export default AccountDetails
