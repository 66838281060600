import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    body{
        font-family: 'Proxima Nova';
        font-style: normal;
        color: #333333;
    }
    a,button{
        cursor: pointer;
    }
    .pagination-container{
        display: flex;
        width: fit-content;
        gap:17px;
        list-style: none;
        
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .pagination-item{
        font-size: 20px;
        line-height: 24px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #7F878B;
        position: relative;
        &.selected{
            color:#3C3F54;
        }
        .arrow{
            &::after{
                content:url("/paginationbutton.svg");
                position: absolute;
                right: -44px;
                top: -7px;
            }
            &.left{
                &::after{
                    top:-13px;
                    transform: rotate(180deg);
                right: 2px;
            }
            }
        }
    }
`;

export default GlobalStyle;
