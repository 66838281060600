import React from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

const OrderNumber = styled.td`
  font-weight: 600;
  color: #18a0fb;
`;

const OrderDate = styled.td``;

const OrderStatus = styled.td`
  span {
    padding-left: 20px;
    background: #f7f8f9;
    display: inline-block;
    width: 100px;
    border-radius: 2px;
    padding: 4px 15px;
    text-align: center;
  }
`;

const Complete = styled.td`
  span {
    text-align: center;
    padding-left: 20px;
    background: #f3f7f2;
    color: #2e9e53;
    display: inline-block;
    width: 100px;
    border-radius: 2px;
    padding: 4px 15px;
  }
`;

const Total = styled.td`
  span {
    color: #7f878b;
    opacity: 0.6;
  }
`;

const ButtonLink = styled(Link)`
color: inherit;
text-decoration: none;
`

const Action = styled.td``;

const Button = styled.button`
  background: #40bf6a;
  border: 1px solid #40bf6a;
  border-radius: 4px;
  width: 110px;
  height: 30px;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  &:hover{
    color: white;
    background-color: #61D186;
  }
  &:active{
    color: white;
    background-color: #2E9E53;
  }
`;


const TableRow = styled.tr`
  td,
  th {
    padding-right: 10px;
  }
  td:nth-child(1),
  th:nth-child(1) {
    padding-left: 35px;
    width: 165px;
  }
  td:nth-child(2),
  th:nth-child(2) {
    width: 130px;
  }
  td:nth-child(3),
  th:nth-child(3) {
    width: 171px;
  }
  th:nth-child(3) {
    padding-left: 30px;
    position: relative;
  }
  td:nth-child(4),
  th:nth-child(4) {
    width: 220px;
  }
  td:nth-child(5),
  th:nth-child(5) {
    padding-right: 35px;
    text-align: right;
  }
  &:hover {
    box-shadow: 0px 4px 10px rgba(26, 33, 45, 0.05);
    border-radius: 4px;
  }
`;
export default function OrderTableRow({ item }) {
  const orderItems = item.items;
  // funcs
  const orderTotalCalc = (items) => {
    let total = 0;
    items.map((elem) => {
      total += Number(elem.price) * elem.qty;
    })
    return Number(total);
  }

  const itemsTotalCalc = (items) => {
    let total = 0;
    items.map((elem) => {
      total += Number(elem.qty);
    })
    return Number(total);
  }

  return (
    <TableRow>
      <OrderNumber>{item.id}</OrderNumber>
      <OrderDate>{dayjs(item.createdAt).format("MMM, DD, YYYY")}</OrderDate>
      {item.status === "complete" ? (
        <Complete>
          <span>{item.status}</span>
        </Complete>
      ) : (
        <OrderStatus>
          <span>{item.status}</span>
        </OrderStatus>
      )}
      <Total>
        €{orderTotalCalc(orderItems)} <span>for {itemsTotalCalc(orderItems)} items</span>
      </Total>
      <Action>
        <ButtonLink to={`/account/orders/${item.id}`}><Button>View Order</Button></ButtonLink>
      </Action>
    </TableRow>
  );
}
