import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { fetchProducts } from "../../../api/productAPI";
import LoadingPlaceholder from "../../Functional/LoadingPlaceholder";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { getFileUrl } from "../../../api/productAPI";
import { Link } from "react-router-dom";
const responsive = {
  moreThanDesktop: {
    breakpoint: { max: 3000, min: 1781 },
    items: 4,
    paritialVisibilityGutter: 10,
  },
  desktop: {
    breakpoint: { max: 1780, min: 1451 },
    items: 3,
    paritialVisibilityGutter: 10,
  },
  tablet: {
    breakpoint: { max: 1450, min: 1128 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 1,
  },
};

const FullWidthSlider = styled.div`
  margin-bottom: 30px;
  .react-multi-carousel-track {
    gap: 15px;
  }
`;
const BlackSalam = styled.div`
  background: black;
  opacity: 35%;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 5px;
`;
const ImageSlide = styled.div`
  min-width: 254px;
  position: relative;
  box-shadow: 0px 4px 14px rgba(26, 33, 45, 0.1);
  margin-right: 15px;
  height: 150px;
  border-radius: 5px;
  @media screen and (min-width: 1128px) {
    min-width: 338px;
    height: 200px;
  }
`;

const ImageSlideTitle = styled.h5`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  position: absolute;
  bottom: 45px;
  left: 30px;
`;

const ImageSlideThumbnail = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 5px;
  @media screen and (min-width: 1128px) {
    height: 200px;
  }
`;

const ImageSlideLink = styled(Link)`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-decoration-line: underline;
  color: #40bf6a;
  position: absolute;
  bottom: 20px;
  left: 30px;
  :after {
    transform: rotate(-90deg);
    content: url("/process-arrow.svg");
    position: absolute;
    right: -10px;
  }
`;

function WikiRelated({ ids }) {
  const [products, setProducts] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  useEffect(() => {
    fetchProducts(ids, []).then(
      (data) => {
        setProducts(data);
        setIsLoaded(true);
      },
      (e) => {
        setError(e);
        setIsLoaded(false);
      }
    );
  }, []);
  if (error) {
    return (
      <>
        <h5>{error}</h5>
      </>
    );
  } else if (!isLoaded) {
    return (
      <>
        <LoadingPlaceholder />
      </>
    );
  } else {
    return (
      <FullWidthSlider>
        <Carousel
          ssr
          itemClass="image-item"
          responsive={responsive}
          infinite={true}
          autoPlaySpeed={1000}
          showDots={false}
          swipeable={false}
          draggable={false}
          centerMode={true}
          partialVisible={false}
          arrows={false}
        >
          {products.length > 0 && products.length < 3
            ? setProducts([...products, ...products])
            : null}

          {!error
            ? products.map((item) => {
              return (
                <ImageSlide>
                  <BlackSalam />
                  <ImageSlideTitle>{item.title}</ImageSlideTitle>
                  <ImageSlideThumbnail src={getFileUrl(item.img)} />
                  <ImageSlideLink to={`/product/${item.url}`}>
                    Shop now
                  </ImageSlideLink>
                </ImageSlide>
              );
            })
            : null}
        </Carousel>
      </FullWidthSlider>
    );
  }
}
export default WikiRelated;
