import React, { useEffect, useContext } from "react";
import AdminUI from "../../Ui/AdminUI";
import { Editor } from 'react-draft-wysiwyg';
import { useState } from "react";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Card, Form, Button, Container, Row, Col, Table } from "react-bootstrap";
import styled from "styled-components";
import { createPost, decodeEditorBody, encodeEditorBody } from "../../../../api/postAPI";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { Context } from "../../../..";
import { useNavigate } from "react-router-dom";
import { fetchCategories, getFileUrl } from "../../../../api/productAPI";
import FMAddImage from "../../FileManager/FMAddImage";
import { fetchPostCategories } from "../../../../api/categoriesAPI";
import Select from "react-select";
import { fetchAttributes } from "../../../../api/attributeAPI";
import { createPostDraft } from "../../../../api/draftAPI";

const FlexBox = styled.div`
display: flex;
justify-content: space-between;
`;

const SelectFiles = styled(Form.Group)`
margin-top: 10px;
display: flex;
justify-content: space-between;
flex-direction: column;
`;

const PreviewFlex = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
`;



const CatsViewer = styled.ul`
`;

const FlexTitle = styled.div`
display: flex;
margin-bottom: 20px;
`;

const PageTitle = styled.h1`
margin: 0px;
margin-right: 20px;
`

const Preview = styled.img`
width: 256px;
height: 256px;
object-fit: cover;
`;


const AddPost = () => {

    // States
    const { user } = useContext(Context)
    const [title, setTitle] = useState('');
    const [editorState, setEditorState] = useState('');
    const [file, setFile] = useState(null);
    const [url, setUrl] = useState('');
    const [rawData, setRaw] = useState(null);
    const [primaryImage, setPrimaryImage] = useState('/placeholder/no-img.gif');
    const navigate = useNavigate();
    const [categoryList, setCategoryList] = useState(null);
    const [loadedCats, setLoadedCats] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState('')
    const [showAddModal, setShowAddModal] = useState(false);
    const [error, setError] = useState(null);
    const [shortDesc, setShortDesc] = useState('');

    // load new format of attrs
    const [attributes, setAttributes] = useState([]);
    const [attributesList, setAttributesList] = useState([]);
    const [attributeTerms, setAttributeTerms] = useState([]);
    const [selectedTerm, setSelectedTerm] = useState(null);
    const [selectedAttribute, setSelectedAttribute] = useState(null);
    const [selectedAttributes, setSelectedAttributes] = useState([]);
    const [attributesTable, setAttributesTable] = useState([]);
    const [needReload, setNeedReload] = useState(false);
    const [attributesLoaded, setAttributesLoaded] = useState(false);

    // seo states
    const [seoTitle, setSeoTitle] = useState('');
    const [seoDesc, setSeoDesc] = useState('');
    const [seoKeywords, setSeoKeywords] = useState('');

    // SEO url generator
    useEffect(() => {
        let result = title.replace(/\s+/g, '-').toLowerCase();
        setUrl(result)
    }, [title])

    useEffect(() => {
        let result = url.replace(/\s+/g, '-').toLowerCase();
        setUrl(result);
    }, [url])


    useEffect(() => {
        fetchPostCategories().then((data) => {
            setCategoryList(data)
            setLoadedCats(true)
        }, (error) => {
            setError(error);
            setLoadedCats(false)
        })
    }, [])

    const selectPrimaryImage = () => {
        setShowAddModal(true)
    }

    // Editor state handler
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
        setShortDesc(editorState.getCurrentContent().getPlainText())
    }

    const appendCategories = (name) => {
        setSelectedCategories(name);
    }

    // attributes control
    const selectAttribute = (change) => {
        setSelectedAttribute(change.value)
    }

    const selectTerm = (change) => {
        setSelectedTerm(change.value)
    }

    // set terms list using attribute id
    useEffect(() => {
        let array = [];
        attributes.map((item) => {
            if (item.id == selectedAttribute) {
                item.terms.map((term) => {
                    array.push({ value: term.id, label: term.name })
                })
            }
        })
        setAttributeTerms(array);
    }, [selectedAttribute])

    useEffect(() => {
        let array = [];
        selectedAttributes.map((elem) => {
            attributes.find((attr) => {
                attr.terms.find((term) => {
                    if (term.id == elem) {
                        array.push({ name: attr.name, term: term.name })
                    }
                })
            })
        })
        setAttributesTable(array)
    }, [needReload])

    const addAttribute = (id) => {
        let item = selectedAttributes.indexOf(id);
        if (item >= 0) {
            let prev = selectedAttributes;
            prev.splice(item, 1);
            setSelectedAttributes(prev);
        }
        else {
            let prev = selectedAttributes;
            prev.push(id);
            setSelectedAttributes(prev);
        }
        setNeedReload(!needReload);
    }

    // new type of attrs load
    useEffect(() => {
        fetchAttributes().then((data) => {
            setAttributes(data);
            let array = [];
            data.map((item) => {
                array.push({ value: item.id, label: item.name })
            })
            setAttributesList(array);
            setAttributesLoaded(true);
        })
    }, [])

    const addPost = () => {
        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', encodeEditorBody(editorState));
        formData.append('url', url);
        formData.append('img', primaryImage);
        formData.append('seo_title', seoTitle);
        formData.append('seo_description', seoDesc);
        formData.append('seo_keywords', seoKeywords);
        formData.append('category', selectedCategories);
        formData.append('attributeId', JSON.stringify(selectedAttributes));
        formData.append('userId', user.user.id);
        if (shortDesc.length > 70) {
            let short = shortDesc.substring(0, 69) + '...';
            formData.append('short', short);
        }
        else {
            formData.append('short', shortDesc + '...');
        }
        createPost(formData).then((data) => {
            if (data.id) {
                NotificationManager.success(`Post "${title}" successfully created`, 'Success')
                setTimeout(() => {
                    navigate(`/admin/posts/edit/${data.id}`)
                }, 2000)
            }
            else {
                NotificationManager.error(`Post "${title}" cannot be created`, `${data}`);
            }
        })
    }

    const draftPost = () => {
        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', encodeEditorBody(editorState));
        formData.append('url', url);
        formData.append('img', primaryImage);
        formData.append('seo_title', seoTitle);
        formData.append('seo_description', seoDesc);
        formData.append('seo_keywords', seoKeywords);
        formData.append('category', selectedCategories);
        formData.append('attributeId', JSON.stringify(selectedAttributes));
        formData.append('userId', user.user.id);
        createPostDraft(formData).then((data) => {
            if (data.id) {
                NotificationManager.success(
                    `Post "${title}" draft saved`,
                    "Success"
                );
                setTimeout(() => {
                    navigate('/admin/post/draft/' + data.id)
                }, 1500)
            } else {
                NotificationManager.error(
                    `Post "${title}" cannot be created`,
                    `${data}`
                );
            }
        })
    }
    if (error) {
        return <AdminUI>{error}</AdminUI>
    }
    else if (!loadedCats || !attributesLoaded) {
        return <AdminUI><h2>Loading...</h2></AdminUI>
    }
    else {
        return (
            <AdminUI>
                <NotificationContainer />
                <FlexTitle>
                    <PageTitle>Add new post</PageTitle>
                    <Button onClick={() => { addPost() }}>Publish</Button>
                    <Button variant="warning" onClick={() => draftPost()} style={{ marginLeft: '10px' }}>Save draft</Button>
                </FlexTitle>
                <Form className="mb-5">
                    <Container>
                        <Row>
                            <Col xs={7}>
                                <Card className="mb-5">
                                    <Card.Body>
                                        <Form.Group className="mb-2">
                                            <Form.Label>Title</Form.Label>
                                            <Form.Control aria-label="large"
                                                value={title}
                                                onChange={e => setTitle(String(e.target.value))}
                                                placeholder="Post title"
                                                type="text"
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>URL</Form.Label>
                                            <Form.Control aria-label="large"
                                                value={url}
                                                onChange={e => setUrl(String(e.target.value))}
                                                placeholder="Post title"
                                                type="text"
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-2">
                                            <Form.Label>Short description</Form.Label>
                                            <Form.Control aria-label="large"
                                                value={shortDesc}
                                                onChange={e => setShortDesc(String(e.target.value))}
                                                placeholder="Post title"
                                                type="text"
                                                height={'auto'}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-2">
                                            <Form.Label>SEO Title</Form.Label>
                                            <Form.Control type="text" placeholder="SEO Title" value={seoTitle} onChange={(e) => setSeoTitle(String(e.target.value))}></Form.Control>
                                        </Form.Group>
                                        <Form.Group className="mb-2">
                                            <Form.Label>SEO Description</Form.Label>
                                            <Form.Control type="text" placeholder="SEO Description" value={seoDesc} onChange={(e) => setSeoDesc(String(e.target.value))}></Form.Control>
                                        </Form.Group>
                                        <Form.Group className="mb-2">
                                            <Form.Label>SEO Keywords</Form.Label>
                                            <Form.Control type="text" placeholder="SEO Keywords" value={seoKeywords} onChange={(e) => setSeoKeywords(String(e.target.value))}></Form.Control>
                                            <Form.Text className="text-muted">List the key words, separated by commas</Form.Text>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                                <Card className="mb-3">
                                    <Card.Body>
                                        <Table>
                                            <thead>
                                                <th>Attribute name</th>
                                                <th>Term</th>
                                                <th></th>
                                            </thead>
                                            <tbody>
                                                {attributesTable[0] ? attributesTable.map((item) => (
                                                    <tr>
                                                        <td>{item.name}</td>
                                                        <td>{item.term}</td>
                                                        <td></td>
                                                    </tr>
                                                )) : null}
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                                <Card className="mb-3" style={{ zIndex: 999999 }}>
                                    <Row>
                                        <Col><Select options={attributesList} onChange={selectAttribute} /></Col>
                                        <Col><Select options={attributeTerms} onChange={selectTerm} /></Col>
                                        <Col> <Button onClick={() => { addAttribute(selectedTerm) }}>Add attribute</Button></Col>
                                    </Row>
                                </Card>
                                <Card>
                                    <Card.Header>Editor</Card.Header>
                                    <Card.Body>
                                        <Editor editorState={editorState}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onEditorStateChange={onEditorStateChange} />
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card className="mb-5">
                                    <Card.Header>Categories</Card.Header>
                                    <Card.Body>
                                        {categoryList.map(item => {
                                            return (
                                                <Form.Check key={item.id} type={'checkbox'}>
                                                    <Form.Check.Input type={'checkbox'} onClick={() => appendCategories(item.name)} checked={item.name === selectedCategories ? true : false} />
                                                    <Form.Check.Label>{item.name}</Form.Check.Label>
                                                </Form.Check>
                                            )
                                        })}
                                    </Card.Body>
                                </Card>
                                <Card className="mb-3">
                                    <Card.Body>
                                        <Form.Group>
                                            <PreviewFlex>
                                                <Form.Label>Primary image preview</Form.Label>
                                                <Preview src={getFileUrl(primaryImage)}></Preview>
                                                <p>{primaryImage == '/placeholder/no-img.gif' ? 'Empty' : primaryImage}</p>
                                            </PreviewFlex>
                                        </Form.Group>
                                        <SelectFiles>
                                            <Form.Label>Select primary image</Form.Label>
                                            <Button onClick={selectPrimaryImage}>Select image</Button>
                                        </SelectFiles>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Form>
                <FMAddImage showModal={showAddModal} setShowModal={setShowAddModal} image={primaryImage} setImage={setPrimaryImage} />
            </AdminUI >
        )
    }
}

export default AddPost