import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Page from "../../Interface/Page/Page";
import AccountMenu from "../subcomponents/AccountMenu";
import PageHeader from "../subcomponents/PageHeader";
import { Link } from "react-router-dom";
import Heading from "../../Interface/TextElements/Heading";
import CircleRadio from "../../Interface/Input/CircleRadio";
import DefaultButton from "../../Interface/Buttons/DefaultButton";
import {
  commentPrivacySwitch,
  getGdpr,
  getUserSettings,
  switchTwoFA,
} from "../../../../api/userAPI";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { Context } from "../../../..";
import LoadingPlaceholder from "../../../Functional/LoadingPlaceholder";

const TemporaryHide = styled.div`
  @media screen and (max-width: 1127px) {
    display: none;
  }
`;
const Container = styled.div`
  margin-top: 20px;
  @media screen and (min-width: 1128px) {
    margin-top: 30px;
    margin-bottom: 40px;
    display: flex;
    width: 1128px;
    margin: 30px auto;
  }
`;

const Wrapper = styled.div`
  width: calc(100vw - 36px);
  margin: 0 auto;
  @media screen and (min-width: 1128px) {
    width: auto;
    margin: 0;
  }
`;

const MobileBackButton = styled(Link)`
  &:before {
    content: url("/arrow-back.svg");
  }
  text-decoration: none;
  color: black;
  position: absolute;
  top: -5px;
  margin-left: 20px;
  vertical-align: center;
  @media screen and (min-width: 1128px) {
    display: none;
  }
  &:hover {
    color: inherit;
  }
  &:active {
    color: inherit;
  }
`;

const Box = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  h4 {
    margin: 0px;
  }
`;

const DeleteButton = styled(DefaultButton)`
  background: #ef5e53;
  border: 1px solid #ef5e53;
  &:hover,
  &:focus,
  &:active {
    border: 1px solid #ef5e53;
    color: #ef5e53;
    background-color: #fff;
    outline: none;
  }
`;

export default function AccountPrivacy() {
  const { user } = useContext(Context);
  const [settings, setSettings] = useState(null);
  const [trigger, cTrigger] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const gdprDownload = () => {
    getGdpr().then((data) => {
      if ((data.status = "sended")) {
        NotificationManager.success(
          "All information sended at your email",
          "Success"
        );
      } else {
        NotificationManager.error("Please, try again later", "Error");
      }
    });
  };

  useEffect(() => {
    getUserSettings(user.user.id).then((data) => {
      setSettings(data);
      setIsLoaded(true);
    });
  }, [trigger]);

  const enabletwoFA = () => {
    switchTwoFA(!settings.twoFa).then((data) => {
      if (data.status == "switched_fa") {
        NotificationManager.success(
          `${settings.twoFa ? "2FA disabled" : "2FA enabled"}`,
          "Success"
        );
        cTrigger(!trigger);
      } else {
        NotificationManager.error(data, "Error");
      }
    });
  };

  return (
    <Page
      background={false}
      seo_title={"Vitaforest - Account - Privacy"}
      seo_description={"Vitaforest - Account details"}
    >
      <NotificationContainer />
      <Container>
        <TemporaryHide>
          <AccountMenu />
        </TemporaryHide>
        {!isLoaded ? (
          <LoadingPlaceholder />
        ) : (
          <Wrapper>
            <MobileBackButton />
            <PageHeader>Privacy tools</PageHeader>
            <Box>
              <Heading>2FA</Heading>
              <p>Enable two factor authentication to protect your account</p>
              {!settings.twoFa ? (
                <DefaultButton
                  title="Enable 2FA"
                  onClick={() => {
                    enabletwoFA();
                  }}
                />
              ) : (
                <DefaultButton
                  title="Disable 2FA"
                  onClick={() => {
                    enabletwoFA();
                  }}
                />
              )}
            </Box>
            <Box>
              <Heading>GDPR. Right to data portability</Heading>
              <p style={{ width: "60%" }}>
                The GDRP requires us to provide information about what user data
                we collect. If you would like to see a list of this information,
                click the "Download" button. As soon as the server has finished
                processing this request, you will receive the information to the
                e-mail address specified in your account.
              </p>
              <DefaultButton
                onClick={() => {
                  gdprDownload();
                }}
                title={"Download data"}
              />
            </Box>
            <Box>
              <Heading>Personal data display</Heading>
              <CircleRadio
                label={"Show my first and last name in comments"}
                value={0}
                onClick={() => {
                  commentPrivacySwitch(false).then((data) => {
                    if (data.status == "switched_cp") {
                      NotificationManager.success(
                        "First and last name are visible",
                        "Enabled"
                      );
                      cTrigger(!trigger);
                    } else {
                      NotificationManager.error(data.message, "Error");
                    }
                  });
                }}
                checked={settings.comments_privacy ? false : true}
              ></CircleRadio>
              <CircleRadio
                label={"Display my comments anonymously "}
                value={1}
                onClick={() => {
                  commentPrivacySwitch(true).then((data) => {
                    if (data.status == "switched_cp") {
                      NotificationManager.success(
                        "First and last name are hidden",
                        "Enabled"
                      );
                      cTrigger(!trigger);
                    } else {
                      NotificationManager.error(data.message, "Error");
                    }
                  });
                }}
                checked={settings.comments_privacy ? true : false}
              ></CircleRadio>
            </Box>
            <Box>
              <Heading>Delete account</Heading>
              <p>
                Once you delete your data, there is no going back. Please be
                certain
              </p>
              <DeleteButton title="Delete my data" />
            </Box>
          </Wrapper>
        )}
      </Container>
    </Page>
  );
}
