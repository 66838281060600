import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Address from "../components/VitaforestUI/Interface/AccountComponetnts/Address";
import Page from "../components/VitaforestUI/Interface/Page/Page";
import Heading from "../components/VitaforestUI/Interface/TextElements/Heading";
import DefaultButton from "../components/VitaforestUI/Interface/Buttons/DefaultButton";
import CircleRadio from "../components/VitaforestUI/Interface/Input/CircleRadio";
import Select from "../components/VitaforestUI/Interface/Select/Select";
import Textarea from "../components/VitaforestUI/Interface/Textarea/Textarea";
import SectionHeading from "../components/VitaforestUI/Interface/TextElements/SectionHeading";
import OrdersTable from "../components/VitaforestUI/Interface/OrdersComponents/OrdersTable";
import { fetchBasket, getCheckoutData } from "../api/basketAPI";
import { Context } from "..";
import { fetchBillingAddresses, fetchShippingAddresses } from "../api/userAPI";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { Spinner } from "react-bootstrap";
import { createOrder } from "../api/ordersAPI";
import { Country, State } from "country-state-city";
import Input from "../components/VitaforestUI/Interface/Input/Input";
import { addNewAddress } from "../api/addressAPI";
import { useNavigate } from "react-router-dom";
import LoadingPlaceholder from "../components/Functional/LoadingPlaceholder";
import EmptyCart from "../components/VitaforestUI/Interface/OrdersComponents/EmptyCart";
import { Helmet } from "react-helmet";
import useModal from "../components/Functional/useModal";
import useScrollBlock from "../components/Functional/useScrollBlock";
import Modal from "../components/VitaforestUI/Interface/Modal";
const FlexContainer = styled.div`
  @media screen and (min-width: 1128px) {
    display: flex;
    padding-top: 25px;
    padding-bottom: 50px;
  }
`;

const CheckoutPage = styled(Page)`
  background: linear-gradient(
    0deg,
    rgba(247, 248, 249, 0.5),
    rgba(247, 248, 249, 0.5)
  );
`;

const CheckoutContainer = styled.div`
  width: calc(100vw - 36px);
  margin: 0 auto;
  @media screen and (min-width: 1128px) {
    background: #ffffff;
    border: 1px solid #eaebec;
    border-radius: 6px;
    box-sizing: border-box;
    padding: 25px;
    margin-right: 25px;
    width: 648px;
    margin-left: 0px;
  }
`;
const AddressSection = styled.div``;

const CheckoutAddresess = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1128px) {
    flex-direction: row;
    align-items: flex-start;
    div {
      margin-right: 10px;
    }
  }
  div {
    margin-bottom: 10px;
  }
`;

const ShippingSection = styled.div`
  margin-top: 30px;
`;

const AdditionInformation = styled.div`
  margin-top: 30px;
`;
const AdditionForm = styled.form``;

const RequiredField = styled.p`
  position: relative;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  color: #ef5e53;
  margin-top: 25px;
`;

const PaymentSection = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: justify;
`;

const LeftColumn = styled.div``;

const Order = styled.div``;

const RightColumn = styled.div``;

const NewAddressSection = styled.div`
`

const Checkout = () => {
  const { user } = useContext(Context);
  const [items, setItems] = useState(null);
  const [billing, setBilling] = useState(null);
  const [addresses, setAddresses] = useState(null);
  const [isItemsLoaded, setIsItemsLoaded] = useState(false);
  const [isAddressesLoaded, setIsAddressesLoaded] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(false);
  const [comment, setComment] = useState(null);
  const [cartId, setCartId] = useState(false);
  const [tare, setTare] = useState('Box');
  const [paymentMethod, setPaymentMethod] = useState(false);
  const [newAddressRender, setNewAddressRender] = useState(false);
  const [country, setCountry] = useState("");
  const [countryIso, setCountryIco] = useState("");
  const [orderId, setOrderId] = useState('');
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [post, setPost] = useState("");
  const [type, setType] = useState("");
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [phone, setPhone] = useState(null);
  const { isShowing, toggle } = useModal();
  const [blockScroll, allowScroll] = useScrollBlock();
  const countriesRaw = Country.getAllCountries();

  const navigate = useNavigate();

  const updateCountry = function (e) {
    setCountry(e.target.value);
    countriesRaw.forEach((elem) => {
      if (elem.name === e.target.value) {
        setCountryIco(elem.isoCode);
      }
    });
  };

  const UpdatedStates = function () {
    if (countryIso) {
      return State.getStatesOfCountry(countryIso);
    } else {
      return State.getStatesOfCountry("AF");
    }
  };


  const orderCreate = () => {
    if (newAddressRender) {
      if (!type || !country || !state || !city || !post || !zip || !firstName || !lastName) {
        NotificationManager.warning('Fill required fields', "Warning");
      }
      else {
        let formdata = new FormData();
        formdata.append("userId", user.user.id);
        formdata.append("type", 'Shipping');
        formdata.append("country", country);
        formdata.append("state", state);
        formdata.append("city", city);
        formdata.append("post", post);
        formdata.append("zip", zip);
        formdata.append("first_name", firstName);
        formdata.append("last_name", lastName);
        formdata.append("phone", phone);
        addNewAddress(formdata).then((data) => {
          if (data.id) {
            NotificationManager.success("Address saved", "Success");
            let formData = new FormData();
            formData.append('items', JSON.stringify(items));
            formData.append('cartId', cartId);
            formData.append('userId', user.user.id);
            formData.append('shipping_address', data.id);
            formdata.append('billing_address', billing)
            formData.append('comment', comment);
            formData.append('tare', tare);
            createOrder(formdata).then((data) => {
              if (data.id) {
                setOrderId(data.id);
                toggle();
                blockScroll();
              }
              else {
                NotificationManager.error(`${data.message}`, 'Error')
              }
            })
          } else {
            NotificationManager.error(data.message, "Error");
          }
        });
      }
    }
    else {
      let formdata = new FormData();
      formdata.append('items', JSON.stringify(items));
      formdata.append('cartId', cartId);
      formdata.append('userId', user.user.id);
      formdata.append('billing_address', billing)
      addresses.find((item) => {
        if (selectedAddress === item.id) {
          formdata.append('shipping_address', selectedAddress);
        }
      })
      formdata.append('comment', comment);
      formdata.append('tare', tare);
      createOrder(formdata).then((data) => {
        if (data.id) {
          setOrderId(data.id);
          toggle();
          blockScroll();
        }
        else {
          NotificationManager.error(`${data.message}`, 'Error')
        }
      })
    }
  }

  const validateOrder = () => {
    if (!selectedAddress && !newAddressRender) {
      NotificationManager.error('Select shipping address', 'Error')
    }
    else if (!paymentMethod) {
      NotificationManager.error('Select payment method', 'Error')
    }
    else {
      orderCreate()
    }
  }

  // get addresses
  useEffect(() => {
    fetchShippingAddresses(user.user.id).then((data) => {
      setAddresses(data);
      data.map((item) => {
        if (item.defaultShipping) {
          setSelectedAddress(item.id)
        }
      })
      fetchBillingAddresses(user.user.id).then((data) => {
        data.map((address) => {
          if (address.defaultBilling) {
            setBilling(address.id);
          }
        })
      })
      setIsAddressesLoaded(true);
    })
  }, [])

  // get basket items
  useEffect(() => {
    fetchBasket(user.user.id).then((data) => {
      setItems(data.items);
      setIsItemsLoaded(true);
      setCartId(data.id);
    })
  }, [])
  // get user details

  if (isItemsLoaded && isAddressesLoaded && items[0]) {
    return (
      <CheckoutPage variant={'centred'}>
        <Helmet>
          <title>Checkout - Vitaforest</title>
          <meta name="description" content={'Vitaforest checkout page'} />
        </Helmet>
        <Modal type={"order"}
          order={orderId}
          isShowing={isShowing}
          hide={toggle}
          allow={allowScroll}
        />
        <NotificationContainer />
        <FlexContainer>
          <CheckoutContainer>
            <LeftColumn>
              <SectionHeading>Checkout</SectionHeading>
              <AddressSection>
                <Heading>Shipping address</Heading>
                {!newAddressRender ? (
                  <CheckoutAddresess>
                    {addresses ? addresses.map((item) => (<Address
                      key={item.id}
                      selected={selectedAddress === item.id ? true : false}
                      defaultItem={item.defaultShipping}
                      name={item.first_name + " " + item.last_name}
                      country={item.country}
                      state={item.state}
                      street={item.post}
                      isOrderView={false}
                      isCheckoutView={true}
                      phone={item.phone}
                      onClick={() => { setSelectedAddress(item.id); }} />)) : null}
                    <DefaultButton title="New address" onClick={() => { setNewAddressRender(true) }} />
                  </CheckoutAddresess>
                ) : (<NewAddressSection>
                  <Input
                    label="First name"
                    placeholder="Your first name"
                    type="text"
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                    value={firstName}
                    required="true"
                  />

                  <Input
                    label="Last name"
                    placeholder="Your last name"
                    type="text"
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                    value={lastName}
                    required="true"
                  />

                  <Input
                    label="Phone"
                    placeholder="Your phone"
                    type="phone"
                    value={phone}
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                    required="true"
                  />

                  <Select
                    label="Country"
                    options={countriesRaw}
                    name={country}
                    type="country"
                    onChange={(e) => {
                      updateCountry(e);
                    }}
                    required="true"
                  />

                  <Select
                    label="State/province"
                    options={UpdatedStates()}
                    name={country}
                    type="country"
                    onChange={(e) => {
                      setState(e.target.value);
                    }}
                    required="true"
                  />

                  <Input
                    label="City"
                    placeholder="Your city"
                    type="text"
                    onChange={(e) => {
                      setCity(e.target.value);
                    }}
                    required="true"
                  />
                  <Input
                    label="Zip/postal code"
                    placeholder="_ _ _ _ _ _"
                    type="text"
                    onChange={(e) => {
                      setZip(e.target.value);
                    }}
                    required="true"
                  />
                  <Input
                    label="Post address"
                    placeholder="Your post address"
                    type="text"
                    onChange={(e) => {
                      setPost(e.target.value);
                    }}
                    required="true"
                  />
                </NewAddressSection>)}
              </AddressSection>
              <ShippingSection>
                <Heading>Shipping method</Heading>
                <CircleRadio
                  label="€0.00"
                  value="Individual calculation"
                  description="Individual calculaion"
                  onChange={(e) => { setPaymentMethod(e.target.value) }}
                />
              </ShippingSection>
              <AdditionInformation>
                <Heading>Addition Information</Heading>
                <AdditionForm>
                  <Select
                    required
                    label="Select a tare"
                    options={["Box"]}
                    onChange={(e) => setTare(e.target.value)}
                  />
                  <Textarea label="Your comment to order" onChange={(e) => { setComment(e.target.value) }} />
                </AdditionForm>
                <RequiredField>* Required fields</RequiredField>
              </AdditionInformation>
              <PaymentSection>
                <Heading>Payment method</Heading>
                <p>Bank transfer payment</p>
              </PaymentSection>
            </LeftColumn>
          </CheckoutContainer>
          <RightColumn>
            <Order>
              <OrdersTable items={items} validate={validateOrder} />
            </Order>
          </RightColumn>
        </FlexContainer>
      </CheckoutPage>
    );
  }
  else if (isItemsLoaded && isAddressesLoaded) {
    return (
      <Page variant={'centred'}>
        <EmptyCart />
      </Page>
    )
  }
  else {
    return (
      <CheckoutPage>
        <LoadingPlaceholder />
      </CheckoutPage>
    )
  }
}
export default Checkout