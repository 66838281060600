import React, { useState } from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import { calcAttrProductsTotal } from "../../../../api/attributeAPI";

const SearchInput = styled.input`
  width: 100%;
  height: 48px;
  background: #ffffff;
  border: 1px solid #eaebec;
  border-radius: 4px;
  margin: 0px;
  @media screen and (min-width: 1128px) {
    width: calc(100% - 30px);
    height: 35px;
    margin: 0 auto;
    display: block;
    margin-top: 10px;
  }
`;

const List = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #303236;
  .list {
    display: none;
  }
  .shown {
    display: block;
  }
  @media screen and (min-width: 1128px) {
    ul {
      margin-bottom: 0;
      padding: 7px 0px;
    }
    .wrapper.shown {
      background: #f7f8f9;
      h4 {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #303236;
        opacity: 1;
      }
    }
  }
`;

const ListTitle = styled.h4`
  font-size: 16px;
  line-height: 19px;
  padding: 7px 0px;
  box-shadow: 0px 1px 0px #eaebec;
  @media screen and (min-width: 1128px) {
    color: #303236;
    opacity: 0.6;
    box-shadow: 0px 1px 0px #eaebec;
    padding: 14px;
    margin-bottom: 0;
    position: relative;
    &::after {
      position: absolute;
      content: url("/arrow.svg");
      right: 17px;
    }
  }
  &.shown {
    &::after {
      transform: rotate(180deg);
    }
  }
`;

const ListItems = styled.ul`
  list-style: none;
  padding: 0px;
`;
const Item = styled.li`
  margin: 0px;
  margin-bottom: 5px;
  padding: 12px 15px;
  display: flex;
  justify-content: space-between;
  position: relative;
  @media screen and (min-width: 1128px) {
    padding: 7px 0px;
    padding-right: 60px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    padding-left: 15px;
  }
  .custom-checkbox + label::before {
    content: url("/checkbox.svg");
    position: absolute;
    right: 3px;
    top: 11px;
    @media screen and (min-width: 1128px) {
      content: url("/checkbox-small.svg");
      top: 6px;
      right: 15px;
    }
  }
  .custom-checkbox:checked + label::before {
    content: url("/checkbox-checked.svg");
    @media screen and (min-width: 1128px) {
      content: url("/checkbox-small-checked.svg");
    }
  }
  .custom-checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
`;

const Input = styled.input`
  width: 100%;
`;

const Counter = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #7f878b;
  position: absolute;
  right: 37px;
  top: 14px;
  @media screen and (min-width: 1128px) {
    top: 5px;
  }
`;

const SingleList = ({
  items,
  title,
  attributesFilters,
  setAttributesFilters,
  reload,
  setReload,
  products,
  isCatFilter
}) => {
  const [listShown, setListShown] = useState(false);
  const [updateState, setUpdateState] = useState(false);
  const [search, setSearch] = useState("");

  const checkFilters = (id) => {
    let item = attributesFilters.indexOf(id);
    if (item >= 0) {
      let prev = attributesFilters;
      prev.splice(item, 1);
      setAttributesFilters(prev);
      setUpdateState(!updateState);
      setReload(!reload);
    } else {
      let prev = attributesFilters;
      prev.push(id);
      setAttributesFilters(prev);
      setUpdateState(!updateState);
      setReload(!reload);
    }
  };

  return (
    <List>
      <div className={listShown ? "wrapper shown" : "wrapper"}>
        <ListTitle
          onClick={() => setListShown(!listShown)}
          className={listShown ? "title shown" : "title"}
        >
          {title}
        </ListTitle>
        <div className={listShown ? "list shown" : "list "}>
          <SearchInput onChange={(e) => setSearch(e.target.value)} />
          <ListItems>
            {items.map((item) => {
              return search == "" ||
                item.name.toLowerCase().includes(search.toLowerCase()) ? (
                <Item key={item.id}>
                  <Input
                    id={item.name}
                    name={item.name}
                    className={"custom-checkbox"}
                    value={item.id}
                    type="checkbox"
                    checked={attributesFilters.includes(item.id) ? true : false}
                    onChange={(e) => {
                      checkFilters(Number(e.target.value));
                    }}
                  ></Input>
                  <label htmlFor={item.name}>
                    {item.name}
                  </label>
                  <Counter>{calcAttrProductsTotal(item.id, products, isCatFilter)}</Counter>
                </Item>
              ) : null;
            })}
          </ListItems>
        </div>
      </div>
    </List>
  );
};

export default SingleList;
