import React from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

const Table = styled.table`
  width: 840px;
  border: 1px solid #eaebec;
  border-radius: 6px;
  margin-top: 24px;
`;

const TableRow = styled.tr`
  td,
  th {
    padding-right: 10px;
  }
  td:nth-child(1),
  th:nth-child(1) {
    padding-left: 35px;
    width: 260px;
  }
  td:nth-child(2),
  th:nth-child(2) {
    width: 120px;
  }
  td:nth-child(3),
  th:nth-child(3) {
    width: 120px;
  }
  td:nth-child(4),
  th:nth-child(4) {
    width: 93px;
  }
  td:nth-child(5),
  th:nth-child(5) {
    padding-right: 35px;
    text-align: right;
  }
  &:hover {
    box-shadow: 0px 4px 10px rgba(26, 33, 45, 0.05);
    border-radius: 4px;
  }
`;
const TableHeader = styled.thead``;
const HeadRow = styled(TableRow)`
  box-shadow: 0px 1px 0px #eaebec;
`;
const HeaderCell = styled.th`
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: #7f878b;
  padding-top: 10px;
`;

const UsualCell = styled.td`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #303236;
  padding: 10px 0px;
`;

const TicketLink = styled(Link)`
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
  background: #3c3f54;
  border: none;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 7px 14px;
  &:hover{
    color: white;
    background-color: #525673; 
  }
  &:active{
    color: white;
    background-color: #242638;
  }
`;

const TableBody = styled.tbody``;

export default function TicketsDesctop({ tickets }) {
  return (
    <Table>
      <TableHeader>
        <HeadRow>
          <HeaderCell>Title</HeaderCell>
          <HeaderCell>Type</HeaderCell>
          <HeaderCell>User</HeaderCell>
          <HeaderCell>Last reply</HeaderCell>
          <HeaderCell></HeaderCell>
        </HeadRow>
      </TableHeader>
      <TableBody>
        {tickets.map((item) => {
          return (
            <TableRow>
              <UsualCell>{item.subject}</UsualCell>
              <UsualCell>{item.status}</UsualCell>
              <UsualCell>
                {item.messages[item.messages.length - 1].senderName}
              </UsualCell>
              <UsualCell>
                {dayjs(
                  item.messages[item.messages.length - 1].updatedAt
                ).format("MMM DD") +
                  ", " +
                  dayjs(
                    item.messages[item.messages.length - 1].updatedAt
                  ).format("YYYY")}
              </UsualCell>
              <UsualCell>
                <TicketLink to={"/account/ticket/" + item.id}>
                  View conversation
                </TicketLink>
              </UsualCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
