import React, { useState } from "react";
import styled from "styled-components";
import { Carousel as MoboleSlieder } from "react-responsive-carousel";
import Carousel from "react-multi-carousel";
import MediaQuery from "react-responsive";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { savePromoLocation, sendForm } from "../api/formsAPI";
import { Helmet } from "react-helmet";
import { appendScript } from "../components/VitaforestUI/Interface/Footer/ScriptAppender";
import DefaultButton from "../components/VitaforestUI/Interface/Buttons/DefaultButton";
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 900 },
    items: 4,
    paritialVisibilityGutter: 10,
  },
  tablet: {
    breakpoint: { max: 899, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 0,
    paritialVisibilityGutter: 0,
  },
};
const Wrapper = styled.div`
  .react-multi-carousel-track {
    gap: 60px;
    align-items: stretch;
  }
  .usage-slide__icon {
    max-width: 80px;
  }

  body {
    overflow-x: hidden;
  }
  label{
    display: block;
  }
  input {
    text-indent: 10px;
  }

  .slick-slider {
    position: relative;
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }

  .slick-list:focus {
    outline: none;
  }

  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .slick-track:before,
  .slick-track:after {
    display: table;
    content: "";
  }

  .slick-track:after {
    clear: both;
  }

  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
  }

  .popup .pform__btn{
    margin: auto;
  }

  [dir="rtl"] .slick-slide {
    float: right;
  }

  .slick-slide img {
    display: block;
    height: 57px;
  }

  .slick-slide.slick-loading img {
    display: none;
  }

  .slick-slide.dragging img {
    pointer-events: none;
  }

  .slick-initialized .slick-slide {
    display: block;
  }

  .slick-loading .slick-slide {
    visibility: hidden;
  }

  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }

  .slick-arrow.slick-hidden {
    display: none;
  }

  /* Slider */

  .slick-loading .slick-list {
    background: #fff url("./ajax-loader.gif") center center no-repeat;
  }

  /* Icons */

  /* Arrows */

  .slick-prev,
  .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
  }

  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent;
  }

  .slick-prev:hover:before,
  .slick-prev:focus:before,
  .slick-next:hover:before,
  .slick-next:focus:before {
    opacity: 1;
  }

  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25;
  }

  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    opacity: 0.75;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .slick-prev {
    left: 30px;
  }

  [dir="rtl"] .slick-prev {
    right: 30px;
    left: auto;
  }

  .slick-prev:before {
    content: url("/promo/slider-arrow_left.svg");
  }

  [dir="rtl"] .slick-prev:before {
    content: url("/promo/slider-arrow_right.svg");
  }

  .slick-next {
    right: 30px;
  }

  [dir="rtl"] .slick-next {
    right: auto;
    left: 30px;
  }

  .slick-next:before {
    content: url("/promo/slider-arrow_right.svg");
  }

  [dir="rtl"] .slick-next:before {
    content: url("/promo/slider-arrow_left.svg");
  }

  /* Dots */

  .slick-dots {
    position: absolute;
    bottom: 30px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
  }

  .slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
  }

  .slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
  }

  .head-star{
    font-size: 24px;
    color: #40bf6a;
  }

  .slick-dots li button:hover,
  .slick-dots li button:focus {
    outline: none;
  }

  .slick-dots li button:hover:before,
  .slick-dots li button:focus:before {
    opacity: 1;
  }

  .slick-dots li button:before {
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: url("/promo/slider-item.svg");
    text-align: center;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .slick-dots li.slick-active button:before {
    content: url("/promo/slider-item_active.svg");
    color: black;
  }

  /* Убираем внешние отступы */

  body,
  h1,
  h2,
  h3,
  h4,
  p,
  ul[class],
  ol[class],
  li,
  figure,
  figcaption,
  blockquote,
  dl,
  dd {
    margin: 0;
  }

  /* Выставляем основные настройки по-умолчанию для body */

  body {
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
  }

  /* Удаляем стандартную стилизацию для всех ul и il, у которых есть атрибут class*/

  ul[class],
  ol[class] {
    list-style: none;
  }

  /* Элементы a, у которых нет класса, сбрасываем до дефолтных стилей */

  a:not([class]) {
    -webkit-text-decoration-skip: ink;
    text-decoration-skip-ink: auto;
  }

  /* Упрощаем работу с изображениями */

  img {
    max-width: 100%;
    display: block;
  }

  /* Указываем понятную периодичность в потоке данных у article*/

  article > * + * {
    margin-top: 1em;
  }

  /* Указываем box sizing */

  *,
  *::before,
  *::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  /* Наследуем шрифты для инпутов и кнопок */

  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  /* Удаляем все анимации и переходы для людей, которые предпочитай их не использовать */

  body {
    font-family: "Proxima Nova";
  }

  .main-header {
    position: absolute;
    width: 100%;
    z-index: 20;
  }

  .main-header__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 17px 20px;
  }

  .main-header__logo {
    width: 130px;
    height: 35px;
  }

  .main-header__phone {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    text-decoration-line: none;
    color: #ffffff;
    position: relative;
  }

  .main-header__phone::before {
    content: url("/promo/phone.svg");
    position: absolute;
    top: 1px;
    left: -20px;
  }

  .container {
    margin: 0 auto;
    width: calc(100vw - 40px);
  }

  .main-content {
    background: #3c3f54;
  }

  @media screen and (min-width: 900px) {
    .main-content {
      background: linear-gradient(
        180deg,
        #848aa5 0%,
        #3b3f54 14.53%
      ) !important;
    }
  }

  .preview__image {
    display: block;
    margin: 0 auto;
    margin-left: -35px;
  }

  .preview__notice {
    position: relative;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
    width: auto;
    margin: 0 auto;
    margin-top: 20px;
    text-align: center;
    &:hover, &:active{
      cursor: pointer;
    }
    &:hover::after {
    content: 'Samples are delivered by DHL. Payment for delivery is made by the customer. The cost of delivery is calculated individually.'; /* Выводим текст */
    position: absolute; /* Абсолютное позиционирование */
    right: -5%;
    width: auto;
    word-wrap: wrap;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: justify;
    color: #303236;
    border-radius: 4px;
    bottom: -75px; /* Положение подсказки */
    z-index: 1; /* Отображаем подсказку поверх других элементов */
    background: #FFFFFF; /* Полупрозрачный цвет фона */
    font-family: Arial, sans-serif; /* Гарнитура шрифта */
    font-size: 11px; /* Размер текста подсказки */
    padding: 5px 10px; /* Поля */
   }
  }

  .preview__title {
    font-weight: bold;
    font-size: 30px;
    line-height: 105%;
    text-align: center;
    color: #ffffff;
    padding: 0px;
    margin-bottom: 25px;
  }

  .preview__btn {
    display: block;
    width: 181px;
    background: #40bf6a;
    border-radius: 4px;
    border: none;
    height: 48px;
    font-weight: 600;
    font-size: 20px;
    line-height: 17px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    color: #ffffff;
    padding-left: 73px;
    margin: 0 auto;
    margin-top: 20px;
    position: relative;
    z-index: 4;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .preview__btn::before {
    position: absolute;
    content: url("/promo/leaf.svg");
    left: 33px;
  }

  .mountain-element {
    background: url("/promo/mountain.svg");
    min-height: 54px;
    width: 100%;
    position: absolute;
    background-size: contain;
    top: -54px;
  }

  .mountain-element::before {
    content: url("/promo/Circles.svg");
    position: absolute;
    left: 20px;
    top: -5px;
    z-index: -1;
  }

  .mountain-element_white {
    background: url("/promo/mountain_white.svg");
    background-size: contain;
  }

  .mountain-element_white::before {
    content: none;
  }

  .mountain-element_last::before {
    content: none;
  }

  .elem-wrapper {
    position: relative;
  }

  .feature {
    background: #3c3f54;
    position: relative;
  }

  .feature__wrapper {
    width: 100vw;
    padding: 33px 20px 0px 20px;
    position: relative;
  }

  .feature__text {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
    padding-left: 92px;
    width: 280px;
    position: relative;
  }

  .feature__text::before {
    content: url("/promo/leafg.svg");
    position: absolute;
    top: -8px;
    left: 0px;
  }

  .slick-dots {
    bottom: -132px;
    z-index: 2;
  }

  .product {
    padding: 45px 20px 109px 20px;
    position: relative;
    z-index: 1;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 300px 170px;
  }

  .product__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-top: 45px;
  }

  .product__title {
    font-weight: bold;
    font-size: 25px;
    line-height: 36px;
    color: #ffffff;
    margin-top: 34px;
    text-align: center;
  }

  .product__text {
    font-size: 16px;
    line-height: 150%;
    text-align: justify;
    color: #ffffff;
    margin-top: 30px;
  }

  .advantages__title {
    font-weight: bold;
    font-size: 25px;
    line-height: 36px;
    text-align: center;
    color: #303236;
    padding-top: 60px;
    margin-bottom: 0;
  }

  .advantages__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 45px;
  }

  .advantage {
    background: #ffffff;
    -webkit-box-shadow: 0px 3px 13px rgba(36, 38, 56, 0.13),
      1px 3px 2px rgba(0, 0, 0, 0.06);
    box-shadow: 0px 3px 13px rgba(36, 38, 56, 0.13),
      1px 3px 2px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 42px 25px 35px 25px;
    margin-bottom: 30px;
    width: 100%;
  }

  .advantage__logo {
    display: block;
    margin: 0 auto;
    width: 60px;
    height: 60px;
  }

  .advantage__logo_cites {
    display: block;
    margin: 0 auto;
    width: 95px;
    height: 60px;
  }

  .advantage__title {
    font-size: 20px;
    line-height: 17px;
    letter-spacing: -0.01em;
    color: #000000;
    text-align: center;
    font-weight: 600;
    margin-top: 26px;
    font-weight: 600;
  }

  .advantage__description {
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    color: #848aa5;
    font-weight: 600;
    margin-top: 10px;
  }

  .advantage__text {
    font-size: 14px;
    line-height: 120%;
    text-align: justify;
    color: #7f878b;
    margin-top: 25px;
  }

  .documents {
    padding-top: 67px;
    padding-bottom: 67px;
    background-color: white;
  }

  .documents__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .document {
    padding-left: 50px;
    position: relative;
    width: 200px;
    margin: 0 auto;
    margin-bottom: 40px;
  }

  .document:before {
    position: absolute;
    left: 0;
  }

  .document_pdf:before {
    content: url("/promo/pdf.svg");
  }

  .document_docx:before {
    content: url("/promo/docx.svg");
  }

  .document__name {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    text-align: left;
  }

  .document__download {
    font-size: 16px;
    line-height: 120%;
    color: #18a0fb;
    text-decoration: none;
    position: relative;
  }

  .document__download::after {
    content: url("/promo/download.svg");
    position: absolute;
    bottom: 0;
    right: -18px;
  }

  .usage {
    background-color: white !important;
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0px 0px;
    padding-top: 60px;
    background: url("/promo/hilsbg.svg");
    padding-bottom: 70px;
  }

  .usage .slick-next:before {
    content: url("/promo/slide-next.svg");
  }

  .usage .slick-prev:before {
    content: url("/promo/slide-prev.svg");
  }

  .usage__title {
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    color: #303236;
    margin-bottom: 45px;
  }

  .usage-slide {
    background: #ffffff;
    -webkit-box-shadow: 0px 3px 13px rgba(36, 38, 56, 0.13),
      1px 3px 2px rgba(0, 0, 0, 0.06);
    box-shadow: 0px 3px 13px rgba(36, 38, 56, 0.13),
      1px 3px 2px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    margin-bottom: 30px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 33px 20px 35px 20px;
  }

  .usage-slide__icon {
    display: block;
    margin: 0 auto;
    text-align: center;
  }

  .usage-slide__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 17px;
    text-align: center;
    color: #000000;
    margin-top: 20px;
    margin-bottom: 25px;
  }

  .usage-slide__list {
    font-size: 14px;
    line-height: 120%;
    color: #7f878b;
    text-indent: 0.6em;
  }

  .usage-slide__list li {
    position: relative;
  }

  .usage-slide__list li::before {
    content: "";
    width: 4px;
    height: 4px;
    background-color: #40bf6a;
    border-radius: 50%;
    position: absolute;
    top: 7px;
    left: 0px;
  }

  .questions {
    background: #3c3f54;
    background-size: cover;
    color: #ffffff;
  }

  .questions__title {
    font-weight: bold;
    font-size: 25px;
    line-height: 36px;
    text-align: center;
    padding-top: 68px;
  }

  .questions__description {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 10px;
  }

  .questions__form {
    width: 100%;
  }

  .form .required {
    position: relative;
  }

  .form .required:after {
    content: "*";
    color: red;
    padding-left: 5px;
  }

  .form__input {
    width: 100%;
    background: #ffffff;
    border: 1px solid #eaebec;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 4px;
    min-height: 48px;
  }

  .form label {
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    margin-bottom: 15px;
    display: block;
  }


  .form__btn {
    width: 100%;
    font-weight: 600;
    font-size: 20px;
    line-height: 17px;
    color: #ffffff;
    border: none;
    height: 48px;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: #40bf6a;
    border-radius: 4px;
  }

  .form textarea {
    min-height: 84px;
  }

  .main-footer {
    background: #242638;
    margin-top: -20px;
    position: relative;
  }

  .copyright {
    text-align: center;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #848aa5;
    padding: 17px;
  }

  section.slider .slick-list {
    height: 380px;
  }

  .usage__slider .slick-list {
    height: 480px !important;
  }

  .popup {
    display: none;
    position: fixed;
    -webkit-box-shadow: 0px 4px 14px rgba(26, 33, 45, 0.1);
    box-shadow: 0px 4px 14px rgba(26, 33, 45, 0.1);
    border-radius: 4px;
    z-index: 100;
    background: #ffffff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 25px 18px;
    width: 96vw;
    left: 2vw;
    min-height: 96vh;
    top: 2vh;
  }

  .popup_active {
    display: block;
  }

  .popup__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #303236;
    margin-right: 30px;
    padding-bottom: 10px;
    border-bottom: 1px solid #303236;
    margin-bottom: 15px;
  }

  .popup__close {
    position: absolute;
    border: none;
    background: none;
    right: 18px;
  }

  .pform__title {
    font-size: 14px;
    line-height: 17px;
    color: #303236;
    margin-bottom: 5px;
    display: block;
  }

  .pform__input {
    height: 48px;
    width: 100%;
    border: 1px solid #eaebec;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 4px;
    color: #7f878b;
    margin-bottom: 5px;
  }

  .pform__btn {
    width: 274px;
    margin: 0 auto;
    margin-top: 15px;
    font-weight: 600;
    font-size: 20px;
    line-height: 17px;
    color: #ffffff;
    border: none;
    height: 48px;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: #40bf6a;
    border-radius: 4px;
  }

  .pform textarea {
    min-height: 84px;
  }

  .btn.slide__btn:hover {
    background-color: #61d186;
  }

  .btn.slide__btn:active {
    background-color: #2e9e53;
  }

  .bglayer {
    opacity: 0.6;
    background: #000000;
    height: 100vh;
    width: 100vw;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 6;
    display: none;
  }

  .bglayer_active {
    transition: opacity 0.3s 0s ease;
    display: block;
  }

  .advantage:hover {
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
  }

  .advantage {
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  .document__download:hover {
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
  }

  .usage-slide {
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  .usage-slide:hover {
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
  }

  .pform__btn:hover {
    background-color: #61d186;
  }

  .pform__btn:active {
    background-color: #2e9e53;
  }

  @media screen and (min-width: 900px) {
    .container {
      width: 1080px;
    }

    .preview__wrapper {
      padding-top: 150px;
      z-index: 2;
      position: relative;
    }

    .preview__image {
      position: absolute;
      margin-left: -50px;
    }

    .preview__notice {
      width: 265px;
      margin-top: 0;
      font-size: 18px;
      line-height: 22px;
    }

    .preview__title {
      font-weight: bold;
      font-size: 60px;
      line-height: 105%;
      width: 622px;
      margin-left: auto;
      text-align: right;
      color: #ffffff;
    }

    .preview__btn {
      width: 181px;
      height: 60px;
      margin: 0;
      margin-left: auto;
    }

    .mountain-element::before {
      content: url("/promo/Circles-desk.svg");
      left: 100px;
      top: 20px;
    }

    .mountain-element_white::before {
      content: none;
    }

    .mountain-element_last::before {
      content: none;
    }

    .mountain-element {
      background: url("/promo/mountain-desk.svg");
      min-height: 216px;
      top: -214px;
    }

    .mountain-element_white {
      background: url("/promo/mountain_white-desk.svg");
    }

    .feature__wrapper {
      width: 348px;
      padding: 0px;
      position: absolute;
      left: 191px;
      top: -70px;
    }

    .feature__text {
      width: 363px;
    }

    .slick-dots {
      bottom: -40px;
    }

    .product {
      padding: 123px 100px 247px 100px;
      background-size: 565px 324px;
      background-position: right 100px bottom 80px;
    }

    .product__content {
      margin-left: 67px;
    }

    .product__wrapper {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
    }

    .product__title {
      text-align: left;
      margin-top: 0;
      font-size: 38px;
    }

    .advantages {
      background: url("/promo//bg_left.svg") left no-repeat,
        url("/promo/bg_right.svg") right no-repeat;
      background-color: white;
    }

    .advantages__title {
      font-size: 38px;
    }

    .advantages__list {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      margin-top: 75px;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-evenly;
    }

    .advantage {
      width: 29%;
      min-height: 400px;
    }

    .documents__list {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }

    .usage {
      padding: 0px 0px 0px 50px;
      padding-bottom: 216px;
      margin: 0px 0px;
    }

    .usage__title {
      font-size: 38px;
      line-height: 36px;
      margin-bottom: 100px;
    }

    .usage-slide {
      width: 300px !important;
      min-height: 447px;
      margin-right: 50px;
    }

    .questions {
      background: url("/promo/hilsbg-dark.svg"),
        -webkit-gradient(linear, left top, right top, from(#3c3f54), to(#3c3f54));
      background: url("/promo/hilsbg-dark.svg"),
        -o-linear-gradient(left, #3c3f54, #3c3f54);
      background: url("/promo/hilsbg-dark.svg"),
        linear-gradient(to right, #3c3f54, #3c3f54);
    }

    .questions__title {
      font-size: 38px;
      text-align: left;
      padding-top: 38px;
      margin-bottom: 20px;
    }

    .questions__description {
      text-align: left;
      margin-bottom: 30px;
    }

    .questions__form {
      width: 530px;
    }

    .form {
      width: 530px;
    }

    .form .wpcf7 {
      width: 530px;
    }

    .form .wpcf7-form {
      display: -webkit-box;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .form p {
      width: 100%;
    }

    .form p:nth-child(2),
    .form p:nth-child(3),
    .form p:nth-child(4),
    .form p:nth-child(5) {
      width: 255px;
    }

    .form {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }

    .form__btn {
      width: 255px;
    }

    .popup {
      width: 552px;
      min-height: 540px;
      top: calc(50% - 270px);
      left: calc(50% - 276px);
    }

    .popup__title {
      font-weight: 600;
      font-size: 20px;
      line-height: 17px;
      margin-bottom: 30px;
    }

    .pform__btn {
      margin-left: 0px;
      margin-top: 0px;
    }
  }

  @media screen and (min-width: 900px) {
    .slick-list {
      min-height: 640px !important;
    }

    .slick-list .slide {
      min-height: 640px !important;
    }

    .slide__content {
      margin: 0 197px;
    }
  }

  @media (prefers-reduced-motion: reduce) {
    * {
      -webkit-animation-duration: 0.01ms !important;
      animation-duration: 0.01ms !important;
      -webkit-animation-iteration-count: 1 !important;
      animation-iteration-count: 1 !important;
      -webkit-transition-duration: 0.01ms !important;
      -o-transition-duration: 0.01ms !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }

  @media screen and (min-wdth: 1100px) {
    section.slider .slick-list {
      height: 640px !important;
    }

    .pform__title {
      margin-bottom: 10px;
    }
  }

  .questions__wrapper {
    display: flex;
    flex-direction: column;
  }

  @media screen and (min-width: 900px) {
    .questions__wrapper {
      display: flex;
      flex-direction: row;
    }
  }

  .about-company {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .company {
    margin-bottom: 30px;
  }

  .footer-logo {
    margin-bottom: 20px;
    width: 180px;
  }

  @media screen and (min-width: 900px) {
    .preview {
      margin-bottom: 100px;
    }

    .pform__input_comment {
      height: 84px;
      width: 100%;
      border: 1px solid #eaebec;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border-radius: 4px;
      margin-bottom: 15px;
    }

    .slick-prev {
      z-index: 8;
    }

    @media screen and(min-width:1100px) {
      .slick-prev,
      .slick-next {
        top: 20%;
      }
    }

    .usage .slick-prev {
      left: -56px;
    }

    .usage .slick-prev,
    .usage .slick-next {
      top: 30% !important;
    }

    .documents__title {
      font-size: 38px !important;
      line-height: 36px !important;
    }

    .questions__wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: row;
    }

    .about-company {
      display: flex;
      flex-direction: row;
      margin-top: -107px;
      flex-wrap: wrap;
      margin-left: 71px;
    }

    div.company {
      margin-left: 40px;
      margin-bottom: 0px;
    }

    .documents .container {
      margin-top: 78px;
    }
  }

  .documents__title {
    margin-bottom: 40px;
    font-weight: bold;
    font-size: 25px;
    line-height: 36px;
    text-align: center;
    color: #303236;
    padding-top: 60px;
  }

  .product__image {
    margin: 0 auto;
  }

  .popup__close img {
    width: 100%;
  }

  .contacts {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .company {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }

  .footer-logo {
    margin-bottom: 77px;
    width: 180px;
  }

  .questions {
    background-color: #3a3f54;
    background: url("/promo/hilsbg-dark.svg");
    background: url("/promo/hilsbg-dark.svg");
    background: url("/promo/hilsbg-dark.svg");
  }

  .advantages {
    background-color: white;
  }

  @media screen and (min-width: 900px) {
    .documents__title {
      font-size: 38px !important;
      line-height: 36px !important;
    }

    .documents .container {
      margin-top: 78px;
    }

    .preview__row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 499px;
      margin-left: auto;
      flex-direction: row-reverse;
    }

    .preview__btn {
      margin-left: 0px;
    }

    .preview__notice {
      margin-left: 30px;
    }
  }

  .follow-us__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 17px;
    color: #ffffff;
  }

  .follow-us__links {
    margin-top: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-bottom: 30px;
  }

  .company__title,
  .contacts__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 17px;
    margin-bottom: 20px;
    color: #ffffff;
  }

  .follow-us__links a {
    padding-right: 10px;
  }

  .footer-logo {
    display: block;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 45px;
  }

  @media screen and (min-width: 900px) {
    .follow-us {
      width: 100%;
      margin-top: 65px;
    }

    .logo-wrapper {
      width: 100%;
      margin-bottom: 68px;
      padding-top: 18px;
    }

    .footer-logo {
      width: 259px;
      margin: 0px;
    }

    .company__title,
    .contacts__title {
      margin-bottom: 20px;
    }

    .form__title {
      margin-bottom: -8px;
      display: block;
    }
  }

  @media screen and (min-width: 900px) {
    .questions__wrapper .form {
      margin-bottom: 50px;
    }

    .form__phone,
    .form__name {
      margin-bottom: 0px;
    }

    .form__comment {
      margin-top: -20px;
    }
  }

  .preview__btn.btn:hover {
    background-color: #61d186;
  }

  .preview__btn.btn:active {
    background-color: #2e9e53;
  }

  .required:after {
    content: "*";
    color: red;
    padding: 8px;
  }

  .phone-number {
    color: #18a0fb;
    text-decoration-line: none;
  }

  .phone-number:hover {
    text-decoration-line: underline;
  }

  .pform__input_comment {
    width: 100%;
  }

  .follow-us__link:hover rect {
    fill: #40bf6a;
  }

  .follow-us__link:hover path {
    fill: white;
  }

  .product__wrapper img {
    height: 100%;
  }

  .noti_active {
    background-color: #61d186;
    color: white;
    margin-top: -20px;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 5px;
  }

  .container .wpcf7-response-output {
    display: none;
    background-color: #61d186;
    color: white;
    margin-top: -20px;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 5px;
  }

  .main-header__phone:hover {
    text-decoration-line: underline;
  }

  .footer-popup_active {
    display: block !important;
  }

  .footer-popup {
    background-color: #fff;
    display: none;
    position: fixed;
    z-index: 9999999999999;
    width: 90%;
    box-sizing: border-box;
    padding: 30px 50px;
    right: 570px !important;
    left: 5%;
    bottom: 22px;
    border: 1px solid #40bf6a;
    box-shadow: 0px 3px 13px rgba(36, 38, 56, 0.13),
      1px 3px 2px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
  }

  .footer-popup__title {
    font-weight: 600;
    line-height: 17px;
    color: #303236;
    margin-bottom: 20px;
    box-shadow: 0px 1px 0px #303236;
    padding-bottom: 10px;
    font-size: 20px;
    line-height: 17px;
  }

  .footer-popup .container {
    width: 100%;
  }

  .footer-popup__text {
    width: 95%;
    font-size: 14px;
    line-height: 17px;
    text-align: justify;
    color: #303236;
    margin-bottom: 20px;
  }

  .footer-popup__btn {
    background: #40bf6a;
    border-radius: 4px;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #ffffff;
    height: 40px;
    width: 90%;
    border: none;
    display: block;
    margin: 0 auto;
  }

  @media screen and (min-width: 1128px) {
    .footer-popup {
      background-color: #fff;
      display: none;
      position: fixed;
      z-index: 80;
      width: 442px;
      box-sizing: border-box;
      padding: 30px 50px;
      right: calc(50% - 250px) !important;
      left: auto;
      bottom: 22px;
      border: 1px solid #40bf6a;
      box-shadow: 0px 3px 13px rgba(36, 38, 56, 0.13),
        1px 3px 2px rgba(0, 0, 0, 0.06);
      border-radius: 4px;
    }

    .footer-popup__title {
      font-weight: 600;
      line-height: 17px;
      color: #303236;
      margin-bottom: 20px;
      box-shadow: 0px 1px 0px #303236;
      padding-bottom: 10px;
      font-size: 20px;
      line-height: 17px;
    }

    .footer-popup .container {
      width: 342px;
    }

    .footer-popup__text {
      width: 342px;
      font-size: 14px;
      line-height: 17px;
      text-align: justify;
      color: #303236;
      margin-bottom: 20px;
    }

    .footer-popup__btn {
      background: #40bf6a;
      border-radius: 4px;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      color: #ffffff;
      height: 40px;
      width: 255px;
      border: none;
      display: block;
      margin: 0 auto;
    }

    .footer-popup .container {
      width: 342px;
    }
  }

  .files-preview {
    display: none;
  }

  .files-preview img {
    width: 1200px;
    height: 1600px;
  }

  .files-preview_active {
    display: block;
    position: fixed;
    width: 1200;
    min-height: 540px;
    top: calc(50% - 383px);
    left: calc(50% - 600px);
    z-index: 50;
  }

  .files-preview_active img {
    border-radius: 10px;
  }

  .popup_docs {
    box-shadow: 0px 4px 14px rgb(26 33 45 / 30%);
  }

  @media screen and (max-width: 1100px) {
    .files-preview_active {
      display: none !important;
    }
  }

  .usage-slide a {
    text-decoration-line: none;
  }

  .rc-anchor.rc-anchor-invisible.rc-anchor-light {
    display: none !important;
  }

  .grecaptcha-badge {
    display: none !important;
  }

  .bx-livechat-control-btn.bx-livechat-control-btn-menu {
    display: none;
  }

  .whatsapp-button {
    background-color: #00bb0a !important;
    background-image: url("/whatsapp-icon.svg") !important;
    position: fixed !important;
    bottom: 120px !important;
    right: 50px !important;
    z-index: 1000 !important;
  }

  .notify1 {
    display: none !important;
  }

  .documents__list.document__list-about{
    margin-top: 50px;
    width: auto;
    white-space: nowrap;
    .document_pdf-about::before{
      top: -5px;
      content: url('/promo/pdf-white.svg');
    }
    h4{
      color: white;
    }
  }

  .head-star{
    font-size: 24px;
    color: #40bf6a;
  }
`;

const RequestContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
margin-top: 50px;
`
const RequestHead = styled.h4`
font-weight: 600;
font-size: 16px;
line-height: 19px;
color: #000000;
text-align: left;
color: white;
`
const ActionsContainer = styled.div`
display: flex;
justify-content: space-between;
width: auto;
flex-direction: column;
align-items: center;
`
const ButtonRequest = styled(DefaultButton)`
height: 50px;
margin-top: 15px;
`

const Input = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 10px 16px 20px;
  gap: 10px;
  width: 300px;
  margin-top: 10px;
  height: 50px;
  background: white;
  border: 1px solid #eaebec;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #303236;
  border-radius: 4px;
  outline: none;
  &:focus {
    border: 1px solid #bbbbbb;
  }
  &:hover {
    border: 1px solid #bbbbbb;
  }
  &:focus-visible {
    border: 1px solid #7f878b;
  }
  &:active {
    border: 1px solid #bbbbbb;
  }
  &:not(:placeholder-shown) {
    &:invalid {
      border: 2px solid #ef5e53;
    }
  }
`

const RhodiolaPromo = () => {

  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [message, setMessage] = useState(null);
  const [company, setCompany] = useState(null);
  const [requestPres, setRequestPres] = useState(false);
  const [doc, setDoc] = useState(null);
  const [from, setFrom] = useState('Rhodiola promo');

  savePromoLocation(from);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const sendPresentation = () => {
    if (!isValidEmail(email)) {
      NotificationManager.error('Email is invalid', 'Error');
    }
    else {
      let formdata = new FormData();
      formdata.append('email', email);
      formdata.append('presentation_request', true);
      formdata.append('name', email);
      formdata.append('Rhodiola promo (Presentation Request)');
      sendForm(formdata).then((data) => {
        if (data.id) {
          NotificationManager.success('Thank you! The presentation will arrive at the email address you provided.', 'Success')
          setRequestPres(false);
          setTimeout(() => {
            window.location.reload();
          }, 2000)
        }
        else {
          NotificationManager.success(data.message, 'Error')
        }
      })
    }
  }

  const send = () => {
    if (email && name && company) {
      if (!isValidEmail(email)) {
        NotificationManager.error('Email is invalid', 'Error');
      }
      else {
        let formdata = new FormData();
        formdata.append('name', `Name: ${name}, Company: ${company}`);
        formdata.append('email', email);
        formdata.append('phone', phone);
        formdata.append('from', from);
        if (doc) {
          formdata.append('message', `${doc}, Message: ${message}`);
        }
        else {
          formdata.append('message', message);
        }
        sendForm(formdata).then((data) => {
          if (data.id) {
            NotificationManager.success('Your message sended!', 'Success')
            setTimeout(() => {
              window.location.reload();
            }, 2000)
          }
          else {
            NotificationManager.success(data.message, 'Error')
          }
        })
      }
    }
    else {
      NotificationManager.warning('Fill all required fields', 'Warning')
    }
  }

  const [isPopupSlOpen, setIsPopupSlOpen] = useState(false);
  const [docsPopupOpen, setDocsPopupOpen] = useState(false);

  return (
    <Wrapper>
      <Helmet>
        <title>Rhodiola Rosea - Promo - Vitaforest</title>
        <meta name="description" content={'Vitaforest rhodiola rosea promo'} />
      </Helmet>
      <NotificationContainer />
      <div>
        <div />
        <div>
          <div>
            <div
              data-b24-crm-button-shadow
              className="b24-widget-button-shadow"
            />
            <div style={{ display: "none" }}>
              <a
                data-b24-crm-button-widget-blank
                data-b24-crm-button-widget
                className="b24-widget-button-social-item"
                title
              >
                <i />
                <span
                  data-b24-crm-button-tooltip
                  className="b24-widget-button-social-tooltip"
                />
              </a>
            </div>
          </div>
        </div>
        <div
          className={
            isPopupSlOpen || docsPopupOpen
              ? "bglayer bglayer_active"
              : "bglayer"
          }
          onClick={() => {
            setIsPopupSlOpen(false);
            setDocsPopupOpen(false);
          }}
        />
        <header className="main-header">
          <div className="main-header__row container">
            <a href="/">
              <img
                src="/logo-white.svg"
                alt="Website logo"
                className="main-header__logo"
              />
            </a>
            <a href="tel:+3728801043" className="main-header__phone">
              +3728801043
            </a>
          </div>
        </header>
        <main className="main-content">
          <div
            className={
              isPopupSlOpen ? "popup popup_sl popup_active" : "popup popup_sl"
            }
          >
            <button
              className="popup__close popup_sl__close"
              onClick={() => { setIsPopupSlOpen(false); setFrom('Rhodiola Promo'); }}
            >
              <img src="/close.svg" alt="" />
            </button>
            <h3 className="popup__title">
              Leave your contact details, we will contact you
            </h3>
            <div className="popup__noti"></div>
            <div
              role="form"
              className="wpcf7"
              id="wpcf7-f16030-o1"
              lang="en-US"
              dir="ltr"
            >
              <div className="screen-reader-response">
                <p role="status" aria-live="polite" aria-atomic="true" /> <ul />
              </div>
              <form
                action="/en/chaga-promo/#wpcf7-f16030-o1"
                method="post"
                className="wpcf7-form init header-form"
                noValidate="novalidate"
                data-status="init"
              >
                <p>
                  <label className="pform__name">
                    <span className="pform__title required">Name</span>
                    <span className="wpcf7-form-control-wrap your-name">
                      <input
                        type="text"
                        name="your-name"
                        size={40}
                        onChange={(e) => { setName(e.target.value) }}
                        className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required pform__input"
                        placeholder="Please write your full name"
                      />
                    </span>
                  </label>
                  <br />
                  <label className="pform__mail">
                    <span className="pform__title required">Email</span>
                    <span className="wpcf7-form-control-wrap your-email">
                      <input
                        type="email"
                        name="your-email"
                        size={40}
                        onChange={(e) => { setEmail(e.target.value) }}
                        className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email pform__input"
                        placeholder="Your email"
                      />
                    </span>{" "}
                  </label>
                  <br />
                  <label className="pform__phone">
                    <span className="pform__title">Phone number</span>
                    <span className="wpcf7-form-control-wrap phone">
                      <input
                        type="tel"
                        name="phone"
                        size={40}
                        onChange={(e) => { setPhone(e.target.value) }}
                        className="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-tel pform__input"
                        aria-invalid="false"
                        placeholder="+ 4 234 234564"
                      />
                    </span>
                  </label>
                  <br />
                  <label className="pform__name">
                    <span className="pform__title required">Company name</span>
                    <span className="wpcf7-form-control-wrap company">
                      <input
                        type="text"
                        onChange={(e) => { setCompany(e.target.value) }}
                        name="company"
                        size={40}
                        className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required pform__input"
                        placeholder="Company name"
                      />
                    </span>
                  </label>
                  <label className="pform__comment">
                    <span className="pform__title">Additional information</span>
                    <span className="wpcf7-form-control-wrap your-message">
                      <textarea
                        name="your-message"
                        onChange={(e) => { setMessage(e.target.value) }}
                        cols={40}
                        rows={10}
                        className="wpcf7-form-control wpcf7-textarea pform__input_comment"
                        aria-invalid="false"
                      />
                    </span>{" "}
                  </label>
                </p>
                <p>
                  <button
                    className="wpcf7-form-control wpcf7-submit pform__btn chaga-header"
                    onClick={(e) => { e.preventDefault(); send() }}>Send</button>
                  <span className="ajax-loader" />
                </p>
                <div className="wpcf7-response-output" aria-hidden="true" />
              </form>
            </div>{" "}
          </div>
          <div
            className={
              docsPopupOpen
                ? "popup popup_docs popup_active"
                : "popup popup_docs"
            }
          >
            <button
              className="popup__close popup_docs__close"
              onClick={() => { setDocsPopupOpen(false); setFrom('Rhodiola Promo'); }}
            >
              <img src="/close.svg" alt="" />
            </button>
            <h3 className="popup__title">
              Leave your contacts to learn more about the product
            </h3>
            <div className="popup__noti_files"></div>
            <div
              role="form"
              className="wpcf7"
              id="wpcf7-f16031-o2"
              lang="en-US"
              dir="ltr"
            >
              <div className="screen-reader-response">
                <p role="status" aria-live="polite" aria-atomic="true" /> <ul />
              </div>
              <form
                action="/en/chaga-promo/#wpcf7-f16031-o2"
                method="post"
                className="wpcf7-form init files-form"
                noValidate="novalidate"
                data-status="init"
              >
                <div style={{ display: "none" }}>
                  <input type="hidden" name="_wpcf7" defaultValue={16031} />
                  <input
                    type="hidden"
                    name="_wpcf7_version"
                    defaultValue="5.4.2"
                  />
                  <input
                    type="hidden"
                    name="_wpcf7_locale"
                    defaultValue="en_US"
                  />
                  <input
                    type="hidden"
                    name="_wpcf7_unit_tag"
                    defaultValue="wpcf7-f16031-o2"
                  />
                  <input
                    type="hidden"
                    name="_wpcf7_container_post"
                    defaultValue={0}
                  />
                  <input
                    type="hidden"
                    name="_wpcf7_posted_data_hash"
                    defaultValue
                  />
                  <input
                    type="hidden"
                    name="_wpcf7_recaptcha_response"
                    defaultValue="03AFY_a8Uu2GPc97VmFkbd2F97DxQFw7sIw3TyXclE92mqS_3wG3tuQtLF5pz7ScSvKwToCwsN-PEbgOQChubFw32HoYSr0ynf9hiZbdhzkgUh0t5cUeU5sYYlHaAGxWuQi-DHd7s__fBudEh-pERlKs8ZSlEaNyNX4dO1EUdBjKFl9-gIoXt2ep8p5O3E3KHb04jXRsfyYyciQh6pUzU2pagZoeDbHl4zIYg2wFANB2Rg0mCGlnR4-EKywZYWmd8nE349-nX1BdW3g3gv113eYDq-o5RAP1c591ImtDhQ91q_Yg3_vHsv7srnTz9tVnEXy4dkkd9zp34PQI1FYJG048nBOnrknitAdZiAK_GV64ysdCAfmk-Dw38Y0T6MDiEP_38SVaTBf9umUT9ICuUu8O13IHM7s5KJ-x2a0WOsqrl-KISbWl4kaD6iV0CWVqQtZBUAzuCZWV4hsv6qYMkWNIRRIfIF1lHmuOiyuGmfaMDFW0XxNoibstG3mXrnqDXB6361FxWcrsiUE934WNBXfVOv_pgQHdKtYw"
                  />
                </div>
                <p>
                  <label className="pform__name">
                    <span className="pform__title required">Name</span>
                    <span className="wpcf7-form-control-wrap your-name">
                      <input
                        onChange={(e) => { setName(e.target.value) }}
                        type="text"
                        name="your-name"
                        size={40}
                        className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required pform__input"
                        placeholder="Please write your full name"
                      />
                    </span>
                  </label>
                  <br />
                  <label className="pform__mail">
                    <span className="pform__title required">Email</span>
                    <span className="wpcf7-form-control-wrap your-email">
                      <input
                        onChange={(e) => { setEmail(e.target.value) }}
                        type="email"
                        name="your-email"
                        size={40}
                        className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email pform__input"
                        placeholder="Your email"
                      />
                    </span>{" "}
                  </label>
                  <br />
                  <label className="pform__phone">
                    <span className="pform__title">Phone number</span>
                    <span className="wpcf7-form-control-wrap phone">
                      <input
                        type="tel"
                        onChange={(e) => { setPhone(e.target.value) }}
                        name="phone"
                        size={40}
                        className="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-tel pform__input"
                        aria-invalid="false"
                        placeholder="+ 4 234 234564"
                      />
                    </span>
                  </label>
                  <br />
                  <label className="pform__name">
                    <span className="pform__title required">Company name</span>
                    <span className="wpcf7-form-control-wrap company">
                      <input
                        onChange={(e) => { setCompany(e.target.value) }}
                        type="text"
                        name="company"
                        size={40}
                        className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required pform__input"
                        placeholder="Company name"
                      />
                    </span>
                  </label>
                  <label className="pform__comment">
                    <span className="pform__title">Additional information</span>
                    <span className="wpcf7-form-control-wrap your-message">
                      <textarea
                        name="your-message"
                        onChange={(e) => { setMessage(e.target.value) }}
                        cols={40}
                        rows={10}
                        className="wpcf7-form-control wpcf7-textarea pform__input_comment"
                        aria-invalid="false"
                      />
                    </span>{" "}
                  </label>
                </p>
                <p>
                  <button
                    className="wpcf7-form-control wpcf7-submit pform__btn chaga-header"
                    onClick={(e) => { e.preventDefault(); send() }}>Send</button>
                  <span className="ajax-loader" />
                  <span className="ajax-loader" />
                </p>
                <div className="wpcf7-response-output" aria-hidden="true" />
              </form>
            </div>{" "}
          </div>
          <div className={`files-preview ${docsPopupOpen ? 'files-preview_active' : null}`}>
            <img src="/file.png" />
          </div>
          <div className="preview">
            <div className="container">
              <picture>
                <source
                  media="(max-width: 1099px)"
                  srcSet="/rhodiola-mobile.png"
                />
                <source
                  media="(min-width: 1100px)"
                  srcSet="/rhodiola-land.png"
                />
                <img
                  src="/rhodiola-land.png"
                  alt="#"
                  className="preview__image"
                />
              </picture>
              <div className="preview__wrapper">
                <h2 className="preview__title">
                  Standardized Rhodiola rosea dry extracts in bulk for business{" "}
                </h2>
                <div className="preview__row">
                  <button
                    className="preview__btn btn"
                    onClick={() => { setIsPopupSlOpen(true); setFrom('Rhodiola Promo') }}
                  >
                    Get now
                  </button>
                  <p className="preview__notice">
                    Get your free samples! <br />Leave a request <span className="head-star">*</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <section className="product">
            <div className="container">
              <div className="product__wrapper">
                <img
                  src="/rhodiola-product-land.png"
                  alt=""
                  className="product__image"
                />
                <div className="product__content">
                  <h2 className="product__title">VITAFOREST Rhodiola Rosea extracts</h2>
                  <h4 style={{ color: 'white' }}>Grown naturally with care for wildlife</h4>
                  <p className="product__text">
                    We produce and distribute rosavins-based Rhodiola rosea L. dry powder extracts of the following types:
                    <ul>
                      <li>Rhodiola rosea dry extract 3 % rosavins 1 % salidroside</li>
                      <li>Rhodiola rosea dry extract 5 % rosavins 1 % salidroside</li>
                      <li>Rhodiola rosea rosavins-based custom solution</li>
                    </ul>
                    Our products meet the requirements of the Convention on International Trade in Endangered Species of Wild Fauna and Flora <span style={{ fontWeight: 800 }}>(CITES)</span> and have the appropriate permits. Our company cares about the preservation of rare species of flora and takes a high level of responsibility for compliance control.<br />
                    The ingredients are produced from genuine Rhodiola rosea L. raw materials to match the expectations of the most demanding buyers. Adaptogenic and anti-stress properties are inherently granted. If you are new to it please check it out by requesting promo materials down below.
                  </p>
                </div>
              </div>
            </div>
            {!requestPres ? <ul className="documents__list document__list-about">
              <li className="documents__item document document_pdf-about">
                <h4 className="document__name">Rhodiola Rosea presentation</h4>
                <a
                  className="document__download"
                  onClick={() => { setRequestPres(true) }}
                >
                  Download
                </a>
              </li>
            </ul> : <RequestContainer><RequestHead>Please enter your email address and we will send an email with the presentation</RequestHead><ActionsContainer><Input type={'email'} placeholder={'Enter your email address'} onChange={(e) => { setEmail(e.target.value) }} /><ButtonRequest title={'Request'} onClick={() => { sendPresentation() }} /></ActionsContainer></RequestContainer>}
          </section>
          <div className="elem-wrapper elem-wrapper_white">
            <section className="mountain-element mountain-element_white" />
          </div>
          <section className="advantages">
            <h3 className="advantages__title">Our advantages</h3>
            <div className="advantages__list container">
              <div className="advantages__item advantage">
                <img src="/clock.svg" alt="" className="advantage__logo" />
                <h4 className="advantage__title">We work fast</h4>
                <p className="advantage__description">
                  Shipment with in 2 days
                </p>
                <p className="advantage__text">
                  Our warehouse is located in Tallinn, Estonia which allows us
                  to deliver in the shortest possible time.
                </p>
              </div>
              <div
                className="advantages__item advantage"
                id="documents-list-anchor"
              >
                <img
                  src="/european.svg"
                  alt="Standarts image"
                  className="advantage__logo"
                />
                <h4 className="advantage__title">European standards</h4>
                <p className="advantage__description">
                  Bulk ingredients that comply with the EU regulations
                </p>
                <p className="advantage__text">
                  Product quality and safety measurements are performed in
                  European third-party labs. The products do not contain
                  ethylene oxides in accordance with Regulation (EC) No
                  396/2005.
                </p>
              </div>
              <div
                className="advantages__item advantage"
                id="documents-list-anchor"
              >
                <img
                  src="/cites_logo.svg"
                  alt="CITES logo"
                  className="advantage__logo_cites"
                />
                <h4 className="advantage__title">CITES compliant</h4>
                <p className="advantage__description">
                  Grown in the wild. CITES Compliant.
                </p>
                <p className="advantage__text">
                  Our products meet the requirements of the Convention on International Trade in Endangered Species of Wild Fauna and Flora (CITES) and have the appropriate permits. Our company cares about the preservation of rare species of flora and takes a high level of responsibility for compliance control.
                </p>
              </div>
            </div>
          </section>
          <section className="documents">
            <h3 className="documents__title">Learn more about our products</h3>
            <div className="container">
              <ul className="documents__list">
                <li className="documents__item document document_pdf">
                  <h4 className="document__name">Rhodiola TDS</h4>
                  <a
                    className="document__download"
                    onClick={() => { setDocsPopupOpen(true); setDoc('Document: Rhodiola TDS'); setFrom('Rhodiola Promo'); }}
                  >
                    Download
                  </a>
                </li>
                <li className="documents__item document document_docx">
                  <h4 className="document__name">Rhodiola SDS</h4>
                  <a
                    className="document__download"
                    onClick={() => { setDocsPopupOpen(true); setDoc('Document: Rhodiola SDS'); setFrom('Rhodiola Promo'); }}
                  >
                    Download
                  </a>
                </li>
                <li className="documents__item document document_pdf">
                  <h4 className="document__name">Rhodiola COA</h4>
                  <a
                    className="document__download"
                    onClick={() => { setDocsPopupOpen(true); setDoc('Document: Rhodiola COA'); setFrom('Rhodiola Promo'); }}
                  >
                    Download
                  </a>
                </li>
              </ul>
            </div>
          </section>
          <section className="usage">
            <h3 className="usage__title">
              Rhodiola Rosea will be interested to manufacturers of
            </h3>
            <div className="usage__slider">
              <MediaQuery maxWidth={900}>
                <div className="usage__slide usage-slide">
                  <a href="/shop/industry-food-supplements/" tabIndex={0}>
                    <img
                      src="/supplements.svg"
                      alt=""
                      className="usage-slide__icon"
                    />
                    <h4 className="usage-slide__title">Food supplements</h4>
                    <ul className="usage-slide__list">
                      <li>Dietary supplements</li>
                      <li>Jelly</li>
                      <li>Dietary (diabetic) confectionery</li>
                      <li>Chocolate</li>
                      <li>Cakes</li>
                      <li>Energy bars</li>
                      <li>Chewing gum</li>
                    </ul>
                  </a>
                </div>
                <div className="usage__slide usage-slide">
                  <a href="/shop/industry-cosmetics/" tabIndex={-1}>
                    <img
                      src="/cosmetics.svg"
                      alt=""
                      className="usage-slide__icon"
                    />
                    <h4 className="usage-slide__title">Cosmetics</h4>
                    <ul className="usage-slide__list">
                      <li>
                        Natural and environment-friendly body and face саге
                        products (including hand-made)
                      </li>
                      <li>Soap and shower products</li>
                      <li>Bath cosmetics</li>
                      <li>Hair саге cosmetics</li>
                      <li>Men's cosmetics</li>
                      <li>Medical cosmetics</li>
                    </ul>
                  </a>
                </div>
                <div className="usage__slide usage-slide">
                  <a
                    href="/shop/industry-pharmaceutical-industry/"
                    tabIndex={-1}
                  >
                    <img
                      src="/pharma.svg"
                      alt=""
                      className="usage-slide__icon"
                    />
                    <h4 className="usage-slide__title">Pharma</h4>
                    <ul className="usage-slide__list">
                      <li>Dietary supplements</li>
                      <li>Medical creams, ointments, gels</li>
                      <li>Ayurvedic medicines and products</li>
                      <li>Other medical products</li>
                    </ul>
                  </a>
                </div>
                <div className="usage__slide usage-slide">
                  <a href="/shop/industry-food-and-beverages/" tabIndex={-1}>
                    <img
                      src="/food.svg"
                      alt=""
                      className="usage-slide__icon"
                    />
                    <h4 className="usage-slide__title">Food and beverages</h4>
                    <ul className="usage-slide__list">
                      <li>
                        Milk substitutions (almond, rice, and other "milk")
                      </li>
                      <li>Strong and light alcoholic drinks</li>
                      <li>
                        Jelly, cocktails, and other dry beverages for health,
                        sports, and dietary nutrition
                      </li>
                      <li>
                        Cold tea and functional (including sports) beverages
                      </li>
                      <li>Dairy products, yogurts</li>
                      <li>Herbal teas</li>
                    </ul>
                  </a>
                </div>
              </MediaQuery>
              <MediaQuery minWidth={1128}>
                <Carousel
                  ssr
                  itemClass="image-item"
                  responsive={responsive}
                  infinite={true}
                  autoPlaySpeed={1000}
                  showDots={false}
                  swipeable={false}
                  draggable={false}
                  centerMode={true}
                  partialVisible={false}
                >
                  <div className="usage__slide usage-slide">
                    <a href="/shop/industry-food-supplements/" tabIndex={0}>
                      <img
                        src="/supplements.svg"
                        alt=""
                        className="usage-slide__icon"
                      />
                      <h4 className="usage-slide__title">Food supplements</h4>
                      <ul className="usage-slide__list">
                        <li>Dietary supplements</li>
                        <li>Jelly</li>
                        <li>Dietary (diabetic) confectionery</li>
                        <li>Chocolate</li>
                        <li>Cakes</li>
                        <li>Energy bars</li>
                        <li>Chewing gum</li>
                      </ul>
                    </a>
                  </div>
                  <div className="usage__slide usage-slide">
                    <a href="/shop/industry-cosmetics/" tabIndex={-1}>
                      <img
                        src="/cosmetics.svg"
                        alt=""
                        className="usage-slide__icon"
                      />
                      <h4 className="usage-slide__title">Cosmetics</h4>
                      <ul className="usage-slide__list">
                        <li>
                          Natural and environment-friendly body and face саге
                          products (including hand-made)
                        </li>
                        <li>Soap and shower products</li>
                        <li>Bath cosmetics</li>
                        <li>Hair саге cosmetics</li>
                        <li>Men's cosmetics</li>
                        <li>Medical cosmetics</li>
                      </ul>
                    </a>
                  </div>
                  <div className="usage__slide usage-slide">
                    <a
                      href="/shop/industry-pharmaceutical-industry/"
                      tabIndex={-1}
                    >
                      <img
                        src="/pharma.svg"
                        alt=""
                        className="usage-slide__icon"
                      />
                      <h4 className="usage-slide__title">Pharma</h4>
                      <ul className="usage-slide__list">
                        <li>Dietary supplements</li>
                        <li>Medical creams, ointments, gels</li>
                        <li>Ayurvedic medicines and products</li>
                        <li>Other medical products</li>
                      </ul>
                    </a>
                  </div>
                  <div className="usage__slide usage-slide">
                    <a href="/shop/industry-food-and-beverages/" tabIndex={-1}>
                      <img
                        src="/food.svg"
                        alt=""
                        className="usage-slide__icon"
                      />
                      <h4 className="usage-slide__title">Food and beverages</h4>
                      <ul className="usage-slide__list">
                        <li>
                          Milk substitutions (almond, rice, and other "milk")
                        </li>
                        <li>Strong and light alcoholic drinks</li>
                        <li>
                          Jelly, cocktails, and other dry beverages for health,
                          sports, and dietary nutrition
                        </li>
                        <li>
                          Cold tea and functional (including sports) beverages
                        </li>
                        <li>Dairy products, yogurts</li>
                        <li>Herbal teas</li>
                      </ul>
                    </a>
                  </div>
                </Carousel>
              </MediaQuery>
            </div>
          </section>
          <div className="elem-wrapper">
            <section className="mountain-element mountain-element_last" />
          </div>
          <section className="questions">
            <div className="container">
              <h3 className="questions__title">Still have questions?</h3>
              <p className="questions__description">
                Contact us and we will answer them as soon as possible
              </p>
              <div className="questions__wrapper">
                <div className="form">
                  <div
                    role="form"
                    className="wpcf7"
                    id="wpcf7-f16032-o3"
                    lang="en-US"
                    dir="ltr"
                  >
                    <div className="screen-reader-response">
                      <p role="status" aria-live="polite" aria-atomic="true" />{" "}
                      <ul />
                    </div>
                    <form
                      action="/en/chaga-promo/#wpcf7-f16032-o3"
                      method="post"
                      className="wpcf7-form init footer-form"
                      noValidate="novalidate"
                      data-status="init"
                    >
                      <div style={{ display: "none" }}>
                        <input
                          type="hidden"
                          name="_wpcf7"
                          defaultValue={16032}
                        />
                        <input
                          type="hidden"
                          name="_wpcf7_version"
                          defaultValue="5.4.2"
                        />
                        <input
                          type="hidden"
                          name="_wpcf7_locale"
                          defaultValue="en_US"
                        />
                        <input
                          type="hidden"
                          name="_wpcf7_unit_tag"
                          defaultValue="wpcf7-f16032-o3"
                        />
                        <input
                          type="hidden"
                          name="_wpcf7_container_post"
                          defaultValue={0}
                        />
                        <input
                          type="hidden"
                          name="_wpcf7_posted_data_hash"
                          defaultValue
                        />
                        <input
                          type="hidden"
                          name="_wpcf7_recaptcha_response"
                          defaultValue="03AFY_a8Uu2GPc97VmFkbd2F97DxQFw7sIw3TyXclE92mqS_3wG3tuQtLF5pz7ScSvKwToCwsN-PEbgOQChubFw32HoYSr0ynf9hiZbdhzkgUh0t5cUeU5sYYlHaAGxWuQi-DHd7s__fBudEh-pERlKs8ZSlEaNyNX4dO1EUdBjKFl9-gIoXt2ep8p5O3E3KHb04jXRsfyYyciQh6pUzU2pagZoeDbHl4zIYg2wFANB2Rg0mCGlnR4-EKywZYWmd8nE349-nX1BdW3g3gv113eYDq-o5RAP1c591ImtDhQ91q_Yg3_vHsv7srnTz9tVnEXy4dkkd9zp34PQI1FYJG048nBOnrknitAdZiAK_GV64ysdCAfmk-Dw38Y0T6MDiEP_38SVaTBf9umUT9ICuUu8O13IHM7s5KJ-x2a0WOsqrl-KISbWl4kaD6iV0CWVqQtZBUAzuCZWV4hsv6qYMkWNIRRIfIF1lHmuOiyuGmfaMDFW0XxNoibstG3mXrnqDXB6361FxWcrsiUE934WNBXfVOv_pgQHdKtYw"
                        />
                      </div>
                      <p>
                        <label className="form__name">
                          <span className="form__title required">Name</span>
                          <br />
                          <span className="wpcf7-form-control-wrap your-name">
                            <input
                              type="text"
                              name="your-name"
                              onChange={(e) => { setName(e.target.value) }}
                              size={40}
                              className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required pform__input"
                              placeholder="Please write your full name"
                            />
                          </span>{" "}
                        </label>
                      </p>
                      <p>
                        <label className="form__mail">
                          <span className="form__title required">Email</span>
                          <br />
                          <span className="wpcf7-form-control-wrap your-email">
                            <input
                              type="email"
                              name="your-email"
                              onChange={(e) => { setEmail(e.target.value) }}
                              size={40}
                              className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email pform__input"
                              placeholder="Your email"
                            />
                          </span>{" "}
                        </label>
                      </p>
                      <p>
                        <label className="form__phone">
                          <span className="form__title">Phone number</span>
                          <br />
                          <span className="wpcf7-form-control-wrap phone">
                            <input
                              onChange={(e) => { setPhone(e.target.value) }}
                              type="tel"
                              name="phone"
                              size={40}
                              className="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-tel pform__input"
                              aria-invalid="false"
                              placeholder="+ 4 234 234564"
                            />
                          </span>
                        </label>
                      </p>
                      <p>
                        <label className="form__name">
                          <span className="form__title required">
                            Company name
                          </span>
                          <br />
                          <span className="wpcf7-form-control-wrap company">
                            <input
                              type="text"
                              onChange={(e) => { setCompany(e.target.value) }}
                              name="company"
                              size={40}
                              className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required pform__input"
                              aria-required="true"
                              aria-invalid="false"
                              placeholder="Company name"
                            />
                          </span>
                        </label>
                      </p>
                      <p>
                        <label className="form__comment">
                          <span className="form__title">
                            Additional information
                          </span>
                          <br />
                          <span className="wpcf7-form-control-wrap your-message">
                            <textarea
                              name="your-message"
                              onChange={(e) => { setMessage(e.target.value) }}
                              cols={40}
                              rows={10}
                              className="wpcf7-form-control wpcf7-textarea pform__input"
                              aria-invalid="false"
                            />
                          </span>{" "}
                        </label>
                      </p>
                      <p>
                        <button
                          className="wpcf7-form-control wpcf7-submit pform__btn chaga-header"
                          onClick={(e) => { e.preventDefault(); send() }}>Send</button>
                        <span className="ajax-loader" />
                        <span className="ajax-loader" />
                      </p>
                      <div
                        className="wpcf7-response-output"
                        aria-hidden="true"
                      />
                    </form>
                  </div>{" "}
                </div>
                <div className="about-company">
                  <div className="logo-wrapper">
                    <img
                      src="/logo-land-footer.svg"
                      alt="Website logo"
                      className="footer-logo"
                    />
                  </div>
                  <div className="contacts">
                    <h2 className="contacts__title">Contact info</h2>
                    <p>Harju maakond, Tallinn,</p>
                    <p>Kesklinna linnaosa,</p>
                    <p>Vesivärava tn 50-301, 10152</p>
                    <a
                      className="phone-number"
                      href="mailto:info@vitaforest.eu"
                    >
                      info@vitaforest.eu
                    </a>
                    <p style={{ color: "white", textDecoration: "none" }}>
                      +3728801043
                    </p>
                  </div>
                  <div className="company">
                    <h2 className="company__title">Company info</h2>
                    <p>VITAFOREST DISTRIBUTION OÜ</p>
                    <p>Register code: 14377464</p>
                    <p>VAT No: EE102049370</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <footer className="main-footer">
          <div className="footer__row copyright">
            <p className="copyright__text">
              Copyright  © {new Date().getFullYear()} Vitaforest Distribution OÜ. All rights reserved. <br />* - The image you have provided is not the CITES logo. The CITES logo is a registered trademark and may only be used with the permission of the rightsholder. Any similarities are purely coincidental. This image is used solely for presentation purposes and is an element of the page design. If you find any obvious copyright violations in the provided image, please contact us to resolve the issue.
            </p>
          </div>
          <div className="main-footer__popup footer-popup">
            <div className="container">
              <h4 className="footer-popup__title">Notice</h4>
              <p className="footer-popup__text">
                Vitaforest.eu uses cookies to provide necessary website
                functionality, improve your experience and analyze our traffic.
                By using our website, you agree to our{" "}
                <a href="/privacy-policy" className="phone-number">
                  Privacy Policy
                </a>{" "}
                and our cookies usage.
              </p>
              <button className="footer-popup__btn">Accept cookies</button>
            </div>
          </div>
        </footer>
        <div>
          <div
            className="grecaptcha-badge"
            data-style="bottomright"
            style={{
              width: "256px",
              height: "60px",
              position: "fixed",
              visibility: "hidden",
              display: "block",
              transition: "right 0.3s ease 0s",
              bottom: "14px",
              right: "-186px",
              boxShadow: "gray 0px 0px 5px",
              borderRadius: "2px",
              overflow: "hidden",
            }}
          >
            <div className="grecaptcha-logo">
              <iframe
                title="reCAPTCHA"
                src="https://www.google.com/recaptcha/api2/anchor?ar=1&k=6Ld7YjoeAAAAAMNzsA5pnYGPaDMS8-QrN2sR3f6t&co=aHR0cHM6Ly92aXRhZm9yZXN0LmV1OjQ0Mw..&hl=ru&v=RGRQD9tdxHtnt-Bxkx9pM75S&size=invisible&cb=riwu5p5lzs2g"
                width={256}
                height={60}
                role="presentation"
                name="a-sl3z2bppu1jt"
                frameBorder={0}
                scrolling="no"
                sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox"
              />
            </div>
            <div className="grecaptcha-error" />
            <textarea
              id="g-recaptcha-response-100000"
              name="g-recaptcha-response"
              className="g-recaptcha-response"
              style={{
                width: "250px",
                height: "40px",
                border: "1px solid rgb(193, 193, 193)",
                margin: "10px 25px",
                padding: "0px",
                resize: "none",
                display: "none",
              }}
              defaultValue={""}
            />
          </div>
          <iframe style={{ display: "none" }} />
        </div>
      </div>
      {appendScript('/3rdparty/yandex/yaMetrics.js')}
      {appendScript('/3rdparty/linkedin/linkedin_tag.js')}
    </Wrapper >
  );
};

export default RhodiolaPromo;
