import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Context } from "../../..";
import { fetchPostByUrl } from "../../../api/postAPI";
import { fetchWikiByUrl } from "../../../api/wikiAPI";
import { fetchProductByUrl } from "../../../api/productAPI";
import { ReactComponent as KyoLogo } from "../AdminPanel/kyoshop.svg";

const NavBar = styled.div`
width: 100%;
display: flex;
background-color: #303236;
flex-direction: row;
flex-wrap: wrap;
height: 40px;
gap: 0;
z-index: 9999999999999;
position: fixed;
`

const NavButton = styled.button`
color: inherit;
margin: 0px;
color: white;
background: none;
padding: 10px 15px;
width: auto;
border: none;
border-right: 1px solid gray;
&:hover, :active{
    background-color: #3a3b3d;
    text-decoration: underline; 
}
`

const MenuLogo = styled.p`
margin: auto;
margin-right: 5px;
margin-left: 5px;
color: white;
text-align: center;
align-items: center;
`

const ExpandContainer = styled.div`
margin-bottom: 40px;
width: 100%;
`

const KyoLogotype = styled(KyoLogo)`
width: 130px;
margin: 0px;
padding: 0px;
height: 40px;
display: flex;
align-items: flex-end;
`


const AdminPanel = () => {
    const navigate = useNavigate();
    const { user } = useContext(Context);
    const [elemId, setElemId] = useState(null);
    const [elemType, setElemType] = useState(null);
    const [linkLoaded, setLinkLoaded] = useState(null);

    useEffect(() => {
        let hrefDecode = window.location.pathname;
        hrefDecode = hrefDecode.split('/');
        if (hrefDecode.length == 4 && hrefDecode[2] == 'blog') {
            fetchPostByUrl(hrefDecode[3]).then((data) => {
                setElemId(data.id);
                setElemType('blog')
                setLinkLoaded(true);
            })
        }
        else if (hrefDecode.length == 4 && hrefDecode[2] == 'wiki') {
            fetchWikiByUrl(hrefDecode[3]).then((data) => {
                setElemId(data.id);
                setElemType('wiki');
                setLinkLoaded(true);
            })
        }
        else if (hrefDecode.length == 4 && hrefDecode[2] == 'product') {
            fetchProductByUrl(hrefDecode[3]).then((data) => {
                setElemId(data.id);
                setElemType('product');
                setLinkLoaded(true);
            })
        }
    }, [])

    if (user.user.role_name == 'Admin') {
        return (
            <>
                <NavBar>
                    <MenuLogo><KyoLogotype /></MenuLogo>
                    <NavButton onClick={() => { navigate('/admin') }}>Admin page</NavButton>
                    {linkLoaded && Number(elemId) > 0 && elemType == 'blog' ? <NavButton onClick={() => { navigate(`/admin/posts/edit/${elemId}`) }}>Edit blog post</NavButton> : null}
                    {linkLoaded && Number(elemId) > 0 && elemType == 'wiki' ? <NavButton onClick={() => { navigate(`/admin/wiki/edit/${elemId} `) }}>Edit wiki post</NavButton> : null}
                    {linkLoaded && Number(elemId) > 0 && elemType == 'product' ? <NavButton onClick={() => { navigate(`/admin/products/edit/${elemId} `) }}>Edit product</NavButton> : null}
                </NavBar>
                <ExpandContainer />
            </>
        )
    }
    else {
        return (
            <></>
        )
    }
}

export default AdminPanel