import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { fetchWiki } from "../api/wikiAPI";
import Header from "../components/VitaforestUI/Interface/Header/Header";
import Footer from "../components/VitaforestUI/Interface/Footer/Footer";
import { Link } from "react-router-dom";
import LoadingPlaceholder from "../components/Functional/LoadingPlaceholder";
import Page from "../components/VitaforestUI/Interface/Page/Page";
import DefaultButton from "../components/VitaforestUI/Interface/Buttons/DefaultButton";
const PageWrapper = styled.div``;

const WikiSearch = styled.div`
  background: url("/wikibg.jfif");
  min-height: 447px;
  background-size: cover;
  margin-top: -10px;
  box-sizing: border-box;
  padding: 100px 18px 20px 18px;
  @media screen and (min-width: 1128px) {
    min-height: 380px;
  }
`;

const Container = styled.div`
  width: calc(100vw - 36px);
  margin: 0 auto;
  @media screen and (min-width: 1128px) {
    width: 1128px;
  }
`;

const PageTitle = styled.h2`
  font-weight: 700;
  font-size: 38px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
`;

const SearchDescription = styled.p`
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  margin-top: 15px;
`;

const SearchForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  margin: 0 auto;
  margin-top: 90px;
  @media screen and (min-width: 1128px) {
    width: 744px;
    flex-direction: row;
  }
`;

const SearchInput = styled.input`
  width: 284px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #eaebec;
  border-radius: 4px;
  text-indent: 10px;
  @media screen and (min-width: 1128px) {
    width: 654px;
    border-radius: 4px 0px 0px 4px;
    &:focus-visible,
    &:focus {
      outline: none;
    }
  }
`;

const SearchButton = styled.button`
  width: 284px;
  height: 48px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  background: #40bf6a;
  border-radius: 4px;
  justify-content: center;
  margin-top: 10px;
  border: none;
  @media screen and (min-width: 1128px) {
    width: 90px;
    margin-top: 0;
    border-radius: 0px 4px 4px 0px;
  }
`;

const Letters = styled.ul`
  font-size: 26px;
  line-height: 28px;
  font-weight: 600;
  padding: 0px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 35px;
  @media screen and (min-width: 1128px) {
    align-items: center;
    justify-content: center;
  }
`;

const Letter = styled.li`
  border: 1px solid #eaebec;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  min-width: 35px;
  min-height: 35px;
  &.active {
    color: #40bf6a;
    text-decoration: underline;
  }
  @media screen and (min-width: 1128px) {
    min-width: 18px;
    border: none;
    background-color: #fff;
    &:hover,
    &:focus,
    &:active {
      color: #40bf6a;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

const AllLeter = styled(Letter)`
  margin-right: 50px;
  border: none;
`;

const Items = styled.div``;

const Item = styled.div``;

const ItemTitle = styled.h4`
  font-weight: 600;
  font-size: 26px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #7f878b;
  background: #f7f8f9;
  border-radius: 6px;
  padding: 10px 18px;
  @media screen and (min-width: 1128px) {
    width: 1128px;
    margin: 0 auto;
    padding: 10px 25px;
  }
`;

const ResultsTitle = styled(ItemTitle)`
  margin-top: 25px;
`;

const LinksList = styled.ul`
  padding: 0px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin: 20px 0px;
  padding-left: 25px;
`;

const LinkItem = styled.li`
  list-style: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #303236;
  width: calc(50% - 10px);
  @media screen and (min-width: 1128px) {
    width: 30%;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  &:hover,
  &:active {
    text-decoration: underline;
  }
`;

const ErrorMessage = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
`

const Wiki = () => {
  const { url } = useParams();
  const [wiki, setWiki] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [letter, setLetter] = useState(null);
  const [filtred, setFiltred] = useState(null);
  const [sorted, setSorted] = useState(null);
  const [search, setSearch] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [alphabet, setAlphabet] = useState(null);

  // funcs
  useEffect(() => {
    fetchWiki(1, 1000).then(
      (data) => {
        setWiki(data.rows);
        // map all items and create unique characters array
        let alphabetMapped = data.rows.map((item) => item.title[0]).sort();
        setAlphabet(alphabetMapped);
        // sort all items for all view
        let groupedPosts = {};
        for (const post of data.rows) {
          const firstLetter = post.title[0];
          if (!groupedPosts[firstLetter]) {
            groupedPosts[firstLetter] = [];
          }
          groupedPosts[firstLetter].push(post);
        }
        setSorted(groupedPosts);
        setIsLoaded(true);
      }
    );
  }, []);


  // Filter data
  useEffect(() => {
    let filtredData = letter
      ? wiki.filter((post) => post.title.startsWith(letter))
      : wiki;
    setFiltred(filtredData);
  }, [letter]);

  if (error && !isLoaded) {
    return (
      <Page>
        <ErrorMessage style={{ marginTop: '30px' }}>
          <h3>{error}</h3>
          <DefaultButton onClick={() => { window.location.reload() }} title={'Reload page'} style={{ marginTop: '30px' }} />
        </ErrorMessage>
      </Page>
    );
  } else if (!isLoaded && !error) {
    return (
      <Page>
        <LoadingPlaceholder />
      </Page>
    );
  } else {
    return (
      <Page>
        <PageWrapper>
          <WikiSearch>
            <PageTitle>Plants database</PageTitle>
            <SearchDescription>
              Search our articles or browse by alphabetical index below
            </SearchDescription>
            <SearchForm>
              <SearchInput
                placeholder="Plant"
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              />
              <SearchButton onClick={() => setSearchValue(search)}>
                Search
              </SearchButton>
            </SearchForm>
          </WikiSearch>
          <Container>
            {searchValue.length < 1 ? (
              <Letters>
                <AllLeter
                  onClick={() => setLetter(null)}
                  className={!letter ? "active" : null}
                >
                  All
                </AllLeter>
                {Array.from(new Set(alphabet)).map((char) => (
                  <Letter
                    onClick={() => setLetter(char)}
                    className={char === letter ? "active" : null}
                  >
                    {char}
                  </Letter>
                ))}
              </Letters>
            ) : null}
          </Container>
          {filtred && letter ? (
            <Items>
              <Item>
                <ItemTitle>{letter}</ItemTitle>
                <Container>
                  <LinksList>
                    {filtred.map((item) => {
                      return (
                        <LinkItem>
                          <Link to={item.url}>{item.title}</Link>
                        </LinkItem>
                      );
                    })}
                  </LinksList>
                </Container>
              </Item>
            </Items>
          ) : !letter && searchValue < 1 ? (
            <Items>
              {Object.entries(sorted)
                .sort()
                .map(([char, posts]) => {
                  return (
                    <Item>
                      <ItemTitle>{char}</ItemTitle>
                      <Container>
                        <LinksList>
                          {posts.map((post) => {
                            return (
                              <LinkItem>
                                <Link to={post.url}>{post.title}</Link>
                              </LinkItem>
                            );
                          })}
                        </LinksList>
                      </Container>
                    </Item>
                  );
                })}
            </Items>
          ) : searchValue.length > 0 ? (
            <Items>
              <ResultsTitle>Results for "{searchValue}"</ResultsTitle>
              {wiki.map((item) => {
                if (
                  item.title.toLowerCase().includes(searchValue.toLowerCase())
                ) {
                  return (
                    <Item>
                      <Container>
                        <LinksList>
                          <LinkItem>
                            <Link to={item.url}>{item.title}</Link>
                          </LinkItem>
                        </LinksList>
                      </Container>
                    </Item>
                  );
                }
              })}
            </Items>
          ) : null}
        </PageWrapper>
      </Page>
    );
  }
};

export default Wiki;
