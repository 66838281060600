import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SingleList from "./SinglieList";
import { Spinner } from "react-bootstrap";
import { fetchAttributes } from "../../../../api/attributeAPI";
import { fetchCategories } from "../../../../api/productAPI";
import LoadingPlaceholder from "../../../Functional/LoadingPlaceholder";
const FiltersContainer = styled.div`
  box-sizing: border-box;
  padding: 15px 18px;
  @media screen and (min-width: 1128px) {
    padding: 0px;
  }
`;

function FiltersLists(props) {
  const [attributes, setAttributes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  useEffect(() => {
    fetchAttributes().then((data) => {
      setAttributes(data)
    });
    fetchCategories().then((data) => {
      setCategories(data);
    });
    setIsLoaded(true);
  }, [props.reload]);
  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return (
      <div>
        <LoadingPlaceholder />
      </div>
    );
  } else {
    return (
      <FiltersContainer>
        <SingleList
          title={'Categories'}
          items={categories}
          attributesFilters={props.categoryFilter}
          setAttributesFilters={props.setCategoryFilter}
          reload={props.reload}
          setReload={props.setReload}
          products={props.products}
          isCatFilter={true}
        ></SingleList>
        {attributes.map((item) => {
          if (item.isFilter) {
            return (
              <SingleList
                title={item.name}
                items={item.terms}
                attributesFilters={props.attributesFilters}
                setAttributesFilters={props.setAttributesFilters}
                reload={props.reload}
                setReload={props.setReload}
                products={props.products}
              ></SingleList>
            )
          }
        })}
      </FiltersContainer>
    );
  }
}

export default FiltersLists;
