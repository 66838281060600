import React from "react";
import styled from "styled-components";

const RequiredElement = styled.p`
  text-align: right;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ef5e53;
`;
export default function Required() {
  return <RequiredElement>* Required fields</RequiredElement>;
}
