import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Context } from "..";
import MobileAccountHead from "../components/VitaforestUI/Account/MobileAccountHead";
import AccountMenu from "../components/VitaforestUI/Account/subcomponents/AccountMenu";
import PageHeader from "../components/VitaforestUI/Account/subcomponents/PageHeader";
import Page from "../components/VitaforestUI/Interface/Page/Page";
import Notification from "../components/VitaforestUI/Interface/Notification";
import { getNotificationsByUser } from "../api/notificationsAPI";
import LoadingPlaceholder from "../components/Functional/LoadingPlaceholder";

const TemporaryHide = styled.div`
  @media screen and (max-width: 1127px) {
    display: none;
  }
`;

const TemporaryHideMobile = styled.div`
  @media screen and (min-width: 1128px) {
    display: none;
  }
`;

const Container = styled.div`
  margin-top: 20px;
  position: relative;
  @media screen and (min-width: 1128px) {
    display: flex;
    margin-top: 30px;
    margin-bottom: 40px;
  }
`;

const Wrapper = styled.div`
  width: calc(100vw - 36px);
  margin: 0 auto;
  @media screen and (min-width: 1128px) {
    width: auto;
    margin: 0;
  }
`;

const NotificationWrapper = styled.div`
display: flex;
flex-direction: column;
gap: 20px;
`

const EmptyContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
margin-top: 20px;
`


const MyAccount = () => {
  const { user } = useContext(Context);
  const [noti, setNoti] = useState([]);
  const [notiLenght, setNL] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [needReload, setNeedReload] = useState(false);

  useEffect(() => {
    getNotificationsByUser().then((data) => {
      setNoti(data);
      setNL(data.length);
      setIsLoaded(true)
    }, (e) => {
      setError(e);
    })
  }, [needReload])

  return (
    <Page variant={"centred"}>
      <Container>
        <Helmet>
          <title>Vitaforest - Account</title>
          <meta name="description" content="Vitaforest user account page" />
        </Helmet>
        <TemporaryHide>
          <AccountMenu />
        </TemporaryHide>
        <TemporaryHideMobile>
          <MobileAccountHead
            fullName={`${user.user.first_name} ${user.user.last_name}`}
            email={user.user.email}
          />
          <AccountMenu />
        </TemporaryHideMobile>
        <TemporaryHide>
          <Wrapper>
            <PageHeader>Dashboard</PageHeader>
            {isLoaded ? <NotificationWrapper>{noti.map((n) => {
              return (
                <Notification
                  title={n.title}
                  description={n.body}
                  id={n.id}
                  reloadTrigger={setNeedReload}
                  reloadState={needReload}
                />
              )
            })}            </NotificationWrapper>
              : <LoadingPlaceholder />}
            {isLoaded && noti.length < 1 ? <EmptyContainer><h5>Nothing here</h5></EmptyContainer> : null}
          </Wrapper>
        </TemporaryHide>
      </Container>
    </Page>
  );
};

export default MyAccount;
