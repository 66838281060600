import React, { useContext, useEffect, useState } from "react";
import MediaQuery from "react-responsive";
import styled from "styled-components";
import { Context } from "../../../..";
import Page from "../../Interface/Page/Page";
import {
  convertWishlistToBasket,
  getAllWishlists,
  removeWishlist,
} from "../../../../api/wishlistAPI";
import AccountMenu from "../subcomponents/AccountMenu";
import PageHeader from "../subcomponents/PageHeader";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { Link } from "react-router-dom";
import LoadingPlaceholder from "../../../Functional/LoadingPlaceholder";
import { fetchProducts } from "../../../../api/productAPI";
import { ReactComponent as EditDark } from "../images/wishlist_edit_dark.svg";
import { ReactComponent as EditLight } from "../images/wishlist_edit_light.svg";

import ShareButtonWishlist from "../../Interface/ShareButton/ShareButtonWishlist";

const MobileWishlistHead = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const MobileWishlistTitle = styled.h4`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #303236;
`;

const MobileItemsCount = styled.p`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #7f878b;
`;

const TemporaryHide = styled.div`
  @media screen and (max-width: 1127px) {
    display: none;
  }
`;

const Container = styled.div`
  margin-top: 20px;
  position: relative;
  margin: 20px auto;
  width: calc(100vw - 36px);

  @media screen and (min-width: 1128px) {
    display: flex;
    margin-top: 30px;
    margin-bottom: 40px;
  }
`;

const TitleContainer = styled.p`
  display: flex;
  gap: 15px;
  margin-bottom: 0;
  img {
    height: 42px;
    width: 42px;
    border-radius: 4px;
  }
`;

const Title = styled.h4`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
`;

const Table = styled.table`
  width: 100%;
  @media screen and (min-width: 1128px) {
    width: 840px;
    box-sizing: border-box;
    padding-bottom: 15px;
  }
`;

const TableContainer = styled.div`
  @media screen and (min-width: 1128px) {
    margin-top: 20px;
    border: 1px solid #eaebec;
    border-radius: 6px 6px 6px 6px;
  }
`;

const TableRow = styled.tr`
  td {
    padding: 10px 18px 10px 18px;
    box-shadow: 0px 1px 0px 0px #eaebec;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    vertical-align: center;
    color: #303236;
    @media screen and (min-width: 1128px) {
      font-size: 14px;
      line-height: 17px;
      box-shadow: none;
    }
  }
  th {
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    color: #7f878b;
    background: #f3f7f2;
    padding: 0px 18px;
    @media screen and (min-width: 1128px) {
      background: none;
      border-bottom: 1px solid #eaebec;
      padding-top: 10px;
    }
  }
  td,
  th {
    padding-right: 10px;
  }
  td:nth-child(1),
  th:nth-child(1) {
    padding-left: 35px;
    width: 570px;
    padding-top: 10px;
  }
  td:nth-child(2),
  th:nth-child(2) {
    width: 40px;
    padding-left: 60px;
  }
  td:nth-child(3),
  th:nth-child(3) {
    width: 60px;
    padding-left: 60px;
    padding-right: 35px;
  }
  &:hover {
    box-shadow: 0px 4px 10px rgba(26, 33, 45, 0.05);
    border-radius: 4px;
  }
`;

const Row = styled(TableRow)`
  height: 42px;
  td {
    padding-left: 18px;
    border-left: none;
    border-right: none;
  }
`;

const Qty = styled.input`
  background: #f7f8f9;
  border: 1px solid #eaebec;
  border-radius: 4px;
  width: 40px;
  height: 37px;
  padding-left: 12px;
  margin-top: 10px;
`;

const QtyCell = styled.td`
  padding-top: 0px !important;
`;

const MobileBackButton = styled(Link)`
  &:before {
    content: url("/arrow-back.svg");
  }
  text-decoration: none;
  color: black;
  position: absolute;
  top: -5px;
  vertical-align: center;
  margin-left: 23px;
  @media screen and (min-width: 1128px) {
    display: none;
  }
  &:hover {
    color: inherit;
  }
  &:active {
    color: inherit;
  }
`;

const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
`;

const WishlistsInner = styled.div``;

const WishlistBtn = styled.button`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #303236;
  padding: 12px 20px;
  border-radius: 4px;
  border: 1px solid #eaebec;
  background-color: transparent;
  &:hover{
    color: #ffffff;
    background: #3c3f54;
    transition: all 0.5s;
  }
`;

const WishlistBtnActive = styled(WishlistBtn)`
  color: #ffffff;
  background: #3c3f54;
  border: 1px solid #3c3f54;
  &:hover,
  &:focus,
  &:active {
    color: white;
    background: #3c3f54;
  }
`;

const WishlistsList = styled.ul`
  display: flex;
  list-style: none;
  gap: 15px;
  padding: 0px;
  margin-top: 20px;
  @media screen and (max-width: 1127px) {
    flex-wrap: wrap;
  }
`;

const CreateLink = styled(Link)`
  color: #40bf6a;
  text-align: center;
  font-size: 13px;
  font-family: Proxima Nova;
  font-weight: 700;
  text-decoration: none;
  position: relative;
  &:hover {
    color: #40bf6a;
  }
  &:before {
    content: "+";
    font-size: 28px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    position: absolute;
    left: -20px;
    top: -8px;
  }
  @media screen and (max-width: 1127px) {
    margin-left: auto;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 1127px) {
    flex-wrap: wrap;
  }
`;

const ProductTitle = styled.p`
  margin-bottom: 0;
`;

const WishlistEditLink = styled(Link)`
  padding-left: 15px;
`;

const MobileHeader = styled.h3`
  font-weight: 600;
  font-size: 20px;
  line-height: 17px;
  text-align: center;
  color: #303236;
  width: 100%;
`;

const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 30px;
`;

const AddToCartButton = styled.button`
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #fff;
  padding: 10px 24px 10px 47px;
  border-radius: 4px;
  background-color: #40bf6a;
  border: 1px solid #40bf6a;
  margin-left: 20px;
  position: relative;
  &::before {
    content: url("/prev-icon.svg");
    position: absolute;
    left: 15px;
    top: 8px;
  }
  &:hover{
    background: #61D186;
  }
  &:active{
    background: #2E9E53;
  }
`;

const DeleteWish = styled.button`
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #fff;
  padding: 10px 24px 10px 47px;
  border-radius: 4px;
  background-color: #3c3f54;
  border: 1px solid #3c3f54;
  margin-left: auto;
  position: relative;
  &::before {
    content: url("/deleteWish.svg");
    position: absolute;
    left: 15px;
    top: 8px;
  }
&:hover{
  background: #525673;
}
&:active{
  background: #242638;
}
`;

const AccountWishlist = () => {
  const [wishlist, setWishlist] = useState([]);
  const [wishlistArray, setWishlistArray] = useState([]);
  const [needReload, setNeedReload] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentWishlist, setCurrentWishlist] = useState([]);
  const [products, setProducts] = useState([]);

  // get user from context
  const { user } = useContext(Context);

  const transformToCart = (id) => {
    convertWishlistToBasket(user.user.id, id).then((data) => {
    });
  };

  const removeHandle = (id) => {
    removeWishlist(id).then((data) => {
      if (data.status == "removed") {
        NotificationManager.success("Wishlist removed", "Success");
        setNeedReload(!needReload);
      } else if (data.status == "no_permission") {
        NotificationManager.warning(
          "You dont have permissons for this action",
          "Warning"
        );
      } else {
        NotificationManager.error(data, "Error");
      }
    });
  };

  const calcPrice = (price, qty) => {
    return price * qty;
  };

  // get data
  useEffect(() => {
    fetchProducts([], []).then((data) => {
      setProducts(data);
      getAllWishlists(user.user.id).then((data) => {
        setWishlist(data);
        setCurrentWishlist(data[0]);
        setIsLoaded(true);
      });
    });
  }, [needReload]);
  if (!isLoaded) {
    return (
      <Page>
        <LoadingPlaceholder />
      </Page>
    );
  } else if (wishlist.length < 1 && isLoaded) {
    return (
      <Page variant="centred">
        <Container>
          <NotificationContainer />
          <TemporaryHide>
            <AccountMenu />
          </TemporaryHide>
          <div>
            <MobileBackButton to={"/account"} />
            <PageHeader>My wish list</PageHeader>
            <MediaQuery maxWidth={1127}>
              <CenteredDiv>
                <h5>You dont have items in wishlist</h5>
              </CenteredDiv>
            </MediaQuery>
            <MediaQuery minWidth={1128}>
              <>
                <h5>You dont have items in wishlist</h5>
              </>
            </MediaQuery>
          </div>
        </Container>
      </Page>
    );
  } else {
    return (
      <Page variant="centred">
        <Container>
          <NotificationContainer />
          <TemporaryHide>
            <AccountMenu />
          </TemporaryHide>
          <div>
            <MobileBackButton to={"/account"} />
            <Flex>
              <MediaQuery maxWidth={1127}>
                <MobileHeader>My wish list</MobileHeader>
              </MediaQuery>
              <MediaQuery minWidth={1128}>
                <PageHeader>My wish list</PageHeader>
              </MediaQuery>
              <CreateLink to="/account/wishlist/create">
                Create new wish list
              </CreateLink>
            </Flex>

            <>
              <WishlistsInner>
                <WishlistsList>
                  {wishlist.map((item) => {
                    return item.name == currentWishlist.name ? (
                      <li>
                        <WishlistBtnActive
                          onClick={() => setCurrentWishlist(item)}
                        >
                          {item.name}
                          <WishlistEditLink to={"/account/wishlist/" + item.id}>
                            <EditDark />
                          </WishlistEditLink>
                        </WishlistBtnActive>
                      </li>
                    ) : (
                      <li>
                        <WishlistBtn onClick={() => setCurrentWishlist(item)}>
                          {item.name}
                          <WishlistEditLink to={"/account/wishlist/" + item.id}>
                            <EditLight />
                          </WishlistEditLink>
                        </WishlistBtn>
                      </li>
                    );
                  })}
                </WishlistsList>
              </WishlistsInner>
              <TableContainer>
                <Table>
                  <thead>
                    <TableRow>
                      <th>Product name</th>
                      <th>Quantity</th>
                      <th>Subtotal</th>
                    </TableRow>
                  </thead>
                  <tbody>
                    {currentWishlist.items.map((elem) => {
                      let item = products.find(
                        (element) => element.id === elem.itemId
                      );
                      return (
                        <TableRow>
                          <td>
                            <ProductTitle>{item.title}</ProductTitle>
                          </td>
                          <QtyCell>
                            <Qty value={elem.qty} placeholder="Qty" />
                          </QtyCell>

                          <td>{calcPrice(item.price, elem.qty)}</td>
                        </TableRow>
                      );
                    })}
                  </tbody>
                </Table>
              </TableContainer>
            </>
            <ButtonsContainer>
              <AddToCartButton
                onClick={() => {
                  transformToCart(currentWishlist.id);
                }}
              >
                Add all to cart
              </AddToCartButton>
              <DeleteWish
                onClick={() => {
                  removeHandle(currentWishlist.id);
                }}
              >
                Delete wish list
              </DeleteWish>
            </ButtonsContainer>
          </div>
        </Container>
      </Page>
    );
  }
};

export default AccountWishlist;
