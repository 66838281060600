import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import MediaQuery from "react-responsive";
import PageHeader from "../subcomponents/PageHeader";
import Page from "../../Interface/Page/Page";
import AccountMenu from "../subcomponents/AccountMenu";
import { Helmet } from "react-helmet";
import { fetchAddress, fetchUserAddresses, updateAddress } from "../../../../api/addressAPI";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { Link, useParams } from "react-router-dom";
import Heading from "../../Interface/TextElements/Heading";
import { Country, State, City } from "country-state-city";
import Input from "../../Interface/Input/Input";
import Select from "../../Interface/Select/Select";
import Required from "../../Interface/TextElements/Required";
import DefaultButton from "../../Interface/Buttons/DefaultButton";
import LoadingPlaceholder from "../../../Functional/LoadingPlaceholder";

const ContentContainer = styled.div`
  margin-top: 20px;
  width: calc(100vw - 36px);
  margin: 0 auto;
  position: relative;
  @media screen and (min-width: 1128px) {
    width: auto;
    display: flex;
    margin-top: 30px;
    margin-bottom: 40px;
  }
`;
const TemporaryHide = styled.div`
  @media screen and (max-width: 1127px) {
    display: none;
  }
`;
const Container = styled.div`
position: relative;
margin-bottom: 30px;
`;

const Wrapper = styled.div`
  @media screen and (min-width: 1128px) {
    width: auto;
    margin: 0;
  }
`;

const Form = styled.form`
  @media screen and (min-width: 1128px) {
    width: 456px;
  }
`;

const FormRequired = styled(Required)`
  margin-top: 20px;
`;

const FormButton = styled(DefaultButton)`
  width: 284px;
  margin: 0 auto;
  @media screen and (min-width: 1128px) {
    width: 130px;
    margin: 0px;
  }
`;

const MobileBackButton = styled(Link)`
&:before{
  content: url('/arrow-back.svg');
}
text-decoration: none;
color: black;
position: absolute;
top: -5px;
vertical-align: center;
@media screen and (min-width: 1128px){
  display: none;
}
&:hover{
  color: inherit;
}
&:active{
  color: inherit;
}
`;


const AccountEditAddress = () => {
  const { id } = useParams();
  const [address, setAddress] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [countryStates, setCountryStates] = useState(null);
  const [country, setCountry] = useState(null);
  const [countryIso, setCountryIso] = useState(null);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [zip, setZip] = useState(null);
  const [post, setPost] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [phone, setPhone] = useState(null);
  const [needReload, setNeedReload] = useState(false);
  const countriesRaw = Country.getAllCountries();

  const updateCountry = function (e) {
    setCountry(e);
    countriesRaw.forEach((elem) => {
      if (elem.name === e) {
        setCountryIso(elem.isoCode);
      }
    });
  };

  useEffect(() => {
    if (countryIso) {
      setCountryStates(State.getStatesOfCountry(countryIso));
    } else {
      setCountryStates(State.getStatesOfCountry("AF"));
    }
  }, [countryIso])

  const update = () => {
    let formdata = new FormData();
    if (!country || !state || !city || !post || !zip || !firstName || !lastName) {
      NotificationManager.warning('Fill required fields', "Warning");
    }
    else {
      formdata.append('id', id)
      formdata.append('country', country)
      formdata.append('state', state)
      formdata.append('post', post)
      formdata.append('zip', zip)
      formdata.append('city', city)
      formdata.append("first_name", firstName);
      formdata.append("last_name", lastName);
      formdata.append("phone", phone);
      updateAddress(formdata).then((data) => {
        if (data.status == 'updated') {
          NotificationManager.success('Address updated', 'Success');
          setNeedReload(!needReload)
        }
        else {
          NotificationManager.error(`${data.message}`, 'Error');
          setNeedReload(!needReload)
        }
      })
    }
  }

  useEffect(() => {
    fetchAddress(id).then(
      (data) => {
        setAddress(data);
        setCity(data.city);
        setState(data.state);
        setZip(data.zip);
        setPost(data.post);
        setFirstName(data.first_name);
        setLastName(data.last_name);
        setPhone(data.phone);
        updateCountry(data.country);
        setIsLoaded(true);
      },
      (e) => {
        setError(e);
        setIsLoaded(false);
      }
    );
  }, [needReload]);
  if (error) {
    return (
      <Page variant={"centred"} seo_title={'Vitaforest - Edit Address - Addresses - Account'} seo_description={'Vitaforest - Account details'}>
        <NotificationContainer />
        <Wrapper>
          <TemporaryHide>
            <AccountMenu />
          </TemporaryHide>
          <Container>
            <PageHeader>Edit address</PageHeader>
            <h5>{error}</h5>
          </Container>
        </Wrapper>
      </Page>
    );
  } else if (!isLoaded) {
    return (
      <Page variant={"centred"} seo_title={'Vitaforest - Details - Account'} seo_description={'Vitaforest - Account details'}>
        <NotificationContainer />
        <ContentContainer>
          <TemporaryHide>
            <AccountMenu />
          </TemporaryHide>
          <Container>
            <PageHeader>Edit address</PageHeader>
            <LoadingPlaceholder />
          </Container>
        </ContentContainer>
      </Page>
    );
  } else {
    return (
      <Page variant={"centred"} seo_title={'Vitaforest - Details - Account'} seo_description={'Vitaforest - Account details'}>
        <NotificationContainer />
        <ContentContainer>
          <TemporaryHide>
            <AccountMenu />
          </TemporaryHide>
          <Container>
            <MobileBackButton to={'/account/addresses'} />
            <PageHeader>Edit address</PageHeader>
            <Heading>Address</Heading>
            <Form>
              <Input
                label="First name"
                placeholder="Your first name"
                type="text"
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
                value={firstName}
                required="true"
              />

              <Input
                label="Last name"
                placeholder="Your last name"
                type="text"
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
                value={lastName}
                required="true"
              />

              <Input
                label="Phone"
                placeholder="Your phone"
                type="phone"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
                required="true"
              />
              <Select
                label="Country"
                options={countriesRaw}
                name={country}
                type="country"
                onChange={(e) => {
                  updateCountry(e.target.value);
                }}
                value={country}
                required="true"
              />

              <Select
                label="State/province"
                options={countryStates}
                name={state}
                value={state}
                type="country"
                onChange={(e) => {
                  setState(e.target.value);
                }}
                required="true"
              />

              <Input
                label="City"
                placeholder="Your city"
                type="text"
                value={city}
                onChange={(e) => {
                  setCity(e.target.value);
                }}
              />
              <Input
                label="Zip/postal code"
                value={zip}
                placeholder="_ _ _ _ _ _"
                type="text"
                onChange={(e) => {
                  setZip(e.target.value);
                }}
                required="true"
              />
              <Input
                label="Post address"
                placeholder="Your post address"
                type="text"
                value={post}
                onChange={(e) => {
                  setPost(e.target.value);
                }}
                required="true"
              />
              <FormRequired />
              <FormButton title="Save changes" onClick={(e) => { e.preventDefault(); update(); }} />
            </Form>
          </Container>
        </ContentContainer>
      </Page>
    );
  }
};

export default AccountEditAddress;
