import React, { useState } from "react";

import styled from "styled-components";
import SingleOrderMobile from "./SingleOrderMobile";

const Container = styled.div`
  .tab {
    display: none;
  }
  .tab.expanded {
    display: block;
  }
`;
const TabsButtons = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;

const Button = styled.button`
  width: 33%;
  color: #7f878b;
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  padding: 10px 0px;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #7f878b;
  &:hover,
  &:focus,
  &:active {
    color: #40bf6a;
    border-color: #40bf6a;
  }
`;

const Counter = styled.span`
  font-weight: 600;
`;

const Tab = styled.div`
  margin-bottom: 40px;
`;

export default function OrdersMobile({ orders }) {
  const [isAllShown, setIsAllShown] = useState(true);
  const [isHoldShown, setIsHoldShown] = useState(false);
  const [isCompleteShown, setIsCompleteShown] = useState(false);
  return (
    <Container>
      <TabsButtons>
        <Button
          onClick={() => {
            setIsHoldShown(false);
            setIsAllShown(true);
            setIsCompleteShown(false);
          }}
        >
          All orders
        </Button>
        <Button
          onClick={() => {
            setIsHoldShown(true);
            setIsAllShown(false);
            setIsCompleteShown(false);
          }}
        >
          On hold
        </Button>
        <Button
          onClick={() => {
            setIsHoldShown(false);
            setIsAllShown(false);
            setIsCompleteShown(true);
          }}
        >
          Complete
        </Button>
      </TabsButtons>
      <Tab className={isHoldShown || isCompleteShown ? "tab" : "tab expanded"}>
        {orders.map((item) => {
          return <SingleOrderMobile item={item} />;
        })}
      </Tab>
      <Tab className={isHoldShown ? "tab expanded" : "tab"}>
        {orders.map((item) =>
          item.status === "hold" ? <SingleOrderMobile item={item} /> : null
        )}
      </Tab>
      <Tab className={isCompleteShown ? "tab expanded" : "tab"}>
        {orders.map((item) =>
          item.status === "complete" ? <SingleOrderMobile item={item} /> : null
        )}
      </Tab>
    </Container>
  );
}
