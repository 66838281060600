import React, { useState, useEffect, useMemo } from "react";
import { Button, Table, Dropdown, Form, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { NotificationManager, NotificationContainer } from 'react-notifications';
import { Link } from "react-router-dom";
import RemoveModal from "../RemoveModal";
import { fetchWiki, removeWiki } from "../../../../api/wikiAPI";
import Pagination from "../../../VitaforestUI/Shop/Pagination";
const TableContainer = styled.div`

`
const EditLabel = styled.span`
color: white;
`

const ControlsTitle = styled.span`
margin-right: 15px;
`

const TotalItems = styled.select`
`


function WikiList() {
    // States
    const [items, setItems] = useState([])
    const [needReload, setNeedReload] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const [error, setError] = useState(null)
    const [modalActive, setModalActive] = useState(false);
    const [currentItemId, setCurrentItemId] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(9);
    const [bulkSelected, setBulkSelected] = useState([]);
    const [search, setSearch] = useState('');
    const [searchFiltered, setSearchFiltered] = useState([]);

    const currentData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * pageSize;
        let lastPageIndex;
        if (pageSize > items.length) {
            lastPageIndex = firstPageIndex + items.length;
        }
        else {
            lastPageIndex = firstPageIndex + pageSize;
        }
        return items.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, items]);

    useEffect(() => {
        if (search.length > 3) {
            let searchRes = items.filter((item) =>
                item.title.toLowerCase().includes(search.toLowerCase())
            )
            console.log(searchRes)
            setSearchFiltered(searchRes)
        }
        else if (search.length < 3) {
            setSearchFiltered([])
        }
    }, [search])

    const RemoveWiki = (id) => {

        const formData = new FormData();
        formData.append('id', id);
        removeWiki(formData).then(data => {

        });
        setNeedReload(true);
    }

    const HandleClicker = (id) => {
        setCurrentItemId(Number(id))
        setModalActive(true)
    }

    const HandleBulk = (id) => {
        let item = bulkSelected.indexOf(id);
        if (item >= 0) {
            let prev = bulkSelected;
            prev.splice(item, 1);
            setBulkSelected(prev);
        }
        else {
            let prev = bulkSelected;
            prev.push(id);
            setBulkSelected(prev);
        }
    }

    // useEffect for products loading
    useEffect(() => {
        fetchWiki(1, 1000).then(
            (result) => {
                setIsLoaded(true)
                setItems(result.rows)
                setNeedReload(false)
            },
            (error) => {
                setIsLoaded(true)
                setError(error)
            },
        )
        return () => {

        }
    }, [needReload])
    if (error) {
        return (
            <div>
                {NotificationManager.error(`${error.message}`, 'Error')}
                <NotificationContainer />
            </div>
        )
    } else if (!isLoaded) {
        return (
            <h1>Loading...</h1>
        )
    } else {
        return (

            // HTML
            <TableContainer>
                <h1>All wiki posts</h1>
                <h5>Items total: {items.length}</h5>
                <Form.Control type="text" placeholder="Type your search query (more then 3 letters)" onChange={(e) => { setSearch(e.target.value); }} style={{ width: '30%', marginBottom: '15px' }} />
                <Row className="mb-3">
                    {searchFiltered.length < 1 ? <Col>
                        <ControlsTitle>Items per page:</ControlsTitle>
                        <TotalItems onChange={(e) => { setPageSize(e.target.value) }}>
                            <option value={9}>Default</option>
                            <option value={25}>25 items</option>
                            <option value={50}>50 items</option>
                            <option value={100}>100 items</option>
                        </TotalItems>
                    </Col> : null}
                </Row>
                <NotificationContainer />
                <Table striped bordered hover variant="dark">
                    <thead>
                        <tr>
                            <th></th>
                            <th>#</th>
                            <th>Title</th>
                            <th>Author ID</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentData.length > 0 && searchFiltered.length < 1 ? currentData.map((item) => (
                            <tr key={item.id}>
                                <td><input type={'checkbox'} value={item.id} onChange={(e) => { HandleBulk(e.target.value); }} defaultChecked={bulkSelected.includes(item.id)} /></td>
                                <td>{item.id}</td>
                                <td>{item.title}</td>
                                <td>{item.userId}</td>
                                <td><Button onClick={() => HandleClicker(item.id)}>Delete</Button></td>
                                <td><Button variant={'primary'}><Link to={'/admin/wiki/edit/' + item.id}><EditLabel>Edit</EditLabel></Link></Button></td>
                            </tr>
                        )) : searchFiltered.length >= 1 ? searchFiltered.map((item) => (<tr key={item.id}>
                            <td><input type={'checkbox'} value={item.id} onChange={(e) => { HandleBulk(e.target.value); }} defaultChecked={bulkSelected.includes(item.id)} /></td>
                            <td>{item.id}</td>
                            <td>{item.title}</td>
                            <td>{item.userId}</td>
                            <td><Button onClick={() => HandleClicker(item.id)}>Delete</Button></td>
                            <td><Button variant={'primary'}><Link to={'/admin/wiki/edit/' + item.id}><EditLabel>Edit</EditLabel></Link></Button></td>
                        </tr>
                        )) : <h6>Products with this parameters not found</h6>}
                    </tbody>
                </Table>
                {searchFiltered.length < 1 ? <Pagination currentPage={currentPage}
                    totalCount={items.length}
                    pageSize={pageSize}
                    onPageChange={(page) => setCurrentPage(page)}>
                </Pagination> : null}
                <RemoveModal modalActive={modalActive} setModalActive={setModalActive} itemId={currentItemId} removeItem={RemoveWiki} />
            </TableContainer>
        )
    }
}

export default WikiList