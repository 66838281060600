import React from "react";
import CartTable from "./CartTable";

const FilledCart = ({ items, setItems, needReload, setNeedReload, notificationPush }) => {
  return (
    <div>
      <CartTable items={items} needReload={needReload} setNeedReload={setNeedReload} setItems={setItems} notificationPush={notificationPush} />
    </div>
  );
}

export default FilledCart
