import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { fetchAttributes } from "../../../../api/attributeAPI";
import { fetchCategories } from "../../../../api/productAPI";

const Item = styled.div`
display: flex;
flex-direction: row;
align-items: center;
padding: 8px;
gap: 6px;
height: 32px;
background: #F3F7F2;
border-radius: 4px;
`;

const Container = styled.div`
display: flex;
align-items: center;
gap: 6px;
`

const ItemName = styled.p`
margin: 0px;
font-family: 'Proxima Nova';
font-style: normal;
font-weight: 400;
font-size: 13px;
line-height: 16px;
height: auto;
white-space: nowrap;
`;

const RemoveButton = styled.button`
border: none;
background-color: inherit;
margin: 0px;
padding: 0px;
`

const ClearAll = styled.button`
display: block;
border: none;
background-color: inherit;
margin: 0px;
padding: 0px;
width: auto;
color: #40bf6a;
white-space: nowrap;
text-decoration: underline;
`

const BubbleActive = ({ reload, setReload, attributes, setAttributes, category, setCategory }) => {

    const [attributesData, setAttributesData] = useState([]);
    const [categoriesData, setCategoriesData] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);
    const { param } = useParams();
    const navigate = useNavigate();

    const handleClear = () => {
        setAttributes([])
        setCategory([])
        if (param) {
            navigate('/en/shop');
        }
        setReload(!reload)
    }

    const checkFilters = (id) => {
        let item = attributes.indexOf(id);
        if (item >= 0) {
            let prev = attributes;
            prev.splice(item, 1);
            setAttributes(prev);
            setReload(!reload);
        }
        else {
            let prev = attributes;
            prev.push(id);
            setAttributes(prev);
            setReload(!reload);
        }
    };

    const checkFiltersCat = (id) => {
        let item = category.indexOf(id);
        if (item >= 0) {
            let prev = category;
            prev.splice(item, 1);
            setCategory(prev);
            setReload(!reload);
        }
        else {
            let prev = category;
            prev.push(id);
            setCategory(prev);
            setReload(!reload);
        }
    };

    useEffect(() => {
        fetchAttributes().then((data) => {
            let array = []
            data.map((item) => {
                if (item.terms[0]) {
                    item.terms.map((term) => {
                        array.push({ id: term.id, name: term.name })
                    })
                }
            })
            setAttributesData(array)
        }, (error) => {
            setError(error)
            setIsLoaded(false);
        });
        fetchCategories().then((data) => {
            setCategoriesData(data);
        }, (error) => {
            setError(error)
            setIsLoaded(false);
        });
        setIsLoaded(true);
    }, [attributes])
    if (error) {
        return (
            <></>
        )
    }
    else if (!isLoaded) {
        return (
            <></>
        )
    }
    else {
        return (
            <Container>
                {category.length > 0 ? categoriesData.map((cat) => {
                    if (category.includes(cat.id)) {
                        return (
                            <Item>
                                <ItemName>{cat.name}</ItemName>
                                <RemoveButton onClick={() => { checkFiltersCat(cat.id) }}><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" fill="#D8D8D8" fill-opacity="0.3" />
                                    <path d="M5.99502 5.00483C5.72166 4.73146 5.27844 4.73146 5.00507 5.00483C4.73171 5.2782 4.73171 5.72141 5.00507 5.99478L7.0101 7.9998L5.00507 10.0048C4.73171 10.2782 4.73171 10.7214 5.00507 10.9948C5.27844 11.2681 5.72166 11.2681 5.99502 10.9948L8.00005 8.98975L10.0051 10.9948C10.2784 11.2681 10.7217 11.2681 10.995 10.9948C11.2684 10.7214 11.2684 10.2782 10.995 10.0048L8.99 7.9998L10.995 5.99478C11.2684 5.72141 11.2684 5.2782 10.995 5.00483C10.7217 4.73146 10.2784 4.73146 10.0051 5.00483L8.00005 7.00986L5.99502 5.00483Z" fill="#7F878B" />
                                    <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" stroke="#F7F8F9" />
                                </svg>
                                </RemoveButton>
                            </Item>
                        )
                    }
                }) : null}
                {attributes.length > 0 ? attributesData.map((attr) => {
                    if (attributes.includes(attr.id)) {
                        return (
                            <Item>
                                <ItemName>{attr.name}</ItemName>
                                <RemoveButton onClick={() => { checkFilters(attr.id) }}><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" fill="#D8D8D8" fill-opacity="0.3" />
                                    <path d="M5.99502 5.00483C5.72166 4.73146 5.27844 4.73146 5.00507 5.00483C4.73171 5.2782 4.73171 5.72141 5.00507 5.99478L7.0101 7.9998L5.00507 10.0048C4.73171 10.2782 4.73171 10.7214 5.00507 10.9948C5.27844 11.2681 5.72166 11.2681 5.99502 10.9948L8.00005 8.98975L10.0051 10.9948C10.2784 11.2681 10.7217 11.2681 10.995 10.9948C11.2684 10.7214 11.2684 10.2782 10.995 10.0048L8.99 7.9998L10.995 5.99478C11.2684 5.72141 11.2684 5.2782 10.995 5.00483C10.7217 4.73146 10.2784 4.73146 10.0051 5.00483L8.00005 7.00986L5.99502 5.00483Z" fill="#7F878B" />
                                    <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" stroke="#F7F8F9" />
                                </svg>
                                </RemoveButton>
                            </Item>
                        )
                    }
                }) : null}
                {attributes.length > 0 || category.length > 0 ? <ClearAll onClick={() => { handleClear() }}>Clear all</ClearAll> : null}
            </Container>
        )
    }

}

export default BubbleActive