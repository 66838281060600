import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as Image } from "./ButtonVisual.svg";
import { ShareSocial } from "react-share-social";
const Button = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  width: ${(props) => (props.width ? props.width : "30px")};
  height: ${(props) => (props.height ? props.height : "30px")};
  background: #f7f8f9;
  border: 1px solid #eaebec;
  border-radius: 4px;
  display: flex;
  align-items: center;
  vertical-align: middle;
`;

const Container = styled.div`
  position: relative;
`;

const ShareList = styled.div`
  position: absolute;
  display: none;
  border-radius: 8px;
  box-shadow: 0px 8px 24px rgb(0 0 0 / 18%), 0px 0px 4px rgb(0 0 0 / 10%);
  width: 180px;
  background-color: #fff;
  left: -150px;
  height: 70px;
  z-index: 100;
  div {
    padding: 0px;
    min-width: 180px;
    margin-top: 5px;
    display: flex;
  }
  &.active {
    display: block;
  }
`;

const ShareButton = (props) => {
  const [active, setActive] = useState(false);
  return (
    <Container>
      <Button onClick={() => setActive(!active)}>
        <Image />
      </Button>
      <ShareList className={active ? "active" : null}>
        <ShareSocial
          socialTypes={["twitter", "linkedin", "whatsapp"]}
          url={window.location.href}
          style={{ copyContainer: { display: "none" } }}
        />
      </ShareList>
    </Container>
  );
};

export default ShareButton;
