import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MediaQuery from "react-responsive";
import Promo from "./Promo";
import { Link, useNavigate } from "react-router-dom";
import { getHeaderAdContent } from "../../../../../api/contentAPI";
import PromoColumn from "./PromoColumn";
const Nav = styled.nav`
  background-color: #fff;
  margin-top: 50px;
  @media screen and (min-width: 1128px) {
    margin-top: 0;
    border: 1px solid #eaebec;
    margin-top: 20px;
  }
  @media screen and (max-width: 1128px) {
    @keyframes down1 {
      0% {
        height: 0%;
      }
      10% {
        height: 10%;
      }
      25% {
        height: 25%;
      }
      40% {
        height: 40%;
      }
      50% {
        height: 50%;
      }
      60% {
        height: 60%;
      }
      75% {
        height: 75%;
      }
      80% {
        height: 80%;
      }
      100% {
        height: max-content;
      }
    }
  }
`;
const MainList = styled.div`
  list-style: none;
  @media screen and (min-width: 1128px) {
    display: flex;
    flex-direction: row;
    width: 1128px;
    margin: 0 auto;
  }
`;
const SubList = styled.ul`
  margin: 0px;
  list-style: none;
  padding: 20px 18px;
  background: #f7f8f9;
  height: 0px;
  display: none;
  cursor: pointer;
  &.sub-expanded {
    display: block;
    height: max-content;
    animation-name: down1;
    animation-duration: 1s;
    animation-timing-function: ease-in;
    @media screen and (min-width: 1128px) {
      position: absolute;
      background: #ffffff;
      border: 1px solid #eaebec;
      border-radius: 4px;
      z-index: 8;
      top: 48px;
      left: -10px;
      padding: 21px 32px;
    }
  }
  .first-sub-item {
    padding-top: 0px;
  }
`;

const MainItem = styled(Link)`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  text-decoration: none;
  align-items: center;
  box-shadow: 0px 1px 0px #eaebec;
  margin: 0;
  padding: 9px 18px;
  color: #303236;
  position: relative;
  &:hover,
  &:focus,
  &:active {
    color: #40bf6a;
  }

  @media screen and (min-width: 1128px) {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #303236;
    box-shadow: none;
    padding: 14px 20px;
    border-right: 1px solid #eaebec;
  }
`;

const MainItemOld = styled.p`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  text-decoration: none;
  align-items: center;
  box-shadow: 0px 1px 0px #eaebec;
  margin: 0;
  padding: 9px 18px;
  position: relative;
  cursor: pointer;
  &:hover,
  &:focus,
  &:active {
    color: #40bf6a;
  }

  @media screen and (min-width: 1128px) {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #303236;
    box-shadow: none;
    padding: 14px 20px;
    border-right: 1px solid #eaebec;
  }
`;

const FirstItem = styled(MainItemOld)`
  &::after {
    content: " ";
    mask: url("/arrow.svg");
    transition: transform 0.5s, background-color 0.5s;
    background-color: #303236;
    position: absolute;
    right: 13px;
    height: 5px;
    width: 10px;
  }
  &.expanded {
    color: #40bf6a;
    &::after {
      transform: rotate(180deg);
      background-color: #40bf6a;
    }
  }
  @media screen and (min-width: 1128px) {
    padding-left: 0px;
  }
`;
const LastItem = styled(MainItemOld)`
  border-right: none;
`;

const SubListProducts = styled(SubList)`
  @media screen and (min-width: 1128px) {
    width: ${(props) => (props.ads == 1 ? '500px' : props.ads == 2 ? '850px' : '150px')};
    &.sub-expanded {
      display: flex;
      justify-content: space-between;
    }
  }
`;

const SubItem = styled.li`
  cursor: pointer;
  padding-top: 15px;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #303236;
  @media screen and (min-width: 1128px) {
    opacity: 0.8;
  }
  &:hover,
  &:focus,
  &:active {
    color: #40bf6a;
    text-decoration: underline;
  }
`;
const SubItemMain = styled.li`
  font-weight: 600;
  cursor: pointer;
  font-family: "Proxima Nova";
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  padding-top: 15px;
  color: #303236;
  @media screen and (min-width: 1128px) {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-decoration: none;
    color: #303236;
    opacity: 0.8;
    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }
  }
`;
const RelativeParrent = styled.div`
  position: relative;
  p {
    padding-right: 33px;
  }
`;

const Column = styled.div``;

const Triangle = styled.div`
  display: none;
  @media screen and (min-width: 1128px) {
    display: block;
    position: absolute;
    width: 17px;
    height: 17px;
    left: 49px;
    border-top: 1px solid #eaebec;
    border-right: 1px solid #eaebec;
    top: -9px;
    background: #ffffff;
    transform: rotate(-45deg);
    z-index: 0;
  }
`;

const CompanyTriangle = styled(Triangle)`
  left: 53px;
`;

const LinkItem = styled(Link)`
  text-decoration: none;
  color: inherit;
  &:hover,
  &:active {
    color: #40bf6a;
    text-decoration: underline;
  }
`;

const ItemWithArrow = styled(MainItemOld)`
  &::after {
    content: " ";
    mask: url("/arrow.svg");
    transition: transform 0.5s, background-color 0.5s;
    background-color: #303236;
    position: absolute;
    right: 13px;
    height: 5px;
    width: 10px;
    cursor: pointer;
  }
  &.expanded {
    color: #40bf6a;
    &::after {
      transform: rotate(180deg);
      background-color: #40bf6a;
    }
  }
`;

const Navigation = () => {
  const [isSubFirstNavExpanded, setIsSubFirstNavExpanded] = useState(false);
  const [isSubSecondNavExpanded, setIsSubSecondExpanded] = useState(false);
  const [ads, setAds] = useState(0);


  let navigate = useNavigate();

  const routeChange = () => {
    let path = `/en/shop`;
    navigate(path);
  };


  return (
    <Nav>
      <MediaQuery minWidth={1128}>
        <MainList>
          <RelativeParrent
            onMouseEnter={() => {
              setIsSubFirstNavExpanded(true);
            }}
            onMouseLeave={() => setIsSubFirstNavExpanded(false)}
          >
            <FirstItem
              className={isSubFirstNavExpanded ? "expanded" : null}
              onClick={() => {
                routeChange();
              }}
            >
              Products
            </FirstItem>
            <SubListProducts
              className={isSubFirstNavExpanded ? "sub-expanded" : "sub"}
              ads={ads}
            >
              <Triangle />
              <Column>
                <SubItemMain
                  className="first-sub-item"
                  onClick={() => {
                    navigate("/en/shop/extracts");
                  }}
                >
                  Extracts
                </SubItemMain>
                <SubItem
                  onClick={() => {
                    navigate("/en/shop/herbal-extracts");
                  }}
                >
                  Herbal extracts
                </SubItem>
                <SubItem
                  onClick={() => {
                    navigate("/en/shop/mushroom-extracts");
                  }}
                >
                  Mushroom extracts
                </SubItem>
                <SubItem
                  onClick={() => {
                    navigate("/en/shop/fruit-extracts");
                  }}
                >
                  Fruit extracts
                </SubItem>
                <SubItemMain
                  onClick={() => {
                    navigate("/en/shop/powders");
                  }}
                >
                  Powders
                </SubItemMain>
                <SubItem
                  onClick={() => {
                    navigate("/en/shop/herbal-powders");
                  }}
                >
                  Herbal powders
                </SubItem>
                <SubItem
                  onClick={() => {
                    navigate("/en/shop/mushroom-powders");
                  }}
                >
                  Mushroom powders
                </SubItem>
                <SubItem
                  onClick={() => {
                    navigate("/en/shop/fruit-powders");
                  }}
                >
                  Fruit powders
                </SubItem>
              </Column>
              <PromoColumn ads={ads} setAds={setAds} />
            </SubListProducts>
          </RelativeParrent>
          <RelativeParrent
            onMouseEnter={() => {
              setIsSubSecondExpanded(!isSubSecondNavExpanded);
            }}
            onMouseLeave={() => setIsSubSecondExpanded(!isSubSecondNavExpanded)}
          >
            <ItemWithArrow
              className={isSubSecondNavExpanded ? "expanded" : null}
            >
              Company
            </ItemWithArrow>
            <SubList
              className={isSubSecondNavExpanded ? "sub-expanded" : "sub"}
            >
              <CompanyTriangle />
              <SubItem className="first-sub-item">
                <LinkItem to={"/en/about-us"}>About us</LinkItem>
              </SubItem>
              <SubItem>
                <LinkItem to={"/en/production"}>Production</LinkItem>
              </SubItem>
            </SubList>
          </RelativeParrent>
          <MainItem to={"/en/wiki"}>Plants database</MainItem>
          <MainItem to={"/en/blog"}>Blog</MainItem>
          <MainItem to={"/en/contact-us"}>Contacts</MainItem>
          <MainItem to={"/en/shop/in-stock"}>In stock products</MainItem>
        </MainList>
      </MediaQuery>
      <MediaQuery maxWidth={1127}>
        <MainList>
          <RelativeParrent>
            <FirstItem
              className={isSubFirstNavExpanded ? "expanded" : null}
              onClick={() => {
                setIsSubFirstNavExpanded(!isSubFirstNavExpanded);
              }}
            >
              Products
            </FirstItem>
            <SubListProducts
              className={isSubFirstNavExpanded ? "sub-expanded" : "sub"}
            >
              <Triangle />
              <Column>
                <SubItemMain
                  className="first-sub-item"
                  onClick={() => {
                    navigate("/en/shop/extracts");
                    window.location.reload();
                  }}
                >
                  Extracts
                </SubItemMain>
                <SubItem
                  onClick={() => {
                    navigate("/en/shop/herbal-extracts");
                    window.location.reload();
                  }}
                >
                  Herbal extracts
                </SubItem>
                <SubItem
                  onClick={() => {
                    navigate("/en/shop/mushroom-extracts");
                    window.location.reload();
                  }}
                >
                  Mushroom extracts
                </SubItem>
                <SubItem
                  onClick={() => {
                    navigate("/en/shop/fruit-extracts");
                    window.location.reload();
                  }}
                >
                  Fruit extracts
                </SubItem>
                <SubItemMain
                  onClick={() => {
                    navigate("/en/shop/powders");
                    window.location.reload();
                  }}
                >
                  Powders
                </SubItemMain>
                <SubItem
                  onClick={() => {
                    navigate("/en/shop/herbal-powders");
                    window.location.reload();
                  }}
                >
                  Herbal powders
                </SubItem>
                <SubItem
                  onClick={() => {
                    navigate("/en/shop/mushroom-powders");
                    window.location.reload();
                  }}
                >
                  Mushroom powders
                </SubItem>
                <SubItem
                  onClick={() => {
                    navigate("/en/shop/fruit-powders");
                    window.location.reload();
                  }}
                >
                  Fruit powders
                </SubItem>
              </Column>
              <PromoColumn>
                <MediaQuery minWidth={1128}>
                  <Promo />
                </MediaQuery>
              </PromoColumn>
            </SubListProducts>
          </RelativeParrent>
          <RelativeParrent>
            <ItemWithArrow
              className={isSubSecondNavExpanded ? "expanded" : null}
              onClick={() => {
                setIsSubSecondExpanded(!isSubSecondNavExpanded);
              }}
            >
              Company
            </ItemWithArrow>
            <SubList
              className={isSubSecondNavExpanded ? "sub-expanded" : "sub"}
            >
              <CompanyTriangle />
              <SubItem
                className="first-sub-item"
                onClick={() => {
                  navigate("/en/about-us");
                }}
              >
                About us
              </SubItem>
              <SubItem
                onClick={() => {
                  navigate("/en/production");
                }}
              >
                Production
              </SubItem>
              <SubItem
                onClick={() => {
                  navigate("/en/faq");
                }}
              >
                FAQ
              </SubItem>
            </SubList>
          </RelativeParrent>
          <MainItem to={"/en/wiki"}>Plants database</MainItem>
          <MainItem to={"/en/blog"}>Blog</MainItem>
          <MainItem to={"/en/contact-us"}>Contacts</MainItem>
          <MainItem to={"/en/shop/in-stock"}>In stock products</MainItem>
        </MainList>
      </MediaQuery>
    </Nav>
  );
};

export default Navigation;
