// Status filter component
// Last edit 04.04.23

import React, { useState } from "react";
import styled from "styled-components";
import BubbleActive from "./BubbleActive";

// Use of styled-components to manipulate CSS in JS manner
const List = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  top: 0px;
  left: 0px;
  margin-bottom: 25px;
  overflow-x: auto;
`;

const SelectContainer = styled.div`
  label {
    box-sizing: border-box;
    padding: 7px 30px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #303236;
    position: relative;
    border-radius: 4px;
    margin-right: 6px;
  }
  input + label::before {
    content: url("/checkbox-status.svg");
    position: absolute;
    left: 8px;
    top: 6px;
  }
  input:checked + label::before {
    content: url("/checkbox-status-checked.svg");
  }
  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
`;

// Use of template literals and extends to avoid repetition
const Avaliable = styled(SelectContainer)`
  label {
    background-color: #e0f3db;
    background-image: url("/leafs.webp");
    border: 1px solid #e0f3db;
  }
  input:checked + label {
    border: 1px solid #40bf6a;
  }
`;

const Soon = styled(SelectContainer)`
  label {
    background-color: #d7e4f8;
    background-image: url("/soon.webp");
    background-position: right;
    border: 1px solid #d7e4f8;
  }
  input:checked + label {
    border: 1px solid #18a0fb;
  }
`;

// Plain styled components, no need to define them
// Create a reusable label style for consistency purposes
const Label = styled.label`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #303236;
  position: relative;
  border-radius: 4px;
  margin-right: 6px;
`;

const Input = styled.input`
  position: absolute;
  z-index: -1;
  opacity: 0;
`;

// Refactor the function to be more concise with object destructuring & removing unnecessary variables
const StatusFilter = ({
  url,
  statusFilter,
  setStatusFilter,
  reload,
  setReload,
  attributesFilters,
  setAttributesFilters,
  categoryFilter,
  setCategoryFilter,
}) => {
  const checkInclude = (item) => {
    const updatedStatusFilter = statusFilter.includes(item)
      ? statusFilter.filter((elem) => elem !== item)
      : [...statusFilter, item];

    setStatusFilter(updatedStatusFilter);
    setReload(!reload);
  };

  // Simplify JSX code by using interpolated values in place of hardcoded strings, for better readability & maintainability
  return (
    <List>
      <Avaliable>
        {url == "in-stock" ? (
          <Input
            type="checkbox"
            id="avaliable"
            value="Avaliable"
            onChange={(e) => checkInclude(e.target.value)}
            checked
          />
        ) : (
          <Input
            type="checkbox"
            id="avaliable"
            value="Avaliable"
            onChange={(e) => checkInclude(e.target.value)}
          />
        )}
        <Label htmlFor="avaliable">Avaliable</Label>
      </Avaliable>
      <Soon>
        <Input
          id="soon"
          value="Soon"
          type="checkbox"
          onChange={(e) => checkInclude(e.target.value)}
        />
        <Label htmlFor="soon">Soon</Label>
      </Soon>
      <BubbleActive
        reload={reload}
        setReload={setReload}
        attributes={attributesFilters}
        setAttributes={setAttributesFilters}
        category={categoryFilter}
        setCategory={setCategoryFilter}
      />
    </List>
  );
};
export default StatusFilter;
