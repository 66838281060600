import { $authHost, $host } from ".";

export const createOrder = async (body) => {
    try {
        const { data } = await $authHost.post('/api/order', body);
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const getOneOrder = async (id) => {
    try {
        const { data } = await $authHost.get(`/api/order/one/${id}`);
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const getOrdersByUserId = async (id) => {
    try {
        const { data } = await $authHost.get(`/api/order/all/${id}`);
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const updateOrder = async (body) => {
    try {
        const { data } = await $authHost.post('/api/order/update', body);
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const removeOrder = async (id) => {
    try {
        const { data } = await $authHost.post(`/api/order/remove/${id}`)
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const getAllOrders = async () => {
    try {
        const { data } = await $authHost.get('/api/order');
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const getAllOrdersByAdmin = async () => {
    try {
        const { data } = await $authHost.get('/api/order/admin/all');
        return data;
    } catch (e) {
        return e.response.data;
    }
}

// requests

export const createProductRequest = async (body) => {
    try {
        const { data } = await $host.post('/api/request/new', body);
        return data
    } catch (e) {
        return e.response.data;
    }
}

export const fetchAllRequests = async () => {
    try {
        const { data } = await $authHost.get('/api/request');
        return data
    } catch (e) {
        return e.response.data
    }
}

export const fetchRequest = async (id) => {
    try {
        const { data } = await $authHost.get(`/api/request/${id}`)
        return data
    } catch (e) {
        return e.response.data
    }
}