import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Table } from "react-bootstrap";
import { fetchTickets } from "../../../../api/ticketAPI";
import AdminUI from "../../Ui/AdminUI";
import { getAllOrders, getAllOrdersByAdmin } from "../../../../api/ordersAPI";
import LoadingPlaceholder from "../../../Functional/LoadingPlaceholder";


const EditLabel = styled(Link)`
color: white;
text-decoration: none;
&:hover{
    color: white;
}
`

const OrdersPage = () => {
    const [items, setItems] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)
    useEffect(() => {
        getAllOrdersByAdmin().then((data) => {
            setItems(data)
            setIsLoaded(true)
        })
    }, [])
    if (!isLoaded) {
        return (
            <AdminUI>
                <LoadingPlaceholder />
            </AdminUI>
        )
    }
    else {
        return (
            <AdminUI>
                <h1>Orders</h1>
                <Table striped bordered hover variant="dark">
                    <thead>
                        <tr>
                            <th>
                                #
                            </th>
                            <th>
                                Status
                            </th>
                            <th>
                                From user with ID
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item) => (
                            <tr>
                                <td>{item.id}</td>
                                <td>{item.status}</td>
                                <td>{item.userId}</td>
                                <td><Button><EditLabel to={`/admin/order/${item.id}`}>View order</EditLabel></Button></td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </AdminUI>
        )
    }
}

export default OrdersPage