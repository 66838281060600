import React, { useState, useEffect, useMemo } from "react";
import Page from "../components/VitaforestUI/Interface/Page/Page";
import styled from "styled-components";
import PageHeader from "../components/VitaforestUI/Account/subcomponents/PageHeader";
import MediaQuery from "react-responsive";
import { fetchPosts, fetchPostsByCategory } from "../api/postAPI";
import { Spinner } from "react-bootstrap";
import { getFileUrl } from "../api/productAPI";
import { Link, useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { fetchPostCategories } from "../api/categoriesAPI";
import LoadingPlaceholder from "../components/Functional/LoadingPlaceholder";
import MainBlogItem from "../components/VitaforestUI/Blog/MainBlogItem";
import Pagination from "../components/VitaforestUI/Shop/Pagination";
import { Helmet } from "react-helmet";
import DefaultButton from "../components/VitaforestUI/Interface/Buttons/DefaultButton";

const ContentColumn = styled.div`
  @media screen and (min-width: 1128px) {
    width: 840px;
  }
`;

const FilterColumn = styled.div`
  .filter-container {
    display: none;
    &.expanded {
      display: block;
    }
  }
  .background-container {
    display: none;
    &.expanded {
      display: block;
    }
  }
  @media screen and (min-width: 1128px) {
    width: 264px;
    margin-right: 24px;
    .filter-container {
      display: block;
    }
  }
`;

const BlogContainer = styled.div`
  margin: 22px 18px;
  width: calc(100vw - 36px);
  @media screen and (min-width: 1128px) {
    width: 1128px;
    margin: 30px auto;
    display: flex;
  }
`;
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const FilterButton = styled.button`
  border: none;
  background-color: #fff;
  @media screen and (min-width: 1128px) {
    display: none;
  }
`;

const Posts = styled.section`
  margin-bottom: 53px;
  margin-top: 53px;
  display: flex;
  flex-direction: row;
  gap: 27px;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Post = styled.div`
  width: 100%;
  @media screen and (min-width: 640px) {
    width: calc(50% - 13.5px);
  }
`;
const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;
const PostCategory = styled(Link)`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #40bf6a;
  text-decoration: none;
  padding-left: 13px;
  position: relative;
  &:before {
    position: absolute;
    content: url("/arrow-green.svg");
    color: #40bf6a;
    top: -1px;
    left: 0;
  }
  &:hover, :active{
    color: #40bf6a;
    text-decoration: underline; 
  }
`;

const PostImage = styled.img`
  object-fit: cover;
  aspect-ratio: 284/200;
  border-radius: 4px;
  width: 100%;
`;

const PostDate = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #7f878b;
  margin-bottom: 0;
`;

const PostTitle = styled(Link)`
display: block;
text-decoration: none;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #303236;
  margin-top: 10px;
  margin-bottom: 5px;
  &:hover, &:active{
    text-decoration: underline;
    color: inherit;
  }
`;

const PostDescription = styled.p`
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  text-align: justify;
  color: #303236;
  margin-bottom: 7px;
`;

const PostLink = styled(Link)`
  text-decoration: none;
  font-weight: 700;
  font-size: 13px;
  line-height: 14px;
  color: #40bf6a;
  &:hover, &:active{
    color: #40bf6a;
    text-decoration: underline;
  }
`;

const Comments = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: justify;
  color: #303236;
  opacity: 0.8;
  position: relative;
  &::before {
    position: absolute;
    content: url("/comments.svg");
    left: -23px;
  }
`;

const Filter = styled.div`
  z-index: 2;
  position: absolute;
  background: #ffffff;
  width: 100vw;
  left: 0;
  box-sizing: border-box;
  padding: 18px;
  @media screen and (min-width: 1128px) {
    width: 100%;
    position: initial;
    padding: 0px;
  }
`;

const Background = styled.div`
  position: absolute;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background: #000000;
  opacity: 0.55;
  z-index: 1;
`;

const FilterTitle = styled.h4`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 17px;
  color: #303236;
  @media screen and (min-width: 1128px) {
    font-size: 16px;
    line-height: 19px;
    opacity: 0.6;
    height: 36px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }
`;

const CloseFilter = styled.button`
  border: none;
  background-color: #ffffff;
`;

const CategoriesList = styled.div`
  margin-top: 31px;
  @media screen and (min-width: 1128px) {
    margin-top: 10px;
    box-shadow: 0px 1px 0px #eaebec;
    padding-bottom: 5px;
  }
`;

const Category = styled(Link)`
  display: flex;
  text-decoration: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #303236;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  margin-bottom: 5px;
  &:hover, &:active{
  span{
  color: #303236;
  text-decoration: underline;
  }
}
  @media screen and (min-width: 1128px) {
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #303236;
    height: 30px;
  }
`;

const CategoryName = styled.span`
`;

const CategoryCounter = styled.p`
  color: #7f878b;
  margin-bottom: 0px;
`;

const ErrorMessage = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
`

export default function Blog() {
  // get category from params
  let { category } = useParams();

  const navigateTo = useNavigate();

  // states

  const [filterShown, setFilterShown] = useState(false);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [needReload, setNeedReload] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoriesLoaded, setCategoriesLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [totalItems, setTotalItems] = useState([])
  const [totalItemsLoaded, setTotalItemsLoaded] = useState(false);

  const currentData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return items.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, items]);


  const calcCatItems = (categoryName) => {
    let count = 0;
    totalItems.map((post) => {
      if (post.category === categoryName) {
        count++;
      }
    })
    return count
  }


  // funcs
  useEffect(() => {
    fetchPostCategories().then((data) => {
      setCategories(data);
      setCategoriesLoaded(true);
    });
    if (!category) {
      fetchPosts(1, 100).then(
        (data) => {
          setItems(data.rows);
          setTotalItems(data.rows);
          setTotalItemsLoaded(true);
          setIsLoaded(true);
        }
      );
    }
    if (category) {
      fetchPosts(1, 100).then((data) => {
        setTotalItems(data.rows);
        setTotalItemsLoaded(true);
      })
      fetchPostsByCategory(category).then(
        (data) => {
          setItems(data);
          setIsLoaded(true);
        }
      );
    }
  }, [needReload]);

  if (error) {
    return (
      <Page>
        <ErrorMessage style={{ marginTop: '30px' }}>
          <h3>{error}</h3>
          <DefaultButton onClick={() => { window.location.reload() }} title={'Reload page'} style={{ marginTop: '30px' }} />
        </ErrorMessage>
      </Page>
    )
  } else if (!isLoaded || !categoriesLoaded || !totalItemsLoaded) {
    return (
      <Page seo_title={`Vitaforest - Blog`} seo_description={'Vitaforest blog'}>
        <LoadingPlaceholder />
      </Page>
    );
  } else if (items.length < 1) {
    return (
      <Page seo_title={`Vitaforest - Blog`} seo_description={'Vitaforest blog'}>
        <BlogContainer>
          <FilterColumn>
            <Filter
              className={
                filterShown ? "filter-container expanded" : "filter-container"
              }
            >
              <Flex>
                <FilterTitle>Categories</FilterTitle>
                <MediaQuery maxWidth={1127}>
                  <CloseFilter onClick={() => setFilterShown(!filterShown)}>
                    <img src="close.svg" />
                  </CloseFilter>
                </MediaQuery>
              </Flex>
              <CategoriesList>
                {categories.map((item) => {
                  return (
                    <Category
                      to={`/en/blog/category/${item.name}`}
                      onClick={() => {
                        setNeedReload(!needReload);
                      }}
                    >
                      <CategoryName>{item.name}</CategoryName>
                      <CategoryCounter>{calcCatItems(item.name)}</CategoryCounter>
                    </Category>
                  );
                })}
                {category ? (<Category><CategoryName>Clear filters</CategoryName></Category>) : null}
              </CategoriesList>
            </Filter>
            <Background
              className={
                filterShown
                  ? "background-container expanded"
                  : "background-container"
              }
              onClick={() => setFilterShown(!filterShown)}
            />
          </FilterColumn>
          <ContentColumn>
            <HeaderContainer>
              <PageHeader>Recent blog posts</PageHeader>
              <MediaQuery maxWidth={1127}>
                <FilterButton onClick={() => setFilterShown(!filterShown)}>
                  <img src="filter.svg" />
                </FilterButton>
              </MediaQuery>
            </HeaderContainer>
            <Posts>
              <h5>Posts not found</h5>
            </Posts>
          </ContentColumn>
        </BlogContainer>
      </Page>
    )
  } else {
    return (
      <Page>
        <BlogContainer>
          <FilterColumn>
            <Filter
              className={
                filterShown ? "filter-container expanded" : "filter-container"
              }
            >
              <Flex>
                <FilterTitle>Categories</FilterTitle>
                <MediaQuery maxWidth={1127}>
                  <CloseFilter onClick={() => setFilterShown(!filterShown)}>
                    <img src="/close.svg" />
                  </CloseFilter>
                </MediaQuery>
              </Flex>
              <CategoriesList>
                {categories.map((item) => {
                  return (
                    <Category
                      to={`/en/blog/category/${item.name}`}
                      onClick={() => {
                        setNeedReload(!needReload);
                      }}
                    >
                      <CategoryName>{item.name}</CategoryName>
                      <CategoryCounter>{calcCatItems(item.name)}</CategoryCounter>
                    </Category>
                  );
                })}
                {category ? (<Category to='/en/blog'><CategoryName>Clear filters</CategoryName></Category>) : null}
              </CategoriesList>
            </Filter>
            <Background
              className={
                filterShown
                  ? "background-container expanded"
                  : "background-container"
              }
              onClick={() => setFilterShown(!filterShown)}
            />
          </FilterColumn>
          <ContentColumn>
            <HeaderContainer>
              <PageHeader>Recent blog posts</PageHeader>
              <MediaQuery maxWidth={1127}>
                <FilterButton onClick={() => setFilterShown(!filterShown)}>
                  <img src="/filter.svg" />
                </FilterButton>
              </MediaQuery>
            </HeaderContainer>
            <Posts>
              {currentPage == 1 && !category ? (
                <MediaQuery minWidth={1128}>
                  <MainBlogItem
                    img={getFileUrl(currentData[0].img)}
                    category={currentData[0].category}
                    date={currentData[0].createdAt}
                    url={currentData[0].url}
                    title={currentData[0].title}
                    short={currentData[0].short}
                    comments={currentData[0].comments ? currentData[0].comments.length : 0}
                  />
                </MediaQuery>
              ) : null}

              {currentData.map((item) => {
                return (
                  <Post>
                    <Flex>
                      {item.category ? (<PostCategory to={"/en/blog/category/" + item.category}>
                        {item.category}
                      </PostCategory>) : <PostCategory to={'#'}>
                        Not selected
                      </PostCategory>}
                      <PostDate>
                        {dayjs(item.createdAt).format("MMM DD") + ", "}
                        {dayjs(item.createdAt).format("YYYY")}
                      </PostDate>
                    </Flex>
                    <Link to={`/en/blog/${item.url}`}>
                      <PostImage src={getFileUrl(item.img)} />
                    </Link>
                    <PostTitle to={`/en/blog/${item.url}`}>{item.title}</PostTitle>
                    <PostDescription>{item.short}</PostDescription>
                    <Flex>
                      <PostLink to={`/en/blog/${item.url}`}>Read more</PostLink>
                      <Comments onClick={() => { navigateTo(`/en/blog/${item.url}`) }}>{item.comments ? item.comments.length : 0}</Comments>
                    </Flex>
                  </Post>
                );
              })}
            </Posts>
            <Pagination
              currentPage={currentPage}
              totalCount={items.length}
              pageSize={pageSize}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </ContentColumn>
        </BlogContainer>
      </Page >
    );
  }
}
