import React from "react";
import styled from "styled-components";

const HeadingComponent = styled.h4`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 14px;
  text-align: left;
  color: #40bf6a;
  @media screen and (min-width: 1128px) {
    margin-bottom: 15px;
  }
`;
export default function Heading({ children }) {
  return <HeadingComponent>{children}</HeadingComponent>;
}
