import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
const Item = styled.div`
  box-sizing: border-box;
  padding: 10px 15px;
  font-size: 12px;
  line-height: 19px;
  p {
    margin-bottom: 5px;
  }
`;

const ItemNumber = styled.h5`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #18a0fb;
  font-weight: 400;
`;

const ItemDate = styled.p`
  margin-bottom: 5px;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ItemSummary = styled.p``;

const Price = styled.span``;

const Count = styled.span`
  color: #7f878b;
`;

const ItemStatus = styled.p``;

const ItemLink = styled(Link)`
  max-width: 284px;
  height: 36px;
  background: #40bf6a;
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  color: #ffffff;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  justify-content: center;
  text-decoration: none;
  &:hover{
    color: white;
    background-color: #61D186;
  }
  &:active{
    color: white;
    background-color: #2E9E53;
  }
`;

export default function SingleOrderMobile({ item }) {
  const orderItems = item.items;
  // funcs
  const orderTotalCalc = (items) => {
    let total = 0;
    items.map((elem) => {
      total += Number(elem.price);
    })
    return Number(total);
  }

  return (
    <Item>
      <Flex>
        <ItemNumber>{item.id}</ItemNumber>
        <ItemStatus>{item.status}</ItemStatus>
      </Flex>
      <ItemDate>{dayjs(item.createdAt).format("MMM, d, YYYY")}</ItemDate>
      <ItemSummary>
        <Price>€{orderTotalCalc(orderItems)}</Price>
        <Count> for{orderItems.length} items</Count>
      </ItemSummary>
      <ItemLink to={`/account/orders/${item.id}`}>View order</ItemLink>
    </Item>
  );
}
