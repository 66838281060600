import React from "react";
import styled from "styled-components";
import { useState } from "react";

const Main = styled.div`
  display: flex;
  position: relative;
  border: 1px solid #eaebec;
  box-sizing: border-box;
  border-radius: 4px;
  background: #f7f8f9;
  box-sizing: border-box;
  max-width: ${(props) => (props.width ? props.width : "100px")};
  max-width: ${(props) => (props.width ? props.width : "100px")};
  height: ${(props) => (props.height ? props.height : "30px")};
  @media screen and (min-width: 1128px) {
    max-width: ${(props) => (props.width ? props.width : "100px")};
  }
`;

const Input = styled.input`
  font-family: "Proxima Nova";
  border: none;
  background: #f7f8f9;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-align: center;
`;

const ButtonPlus = styled.button`
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  width: 20px;
  padding: 0;
  display: block;
  text-align: center;
  border: none;
  font-size: 16px;
  font-weight: 600;
  background: #f7f8f9;
`;

const ButtonMinus = styled(ButtonPlus)`
  position: absolute;
  top: 1px;
  left: 1px;
  bottom: 1px;
  width: 20px;
  padding: 0;
  display: block;
  text-align: center;
  border: none;
  font-size: 16px;
  font-weight: 600;
`;

const CartItemCounter = ({
  step,
  min,
  value,
  width,
  height,
  items,
  setItems,
  itemId,
  setValue,
  onChange
}) => {


  let increment = () => {
    value += step;
    setItems(
      items.map((i) => (i.id === itemId ? { ...i, ["qty"]: value } : i))
    );
  };

  let decrement = () => {
    if (value > min) {
      value -= step;
      setItems(
        items.map((i) => (i.id === itemId ? { ...i, ["qty"]: value } : i))
      );
    }
  };

  return (
    <Main width={width} height={height}>
      <ButtonMinus
        onClick={() => {
          decrement();
        }}
      >
        -
      </ButtonMinus>
      <Input
        type="number"
        value={value}
        onChange={(e) => {
          e.preventDefault();
          value = Number(e.target.value);
        }}
        min={min}
      />
      <ButtonPlus
        onClick={() => {
          increment();
        }}
      >
        +
      </ButtonPlus>
    </Main>
  );
};

export default CartItemCounter;
