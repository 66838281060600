import React from "react";
import Page from "../components/VitaforestUI/Interface/Page/Page";
import styled from "styled-components";


const Container = styled.div`
width: 100%;
p{
    width: 100%;
}
span{
    width: 100%;
    word-wrap: break-word;
}
`

const CookiePolicy = () => {
    return (
        <Page variant={'centred'}>
            <Container>
                <p><b>Cookie policy</b></p>
                <p><strong>Last updated March 10, 2022</strong></p>
                <p><span >This Cookie Policy explains how Vitaforest Distribution OU (“Company”, “we”, “us”, and “our”) uses cookies and similar technologies to recognize</span></p>
                <p><span >you when you visit our websites at http://vitaforest.eu/, (“Websites”). It explains what these technologies are and why we use them, as well as your rights</span></p>
                <p><span >to control our use of them.</span></p>
                <p><span >In some cases we may use cookies to collect personal information, or that becomes personal information if we combine it with other information.</span></p>
                <p><b>What are cookies?</b></p>
                <p><span >Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners in</span></p>
                <p><span >order to make their websites work, or to work more efficiently, as well as to provide reporting information.</span></p>
                <p><span >Cookies set by the website owner (in this case, Vitaforest Distribution OU) are called “first party cookies”. Cookies set by parties other than the</span></p>
                <p><span >website owner are called “third party cookies”. Third party cookies enable third party features or functionality to be provided on or through the website</span></p>
                <p><span >(e.g. like advertising, interactive content and analytics). The parties that set these third party cookies can recognize your computer both when it visits the</span></p>
                <p><span >website in question and also when it visits certain other websites.</span></p>
                <p><b>Why do we use cookies?</b></p>
                <p><span >We use first and third party cookies for several reasons. Some cookies are required for technical reasons in order for our Websites to operate, and we</span></p>
                <p><span >refer to these as “essential” or “strictly necessary” cookies. Other cookies also enable us to track and target the interests of our users to enhance the</span></p>
                <p><span >experience on our Online Properties. Third parties serve cookies through our Websites for advertising, analytics and other purposes. This is described in</span></p>
                <p><span >more detail below.</span></p>
                <p><span >The specific types of first and third party cookies served through our Websites and the purposes they perform are described below (please note that the</span></p>
                <p><span >specific cookies served may vary depending on the specific Online Properties you visit):</span></p>
                <p><b>How can I control cookies?</b></p>
                <p><span >You have the right to decide whether to accept or reject cookies. You can exercise your cookie rights by setting your preferences in the Cookie Consent</span></p>
                <p><span >Manager. The Cookie Consent Manager allows you to select which categories of cookies you accept or reject. Essential cookies cannot be rejected as</span></p>
                <p><span >they are strictly necessary to provide you with services.</span></p>
                <p><span >The Cookie Consent Manager can be found in the notification banner and on our website. If you choose to reject cookies, you may still use our website</span></p>
                <p><span >though your access to some functionality and areas of our website may be restricted. You may also set or amend your web browser controls to accept or</span></p>
                <p><span >refuse cookies. As the means by which you can refuse cookies through your web browser controls vary from browser-to-browser, you should visit your</span></p>
                <p><span >browser’s help menu for more information.</span></p>
                <p><span >In addition, most advertising networks offer you a way to opt out of targeted advertising. If you would like to find out more information, please</span></p>
                <p><span >visit http://www.aboutads.info/choices/ or http://www.youronlinechoices.com.</span></p>
                <p><span >The specific types of first and third party cookies served through our Websites and the purposes they perform are described in the table below (please</span></p>
                <p><span >note that the specific cookies served may vary depending on the specific Online Properties you visit):</span></p>
                <p><b>Essential website cookies:</b></p>
                <p><span >These cookies are strictly necessary to provide you with services available through our Websites and to use some of its features, such as access to</span></p>
                <p><span >secure areas.</span></p>
                <p><b>Analytics and customization cookies:</b></p>
                <p><span >These cookies collect information that is used either in aggregate form to help us understand how our Websites are being used or how effective our</span></p>
                <p><span >marketing campaigns are, or to help us customize our Websites for you.</span></p>
                <p><span >Name: 65590639</span></p>
                <p><span >Purpose: __________</span></p>
                <p><span >Provider: vitaforest.eu</span></p>
                <p><span >Service: __________</span></p>
                <p><span >Country: Russia</span></p>
                <p><span >Type: pixel_tracker</span></p>
                <p><span >Expires in: session</span></p>
                <p><span >Name: px/</span></p>
                <p><span >Purpose: __________</span></p>
                <p><span >Provider: vitaforest.eu</span></p>
                <p><span >Service: __________</span></p>
                <p><span >Country: United States</span></p>
                <p><span >Type: pixel_tracker</span></p>
                <p><span >Expires in: session</span></p>
                <p><span >Name: _ga</span></p>
                <p><span >Purpose: It records a particular ID used to come up with data about website usage by the user. It is a HTTP cookie that expires after 2 years.</span></p>
                <p><span >Provider: .vitaforest.eu</span></p>
                <p><span >Service: Google Analytics View Service Privacy Policy</span></p>
                <p><span >Country: United States</span></p>
                <p><span >Type: http_cookie</span></p>
                <p><span >Expires in: 1 year 11 months 29 days</span></p>
                <p><span >Name: _gid</span></p>
                <p><span >Purpose: Keeps an entry of unique ID which is then used to come up with statistical data on website usage by visitors. It is a HTTP cookie type</span></p>
                <p><span >and expires after a browsing session.</span></p>
                <p><span >Provider: .vitaforest.eu</span></p>
                <p><span >Service: Google Analytics View Service Privacy Policy</span></p>
                <p><span >Country: United States</span></p>
                <p><span >Type: http_cookie</span></p>
                <p><span >Expires in: 1 day</span></p>
                <p><span >Name: _gat#</span></p>
                <p><span >Purpose: Enables Google Analytics regulate the rate of requesting. It is a HTTP cookie type that lasts for a session.</span></p>
                <p><span >Provider: .vitaforest.eu</span></p>
                <p><span >Service: Google Analytics View Service Privacy Policy</span></p>
                <p><span >Country: United States</span></p>
                <p><span >Type: http_cookie</span></p>
                <p><span >Expires in: 1 minute</span></p>
                <p><span >Name: _ym_uid</span></p>
                <p><span >Purpose: Used by Yandex Metrica as a unique user ID to help track a user in a website</span></p>
                <p><span >Provider: .vitaforest.eu</span></p>
                <p><span >Service: Metrica View Service Privacy Policy</span></p>
                <p><span >Country: United States</span></p>
                <p><span >Type: http_cookie</span></p>
                <p><span >Expires in: 11 months 30 days</span></p>
                <p><span >Name: _ym_isad</span></p>
                <p><span >Purpose: Used by Yandex Metrica to determine if a visitor has ad blockers installed in their browsers</span></p>
                <p><span >Provider: .vitaforest.eu</span></p>
                <p><span >Service: Metrica View Service Privacy Policy</span></p>
                <p><span >Country: United States</span></p>
                <p><span >Type: http_cookie</span></p>
                <p><span >Expires in: 20 hours</span></p>
                <p><span >Name: _ym_d</span></p>
                <p><span >Purpose: Used by Yandex Metrica to determine the date of the user’s first site session.</span></p>
                <p><span >Provider: .vitaforest.eu</span></p>
                <p><span >Service: Metrica View Service Privacy Policy</span></p>
                <p><span >Country: United States</span></p>
                <p><span >Type: http_cookie</span></p>
                <p><span >Expires in: 11 months 30 days</span></p>
                <p><span >Name: _ym_uid</span></p>
                <p><span >Purpose: Used by Yandex Metrica as a unique user ID to help track a user in a website</span></p>
                <p><span >Provider: vitaforest.eu</span></p>
                <p><span >Service: Metrica View Service Privacy Policy</span></p>
                <p><span >Country: United States</span></p>
                <p><span >Type: html_local_storage</span></p>
                <p><span >Expires in: persistent</span></p>
                <p><span >Name: 809692222/</span></p>
                <p><span >Purpose: __________</span></p>
                <p><span >Provider: vitaforest.eu</span></p>
                <p><span >Service: __________</span></p>
                <p><span >Country: United States</span></p>
                <p><span >Type: pixel_tracker</span></p>
                <p><span >Expires in: session</span></p>
                <p><span >Name: i</span></p>
                <p><span >Purpose: Used by Yandex Metrica for identifying site users. This cookie exists for a period of 1 year maximum.</span></p>
                <p><span >Provider: .yandex.ru</span></p>
                <p><span >Service: Yandex Metrica View Service Privacy Policy</span></p>
                <p><span >Country: Russia</span></p>
                <p><span >Type: http_cookie</span></p>
                <p><span >Expires in: 9 years 11 months 28 days</span></p>
                <p><b>Advertising cookies:</b></p>
                <p><span >These cookies are used to make advertising messages more relevant to you. They perform functions like preventing the same ad from continuously</span></p>
                <p><span >reappearing, ensuring that ads are properly displayed for advertisers, and in some cases selecting advertisements that are based on your interests.</span></p>
                <p><span >Name: _gcl_au</span></p>
                <p><span >Purpose: Used by Google AdSense for experimenting with advertisement efficiency across websites using their services.</span></p>
                <p><span >Provider: .vitaforest.eu</span></p>
                <p><span >Service: AdSense View Service Privacy Policy</span></p>
                <p><span >Country: United States</span></p>
                <p><span >Type: http_cookie</span></p>
                <p><span >Expires in: 2 months 29 days</span></p>
                <p><span >Name: bcookie</span></p>
                <p><span >Purpose: Used to optimize the range of advertising on Linkedin</span></p>
                <p><span >Provider: .linkedin.com</span></p>
                <p><span >Service: Linkedin Ad Analytics View Service Privacy Policy</span></p>
                <p><span >Country: United States</span></p>
                <p><span >Type: http_cookie</span></p>
                <p><span >Expires in: 1 year 11 months 30 days</span></p>
                <p><span >Name: test_cookie</span></p>
                <p><span >Purpose: A session cookie used to check if the user’s browser supports cookies.</span></p>
                <p><span >Provider: .doubleclick.net</span></p>
                <p><span >Service: DoubleClick View Service Privacy Policy</span></p>
                <p><span >Country: United States</span></p>
                <p><span >Type: server_cookie</span></p>
                <p><span >Expires in: 15 minutes</span></p>
                <p><span >Name: _fbp</span></p>
                <p><span >Purpose: Facebook tracking pixel used to identify visitors for personalized advertising.</span></p>
                <p><span >Provider: .vitaforest.eu</span></p>
                <p><span >Service: Facebook View Service Privacy Policy</span></p>
                <p><span >Country: United States</span></p>
                <p><span >Type: http_cookie</span></p>
                <p><span >Expires in: 2 months 29 days</span></p>
                <p><span >Name: ga-audiences</span></p>
                <p><span >Purpose: Used by Google AdWords to re-engage visitors that are likely to convert to customers based on the visitor’s online behaviour across w</span></p>
                <p><span >ebsites</span></p>
                <p><span >Provider: vitaforest.eu</span></p>
                <p><span >Service: AdWords View Service Privacy Policy</span></p>
                <p><span >Country: United States</span></p>
                <p><span >Type: pixel_tracker</span></p>
                <p><span >Expires in: session</span></p>
                <p><span >Name: lidc</span></p>
                <p><span >Purpose: These cookies are associated with a B2B marketing platform, formerly known as Bizo, which is now owned by LinkedIn, the business</span></p>
                <p><span >networking platform. This sub-domain is connected with LinkedIn’s marketing services that enable website owners to gain insight into t</span></p>
                <p><span >ypes of users on their site based on LinkedIn profile data, to improve targeting.</span></p>
                <p><span >Provider: .linkedin.com</span></p>
                <p><span >Service: LinkedIn View Service Privacy Policy</span></p>
                <p><span >Country: United States</span></p>
                <p><span >Type: server_cookie</span></p>
                <p><span >Expires in: 1 day</span></p>
                <p><span >Name: UserMatchHistory</span></p>
                <p><span >Purpose:</span></p>
                <p><span >These cookies are associated with a B2B marketing platform, formerly known as Bizo, which is now owned by LinkedIn, the business</span></p>
                <p><span >networking platform. This sub-domain is connected with LinkedIn’s marketing services that enable website owners to gain insight into t</span></p>
                <p><span >ypes of users on their site based on LinkedIn profile data, to improve targeting.</span></p>
                <p><span >Provider: .linkedin.com</span></p>
                <p><span >Service: LinkedIn View Service Privacy Policy</span></p>
                <p><span >Country: United States</span></p>
                <p><span >Type: http_cookie</span></p>
                <p><span >Expires in: 30 days</span></p>
                <p><span >Name: wc_fragments_#</span></p>
                <p><span >Purpose: Used to remember items a user has placed in a shopping cart and ensure that cart functionality works perfectly.It expires immediately</span></p>
                <p><span >a user leaves the site.</span></p>
                <p><span >Provider: vitaforest.eu</span></p>
                <p><span >Service: Woocommerce View Service Privacy Policy</span></p>
                <p><span >Country: United States</span></p>
                <p><span >Type: html_session_storage</span></p>
                <p><span >Expires in: session</span></p>
                <p><b>Social networking cookies:</b></p>
                <p><span >These cookies are used to enable you to share pages and content that you find interesting on our Websites through third party social networking and</span></p>
                <p><span >other websites. These cookies may also be used for advertising purposes.</span></p>
                <p><span >Name: bscookie</span></p>
                <p><span >Purpose: Cookie used for Sign-in with Linkedin and/or for Linkedin follow feature on 3rd party websites</span></p>
                <p><span >Provider: .www.linkedin.com</span></p>
                <p><span >Service: Linkedin Ad Analytics View Service Privacy Policy</span></p>
                <p><span >Country: United States</span></p>
                <p><span >Type: server_cookie</span></p>
                <p><span >Expires in: 1 year 11 months 30 days</span></p>
                <p><b>Unclassified cookies:</b></p>
                <p><span >These are cookies that have not yet been categorized. We are in the process of classifying these cookies with the help of their providers.</span></p>
                <p><span >Name: lang</span></p>
                <p><span >Purpose: __________</span></p>
                <p><span >Provider: .linkedin.com</span></p>
                <p><span >Service: __________</span></p>
                <p><span >Country: United States</span></p>
                <p><span >Type: http_cookie</span></p>
                <p><span >Expires in: session</span></p>
                <p><span >Name: bx-vuex-3162021e307e50d6e26b8ed385b07edd</span></p>
                <p><span >Purpose: __________</span></p>
                <p><span >Provider: vitaforest.eu</span></p>
                <p><span >Service: __________</span></p>
                <p><span >Country: United States</span></p>
                <p><span >Type: html_local_storage</span></p>
                <p><span >Expires in: persistent</span></p>
                <p><span >Name: _ym_wv2rf:65590639:0</span></p>
                <p><span >Purpose: __________</span></p>
                <p><span >Provider: vitaforest.eu</span></p>
                <p><span >Service: __________</span></p>
                <p><span >Country: United States</span></p>
                <p><span >Type: html_local_storage</span></p>
                <p><span >Expires in: persistent</span></p>
                <p><span >Name: li_sugr</span></p>
                <p><span >Purpose: __________</span></p>
                <p><span >Provider: .linkedin.com</span></p>
                <p><span >Service: __________</span></p>
                <p><span >Country: United States</span></p>
                <p><span >Type: server_cookie</span></p>
                <p><span >Expires in: 2 months 29 days</span></p>
                <p><span >Name: wc_cart_hash_2c491fa4a461caf2283e3e7189189eb0</span></p>
                <p><span >Purpose: __________</span></p>
                <p><span >Provider: vitaforest.eu</span></p>
                <p><span >Service: __________</span></p>
                <p><span >Country: United States</span></p>
                <p><span >Type: html_local_storage</span></p>
                <p><span >Expires in: persistent</span></p>
                <p><span >Name: _ym65590639_reqNum</span></p>
                <p><span >Purpose: __________</span></p>
                <p><span >Provider: vitaforest.eu</span></p>
                <p><span >Service: __________</span></p>
                <p><span >Country: United States</span></p>
                <p><span >Type: html_local_storage</span></p>
                <p><span >Expires in: persistent</span></p>
                <p><span >Name: __vw_tab_guid</span></p>
                <p><span >Purpose: __________</span></p>
                <p><span >Provider: vitaforest.eu</span></p>
                <p><span >Service: __________</span></p>
                <p><span >Country: United States</span></p>
                <p><span >Type: html_session_storage</span></p>
                <p><span >Expires in: session</span></p>
                <p><span >Name: wp_woocommerce_session_a50968d3c57571918335f0581934f9de</span></p>
                <p><span >Purpose: __________</span></p>
                <p><span >Provider: vitaforest.eu</span></p>
                <p><span >Service: __________</span></p>
                <p><span >Country: United States</span></p>
                <p><span >Type: server_cookie</span></p>
                <p><span >Expires in: 2 days</span></p>
                <p><span >Name: trp_language</span></p>
                <p><span >Purpose: __________</span></p>
                <p><span >Provider: vitaforest.eu</span></p>
                <p><span >Service: __________</span></p>
                <p><span >Country: United States</span></p>
                <p><span >Type: http_cookie</span></p>
                <p><span >Expires in: 30 days</span></p>
                <p><span >Name: AnalyticsSyncHistory</span></p>
                <p><span >Purpose: __________</span></p>
                <p><span >Provider: .linkedin.com</span></p>
                <p><span >Service: __________</span></p>
                <p><span >Country: United States</span></p>
                <p><span >Type: server_cookie</span></p>
                <p><span >Expires in: 30 days</span></p>
                <p><span >Name: b24_crm_guest_pages</span></p>
                <p><span >Purpose: __________</span></p>
                <p><span >Provider: vitaforest.eu</span></p>
                <p><span >Service: __________</span></p>
                <p><span >Country: United States</span></p>
                <p><span >Type: html_local_storage</span></p>
                <p><span >Expires in: persistent</span></p>
                <p><span >Name: yabs-sid</span></p>
                <p><span >Purpose: __________</span></p>
                <p><span >Provider: mc.yandex.ru</span></p>
                <p><span >Service: __________</span></p>
                <p><span >Country: Russia</span></p>
                <p><span >Type: http_cookie</span></p>
                <p><span >Expires in: session</span></p>
                <p><span >Name: lang</span></p>
                <p><span >Purpose: __________</span></p>
                <p><span >Provider: .ads.linkedin.com</span></p>
                <p><span >Service: __________</span></p>
                <p><span >Country: United States</span></p>
                <p><span >Type: http_cookie</span></p>
                <p><span >Expires in: session</span></p>
                <p><span >Name: b24_crm_guest_utm</span></p>
                <p><span >Purpose: __________</span></p>
                <p><span >Provider: vitaforest.eu</span></p>
                <p><span >Service: __________</span></p>
                <p><span >Country: United States</span></p>
                <p><span >Type: html_local_storage</span></p>
                <p><span >Expires in: persistent</span></p>
                <p><span >Name: U</span></p>
                <p><span >Purpose: __________</span></p>
                <p><span >Provider: .adsymptotic.com</span></p>
                <p><span >Service: __________</span></p>
                <p><span >Country: United States</span></p>
                <p><span >Type: server_cookie</span></p>
                <p><span >Expires in: 2 months 29 days</span></p>
                <p><span >Name: _ym_retryReqs</span></p>
                <p><span >Purpose: __________</span></p>
                <p><span >Provider: vitaforest.eu</span></p>
                <p><span >Service: __________</span></p>
                <p><span >Country: United States</span></p>
                <p><span >Type: html_local_storage</span></p>
                <p><span >Expires in: persistent</span></p>
                <p><span >Name: ymex</span></p>
                <p><span >Purpose: __________</span></p>
                <p><span >Provider: .yandex.ru</span></p>
                <p><span >Service: __________</span></p>
                <p><span >Country: Russia</span></p>
                <p><span >Type: server_cookie</span></p>
                <p><span >Expires in: 11 months 30 days</span></p>
                <p><span >Name: _ym65590639_lsid</span></p>
                <p><span >Purpose: __________</span></p>
                <p><span >Provider: vitaforest.eu</span></p>
                <p><span >Service: __________</span></p>
                <p><span >Country: United States</span></p>
                <p><span >Type: html_local_storage</span></p>
                <p><span >Expires in: persistent</span></p>
                <p><span >Name: yandexuid</span></p>
                <p><span >Purpose: __________</span></p>
                <p><span >Provider: .yandex.ru</span></p>
                <p><span >Service: __________</span></p>
                <p><span >Country: Russia</span></p>
                <p><span >Type: http_cookie</span></p>
                <p><span >Expires in: 11 months 30 days</span></p>
                <p><span >Name: _ym_visorc</span></p>
                <p><span >Purpose: __________</span></p>
                <p><span >Provider: .vitaforest.eu</span></p>
                <p><span >Service: __________</span></p>
                <p><span >Country: United States</span></p>
                <p><span >Type: http_cookie</span></p>
                <p><span >Expires in: 30 minutes</span></p>
                <p><b>What about other tracking technologies, like web beacons?</b></p>
                <p><span >Cookies are not the only way to recognize or track visitors to a website. We may use other, similar technologies from time to time, like web beacons</span></p>
                <p><span >(sometimes called “tracking pixels” or “clear gifs”). These are tiny graphics files that contain a unique identifier that enable us to recognize when</span></p>
                <p><span >someone has visited our Websites or opened an e-mail including them. This allows us, for example, to monitor the traffic patterns of users from one page</span></p>
                <p><span >within a website to another, to deliver or communicate with cookies, to understand whether you have come to the website from an online advertisement</span></p>
                <p><span >displayed on a third-party website, to improve site performance, and to measure the success of e-mail marketing campaigns. In many instances, these</span></p>
                <p><span >technologies are reliant on cookies to function properly, and so declining cookies will impair their functioning.</span></p>
                <p><b>Do you use Flash cookies or Local Shared Objects?</b></p>
                <p><span >Websites may also use so-called “Flash Cookies” (also known as Local Shared Objects or “LSOs”) to, among other things, collect and store information</span></p>
                <p><span >about your use of our services, fraud prevention and for other site operations.</span></p>
                <p><span >If you do not want Flash Cookies stored on your computer, you can adjust the settings of your Flash player to block Flash Cookies storage using the</span></p>
                <p><span >tools contained in the Website Storage Settings Panel. You can also control Flash Cookies by going to the Global Storage Settings</span></p>
                <p><span >Panel and following the instructions (which may include instructions that explain, for example, how to delete existing Flash Cookies (referred to</span></p>
                <p><span >“information” on the Macromedia site), how to prevent Flash LSOs from being placed on your computer without your being asked, and (for Flash Player 8</span></p>
                <p><span >and later) how to block Flash Cookies that are not being delivered by the operator of the page you are on at the time).</span></p>
                <p><span >Please note that setting the Flash Player to restrict or limit acceptance of Flash Cookies may reduce or impede the functionality of some Flash</span></p>
                <p><span >applications, including, potentially, Flash applications used in connection with our services or online content.</span></p>
                <p><b>Do you serve targeted advertising?</b></p>
                <p><span >Third parties may serve cookies on your computer or mobile device to serve advertising through our Websites. These companies may use information</span></p>
                <p><span >about your visits to this and other websites in order to provide relevant advertisements about goods and services that you may be interested in. They</span></p>
                <p><span >may also employ technology that is used to measure the effectiveness of advertisements. This can be accomplished by them using cookies or web</span></p>
                <p><span >beacons to collect information about your visits to this and other sites in order to provide relevant advertisements about goods and services of potential</span></p>
                <p><span >interest to you. The information collected through this process does not enable us or them to identify your name, contact details or other details that</span></p>
                <p><span >directly identify you unless you choose to provide these.</span></p>
                <p><span >How often will you update this Cookie Policy?</span></p>
                <p><span >We may update this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal or</span></p>
                <p><span >regulatory reasons. Please therefore re-visit this Cookie Policy regularly to stay informed about our use of cookies and related technologies.</span></p>
                <p><span >The date at the top of this Cookie Policy indicates when it was last updated.</span></p>
                <p><span >Where can I get further information?</span></p>
                <p><span >If you have any questions about our use of cookies or other technologies, please email us at info@vitaforest.eu or by post to:</span></p>
                <p><strong>Vitaforest Distribution OU</strong></p>
                <p><strong>Kesklinna linnaosa, Vesivärava tn 50-301</strong></p>
                <p><strong>Tallin, Harju maakond 11415</strong></p>
                <p><strong>Estonia</strong></p>
                <p><strong>Phone: 8801043</strong></p>
            </Container>
        </Page>
    )
}

export default CookiePolicy