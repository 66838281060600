import React, { useEffect, useMemo, useState } from "react";
import { Button, Spinner, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Table } from "react-bootstrap";
import { adminFetchTickets } from "../../../../api/ticketAPI";
import AdminUI from "../../Ui/AdminUI";
import { getAllOffers } from "../../../../api/offerAPI";
import LoadingPlaceholder from "../../../Functional/LoadingPlaceholder";

const TableContainer = styled.div`

`


const ControlsTitle = styled.span`
margin-right: 15px;
`

const TotalItems = styled.select`
`



const EditLabel = styled(Link)`
color: white;
text-decoration: none;
&:hover{
    color: white;
}
`

const FlexDiv = styled.div`
margin-top: 20px;
display: flex;
margin-bottom: 20px;
h1{
    margin: 0px;
    margin-right: 20px;
}
`

const OffersList = () => {
    const [items, setItems] = useState([]);
    const [needReload, setNeedReload] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const [error, setError] = useState(null)
    const [modalActive, setModalActive] = useState(false);
    const [currentItemId, setCurrentItemId] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(9);
    const [bulkSelected, setBulkSelected] = useState([]);

    const currentData = useMemo(() => {
        if (items.length > 0) {
            const firstPageIndex = (currentPage - 1) * pageSize;
            let lastPageIndex;
            if (pageSize > items.length) {
                lastPageIndex = firstPageIndex + items.length;
            }
            else {
                lastPageIndex = firstPageIndex + pageSize;
            }
            return items.slice(firstPageIndex, lastPageIndex);
        }
        else {
            return false
        }
    }, [currentPage, items, pageSize]);


    const navigate = useNavigate();

    useEffect(() => {
        getAllOffers().then((data) => {
            if (data.length > 0) {
                setItems(data)
                setIsLoaded(true)
            }
            else {
                setItems([]);
                setIsLoaded(true);
            }
        })
    }, [])
    if (!isLoaded) {
        return (
            <AdminUI>
                <LoadingPlaceholder />
            </AdminUI>
        )
    }
    else {
        return (
            <>
                {console.log(currentData)}
                {
                    items.length < 1 ? (<AdminUI><h1>Offers not founded</h1></AdminUI>) : (<AdminUI>
                        <FlexDiv>
                            <h1>Offers</h1>
                            <Button onClick={() => { navigate('/admin/offers/add') }}>Create new</Button>
                        </FlexDiv>
                        <h5>Items total: {items.length}</h5>
                        <Row className="mb-3">
                            <Col>
                                <ControlsTitle>Items per page:</ControlsTitle>
                                <TotalItems onChange={(e) => { setPageSize(e.target.value) }}>
                                    <option value={9}>Default</option>
                                    <option value={25}>25 items</option>
                                    <option value={50}>50 items</option>
                                    <option value={100}>100 items</option>
                                </TotalItems>
                            </Col>
                        </Row>
                        <Table striped bordered hover variant="dark">
                            <thead>
                                <tr>
                                    <th>
                                        #
                                    </th>
                                    <th>
                                        Subject
                                    </th>
                                    <th>
                                        Type
                                    </th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentData.map((item) => (
                                    <tr>
                                        <td>{item.id}</td>
                                        <td>{item.name}</td>
                                        <td>{item.type}</td>
                                        <td><Button onClick={() => { navigate(`/admin/offers/edit/${item.id}`) }}>Edit</Button></td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </AdminUI>)
                }
            </>)
    }
}

export default OffersList