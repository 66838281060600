import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ShopProducts from "../components/VitaforestUI/Shop/ShopProducts";
import Page from "../components/VitaforestUI/Interface/Page/Page";
import { ReactComponent as Burger } from "../components/VitaforestUI/Shop/images/Burger.svg";
import { ReactComponent as Filter } from "../components/VitaforestUI/Shop/images/filter.svg";
import { ReactComponent as Sliders } from "../components/VitaforestUI/Shop/images/sliders.svg";
import { ReactComponent as Sort } from "../components/VitaforestUI/Shop/images/sort.svg";
import MediaQuery from "react-responsive";
import ShopFilter from "../components/VitaforestUI/Shop/ShopFilter";
import StatusFilter from "../components/VitaforestUI/Shop/FilterComponents/StatusFilter";
import { fetchProducts } from "../api/productAPI";
import LoadingPlaceholder from "../components/Functional/LoadingPlaceholder";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getUrlData } from "../api/urlsApi";
import { Helmet } from "react-helmet";
import CategoryDescription from "../components/VitaforestUI/Shop/CategoryDescription";
import DefaultButton from "../components/VitaforestUI/Interface/Buttons/DefaultButton";
const ShopContainer = styled.div`
  width: calc(100vw - 36px);
  margin: 0 auto;
  position: relative;
  @media screen and (min-width: 1128px) {
    width: 1128px;
    margin-bottom: 40px;
  }
  .darked {
    z-index: 2;
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: #000;
    opacity: 0.7;
    display: none;
    left: -18px;
  }
  .darked.shown {
    display: block;
  }
`;

const ShopFlex = styled.div`
  @media screen and (min-width: 1128px) {
    display: flex;
  }
`;

const MobileButtons = styled.div`
  right: 5px;
  display: flex;
  width: 150px;
  justify-content: flex-end;
  gap: 10px;
`;

const FiltersButton = styled.button`
  position: relative;
  border: none;
  background-color: transparent;
  width: 30px;
  height: 30px;
  &:active,
  &:hover,
  &:focus {
    background: #f3f7f2;
  }
`;

const BurgerButton = styled(Burger)`
  &.active {
    background: #f3f7f2;
  }
`;

const ShopHeading = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 17px;
  color: #303236;
  margin-top: 17px;
  margin-bottom: 15px;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SortDropdown = styled.ul`
  position: absolute;
  display: none;
  right: 20px;
  list-style: none;
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(26, 33, 45, 0.1);
  border-radius: 4px;
  padding: 6px 0px;
  width: auto;
  z-index: 4;
  &.opened {
    display: block;
  }
  li {
    padding: 8px 15px;
    color: #303236;
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    white-space: nowrap;
  }
`;

const StyledSort = styled(Sort)`
  position: relative;
`;

const ErrorMessage = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
`

const Column = styled.div`
  width: 100%;
`;

function Shop() {
  const { param } = useParams();
  let currentVisual = localStorage.getItem("grid");
  if (currentVisual === "false") {
    currentVisual = false;
  }
  const [isCard, setIsCard] = useState(!currentVisual ? currentVisual : true);
  const [isFilterShown, setIsFilterShown] = useState(false);
  const [statusFilter, setStatusFilter] = useState([]);
  const [attributesFilters, setAttributesFilters] = useState([]);
  const [reload, setReload] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [items, setItems] = useState([]);
  const [staticItems, setStaticItems] = useState([]);
  const [error, setError] = useState(null);
  const [sortType, setSortType] = useState("");
  const [sortOpen, setSortOpen] = useState(false);
  const [urlData, setUrlData] = useState({
    seo_title: "Main",
    seo_description: "Vitaforest shop page",
  });

  useEffect(() => {
    getUrlData(param).then((data) => {
      if (data.seo_title) {
        setAttributesFilters(data.attributeId);
        setCategoryFilter(data.categoryId);
        setUrlData(data);
      }
    });
  }, [param]);

  useEffect(() => {
    switch (param) {
      case "extracts":
        categoryFilter.push(1);
        break;
      case "fruit-extracts":
        categoryFilter.push(2);
        break;
      case "herbal-extracts":
        categoryFilter.push(3);
        break;
      case "mushroom-extracts":
        categoryFilter.push(4);
        break;
      case "powders":
        categoryFilter.push(5);
        break;
      case "fruit-powders":
        categoryFilter.push(6);
        break;
      case "herbal-powders":
        categoryFilter.push(7);
        break;
      case "mushroom-powders":
        categoryFilter.push(8);
        break;
      case "food-supplements":
        attributesFilters.push(7);
        break;
      case "food-and-beverages":
        attributesFilters.push(6);
        break;
      case "feed-additives":
        attributesFilters.push(5);
        break;
      case "cosmetology":
        attributesFilters.push(4);
        break;
      case "healthy-nutrition":
        attributesFilters.push(8);
        break;
      case "pharmaceutical-industry":
        attributesFilters.push(9);
        break;
      case "in-stock":
        statusFilter.push("Avaliable");
        break;
    }
    fetchProducts(attributesFilters, categoryFilter)
      .then((data) => {
        const filteredData = statusFilter[0]
          ? data.filter((item) => statusFilter.includes(item.status))
          : data;
        const sortedData =
          sortType === "pasc"
            ? filteredData.sort((a, b) => sortByPrice(a, b, true))
            : sortType === "pdsc"
              ? filteredData.sort((a, b) => sortByPrice(a, b, false))
              : filteredData;
        setStaticItems(data);
        setItems(sortedData);
        setIsLoaded(true);
      });
  }, [reload]);


  const sortByPrice = (a, b, isAsc) => {
    if (a.price === 0 && b.price === 0) return 0;
    if (a.price === 0) return 1;
    if (b.price === 0) return -1;
    return isAsc ? a.price - b.price : b.price - a.price;
  };

  const changeVisual = () => {
    localStorage.setItem("grid", !isCard);
    setIsCard(!isCard);
  };

  if (error) {
    return (
      <Page>
        <ErrorMessage style={{ marginTop: '30px' }}>
          <h3>{error}</h3>
          <DefaultButton onClick={() => { window.location.reload() }} title={'Reload page'} style={{ marginTop: '30px' }} />
        </ErrorMessage>
      </Page>
    );
  } else if (!error && !isLoaded) {
    return (
      <Page>
        <LoadingPlaceholder />
      </Page>
    );
  } else {
    return (
      <Page
        seo_title={`${urlData.seo_title} - Shop - Vitaforest`}
        seo_description={urlData.seo_description}
      >
        <ShopContainer>
          <div className={isFilterShown ? "darked shown" : "darked"}></div>
          <MediaQuery maxWidth={"1128px"}>
            <Flex>
              <ShopHeading>Products</ShopHeading>
              <MobileButtons>
                <FiltersButton onClick={() => setIsCard(!isCard)}>
                  <BurgerButton className={!isCard ? "active" : null} />
                </FiltersButton>
                <FiltersButton>
                  <Filter />
                </FiltersButton>
                <FiltersButton onClick={() => setIsFilterShown(!isFilterShown)}>
                  <Sliders />
                </FiltersButton>
                <FiltersButton>
                  <Sort
                    onClick={() => {
                      sortOpen ? setSortOpen(false) : setSortOpen(true);
                    }}
                  />
                  <SortDropdown className={sortOpen ? "opened" : null}>
                    <li
                      onClick={() => {
                        setSortType("pasc");
                        setReload(!reload);
                        sortOpen ? setSortOpen(false) : setSortOpen(true);
                      }}
                    >
                      Sort by price (ascending)
                    </li>
                    <li
                      onClick={() => {
                        setSortType("pdsc");
                        setReload(!reload);
                        sortOpen ? setSortOpen(false) : setSortOpen(true);
                      }}
                    >
                      Sort by price (descendign)
                    </li>
                  </SortDropdown>
                </FiltersButton>
              </MobileButtons>
            </Flex>
          </MediaQuery>
          <ShopFlex>
            <ShopFilter
              isShown={isFilterShown}
              onClick={() => setIsFilterShown(!isFilterShown)}
              attributesFilters={attributesFilters}
              categoryFilter={categoryFilter}
              setCategoryFilter={setCategoryFilter}
              setAttributesFilters={setAttributesFilters}
              reload={reload}
              setReload={setReload}
              products={staticItems}
            />
            <Column>
              <MediaQuery minWidth={"1128px"}>
                <Flex>
                  <ShopHeading>Products</ShopHeading>
                  <MobileButtons>
                    <FiltersButton onClick={() => changeVisual()}>
                      <BurgerButton className={!isCard ? "active" : null} />
                    </FiltersButton>
                    <FiltersButton>
                      <Filter />
                    </FiltersButton>
                    <FiltersButton>
                      <Sort
                        onClick={() => {
                          sortOpen ? setSortOpen(false) : setSortOpen(true);
                        }}
                      />
                      <SortDropdown className={sortOpen ? "opened" : null}>
                        <li
                          onClick={() => {
                            setSortType("pasc");
                            setReload(!reload);
                            sortOpen ? setSortOpen(false) : setSortOpen(true);
                          }}
                        >
                          Sort by price (ascending)
                        </li>
                        <li
                          onClick={() => {
                            setSortType("pdsc");
                            setReload(!reload);
                            sortOpen ? setSortOpen(false) : setSortOpen(true);
                          }}
                        >
                          Sort by price (descendign)
                        </li>
                      </SortDropdown>
                    </FiltersButton>
                  </MobileButtons>
                </Flex>
              </MediaQuery>
              <CategoryDescription param={param} />
              <StatusFilter
                statusFilter={statusFilter}
                setStatusFilter={setStatusFilter}
                reload={reload}
                setReload={setReload}
                attributesFilters={attributesFilters}
                setAttributesFilters={setAttributesFilters}
                categoryFilter={categoryFilter}
                setCategoryFilter={setCategoryFilter}
                url={param}
              />

              <ShopProducts
                isCard={isCard}
                filters={attributesFilters}
                reload={reload}
                products={items}
              />
            </Column>
          </ShopFlex>
        </ShopContainer>
      </Page>
    );
  }
}

export default Shop;
