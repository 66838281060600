import React, { useState } from "react";
import Page from "../components/VitaforestUI/Interface/Page/Page";
import styled from "styled-components";
import FAQquestion from "../components/VitaforestUI/Interface/TextElements/FAQquestion";
import { Helmet } from "react-helmet";
const Container = styled.div`
  width: calc(100vw - 36px);
  margin: 30px auto;
  @media screen and (min-width: 1128px) {
    width: 1128px;
    margin-top: 36px;
  }
`;

const Preview = styled.div`
  height: 530px;
  background: url("/production-preview.webp");
  background-size: cover;
  margin-top: -10px;
  @media screen and (min-width: 1128px) {
    height: 380px;
  }
`;

const PreviewTitle = styled.h2`
  font-weight: 700;
  font-size: 38px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
  padding-top: 100px;
  position: relative;

  @media screen and (min-width: 1128px) {
    padding-top: 110px;
    font-size: 32px;
    line-height: 36px;
  }
`;

const PreviewDescription = styled.p`
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  width: 250px;
  margin-top: 15px;
  margin: 15px auto;
  @media screen and (min-width: 1128px) {
    width: 364px;
    margin-top: 6px;
  }
`;

const SearchDescription = styled.p`
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  margin-top: 15px;
`;

const SearchForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  margin: 0 auto;
  margin-top: 90px;
  @media screen and (min-width: 1128px) {
    width: 744px;
    flex-direction: row;
  }
`;

const SearchInput = styled.input`
  width: 284px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #eaebec;
  border-radius: 4px;
  text-indent: 10px;
  @media screen and (min-width: 1128px) {
    width: 654px;
    border-radius: 4px 0px 0px 4px;
    &:focus-visible,
    &:focus {
      outline: none;
    }
  }
`;

const SearchButton = styled.button`
  width: 284px;
  height: 48px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  background: #40bf6a;
  border-radius: 4px;
  justify-content: center;
  margin-top: 10px;
  border: none;
  @media screen and (min-width: 1128px) {
    width: 90px;
    margin-top: 0;
    border-radius: 0px 4px 4px 0px;
  }
`;

const Section = styled.section``;

const SectionTitle = styled.h3`
  font-weight: 600;
  font-size: 20px;
  line-height: 17px;
  color: #303236;
  padding-left: 35px;
  margin: 16px 0;
  position: relative;
  &::before {
    position: absolute;
    left: 0;
  }
  @media screen and (min-width: 1128px) {
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 28px;
    color: #303236;
    height: 72px;
    width: 280px;
    padding-left: 82px;
    position: relative;
    margin-top: 22px;
    &::before {
      position: absolute;
      left: 0;
      top: -7px;
    }
  }
`;

const BuyTitle = styled(SectionTitle)`
  &::before {
    content: url("/mhowtobuy.svg");
    @media screen and (min-width: 1128px) {
      content: url("/howtobuy.svg");
    }
  }
`;

const RefundTitle = styled(SectionTitle)`
  &::before {
    content: url("/mrefund.svg");
    @media screen and (min-width: 1128px) {
      content: url("/refund.svg");
    }
  }
`;
const SupportTitle = styled(SectionTitle)`
  &::before {
    content: url("/msupport.svg");
    @media screen and (min-width: 1128px) {
      content: url("/support.svg");
    }
  }
`;

const AbboutTitle = styled(SectionTitle)`
  &::before {
    content: url("/maboutpr.svg");
    @media screen and (min-width: 1128px) {
      content: url("/aboutpr.svg");
    }
  }
`;

const ImpactTitle = styled(SectionTitle)`
  &::before {
    content: url("/mimpact.svg");
    @media screen and (min-width: 1128px) {
      content: url("/impact.svg");
    }
  }
`;

const AccountTitle = styled(SectionTitle)`
  &::before {
    content: url("/mmy-acc-kb.svg");
    @media screen and (min-width: 1128px) {
      content: url("/my-acc-kb.svg");
    }
  }
`;

const OrderTitle = styled(SectionTitle)`
  &::before {
    content: url("/morder.svg");
    @media screen and (min-width: 1128px) {
      content: url("/order.svg");
    }
  }
`;

const SectionContent = styled.div`
  box-shadow: 0px 1px 0px #eaebec;
  padding-bottom: 10px;
  @media screen and (min-width: 1128px) {
    width: 740px;
    margin: 0 auto;
    padding-bottom: 41px;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
`;
const SetcionQuestion = styled.p`
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  padding-left: 10px;
  position: relative;
  margin-bottom: 15px;
  margin-top: 0;
  &::before {
    content: "•";
    position: absolute;
    left: 0px;
  }
`;

const SectionList = styled.div`
  @media screen and (min-width: 1128px) {
    width: 456px;
    padding-top: 31px;
  }
`;

const SectionAnswer = styled.p`
  display: none;
  background: #f7f8f9;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  text-align: justify;
  color: #303236;
  box-sizing: border-box;
  padding: 10px;
  margin-bottom: 20px;
  &.active {
    display: block;
  }
`;

const OnClick = () => {
  this.nextElementSibling.classList.toogle("active");
};
export default function FAQ() {
  const [search, setSearch] = useState("");
  const [searchValue, setSearchValue] = useState("");

  return (
    <Page seo_title={`FAQ - Vitaforest`} seo_description={'Vitaforest FAQ page'}>
      <Preview>
        <PreviewTitle>Knowledge base</PreviewTitle>
        <PreviewDescription>
          Search our articles or browse by category below
        </PreviewDescription>
        <SearchForm>
          <SearchInput
            placeholder="How do we help?"
            onChange={(e) => setSearch(e.target.value)}
          />
          <SearchButton onClick={() => setSearchValue(search)}>
            Search
          </SearchButton>
        </SearchForm>
      </Preview>
      <Container>
        <SectionContent>
          <BuyTitle>How to buy?</BuyTitle>
          <SectionList>
            <FAQquestion
              question={" How is the payment made?"}
              answer=" Payment is made on the basis of the proforma invoice or order
              order. Currently only by bank transfer.."
            />
            <FAQquestion
              question="How is the delivery of goods?"
              answer="Goods are delivered by Europiir Logistics or DHL. At your request,
              we will send the available goods in any way convenient for you
              from Tallinn within 2 days after making the payment. The average
              production and delivery time for custom-made goods is 35–40"
            />
            <FAQquestion
              question="How fast can I get the goods?"
              answer=" Shipment of goods from the warehouse is carried out on day 2 after
              payment. The average production and delivery time for custom-made
              goods is 35–40 days."
            />
          </SectionList>
        </SectionContent>
        <SectionContent>
          <RefundTitle>Refunds</RefundTitle>
          <SectionList>
            <FAQquestion
              question="How to return the goods?"
              answer="If it is documented that the product is subject to replacement and
              the factory packaging is not damaged, you can exchange the product
              for a new one or return its full cost. Refunds are made within 14
              days after the receipt and inspection of the goods by our
              specialists."
            />
            <FAQquestion
              question="How is the payment made?"
              answer=" The goods are subject to return if its declared characteristics
              (active substances, safety parameters) do not correspond to the
              actual ones. Tested in an independent laboratory, there is an
              official conclusion. Tests on safety parameters must be carried
              o..."
            />
          </SectionList>
        </SectionContent>
        <SectionContent>
          <SupportTitle>Support</SupportTitle>
          <SectionList>
            <FAQquestion
              question=" How to get advice on the company, product?"
              answer="You can contact us by phone +372 8801043, by email
              info@vitarorest.eu, via the feedback form or contacting the chat
              on the site for urgent questions."
            />
            <FAQquestion
              question="How can I check the status of the ticket?"
              answer="All tickets you create are displayed in the My Tickets section.
              Please note that it may take some time to process your question.
              Upon the response, you will receive a notification to your e-mail."
            />
            <FAQquestion
              question="How can I contact you?"
              answer="  You can contact us by phone +372 8801043, by email
              info@vitaforest.eu, via the feedback form or contacting the chat
              on the site for urgent questions."
            />
          </SectionList>
        </SectionContent>
        <SectionContent>
          <AbboutTitle>About products</AbboutTitle>
          <SectionList>
            <FAQquestion
              question="Are your products vegan, gluten GMO free?"
              answer="All our products are gluten &amp; GMO free. Pure Chaga is vegan
              and contains no animal products. It is guaranteed GMO free as it
              is produced exclusively from wild harvested Siberian Chaga
              mushroom."
            />
            <FAQquestion
              question="Does your Chaga products contain caffeine?"
              answer=" No – none of our products contain caffeine or any other types of
              stimulants of any kind."
            />
            <FAQquestion
              question="Is Chaga mushroom hallucinogenic (psycho active)?"
              answer="Chaga contains no hallucinogenic components whatsoever and is not
              part of the psycho active mushroom family."
            />
          </SectionList>
        </SectionContent>
        <SectionContent>
          <ImpactTitle>Impact on nature</ImpactTitle>
          <SectionList>
            <FAQquestion
              question="Harvesting wild materials damages nature?"
              answer="No, we adhere to the principle of careful collection of sources
              and carefully monitor compliance with the rules for the
              procurement of raw materials by attracted collectors."
            />
          </SectionList>
        </SectionContent>
        <SectionContent>
          <AccountTitle>My account</AccountTitle>
          <SectionList>
            <FAQquestion
              question="I can not log into my account"
              answer="First, make sure you get a letter of access by mail. If you
              received a letter, but the authorization fails, double-check if
              you enter the username and password correctly. If, under all the
              above conditions, the entrance fails, please contact us via the
              chat on the website, by phone +372 8801043 or by mail
              info@vitarorest.eu"
            />
            <FAQquestion
              question="How to delete an account?"
              answer="To delete your account and data associated with it, click the
              'Delete My Data' button in the Privacy Tools page."
            />
            <FAQquestion
              question="How can I change/update my data?"
              answer="You can independently change the account information in the
              Account Information and Address Book sections, as well as contact
              us via chat on the site or by creating a ticket in your account."
            />
          </SectionList>
        </SectionContent>
        <SectionContent>
          <OrderTitle>Order, payment, invoice</OrderTitle>
          <SectionList>
            <FAQquestion
              question=" Do you have special offers / prices for large orders?"
              answer="Yes, we are open to cooperation. You can request an individual
              offer"
            />
            <FAQquestion
              question=" Is it possible to change/update my address in the invoice?"
              answer="Yes, you can issue a ticket in your account with a description of
              the necessary changes."
            />
            <FAQquestion
              question="I placed an order, but I didn't receive any confirmation or email?"
              answer=" Make sure you place your order in your account. To do this, go to
              the “My order” tab on the left side panel to check the status of
              the placed order. If the problem is not resolved, create a ticket
              in your account and get detailed advice from our manager."
            />
            <FAQquestion
              question=" Where can I pick up the goods by myself?"
              answer="The goods are shipped in Tallinn (Estonia) 2 days after payment of
              the invoice."
            />
          </SectionList>
        </SectionContent>
      </Container>
    </Page>
  );
}
