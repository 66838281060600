import { $authHost, $host } from ".";

export const createAttribute = async (body) => {
    try {
        const { data } = await $authHost.post('/api/attribute/create', body);
        return data;
    } catch (e) {
        return e;
    }
}

export const createAttributeTerm = async (body) => {
    try {
        const { data } = await $authHost.post('/api/attribute/terms/create', body);
        return data;
    } catch (e) {
        return e;
    }
}


export const updateAttribute = async (body) => {
    try {
        const { data } = await $authHost.post('/api/attribute/update', body);
        return data;
    } catch (e) {
        return e;
    }
}


export const updateAttributeTerm = async (body) => {
    try {
        const { data } = await $authHost.post('/api/attribute/terms/update', body);
        return data;
    } catch (e) {
        return e;
    }
}

export const fetchAttributes = async () => {
    try {
        const { data } = await $host.get('/api/attribute');
        return data;
    } catch (e) {
        return e;
    }
}

export const removeAttribute = async (id) => {
    try {
        const { data } = await $authHost.post(`/api/attribute/remove/${id}`);
        return data;
    } catch (e) {
        return e;
    }
}

export const removeAttributeTerm = async (id) => {
    try {
        const { data } = await $authHost.post(`/api/attribute/terms/remove/${id}`);
        return data;
    } catch (e) {
        return e;
    }
}

export const fetchAttributeTerms = async (id) => {
    try {
        const { data } = await $authHost.get(`/api/attribute/terms/getbyid/${id}`);
        return data;
    } catch (e) {
        return e;
    }
}


export const calcAttrProductsTotal = (id, products, isCatFilter) => {
    let matchingCount = 0;
    if (!isCatFilter) {
        products.map((item) => {
            if (item.attributeId.includes(id)) {
                matchingCount++;
            }
        })
    }
    else {
        products.map((item) => {
            if (item.category.includes(id)) {
                matchingCount++;
            }
        })
    }
    return matchingCount;
}