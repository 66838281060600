import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import MediaQuery from "react-responsive";
import PageHeader from "../subcomponents/PageHeader";
import Page from "../../Interface/Page/Page";
import AccountMenu from "../subcomponents/AccountMenu";
import Heading from "../../Interface/TextElements/Heading";
import { Country, State, City } from "country-state-city";
import Input from "../../Interface/Input/Input";
import Select from "../../Interface/Select/Select";
import Required from "../../Interface/TextElements/Required";
import DefaultButton from "../../Interface/Buttons/DefaultButton";
import { Helmet } from "react-helmet";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { Context } from "../../../..";
import { addNewAddress } from "../../../../api/addressAPI";
import { Link, useNavigate } from "react-router-dom";

const ContentContainer = styled.div`
position: relative;
  margin-top: 20px;
  width: calc(100vw - 36px);
  margin: 30px auto;
  @media screen and (min-width: 1128px) {
    width: auto;
    display: flex;
    margin-top: 30px;
    margin-bottom: 40px;
  }
`;
const TemporaryHide = styled.div`
  @media screen and (max-width: 1127px) {
    display: none;
  }
`;
const Container = styled.div``;

const Wrapper = styled.div`
  @media screen and (min-width: 1128px) {
    width: auto;
    margin: 0;
  }
`;

const Form = styled.form`
  @media screen and (min-width: 1128px) {
    width: 456px;
  }
`;

const FormRequired = styled(Required)`
  margin-top: 20px;
`;

const FormButton = styled(DefaultButton)`
  width: 284px;
  margin: 0 auto;
  @media screen and (min-width: 1128px) {
    width: 130px;
    margin: 0px;
  }
`;

const MobileBackButton = styled(Link)`
&:before{
  content: url('/arrow-back.svg');
}
text-decoration: none;
color: black;
position: absolute;
top: -5px;
vertical-align: center;
@media screen and (min-width: 1128px){
  display: none;
}
&:hover{
  color: inherit;
}
&:active{
  color: inherit;
}
`;

const AccountAddAddress = () => {
  const { user } = useContext(Context);
  const navigate = useNavigate();
  const [country, setCountry] = useState(null);
  const [countryIso, setCountryIco] = useState(null);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [zip, setZip] = useState(null);
  const [post, setPost] = useState(null);
  const [type, setType] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [phone, setPhone] = useState(null)
  const countriesRaw = Country.getAllCountries();

  const updateCountry = function (e) {
    setCountry(e.target.value);
    countriesRaw.forEach((elem) => {
      if (elem.name === e.target.value) {
        setCountryIco(elem.isoCode);
      }
    });
  };

  const UpdatedStates = function () {
    if (countryIso) {
      return State.getStatesOfCountry(countryIso);
    } else {
      return State.getStatesOfCountry("AF");
    }
  };

  const createNew = () => {
    let formdata = new FormData();
    if (!type || !country || !state || !city || !post || !zip || !firstName || !lastName) {
      NotificationManager.warning('Fill required fields', "Warning");
    }
    else {
      formdata.append("userId", user.user.id);
      formdata.append("type", type);
      formdata.append("country", country);
      formdata.append("state", state);
      formdata.append("city", city);
      formdata.append("post", post);
      formdata.append("zip", zip);
      formdata.append("first_name", firstName);
      formdata.append("last_name", lastName);
      formdata.append("phone", phone);
      addNewAddress(formdata).then((data) => {
        if (data.id) {
          NotificationManager.success("Address created", "Success");
          setTimeout(() => {
            navigate("/account/addresses");
          }, 1000);
        } else {
          NotificationManager.error(data.message, "Error");
        }
      });
    }
  };

  return (
    <Page variant={"centred"} seo_title={'Vitaforest - Details - Account'} seo_description={'Vitaforest - Account details'}>
      <NotificationContainer />
      <ContentContainer>
        <TemporaryHide>
          <AccountMenu />
        </TemporaryHide>
        <Container>
          <MobileBackButton to={'/account/addresses'} />
          <PageHeader>Add address</PageHeader>
          <Heading>Address</Heading>
          <Form>
            <Select
              label="Address type"
              options={["Billing", "Shipping"]}
              name={type}
              onChange={(e) => {
                setType(e.target.value);
              }}
              required="true"
            />

            <Input
              label="First name"
              placeholder="Your first name"
              type="text"
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              value={firstName}
              required="true"
            />

            <Input
              label="Last name"
              placeholder="Your last name"
              type="text"
              onChange={(e) => {
                setLastName(e.target.value);
              }}
              value={lastName}
              required="true"
            />

            <Input
              label="Phone"
              placeholder="Your phone"
              type="phone"
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value);
              }}
              required="true"
            />

            <Select
              label="Country"
              options={countriesRaw}
              name={country}
              type="country"
              onChange={(e) => {
                updateCountry(e);
              }}
              required="true"
            />

            <Select
              label="State/province"
              options={UpdatedStates()}
              name={country}
              type="country"
              onChange={(e) => {
                setState(e.target.value);
              }}
              required="true"
            />

            <Input
              label="City"
              placeholder="Your city"
              type="text"
              onChange={(e) => {
                setCity(e.target.value);
              }}
              required="true"
            />
            <Input
              label="Zip/postal code"
              placeholder="_ _ _ _ _ _"
              type="text"
              onChange={(e) => {
                setZip(e.target.value);
              }}
              required="true"
            />
            <Input
              label="Post address"
              placeholder="Your post address"
              type="text"
              onChange={(e) => {
                setPost(e.target.value);
              }}
              required="true"
            />
            <FormRequired />
            <FormButton
              title="Save changes"
              onClick={(e) => {
                e.preventDefault();
                createNew();
              }}
            />
          </Form>
        </Container>
      </ContentContainer>
    </Page>
  );
};

export default AccountAddAddress;
