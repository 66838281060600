import { $authHost } from "."

export const fetchAddress = async (id) => {
    try {
        const { data } = await $authHost.get(`/api/address/${id}`);
        return data;
    } catch (e) {
        return e.response.data
    }
}

export const fetchUserAddresses = async (id) => {
    try {
        const { data } = await $authHost.get(`/api/address/getall/${id}`)
        return data;
    } catch (e) {
        return e.response.data
    }
}

export const updateAddress = async (body) => {
    try {
        const { data } = await $authHost.post('/api/address/update', body)
        return data
    } catch (e) {
        return e.response.data
    }
}

export const removeAddress = async (id) => {
    try {
        const { data } = await $authHost.post(`/api/address/remove/${id}`);
        return data
    } catch (e) {
        return e.response.data
    }
}

export const addNewAddress = async (body) => {
    try {
        const { data } = await $authHost.post('/api/address/', body);
        return data;
    } catch (e) {
        return e.response.data
    }
}

export const selectAddressDefault = async (body) => {
    try {
        const { data } = await $authHost.post('/api/address/setdefault', body)
        return data
    } catch (e) {
        return e.response.data
    }
}