import React, { useState } from "react";
import styled from "styled-components";
import DefaultButton from "../../Interface/Buttons/DefaultButton";
import OrderTableRow from "./OrderTableRow";


const OrdersTable = styled.table`
  width: 840px;
  border: 1px solid #eaebec;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 10px 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #303236;
`;
const TableHeader = styled.thead``;

const HeaderCell = styled.th`
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: #7f878b;
  padding-top: 10px;
`;

const TableRow = styled.tr`
  td,
  th {
    padding-right: 10px;
  }
  td:nth-child(1),
  th:nth-child(1) {
    padding-left: 35px;
    width: 165px;
  }
  td:nth-child(2),
  th:nth-child(2) {
    width: 130px;
  }
  td:nth-child(3),
  th:nth-child(3) {
    width: 171px;
  }
  th:nth-child(3) {
    padding-left: 30px;
    position: relative;
  }
  td:nth-child(4),
  th:nth-child(4) {
    width: 220px;
  }
  td:nth-child(5),
  th:nth-child(5) {
    padding-right: 35px;
    text-align: right;
  }
`;

const HeadRow = styled(TableRow)`
  box-shadow: 0px 1px 0px #eaebec;
`;

const TableBody = styled.tbody`
  td {
    height: 50px;
  }
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #303236;
`;

const StatusChanger = styled.div`
  width: 100px;
  height: 118px;
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(26, 33, 45, 0.1);
  border-radius: 4px;
  position: absolute;
  left: 0px;
  bottom: -122px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const ChangeStatusBtn = styled.button`
  width: 100px;
  height: 32px;
  background: #f7f8f9;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #303236;
  padding: 8px 15px;
  border: none;
  background-color: #fff;
`;

const StatusButton = styled.button`
  position: relative;
  border: none;
  background-color: transparent;
  &:after {
    content: url("/arrow.svg");
    position: absolute;
    padding-left: 10px;
  }
`;

export default function OrdersDesktop({ orders }) {
  const [status, setStatus] = useState("All");
  const [statusShown, setStatusShown] = useState(false);
  return (
    <OrdersTable>
      <TableHeader>
        <HeadRow>
          <HeaderCell>Order number</HeaderCell>
          <HeaderCell>Date</HeaderCell>
          <HeaderCell>
            <StatusButton onClick={() => setStatusShown(!statusShown)}>
              Status
            </StatusButton>
            {statusShown ? (
              <StatusChanger>
                <ChangeStatusBtn
                  onClick={() => {
                    setStatus("All");
                    setStatusShown(!statusShown);
                  }}
                >
                  All
                </ChangeStatusBtn>
                <ChangeStatusBtn
                  onClick={() => {
                    setStatus("On hold");
                    setStatusShown(!statusShown);
                  }}
                >
                  On hold
                </ChangeStatusBtn>
                <ChangeStatusBtn
                  onClick={() => {
                    setStatus("Complete");
                    setStatusShown(!statusShown);
                  }}
                >
                  Complete
                </ChangeStatusBtn>
              </StatusChanger>
            ) : null}
          </HeaderCell>
          <HeaderCell>Order total</HeaderCell>
          <HeaderCell>Actions</HeaderCell>
        </HeadRow>
      </TableHeader>
      <TableBody>
        {orders.map((item) =>
          status === "All" ? (
            <OrderTableRow item={item} />
          ) : item.status === status ? (
            <OrderTableRow item={item} />
          ) : null
        )}
      </TableBody>
    </OrdersTable>
  );
}
