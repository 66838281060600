import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { getNotificationsByUser } from "../../../../api/notificationsAPI";

const Wrapper = styled.div`
  background-color: #fff;
  z-index: 2;
  position: relative;
`;
const Container = styled.div`
  margin: 0 auto;
  margin-top: 15px;
  width: calc(100vw - 36px);

  @media screen and (min-width: 1128px) {
    width: 264px;
    margin: 0px 24px 40px 0px;
  }
`;

const Menu = styled.ul`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  padding-left: 0;
  color: #303236;

  @media screen and (min-width: 1128px) {
    font-size: 16px;
    line-height: 19px;
    border: 1px solid #eaebec;
    border-radius: 6px;
  }
`;
const MenuItem = styled.li`
  position: relative;
  height: 47px;
  display: flex;
  align-items: center;
  padding-right: 30px;
  box-shadow: 0px 1px 0px #eaebec;
  &:nth-last-child(-n + 1) {
    box-shadow: none;
  }
  &:after {
    content: url("/arrow.svg");
    position: absolute;
    right: 0px;
    transform: rotate(-90deg);
  }
  @media screen and (min-width: 1128px) {
    padding-left: 20px;
    &:after {
      content: none;
    }
    &:hover,
    &:focus,
    &:active {
      color: #40bf6a;
      border-left: 4px solid #40bf6a;
      padding-left: 16px;
    }
    &.active {
      color: #40bf6a;
      border-left: 4px solid #40bf6a;
      padding-left: 16px;
    }
  }
`;

const MenuItemNotifications = styled(MenuItem)`
@media screen and (min-width: 1128px) {
display: none;
}
`

const GoToDashboard = styled(MenuItem)`
display: none;
@media screen and (min-width: 1128px) {
display: flex;
}
`

const NavigateItem = styled(Link)`
  text-decoration: none;
  color: inherit;
  &:hover {
    color: inherit;
  }
  &:active {
    color: inherit;
  }
`;

const NotifyBubble = styled.div`
background: red;
color: white;
width: 20px;
height: 20px;
border-radius: 10px;
font-size: 10pt;
display: flex;
align-items: center;
justify-content: center;
margin-left: 10px;
p{
  margin-top: 17px !important;
}
`

export default function AccountMenu() {
  const [noti, setNoti] = useState([]);
  const [notiLenght, setNL] = useState(0);
  const [needReload, setNeedReload] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);


  useEffect(() => {
    getNotificationsByUser().then((data) => {
      setNoti(data);
      setNL(data.length);
      setIsLoaded(true)
    }, (e) => {
      setError(e);
    })
  }, [needReload])

  const path = window.location.pathname;
  return (
    <Wrapper>
      <Container>
        <Menu>
          <MenuItemNotifications className={path === "/account/notifications" ? "active" : null}>
            <NavigateItem to={"/account/notifications"}>Notifications</NavigateItem>
            {notiLenght > 0 ? <NotifyBubble><p>{notiLenght}</p></NotifyBubble> : null}
          </MenuItemNotifications>
          <GoToDashboard className={path === "/account" ? "active" : null}>
            <NavigateItem to={"/account"}>Dashboard</NavigateItem>
            {notiLenght > 0 ? <NotifyBubble><p>{notiLenght}</p></NotifyBubble> : null}
          </GoToDashboard>
          <MenuItem className={path === "/account/orders" ? "active" : null}>
            <NavigateItem to={"/account/orders"}>My orders</NavigateItem>
          </MenuItem>
          <MenuItem className={path === "/account/addresses" ? "active" : null}>
            <NavigateItem to={"/account/addresses"}>Address book</NavigateItem>
          </MenuItem>
          <MenuItem className={path === "/account/tickets" ? "active" : null}>
            <NavigateItem to={"/account/tickets"}>My tickets</NavigateItem>
          </MenuItem>
          <MenuItem className={path === "/account/details" ? "active" : null}>
            <NavigateItem to={"/account/details"}>
              Accounts details
            </NavigateItem>
          </MenuItem>
          <MenuItem className={path === "/account/wishlist" ? "active" : null}>
            <NavigateItem to={"/account/wishlist"}>My wishlists</NavigateItem>
          </MenuItem>
          <MenuItem className={path === "/account/privacy" ? "active" : null}>
            <NavigateItem to={"/account/privacy"}>Privacy</NavigateItem>
          </MenuItem>
          <MenuItem className={path === "/account/offers" ? "active" : null}>
            <NavigateItem to={"/account/offers"}>Offers</NavigateItem>
          </MenuItem>
        </Menu>
      </Container>
    </Wrapper>
  );
}
