import React, { useContext } from "react";
import { Context } from "../../../..";
import CardLogged from "./subcomponents/CardLogged";
import CardUnLogged from "./subcomponents/CardUnLogged";
import LineLogged from "./subcomponents/LineLogged";
import LineUnLogged from "./subcomponents/LineUnLogged";

const ProductCard = ({
  variant,
  item,
  wishlist,
  needReload,
  setNeedReload,
  notificationPush,
  toggle,
}) => {
  const { user } = useContext(Context);

  if (variant == "card" && !user.isAuth) {
    return (
      <CardUnLogged
        title={item.title}
        sku={item.sku}
        img={item.img}
        status={item.status}
        url={item.url}
        id={item.id}
        moq={item.moq}
        step={item.qty_step}
      />
    );
  } else if (variant == "line" && user.isAuth) {
    return (
      <LineLogged
        title={item.title}
        sku={item.sku}
        img={item.img}
        status={item.status}
        price={item.price}
        stock={item.stock}
        id={item.id}
        needReload={needReload}
        setNeedReload={setNeedReload}
        wishlist={wishlist ? wishlist : []}
        notificationPush={notificationPush}
        step={item.qty_step}
        moq={item.moq}
        prices={item.prices ? item.prices : false}
        url={item.url}
      />
    );
  } else if (variant == "card" && user.isAuth) {
    return (
      <CardLogged
        title={item.title}
        sku={item.sku}
        img={item.img}
        status={item.status}
        price={item.price}
        stock={item.stock}
        id={item.id}
        needReload={needReload}
        setNeedReload={setNeedReload}
        wishlist={wishlist ? wishlist : []}
        notificationPush={notificationPush}
        step={item.qty_step}
        moq={item.moq}
        prices={item.prices ? item.prices : false}
        url={item.url}
      />
    );
  } else {
    return (
      <LineUnLogged
        title={item.title}
        sku={item.sku}
        img={item.img}
        status={item.status}
        url={item.url}
        id={item.id}
        moq={item.moq}
        step={item.qty_step}
        d
      />
    );
  }
};

export default ProductCard;
