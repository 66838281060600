import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { appendScript } from "../Footer/ScriptAppender";
import AuthNotify from "./subcomponents/AuthNotify";
import HeaderMenu from "./subcomponents/HeaderMenu";
import { Context } from "../../../..";

const HeaderContainer = styled.div`
  margin: 0px 0px 10px 0px;
  background-color: #ffffff;
  @media screen and (max-width: 1128px) {
    padding-bottom: 10px;
    box-shadow: 0px 4px 10px rgba(26, 33, 45, 0.05);
  }
`;

const HeaderContainerUnlogged = styled.div`
  margin: 0px 0px 10px 0px;
  background-color: #ffffff;
  @media screen and (max-width: 1128px) {
    box-shadow: 0px 4px 10px rgba(26, 33, 45, 0.05);
  }
`;

const Header = (props) => {
  const { user } = useContext(Context);
  if (!user.isAuth) {
    return (
      <HeaderContainerUnlogged>
        <Helmet>
          {appendScript('/3rdparty/yandex/yaMetrics.js')}
          {appendScript('/3rdparty/meta/metaPixel.js')}
        </Helmet>
        <noscript><img height="1" width="1" style="display:none"
          src="https://www.facebook.com/tr?id=827695741935333&ev=PageView&noscript=1"
        /></noscript>
        <HeaderMenu />
        <AuthNotify />
      </HeaderContainerUnlogged>
    )
  }
  else {
    return (
      <HeaderContainer>
        <Helmet>
          {appendScript('/3rdparty/yandex/yaMetrics.js')}
          {appendScript('/3rdparty/meta/metaPixel.js')}
        </Helmet>
        <noscript><img height="1" width="1" style="display:none"
          src="https://www.facebook.com/tr?id=827695741935333&ev=PageView&noscript=1"
        /></noscript>
        <HeaderMenu />
        <AuthNotify />
      </HeaderContainer>
    )
  }
};

export default Header;
