import React, { useContext, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { authRoutes, publicRoutes, adminRoutes } from "./routes";
import { Context } from "../index";
import NotFound from "../pages/NotFound";
import MyAccount from "../pages/MyAccount";
import Auth from "../pages/Auth";
import ScrollToTop from "../components/Functional/ScrollToTop";
import DefaultPage from "../pages/DefaultPage";
const AppRouter = () => {
  const { user } = useContext(Context);
  return (
    <ScrollToTop>
      <Routes>
        {user.isAuth &&
          user.user.role_name == "Admin" &&
          adminRoutes.map(({ path, Component }) => (
            <Route key={path} path={path} element={<Component />} exact />
          ))}
        {user.isAuth &&
          authRoutes.map(({ path, Component }) => (
            <Route key={path} path={path} element={<Component />} exact />
          ))}
        {publicRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} exact />
        ))}
        {user.isAuth ? (
          <Route path="/account" element={<MyAccount />} />
        ) : (
          <Route path="/account" element={<Auth />} />
        )}
        <Route path="/:url" element={<DefaultPage />} />
      </Routes>
    </ScrollToTop>
  );
};

export default AppRouter;
