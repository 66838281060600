import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import dayjs from "dayjs";
const Tickets = styled.div`
  margin-top: 25px;
`;

const Ticket = styled.div`
  margin-bottom: 10px;
`;

const TicketTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #303236;
  text-align: left;
  margin-bottom: 5px;
`;

const Flex = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #7f878b;
  margin-bottom: 10px;
  justify-content: space-between;
`;

const TicketLink = styled(Link)`
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
  background: #3c3f54;
  border: none;
  border-radius: 4px;
  width: 284px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  text-decoration: none;
  &:hover{
    color: white;
    background-color: #525673; 
  }
  &:active{
    color: white;
    background-color: #242638;
  }
`;
export default function TicketsMobile({ tickets }) {
  return (
    <Tickets>
      {tickets.map((item) => {
        return (
          <Ticket>
            <TicketTitle>{item.subject}</TicketTitle>
            <Flex>
              <span>{item.status}</span>
              <span>
                {" "}
                {dayjs(item.createdAt).format("MMM DD") + ", "}
                {dayjs(item.createdAt).format("YYYY")}
              </span>
            </Flex>
            <TicketLink to={"/account/ticket/" + item.id}>
              View conversation
            </TicketLink>
          </Ticket>
        );
      })}
    </Tickets>
  );
}
