import React, { useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { NotificationContainer, NotificationManager } from "react-notifications";
import styled from "styled-components";
import { createAttribute, fetchAttributes, removeAttribute, updateAttribute } from "../../../../api/attributeAPI";
import AdminUI from "../../Ui/AdminUI";
import AddAttributeModal from "./AddAttributeModal";
import EditAttributeModal from "./EditAttributeModal";
import RemoveAttributeModal from "./RemoveAttributeModal";
import { Link } from "react-router-dom";
const TableContainer = styled.div`

`

const FlexBox = styled.div`
display: flex;
`

const FlexTitle = styled.div`
display: flex;
margin-bottom: 20px;
`;

const PageTitle = styled.h1`
margin: 0px;
margin-right: 20px;
`

const EditLabel = styled(Link)`
color: white;
text-decoration: none;
&:hover{
    color: white;
}
`

const Attributes = () => {
    const [attributes, setAttributes] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [modalActive, setModalActive] = useState(false);
    const [editModalActive, setEditModalActive] = useState(false);
    const [removeModal, setRemoveModal] = useState(false);
    const [removableId, setRemovableId] = useState(null);
    const [editId, setEditId] = useState(null);
    const [editTitle, setEditTitle] = useState(null);
    const [needReload, setNeedReload] = useState(false);
    // Loading cats for table draw
    useEffect(() => {
        fetchAttributes().then((data) => {
            setAttributes(data);
        }).finally(() => {
            setIsLoaded(true);
        })
    }, [needReload])

    // Open add modal
    const openAddModal = () => {
        setModalActive(true)
    }

    const openRemoveModal = (id) => {
        setRemovableId(id)
        setRemoveModal(true);
    }

    const openEditModal = (id, name) => {
        setEditId(id);
        setEditTitle(name);
        setEditModalActive(true);
    }

    const updateSelectedAttribute = (name) => {
        let formdata = new FormData();
        formdata.append('id', editId);
        formdata.append('name', name);
        updateAttribute(formdata).then((data) => {
            setEditId(null);
            setEditTitle(null);
            setNeedReload(!needReload);
            if (data.status == 'updated') {
                NotificationManager.success(`Attribute updated`, 'Success')
            }
            else {
                NotificationManager.error(`${data.message}`, 'Error')
            }
        })
    }


    const removeCurrentAttribute = (id) => {
        removeAttribute(id).then((data) => {
            if (data.status == 'removed') {
                NotificationManager.success(`Attribute removed`, 'Success')
            }
            else {
                NotificationManager.error(`${data.message}`, 'Error')
            }
            setNeedReload(!needReload);
            setRemovableId(null);
        })
    }

    const updateState = (id, value) => {
        let formdata = new FormData();
        formdata.append('isFilter', value);
        updateAttribute(formdata).then((data) => {
            if (data.id) {
                NotificationManager.success('Attribute added', 'Success');
                setNeedReload(!needReload);
            }
            else {
                NotificationManager.error(`${data.message}`, 'Error');
            }
        })
    }

    const addAttribute = (name) => {
        let formdata = new FormData();
        formdata.append('name', name);
        createAttribute(formdata).then((data) => {
            if (data.id) {
                NotificationManager.success('Attribute added', 'Success');
                setNeedReload(!needReload);
            }
            else {
                NotificationManager.error(`${data.message}`, 'Error');
            }
        })
    }
    if (!isLoaded) {
        return (
            <AdminUI>
                <h5>Loading</h5>
            </AdminUI>
        )
    }
    return (
        <AdminUI>
            <FlexTitle>
                <PageTitle>Product attributes</PageTitle>
                <Button onClick={() => openAddModal()}>Add new</Button>
            </FlexTitle>
            <NotificationContainer />
            <TableContainer>
                <Table striped bordered hover variant="dark">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Attribute</th>
                            <th>Terms</th>
                            <th>Uses in filters</th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {attributes.map((item) => (
                            <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                <td>{item.terms ? (item.terms.length > 1 ? item.terms.map((i) => (i.name + ', ')) : item.terms.map((i) => (i.name))) : null}</td>
                                <td>{item.isFilter ? <Button onClick={() => { updateState(item.id, false) }}>Disable</Button> : <Button onClick={() => { updateState(item.id, true) }}>Enable</Button>}</td>
                                <td><Button onClick={() => openEditModal(item.id, item.name)}>Edit</Button></td>
                                <td><Button variant={'primary'}><EditLabel to={'/admin/attributes/terms/' + item.id}>Edit terms</EditLabel></Button></td>
                                <td><Button onClick={() => openRemoveModal(item.id)}>Delete</Button></td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </TableContainer>
            <AddAttributeModal modalActive={modalActive} setModalActive={setModalActive} addItem={addAttribute} />
            <RemoveAttributeModal modalActive={removeModal} setModalActive={setRemoveModal} itemId={removableId} removeItem={removeCurrentAttribute} />
            <EditAttributeModal modalActive={editModalActive} setModalActive={setEditModalActive} editId={editId} editTitle={editTitle} editItem={updateSelectedAttribute} />
        </AdminUI >
    )

}

export default Attributes