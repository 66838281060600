import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap"
import { Helmet } from "react-helmet";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Context } from "../../../..";
import { getUserData, updateUser } from "../../../../api/userAPI";
import LoadingPlaceholder from "../../../Functional/LoadingPlaceholder";
import DefaultButton from "../../Interface/Buttons/DefaultButton";
import Input from "../../Interface/Input/Input";
import Page from "../../Interface/Page/Page";
import Heading from "../../Interface/TextElements/Heading";
import Required from "../../Interface/TextElements/Required";
import AccountMenu from "../subcomponents/AccountMenu";
import PageHeader from "../subcomponents/PageHeader";
import { getNotificationsByUser } from "../../../../api/notificationsAPI";
import Notification from "../../Interface/Notification";

const Container = styled.div`
  position: relative;
  width: calc(100vw - 36px);
  margin: 0 auto;
  margin-bottom: 40px;
  h4 {
    margin-top: 20px;
  }
  button {
    margin-top: 30px;
  }
  @media screen and (min-width: 1128px) {
    width: auto;
    margin: 0;
  }
`;

const HeadingStyled = styled(Heading)`
  margin-bottom: 5px;
`;

const Form = styled.div`
  width: 100%;
  @media screen and (min-width: 1128px) {
    width: 456px;
  }
`;

const TemporaryHide = styled.div`
  @media screen and (max-width: 1127px) {
    display: none;
  }
`;

const ContentContainer = styled.div`
  margin-top: 20px;
  @media screen and (min-width: 1128px) {
    display: flex;
    margin-top: 30px;
    margin-bottom: 40px;
  }
`;


const MobileBackButton = styled(Link)`
&:before{
  content: url('/arrow-back.svg');
}
text-decoration: none;
color: black;
position: absolute;
top: -5px;
vertical-align: center;
@media screen and (min-width: 1128px){
  display: none;
}
&:hover{
  color: inherit;
}
&:active{
  color: inherit;
}
`;

const EmptyContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
margin-top: 20px;
`

const NotificationMobile = styled(Notification)`
width: 100%;
min-width: 320px !important;
`

const NotificationWrapper = styled.div`
display: flex;
flex-direction: column;
gap: 20px;
`

const AccountNotifications = () => {
    const [notifications, setNotifications] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [needReload, setNeedReload] = useState(false);
    useEffect(() => {
        getNotificationsByUser().then((data) => {
            setNotifications(data);
            setIsLoaded(true);
        }, (error) => {
            setIsLoaded(false);
            setError(error);
        })
    }, [needReload])
    if (error) {
        return (
            <Page>
                {error}
            </Page>
        )
    }
    else if (!isLoaded) {
        return (
            <Page>
                <LoadingPlaceholder />
            </Page>
        )
    }
    else {
        return (
            <Page variant={'centred'} seo_title={'Vitaforest - Notifications - Account'} seo_description={'Vitaforest - Account details'}>
                <NotificationContainer />
                <ContentContainer>
                    <TemporaryHide>
                        <AccountMenu />
                    </TemporaryHide>
                    <Container>
                        <MobileBackButton to={'/account'} />
                        <PageHeader>Notifications</PageHeader>
                        {notifications.length < 1 ? <EmptyContainer><h5>Nothing here</h5></EmptyContainer> : <NotificationWrapper>{notifications.map((noti) => {
                            return (
                                <NotificationMobile title={noti.title}
                                    description={
                                        noti.body
                                    } id={noti.id} reloadTrigger={setNeedReload} reloadState={needReload} />
                            )
                        })}</NotificationWrapper>}
                    </Container>
                </ContentContainer>
            </Page>
        );
    }
}

export default AccountNotifications
