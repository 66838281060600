import { style } from "@mui/system";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const Body = styled.div`
width: 100%;
border: 1px solid #40bf6a;
box-sizing: border-box;
padding: 20px;
box-shadow: 0px 4px 10px rgb(26 33 45 / 5%);
border-radius: 4px;
background-color: white;
margin-bottom: 15px;
&.hidden{
    display: none;
}
@media screen and (min-width: 1128px){
width: 400px;
}
`;

const Head = styled.div`
`

const HeadText = styled.p`
font-weight: 600;
font-size: 20px;
line-height: 17px;
display: flex;
align-items: center;
color: #303236;
padding-bottom: 10px;
margin-top: 0;
border-bottom: 1px solid #303236;
`

const MainText = styled.p`

`

const ActionsContainer = styled.div`
display: flex;
justify-content: center;
`

const Confirm = styled.button`
width: 135px;
background: #40bf6a;
border-radius: 4px;
text-align: center;
color: white;
padding-top: 10px;
padding-bottom: 10px;
border: none;
`

const NotificationMessage = ({ text, name, children, action }) => {

    const [visible, setVisible] = useState(true);

    useEffect(() => {
        let item = localStorage.getItem(`pop_${name}`);
        if (item) {
            setVisible(Boolean(false));
        }
    }, [])

    const handleClick = () => {
        setVisible(false)
        localStorage.setItem(`pop_${name}`, false);
    }

    return (
        <Body className={visible ? null : 'hidden'}>
            <Head>
                <HeadText>Notice</HeadText>
            </Head>
            <MainText>
                {children}
            </MainText>
            <ActionsContainer>
                <Confirm onClick={() => { handleClick() }}>{action}</Confirm>
            </ActionsContainer>
        </Body>
    )
}

export default NotificationMessage