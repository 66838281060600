import { set } from "mobx";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import DefaultButton from "../Buttons/DefaultButton";
import MediaQuery from "react-responsive";
import SectionHeading from "../../Interface/TextElements/SectionHeading";
import CartProduct from "../Header/subcomponents/CartProduct";
import { fetchBasket } from "../../../../api/basketAPI";
import { Context } from "../../../..";
import { Button } from "react-bootstrap";
import { declineOffer } from "../../../../api/offerAPI";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";

const Table = styled.table`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #303236;
  width: 100%;
`;
const ItemsCounter = styled.p`
  font-size: 12px;
  line-height: 19px;
  text-align: center;
  color: #7f878b;
`;
const TableHeader = styled.thead`
  color: #7f878b;
  box-sizing: border-box;
  padding: 5px 18px;
  font-weight: 400;
  background: #f3f7f2;
  width: 100%;
  font-size: 12px;
  line-height: 19px;
`;

const Th = styled.th`
  font-weight: 400;
  padding-right: 5px;
`;

const ThProduct = styled(Th)`
  width: 40%;
  padding-left: 18px;
`;

const ThPrice = styled(Th)`
  width: 18%;
`;

const ThQty = styled(Th)`
  width: 15.5%;
`;

const TBody = styled.tbody``;
const Row = styled.tr`
  position: relative;
  &:after {
    content: " ";
    display: block;
    position: absolute;
    width: calc(100vw - 36px);
    height: 1px;
    background-color: #eaebec;
    left: 18px;
    bottom: -5px;
  }
`;

const Td = styled.td`
  padding-top: 10px;
`;
const Name = styled(Td)`
  padding-left: 18px;
`;

const Oders = styled.div``;

const Summary = styled.table`
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  color: #303236;
  opacity: 0.6;
  width: 100%;
  padding: 10px 0px;
  box-sizing: border-box;
`;

const SummaryCell = styled.td`
  width: 50%;
`;

const LeftCell = styled(SummaryCell)`
  text-align: right;
`;

const RightCell = styled(SummaryCell)`
  text-align: left;
`;
const Container = styled.div`
  padding: 15px;
  box-sizing: border-box;
  margin-top: 25px;
  background: #f3f7f2;
`;
const CalculatePrice = (items) => {
  let ammount = 0;
  items.map((item) => {
    ammount = ammount + item.price * item.qty;
  });
  return ammount.toFixed(2);
};

const TotalRow = styled.tr`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #303236;
`;
const TotalTitle = styled(LeftCell)`
  padding-top: 10px;
`;
const TotalValue = styled(RightCell)`
  padding-top: 10px;
`;

const OrderButton = styled(DefaultButton)`
  width: calc(100vw - 36px);
  margin: 0px auto;
  margin-top: 25px;
  margin-bottom: 25px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Counter = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #40bf6a;
`;

const OrderDesktop = styled.div`
  width: 456px;
  box-sizing: border-box;
  padding: 25px;
  background: #ffffff;
  border: 1px solid #eaebec;
  border-radius: 6px;
`;

const OrderAmmount = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #303236;
`;

const AmmountRow = styled.p`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  opacity: 0.6;
`;

const AmmountRowBold = styled(AmmountRow)`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #303236;
  margin-top: 10px;
  opacity: 1;
`;
const OrderList = styled.div``;

const OrderBtn = styled(DefaultButton)`
  width: 406px;
  margin-top: 25px;
`;

const DeclineBtn = styled(DefaultButton)`
  width: 406px;
  margin-top: 25px;
`

const OrdersTable = ({ items, validate }) => {

  const nav = useNavigate();

  const decline = () => {
    declineOffer(items[0].offer_id).then((data) => {
      if (data.status == 'declined') {
        NotificationManager.success('Offer declined', 'Success');
        setTimeout(() => {
          nav('/account/offers');
        }, 1500);
      }
    })
  }


  return (
    <>
      <NotificationContainer />
      <MediaQuery minWidth={1128}>
        <OrderDesktop>
          <HeaderContainer>
            <SectionHeading>Order summary</SectionHeading>
            <Counter>{items.length} items</Counter>
          </HeaderContainer>
          <OrderList>
            {items.map((item) => {
              return (
                <CartProduct
                  key={item.id}
                  title={item.title}
                  img={item.img}
                  status={item.status}
                  qty={item.qty}
                  price={item.price}
                />
              );
            })}
          </OrderList>
          <OrderAmmount>
            <AmmountRow>
              <span>Cart subtotal:</span>
              <span>€{CalculatePrice(items)}</span>
            </AmmountRow>
            <AmmountRow>
              <span>Shipping (individual calculation):</span>
              <span>preorder</span>
            </AmmountRow>
            <AmmountRowBold>
              <span>Order total:</span>
              <span>€{CalculatePrice(items)}</span>
            </AmmountRowBold>
            <OrderBtn title="Place order" onClick={() => { validate() }} />
            {items[0].item_type == 'offer' ? <DeclineBtn variant="danger" title="Decline offer" onClick={() => { decline() }}></DeclineBtn> : null}
          </OrderAmmount>
        </OrderDesktop>
      </MediaQuery>
      <MediaQuery maxWidth={1127}>
        <Oders>
          <SectionHeading>Order summary</SectionHeading>
          <ItemsCounter>4 items</ItemsCounter>
          <Table>
            <TableHeader>
              <ThProduct>Product</ThProduct>
              <ThPrice>Unit price</ThPrice>
              <ThQty>Qty</ThQty>
              <Th>Amount</Th>
            </TableHeader>
            <TBody>
              {items.map((item) => {
                return (
                  <Row key={item.id}>
                    <Name>{item.title} </Name>
                    <Td>€{item.price.toFixed(2)} </Td>
                    <Td> {item.qty} </Td>
                    <Td>€{(item.price * item.qty).toFixed(2)}</Td>
                  </Row>
                );
              })}
            </TBody>
          </Table>
          <Container>
            <Summary>
              <tr>
                <LeftCell>Cart subtotal:</LeftCell>
                <RightCell>€{CalculatePrice(items)}</RightCell>
              </tr>
              <tr>
                <LeftCell>Payment method:</LeftCell>
                <RightCell>Direct bank transfer</RightCell>
              </tr>
              <tr>
                <LeftCell>Shipping & handling:</LeftCell>
                <RightCell>individual calculation</RightCell>
              </tr>
              <TotalRow>
                <TotalTitle>Order total:</TotalTitle>
                <TotalValue>€{CalculatePrice(items)}</TotalValue>
              </TotalRow>
            </Summary>
          </Container>
          <OrderButton title="Place order" onClick={() => { validate() }} />
          {items[0].item_type == 'offer' ? <OrderButton variant="danger" title="Decline offer" onClick={() => { decline() }}></OrderButton> : null}
        </Oders>
      </MediaQuery>
    </>
  );
}
export default OrdersTable