import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import { removeNotification } from '../../../../api/notificationsAPI';
import { useEffect } from 'react';

function AdminNotification({ id, title, body, notifyType, eventedBy, createdObjectId, assignedFor, eventTime, needReload, setNeedReload }) {
    const removeHandle = (id) => {
        removeNotification(id).then((data) => {
            if (data.status == 'removed') {
                setNeedReload(!needReload);
            }
        })
    }

    if (notifyType == 'admin-login') {
        return (
            <Card>
                <Card.Header as="h5">Administrator login</Card.Header>
                <Card.Body>
                    <Card.Title>Administrator <Link to={`/admin/users/edit/${eventedBy}`}>#{eventedBy}</Link> logged in</Card.Title>
                    <Card.Text>
                        Event time: {eventTime}
                    </Card.Text>
                    <Button variant="danger" onClick={() => { removeHandle(id) }}>Remove</Button>
                </Card.Body>
            </Card>
        );
    }
    else if (notifyType == 'user-pass-change') {
        return (
            <Card>
                <Card.Header as="h5">User changed password</Card.Header>
                <Card.Body>
                    <Card.Title>User <Link to={`/admin/users/edit/${eventedBy}`}>#{eventedBy}</Link> changed password</Card.Title>
                    <Card.Text>
                        Event time: {eventTime}
                    </Card.Text>
                    <Button variant="danger" onClick={() => { removeHandle(id) }}>Remove</Button>
                </Card.Body>
            </Card>
        );
    }
    else if (notifyType == 'user-strange-login') {
        return (
            <Card>
                <Card.Header as="h5">Suspicious login attempt</Card.Header>
                <Card.Body>
                    <Card.Title>User <Link to={`/admin/users/edit/${eventedBy}`}>#{eventedBy}</Link> - suspicious login attempt</Card.Title>
                    <Card.Text>
                        Event time: {eventTime}
                    </Card.Text>
                    <Button variant="danger" onClick={() => { removeHandle(id) }}>Remove</Button>
                </Card.Body>
            </Card>
        );
    }
    else if (notifyType == 'order-status-changed') {
        return (
            <Card>
                <Card.Header as="h5">Order #{createdObjectId} status changed</Card.Header>
                <Card.Body>
                    <Card.Title>Administrator <Link to={`/admin/users/edit/${eventedBy}`}>#{eventedBy}</Link> changed order <Link to={`/admin/order/${createdObjectId}`}>#{createdObjectId}</Link> status to "{body}"</Card.Title>
                    <Card.Text>
                        Event time: {eventTime}
                    </Card.Text>
                    <Button variant="danger" onClick={() => { removeHandle(id) }}>Remove</Button>
                </Card.Body>
            </Card>
        );
    }
    else if (notifyType == 'user-created') {
        return (
            <Card>
                <Card.Header as="h5">New user registered</Card.Header>
                <Card.Body>
                    <Card.Title><Link to={`/admin/users/edit/${createdObjectId}`}>#{createdObjectId}</Link> user created</Card.Title>
                    <Card.Text>
                        Event time: {eventTime}
                    </Card.Text>
                    <Button variant="danger" onClick={() => { removeHandle(id) }}>Remove</Button>
                </Card.Body>
            </Card>
        );
    }
    else if (notifyType == 'user-new-order') {
        return (
            <Card>
                <Card.Header as="h5">New order</Card.Header>
                <Card.Body>
                    <Card.Title>Order <Link to={`/admin/order/${createdObjectId}`}>#{createdObjectId}</Link> created by user <Link to={`/admin/users/edit/${eventedBy}`}>#{eventedBy}</Link></Card.Title>
                    <Card.Text>
                        Event time: {eventTime}
                    </Card.Text>
                    <Button variant="danger" onClick={() => { removeHandle(id) }}>Remove</Button>
                </Card.Body>
            </Card>
        );
    }
}

export default AdminNotification;