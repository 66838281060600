import { $authHost } from "."

export const exportUsers = async () => {
    try {
        const resp = await $authHost.get(`/api/data-export/users`, { responseType: 'blob', });
        const mediaUrl = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a');
        link.href = mediaUrl;
        link.setAttribute('download', 'users.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (e) {
        return e.response.data
    }
}

export const exportProducts = async () => {
    try {
        const resp = await $authHost.get(`/api/data-export/products`, { responseType: 'blob', });
        const mediaUrl = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a');
        link.href = mediaUrl;
        link.setAttribute('download', 'products.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (e) {
        return e.response.data
    }
}

export const exportRoles = async () => {
    try {
        const resp = await $authHost.get(`/api/data-export/roles`, { responseType: 'blob', });
        const mediaUrl = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a');
        link.href = mediaUrl;
        link.setAttribute('download', 'roles.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (e) {
        return e.response.data
    }
}

export const exportCompanies = async () => {
    try {
        const resp = await $authHost.get(`/api/data-export/companies`, { responseType: 'blob', });
        const mediaUrl = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a');
        link.href = mediaUrl;
        link.setAttribute('download', 'companies.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (e) {
        return e.response.data
    }
}

export const exportPrices = async () => {
    try {
        const resp = await $authHost.get(`/api/data-export/prices`, { responseType: 'blob', });
        const mediaUrl = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a');
        link.href = mediaUrl;
        link.setAttribute('download', 'prices.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (e) {
        return e.response.data
    }
}

export const exportForms = async () => {
    try {
        const resp = await $authHost.get(`/api/data-export/forms`, { responseType: 'blob', });
        const mediaUrl = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a');
        link.href = mediaUrl;
        link.setAttribute('download', 'forms.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (e) {
        return e.response.data
    }
}

export const exportPosts = async () => {
    try {
        const resp = await $authHost.get(`/api/data-export/posts`, { responseType: 'blob', });
        const mediaUrl = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a');
        link.href = mediaUrl;
        link.setAttribute('download', 'posts.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (e) {
        return e.response.data
    }
}

export const exportPostCats = async () => {
    try {
        const resp = await $authHost.get(`/api/data-export/postcats`, { responseType: 'blob', });
        const mediaUrl = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a');
        link.href = mediaUrl;
        link.setAttribute('download', 'postcats.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (e) {
        return e.response.data
    }
}

export const exportProdCats = async () => {
    try {
        const resp = await $authHost.get(`/api/data-export/prodcats`, { responseType: 'blob', });
        const mediaUrl = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a');
        link.href = mediaUrl;
        link.setAttribute('download', 'prodcats.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (e) {
        return e.response.data
    }
}

export const exportPages = async () => {
    try {
        const resp = await $authHost.get(`/api/data-export/pages`, { responseType: 'blob', });
        const mediaUrl = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a');
        link.href = mediaUrl;
        link.setAttribute('download', 'pages.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (e) {
        return e.response.data
    }
}

export const exportUrl = async () => {
    try {
        const resp = await $authHost.get(`/api/data-export/urls`, { responseType: 'blob', });
        const mediaUrl = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a');
        link.href = mediaUrl;
        link.setAttribute('download', 'urls.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (e) {
        return e.response.data
    }
}

export const exportWikis = async () => {
    try {
        const resp = await $authHost.get(`/api/data-export/wikis`, { responseType: 'blob', });
        const mediaUrl = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a');
        link.href = mediaUrl;
        link.setAttribute('download', 'wikis.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (e) {
        return e.response.data
    }
}