import AdminPage from "../pages/AdminPage";
import Basket from "../pages/Basket";
import Shop from "../pages/Shop";
import ProductPage from "../pages/Product";
import Auth from "../pages/Auth";
import {
  ADMIN_ROUTE,
  BASKET_ROUTE,
  SHOP_ROUTE,
  LOGIN_ROUTE,
  REGISTRATION_ROUTE,
  PRODUCT_ROUTE,
  HOME_ROUTE,
  BLOG_ROUTE,
  UI_KIT,
  ADMIN_ADD_PRODUCT,
  ADMIN_SEE_PRODUCTS,
  ADMIN_EDIT_PRODUCT,
  ADMIN_ADD_POST,
  ADMIN_EDIT_POST,
  ADMIN_SEE_POSTS,
  ADMIN_SEE_USERS,
  ADMIN_ADD_PRODUCT_CATEGORY,
  ADMIN_EDIT_USER,
  ADMIN_ADD_USER,
  ADMIN_ADD_WIKI,
  ADMIN_EDIT_WIKI,
  ADMIN_SEE_WIKI,
  ADMIN_SEE_ATTRIBUTES,
  ADMIN_SEE_TICKETS,
  ADMIN_SEE_TICKET,
  CHECOUT_ROUTE,
  ADMIN_SEE_ROLES,
  ADMIN_SEE_ORDERS,
  ADMIN_ADD_POST_CATEGORY,
  ADMIN_SEE_SINGLEORDER,
  MY_ACCOUNT_ORDER,
  MY_ACCOUNT_VIEW_ORDER,
  MY_ACCOUNT_NEW_TICKET,
  MY_ACCOUNT_ACCOUNT_DETAILS,
  MY_ACCOUNT_WISHLIST,
  BLOG_PAGE_ROUTE,
  WIKI_SINGLE_PAGE,
  WIKI_ROUTE,
  SEARCH_ROUTE,
  REQUEST_ROUTE,
  PRODUCTION_PAGE,
  ADMIN_SEE_REQUESTS,
  ADMIN_SEE_REQUEST,
  MY_ACCOUNT_TICKETS,
  MY_ACCOUNT_VIEW_TICKET,
  ADMIN_SEE_ATTRIBUTE_TERMS,
  MY_ACCOUNT_VIEW_ADRESSES,
  MY_ACCOUNT_EDIT_ADDRESS,
  MY_ACCOUNT_ADD_ADDRESS,
  MY_ACCOUNT_VIEW_ADDRESSES,
  CONTACTS_PAGE,
  ABOUT_PAGE,
  RHODIOLA_PROMO_PAGE,
  SHOP_PARAMS_ROUTE,
  CHAGA_PROMO_PAGE,
  FAQ_ROUTE,
  ADMIN_SEE_FORMS,
  PRIVACY_ROUTER,
  COOKIE_POLICY,
  HOME_ROUTE_EN,
  TERMS_ROUTE,
  TAXIFOLIN_PROMO,
  ADMIN_APPROVE_COMMENTS,
  TAXIFOLIN_PROMO_PARAMS,
  CHAGA_PROMO_PAGE_PARAMS,
  ADMIN_PRODUCT_URL_ADD,
  ADMIN_PRODUCT_URLS,
  DEV_PAGE,
  MY_ACCOUNT_OFFERS,
  MY_ACCOUNT_NEW_TICKET_PARAMS,
  ADMIN_ADD_OFFER,
  ADMIN_OFFERS_LIST,
  MY_ACCOUNT_PRIVACY,
  ADMIN_EDIT_OFFER,
  ADMIN_COMMENTS_LIST,
  MY_ACCOUNT_NEW_WISHLIST,
  RESTORE_PASSWORD,
  MY_ACCOUNT_EDIT_WISHLIST,
  MY_ACCOUNT_NOTIFICATIONS,
  ADMIN_EDIT_PRODUCT_DRAFT,
  ADMIN_PRODUCT_DRAFT_LIST,
  ADMIN_WIKI_DRAFT_LIST,
  ADMIN_EDIT_WIKI_DRAFT,
  ADMIN_EDIT_POST_DRAFT,
  ADMIN_POST_DRAFT_LIST,
  ADMIN_EDIT_HEADER_PROMO,
  ADMIN_CREATE_WYSIWYG_PAGE,
  ADMIN_CREATE_HTML_PAGE,
  ADMIN_PAGES_LIST,
  ADMIN_PAGES_DRAFTS_LIST,
  ADMIN_EDIT_DRAFT_PAGE,
  ADMIN_EDIT_PAGE,
  ADMIN_EXPORT_PAGE
} from "./consts";
import HomePage from "../pages/HomePage";
import Registration from "../pages/Registration";
import Ui from "../pages/Ui";
import AddProduct from "../components/Admin/Pages/AddProduct/AddProduct";
import ProductListPage from "../components/Admin/Pages/ProductsList/ProductListPage";
import EditProduct from "../components/Admin/Pages/EditProduct/EditProduct";
import AddPost from "../components/Admin/Pages/AddPost/AddPost";
import EditPost from "../components/Admin/Pages/EditPost/EditPost";
import PostListPage from "../components/Admin/Pages/PostList/PostListPage";
import UsersListPage from "../components/Admin/Pages/UsersList/UsersListPage";
import AddProductCategory from "../components/Admin/Pages/ProductCategory/ProductCategory";
import EditUser from "../components/Admin/Pages/EditUser/EditUser";
import AddUser from "../components/Admin/Pages/AddUser/AddUser";
import AddWiki from "../components/Admin/Pages/AddWiki/AddWiki";
import EditWiki from "../components/Admin/Pages/EditWiki/EditWiki";
import WikiListPage from "../components/Admin/Pages/WikiList/WikiListPage";
import Attributes from "../components/Admin/Pages/Attributes/Attributes";
import TicketsPage from "../components/Admin/Pages/Tickets/Tickets";
import TicketPage from "../components/Admin/Pages/Tickets/TicketPage";
import Checkout from "../pages/Checkout";
import UserRoles from "../components/Admin/Pages/UserRoles/UserRoles";
import OrdersPage from "../components/Admin/Pages/Orders/OrdersPage";
import OrderPage from "../components/Admin/Pages/Orders/OrderPage";
import PostCategory from "../components/Admin/Pages/PostCategory/PostCategory";
import Product from "../pages/Product";
import Blog from "../pages/Blog";
import Orders from "../components/VitaforestUI/Account/subpages/Orders";
import SingleOrder from "../components/VitaforestUI/Account/subpages/SingleOrder";
import AccountDetails from "../components/VitaforestUI/Account/subpages/AccountDetails";
import AccountWishlist from "../components/VitaforestUI/Account/subpages/AccountWishlist";
import Post from "../pages/Post";
import SingleWiki from "../pages/SingleWiki";
import Wiki from "../pages/Wiki";
import Search from "../pages/Search";
import Request from "../pages/Request";
import ProductionPage from "../pages/Production";
import RequestsPage from "../components/Admin/Pages/Requests/RequestsPage";
import RequestPage from "../components/Admin/Pages/Requests/RequestPage";
import Tickets from "../components/VitaforestUI/Account/subpages/Tickets";
import SingleTicket from "../components/VitaforestUI/Account/subpages/SingleTicket";
import NewTicket from "../components/VitaforestUI/Account/subpages/NewTicket";
import AttributeTerms from "../components/Admin/Pages/Attributes/AttributeTerms";
import AccountAddresses from "../components/VitaforestUI/Account/subpages/AccountAddresses";
import AccountEditAddress from "../components/VitaforestUI/Account/subpages/AccountEditAddress";
import AccountAddAddress from "../components/VitaforestUI/Account/subpages/AccountAddAddress";
import Contacts from "../pages/Contacts";
import About from "../pages/About";
import RhodiolaPromo from "../pages/RhodiolaPromo.js";
import ChagaPromo from "../pages/ChagaPromo.js";
import FAQ from "../pages/FAQ";
import ViewForms from "../components/Admin/Pages/Forms/ViewForms";
import Privacy from "../pages/Privacy";
import CookiePolicy from "../pages/CookiePolicy";
import Terms from "../pages/Terms";
import TaxifolinPromo from "../pages/TaxifolinPromo";
import CommentsApprove from "../components/Admin/Pages/Comments/CommentsApprove";
import AddUrl from "../components/Admin/Pages/Urls/AddUrl";
import Plug from "../pages/Plug";
import UrlsList from "../components/Admin/Pages/Urls/UrlsList";
import Offers from "../components/VitaforestUI/Account/subpages/Offers";
import CreateOffer from "../components/Admin/Pages/Offers/CreateOffer";
import OffersList from "../components/Admin/Pages/Offers/OffersList";
import AccountPrivacy from "../components/VitaforestUI/Account/subpages/AccountPrivacy";
import EditOffer from "../components/Admin/Pages/Offers/EditOffer";
import CommentsList from "../components/Admin/Pages/Comments/CommentsList";
import AccountAddWishlist from "../components/VitaforestUI/Account/subpages/AccountAddWishlist";
import RestorePassword from "../pages/RestorePassword";
import AccountEditWishlist from "../components/VitaforestUI/Account/subpages/AccountEditWishlist";
import AccountNotifications from "../components/VitaforestUI/Account/subpages/AccountNotifications";
import ProductDraftEdit from "../components/Admin/Pages/Drafts/ProductDraftEdit";
import ProductDraftList from "../components/Admin/Pages/Drafts/ProductDraftList";
import WikiDraftList from "../components/Admin/Pages/Drafts/WikiDraftList";
import WikiDraftEdit from "../components/Admin/Pages/Drafts/WikiDraftEdit";
import PostDraftEdit from "../components/Admin/Pages/Drafts/PostDraftEdit";
import PostDraftList from "../components/Admin/Pages/Drafts/PostDraftList";
import EditPromo from "../components/Admin/Pages/EditPagesContent/EditPromo";
import WysiwygPage from "../components/Admin/Pages/PagesConstructor/WysiwygPage.js";
import HTMLPage from "../components/Admin/Pages/PagesConstructor/HTMLPage.js";
import AllPagesList from "../components/Admin/Pages/PagesConstructor/AllPagesList.js";
import PageDraftList from "../components/Admin/Pages/Drafts/PageDraftList.js";
import PageDraftEdit from "../components/Admin/Pages/Drafts/PageDraftEdit.js";
import PageEdit from "../components/Admin/Pages/PagesConstructor/PageEdit.js";
import ExportPage from "../components/Admin/Pages/ExportPage/ExportPage.js";
// Administrator routes
export const adminRoutes = [
  {
    path: ADMIN_ADD_PRODUCT,
    Component: AddProduct,
  },
  {
    path: ADMIN_SEE_PRODUCTS,
    Component: ProductListPage,
  },
  {
    path: ADMIN_EDIT_PRODUCT,
    Component: EditProduct,
  },
  {
    path: ADMIN_ADD_POST,
    Component: AddPost,
  },
  {
    path: ADMIN_EDIT_POST,
    Component: EditPost,
  },
  {
    path: ADMIN_SEE_POSTS,
    Component: PostListPage,
  },
  {
    path: ADMIN_ROUTE,
    Component: AdminPage,
  },
  {
    path: ADMIN_SEE_USERS,
    Component: UsersListPage,
  },
  {
    path: ADMIN_ADD_PRODUCT_CATEGORY,
    Component: AddProductCategory,
  },
  {
    path: ADMIN_EDIT_USER,
    Component: EditUser,
  },
  {
    path: ADMIN_ADD_USER,
    Component: AddUser,
  },
  {
    path: ADMIN_ADD_WIKI,
    Component: AddWiki,
  },
  {
    path: ADMIN_EDIT_WIKI,
    Component: EditWiki,
  },
  {
    path: ADMIN_SEE_WIKI,
    Component: WikiListPage,
  },
  {
    path: ADMIN_SEE_ATTRIBUTES,
    Component: Attributes,
  },
  {
    path: ADMIN_SEE_TICKETS,
    Component: TicketsPage,
  },
  {
    path: ADMIN_SEE_TICKET,
    Component: TicketPage,
  },
  {
    path: ADMIN_SEE_ROLES,
    Component: UserRoles,
  },
  {
    path: ADMIN_SEE_ORDERS,
    Component: OrdersPage,
  },
  {
    path: ADMIN_ADD_POST_CATEGORY,
    Component: PostCategory,
  },
  {
    path: ADMIN_SEE_SINGLEORDER,
    Component: OrderPage,
  },
  {
    path: ADMIN_SEE_REQUESTS,
    Component: RequestsPage,
  },
  {
    path: ADMIN_SEE_REQUEST,
    Component: RequestPage,
  },
  {
    path: ADMIN_SEE_ATTRIBUTE_TERMS,
    Component: AttributeTerms,
  },
  {
    path: ADMIN_SEE_FORMS,
    Component: ViewForms,
  },
  {
    path: ADMIN_APPROVE_COMMENTS,
    Component: CommentsApprove,
  },
  {
    path: ADMIN_PRODUCT_URL_ADD,
    Component: AddUrl,
  },
  {
    path: ADMIN_PRODUCT_URLS,
    Component: UrlsList,
  },
  {
    path: ADMIN_ADD_OFFER,
    Component: CreateOffer,
  },
  {
    path: ADMIN_OFFERS_LIST,
    Component: OffersList,
  },
  {
    path: ADMIN_EDIT_OFFER,
    Component: EditOffer,
  },
  {
    path: ADMIN_COMMENTS_LIST,
    Component: CommentsList,
  },
  {
    path: ADMIN_EDIT_PRODUCT_DRAFT,
    Component: ProductDraftEdit,
  },
  {
    path: ADMIN_PRODUCT_DRAFT_LIST,
    Component: ProductDraftList,
  },
  {
    path: ADMIN_WIKI_DRAFT_LIST,
    Component: WikiDraftList,
  },
  {
    path: ADMIN_EDIT_WIKI_DRAFT,
    Component: WikiDraftEdit,
  },
  {
    path: ADMIN_EDIT_POST_DRAFT,
    Component: PostDraftEdit,
  },
  {
    path: ADMIN_POST_DRAFT_LIST,
    Component: PostDraftList,
  },
  {
    path: ADMIN_EDIT_HEADER_PROMO,
    Component: EditPromo,
  },
  {
    path: ADMIN_CREATE_WYSIWYG_PAGE,
    Component: WysiwygPage,
  },
  {
    path: ADMIN_CREATE_HTML_PAGE,
    Component: HTMLPage,
  },
  {
    path: ADMIN_PAGES_LIST,
    Component: AllPagesList,
  },
  {
    path: ADMIN_PAGES_DRAFTS_LIST,
    Component: PageDraftList,
  },
  {
    path: ADMIN_EDIT_DRAFT_PAGE,
    Component: PageDraftEdit,
  },
  {
    path: ADMIN_EDIT_PAGE,
    Component: PageEdit,
  },
  {
    path: ADMIN_EXPORT_PAGE,
    Component: ExportPage,
  },
];
// Authorized user routes
export const authRoutes = [
  {
    path: BASKET_ROUTE,
    Component: Basket,
  },
  {
    path: CHECOUT_ROUTE,
    Component: Checkout,
  },
  {
    path: BASKET_ROUTE,
    Component: Basket,
  },
  {
    path: MY_ACCOUNT_ORDER,
    Component: Orders,
  },
  {
    path: MY_ACCOUNT_VIEW_ORDER,
    Component: SingleOrder,
  },
  {
    path: MY_ACCOUNT_ACCOUNT_DETAILS,
    Component: AccountDetails,
  },
  {
    path: MY_ACCOUNT_WISHLIST,
    Component: AccountWishlist,
  },
  {
    path: MY_ACCOUNT_TICKETS,
    Component: Tickets,
  },
  {
    path: MY_ACCOUNT_VIEW_TICKET,
    Component: SingleTicket,
  },
  {
    path: MY_ACCOUNT_EDIT_ADDRESS,
    Component: AccountEditAddress,
  },
  {
    path: MY_ACCOUNT_ADD_ADDRESS,
    Component: AccountAddAddress,
  },
  {
    path: MY_ACCOUNT_VIEW_ADDRESSES,
    Component: AccountAddresses,
  },
  {
    path: MY_ACCOUNT_NEW_TICKET,
    Component: NewTicket,
  },
  {
    path: MY_ACCOUNT_NEW_TICKET_PARAMS,
    Component: NewTicket,
  },
  {
    path: MY_ACCOUNT_OFFERS,
    Component: Offers,
  },
  {
    path: MY_ACCOUNT_PRIVACY,
    Component: AccountPrivacy,
  },
  {
    path: MY_ACCOUNT_NEW_WISHLIST,
    Component: AccountAddWishlist,
  },
  {
    path: MY_ACCOUNT_EDIT_WISHLIST,
    Component: AccountEditWishlist,
  },
  {
    path: MY_ACCOUNT_NOTIFICATIONS,
    Component: AccountNotifications,
  },
];
// Routes with public visibiliy
export const publicRoutes = [
  {
    path: COOKIE_POLICY,
    Component: CookiePolicy,
  },
  {
    path: DEV_PAGE,
    Component: Plug,
  },
  {
    path: PRIVACY_ROUTER,
    Component: Privacy,
  },
  {
    path: ABOUT_PAGE,
    Component: About,
  },
  /**
  {
    path: CHAGA_PROMO_PAGE,
    Component: ChagaPromo,
  },
  {
    path: CHAGA_PROMO_PAGE_PARAMS,
    Component: ChagaPromo,
  },
  **/
  {
    path: FAQ_ROUTE,
    Component: FAQ,
  },
  {
    path: CONTACTS_PAGE,
    Component: Contacts,
  },
  {
    path: SEARCH_ROUTE,
    Component: Search,
  },
  {
    path: BLOG_ROUTE + "/category/:category",
    Component: Blog,
  },
  {
    path: BLOG_ROUTE,
    Component: Blog,
  },
  {
    path: BLOG_PAGE_ROUTE,
    Component: Post,
  },
  {
    path: WIKI_SINGLE_PAGE,
    Component: SingleWiki,
  },
  {
    path: HOME_ROUTE,
    Component: HomePage,
  },
  {
    path: HOME_ROUTE_EN,
    Component: HomePage,
  },
  {
    path: SHOP_ROUTE,
    Component: Shop,
  },
  {
    path: SHOP_PARAMS_ROUTE,
    Component: Shop,
  },
  {
    path: LOGIN_ROUTE,
    Component: Auth,
  },
  {
    path: REGISTRATION_ROUTE,
    Component: Registration,
  },
  {
    path: PRODUCT_ROUTE + "/:url",
    Component: Product,
  },
  {
    path: UI_KIT,
    Component: Ui,
  },
  {
    path: WIKI_ROUTE,
    Component: Wiki,
  },
  {
    path: REQUEST_ROUTE,
    Component: Request,
  },
  {
    path: PRODUCTION_PAGE,
    Component: ProductionPage,
  },
  {
    path: RHODIOLA_PROMO_PAGE,
    Component: RhodiolaPromo,
  },
  {
    path: TERMS_ROUTE,
    Component: Terms,
  },
  /**
  {
    path: TAXIFOLIN_PROMO,
    Component: TaxifolinPromo,
  },
  {
    path: TAXIFOLIN_PROMO_PARAMS,
    Component: TaxifolinPromo,
  },
   **/
  {
    path: RESTORE_PASSWORD,
    Component: RestorePassword,
  },
];
