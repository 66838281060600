import React, { useEffect, useContext } from "react";
import AdminUI from "../../Ui/AdminUI";
import { Editor } from 'react-draft-wysiwyg';
import { useState } from "react";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Card, Form, Button, Container, Row, Col, Table } from "react-bootstrap";
import styled from "styled-components";
import { createPost, decodeEditorBody, encodeEditorBody } from "../../../../api/postAPI";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { Context } from "../../../..";
import { useNavigate, useParams } from "react-router-dom";
import { createPageDraft, createPostDraft, getDraft } from "../../../../api/draftAPI";
import LoadingPlaceholder from "../../../Functional/LoadingPlaceholder";
import PageVar from "../../../VitaforestUI/Interface/Page/PageVar";
import { EditorState } from "draft-js";
import { createNewPage, getPageById } from "../../../../api/pagesAPI";
import CodeEditor from '@uiw/react-textarea-code-editor';


const FlexTitle = styled.div`
display: flex;
margin-bottom: 20px;
`;

const PageTitle = styled.h1`
margin: 0px;
margin-right: 20px;
`





const PageDraftEdit = () => {

    const { id } = useParams();
    const { user } = useContext(Context)
    const navigate = useNavigate();
    const [seoTitle, setSeoTitle] = useState('');
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [seoDesc, setSeoDesc] = useState('');
    const [seoKeywords, setSeoKeywords] = useState('');
    const [html, setHtml] = useState('');
    const [css, setCss] = useState('');
    const [url, setUrl] = useState('');
    const [error, setError] = useState(null);
    const [pageType, setPageType] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [previewData, setPreviewData] = useState(EditorState.createEmpty())
    const [withInterface, setWithInterface] = useState('');

    useEffect(() => {
        getDraft(id).then((data) => {
            if (data.id && data.pageType == 'wysiwyg') {
                if (data.description != null) {
                    setEditorState(EditorState.createWithContent(decodeEditorBody(data.description)));
                }
                setSeoTitle(data.seo_title);
                setSeoDesc(data.seo_description);
                setSeoKeywords(data.seo_keywords);
                setUrl(data.url);
                setPageType(data.pageType);
                setIsLoaded(true)
            }
            else if (data.id && data.pageType == 'standalone' || data.id && data.pageType == 'standwith') {
                setSeoTitle(data.seo_title);
                setSeoDesc(data.seo_description);
                setSeoKeywords(data.seo_keywords);
                setHtml(data.html_body);
                setCss(data.html_styles);
                setWithInterface(data.pageType);
                setUrl(data.url);
                setPageType(data.pageType);
                setIsLoaded(true);
            }
        })
    }, [])

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
        setPreviewData(editorState)
    }

    const createPage = () => {
        if (pageType == 'standwith' || pageType == 'standalone') {
            const formData = new FormData();
            formData.append('html_body', html);
            formData.append('html_styles', css);
            formData.append('url', url);
            if (url.length < 1) {
                NotificationManager.error(
                    `Url cannot be empty or less then 1 symbol`
                );
                return false;
            }
            formData.append('seo_title', seoTitle);
            if (seoTitle.length < 1) {
                NotificationManager.error(
                    `Title cannot be empty or less then 1 symbol`
                );
                return false;
            }
            formData.append('seo_description', seoDesc);
            formData.append('seo_keywords', seoKeywords);
            formData.append('pageType', withInterface);
            createNewPage(formData).then((data) => {
                if (data.id) {
                    NotificationManager.success(
                        `Page "${seoTitle}" draft saved`,
                        "Success"
                    );
                    setTimeout(() => {
                        navigate('/admin/pages/edit/' + data.id)
                    }, 1500)
                } else {
                    NotificationManager.error(
                        `Page "${seoTitle}" cannot be created`,
                        `${data}`
                    );
                }
            })
        }

        else if (pageType == 'wysiwyg') {
            const formData = new FormData();
            formData.append('body', encodeEditorBody(editorState));
            formData.append('url', url);
            if (url.length < 1) {
                NotificationManager.error(
                    `Url cannot be empty or less then 1 symbol`
                );
                return false;
            }
            formData.append('seo_title', seoTitle);
            if (seoTitle.length < 1) {
                NotificationManager.error(
                    `Title cannot be empty or less then 1 symbol`
                );
                return false;
            }
            formData.append('seo_description', seoDesc);
            formData.append('seo_keywords', seoKeywords);
            formData.append('pageType', 'wysiwyg');
            createNewPage(formData).then((data) => {
                if (data.id) {
                    NotificationManager.success(
                        `Page "${seoTitle}" draft saved`,
                        "Success"
                    );
                    setTimeout(() => {
                        navigate('/admin/pages/edit/' + data.id)
                    }, 1500)
                } else {
                    NotificationManager.error(
                        `Page "${seoTitle}" cannot be created`,
                        `${data}`
                    );
                }
            })
        }
    }

    const draftPage = () => {
        if (pageType == 'standwith' || pageType == 'standalone') {
            const formData = new FormData();
            formData.append('id', id);
            formData.append('html_body', html);
            formData.append('html_styles', css);
            formData.append('url', url);
            if (url.length < 1) {
                NotificationManager.error(
                    `Url cannot be empty or less then 1 symbol`
                );
                return false;
            }
            formData.append('seo_title', seoTitle);
            if (seoTitle.length < 1) {
                NotificationManager.error(
                    `Title cannot be empty or less then 1 symbol`
                );
                return false;
            }
            formData.append('seo_description', seoDesc);
            formData.append('seo_keywords', seoKeywords);
            formData.append('pageType', withInterface);
            console.log(formData.pageType)
            createPageDraft(formData).then((data) => {
                if (data.id) {
                    NotificationManager.success(
                        `Page "${seoTitle}" draft saved`,
                        "Success"
                    );
                    setTimeout(() => {
                        navigate('/admin/pages/draft/' + data.id)
                    }, 1500)
                } else {
                    NotificationManager.error(
                        `Page "${seoTitle}" cannot be created`,
                        `${data}`
                    );
                }
            })

        }
        else if (pageType == 'wysiwyg') {
            const formData = new FormData();
            formData.append('id', id);
            formData.append('description', encodeEditorBody(editorState));
            formData.append('url', url);
            if (url.length < 1) {
                NotificationManager.error(
                    `Url cannot be empty or less then 1 symbol`
                );
                return false;
            }
            formData.append('seo_title', seoTitle);
            if (seoTitle.length < 1) {
                NotificationManager.error(
                    `Title cannot be empty or less then 1 symbol`
                );
                return false;
            }
            formData.append('seo_description', seoDesc);
            formData.append('seo_keywords', seoKeywords);
            formData.append('userId', user.user.id);
            createPageDraft(formData).then((data) => {
                if (data.id) {
                    NotificationManager.success(
                        `Page "${seoTitle}" draft saved`,
                        "Success"
                    );
                    setTimeout(() => {
                        navigate('/admin/pages/draft/' + data.id)
                    }, 1500)
                } else {
                    NotificationManager.error(
                        `Page "${seoTitle}" cannot be created`,
                        `${data}`
                    );
                }
            })
        }
    }
    if (!isLoaded) {
        return (
            <AdminUI>
                <LoadingPlaceholder />
            </AdminUI>
        )
    }
    else if (isLoaded && pageType == 'standalone' || isLoaded && pageType == 'standwith') {
        return (
            <AdminUI>
                <NotificationContainer />
                <FlexTitle>
                    <PageTitle>Add new page</PageTitle>
                    <Button onClick={() => { createPage() }} >Publish page</Button>
                    <Button variant="warning" onClick={() => { draftPage() }} style={{ marginLeft: '10px' }}>Save draft</Button>
                </FlexTitle>
                <Form className="mb-5">
                    <Container>
                        <Row>
                            <Col>
                                <Card className="mb-5">
                                    <Card.Body>
                                        <Form.Group className="mb-2">
                                            <Form.Label>URL</Form.Label>
                                            <Form.Control aria-label="large"
                                                value={url}
                                                onChange={e => setUrl(String(e.target.value))}
                                                placeholder="Post title"
                                                type="text"
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-2">
                                            <Form.Label>SEO Title / Page title</Form.Label>
                                            <Form.Control type="text" placeholder="SEO Title" value={seoTitle} onChange={(e) => setSeoTitle(String(e.target.value))}></Form.Control>
                                        </Form.Group>
                                        <Form.Group className="mb-2">
                                            <Form.Label>SEO Description</Form.Label>
                                            <Form.Control type="text" placeholder="SEO Description" value={seoDesc} onChange={(e) => setSeoDesc(String(e.target.value))}></Form.Control>
                                        </Form.Group>
                                        <Form.Group className="mb-2">
                                            <Form.Label>SEO Keywords</Form.Label>
                                            <Form.Control type="text" placeholder="SEO Keywords" value={seoKeywords} onChange={(e) => setSeoKeywords(String(e.target.value))}></Form.Control>
                                            <Form.Text className="text-muted">List the key words, separated by commas</Form.Text>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                                <Card className="mb-2">
                                    <Card.Header>Preview (for preview refresh click on any empty space)</Card.Header>
                                    <PageVar type={'standalone-preview'} html_body={html} html_styles={css} />
                                    <Card.Body>

                                    </Card.Body>
                                </Card>
                                <Card className="mb-2">
                                    <Card.Header>HTML editor</Card.Header>
                                    <Card.Body>
                                        <CodeEditor
                                            value={html}
                                            language="html"
                                            placeholder="Please enter html code."
                                            onChange={(evn) => setHtml(evn.target.value)}
                                            padding={15}
                                            style={{
                                                backgroundColor: "#f5f5f5",
                                                fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                                                color: 'black'
                                            }}
                                        />
                                    </Card.Body>
                                </Card>
                                <Card className="mb-2">
                                    <Card.Header>CSS editor</Card.Header>
                                    <Card.Body>
                                        <CodeEditor
                                            value={css}
                                            language="css"
                                            placeholder="Please enter html code."
                                            onChange={(evn) => setCss(evn.target.value)}
                                            padding={15}
                                            style={{
                                                backgroundColor: "#f5f5f5",
                                                fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                                                color: 'black'
                                            }}
                                        />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Form>
            </AdminUI >
        )
    }
    else if (isLoaded && pageType == 'wysiwyg') {
        return (
            <AdminUI>
                <NotificationContainer />
                <FlexTitle>
                    <PageTitle>Edit draft</PageTitle>
                    <Button onClick={() => { createPage() }} >Publish page</Button>
                    <Button variant="warning" onClick={() => { draftPage() }} style={{ marginLeft: '10px' }}>Save draft</Button>
                </FlexTitle>
                <Form className="mb-5">
                    <Container>
                        <Row>
                            <Col>
                                <Card className="mb-5">
                                    <Card.Body>
                                        <Form.Group className="mb-2">
                                            <Form.Label>URL</Form.Label>
                                            <Form.Control aria-label="large"
                                                value={url}
                                                onChange={e => setUrl(String(e.target.value))}
                                                placeholder="Post title"
                                                type="text"
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-2">
                                            <Form.Label>SEO Title / Page title</Form.Label>
                                            <Form.Control type="text" placeholder="SEO Title" value={seoTitle} onChange={(e) => setSeoTitle(String(e.target.value))}></Form.Control>
                                        </Form.Group>
                                        <Form.Group className="mb-2">
                                            <Form.Label>SEO Description</Form.Label>
                                            <Form.Control type="text" placeholder="SEO Description" value={seoDesc} onChange={(e) => setSeoDesc(String(e.target.value))}></Form.Control>
                                        </Form.Group>
                                        <Form.Group className="mb-2">
                                            <Form.Label>SEO Keywords</Form.Label>
                                            <Form.Control type="text" placeholder="SEO Keywords" value={seoKeywords} onChange={(e) => setSeoKeywords(String(e.target.value))}></Form.Control>
                                            <Form.Text className="text-muted">List the key words, separated by commas</Form.Text>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                                <Card className="mb-2">
                                    <Card.Header>Preview (for preview refresh click on any empty space)</Card.Header>
                                    <Card.Body>
                                        <PageVar type={'wysiwyg-preview'} body={previewData} />
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Header>Editor</Card.Header>
                                    <Card.Body>
                                        <Editor editorState={editorState}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onEditorStateChange={onEditorStateChange} />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Form>
            </AdminUI >
        )
    }
}
export default PageDraftEdit