import { $authHost, $host } from ".";

export const sendForm = async (body) => {
    try {
        const { data } = await $host.post('/api/forms/', body);
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const getForms = async () => {
    try {
        const { data } = await $authHost.get('/api/forms');
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const removeForm = async (id) => {
    try {
        const { data } = await $authHost.get(`/api/forms/remove/${id}`);
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const savePromoLocation = (location) => {
    localStorage.setItem('promo_visited', location);
}

export const isPromoVisited = () => {
    let lastLocation = localStorage.getItem('promo_visited');
    if (lastLocation) {
        return lastLocation;
    }
    else {
        return false;
    }
}