import React from "react";
import EmptyCart from "../components/VitaforestUI/Interface/OrdersComponents/EmptyCart";
import Page from "../components/VitaforestUI/Interface/Page/Page";
import styled from "styled-components";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { useState, useEffect, useContext } from "react";
import { fetchBasket } from "../api/basketAPI";
import { Context } from "..";
import FilledRequest from "../components/VitaforestUI/Interface/OrdersComponents/FilledRequest";
import { createProductRequest } from "../api/ordersAPI";
import { Helmet } from "react-helmet";

const CartContainer = styled.div`
  margin-top: 20px;
`;

const Request = () => {
    const [items, setItems] = useState([])
    const [needReload, setNeedReload] = useState(false);
    useEffect(() => {
        if (localStorage.getItem('request_items')) {
            setItems(JSON.parse(localStorage.getItem('request_items')));
        }
    }, [needReload])
    //funcs
    const pushNotification = (title, type) => {
        if (type === 'remove') {
            NotificationManager.success(`${title} removed from cart`)
        }
        else if (type === 'error') {
            NotificationManager.error(`${title}`)
        }
    }

    return (
        <Page variant={'centred'} seo_title={`Request - Vitaforest`} seo_description={'Vitaforest request page'}>
            <NotificationContainer />
            <CartContainer>
                {items[0] ? <FilledRequest items={items} notificationPush={pushNotification} needReload={needReload} setNeedReload={setNeedReload} /> : <EmptyCart isReq={true} />}
            </CartContainer>
        </Page>
    );
};

export default Request;
