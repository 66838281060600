import React from "react";
import styled, { css } from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputElement = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 10px 16px 20px;
  gap: 10px;
  width: 100%;
  height: ${(props) => (props.height ? props.height : "auto")};
  background: #ffffff;
  border: 1px solid #eaebec;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #303236;
  opacity: 0.8;
  border-radius: 4px;
  outline: none;
  &:focus {
    border: 1px solid #bbbbbb;
  }
  &:hover {
    border: 1px solid #bbbbbb;
  }
  &:focus-visible {
    border: 1px solid #7f878b;
  }
  &:active {
    border: 1px solid #bbbbbb;
  }
  &:not(:placeholder-shown) {
    &:invalid {
      border: 1px solid #ef5e53;
    }
  }
`;

const Label = styled.label`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: ${(props) => (props.fs ? props.fs : "14px")};
  line-height: ${(props) => (props.lh ? props.lh : "17px")};
  display: flex;
  align-items: center;
  color: #303236;
  margin: 0px 0px 10px 0px;
  padding-top: 10px;
  &.required {
    position: relative;
    &::after {
      content: "*";
      color: red;
    }
  }
`;

const Input = (props) => {
  return (
    <Container onChange={props.onChange} onClick={props.onClick}>
      {props.label ? <Label
        className={props.required ? "required" : ""}
        fs={props.fs}
        lh={props.lh}
      >
        {props.label}
      </Label> : null}
      <InputElement
        placeholder={props.placeholder}
        type={props.type}
        value={props.value}
        required={props.required}
        disabled={props.disabled}
      />
    </Container>
  );
};

export default Input;
