import React, { useEffect } from "react";
import AdminUI from "../../Ui/AdminUI";
import { useState } from "react";
import { Card, Form, Button, Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { useParams } from 'react-router-dom';
import { getUserById, updateCompany, updateUser } from "../../../../api/userAPI"
import LoadingPlaceholder from "../../../Functional/LoadingPlaceholder";

// Styled components
const FlexBox = styled.div`
display: flex;
margin-bottom: 20px;
h1{
    margin: 0px;
    margin-right: 10px;
}
`;




const EditUser = () => {

    const { id } = useParams()

    // States
    const [userData, setUserData] = useState([]);
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [role, setRole] = useState('')
    const [vat, setVat] = useState('');
    const [reg, setReg] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [website, setWebsite] = useState('');
    const [approved, setApproved] = useState('')
    const [isLoaded, setIsLoaded] = useState(false)
    const [error, setError] = useState()

    // Save changes
    const UserUpdate = () => {
        let userFormData = new FormData();
        let companyFormData = new FormData();
        userFormData.append('id', id);
        userFormData.append('first_name', firstName);
        userFormData.append('last_name', lastName);
        userFormData.append('email', email);
        userFormData.append('approved', Boolean(approved));
        userFormData.append('role_name', role);
        companyFormData.append('companyName', companyName);
        companyFormData.append('vat', vat);
        companyFormData.append('registryCode', reg);
        companyFormData.append('website', website);
        companyFormData.append('id', userData.company.id);
        updateUser(userFormData).then((data) => {
            if (data == true) {
                NotificationManager.success(`Data of user "${email}" successfully updated`, 'Success')
            }
            else {
                NotificationManager.error(`User "${email}" cannot be updated`, `Error`);
            }
        });
        updateCompany(companyFormData).then((data) => {
            if (data == true) {
                NotificationManager.success(`Data of company "${userData.company.companyName}" successfully updated`, 'Success')
            }
            else {
                NotificationManager.error(`User "${email}" cannot be updated`, `${data}`);
            }
        });
    }

    // Load Data
    useEffect(() => {
        getUserById(id).then((data) => {
            setFirstName(data.first_name);
            setLastName(data.last_name);
            setEmail(data.email);
            setRole(data.role_name);
            setApproved(data.approved);
            setUserData(data);
            setCompanyName(data.company.companyName);
            setVat(data.company.vat);
            setReg(data.company.registryCode);
            setWebsite(data.company.website);
            setIsLoaded(true);
        }, (error) => {
            setIsLoaded(false);
            setError(error);
        })
    }, [])

    if (error) {
        return (
            <AdminUI>
                {NotificationManager.error(`${error.message}`, 'Error')}
                <NotificationContainer />
            </AdminUI>
        )
    }
    else if (!isLoaded) {
        return (
            <AdminUI>
                <LoadingPlaceholder />
            </AdminUI>
        )
    }
    else {
        return (
            <AdminUI>
                <NotificationContainer />
                <FlexBox>
                    <h1>Edit user {email}</h1>
                    <Button variant="success" onClick={() => { UserUpdate() }}>Update</Button>
                </FlexBox>
                <Row>
                    <Col>
                        <Form>
                            <Container>
                                <Card className="mb-3">
                                    <Card.Header>
                                        First name
                                    </Card.Header>
                                    <Card.Body>
                                        <Form.Control aria-label="large" value={firstName} onChange={e => setFirstName(String(e.target.value))} placeholder='First name' type='text' />
                                    </Card.Body>
                                </Card>
                                <Card className="mb-3">
                                    <Card.Header>
                                        Last name
                                    </Card.Header>
                                    <Card.Body>
                                        <Form.Control aria-label="large" value={lastName} onChange={e => setLastName(String(e.target.value))} placeholder='First name' type='text' />
                                    </Card.Body>
                                </Card>
                                <Card className="mb-3">
                                    <Card.Header>
                                        Email
                                    </Card.Header>
                                    <Card.Body>
                                        <Form.Control aria-label="large" value={email} onChange={e => setEmail(String(e.target.value))} placeholder='user@dejikyo.com' type='email' />
                                    </Card.Body>
                                </Card>
                                <Card className="mb-3">
                                    <Card.Header>
                                        Role
                                    </Card.Header>
                                    <Card.Body>
                                        <Form.Control aria-label="large" value={role} onChange={e => setRole(String(e.target.value))} placeholder='role' type='text' />
                                    </Card.Body>
                                </Card>
                                <Card className="mb-3">
                                    <Card.Header>
                                        Approved
                                    </Card.Header>
                                    <Card.Body>
                                        <Form.Control aria-label="large" value={approved} onChange={e => setApproved(String(e.target.value))} placeholder='' type='text' />
                                    </Card.Body>
                                </Card>
                            </Container>
                        </Form>
                    </Col>
                    <Col>
                        <Form>
                            <Container>
                                <Card className="mb-3">
                                    <Card.Header>
                                        Company
                                    </Card.Header>
                                    <Card.Body>
                                        <Form.Control aria-label="large" value={companyName} onChange={e => setCompanyName(String(e.target.value))} type='text' />
                                    </Card.Body>
                                </Card>
                                <Card className="mb-3">
                                    <Card.Header>
                                        VAT
                                    </Card.Header>
                                    <Card.Body>
                                        <Form.Control aria-label="large" value={vat} onChange={e => setVat(String(e.target.value))} type='text' />
                                    </Card.Body>
                                </Card>
                                <Card className="mb-3">
                                    <Card.Header>
                                        Register code
                                    </Card.Header>
                                    <Card.Body>
                                        <Form.Control aria-label="large" value={reg} type="text" onChange={e => setReg(String(e.target.value))} />
                                    </Card.Body>
                                </Card>
                                <Card className="mb-3">
                                    <Card.Header>
                                        Website
                                    </Card.Header>
                                    <Card.Body>
                                        <Form.Control aria-label="large" value={website} onChange={e => setWebsite(String(e.target.value))} type='text' />
                                    </Card.Body>
                                </Card>
                            </Container>
                        </Form>
                    </Col>
                </Row>
            </AdminUI>
        )
    }
}

export default EditUser