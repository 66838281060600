import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import styled from "styled-components";
import { Context } from "../../../..";
import { userFetchTickets } from "../../../../api/ticketAPI";
import Page from "../../Interface/Page/Page";
import { Helmet } from "react-helmet";
import AccountMenu from "../subcomponents/AccountMenu";
import PageHeader from "../subcomponents/PageHeader";
import MediaQuery from "react-responsive";
import TicketsMobile from "../subcomponents/TicketsMobile";
import TicketsDesktop from "../subcomponents/TicketsDesktop";
import { Link, useNavigate } from "react-router-dom";
import LoadingPlaceholder from "../../../Functional/LoadingPlaceholder";

const TemporaryHide = styled.div`
  @media screen and (max-width: 1127px) {
    display: none;
  }
`;
const Container = styled.div`
position: relative;
  margin-top: 20px;
  @media screen and (min-width: 1128px) {
    margin-top: 30px;
    margin-bottom: 40px;
    display: flex;
    width: 1128px;
    margin: 30px auto;
  }
`;

const Wrapper = styled.div`
  width: calc(100vw - 36px);
  margin: 0 auto;
  @media screen and (min-width: 1128px) {
    width: auto;
    margin: 0;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  @media screen and (min-width: 1128px) {
    justify-content: space-between;
    flex-direction: row;
  }
`;

const NewTicket = styled.button`
  width: 284px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #40bf6a;
  border: none;
  border-radius: 4px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  @media screen and (min-width: 1128px) {
    height: 40px;
    font-size: 13px;
    line-height: 16px;
    width: 175px;
  }
  &:hover{
    background-color: #61D186; 
  }
  &:active{
    background-color: #2E9E53;
  }
`;

const MobileBackButton = styled(Link)`
&:before{
  content: url('/arrow-back.svg');
}
text-decoration: none;
color: black;
position: absolute;
top: -5px;
margin-left: 3px;
vertical-align: center;
@media screen and (min-width: 1128px){
  display: none;
}
&:hover{
  color: inherit;
}
&:active{
  color: inherit;
}
`;


const Tickets = () => {
  // data
  const { user } = useContext(Context);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [tickets, setTickets] = useState([]);

  // fetching ticket list
  useEffect(() => {
    userFetchTickets(user.user.id).then(
      (data) => {
        setTickets(data);
        setIsLoaded(true);
      },
      (e) => {
        setError(e);
        setIsLoaded(false);
      }
    );
  }, []);
  if (error) {
    return (
      <Page>
        <h5>{error}</h5>
      </Page>
    );
  } else if (isLoaded) {
    return (
      <Page seo_title={`Vitaforest - Account - Tickets`} seo_description={'Vitaforest user orders page'}>
        <Container>
          <TemporaryHide>
            <AccountMenu />
          </TemporaryHide>
          <Wrapper>
            <MobileBackButton to={'/account'} />
            <Flex>
              <PageHeader>My tickets</PageHeader>
              <NewTicket
                onClick={() => {
                  navigate("/account/new-ticket");
                }}
              >
                Create new ticket
              </NewTicket>
            </Flex>
            <MediaQuery maxWidth={1127}>
              <TicketsMobile tickets={tickets} />
            </MediaQuery>
            <MediaQuery minWidth={1128}>
              <TicketsDesktop tickets={tickets} />
            </MediaQuery>
          </Wrapper>
        </Container>
      </Page>
    );
  } else {
    return (
      <Page>
        <LoadingPlaceholder />
      </Page>
    );
  }
};

export default Tickets;
