import React, { useState } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Input from "./Input/Input";
import Textarea from "./Textarea/Textarea";
import DefaultButton from "./Buttons/DefaultButton";
import Select from "react-select";
import { addToWishlist } from "../../../api/wishlistAPI";
const ModalWrapper = styled.div`
  position: absolute;
  z-index: 1000;
  background-color: #fff;
  box-sizing: border-box;
  padding: 30px 50px;
  max-width: 552px;
  top: calc(50vh - 128px);
  left: calc(50vw - 285px);
  @media screen and (max-width: 700px) {
    width: calc(100vw - 40px);
    left: 20px;
  }
`;

const Close = styled.button`
  position: absolute;
  right: 22px;
  top: 35px;
  border: none;
  background-color: transparent;
`;

const ModalText = styled.p`
  font-size: 14px;
  line-height: 17px;
  color: #333333;
  margin-bottom: 0;
`;

const ItemTitle = styled(Link)`
  color: #18a0fb;
  text-decoration: none;
`;

const ModalTitle = styled.h3`
  font-weight: 600;
  font-size: 20px;
  line-height: 17px;
  display: flex;
  align-items: center;
  padding: 10px 0px;
  box-shadow: 0px 1px 0px #303236;
  margin-bottom: 30px;
`;

const Shadow = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #000000;
  opacity: 0.6;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 15;
  overflow: hidden;
`;

const CartLink = styled(Link)`
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #40bf6a;
  text-decoration: none;
  padding: 12px 30px;
  border: 1px solid #40bf6a;
  border-radius: 4px;
  &:hover,
  &:active {
    background-color: #40bf6a;
    color: white;
  }
`;

const ButtonsWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-top: 30px;
`;

const CartFlex = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

const Image = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 4px;
`;

const CheckoutButton = styled(CartLink)`
  background-color: #40bf6a;
  color: #ffffff;
  &:hover {
    background-color: #61d186;
  }
  &:active {
    background-color: #2e9e53;
  }
`;

const OrderNumber = styled.span`
  font-size: 13px;
  line-height: 16px;
  color: #40bf6a;
`;

const QuestionForm = styled.div``;

const QuestionWrapper = styled(ModalWrapper)`
  top: 10px;
`;

const QuestionButton = styled(DefaultButton)`
  margin: 15px auto;
`;

const WishList = styled.div``;
const Modal = ({
  isShowing,
  hide,
  type,
  item,
  link,
  image,
  order,
  action,
  allow,
  setRestoreEmail,
  wishlists,
  id,
  notiManager,
  reloadState,
  reloadStateSetter,
}) => {
  const [name, setName] = useState();
  const [vat, setVat] = useState();
  const [registry, setRegistry] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [currentWishlist, setCurrentWishlist] = useState();

  const selectNewItem = (OnChangeValue) => {
    setCurrentWishlist(OnChangeValue.value);
  };

  return isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <Shadow
            onClick={() => {
              allow();
              hide();
            }}
          />
          {type == "request" ? (
            <ModalWrapper>
              <Close
                onClick={() => {
                  allow();
                  hide();
                }}
              >
                <img src="/close.svg" />
              </Close>
              <ModalTitle>Request</ModalTitle>
              <ModalText>
                Item <ItemTitle to={`/en/product/${link}`}>{item}</ItemTitle>{" "}
                successfuly added to request
              </ModalText>
              <ButtonsWrapper>
                <CartLink to="/request">To request</CartLink>
              </ButtonsWrapper>
            </ModalWrapper>
          ) : type == "add-cart" ? (
            <ModalWrapper>
              <Close
                onClick={() => {
                  allow();
                  hide();
                }}
              >
                <img src="/close.svg" />
              </Close>
              <ModalTitle>Shopping cart</ModalTitle>
              <CartFlex>
                <Image src={image} />
                <ModalText>
                  You added
                  <ItemTitle to={`/en/product/${link}`}> {item}</ItemTitle> to
                  your shopping cart
                </ModalText>
              </CartFlex>
              <ButtonsWrapper>
                <CartLink to="/cart">View cart</CartLink>
                <CheckoutButton to="/checkout">Go to checkout</CheckoutButton>
              </ButtonsWrapper>
            </ModalWrapper>
          ) : type == "delete" ? (
            <ModalWrapper>
              <Close
                onClick={() => {
                  allow();
                  hide();
                }}
              >
                <img src="/close.svg" />
              </Close>
              <ModalTitle>Shopping cart</ModalTitle>
              <CartFlex>
                <Image src={image} />
                <ModalText>
                  Are you sure you would like to remove this item from the
                  shopping cart?
                </ModalText>
              </CartFlex>
              <ButtonsWrapper>
                <CartLink to="/cart">Cancel</CartLink>
                <CheckoutButton to="/checkout">Ok</CheckoutButton>
              </ButtonsWrapper>
            </ModalWrapper>
          ) : type == "order" ? (
            <ModalWrapper>
              <Close
                onClick={() => {
                  allow();
                  hide();
                }}
              >
                <img src="/close.svg" />
              </Close>
              <ModalTitle>Successful ordering</ModalTitle>
              <CartFlex>
                <ModalText>
                  Your order number is
                  <OrderNumber> {order}</OrderNumber>. We`ll email you an order
                  confirmation with details and tracking info.
                </ModalText>
              </CartFlex>
              <ButtonsWrapper>
                <CartLink to="/en/shop">Go to shop</CartLink>
                <CheckoutButton to="/account/orders">Continue</CheckoutButton>
              </ButtonsWrapper>
            </ModalWrapper>
          ) : type == "reset-password" ? (
            <ModalWrapper>
              <Close
                onClick={() => {
                  allow();
                  hide();
                }}
              >
                <img src="/close.svg" />
              </Close>
              <ModalTitle>Reset password</ModalTitle>
              <ModalText>
                Enter your email and we send link to restore password
                <Input
                  type="email"
                  onChange={(e) => {
                    setRestoreEmail(e.target.value);
                  }}
                />
              </ModalText>
              <ButtonsWrapper>
                <CheckoutButton
                  onClick={() => {
                    action();
                  }}
                >
                  Send link
                </CheckoutButton>
              </ButtonsWrapper>
            </ModalWrapper>
          ) : type == "question" ? (
            <QuestionWrapper>
              <Close
                onClick={() => {
                  allow();
                  hide();
                }}
              >
                <img src="/close.svg" />
              </Close>
              <ModalTitle>Ask your question</ModalTitle>
              <CartFlex>
                <Image src={image} />
                <ModalText>{item}</ModalText>
              </CartFlex>
              <QuestionForm>
                <Input
                  label="Company Name"
                  placeholder="Company Name"
                  type="text"
                  required="true"
                  onChange={(e) => setName(e.target.value)}
                />
                <Input
                  label="Tax/VAT number"
                  placeholder="_ _ _ _ _ _"
                  type="text"
                  required="true"
                  onChange={(e) => setVat(e.target.value)}
                />
                <Input
                  label="Registry code"
                  placeholder="_ _ _ _ _ _"
                  type="text"
                  onChange={(e) => setRegistry(e.target.value)}
                />
                <Input label="Contact name" placeholder="Person" type="text" />
                <Input
                  label="Email"
                  placeholder="youraddress@yourmail.com"
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  required="true"
                />
                <Input
                  label="Phone number"
                  placeholder="+ _ _ _ (_ _ _)"
                  type="text"
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  required="true"
                />
                <Textarea label="Comment"></Textarea>
                <QuestionButton title="Ask question" />
              </QuestionForm>
            </QuestionWrapper>
          ) : type == "wishlist" ? (
            <ModalWrapper>
              <Close
                onClick={() => {
                  allow();
                  hide();
                }}
              >
                <img src="/close.svg" />
              </Close>
              <ModalTitle>
                Please choose a wish list for the selected product
              </ModalTitle>
              <WishList>
                <Select
                  options={wishlists}
                  onChange={selectNewItem}
                  title="Search by"
                />
              </WishList>
              {console.log(id)}
              <QuestionButton
                title="Add to wishlist"
                onClick={() => {
                  addToWishlist(id, currentWishlist).then((data) => {
                    if (data.id) {
                      allow();
                      hide();
                      notiManager.success("Item added to wishlist", "Success");
                      reloadStateSetter(!reloadState);
                    } else if (data.status == "in_wish") {
                      allow();
                      hide();
                      notiManager.warning(
                        "Item already in wishlist",
                        "Warning"
                      );
                    }
                  });
                }}
              ></QuestionButton>
            </ModalWrapper>
          ) : null}
        </React.Fragment>,
        document.body
      )
    : null;
};
export default Modal;
