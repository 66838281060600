import React, { useContext, useEffect, useState } from "react";
import Page from "../../Interface/Page/Page";
import styled from "styled-components";
import AccountMenu from "../subcomponents/AccountMenu";
import PageHeader from "../subcomponents/PageHeader";
import { Link, useNavigate, useParams } from "react-router-dom";
import { fetchProducts } from "../../../../api/productAPI";
import Select from "react-select";
import { Context } from "../../../..";
import {
  createNewWishlist,
  getAllWishlists,
  updateWishlist,
} from "../../../../api/wishlistAPI";
import LoadingPlaceholder from "../../../Functional/LoadingPlaceholder";
import Input from "../../Interface/Input/Input";
import { Form } from "react-bootstrap";
import { NotificationContainer, NotificationManager } from "react-notifications";

const ContentContainer = styled.div`
  position: relative;
  margin-top: 20px;
  width: calc(100vw - 36px);
  margin: 30px auto;
  @media screen and (min-width: 1128px) {
    width: auto;
    display: flex;
    margin-top: 30px;
    margin-bottom: 40px;
  }
`;

const TemporaryHide = styled.div`
  @media screen and (max-width: 1127px) {
    display: none;
  }
`;

const Container = styled.div`
  width: 100%;
`;


const MobileBackButton = styled(Link)`
  &:before {
    content: url("/arrow-back.svg");
  }
  text-decoration: none;
  color: black;
  position: absolute;
  top: -5px;
  vertical-align: center;
  @media screen and (min-width: 1128px) {
    display: none;
  }
  &:hover {
    color: inherit;
  }
  &:active {
    color: inherit;
  }
`;

const Table = styled.table`
  width: 100%;
  @media screen and (min-width: 1128px) {
    width: 840px;
    box-sizing: border-box;
    padding-bottom: 15px;
  }
`;

const TableContainer = styled.div`
  @media screen and (min-width: 1128px) {
    margin-top: 20px;
    border: 1px solid #eaebec;
    border-radius: 6px 6px 6px 6px;
  }
`;

const TableRow = styled.tr`
  td {
    padding: 10px 18px 10px 18px;
    box-shadow: 0px 1px 0px 0px #eaebec;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    vertical-align: center;
    color: #303236;
    @media screen and (min-width: 1128px) {
      font-size: 14px;
      line-height: 17px;
      box-shadow: none;
    }
  }
  th {
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    color: #7f878b;
    background: #f3f7f2;
    padding: 0px 18px;
    @media screen and (min-width: 1128px) {
      background: none;
      border-bottom: 1px solid #eaebec;
      padding-top: 10px;
    }
  }
  td,
  th {
    padding-right: 10px;
  }
  td:nth-child(1),
  th:nth-child(1) {
    padding-left: 35px;
    width: 570px;
  }
  td:nth-child(2),
  th:nth-child(2) {
    width: 40px;
    padding-left: 60px;
  }
  td:nth-child(3),
  th:nth-child(3) {
    width: 60px;
    padding-left: 60px;
    padding-right: 35px;
  }
  &:hover {
    box-shadow: 0px 4px 10px rgba(26, 33, 45, 0.05);
    border-radius: 4px;
  }
`;

const SelectContainer = styled.div`
  max-width: 590px;
  margin-bottom: 5px;
  .react-select__indicator-separator {
    display: none;
  }
  .react-select__control {
    background: #ffffff;
    border: 1px solid #dee1e3;
    border-radius: 4px;
  }
`;

const Qty = styled.input`
  background: #f7f8f9;
  border: 1px solid #eaebec;
  border-radius: 4px;
  width: 40px;
  height: 37px;
  padding-left: 12px;
`;

const QtyCell = styled.td`
  padding-top: 0px !important;
`;

const Button = styled.button`
  background: #3c3f54;
  border-radius: 4px;
  font-size: 13px;
  line-height: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  padding: 12px 15px 12px 15px;
  border: none;
  border: 1px solid #3c3f54;
  &:hover,
  &:focus,
  &:active {
    color: #3c3f54;
    background-color: #fff;
    transition: all 0.5s ease-out;
  }
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 35px 10px 35px;
  align-items: center;
  width: 100%;
`;
const Total = styled.p`
  margin-bottom: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 30px;
`;
const AddToCartBtn = styled.button`
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  background: #40bf6a;
  color: #ffffff;
  padding: 12px 24px 12px 47px;
  border: 1px solid #40bf6a;
  border-radius: 4px;
  position: relative;
  &:before {
    content: url("/btnBascket.svg");
    position: absolute;
    left: 17px;
    top: 9px;
  }
`;

const SaveListBtn = styled.button`
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #40bf6a;
  padding: 12px 24px;
  background-color: #fff;
  border: 1px solid #40bf6a;
  border-radius: 4px;
  &:hover{
    background: #40bf6a;
    color: white;
  }
  &:active{
    color: white;
    background: #2E9E53;
  }
`;

const HeaderControl = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
  h5{
    margin: 0px;
    margin-right: 10px;
  }
`;

const Name = styled(Form.Control)`
  &:focus, :active{
    border-color: #40bf6a;
    box-shadow: none;
  }
`

const AccountEditWishlist = () => {
  const [products, setProducts] = useState([]);
  const [items, setItems] = useState([]);
  const [needReload, setNeedReload] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [selectedNewItem, setSelectedNewItem] = useState(1);
  const [orderItems, setOrderItems] = useState([]);
  const [selectItems, setSelectItems] = useState({});
  const [productsLoaded, setProductsLoaded] = useState(false);
  const [reloadItems, setReloadItems] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [currentName, setCurrentName] = useState(null);
  const { user } = useContext(Context);
  const navigate = useNavigate();
  const { id } = useParams();

  const calcPrice = (price, qty) => {
    return price * qty;
  };

  const calcFullPrice = () => {
    let total = 0;
    orderItems.map((item) => {
      total =
        total +
        item.qty *
        products.find((elem) => {
          if (item.itemId === elem.id) {
            return elem;
          }
        }).price;
    });
    return total;
  };

  const changeQty = (key, value, id) => {
    setOrderItems(
      orderItems.map((i) =>
        i.itemId === id ? { ...i, [key]: Number(value) } : i
      )
    );
  };

  const selectNewItem = (OnChangeValue) => {
    setSelectedNewItem(OnChangeValue.value);
  };

  const addNewItem = () => {
    if (selectedNewItem) {
      products.map((item) => {
        if (item.id === selectedNewItem) {
          setOrderItems([
            ...orderItems,
            {
              itemId: item.id,
              qty: quantity,
            },
          ]);
        }
      });
      setReloadItems(!reloadItems);
    }
  };

  const HandleSave = (name, items) => {
    updateWishlist(name, items, id).then((data) => {
      if (data.status == 'updated') {
        NotificationManager.success('Wishlist updated', 'Success');
        setTimeout(() => {
          navigate('/account/wishlist');
        }, 1500);
      }
    });
  };

  useEffect(() => {
    getAllWishlists(user.user.id).then((data) => {
      data.map((elem) => {
        if (elem.id == id) {
          setCurrentName(elem.name);
          setOrderItems(elem.items);
        }
      });
    });

    fetchProducts([], []).then(
      (data) => {
        setProducts(data);
        let dataArray = [];
        data.map((item) => {
          dataArray.push({
            value: item.id,
            label: `${item.title} (SKU: ${item.sku})`,
          });
        });
        setSelectItems(dataArray);
        setProductsLoaded(true);
      },
      (error) => {
        setProductsLoaded(false);
        setError(error);
      }
    );
    return () => { };
  }, [needReload]);
  if (error) {
    return <div>error</div>;
  } else if (!productsLoaded) {
    return (
      <Page variant={"centred"}>
        <ContentContainer>
          <TemporaryHide>
            <AccountMenu />
          </TemporaryHide>
          <Container>
            <MobileBackButton to={"/account/wishlist"} />
            <PageHeader>Edit wishlist</PageHeader>
            <LoadingPlaceholder />
          </Container>
        </ContentContainer>
      </Page>
    );
  } else {
    return (
      <Page variant={"centred"}>
        <NotificationContainer />
        <ContentContainer>
          <TemporaryHide>
            <AccountMenu />
          </TemporaryHide>
          <Container>
            <MobileBackButton to={"/account/wishlist"} />
            <PageHeader style={{ margin: "0px" }}>Edit wishlist</PageHeader>
            <HeaderControl>
              <h5>Name: </h5>
              <Form.Control
                value={currentName}
                onChange={(e) => {
                  setCurrentName(e.target.value);
                }}
                style={{ width: "40%" }}
              />
            </HeaderControl>
            <TableContainer>
              <Table>
                <thead>
                  <TableRow>
                    <th>Product name</th>
                    <th>Quantity</th>
                    <th>Subtotal</th>
                  </TableRow>
                </thead>
                <tbody>
                  {orderItems.map((item) => (
                    <TableRow>
                      <td>
                        <SelectContainer>
                          <Select
                            title="Search by "
                            options={selectItems}
                            onChange={selectNewItem}
                            isSearchable={true}
                            className="react-select-container"
                            classNamePrefix="react-select"
                            defaultValue={selectItems.find((elem) => {
                              if (item.itemId === elem.value) {
                                return elem;
                              }
                            })}
                          ></Select>
                        </SelectContainer>
                      </td>
                      <QtyCell>
                        <Qty
                          value={item.qty}
                          onChange={(e) => {
                            changeQty("qty", e.target.value, item.itemId);
                          }}
                          placeholder="Qty"
                        />
                      </QtyCell>

                      <td>
                        {calcPrice(
                          products.find((elem) => {
                            if (item.itemId === elem.id) {
                              return elem;
                            }
                          }).price,
                          item.qty
                        )}
                      </td>
                    </TableRow>
                  ))}
                  <TableRow>
                    <td>
                      <SelectContainer>
                        <Select
                          title="Search by "
                          options={selectItems}
                          onChange={selectNewItem}
                          isSearchable={true}
                          className="react-select-container"
                          classNamePrefix="react-select"
                        />
                      </SelectContainer>
                    </td>

                    <QtyCell>
                      <Qty
                        value={quantity}
                        placeholder="Qty"
                        onChange={(e) => setQuantity(e.target.value)}
                      />
                    </QtyCell>

                    <td></td>
                  </TableRow>
                </tbody>
              </Table>
              <FlexRow>
                <Button
                  onClick={() => {
                    addNewItem();
                  }}
                >
                  Add product
                </Button>
                <Total>Total: €{calcFullPrice()}</Total>
              </FlexRow>
            </TableContainer>
            <ButtonsContainer>
              <SaveListBtn
                onClick={() => {
                  HandleSave(currentName, orderItems);
                }}
              >
                Save list
              </SaveListBtn>
            </ButtonsContainer>
          </Container>
        </ContentContainer>
      </Page>
    );
  }
};
export default AccountEditWishlist;
