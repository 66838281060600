import React from "react";
import styled from "styled-components";
import { getFileUrl } from "../../../../../api/productAPI";
import Counter from "../../Counter/Counter";
import CartModalStatus from "../../Status/CartModalStatus";
import CartStatus from "../../Status/CartStatus";
const Product = styled.li`
  list-style: none;
  display: flex;
  position: relative;
  margin-bottom: 15px;
`;
const ProductImage = styled.img`
  width: 100px;
  height: auto;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 13px;
`;

const ProductTitle = styled.h5`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 5px;
`;

const ProductWrapper = styled.div``;

const ProductPrice = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #7f878b;
  opacity: 0.8;
  margin-bottom: 15px;
`;

const Preorder = styled.p``;
const Qty = styled.p`
  margin-bottom: 0;
  min-width: 34px;
  min-height: 30px;
  background: #f7f8f9;
  border: 1px solid #eaebec;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
`;
const Status = styled(CartModalStatus)`
  position: absolute;
  left: 0px;
  top: 0px;
`;

const AmountContainer = styled.div`
  display: flex;
`;

const Ammount = styled.p`
  margin-bottom: 0px;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  font-weight: 600;
  color: #303236;
`;
const CartProduct = ({ img, qty, price, title, type, status }) => {
  return (
    <Product>
      <ProductImage src={getFileUrl(img)} />
      <ProductWrapper>
        <ProductTitle>{title}</ProductTitle>
        {price ? (
          <ProductPrice> €{price.toFixed(2)}/kg</ProductPrice>
        ) : (
          <Preorder>Preorder</Preorder>
        )}
        {type === "cart" ? (
          <Counter qty={qty} />
        ) : (
          <AmountContainer>
            <Qty>{qty}</Qty>
            {price ? <Ammount>€{(price * qty).toFixed(2)}</Ammount> : null}
          </AmountContainer>
        )}

        <Status status={status} />
      </ProductWrapper>
    </Product>
  );
};

export default CartProduct;
