import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { decodeEditorBody, editorParse } from "../../../../../api/postAPI";
import FilePopup from "./FilePopup";
import { convertFromRaw, Editor, EditorState } from "draft-js";
import { getFileUrl } from "../../../../../api/productAPI";
import { getFilesCollection } from "../../../../../api/fileAPI";
import { Context } from "../../../../..";
import LoadingPlaceholder from "../../../../Functional/LoadingPlaceholder";

const TabsContainer = styled.div`
  margin-top: 35px;
  @media screen and (min-width: 1128px) {
    margin-top: 5px;
  }
`;

const ButtonsRow = styled.div`
  margin-bottom: 30px;
  @media screen and (min-width: 1128px) {
    text-align: left;
    border-bottom: 1px solid #eaebec;
    button {
      border-bottom: none;
      min-width: max-content;
      margin-right: 50px;
      margin-bottom: -1px;
      border-bottom: 1px solid #eaebec;
    }
  }
`;

const Button = styled.button`
  border: none;
  background-color: #fff;
  border-bottom: 1px solid #eaebec;
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  text-align: center;
  color: #7f878b;
  padding: 10px;
  min-width: 33%;
  box-sizing: border-box;
  @media screen and (max-width: 419px) {
    min-width: 29%;
  }
  @media screen and (max-width: 343px) {
    min-width: 27%;
  }
  &:hover,
  :active,
  :focus {
    color: #40bf6a;
    border-bottom: 1px solid #40bf6a;
  }
`;

const Details = styled.div``;
const Files = styled.div``;
const Delivery = styled.div``;

const DocumentButton = styled.button`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #303236;
  margin-bottom: 25px;
  padding-left: 37px;
  border: none;
  background-color: transparent;
  position: relative;
  &:before {
    content: url("/file.svg");
    position: absolute;
    left: 0px;
  }
  @media screen and (min-width: 1128px) {
    margin-bottom: 35px;
  }
`;
const Tabs = ({ documents, description, onClick }) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState(true);
  const [isDeliveryOpen, setIsDeliveryOpen] = useState(false);
  const [isFilesOpen, setIsFilesOpen] = useState(false);
  const [popupShown, setPopupShown] = useState(false);
  const [currentFile, setCurrentFile] = useState(false);
  const [fileGallery, setFileGallery] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const itemText = EditorState.createWithContent(decodeEditorBody(description));
  const { user } = useContext(Context);

  // load files info and render page
  useEffect(() => {
    if (!documents) {
      setIsLoaded(true)
    }
    else {
      getFilesCollection(documents).then(
        (data) => {
          setFileGallery(data);
          setIsLoaded(true);
        },
        (e) => {
          setIsLoaded(false);
          setError(e);
        }
      );
    }
  }, []);
  if (!isLoaded) {
    return <LoadingPlaceholder />;
  } else if (error) {
    return <h5>{error}</h5>;
  } else {
    return (
      <TabsContainer>
        {popupShown ? (
          <FilePopup
            url={currentFile}
            onClick={() => {
              setPopupShown(false);
              onClick();
            }}
          />
        ) : (
          <></>
        )}
        <ButtonsRow>
          <Button
            onClick={() => {
              setIsDetailsOpen(true);
              setIsDeliveryOpen(false);
              setIsFilesOpen(false);
            }}
          >
            Details
          </Button>
          <Button
            onClick={() => {
              setIsDetailsOpen(false);
              setIsDeliveryOpen(true);
              setIsFilesOpen(false);
            }}
          >
            Payment & delivery
          </Button>
          {documents.length > 0 && user.isAuth ? (
            <Button
              onClick={() => {
                setIsDetailsOpen(false);
                setIsDeliveryOpen(false);
                setIsFilesOpen(true);
              }}
            >
              Files
            </Button>
          ) : null}
        </ButtonsRow>
        {isDetailsOpen ? (
          <Details>
            <Editor editorState={itemText} readOnly={true} />
          </Details>
        ) : isFilesOpen && documents ? (
          <Files>
            {fileGallery.map((item) => {
              return (
                <DocumentButton
                  onClick={() => {
                    setCurrentFile(getFileUrl(item.src));
                    setPopupShown(true);
                    onClick();
                  }}
                >
                  {item.name}
                </DocumentButton>
              );
            })}
          </Files>
        ) : isDeliveryOpen ? (
          <Delivery>
            <h2>Packaging</h2>
            <p>
              We use double packages of 5-10 kg to prevent moisture
              condensation. Packages are placed in strong five-layer cardboard
              boxes or craft bags. If an order consists of 4 or more boxes or
              bags it can be plased on a pallet at your request.
            </p>
            <h2>Delivery</h2>
            <p>
              The main cargo delivery is carried out for customer`s account by
              DHL or Europiir Logistics, which helps our customers save on
              shipping costs without sacrificing its quality. At your request,
              we`ll send the available goods in any convenient way from Tallinn
              within 2 days after making the payment. The average production and
              delivery time for custom-made goods is 35–40 days.
            </p>
            <h2>Payment</h2>
            <p>
              Payment is issued on proforma invoice or you can do it online.
            </p>
          </Delivery>
        ) : (
          <></>
        )}
      </TabsContainer>
    );
  }
};
export default Tabs;
