import React, { useState } from "react";
import styled from "styled-components";
import { removeNotification } from "../../../api/notificationsAPI";

const Title = styled.h4`
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
`;

const Description = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const Container = styled.div`
  box-shadow: 0px 4px 14px 0px rgba(26, 33, 45, 0.1);
  box-sizing: border-box;
  padding: 10px 15px 15px 25px;
  position: relative;
  @media screen and (min-width: 1128px){
    min-width: 600px;
    padding: 20px 15px 15px 25px;
  }
`;

const Close = styled.button`
  position: absolute;
  right: 3px;
  top: 7px;
  border: none;
  margin-top: 0px !important;
  background-color: transparent;
`;

const Notification = ({ title, description, variant, id, reloadState, reloadTrigger }) => {
  const [isShowing, setIsShowing] = useState(true);

  const handleRemove = (id) => {
    removeNotification(id).then((data) => {
      if (data.status == 'removed') {
        reloadTrigger(!reloadState);
      }
    })
  }

  if (isShowing == true) {
    return (<Container>
      <Close onClick={() => { handleRemove(id) }}>
        <img src="/close.svg" />
      </Close>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Container>)
  }
  else {
    return (<></>);
  }
};

export default Notification;
