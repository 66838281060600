import React from "react";
import { NotificationContainer } from "react-notifications";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { addToRequest } from "../../../../../api/productAPI";
import Status from "../../../Interface/Status/Status";
import useModal from "../../../../Functional/useModal";
import Modal from "../../../Interface/Modal";
import useScrollBlock from "../../../../Functional/useScrollBlock";

const Card = styled.div`
  box-shadow: 1px 4px 10px 1px rgba(26, 33, 45, 0.05);
  border-radius: 4px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  margin-bottom: 10px;
`;

const Title = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #303236;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SKU = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #7f878b;
  margin-bottom: 0;
`;

const Button = styled.button`
  width: 93px;
  height: 30px;
  border: 1px solid #40bf6a;
  border-radius: 4px;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0px 17px 0px 20px;
  color: #40bf6a;
  background-color: #fff;
  &:hover {
    background-color: #61d186;
    color: white;
  }
  &:active {
    background-color: #2e9e53;
  }
`;

const FirstFlex = styled(Flex)`
  margin-bottom: 7px;
`;

const LinkElement = styled(Link)`
  text-decoration: none;
  color: inherit;
  &:hover {
    color: black;
  }
`;

const LineUnLogged = ({ status, title, sku, url, id, img, moq, step }) => {
  const { isShowing, toggle } = useModal();
  const [blockScroll, allowScroll] = useScrollBlock();
  return (
    <Card>
      <NotificationContainer />
      <Modal
        allow={allowScroll}
        type={"request"}
        isShowing={isShowing}
        hide={toggle}
        item={title}
        link={url}
      />
      <Status variant={status} />
      <FirstFlex>
        <Title>
          <LinkElement to={`/en/product/${url}`}>{title}</LinkElement>
        </Title>
      </FirstFlex>
      <Flex>
        <SKU>SKU: {sku}</SKU>
        <Button
          onClick={() => {
            addToRequest(id, title, img, moq, status, moq, step);
            blockScroll();
            toggle();
          }}
        >
          Request
        </Button>
      </Flex>
    </Card>
  );
};

export default LineUnLogged;
