import React from "react";
import styled from "styled-components";
import { ReactComponent as Image } from "./ButtonVisual.svg";

const ButtonUnliked = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  padding: 0px;
`;
const ButtonLiked = styled(ButtonUnliked)`
  path {
    fill: rgb(255, 83, 83);
  }
  rect{
    stroke: rgb(255, 83, 83);
  }
  padding: 0px;
`;

const WishlistButton = ({ variant, onClick }) => {
  switch (variant) {
    case "liked":
      return (
        <ButtonLiked onClick={onClick}>
          <Image />
        </ButtonLiked>
      );
    case "unliked":
      return (
        <ButtonUnliked onClick={onClick}>
          <Image />
        </ButtonUnliked>
      );
    default:
      return (
        <ButtonUnliked onClick={onClick}>
          <Image />
        </ButtonUnliked>
      );
  }
};

export default WishlistButton;
