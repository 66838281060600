import React, { useState } from "react";
import styled from "styled-components";
import DefaultButton from "../Buttons/DefaultButton";
import MediaQuery from "react-responsive";
import SectionHeading from "../../Interface/TextElements/SectionHeading";
import Input from "../Input/Input";
import Counter from "../Counter/Counter";
import Status from "../Status/Status";
import { useEffect } from "react";
import { useContext } from "react";
import { Context } from "../../../..";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import RequestTableRowItem from "./RequestTableRowItem";
import { createProductRequest } from "../../../../api/ordersAPI";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

const Table = styled.table`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #303236;
  width: 100%;
`;
const ItemsCounter = styled.p`
  font-size: 12px;
  line-height: 19px;
  text-align: center;
  color: #7f878b;
`;
const TableHeader = styled.thead`
  color: #7f878b;
  box-sizing: border-box;
  padding: 5px 18px;
  font-weight: 400;
  background: #f3f7f2;
  width: 100%;
  font-size: 12px;
  line-height: 19px;
`;

const Th = styled.th`
  font-weight: 400;
  padding-right: 5px;
`;

const ThProduct = styled(Th)`
  width: 40%;
  padding-left: 18px;
`;

const ThPrice = styled(Th)`
  width: 18%;
`;

const ThQty = styled(Th)`
  width: 15.5%;
`;

const TBody = styled.tbody``;
const Row = styled.tr`
  position: relative;
  &:after {
    content: " ";
    display: block;
    position: absolute;
    width: calc(100vw - 36px);
    height: 1px;
    background-color: #eaebec;
    left: 18px;
    bottom: -5px;
  }
`;

const Td = styled.td`
  padding-top: 10px;
`;
const Name = styled(Td)`
  padding-left: 18px;
`;

const Oders = styled.div``;

const Summary = styled.table`
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  color: #303236;
  opacity: 0.6;
  width: 100%;
  padding: 10px 0px;
  box-sizing: border-box;
`;

const Container = styled.div`
  padding: 15px;
  box-sizing: border-box;
  margin-top: 25px;
`;
const OrderButton = styled(DefaultButton)`
  width: calc(100vw - 53px);
  margin: 0px auto;
  margin-top: 10px;
  margin-bottom: 25px;
  @media screen and (min-width: 1128px) {
    width: 140px;
    margin: 0px;
    padding: 14px 24px;
    margin-left: 20px;
  }
`;

const DeleteCell = styled.td``;

const DeleteButton = styled.button`
  position: absolute;
  width: 24px;
  height: 24px;
  background: url("/deleteCart.svg");
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  right: 9px;
  bottom: -15px;
  z-index: 8;
  @media screen and (min-width: 1128px) {
    position: initial;
  }
`;

const CentredContainer = styled.div`
  margin: 0px 18px;
`;

const ButtonsContainer = styled.div`
  border-bottom: 1px solid #eaebec;
  box-sizing: border-box;
  padding: 25px 0px;
  @media screen and (min-width: 1128px) {
    display: flex;
    align-items: center;
    border-bottom: none;
    justify-content: center;
  }
`;

const Discount = styled.div`
  box-sizing: border-box;
  padding: 25px 0px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #303236;
`;
const CartContainer = styled.div``;
const LeftColumn = styled.div`
  width: 840px;
  box-sizing: border-box;
  padding: 25px;
  margin-top: 25px;
  background: #ffffff;
  border: 1px solid #eaebec;
  border-radius: 6px;
  margin-bottom: 40px;
`;
const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ProductTable = styled.table`
  width: 100%;
  margin-top: 40px;
  box-shadow: 0px 1px 0px #eaebec;
  margin-bottom: 40px;
`;
const ProductsCounter = styled.p`
  margin-bottom: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #40bf6a;
`;
const DesktopHeading = styled.h3`
  font-weight: 600;
  font-size: 26px;
  color: #303236;
  line-height: 28px;
  margin-bottom: 0;
`;

const TableHead = styled.thead`
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: #7f878b;
  box-shadow: 0px 1px 0px #eaebec;
`;
const Item = styled.td``;

const ProductHead = styled(Item)`
  width: 415px;
`;
const PriceHead = styled(Item)`
  width: 110px;
`;

const QuantityHead = styled(Item)`
  width: 130px;
  padding-right: 30px;
`;

const FormContainer = styled.div`
  width: calc(50% - 12px);
`;

const SummaryContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  flex-wrap: wrap;
`;

const DeleteHead = styled(Item)`
  width: 25px;
`;

const RequestTable = ({
  items,
  setItems,
  needReload,
  setNeedReload,
  notificationPush,
}) => {
  const { user } = useContext(Context);

  const [companyName, setCompanyName] = useState(null);
  const [vat, setVat] = useState(null);
  const [registryCode, setRegistryCode] = useState(null);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [comment, setComment] = useState(null);

  const addNewRequest = (items) => {
    if (!companyName || !vat || !registryCode || !name || !email || !phone) {
      NotificationManager.error(
        "Fill out the required fields on the form",
        "Error"
      );
    } else {
      let formdata = new FormData();
      formdata.append("company_name", companyName);
      formdata.append("vat", vat);
      formdata.append("registry_code", registryCode);
      formdata.append("email", email);
      formdata.append("phone", phone);
      formdata.append("comment", comment);
      formdata.append("items", JSON.stringify(items));
      formdata.append("contact_name", name);
      createProductRequest(formdata).then((data) => {
        if (data.id) {
          NotificationManager.success(
            "Your request successfully sended",
            "Sended"
          );
          localStorage.removeItem("request_items");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          NotificationManager.error(data, "Error");
        }
      });
    }
  };

  const setItemQty = (items) => {
    localStorage.setItem("request_items", JSON.stringify(items));
    setNeedReload(!needReload);
  };

  if (needReload || !needReload) {
    return (
      <>
        <NotificationContainer />
        <MediaQuery minWidth={1128}>
          <CartContainer>
            <LeftColumn>
              <HeaderRow>
                <DesktopHeading>Request</DesktopHeading>
                <ProductsCounter>{items.length} items</ProductsCounter>
              </HeaderRow>
              <ProductTable>
                <TableHead>
                  <tr>
                    <ProductHead>Product</ProductHead>
                    <PriceHead></PriceHead>
                    <QuantityHead>Quantity</QuantityHead>
                    <DeleteHead></DeleteHead>
                  </tr>
                </TableHead>
                <tbody>
                  {items.map((item) => {
                    return (
                      <RequestTableRowItem
                        item={item}
                        items={items}
                        setItems={setItemQty}
                        setNeedReload={setNeedReload}
                        needReload={needReload}
                      />
                    );
                  })}
                </tbody>
              </ProductTable>
              <SummaryContainer>
                <FormContainer>
                  <Input
                    value={companyName}
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                    }}
                    placeholder="Company name"
                    required={true}
                    label={"Company name"}
                  />
                </FormContainer>
                <FormContainer>
                  <Input
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    placeholder="Contact name"
                    required={true}
                    label={"Contact name"}
                  />
                </FormContainer>
                <FormContainer>
                  {" "}
                  <Input
                    value={vat}
                    onChange={(e) => {
                      setVat(e.target.value);
                    }}
                    placeholder="TAX/VAT number"
                    required={true}
                    label={"TAX/VAT number"}
                  />
                </FormContainer>
                <FormContainer>
                  <Input
                    value={registryCode}
                    onChange={(e) => {
                      setRegistryCode(e.target.value);
                    }}
                    placeholder="Registry code"
                    required={true}
                    label={"Registry code"}
                  />
                </FormContainer>
                <FormContainer>
                  <Input
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    placeholder="Email"
                    required={true}
                    label={"Email"}
                  />
                </FormContainer>
                <FormContainer>
                  <Input
                    value={phone}
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                    placeholder="Phone"
                    required={true}
                    label={"Phone"}
                  />
                </FormContainer>

                <FormContainer>
                  <Input
                    value={comment}
                    onChange={(e) => {
                      setComment(e.target.value);
                    }}
                    placeholder="Comment"
                    required={true}
                    label={"Comment"}
                  />
                </FormContainer>
              </SummaryContainer>
              <ButtonsContainer>
                <OrderButton
                  title="Send request"
                  onClick={() => {
                    addNewRequest(items);
                  }}
                />
              </ButtonsContainer>
            </LeftColumn>
          </CartContainer>
        </MediaQuery>
        <MediaQuery maxWidth={1127}>
          <Oders>
            <SectionHeading>Request</SectionHeading>
            <ItemsCounter>{items.length} items</ItemsCounter>
            <Table>
              <TableHeader>
                <ThProduct>Product</ThProduct>
                <ThPrice></ThPrice>
                <ThQty></ThQty>
                <Th></Th>
              </TableHeader>
              <TBody>
                {items.map((item) => {
                  return (
                    <Row>
                      <Name>{item.title} </Name>
                      <DeleteCell>
                        <DeleteButton />
                      </DeleteCell>
                    </Row>
                  );
                })}
              </TBody>
            </Table>
            <Container>
              <Summary>
                <Input
                  value={companyName}
                  onChange={(e) => {
                    setCompanyName(e.target.value);
                  }}
                  placeholder="Company name"
                  required={true}
                  label={"Company name"}
                />
                <Input
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  placeholder="Contact name"
                  required={true}
                  label={"Contact name"}
                />
                <Input
                  value={vat}
                  onChange={(e) => {
                    setVat(e.target.value);
                  }}
                  placeholder="TAX/VAT number"
                  required={true}
                  label={"TAX/VAT number"}
                />
                <Input
                  value={registryCode}
                  onChange={(e) => {
                    setRegistryCode(e.target.value);
                  }}
                  placeholder="Registry code"
                  required={true}
                  label={"Registry code"}
                />
                <Input
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  placeholder="Email"
                  required={true}
                  label={"Email"}
                />
                <Input
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  placeholder="Phone"
                  required={true}
                  label={"Phone"}
                />
                <Input
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                  placeholder="Comment"
                  required={true}
                  label={"Comment"}
                />
              </Summary>
            </Container>
            <CentredContainer>
              <ButtonsContainer>
                <OrderButton
                  title="Send request"
                  onClick={() => {
                    addNewRequest(items);
                  }}
                />
              </ButtonsContainer>
            </CentredContainer>
            <Discount></Discount>
          </Oders>
        </MediaQuery>
      </>
    );
  }
};

export default RequestTable;
