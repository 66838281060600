import React from "react";
import styled from "styled-components";

const Component = styled.h3`
  font-weight: 600;
  font-size: 20px;
  line-height: 17px;
  text-align: center;
  color: #303236;
  @media screen and (min-width: 1128px) {
    text-align: left;
    margin-bottom: 25px;
  }
`;
export default function SectionHeading({ children }) {
  return <Component>{children}</Component>;
}
