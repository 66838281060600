export const appendScript = (scriptToAppend) => {
  // automatically create element id and check it
  let name = scriptToAppend.split("/").pop().split(".")[0];
  let validate = document.querySelector(`#${name}`);
  if (validate) {
  } else {
    const script = document.createElement("script");
    script.src = scriptToAppend;
    script.async = true;
    script.id = name;
    document.body.appendChild(script);
  }
};
