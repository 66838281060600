import React from "react";
import styled from "styled-components";
import LinedButton from "../../../Interface/Buttons/LinedButton";
import { getFileUrl } from "../../../../../api/productAPI";
import Status from "../../../Interface/Status/Status";
import { Link } from "react-router-dom";
import { addToRequest } from "../../../../../api/productAPI";
import useModal from "../../../../Functional/useModal";
import Modal from "../../../Interface/Modal";
import useScrollBlock from "../../../../Functional/useScrollBlock";

const CardContainer = styled.div`
  box-shadow: 0px 4px 10px rgba(26, 33, 45, 0.05);
  border-radius: 4px;
  position: relative;
  width: calc(50% - 5px);
  @media screen and (max-width: 320px) {
    width: 100%;
  }
  @media screen and (min-width: 900px) {
    width: calc(33% - 7px);
  }
`;

const CardImage = styled.img`
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  aspect-ratio: 1.47;
`;

const CardContent = styled.div`
  box-sizing: border-box;
  padding: 10px 15px 1px 15px;
  @media screen and (min-width: 1128px) {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 15px 15px 15px;
    justify-content: space-between;
    h3 {
      width: 100%;
    }
    button {
      display: inline;
      margin: 0px !important;
    }
  }
  button {
    display: block;
    margin: 15px auto;
  }
`;

const AbsoluteRow = styled.div`
  button {
    position: absolute;
    right: 5px;
    top: 5px;
  }
`;

const Sku = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #7f878b;
  margin: auto 0;
`;

const Title = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #303236;
  margin-bottom: 10px;
  margin-top: 10px;
  @media screen and (min-width: 1128px) {
    font-size: 16px;
    line-height: 19px;
    margin-top: 10px;
  }
`;

const ShopStatus = styled(Status)`
  position: absolute;
  top: 0px;
`;

const RequestButton = styled(LinedButton)`
  height: 30px;
  width: 108px;
  font-size: 13px;
  line-height: 16px;
  padding-top: 6px;
`;

const LinkElement = styled(Link)`
  text-decoration: none;
  color: inherit;
  &:hover {
    color: black;
  }
`;

const CardUnLogged = ({ img, sku, status, title, url, id, moq, step }) => {
  // funcs
  const { isShowing, toggle } = useModal();
  const [blockScroll, allowScroll] = useScrollBlock();
  return (
    <CardContainer>
      <Modal
        type={"request"}
        isShowing={isShowing}
        allow={allowScroll}
        hide={toggle}
        item={title}
        link={url}
      />
      <Link to={`/en/product/${url}`}>
        <CardImage src={getFileUrl(img)} />
      </Link>
      <CardContent>
        <Title>
          <LinkElement to={`/en/product/${url}`}>{title}</LinkElement>
        </Title>
        <Sku className="SKU">{`SKU: ${sku}`}</Sku>
        <RequestButton
          variant="lined-small"
          title="Request"
          onClick={() => {
            addToRequest(id, title, img, moq, status, moq, step);
            toggle();
            blockScroll();
          }}
        />
      </CardContent>
      <AbsoluteRow>
        <ShopStatus variant={status} />
      </AbsoluteRow>
    </CardContainer>
  );
};

export default CardUnLogged;
