import React from "react";
import styled from "styled-components";
import { ReactComponent as PassIcon } from "./images/pass.svg";
import { ReactComponent as LogOut } from "./images/sign-out.svg";
import { myAccBg } from "./images/myacc-bg.webp";
import { Link } from "react-router-dom";
import { NotificationContainer, NotificationManager } from "react-notifications";

const Main = styled.div`
  width: 100%;
  background-color: #f3f7f2;
  padding: 20px 18px 40px 18px;
  box-sizing: border-box;
  margin-top: -20px;
  position: relative;
  z-index: 2;
  &::before {
    content: url("/encbg.png");
    opacity: 0.4;
    z-index: 1;
    position: absolute;
    right: -40px;
    bottom: -167px;
  }
`;

const UserName = styled.p`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #303236;
  margin: 0px;
`;

const UserMail = styled.p`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #303236;
  opacity: 0.5;
  margin: 5px 0px;
`;

const Controls = styled.div`
  width: 90%;
  bottom: -15px;
  position: absolute;
  display: flex;
  justify-content: space-between;
  z-index: 3;
`;

const ControlButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6.5px 15px;
  gap: 10px;
  background: #ffffff;
  border-radius: 4px;
  border: none;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #303236;
  box-shadow: 0px 2px 0px #f6f6f6;
`;

const EditAccountDetails = styled(Link)`
  position: absolute;
  top: 18px;
  right: 17.5px;
  &:hover svg path {
    fill: #3c3f54;
  }
`;

const MobileAccountHead = (props) => {
  const logOut = () => {
    localStorage.clear();
    setTimeout(() => {
      window.location.replace('/');
    }, 500);
  };
  return (
    <Main>
      <EditAccountDetails to={"/account/details"}>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.3358 5.51593C16.2214 4.6303 16.2214 3.2056 15.3358 2.31997L13.6801 0.664232C12.7944 -0.221395 11.3697 -0.221395 10.4841 0.664232L0.395645 10.7527L0.0105896 14.2567C-0.104927 15.2578 0.742195 16.1049 1.74334 15.9894L5.24734 15.6044L15.3358 5.51593ZM11.9381 6.98838L4.7468 14.1797L1.68508 14.413L1.91837 11.3513L9.1097 4.15995L11.9381 6.98838ZM14.2695 3.38625C14.6854 3.80215 14.7666 4.15995 14.3924 4.53407L12.9795 5.94697L10.1704 3.09929L11.5448 1.72489C11.9381 1.33152 12.3396 1.45639 12.6138 1.73052L14.2695 3.38625Z"
            fill="#7F878B"
          />
        </svg>
      </EditAccountDetails>
      <UserName>{props.fullName}</UserName>
      <UserMail>{props.email}</UserMail>
      <Controls>
        <ControlButton>
          <PassIcon /> Change password
        </ControlButton>
        <ControlButton onClick={() => { logOut() }}>
          <LogOut />
          Sign out
        </ControlButton>
      </Controls>
    </Main>
  );
};

export default MobileAccountHead;
