import { $authHost, $host } from ".";

export const getUrlsList = async () => {
    try {
        const { data } = await $authHost.get('/api/urls');
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const createNewUrl = async (body) => {
    try {
        const { data } = await $authHost.post('/api/urls', body);
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const getUrlData = async (url) => {
    try {
        const { data } = await $authHost.get(`/api/urls/getdata/${url}`);
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const removeUrl = async (id) => {
    try {
        const { data } = await $authHost.post(`/api/urls/remove/${id}`);
        return data;
    } catch (e) {
        return e.response.data;
    }
}