import React from "react";
import EmptyCart from "../components/VitaforestUI/Interface/OrdersComponents/EmptyCart";
import Page from "../components/VitaforestUI/Interface/Page/Page";
import styled from "styled-components";
import FilledCart from "../components/VitaforestUI/Interface/OrdersComponents/FilledCart";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { useState, useEffect, useContext } from "react";
import { fetchBasket } from "../api/basketAPI";
import { Context } from "..";
import LoadingPlaceholder from "../components/Functional/LoadingPlaceholder";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

const CartContainer = styled.div`
  margin-top: 20px;
`;

const Basket = () => {

  const { user } = useContext(Context);

  const [needReload, setNeedReload] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  //funcs
  const pushNotification = (title, type) => {
    if (type === 'remove') {
      NotificationManager.success(`${title} removed from cart`)
    }
    else if (type === 'error') {
      NotificationManager.error(`${title}`)
    }
  }

  useEffect(() => {
    fetchBasket(user.user.id).then((data) => {
      setItems(data.items);
      if (data.items[0].item_type == 'offer') {
        setIsLoaded(false);
        navigate('/checkout');
      }
      else {
        setIsLoaded(true);
      }
    }, (error) => {
      setError(error);
      setIsLoaded(false);
    })
  }, [needReload])
  if (error) {
    return <>{error}</>
  }
  else if (!isLoaded) {
    return (
      <Page>
        <LoadingPlaceholder />
      </Page>
    )
  }
  else {
    return (
      <Page variant={'centred'} seo_title={`Cart - Vitaforest`} seo_description={'Vitaforest cart'}>
        <NotificationContainer />
        <CartContainer>
          {items[0] ? <FilledCart items={items} setItems={setItems} needReload={needReload} setNeedReload={setNeedReload} notificationPush={pushNotification} /> : <EmptyCart />}
        </CartContainer>
      </Page>
    );
  }
};

export default Basket;
