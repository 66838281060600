import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import Counter from "../../../Interface/Counter/Counter";
import { ReactComponent as Image } from "../img/cart.svg";
import { Context } from "../../../../..";
import ShareButton from "../../../Interface/ShareButton/ShareButton";
import WishlistButton from "../../../Interface/WishlistButton/WishlistButton";
import Attributes from "./Attributes";
import { getFileUrl } from "../../../../../api/productAPI";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { addBasketItem } from "../../../../../api/basketAPI";
import { getAllWishlists } from "../../../../../api/wishlistAPI";
import LoadingPlaceholder from "../../../../Functional/LoadingPlaceholder";
import Status from "../../../Interface/Status/Status";
import { addToRequest } from "../../../../../api/productAPI";
import Modal from "../../../Interface/Modal";
import useModal from "../../../../Functional/useModal";
import useScrollBlock from "../../../../Functional/useScrollBlock";
const Container = styled.div``;
const Flex = styled.div`
  display: flex;
`;

const MainImage = styled.img`
  width: 300px;
  object-fit: cover;
  width: 100%;
  aspect-ratio: 300/200;
  border-radius: 4px;
`;

const Slider = styled.div`
  position: relative;
  width: 300px;
  img {
    object-fit: cover;
    width: 100%;
    aspect-ratio: 300/200;
    border-radius: 4px;
  }
  .carousel .thumbs-wrapper {
    margin: 0px;
  }
  .thumbs {
    padding: 0;
    margin: 0px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }
  .carousel .thumb {
    margin: 0;
    padding: 0;
    opacity: 0.3;
    border: none;
    margin-right: 10px;
    :last-child {
      margin-right: 0px;
    }
    &.selected {
      opacity: 1;
      border: none;
    }
  }
`;

const Calculator = styled.div`
  margin-left: 35px;
`;

const Title = styled.h3`
  font-weight: 600;
  font-size: 20px;
  line-height: 17px;
  color: #303236;
  width: 384px;
`;

const SKU = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #7f878b;
  margin-top: 10px;
`;

const DeliveryRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  display: flex;
`;

const OrderStatus = styled.span`
  width: 135px;
  height: 34px;
  background: #f7f8f9;
  border-radius: 0px;
  box-sizing: border-box;
  padding: 9px 15px;
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  border-radius: 4px;
`;

const DeliveryInfo = styled.p`
  box-sizing: border-box;
  padding: 0px 20px;
  width: 239px;
  height: 34px;
  background: rgba(24, 160, 251, 0.1);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;
  margin-bottom: 0;
  span {
    color: #18a0fb;
    opacity: 0.8;
    font-weight: 600;
    font-size: 13px;
    line-height: 14px;
  }
`;

const PurchaseSection = styled.div`
  margin-top: 35px;
  box-sizing: border-box;
  padding: 25px;
  border: 1px solid #eaebec;
  border-radius: 4px;
`;

const PurcahseRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:last-child {
    margin-top: 25px;
  }
  &:first-child {
    margin-top: 0px;
  }
`;

const Price = styled.p`
  font-weight: 600;
  font-size: 26px;
  line-height: 28px;
  color: #303236;
  margin-bottom: 0;
  span {
    color: #7f878b;
  }
`;
const TotalPrice = styled.p`
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
`;
const BuyButton = styled.button`
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  width: 150px;
  height: 38px;
  border: none;
  background: #40bf6a;
  border: 1px solid #40bf6a;
  border-radius: 4px;
  padding: 6px;
  justify-content: center;
  &:hover,
  &:focus,
  &:active {
    background: #ffffff;
    color: #40bf6a;
    path {
      fill: #40bf6a;
    }
  }
  span {
    padding-left: 10px;
  }
`;

const ButtonsContainer = styled.div`
  position: absolute;
  top: 25px;
  right: 25px;
  button {
    margin-bottom: 10px;
  }
`;

const RequestRow = styled(PurcahseRow)`
  justify-content: space-between;
`;

const RequestWrapper = styled.div`
  margin-top: 97px;
`;

const PurchaseAuth = styled.div`
  margin-top: 25px;
`;

const TotalValue = styled.span`
  line-height: 19px;
  color: #303236;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0px;
`;

const TotalTitle = styled.span`
  font-size: 11px;
  line-height: 16px;
  color: #7f878b;
`;

const ProductStatus = styled(Status)``;

const MainSectionDesk = ({
  title,
  price,
  sku,
  moq,
  gallery,
  status,
  step,
  orderStatus,
  attributeId,
  img,
  stock,
  prices,
  id,
  onClick,
  url,
}) => {
  const { user } = useContext(Context);
  // states
  const [value, setValue] = useState(moq);
  const [pricesArray, setPricesArray] = useState(prices ? prices : false);
  const [itemPrice, setItemPrice] = useState(price);
  const [wishlistedItems, setWishlistedItems] = useState([]);
  const [needReload, setNeedReload] = useState(false);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const { isShowing, toggle } = useModal();
  const [blockScroll, allowScroll] = useScrollBlock();
  const [wishModalOpen, setWishModalOpen] = useState(false);
  // load wishlist data
  useEffect(() => {
    if (user.isAuth) {
      getAllWishlists(user.user.id).then(
        (data) => {
          setWishlistedItems(data);
          setIsLoaded(true);
        },
        (error) => {
          setError(error);
          setIsLoaded(false);
        }
      );
    } else {
      setIsLoaded(true);
    }
  }, [needReload]);

  // funcs
  const addToCart = () => {
    let formdata = new FormData();
    formdata.append("title", title);
    formdata.append("img", img);
    formdata.append("status", status);
    formdata.append("price", itemPrice);
    formdata.append("qty", value);
    formdata.append("prices", JSON.stringify(pricesArray));
    formdata.append("item_id", id);
    formdata.append("moq", moq);
    formdata.append("qty_step", step);
    formdata.append("userId", user.user.id);
    addBasketItem(formdata).then((data) => {
      if (data.id) {
        toggle();
        blockScroll();
      } else {
        NotificationManager.error(`${data.message}`, "Error");
      }
    });
  };

  const CheckInWish = (wish) => {
    let result;
    wish.map((item) => {
      item.items.map((elem) => {
        if (elem.itemId === id) {
          result = true;
        }
      });
    });
    return result;
  };

  const BoundWish = (wishlist) => {
    let result = [];
    wishlist.map((item) => {
      result.push({ value: item.id, label: item.name });
    });
    return result;
  };

  const calculatePrice = () => {
    pricesArray.find((elem) => {
      if (value >= elem.min && value <= elem.max) {
        setItemPrice(elem.price);
      }
    });
  };
  if (error) {
    return (
      <Container>
        <h5>{error}</h5>
      </Container>
    );
  }
  if (isLoaded) {
    return (
      <Container>
        <Modal
          type={"add-cart"}
          isShowing={isShowing}
          hide={toggle}
          item={title}
          allow={allowScroll}
          image={getFileUrl(img)}
          link={url}
        />
        <Modal
          type={"wishlist"}
          isShowing={wishModalOpen}
          hide={() => setWishModalOpen(false)}
          wishlists={BoundWish(wishlistedItems)}
          allow={allowScroll}
          id={id}
        />
        <NotificationContainer />
        <ButtonsContainer>
          {CheckInWish(wishlistedItems) ? (
            <WishlistButton
              className="wish"
              variant={"liked"}
              onClick={() => {
                setWishModalOpen(true);
              }}
            />
          ) : (
            <WishlistButton
              className="wish"
              onClick={() => {
                setWishModalOpen(true);
              }}
            />
          )}
          <ShareButton />
        </ButtonsContainer>
        <Flex>
          <Slider>
            {gallery.length == 1 ? (
              <MainImage
                src={getFileUrl(gallery[0])}
                onClick={() => {
                  onClick(getFileUrl(gallery[0]));
                }}
              />
            ) : (
              <Carousel
                infiniteLoop={true}
                showArrows={false}
                thumbWidth="20vw"
                showIndicators={false}
                showStatus={false}
              >
                {gallery.map((item) => {
                  return <img src={getFileUrl(item)} />;
                })}
              </Carousel>
            )}
            <ProductStatus variant={status} />
          </Slider>
          <Calculator>
            <Title>{title}</Title>
            <SKU>SKU: {sku}</SKU>
            <DeliveryRow>
              <OrderStatus>
                {stock > 0
                  ? `In stock: ${stock} kg`
                  : stock == 0 && status == "Soon"
                    ? "Preorder"
                    : "Out of stock"}
              </OrderStatus>
              <DeliveryInfo>
                <span>1 box = {step} kg</span>
                <span>MOQ = {moq} kg</span>
              </DeliveryInfo>
            </DeliveryRow>
            {price ? (
              <PurchaseAuth>
                <PurchaseSection>
                  <PurcahseRow>
                    <Price>
                      €{itemPrice} <span>/ kg</span>
                    </Price>
                    {pricesArray[0] ? (
                      <Counter
                        width="165px"
                        height="38px"
                        value={value}
                        step={step}
                        qty={moq}
                        stock={stock}
                        min={moq}
                        setValue={setValue}
                        calcPrice={calculatePrice}
                      />
                    ) : (
                      <Counter
                        width="150px"
                        height="38px"
                        value={value}
                        stock={stock}
                        step={step}
                        qty={moq}
                        min={moq}
                        setValue={setValue}
                      />
                    )}
                  </PurcahseRow>
                  <PurcahseRow>
                    <TotalPrice>
                      <TotalTitle>total price</TotalTitle>
                      <TotalValue>€ {itemPrice * value}</TotalValue>
                    </TotalPrice>
                    <BuyButton onClick={() => addToCart()}>
                      <Image />
                      <span>Add to cart</span>
                    </BuyButton>
                  </PurcahseRow>
                </PurchaseSection>
              </PurchaseAuth>
            ) : !user.isAuth ? (
              <RequestWrapper>
                <PurchaseSection>
                  <RequestRow>
                    {pricesArray[0] ? (
                      <Counter
                        width="160px"
                        height="38px"
                        value={value}
                        step={step}
                        qty={moq}
                        min={moq}
                        setValue={setValue}
                        calcPrice={calculatePrice}
                      />
                    ) : (
                      <Counter
                        width="150px"
                        height="38px"
                        value={value}
                        step={step}
                        qty={moq}
                        min={moq}
                        setValue={setValue}
                      />
                    )}
                    <BuyButton
                      onClick={() => {
                        addToRequest(id, title, img, moq, status, value, step);
                        toggle();
                        blockScroll();
                      }}
                    >
                      Request
                    </BuyButton>
                  </RequestRow>
                </PurchaseSection>
              </RequestWrapper>
            ) : (
              <RequestWrapper>
                <PurchaseSection>
                  <RequestRow>
                    {pricesArray[0] ? (
                      <Counter
                        width="160px"
                        height="38px"
                        value={value}
                        step={step}
                        qty={moq}
                        min={moq}
                        setValue={setValue}
                        calcPrice={calculatePrice}
                      />
                    ) : (
                      <Counter
                        width="150px"
                        height="38px"
                        value={value}
                        step={step}
                        qty={moq}
                        min={moq}
                        setValue={setValue}
                      />
                    )}
                    <BuyButton onClick={() => addToCart()}>
                      Add to quote
                    </BuyButton>
                  </RequestRow>
                </PurchaseSection>
              </RequestWrapper>
            )}
          </Calculator>
        </Flex>
        {user.isAuth ? <Attributes attributeId={attributeId} /> : <></>}
      </Container>
    );
  } else {
    return (
      <Container>
        <LoadingPlaceholder />
      </Container>
    );
  }
};

export default MainSectionDesk;
