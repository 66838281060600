import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as MobileSearchIcon } from "../images/mobileSearch.svg";
import { ReactComponent as MobileUserIcon } from "../images/mobileUser.svg";
import { ReactComponent as MobileCartIcon } from "../images/mobileCart.svg";
import { ReactComponent as BurgerIcon } from "../images/burger.svg";
import { ReactComponent as LogoMobileIcon } from "../images/logo-mobile.svg";
import { ReactComponent as CloseIcon } from "../images/close.svg";
import { ReactComponent as LogoDescktop } from "../images/logo-descktop.svg";
import { ReactComponent as SearchDescktop } from "../images/searchDescktop.svg";
import { ReactComponent as UserDescktop } from "../images/descktopUser.svg";
import { ReactComponent as CartDescktop } from "../images/descktopCart.svg";
import MediaQuery from "react-responsive";
import Navigation from "./Navigation";
import AccountModal from "./AccountModal";
import CartModal from "./CartModal";
import SearchResults from "./SearchResults";
import { Context } from "../../../../..";
import { fetchBasket } from "../../../../../api/basketAPI";
const BurgerButton = styled.button`
  background-color: transparent;
  border: none;
  margin-right: 15px;
  @media screen and (min-width: 1128px) {
    display: none;
  }
`;
const MenuContainer = styled.div`
  width: calc(100% - 36px);
  margin: 0 auto;
  @media screen and (min-width: 1128px) {
    width: 100%;
  }
  .navigation-menu {
    position: absolute;
    top: 0;
    background-color: #fff;
    width: 90vw;
    height: 100vh;
    z-index: 3;
    left: -100vw;
    -webkit-transition: left 0.5s;
    transition: left 0.5s;
    @media screen and (min-width: 1128px) {
      display: block;
      position: relative;
      left: 0px;
      height: auto;
      width: 100%;
    }
    .close {
      position: absolute;
      right: 10px;
      top: 10px;
      border: none;
      background: none;
      @media screen and (min-width: 1128px) {
        display: none;
      }
    }
  }
  .navigation-menu.expanded {
    display: block;
    left: 0px;
    -webkit-transition: left 0.5s;
    transition: left 0.5s;
  }
  .darked {
    z-index: 3;
    background-color: #fff;
    transition: background-color 1s;
    position: absolute;
    width: 105vw;
    height: 100vh;
    display: none;
    left: -18px;
  }
  .darked.shown {
    display: block;
    opacity: 0.7;
    background-color: #000;
    @media screen and (min-width: 1128px) {
      display: none;
    }
  }
`;
const MenuWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding-top: 11px;
  margin: 0 auto;
  width: 100%;
 position: relative;
  @media screen and (min-width: 1128px) {
    width: 1128px;
    position: relative;
  }
  .logo-container.expanded {
    display: none;
  }
`;
const SearchShowButton = styled.button`
  background-color: transparent;
  border: none;
  @media screen and (min-width: 1128px) {
    display: none;
  }
`;
const UserButton = styled.button`
  background-color: transparent;
  border: none;
  @media screen and (min-width: 1128px) {
    font-weight: 400;
    font-size: 13px;
    line-height: 30px;
    color: #303236;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 30px 5px 13px;
    gap: 10px;
    width: 159px;
    height: 40px;
    border: 1px solid rgba(216, 216, 216, 0.3);
    border-radius: 2px;
    position: relative;
    margin-right: 20px;
    margin-left: 33px;
    &::after {
      content: " ";
      mask: url("/arrow.svg");
      transition: transform 0.5s, background-color 0.5s;
      background-color: #303236;
      position: absolute;
      right: 13px;
      height: 5px;
      width: 10px;
    }
    &.expanded {
      color: #000000;
      border-color: #000000;
      &::after {
        transform: rotate(180deg);
        background-color: #000000;
      }
    }
  }
`;

const CartButton = styled.button`
  position: relative;
  background-color: transparent;
  border: none;
  @media screen and (min-width: 1128px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px 17.5px;
    gap: 10px;
    width: 51px;
    height: 40px;
    border: 1px solid rgba(216, 216, 216, 0.3);
    border-radius: 2px;
    &.expanded {
      border-color: #000000;
    }
  }
`;

const MobileLogo = styled.div`
  margin-right: auto;
  @media screen and (min-width: 1128px) {
    margin-right: 45px;
  }
`;

const CartTotalBubble = styled.div`
color: white;
position: absolute;
bottom: -10px;
left: -10px;
background-color: red;
border-radius: 10px;
width: 20px;
height: 20px;
display: flex;
align-items: center;
justify-content: center;
p{
  margin: 0px;
}
`

const SearchForm = styled.div`
  .search-container {
    position: absolute;
    display: flex;
    -webkit-transition: top 0.5s;
    top: -80px;
    transition: top 0.5s;
    width: 100vw;
    background-color: #fff;
    z-index: 3;
    left: -18px;
    box-sizing: border-box;
    padding: 10px 20px;
    @media screen and (min-width: 1128px) {
      width: 693px;
      position: inherit;
      left: 0px;
      padding: 0px;
    }
    .search-input {
      width: calc(100vw - 68px);
      display: inline-block;
      box-shadow: 0px 1px 0px #eaebec;
      border: none;
    }
    @media screen and (min-width: 1128px) {
      .search-input {
        border: 1px solid rgba(216, 216, 216, 0.3);
        border-radius: 2px;
        height: 38px;
        text-indent: 10px;
        box-shadow: none;
        width: 633px;
        &:focus-visible {
          outline: none;
        }
      }
    }
  }
  .expanded {
    -webkit-transition: top 0.5s;
    transition: top 0.5s;
    top: 0px;
    &:focus-visible :focus {
      outline: none;
    }
  }
  .search-button {
    border: none;
    background-color: #fff;
    padding-top: 5px;
    vertical-align: baseline;
    @media screen and (min-width: 1128px) {
      width: 60px;
      height: 38px;
      background: #eaebec;
      border-radius: 0px 1px 1px 0px;
      padding: 12.5px 23.5px;
      gap: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`;

const HeaderMenu = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const [isUserExpanded, setIsUserExpanded] = useState(false);
  const [isCartExpanded, setIsCartExpanded] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState(false);

  const { user } = useContext(Context)

  let navigate = useNavigate();
  const routeChange = () => {
    let path;
    if (user.isAuth) {
      path = `/cart`
    }
    else {
      path = `/request`;
    }
    navigate(path);
  };

  useEffect(() => {
    if (user.user.id) {
      fetchBasket(user.user.id).then(
        (data) => {
          setItems(data.items);
          let subtotal = 0;
          data.items.map((item) => {
            let presum = item.qty * item.price;
            subtotal += presum;
          })
          setTotal(subtotal)
        },
        (e) => {
          setIsLoaded(false);
          setError(e);
        }
      ).finally(setIsLoaded(true));
    } else {
    }
  }, [isCartExpanded]);


  return (
    <MenuContainer>
      <div
        className={
          isSearchExpanded || isNavExpanded || isUserExpanded || isCartExpanded
            ? "darked shown"
            : "darked"
        }
        onClick={() => {
          setIsNavExpanded(false);
          setIsSearchExpanded(false);
          setIsUserExpanded(false);
        }}
      ></div>
      <MenuWrapper>
        <BurgerButton
          onClick={() => {
            setIsNavExpanded(!isNavExpanded);
          }}
        >
          <BurgerIcon />
        </BurgerButton>
        <MobileLogo>
          <Link to="/">
            <MediaQuery maxWidth={1127}>
              <LogoMobileIcon />
            </MediaQuery>
            <MediaQuery minWidth={1128}>
              <LogoDescktop />
            </MediaQuery>
          </Link>
        </MobileLogo>
        <SearchForm>
          <div
            className={
              isSearchExpanded
                ? "search-container expanded"
                : "search-container"
            }
          >
            <input
              className="search-input"
              placeholder="Search entire store here... (Enter more then 3 letters)"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
            <Link onClick={() => { navigate(`/search/${search}`); window.location.reload() }}>
              <button className="search-button">
                <MediaQuery maxWidth={1127}>
                  <MobileSearchIcon />
                </MediaQuery>
                <MediaQuery minWidth={1128}>
                  <SearchDescktop />
                </MediaQuery>
              </button>
            </Link>
          </div>
          {search ? <SearchResults search={search} /> : null}
        </SearchForm>
        <SearchShowButton
          onClick={() => {
            setIsSearchExpanded(!isSearchExpanded);
          }}
        >
          <MobileSearchIcon />
        </SearchShowButton>
        <UserButton
          className={isUserExpanded ? "expanded" : null}
          onClick={() => setIsUserExpanded(!isUserExpanded)}
        >
          <MediaQuery maxWidth={1127}>
            <MobileUserIcon />
          </MediaQuery>
          <MediaQuery minWidth={1128}>
            <UserDescktop />
            <span>My account</span>
          </MediaQuery>
        </UserButton>
        <AccountModal
          expanded={isUserExpanded}
          onClick={() => setIsUserExpanded(!isUserExpanded)}
        />
        <MediaQuery minWidth={1128}>
          <CartButton
            onClick={() => { user.isAuth ? setIsCartExpanded(!isCartExpanded) : navigate('/request') }}
            className={isCartExpanded ? "expanded" : null}
          >
            <CartDescktop />
            {items.length > 0 ? <CartTotalBubble><p>{items.length}</p></CartTotalBubble> : null}
          </CartButton>
        </MediaQuery>
        <MediaQuery maxWidth={1127}>
          <CartButton onClick={routeChange}>
            <MobileCartIcon />
          </CartButton>
        </MediaQuery>

        <MediaQuery minWidth={1128}>
          {user.isAuth ? <CartModal
            error={error}
            isLoaded={isLoaded}
            products={items}
            expanded={isCartExpanded}
            total={total}
            onClick={() => setIsCartExpanded(!isCartExpanded)}
          /> : null}
        </MediaQuery>
      </MenuWrapper>
      <div
        className={
          isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
        }
      >
        <button
          className="close"
          onClick={() => {
            setIsNavExpanded(!isNavExpanded);
          }}
        >
          <CloseIcon />
        </button>
        <Navigation />
      </div>
    </MenuContainer>
  );
};
export default HeaderMenu;
