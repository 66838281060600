import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import Promo from './Promo'
import { getFileUrl } from '../../../../../api/productAPI';
import MediaQuery from 'react-responsive';
import LoadingPlaceholder from '../../../../Functional/LoadingPlaceholder';
import { Link, useNavigate } from 'react-router-dom';
import { getHeaderAdContent } from '../../../../../api/contentAPI';
import { Context } from '../../../../..';

const PromoColumnContainer = styled.div`
  display: flex;
  gap: 10px;
  position: relative;
`;

const EditButton = styled(Link)`
background: none;
text-decoration: none;
color: #40bf6a;
font-size: 10pt;
position: absolute;
top: -20px;
left: 0px;
&:hover, :active{
    text-decoration: underline;
    color: #40bf6a;
}
`

const PromoColumn = ({ ads, setAds }) => {
    const { user } = useContext(Context);
    const [content, setContent] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        getHeaderAdContent().then((data) => {
            setAds(data.length)
            setContent(data)
        }, (e) => {
            setContent([]);
        })
    }, [])

    if (content[0]) {
        return (
            <PromoColumnContainer>
                {user.user.role_name == 'Admin' ? <MediaQuery minWidth={1128}><EditButton to={'/admin/customize/header'}>Edit promo</EditButton></MediaQuery> : null}
                <MediaQuery minWidth={1128}>
                    {content.map((ad) => {
                        return (
                            <Promo title={ad.main_text} img={getFileUrl(ad.img)} url={ad.dest_link ? ad.dest_link : '/'} />
                        )
                    })}
                </MediaQuery>
            </PromoColumnContainer>
        )
    }
    else {
        return (
            <></>
        )
    }
}

export default PromoColumn