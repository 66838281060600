import React from "react";
import styled from "styled-components";

const Component = styled.p`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
padding: 8px 6px;
background: ${props => props.status === 'Soon' ? '#18A0FB' : '#40BF6A'};
width: ${props => props.width ? props.width : '53px'};
height: ${props => props.height ? props.height : '19px'};
border-radius: 4px;
font-family: 'Proxima Nova';
font-style: normal;
font-weight: 600;
font-size: 10px;
line-height: 19px;
color: #FFFFFF;
margin: ${props => props.margin ? props.margin : '0px'};
`;

const Avaliable = styled(Component)`
  background: #40bf6a;
`;

const UponRequest = styled(Component)`
  background: #EF5E53;
`;

const Soon = styled(Component)`
  background: #18a0fb;
`;


const CartStatus = ({ status, width, height, margin }) => {
    return (
        <>
            {status == "under-development" ? (
                <Component>Under development</Component>
            ) : status == "Avaliable" ? (
                <Avaliable>Avaliable</Avaliable>
            ) : status == "under-development" ? (
                <UponRequest>Upon request</UponRequest>
            ) : status == "Soon" ? (
                <Soon>Soon</Soon>
            ) : (
                <UponRequest>Unset</UponRequest>
            )}
        </>
    )
}

export default CartStatus;