import React, { useState } from "react";
import styled from "styled-components";

const SetcionQuestion = styled.p`
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  padding-left: 10px;
  position: relative;
  margin-bottom: 15px;
  margin-top: 0;
  &::before {
    content: "•";
    position: absolute;
    left: 0px;
  }
`;

const SectionAnswer = styled.p`
  display: none;
  background: #f7f8f9;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  text-align: justify;
  color: #303236;
  box-sizing: border-box;
  padding: 10px;
  margin-bottom: 20px;
  &.active {
    display: block;
  }
`;

function FAQquestion({ question, answer }) {
  const [active, setActive] = useState(false);
  return (
    <>
      <SetcionQuestion onClick={() => setActive(!active)}>
        {question}
      </SetcionQuestion>
      <SectionAnswer className={active ? "active" : null}>
        {answer}
      </SectionAnswer>
    </>
  );
}

export default FAQquestion;
