import React from "react";
import styled from "styled-components";
import Page from "../components/VitaforestUI/Interface/Page/Page";
import ProductPage from "../components/VitaforestUI/Product/ProductPage/ProductPage";

const Product = () => {
  return (
    <Page background={true} variant={'centred'}>
      <script src="https://yastatic.net/share2/share.js"></script>
      <div class="ya-share2" data-curtain data-color-scheme="whiteblack" data-limit="0" data-more-button-type="short" data-services="viber,linkedin"></div>
      <ProductPage />
    </Page>
  );
};

export default Product;
