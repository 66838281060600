import React, { useContext, useState } from "react";
import { login } from "../api/userAPI";
import { Form } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import { Context } from "../index";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import DefaultButton from "../components/VitaforestUI/Interface/Buttons/DefaultButton";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Header from "../components/VitaforestUI/Interface/Header/Header";
import Footer from "../components/VitaforestUI/Interface/Footer/Footer";
import MediaQuery from "react-responsive";
import Input from "../components/VitaforestUI/Interface/Input/Input";
import Textarea from "../components/VitaforestUI/Interface/Textarea/Textarea";
import { sendForm } from "../api/formsAPI";

const PageContainer = styled.div`
  font-family: "Proxima Nova";
  font-style: normal;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  @media screen and (min-width: 1128px) {
    height: 100vh;
    flex-direction: row;
    justify-content: flex-start;
  }
`;

const Heading = styled.h2`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 17px;
  color: #303236;
  padding-bottom: 8px;
  border-bottom: 1px solid #303236;
  margin-top: 25px;
`;

const ButtonSubmit = styled(DefaultButton)`
  max-width: 280px;
  margin: 15px auto;
  color: #ffffff;
  background-color: #3c3f54;
  &:hover,
  &:active,
  &:focus {
    background-color: #242638;
  }
  @media screen and (min-width: 1128px) {
    min-width: 100%;
  }
`;

const LoginForm = styled.div`
  margin: 18px;
  margin-bottom: auto;
  @media screen and (min-width: 1128px) {
    width: 43vw;
    box-sizing: border-box;
    padding: 40px 86px;
  }
`;

const Text = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-top: 15px;
  color: #303236;
  margin-bottom: 0;
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 40px 42px 22px 76px;
  background-image: url("/regbg.webp");
  width: 57vw;
  background-size: cover;
  .logo {
    width: 191px;
    height: 49px;
  }
`;

const RegText = styled(Text)`
  margin-bottom: 30px;
`;
const BackButton = styled.button`
  padding: 12px 24px 12px 42px;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  border: 2px solid #3c3f54;
  color: #3c3f54;
  background-color: #ffffff;
  border-radius: 4px;
  position: relative;
  display: block;
  margin-left: auto;
  margin-bottom: 20px;
  &:before {
    content: url("/arrow-reg.svg");
    position: absolute;
    left: 19px;
    top: 12px;
  }
`;

const Socials = styled.div`
  margin-top: auto;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  img {
    padding-right: 10px;
  }
`;

const Copyright = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #eaebec;
  justify-content: space-between;
  margin-top: auto;
`;

const Privacy = styled(Link)`
  text-align: right;
  text-decoration-line: underline;
  color: inherit;
`;

const StylesLayer = styled(PageContainer)`
  .form-control:focus {
    border: 1px solid #bbbbbb;
    box-shadow: none;
  }
`;

const Flex = styled.div`
  display: flex;
  gap: 25px;
  margin-top: 10px;
`;

const FormDiv = styled.div`
  width: 100%;
`;

const LogoLink = styled(Link)``;

const Plug = observer(() => {
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const send = () => {
    if (email && name && message) {
      if (!isValidEmail(email)) {
        NotificationManager.error("Email is invalid", "Error");
      } else {
        let formdata = new FormData();
        formdata.append("name", name);
        formdata.append("email", email);
        formdata.append("phone", phone);
        formdata.append("message", message);
        formdata.append("from", "Country block page");
        sendForm(formdata).then((data) => {
          if (data.id) {
            NotificationManager.success("Your message sended!", "Success");
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            NotificationManager.success(data.message, "Error");
          }
        });
      }
    } else {
      NotificationManager.warning("Fill all required fields", "Warning");
    }
  };

  return (
    <StylesLayer>
      <MediaQuery minWidth={1128}>
        <LeftColumn>
          <LogoLink to={"/en"}>
            <img className="logo" src="/LogoLight.svg" />
          </LogoLink>

          <Copyright>
            <span> © VitaForest 2023</span>
          </Copyright>
        </LeftColumn>
      </MediaQuery>
      <LoginForm>
        <Heading>Coming soon</Heading>
        <RegText>
          We're coming soon. If you want to contact us and ask questions, please
          fill out the feedback form below.
        </RegText>
        <NotificationContainer />
        <Input label="Your name" type="text" placeholder="Jhon Doe" required />
        <Flex>
          <FormDiv>
            <Input
              label="Your email"
              type="email"
              placeholder="example@email.com"
              required
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </FormDiv>
          <FormDiv>
            <Input
              label="Phone number"
              type="phone"
              placeholder="+ _ _ _ ( _ _ _ ) "
              required
              onChange={(e) => {
                setPhone(e.target.value);
              }}
            />
          </FormDiv>
        </Flex>
        <Textarea label="What's on your mind" required
          onChange={(e) => {
            setMessage(e.target.value);
          }} />
        <ButtonSubmit type="submit" title="Submit" onClick={() => {
          send();
        }} />
      </LoginForm>
    </StylesLayer>
  );
});

export default Plug;
