import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { getFileUrl } from "../../../api/productAPI";

const Container = styled(Link)`
  box-shadow: 0px 4px 10px rgba(26, 33, 45, 0.05);
  border-radius: 4px;
  position: relative;
  width: calc(50% - 5px);
  text-decoration: none;
  @media screen and (max-width: 320px) {
    width: 100%;
  }
  @media screen and (min-width: 900px) {
    width: 264px;
  }
`

const CardImage = styled.img`
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  aspect-ratio: 1.47;
`;

const Title = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #303236;
  margin-bottom: 5px;
  margin-top: 10px;
  padding-left: 15px;
  @media screen and (min-width: 1128px) {
    font-size: 16px;
    line-height: 19px;
    margin-top: 15px;
    margin-bottom: 8px;
  }
`;

const Description = styled.p`
font-family: 'Proxima Nova';
font-style: normal;
font-weight: 400;
font-size: 13px;
line-height: 16px;
color: #7f878b;
align-self: stretch;
padding-left: 15px;
@media screen and (min-width: 1128px){
font-weight: 400;
font-size: 14px;
line-height: 17px;
}
`

const WikiSearchElem = ({ img, title, short, url }) => {
  return (
    <Container to={`/en/wiki/${url}`}>
      <CardImage src={img} />
      <Title>{title}</Title>
      <Description>{short}</Description>
    </Container>
  )
}

export default WikiSearchElem