import React from "react";
import styled from "styled-components";
const Header = styled.h3`
  font-weight: 600;
  font-size: 20px;
  line-height: 17px;
  text-align: center;
  color: #303236;
  @media screen and (min-width: 1128px) {
    font-size: 26px;
    line-height: 28px;
    text-align: left;
  }
`;
export default function PageHeader({ children, style }) {
  return <Header style={style}>{children}</Header>;
}
