import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import DefaultButton from "../Buttons/DefaultButton";
import SectionHeading from "../TextElements/SectionHeading";

const CartContainer = styled.div`
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #303236;
  margin-top: 30px;
  background-image: url("/emptycart.svg");
  background-repeat: no-repeat;
  padding-bottom: 300px;
  background-position: center;
  @media screen and (min-width: 1128px) {
    background-size: 350px;
    background-position: 418px 67px;
  }
`;

const CartHeading = styled.h3`
  font-weight: 600;
  font-size: 20px;
  line-height: 17px;
  margin-bottom: 30px;
  color: #303236;
  @media screen and (min-width: 1128px) {
    display: none;
  }
`;
const CartWrapper = styled.div`
  @media screen and (min-width: 1128px) {
    margin-left: 316px;
    padding-top: 127px;
  }
`;
const EmptyDescription = styled.p`
  @media screen and (min-width: 1128px) {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #303236;
    width: 154px;
  }
`;

const Button = styled(DefaultButton)`
  width: 284px;
  display: block;
  margin: 0 auto;
  @media screen and (min-width: 1128px) {
    width: 154px;
    padding: 12px 19px;
    margin: 0px;
  }
`;

const StyledLink = styled(Link)`
text-decoration: none;
`

export default function EmptyCart(isReq) {
  if (!isReq) {
    return (
      <CartContainer>
        <CartWrapper>
          <CartHeading>Shopping cart</CartHeading>
          <EmptyDescription>
            You have no items in your shopping cart
          </EmptyDescription>
          <StyledLink to={'/en/shop'}>
            <Button title="Continue shopping" />
          </StyledLink>
        </CartWrapper>
      </CartContainer>
    );
  }
  else {
    return (
      <CartContainer>
        <CartWrapper>
          <CartHeading>Request cart</CartHeading>
          <EmptyDescription>
            You have no items in request cart
          </EmptyDescription>
          <StyledLink to={'/en/shop'}>
            <Button title="Go shopping" />
          </StyledLink>
        </CartWrapper>
      </CartContainer>
    );
  }
}
