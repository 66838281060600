import React from "react";
import RequestTable from "./RequestTable";

const FilledRequest = ({ items, setItems, needReload, setNeedReload, notificationPush }) => {
    return (
        <>
            <RequestTable items={items} needReload={needReload} setNeedReload={setNeedReload} setItems={setItems} notificationPush={notificationPush} />
        </>
    );

}

export default FilledRequest
