import React, { useState } from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import styled from "styled-components";
import { appendScript } from "../Footer/ScriptAppender";
import PromoButton from "../PromoComponents/PromoButton";
import { isPromoVisited } from "../../../../api/formsAPI";
import { Helmet } from "react-helmet";
import AdminPanel from "../../../Admin/AdminPanel/AdminPanel";

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  background: ${(props) =>
    props.background
      ? "linear-gradient(0deg,rgba(247, 248, 249, 0.5),rgba(247, 248, 249, 0.5)),#ffffff;"
      : null};
  .centred {
    @media screen and (min-width: 1128px) {
      width: 1128px;
      margin: 0 auto;
    }
  }
`;
const ContentContainer = styled.div``;

const Content = styled.div`
  flex: 1 0 auto;
`;

const Button = styled.button`
  position: fixed;
  z-index: 8;
  width: 40px;
  height: 40px;
  border: none;
  background-color: #40bf6a;
  right: 13px;
  bottom: 200px;
  border-radius: 50%;
  &.none {
    display: none;
  }
  &.shown {
    display: block;
  }
  &::after {
    position: absolute;
    content: url("/arrow-scroll.svg");
    top: 12px;
    left: 15px;
  }
  @media screen and (min-width: 1128px) {
    display: none;
  }
`;

const Page = ({ children, background, variant, seo_title, seo_description, seo_keywords, panelVisible }) => {
  const [visible, setVisible] = useState(false);
  const [withPanel, setWithPanel] = useState(panelVisible == false ? panelVisible : true);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 1000) {
      setVisible(true);
    } else if (scrolled <= 1000) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  window.addEventListener("scroll", toggleVisible);
  return (
    <Container background={background}>
      {withPanel ? <AdminPanel /> : null}
      <Helmet>
        <title>{seo_title}</title>
        <meta name="description" content={seo_description} />
        <meta name="keywords" content={seo_keywords} />
      </Helmet>
      <Content>
        <Header />
        <ContentContainer className={variant ? "centred " : " "}>
          {children}
        </ContentContainer>
      </Content>
      <Footer />
      <Button className={visible ? "shown" : "none"} onClick={scrollToTop} />
      {appendScript("/mainBitrixChat.js")}
      {isPromoVisited() ? <PromoButton location={isPromoVisited()} /> : null}
    </Container>
  );
};

export default Page;
