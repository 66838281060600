import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import Page from "../components/VitaforestUI/Interface/Page/Page";

const NotFoundBody = styled.div`
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-top: 120px;
`;

const NotFoundTitle = styled.h2`
	font-size: 72px;
	margin: 0 auto;
`;

const NotFoundText = styled.p`
font-size: 18px;
`

const NotFound = () => {
	return (
		<Page seo_title={`404 - Vitaforest`} seo_description={'Vitaforest 404 page'}>
			<NotFoundBody>
				<NotFoundTitle>404</NotFoundTitle>
				<NotFoundText>Do not rush to leave us! It is possible that what you are looking for has moved to a different address. Try using search.</NotFoundText>
			</NotFoundBody>
		</Page>
	)
}

export default NotFound