import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

const Container = styled.div`
  width: 100%;
  display: flex;
  gap: 24px;
`;

const Image = styled.img`
  width: 552px;
  border-radius: 4px;
  height: 300px;
  object-fit: cover;
`;

const LeftContainer = styled.div``;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CategoryFlex = styled(Flex)`
  margin-bottom: 5px;
`;
const Category = styled(Link)`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #40bf6a;
  text-decoration: none;
  padding-left: 13px;
  position: relative;
  &:before {
    position: absolute;
    content: url("/arrow-green.svg");
    color: #40bf6a;
    top: -1px;
    left: 0;
  }
  &:hover, &:active{
    color: #40bf6a;
    text-decoration: underline;
  }
`;

const PostDate = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #7f878b;
  margin-bottom: 0;
`;

const RightContainer = styled.div`
  width: 264px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h3`
  font-weight: 700;
  font-size: 38px;
  line-height: 43px;
  margin-top: 10px;
`;

const Description = styled.p`
  margin-top: 15px;
  margin-bottom: auto;
`;

const PostLink = styled(Link)`
  text-decoration: none;
  font-weight: 700;
  font-size: 13px;
  line-height: 14px;
  color: #40bf6a;
  &:hover, &:active{
    color: #40bf6a;
    text-decoration: underline;
  }
`;

const Comments = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: justify;
  color: #303236;
  opacity: 0.8;
  margin-bottom: 0;
  position: relative;
  &::before {
    position: absolute;
    content: url("/comments.svg");
    left: -23px;
  }
`;

export default function MainBlogItem(props) {
  return (
    <Container>
      <LeftContainer>
        <CategoryFlex>
          <Category to={"/en/blog/" + props.category}>{props.category}</Category>
          <PostDate>
            {dayjs(props.createdAt).format("MMM DD") + ", "}
            {dayjs(props.createdAt).format("YYYY")}
          </PostDate>
        </CategoryFlex>
        <Image src={props.img} />
      </LeftContainer>
      <RightContainer>
        <Title>{props.title}</Title>
        <Description>{props.short}</Description>
        <Flex>
          <PostLink to={`/en/blog/${props.url}`}>Read more</PostLink>
          <Comments>{props.comments}</Comments>
        </Flex>
      </RightContainer>
    </Container>
  );
}
