import { $authHost, $host } from ".";
import jwt_decode from "jwt-decode";
import { NotificationManager } from "react-notifications";

export const createCategory = async (category) => {
    const { data } = await $host.post('api/category', category)
    return data
}

export const fetchCategories = async () => {
    const { data } = await $host.get('api/category')
    return data
}

export const removeProduct = async (id) => {
    try {
        const { data } = await $host.post('api/product/remove', id)
        return true
    }
    catch (e) {
        return e.response.data
    }
}


export const createProduct = async (product) => {
    try {
        const { data } = await $authHost.post('api/product', product)
        return data
    }
    catch (e) {
        return e.response.data
    }
}

export const updateProduct = async (product) => {
    try {
        const { data } = await $host.post('api/product/update', product)
        return true
    }
    catch (e) {
        return e.response.data;
    }
}

export const fetchProducts = async (attributeIds, category) => {
    try {
        if (attributeIds || category) {
            attributeIds = JSON.stringify(attributeIds);
            category = JSON.stringify(category);
            const { data } = await $host.get('api/product', {
                params: {
                    attributeIds, category
                }
            })
            return data
        }
        else {
            const { data } = await $host.get('api/product')
            return data
        }
    }
    catch (e) {
        return e.response.data
    }
}

export const fetchOneProduct = async (id) => {
    try {
        const { data } = await $host.get(`api/product/${id}`)
        return data
    }
    catch (e) {
        return e.response.data
    }
}

export const fetchProductByUrl = async (url) => {
    try {
        const { data } = await $host.get(`api/product/url/${url}`)
        return data
    }
    catch (e) {
        return e.response.data
    }
}

export const getFileUrl = (url) => {
    return process.env.REACT_APP_API_URL + `/${url}`;
}


export const deletePrice = async (id) => {
    try {
        const { data } = await $host.post(`api/product/remove/price/${id}`)
        return data
    }
    catch (e) {
        return e.response.data
    }
}


export const addToRequest = (id, title, img, moq, status, qty, step) => {
    let empty = [];
    let item = { id: id, title: title, img: img, qty: qty, status: status, qty_step: step, moq: moq };
    if (!localStorage.getItem("request_items")) {
        empty.push(item);
        localStorage.setItem("request_items", JSON.stringify(empty));
    } else {
        let items = JSON.parse(localStorage.getItem("request_items"));
        let findElem = () => {
            let res = false;
            items.find((elem) => {
                if (elem.id === item.id) {
                    res = true;
                }
            });
            return res;
        };
        if (!findElem()) {
            items.push(item);
            localStorage.setItem("request_items", JSON.stringify(items));
        }
        else {
            NotificationManager.warning('Item already in request', '')
        }
    }
}