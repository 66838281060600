import React from "react";
import styled from "styled-components";
import { ReactComponent as PrevButton } from './svg/prev.svg';
import { ReactComponent as NextButton } from './svg/next.svg';

const PrevBtn = styled(PrevButton)`
&:hover path, &:active path{
    fill: #3C3F54;
}
&:hover rect, &:active path{
    stroke: #3C3F54;
}
`

const NextBtn = styled(NextButton)`
&:hover path, &:active path{
    fill: #3C3F54;
}
&:hover rect, &:active path{
    stroke: #3C3F54;
}
`

const SliderButton = ({ variant }) => {
    return (
        variant == "back" ? <PrevBtn /> : variant == 'next' ? <NextBtn /> : null)
}

export default SliderButton