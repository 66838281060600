import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Table } from "react-bootstrap";
import AdminUI from "../../Ui/AdminUI";
import { createUserRole, fetchUserRoles, removeUserRole, updateUserRole } from "../../../../api/userAPI";
import AddRoleModal from "./AddRoleModal";
import { NotificationContainer, NotificationManager } from "react-notifications";
import RemoveRoleModal from "./RemoveRoleModal";
import EditRoleModal from "./EditRoleModal";


const EditLabel = styled(Link)`
color: white;
text-decoration: none;
&:hover{
    color: white;
}
`

const FlexTitle = styled.div`
display: flex;
margin-bottom: 20px;
`;

const PageTitle = styled.h1`
margin: 0px;
margin-right: 20px;
`

const UserRoles = () => {
    const [items, setItems] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)
    const [needReload, setNeedReload] = useState(false);
    const [addModalActive, setAddModalActive] = useState(false);
    const [editModalActive, setEditModalActive] = useState(false);
    const [removeModalActive, setRemoveModalActive] = useState(false);
    const [currentItemId, setCurrentItemId] = useState(null);
    const [currentItemName, setCurrentItemName] = useState('');

    const openAddModal = () => {
        setAddModalActive(true);
    }

    const openEditModal = (id, name) => {
        setCurrentItemId(id);
        setCurrentItemName(name);
        setEditModalActive(true);
    }

    const openRemoveModal = (id) => {
        setCurrentItemId(id)
        setRemoveModalActive(true);
    }


    const updateRole = (name) => {
        const formdata = new FormData();
        formdata.append('name', name);
        formdata.append('id', currentItemId);
        updateUserRole(formdata).then((data) => {

            if (data.status = 'updated') {
                NotificationManager.success(`Role updated`, 'Success')
                setNeedReload(!needReload);
            }
            else {
                NotificationManager.error(`${data.message}`, 'Error');
                setNeedReload(!needReload);
            }
        })
    }


    const removeRole = (id) => {
        removeUserRole(id).then((data) => {
            if (data === 'Removed') {
                NotificationManager.success(`Role removed`, 'Success')
                setNeedReload(!needReload);
                setCurrentItemId(null);
            }
            else {
                NotificationManager.error(`${data.message}`, 'Error');
                setNeedReload(!needReload);
            }
        })
    }

    const addNewRole = (name) => {
        const formdata = new FormData();
        formdata.append('name', name);
        createUserRole(formdata).then((data) => {
            if (data.name) {
                NotificationManager.success(`Role removed`, 'Success')
                setNeedReload(!needReload);
            }
            else {
                NotificationManager.error(`${data.message}`, 'Error');
                setNeedReload(!needReload);
            }
        })
    }

    useEffect(() => {
        fetchUserRoles().then((data) => {
            setItems(data)
            setIsLoaded(true)
        })
    }, [needReload])
    if (!isLoaded) {
        return (
            <div>
                <Spinner />
                <h3>Loading</h3>
            </div>
        )
    }
    else {
        return (
            <AdminUI>
                <NotificationContainer />
                <FlexTitle>
                    <PageTitle>Roles</PageTitle>
                    <Button onClick={() => { openAddModal() }}>Add new</Button>
                </FlexTitle>
                <Table striped bordered hover variant="dark">
                    <thead>
                        <tr>
                            <th>
                                #
                            </th>
                            <th>
                                Name
                            </th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item) => (
                            <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                <td><Button onClick={() => { openRemoveModal(item.id) }}>Delete</Button></td>
                                <td><Button onClick={() => { openEditModal(item.id, item.name) }}>Edit</Button></td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <AddRoleModal addItem={addNewRole} modalActive={addModalActive} setModalActive={setAddModalActive} />
                <RemoveRoleModal modalActive={removeModalActive} setModalActive={setRemoveModalActive} itemId={currentItemId} removeItem={removeRole} />
                <EditRoleModal modalActive={editModalActive} setModalActive={setEditModalActive} updateItem={updateRole} currentName={currentItemName} />
            </AdminUI>
        )
    }
}

export default UserRoles