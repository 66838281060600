import { $authHost, $host } from ".";
import jwt_decode from "jwt-decode";
import { convertFromRaw, convertToRaw } from "draft-js";
import draftToHtml from 'draftjs-to-html';

export const removePost = async (id) => {
    try {
        const formData = new FormData();
        formData.append('id', id);
        const { data } = await $host.post('api/post/remove', formData)
        return true
    }
    catch (e) {
        return e.response.data
    }
}

export const fetchAllComments = async () => {
    try {
        const { data } = await $authHost.get(`api/comments/post`);
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const approveFetchComments = async () => {
    try {
        const { data } = await $authHost.get('api/comments/post');
        return data
    } catch (e) {
        return e.response.data
    }
}

export const commentApprove = async (id) => {
    try {
        const { data } = await $authHost.post(`api/comments/post/approve/${id}`);
        return data
    } catch (e) {
        return e.response.data;
    }
}

export const removeComment = async (id) => {
    try {
        const { data } = await $authHost.post(`api/comments/post/remove/${id}`);
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const createPost = async (post) => {
    try {
        const { data } = await $authHost.post('api/post', post)
        return data
    }
    catch (e) {
        return e.response.data
    }
}

export const updatePost = async (post) => {
    try {
        const { data } = await $host.post('api/post/update', post)
        return true
    }
    catch (e) {
        return e.response.data
    }
}

export const fetchPosts = async (page, limit = 5) => {
    try {
        const { data } = await $host.get('api/post', {
            params: {
                page, limit
            }
        })
        return data
    }
    catch (e) {
        return e.response.data
    }
}

export const fetchOnePost = async (id) => {
    try {
        const { data } = await $host.get(`api/post/${id}`)
        return data
    }
    catch (e) {
        return e.response.data
    }
}

export const fetchPostByUrl = async (url) => {
    try {
        const { data } = await $host.get(`api/post/url/${url}`);
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const addPostComment = async (body) => {
    try {
        const { data } = await $authHost.post(`api/comments/post`, body);
        return data;
    } catch (e) {
        return e.response.data;
    }
}


export const fetchPostsByCategory = async (category) => {
    try {
        const { data } = await $authHost.get(`api/post/category/${category}`);
        return data;
    } catch (e) {
        return e.response.data;
    }
}

// use to convert raw editor from database to editable data format
export const decodeEditorBody = (body) => {
    return convertFromRaw(JSON.parse(body));
}
// use to convert editable data format into json
export const encodeEditorBody = (body) => {
    return JSON.stringify(convertToRaw(body.getCurrentContent()))
}
// creates html from editor data
export const editorParse = (body) => {
    const rawPost = decodeEditorBody(body);
    return draftToHtml(convertToRaw(rawPost));
}
