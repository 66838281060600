import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: none;
  font-size: 14px;
  @media screen and (min-width: 1128px) {
    display: block;
    text-align: justify;
  }
`;

const CategoryTitle = styled.h3`
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  position: relative;
  padding-left: 34px;
  margin-bottom: 33px;
  &::before {
    position: absolute;
    content: url("/shop-food-supplements.svg");
    left: 0px;
    top: -4px;
  }
`;

const FoodSupplementsTitle = styled(CategoryTitle)`
  &::before {
    content: url("/shop-food-supplements.svg");
  }
`;

const PharmaceuticalTitle = styled(CategoryTitle)`
  &::before {
    content: url("/shop-pharmaceutical.svg");
  }
`;

const HealthyNutritionTitle = styled(CategoryTitle)`
  &::before {
    content: url("/shop-healthy-nutrition.svg");
  }
`;

const PowdersTitle = styled(CategoryTitle)`
  &::before {
    content: url("/shop-powders.svg");
  }
`;

const FoodAndDrinksTitle = styled(CategoryTitle)`
  &::before {
    content: url("/shop-food-and-drinks.svg");
  }
`;

const AnimalsTitle = styled(CategoryTitle)`
  &::before {
    content: url("/shop-animals.svg");
  }
`;

const CosmetologyTitle = styled(CategoryTitle)`
  &::before {
    content: url("/shop-cosmetology.svg");
  }
`;

const ExtractsTitle = styled(CategoryTitle)`
  &::before {
    content: url("/shop-extracts.svg");
  }
`;

const CategoryText = styled.p`
  margin: 16px 0px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const ExtractsText = styled(CategoryText)`
  margin: 34px 0px 20px 0px;
`;

const ExtractsSubtitle = styled.h5`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 25px;
`;

const ExtractsList = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 70px;
  grid-row-gap: 30px;
  padding: 0px;
  li:nth-child(1) {
    grid-area: 1 / 1 / 2 / 2;
  }

  li:nth-child(2) {
    grid-area: 1 / 2 / 2 / 3;
  }

  li:nth-child(3) {
    grid-area: 1 / 3 / 2 / 4;
  }

  li:nth-child(4) {
    grid-area: 2 / 1 / 3 / 2;
  }

  li:nth-child(5) {
    grid-area: 2 / 2 / 3 / 4;
  }
`;

const ExtractsItem = styled.li`
  list-style: none;
`;

const ItemFlex = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

const ExtractsImage = styled.img``;

const ExtractsItemTitle = styled.h5`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const ExtractsItemDescription = styled.p`
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  line-height: 16px;
`;

const CategoryList = styled.ul`
  font-size: 14px;
  font-weight: 400;
  padding-left: 0;
  line-height: 17px;
  list-style: inside;
  margin-bottom: 16px;
`;

export default function CategoryDescription(param) {
  return (
    <Container>
      {(() => {
        switch (param.param) {
          case "extracts":
            return (
              <>
                <ExtractsTitle>Extracts</ExtractsTitle>
                <ExtractsText>
                  Vitaforest © portfolio composed of dry form ingredients:
                  extracts and powders, the moisture content of which doesn't
                  exceed 5 %. There are no artificial additives been thrown in
                  either: coloring agents, flavors, or fragrances. Gentle
                  extraction methods allows to secure the highest possible level
                  of bioactive substances. We are happy to share the primordial
                  infinite power of the Siberian wild nature, with you.
                </ExtractsText>
                <ExtractsSubtitle>
                  Vitaforest © Extract key benefits:
                </ExtractsSubtitle>
                <ExtractsList>
                  <ExtractsItem>
                    <ItemFlex>
                      <ExtractsImage src="/extracts-time.svg" />
                      <ExtractsItemTitle>Time-saving</ExtractsItemTitle>
                    </ItemFlex>
                    <ExtractsItemDescription>
                      Powder extracts are prepared for further process or
                      consumption
                    </ExtractsItemDescription>
                  </ExtractsItem>
                  <ExtractsItem>
                    <ItemFlex>
                      <ExtractsImage src="/extracts-live.svg" />
                      <ExtractsItemTitle>Longer shelf life</ExtractsItemTitle>
                    </ItemFlex>
                    <ExtractsItemDescription>
                      Compared to liquid forms, the shelf life of which is
                      significantly shorter
                    </ExtractsItemDescription>
                  </ExtractsItem>
                  <ExtractsItem>
                    <ItemFlex>
                      <ExtractsImage src="/extracts-wild.svg" />
                      <ExtractsItemTitle>Wild-crafted</ExtractsItemTitle>
                    </ItemFlex>
                    <ExtractsItemDescription>
                      Genuine Siberian origin, manual forage, 100% natural
                      formulation
                    </ExtractsItemDescription>
                  </ExtractsItem>
                  <ExtractsItem>
                    <ItemFlex>
                      <ExtractsImage src="/extracts-ease.svg" />
                      <ExtractsItemTitle>
                        Ease in use, transportation and storage
                      </ExtractsItemTitle>
                    </ItemFlex>
                    <ExtractsItemDescription>
                      Extracts have minimum possible weight and volume-density
                    </ExtractsItemDescription>
                  </ExtractsItem>
                  <ExtractsItem>
                    <ItemFlex>
                      <ExtractsImage src="/extracts-fertil.svg" />
                      <ExtractsItemTitle>Efficiency</ExtractsItemTitle>
                    </ItemFlex>
                    <ExtractsItemDescription>
                      Powdered extracts are made by soaking the herb in a
                      solvent that is later evaporated. What's left behind is a
                      highly concentrated plant powder, therefore less amount is
                      needed to be taken for further usage
                    </ExtractsItemDescription>
                  </ExtractsItem>
                </ExtractsList>
              </>
            );
          case "pharmaceutical-industry":
            return (
              <>
                <PharmaceuticalTitle>Pharmaceutical</PharmaceuticalTitle>
                <p>
                  VitaForest’s medicinal herbs and mushrooms extracts and
                  powders are used in production of anti-inflammatory,
                  repairing, immunomodulatory and preventive care pastes, gels,
                  suppositories and hygienic agents. It is driven by fact of
                  high concentration of bioactive compounds in Siberian plant
                  and mushrooms sources and their unique adaptogenic
                  characteristics. They tend to boost immunity, protect from
                  multiple infections, prevent development of inflammatory
                  processes.
                </p>
                <p>
                  In pharmaceutical industry most commonly such dehydrated
                  powders and extratcs as rhodiola rosea, wild rose, astralagus,
                  mayflower, common St. Jhon’s wort, common elder, stinging
                  nettle, spiny eleuterococus and other herbs are used.
                </p>
                <p>
                  We place greater focus on our product and we conduct quality
                  tests on different stages of production. To start with,
                  verification of herbs during foraging stage, qualitative and
                  quantative analyses of raw materials, and qualitative analysis
                  of ready powder or extract. Sigma-Aldrich” matter standards
                  are used during laboratory tests implementation. Ready
                  products comply with EU safety and quality standards.
                </p>
                <p>
                  Upon your request, we can develop technical documentation and
                  produce dehydrated extrcts and powders from any wild healthful
                  sibirian sources.
                </p>
              </>
            );
          case "healthy-nutrition":
            return (
              <>
                <HealthyNutritionTitle>Healthy nutrition</HealthyNutritionTitle>
                <p>
                  Producers of sport, healthy and diabetical food&beverage in
                  numerous countries already acknowledged benefits of unique and
                  functional VitaForest extracts and powders, produced from
                  Siberian adaptogenic herbs. Their uniqueness is manifests in
                  the capability of improving general well-being and high
                  concentration of bioactive compounds such as flavonoids,
                  glycosides, glycans, phytosterols and terpenoids.
                </p>
                <p>
                  Application of extracts and powders made form Siberian
                  adaptogenics in production contributes to establishing highly
                  competitive healthy food products lines which:
                </p>
                <CategoryList>
                  <li>
                    increase physical and mental capacity, without negative
                    impact on human body;
                  </li>
                  <li>
                    support cognitive functions such as memory, thinking and
                    focus abilities;
                  </li>
                  <li>delay aging;</li>
                  <li>increase vitality;</li>
                  <li>boost immunity;</li>
                  <li>prevents tumor growth;</li>
                  <li>
                    increase durability during physical exercises and sport
                    trainings.
                  </li>
                </CategoryList>
                <p>
                  Extracts and powders from roseroot, Siberian ginseng, maral
                  root, spiny eleuterococcus, nanaian citronella and Manchurian
                  aralia are the richest with useful qualities.
                </p>
                <p>
                  VitaForest’s dehydrated extracts are convenient in use in
                  production of dietary marmalade, cookies, nutritional bars and
                  snacks, muesli, cocktails, tea, coffee, fruit water infusions
                  and other functional beverages.
                </p>
              </>
            );
          case "powders":
            return (
              <>
                <PowdersTitle>Powders</PowdersTitle>
                <p>
                  VitaForest © involves only the genuine Siberian wildcrafted
                  mushrooms, plants and fruits to develop dry functional powders
                  of excellent grade. These are foraged manually to preserve its
                  native content level of active substances in finished
                  products.
                </p>
                <p>
                  You can find such products as Chaga, Sea Buckthorn, Rosehips,
                  and Bilberry amongst the range of dry powders. All of the
                  VitaForest © products passed through several stages of the
                  laboratory tests and can be involved in the production of
                  nutritional products, dietary supplements, feed additives,
                  cosmetic and pharmaceutical industries.
                </p>
              </>
            );
          case "food-and-beverages":
            return (
              <>
                <FoodAndDrinksTitle>Food and beverages</FoodAndDrinksTitle>
                <p>
                  Due to unique characteristics of siberian mushrooms and
                  adaptogenic herbs, VitaForest’s extracts and powders are used
                  for production of bioactive food supplements for already more
                  than 5 years. They help to endure rugged environment and
                  increase human body’s adaptation mechanisms.
                </p>
                <p>
                  Handpicking of mushrooms and herbs in outlying Siberian areas,
                  gentle processing and scientifically proven adjusted to each
                  ingredient production technology give an opportunity to keep
                  unique bioactive compounds in VitaForest’s powders and
                  extracts. Food supplements produced with sibirian mushrooms
                  and herbs may:
                </p>
                <CategoryList>
                  <li>help to endure rugged environment;</li>
                  <li>regulates sleep patterns;</li>
                  <li>increase libido;</li>
                  <li>increase stress-resistance;</li>
                  <li>decrease chronical fatigue;</li>
                  <li>clear of apathy, inertness, constant urge to sleep;</li>
                  <li>
                    ease jet lag, effects of climate change and new schedule
                    adjustments struggles;
                  </li>
                  <li>reduce effect of harmful aspects at production.</li>
                  <p>
                    VitaForest’s extracts and powders from siberian mushrooms
                    and herbs give an opportunity of production highely
                    competitive and ecological friendly food supplements with
                    high concentration of glycans, glycosides, phytosterols,
                    flavonoids and terpenoids. These compounds positively affect
                    on general well-being of a human body.{" "}
                  </p>
                </CategoryList>
              </>
            );
          case "feed-additives":
            return (
              <>
                <AnimalsTitle>Feed additives</AnimalsTitle>
                <p>
                  VitaForest’s functional extracts and powders made out of
                  sibirian wild herbs and mushrooms are used also in veterinary
                  medicine, dry pet food production and feed additives for farms
                  and aquacultures.
                </p>
                <p>
                  Siberian birch chaga is a natural energy source for animals.
                  Chaga extracts and powders are used in veterinary practice and
                  animal farming as a natural growth stimulus for juveniles. As
                  reseaches have proved, chaga positively affects blood-forming
                  and protein creating liver’s function, and, in addition, helps
                  in curing indigestion among juveniles and increases haemo
                  globin levels.
                </p>
                <p>
                  Extracts and powders such medicinal herbs as rhodiola rosea,
                  common dandelion, purple coneflower, Baikal skullcap, plaster
                  clover and others are used as animal feed supplement, bio
                  active ingredients for veterinary pastes, suppositories and
                  other drugs, and also can be used as a flavoring agents for
                  animal feeds.
                </p>
                <p>
                  Siberian mushrooms and herbs are famous for their adaptogenic
                  characteristics. Regular application positively affects
                  growth, development, health and appearance of pets and
                  animals.
                </p>
              </>
            );
          case "cosmetology":
            return (
              <>
                <CosmetologyTitle>Cosmetology</CosmetologyTitle>
                <p>
                  Cosmetic producers highly value VitaForest’s herbal and
                  mushroom powders for the fantastic adaptogenic characteristics
                  of siberian areas. Huge amount of herbs exist which proved to
                  be efficient as external agents in the nature. However, as it
                  is proved by numerous researches, particularly Siberian nature
                  is famous for its richness of the most valuable bio
                  components.
                </p>
                <p>
                  Rhodiola rosea of Russian origin is added into creams and
                  lotions as an effective agent for delaying aging due to high
                  concentration of rosavin, rosarin and rosin in it. Siberian
                  ginseng contains a wide range of minerals which can be found
                  only in that type of ginseng. It is used in cosmetic for skin
                  care purposes. Nanain citronella hips are rich of E and C
                  vitamins, minerals and essential oils and are natural
                  antioxidants. Manchurian aralia rejuvenates, strengthens skin,
                  improves it’s protection from external influences and recovers
                  it elasticity and smoothes wrinkles
                </p>
                <p>
                  In cosmetic production Rhodiola rosea, chaga, milk thistle,
                  purple coneflower, siberian ginseng, Baikal skullcap and other
                  siberian herbs powders and extracts are used.{" "}
                </p>
              </>
            );
          case "herbal-extracts":
            return (
              <>
                <ExtractsTitle>Herbal extracts</ExtractsTitle>
                <p>
                  Vitaforest © herb`s commodities range includes dry extracts of
                  Rhodiola Rosea, Marsh-mallow (Althaéa officinális), Birch
                  leaves (Bétula), Echinacea purpurea, Elderberry (Sambúcus
                  nígra) fruit, Leuzea carthamoides, Baikal skullcap
                  (Scutellaria baicalensis), Bearberry (Arctostáphylos
                  úva-úrsi), Pallas milkweed (Euphorbia pallasiana), Eyebright
                  (Euphrásia officinális) and other valuable species from the
                  treasury of Siberia. Vitaforest © dry extracts undergo
                  laboratory testings for active substances content and product
                  safety. Commonly used in the production of healthy nutrition
                  products, beverages, food supplements, feed additives,
                  cosmetics and pharmaceuticals.
                </p>
              </>
            );
          case "mushroom-extracts":
            return (
              <>
                <ExtractsTitle>Mushroom extracts</ExtractsTitle>
                <p>
                  VitaForest © functional mushroom extracts, Chaga for example,
                  are made of genuine raw materials throughout gentle extraction
                  methods to maintain the highest possible level of active
                  substances.
                </p>
                <p>
                  VitaForest © Siberian Chaga mushroom extracts are used in food,
                  cosmetic and pharmaceutical industries in Europe, Asia, UK.
                  You may purchase preformed dry Chaga extracts of specific
                  consistency or run the individual procurement project applying
                  it to your healthy food, beverages, dietary supplements, feed
                  additives, cosmetic or pharmaceutical production purpose.
                </p>
              </>
            );
          case "fruit-extracts":
            return (
              <>
                <ExtractsTitle>Mushroom extracts</ExtractsTitle>
                <p>
                  VitaForest © functional mushroom extracts, Chaga for example,
                  are made of genuine raw materials throughout gentle extraction
                  methods to maintain the highest possible level of active
                  substances.
                </p>
                <p>
                  VitaForest © Siberian Chaga mushroom extracts are used in food,
                  cosmetic and pharmaceutical industries in Europe, Asia, UK.
                  You may purchase preformed dry Chaga extracts of specific
                  consistency or run the individual procurement project applying
                  it to your healthy food, beverages, dietary supplements, feed
                  additives, cosmetic or pharmaceutical production purpose.
                </p>
              </>
            );
          case "herbal-powders":
            return (
              <>
                <PowdersTitle>Herbal powders</PowdersTitle>
                <p>
                  There are some climatic and landscape features of Siberia that
                  are well-known all over the world due to its singularity,
                  therefore plants that grow in its pristine wild are so
                  valuable. Siberian herbs beneficially affect the night's rest,
                  protect the immune system, have an anti-stress and relaxation
                  properties, and can also be used in the contraction of various
                  diseases.
                </p>
                <p>
                  Rhodiola Rosea, Echinacea purpurea, Elderberry (Sambúcus
                  nígra) fruit, Leuzea carthamoides, Bearberry (Arctostáphylos
                  úva-úrsi), Eyebright (Euphrásia officinális) and other
                  valuable herbs from the treasury of Siberia are regularly
                  consumed in daily nutrition to maintain the active, eventful
                  lifestyle, minimizes the stress impact and care about the
                  health and the immune system of the loved ones.
                </p>
              </>
            );
          case "mushroom-powders":
            return (
              <>
                <PowdersTitle>Mushroom powders</PowdersTitle>
                <p>
                  Vitaforest © mushroom powders distinctive feature is the high
                  content level of polysaccharides. Those are the complex
                  high-molecular carbohydrates that act as potent immunity and
                  energy enhancers for human. Natural mushroom powders
                  production maintained on the application of modern
                  biotechnologies and scientific basis. Therefore dry Reishi
                  (Ganoderma lucidum), Agaricus, Tinder fungus (Fomes
                  fomentarius) powders made by Vitaforest © contains the maximum
                  amount of active substances and well absorbed by the body.
                </p>
              </>
            );
          case "fruit-powders":
            return (
              <>
                <PowdersTitle>Fruit powders</PowdersTitle>
                <p>
                  Fruits of the Siberian wildings are a valuable source of
                  natural sugars, polysaccharides, pectins, organic acids,
                  minerals, vitamins and other bioactive substances. Fresh
                  fruits and berries are processed into the food-related
                  powders, consequently saving active ingredients content level,
                  inherent a certain fruit species.
                </p>
                <p>
                  Food powders widely applied in the manufacturing of food,
                  beverages, health nutrition, food supplements, feed additives,
                  cosmetic and pharmaceutical products. By using modern
                  biotechnologies and scientific research we manage to retain
                  the maximum amount of active substances, contained in the
                  finished Vitaforest © products.
                </p>
              </>
            );
        }
      })()}
    </Container>
  );
}
