import { Editor, EditorState } from "draft-js";
import { Helmet } from "react-helmet";
import { decodeEditorBody } from "../../../../api/postAPI";
import Page from "./Page";
import { useEffect, useState } from "react";
import styled from "styled-components";
const PageVar = ({ body, seo_title, seo_description, seo_keywords, type, html_body, html_styles }) => {

    const [refresh, doRefresh] = useState(false);
    useEffect(() => {
        doRefresh(!refresh);
    }, [body, seo_title, seo_description, seo_keywords, type, html_body, html_styles])

    const HTMLContainer = styled.div`
    ${html_styles}
    `

    if (type == 'wysiwyg') {
        return (
            <>
                <Helmet>
                    <title>{seo_title}</title>
                    <meta name="description" content={seo_description} />
                    <meta name="keywords" content={seo_keywords} />
                </Helmet>
                <Page variant={'centred'}>
                    <Editor readOnly={true} editorState={EditorState.createWithContent(decodeEditorBody(body))} />
                </Page>
            </>
        )
    }
    else if (type == 'wysiwyg-preview') {
        return (
            <>
                <Helmet>
                    <title>{seo_title}</title>
                    <meta name="description" content={seo_description} />
                    <meta name="keywords" content={seo_keywords} />
                </Helmet>
                <Page variant={'centred'} panelVisible={false}>
                    <Editor readOnly={true} editorState={body} />
                </Page>
            </>
        )
    }
    else if (type == 'standalone-preview') {
        return (
            <>
                <Helmet>
                    <title>{seo_title}</title>
                    <meta name="description" content={seo_description} />
                    <meta name="keywords" content={seo_keywords} />
                </Helmet>
                <Page variant={'centred'} panelVisible={false}>
                    <HTMLContainer dangerouslySetInnerHTML={{ __html: html_body }} />
                </Page>
            </>
        )
    }
    else {
        return (
            <></>
        )
    }
}
export default PageVar;